import numeral from "numeral";
import "./ContrattiCell.scss";
import NumberInput from "../common/NumberInput";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

export function ContrattiCell({
  numeroDiContratti,
  importo,
  labels = ["contratto", "contratti"],
  color,
  editable = false,
  onEdit = undefined,
}: {
  numeroDiContratti: number;
  importo: number;
  labels?: [string, string];
  color?: string;
  editable?: boolean;
  onEdit?: (target: EventTarget) => void;
}) {
  const nString = numeroDiContratti === 1 ? labels[0] : labels[1];
  const importoString = numeral(importo).format("0,0");

  return (
    <div
      className={"ContrattiCell" + (editable ? " ContrattiCell-editable" : "")}
      style={{
        color: color,
      }}
    >
      {editable && (
        <div className="ContrattiCell-edit-button-container">
          <IconButton
            size="small"
            onClick={(event) => {
              if (onEdit) {
                onEdit(event.currentTarget);
              }
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      )}
      <div className="ContrattiCell-n">
        {numeroDiContratti} {nString}
      </div>
      <div className="ContrattiCell-i">
        {importoString} {"€"}
      </div>
    </div>
  );
}
