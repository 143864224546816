import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import moment from "moment";
import {
  Calendar as BCalendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const DnDCalendar = withDragAndDrop(BCalendar);

export type CalendarAccessors<E> = {
  isAllDay?: (e: E) => boolean;
  getId?: (e: E) => string | number; // must be unique
  getStart: (e: E) => Date;
  getEnd: (e: E) => Date;
  getTitle: (e: E) => ReactNode;
  getEventProps?: (e: E, isSelected: boolean) => any;
};
// & (E extends { id: number | string }
//   ? {
//       getId?: (e: E) => string | number; // must be unique
//     }
//   : {
//       getId: (e: E) => string | number; // must be unique
//     });

const defaultCalendarAccessors: any =
  // Partial<CalendarAccessors<any>>
  {
    isAllDay: () => false,
    getId: (e) => e.id,
  };

function getAccessor<K extends keyof CalendarAccessors<any>>(
  k: K,
  accessors: CalendarAccessors<any>
) {
  return accessors[k] || defaultCalendarAccessors[k];
}

function getAccessor0_3<K extends keyof CalendarAccessors<any>>(
  k: K,
  accessors: CalendarAccessors<any>
) {
  const f = accessors[k] || defaultCalendarAccessors[k];
  return f ? (a0, a1, a2, a3) => f(a0, a3) : undefined;
}

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const messages = {
  date: "Data",
  time: "Ora",
  event: "Evento",
  allDay: "Tutto il giorno",
  week: "Settimana",
  work_week: "Settimana lavorativa",
  day: "Giorno",
  month: "Mese",
  previous: "Precedente",
  next: "Successiva",
  yesterday: "Ieri",
  tomorrow: "Domani",
  today: "Oggi",
  agenda: "Agenda",
  noEventsInRange: "Nessun evento in questo intervallo.",
  showMore: (total) => (total === 1 ? `+${total} altro` : `+${total} altri`),
};

export default function Calendar<E>({
  defaultDate: _defaultDate,
  style,
  events,
  accessors,
  onSelectEvent,
  draggable,
  canDrag,
  onEventDrop,
}: {
  defaultDate?: string;
  style?: CSSProperties | undefined;
  events: E[];
  accessors: CalendarAccessors<E>;
  onSelectEvent?: (e: E) => void;
  draggable?: boolean;
  canDrag?: (e: E) => boolean;
  onEventDrop?: (e: E, start: Date, end: Date) => void;
}) {
  const { components, defaultDate, views } = useMemo(
    () => ({
      components: {
        // timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: moment(_defaultDate || undefined).toDate(),
      views: [Views.MONTH, Views.AGENDA], // Object.keys(Views).map((k) => Views[k]),
    }),
    [_defaultDate]
  );

  const CCalendar = !draggable ? BCalendar : DnDCalendar;

  const onEventDropRef = useRef(onEventDrop);
  onEventDropRef.current = onEventDrop;

  const _onEventDrop = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      onEventDropRef.current && onEventDropRef.current(event, start, end);
    },
    []
  );

  const otherProps: any = {};

  if (draggable) {
    if (canDrag) {
      otherProps.draggableAccessor = canDrag;
    } else {
      otherProps.draggableAccessor = () => true;
    }
    otherProps.onEventDrop = _onEventDrop;
    otherProps.resizable = false;
  }

  return (
    <div style={style}>
      <CCalendar
        components={components}
        defaultDate={defaultDate}
        events={_toAny(events || [])}
        localizer={localizer}
        messages={messages}
        showMultiDayTimes
        step={60}
        views={views}
        startAccessor={getAccessor("getStart", accessors)}
        endAccessor={getAccessor("getEnd", accessors)}
        titleAccessor={getAccessor("getTitle", accessors)}
        allDayAccessor={getAccessor("isAllDay", accessors)}
        resourceIdAccessor={getAccessor("getId", accessors)}
        eventPropGetter={getAccessor0_3("getEventProps", accessors)}
        // onShowMore={() => false}
        showAllEvents
        // popup
        onSelectEvent={onSelectEvent}
        {...otherProps}
      />
    </div>
  );
}

function _toAny(o): any {
  return o;
}
