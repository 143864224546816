const baseUrl = (function () {
  let script;
  if (document.currentScript) {
    script = document.currentScript;
  } else {
    const scripts = document.getElementsByTagName("script");
    const index = scripts.length - 1;
    script = scripts[index];
  }
  const src = script.src;
  if (!src) {
    return "";
  }
  const url = new URL(src);
  const baseUrl = url.origin;
  return baseUrl;
})();

export default baseUrl;
