import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { DataTableBodyCell } from "./DataTableBodyCell";
import { useDataTableContext } from "app/contexts/DataTableContext";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { useDataSource } from "app/hooks/DataSource/DataSource";

type DataTableRowProps = {
  row: any;
  rowIndex: number;
};

const useStyles = makeStyles((theme) => ({
  expandedRowParent: {
    "& > td": {
      borderBottom: "none",
    },
  },
}));

export function DataTableRow({ row, rowIndex }: DataTableRowProps) {
  const classes = useStyles();

  const {
    source,
    columns,
    totalColumns,
    expandableRows,
    isRowExpandable,
    renderExpandedRow,
    rowSourceOptions,
    rowsDefaultExpanded,
    sourceKey,
  } = useDataTableContext();

  const [isExpanded, setIsExpanded] = useState(rowsDefaultExpanded);

  const rowSource = useDataSource({
    parent: source,
    pathInParent: sourceKey ? [sourceKey, { id: row.id }] : [{ id: row.id }],
    itemId: row.id,
    initialData: {},
    ...rowSourceOptions,
  });

  return (
    <React.Fragment key={rowIndex}>
      <TableRow
        className={clsx(isExpanded && classes.expandedRowParent)}
        // {...bodyClasses}
        // options={options}
        // rowSelected={isRowSelected}
        // isRowSelectable={isRowSelectable}
        // onClick={this.handleRowClick.bind(null, row, { rowIndex, dataIndex })}
        // className={clsx({
        //   [classes.lastStackedCell]:
        //     options.responsive === 'vertical' ||
        //     options.responsive === 'stacked' ||
        //     options.responsive === 'stackedFullWidth',
        //   [classes.lastSimpleCell]: options.responsive === 'simple',
        //   [bodyClasses.className]: bodyClasses.className,
        // })}
      >
        {/* <TableSelectCell
                    onChange={this.handleRowSelect.bind(null, {
                      index: this.getRowIndex(rowIndex),
                      dataIndex: dataIndex,
                    })}
                    onExpand={toggleExpandRow.bind(null, {
                      index: this.getRowIndex(rowIndex),
                      dataIndex: dataIndex,
                    })}
                    fixedHeader={options.fixedHeader}
                    fixedSelectColumn={options.fixedSelectColumn}
                    checked={isRowSelected}
                    expandableOn={options.expandableRows}
                    selectableOn={options.selectableRows}
                    selectableRowsHideCheckboxes={options.selectableRowsHideCheckboxes}
                    isRowExpanded={this.isRowExpanded(dataIndex)}
                    isRowSelectable={isRowSelectable}
                    dataIndex={dataIndex}
                    id={'MUIDataTableSelectCell-' + dataIndex}
                    components={components}
                  /> */}
        {expandableRows && isRowExpandable(rowIndex, row, rowSource) && (
          <ExpandRowToggleCell
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        )}

        {columns.map((column, index) => {
          const value = rowSource.getValue(column.path);
          const cellContent = column.renderCell
            ? column.renderCell(value, row, rowIndex, rowSource)
            : value;

          return (
            <DataTableBodyCell
              // {...(columns[column.index].setCellProps
              //   ? columns[column.index].setCellProps(column.value, dataIndex, column.index) || {}
              //   : {})}
              // data-testid={`MuiDataTableBodyCell-${column.index}-${rowIndex}`}
              // dataIndex={dataIndex}
              // rowIndex={rowIndex}
              // colIndex={column.index}
              // columnHeader={columns[column.index].label}
              // print={columns[column.index].print}
              // options={options}
              // tableId={tableId}
              key={index}
              column={column}
            >
              {cellContent}
            </DataTableBodyCell>
          );
        })}
      </TableRow>
      {expandableRows &&
        isRowExpandable(rowIndex, row, rowSource) &&
        isExpanded && (
          <TableRow>
            <TableCell colSpan={totalColumns} style={{ padding: 0 }}>
              {renderExpandedRow(rowIndex, row, rowSource)}
            </TableCell>
          </TableRow>
        )}
    </React.Fragment>
  );
}

function ExpandRowToggleCell({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (exp: boolean) => void;
}) {
  return (
    <TableCell style={{ maxWidth: 24, paddingLeft: 0 }}>
      <IconButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
  );
}
