import React, { useEffect, useMemo, useState } from "react";
import { Dialog, Divider, Grid, TextField, Tooltip } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import { every, some } from "lodash";
import AbstractTabellaFatture from "./AbstractTabellaFatture";
import DateInput from "app/components/common/DateInput";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import { useApi } from "app/hooks/useApi";
import { getFatturaMago as getFatturaMagoAction } from "app/actions";
import { useToast } from "app/hooks/useToast";
import { isFatturaPagata } from "common/mago";

export default function DialogFattura(props) {
  const hasPermission = useCheckPermission();

  const { dialog, fatturaSource, isApprovare, onDeleteFatturaAnnullata } =
    props;
  const fattura = fatturaSource.data;

  const aggregatedData = useMemo(() => {
    return dialog.isOpen && fattura ? [fattura] : [];
  }, [dialog.isOpen, fattura]);

  const [pagamentiDaRimuovere, setPagamentiDaRimuovere] = useState({});

  const changeImportoEffettivo = (_, indexP, newValue) => {
    let importoMaturato = 0;
    let importoEffettivo = 0;

    let newStato;

    const pagamenti = fattura.pagamenti
      .filter((p) => !pagamentiDaRimuovere[p.id])
      .map((p, i) => {
        if (i === indexP) {
          newStato =
            p.importoMaturato === newValue ? "maturato" : "in-approvazione";
          p = {
            ...p,
            importoEffettivo: newValue,
            stato: newStato,
          };
        }

        importoMaturato += p.importoMaturato;
        importoEffettivo += p.importoEffettivo;

        return p;
      });

    const pagamentiTuttiApprovati = every(
      pagamenti,
      (p) => p.stato === "approvato" || p.stato === "maturato"
    );
    const newStatoFattura = pagamentiTuttiApprovati
      ? "approvata"
      : "in-approvazione";

    fatturaSource.changeValue(
      ["pagamenti", indexP, "importoEffettivo"],
      newValue
    );
    fatturaSource.changeValue(["pagamenti", indexP, "stato"], newStato);
    fatturaSource.changeValue("importoMaturato", importoMaturato);
    fatturaSource.changeValue("importoEffettivo", importoEffettivo);
    fatturaSource.changeValue("stato", newStatoFattura);
    fatturaSource.changeValue("dataFattura", null);
  };

  const approvaTutto = () => {
    for (const indexP in fattura.pagamenti) {
      fatturaSource.changeValue(["pagamenti", indexP, "stato"], "approvato");
    }
    fatturaSource.changeValue("stato", "approvata");
  };

  const onFatturaNoteChanged = (index, newValue) => {
    fatturaSource.changeValue("note", newValue);
  };

  const onPagamentoNoteChanged = (index, indexP, newValue) => {
    fatturaSource.changeValue(["pagamenti", indexP, "note"], newValue);
  };

  const isPagamentoRemoved = (_, indexP) => {
    const pagamento = fattura.pagamenti[indexP];
    return pagamentiDaRimuovere[pagamento.id];
  };
  const toggleRemovePagamento = (_, indexP, removed) => {
    const pagamento = fattura.pagamenti[indexP];
    setPagamentiDaRimuovere((old) => {
      return {
        ...old,
        [pagamento.id]: removed,
      };
    });

    let importoMaturato = 0;
    let importoEffettivo = 0;
    fattura.pagamenti
      .filter((p, i) => (i === indexP ? !removed : !pagamentiDaRimuovere[p.id]))
      .forEach((p) => {
        importoMaturato += p.importoMaturato;
        importoEffettivo += p.importoEffettivo;
      });

    fatturaSource.changeValue("importoMaturato", importoMaturato);
    fatturaSource.changeValue("importoEffettivo", importoEffettivo);
  };

  const showToast = useToast();
  const getFatturaMagoApi = useApi(getFatturaMagoAction, {
    onError: () => {
      showToast("Errore durante il download della fattura Mago", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });
  const [inviataOPagataInMago, setInviataOPagataInMago] = useState(null);
  useEffect(() => {
    if (
      !fattura.magoFattura ||
      fattura.stato === "inviata" ||
      fattura.stato === "pagata"
    ) {
      setInviataOPagataInMago(false);
      return;
    }

    setInviataOPagataInMago(null);
    getFatturaMagoApi.callApi(fattura.id).then((data) => {
      const fatturaInMago = data.data;
      const inviataOPagata =
        fatturaInMago.sendedToSDI || isFatturaPagata(fatturaInMago);
      setInviataOPagataInMago(inviataOPagata);
    });
  }, [fattura.id]);

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth
      maxWidth="lg"
      open={dialog.isOpen}
      scroll="paper"
    >
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          Fattura{" "}
          {fatturaSource.getValue("magoFattura") ? null : (
            <Tooltip title="Fattura non creata in Mago: se la fattura è in stato 'approvata', verrà creata a breve.">
              <CloudOffIcon />
            </Tooltip>
          )}
        </div>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <DateInput
              label="Data fattura"
              variant="outlined"
              inputVariant="outlined"
              fullWidth
              size="small"
              disabled
              readOnly
              source={fatturaSource}
              sourceKey="dataFattura"
              maxDate={moment().endOf("day")}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              disabled
              readOnly
              fullWidth
              label="Codice interno"
              type="text"
              value={"#" + fatturaSource.getValue("id", "")}
              variant="outlined"
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              disabled
              readOnly
              error={
                fatturaSource.getValidationErrors("numeroFattura").length > 0
              }
              fullWidth
              helperText={fatturaSource.getValidationErrors("numeroFattura")[0]}
              label="Numero fattura"
              onBlur={() => {
                fatturaSource.runValidation("numeroFattura");
              }}
              onChange={(event) => {
                const value = event.target.value;
                fatturaSource.changeValue("numeroFattura", value);
              }}
              type="text"
              value={fatturaSource.getValue("numeroFattura", "")}
              variant="outlined"
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <DateInput
              label="Data pagamento"
              variant="outlined"
              inputVariant="outlined"
              fullWidth
              size="small"
              disabled
              readOnly
              source={fatturaSource}
              sourceKey="dataPagamento"
              maxDate={moment().endOf("day")}
            />
          </Grid>
        </Grid>

        {fatturaSource.getValue("stato") === "pagata" ? (
          <div>
            <Alert color="success">Fattura Pagata!</Alert>
          </div>
        ) : fatturaSource.getValue("stato") === "inviata" ? (
          <div>
            <Alert color="success">Fattura Inviata!</Alert>
          </div>
        ) : fatturaSource.getValue("stato") === "annullata" ? (
          <div>
            <Alert color="warning">Fattura annullata</Alert>
          </div>
        ) : fatturaSource.getValue("stato") !== "approvata" ? (
          <div>
            <Alert severity="warning">
              Fattura non da inviare! Non tutti i pagamenti della fattura sono
              stati approvati
            </Alert>
          </div>
        ) : null}

        <Divider style={{ marginTop: 20, marginBottom: 23 }} />

        <AbstractTabellaFatture
          fatture={aggregatedData}
          canEditImportoEffettivo={
            inviataOPagataInMago === false &&
            fatturaSource.getValue("stato") !== "pagata" &&
            fatturaSource.getValue("stato") !== "inviata" &&
            fatturaSource.getValue("stato") !== "annullata"
          }
          onImportoEffettivoChange={changeImportoEffettivo}
          onFatturaNoteChanged={onFatturaNoteChanged}
          onPagamentoNoteChanged={onPagamentoNoteChanged}
          canRemovePagamento={
            inviataOPagataInMago === false &&
            fatturaSource.getValue("stato") !== "pagata" &&
            fatturaSource.getValue("stato") !== "inviata"
          }
          onRemovePagamento={toggleRemovePagamento}
          isPagamentoRemoved={isPagamentoRemoved}
        />
      </DialogContent>

      <DialogActions>
        {fatturaSource.getValue("stato") === "annullata" &&
          onDeleteFatturaAnnullata && (
            <ColoredButton
              onClick={async () => {
                const ok = window.confirm("Eliminare la fattura?");
                if (!ok) {
                  return;
                }
                await onDeleteFatturaAnnullata();
                dialog.close();
              }}
              color="primary"
            >
              Elimina fattura
            </ColoredButton>
          )}

        <div
          style={{
            flexGrow: 1,
          }}
        />

        <ColoredButton
          onClick={() => {
            fatturaSource.cancel();
            dialog.close();
          }}
          color="secondary"
        >
          Chiudi
        </ColoredButton>

        {
          // fatturaSource.getValue("stato") !== "inviata" &&
          fatturaSource.getValue("stato") !== "annullata" && (
            <ColoredButton
              disabled={
                fatturaSource.loading ||
                fatturaSource.editing ||
                (isApprovare && !hasPermission("approva_pagamenti"))
              }
              onClick={() => {
                if (some(pagamentiDaRimuovere, (v) => v)) {
                  const pagamentiAfterDeletion = fattura.pagamenti.filter(
                    (x) => !pagamentiDaRimuovere[x.id]
                  );
                  const userConfirmsAction = window.confirm(
                    pagamentiAfterDeletion.length === 0
                      ? "Tutti i pagamenti verranno rimossi e la fattura verrà annullata. Continuare?"
                      : "I pagamenti deselezionati verranno rimossi dalla fattura. Continuare?"
                  );
                  if (!userConfirmsAction) {
                    return;
                  }
                }

                fatturaSource.changeValue(
                  "pagamentiDaRimuovere",
                  pagamentiDaRimuovere
                );

                if (isApprovare) {
                  approvaTutto();
                  setTimeout(() => {
                    fatturaSource.commit();
                  }, 10);
                  return;
                }
                setTimeout(() => {
                  fatturaSource.commit();
                }, 10);
              }}
              color="primary"
            >
              Salva {isApprovare ? " (e approva)" : null}
            </ColoredButton>
          )
        }
      </DialogActions>
    </Dialog>
  );
}
