import { useEffect, useState } from "react";

export function extractURLSearchValue(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function extractURLSearchValueAsNumber(
  name,
  url = window.location.href
) {
  const value = extractURLSearchValue(name, url);
  return value ? parseFloat(value) || null : null;
}

export function useEffectOnSearchValue(name, func, options) {
  const waitForSourceLoaded = options?.waitForSourceLoaded;

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (done) {
      return;
    }
    if (waitForSourceLoaded) {
      if (waitForSourceLoaded.loading) {
        return;
      }
    }
    setDone(true);
    const v = extractURLSearchValue(name);
    if (v) {
      setTimeout(() => {
        func();
      }, 10);
    }
  }, [waitForSourceLoaded?.loading]);
}
