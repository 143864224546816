import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDialog } from "app/hooks/useDialog";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getCliente as getClienteAction,
  editCliente as editClienteAction,
  deleteCliente as deleteClienteAction,
  getNomiDocumento as getNomiDocumentoAction,
} from "app/actions";
import DimensioneAzienda from "app/constants/DimensioneAzienda";
import AtokaSearch from "app/components/Clienti/AtokaSearch";
import {
  Container,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import BackButton from "app/components/common/BackButton";
import StatoClienteBadge from "app/components/common/StatoClienteBadge";
import { ColoredButton } from "app/elements/ColoredButton";
import LaunchIcon from "@material-ui/icons/Launch";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FormCliente from "app/components/Clienti/FormCliente";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import ContattoPicker from "app/components/common/ContattoPicker";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import AddIcon from "@material-ui/icons/Add";
import DateInput from "app/components/common/DateInput";
import NumberInput from "app/components/common/NumberInput";
import { AsyncSelect } from "app/elements/AsyncSelect";
import ClienteMandatiTable from "./ClienteMandatiTable";
import DizionarioSelect from "app/components/common/DizionarioSelect";
import FileBox from "app/components/common/FileBox";
import moment from "moment";
import FattureTablePerCliente from "app/components/Fatture/FattureTablePerCliente";
import ClienteInterazioni from "app/components/Clienti/ClienteInterazioni";
import DialogDeleteCliente from "app/components/Clienti/DialogDeleteCliente";
import {
  extractURLSearchValue,
  useEffectOnSearchValue,
} from "../../utils/extractURLSearchValue";
import RichTextEditor from "app/components/common/RichTextEditor";
import "./Cliente.scss";

export default function Cliente(props) {
  const hasPermission = useCheckPermission();
  const showToast = useToast();
  const history = useHistory();

  const { id } = props.useIdFromProps ? props : useParams();

  const [editing, setEditing] = useState(false);
  const [openAtokaUpdate, setOpenAtokaUpdate] = useState(false);

  const readOnly = !editing;

  const variant = readOnly ? "standard" : "outlined";

  const deleteDialog = useDialog();

  const cliente = useDataSource({
    initialData: {},
    itemId: id,
    loadAction: getClienteAction,
    onLoadError: () => {
      showToast("Errore durante il caricamento del cliente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editClienteAction,
    onEditSuccess: (response) => {
      setEditing(false);

      setOpenAtokaUpdate(false);

      showToast("Cliente modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: () => {
      showToast("Errore durante il salvataggio", { color: "error" });
    },
    deleteAction: deleteClienteAction,
    onDeleteSuccess: () => {
      showToast("Cliente eliminato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/aziende");
    },
    onDeleteError: (response) => {
      showToast(
        response.message
          ? `Errore: ${response.message}`
          : "Errore durante l'eliminazione del cliente",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  const referenti = useDataSource({
    parent: cliente,
    pathInParent: ["referenti"],
    initialData: [],
  });

  const banche = useDataSource({
    parent: cliente,
    pathInParent: ["banche"],
    initialData: [],
  });

  const documenti = useDataSource({
    parent: cliente,
    pathInParent: ["documenti"],
    initialData: [],
  });

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_azienda") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_azienda")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const jumpToInterazioni = useMemo(
    () => props.interazioni ?? extractURLSearchValue("interazioni") ?? false,
    []
  );
  const interazioniRef = useRef();

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_azienda"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }

  function handleAggiornaConAtokaClick() {
    const ultimoDownload = cliente.getValue("atokaUltimoDownload");
    const showWarning =
      ultimoDownload && moment().diff(moment(ultimoDownload), "days") < 30;
    if (showWarning) {
      const message = `L'ultimo aggiornamento di questo cliente con i dati di Atoka è stato effettuato il ${moment(
        ultimoDownload
      ).format(
        "DD/MM/YYYY"
      )}. Sei sicuro di voler aggiornare nuovamente il cliente?`;
      if (!window.confirm(message)) {
        return;
      }
    }

    setOpenAtokaUpdate(true);
  }

  return (
    <div>
      {cliente.loading ? (
        <div>Caricamento...</div>
      ) : (
        <div>
          {cliente.loadError ? (
            <div>{cliente.loadError.message || "Errore nel caricamento"} </div>
          ) : (
            <Container disableGutters={true} fixed={false} maxWidth={false}>
              <AtokaSearch
                key={openAtokaUpdate}
                isUpdate={true}
                dataSource={cliente}
                open={openAtokaUpdate}
                cancel={() => {
                  setOpenAtokaUpdate(false);
                }}
                partitaIva={cliente.getValue("partitaIva")}
              />
              <h2
                className="page-title-header page-title-header-sticky"
                style={{
                  marginTop: -20,
                  marginBottom: 0,
                }}
              >
                <BackButton defaultBackLink="" />
                Azienda
              </h2>
              <div
                className="page-title-header-sticky-buttons-row"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StatoClienteBadge stato={cliente.getValue("stato")} />
                <h1
                  style={{
                    display: "inline",
                    flexGrow: 1,
                  }}
                >
                  {cliente.getValue("ragioneSociale")}{" "}
                </h1>
                {props.isSideModal && readOnly && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<LaunchIcon />}
                    variant="outlined"
                    to={`/azienda/${id}?edit=true`}
                    component={Link}
                  >
                    Apri
                  </ColoredButton>
                )}
                {editing ? (
                  <>
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        cliente.cancel();

                        setEditing(false);
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<CancelIcon />}
                      variant="outlined"
                    >
                      Annulla modifiche
                    </ColoredButton>

                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        cliente.commit();
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                    >
                      Salva
                    </ColoredButton>
                  </>
                ) : hasPermission("modifica_azienda") ? (
                  <>
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        setEditing(true);
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<EditIcon />}
                      variant="outlined"
                    >
                      Modifica
                    </ColoredButton>

                    {hasPermission("scarica_dati_atoka") &&
                      window.atokaEnabled && (
                        <ColoredButton
                          style={{
                            marginLeft: 8,
                          }}
                          disabled={false}
                          onClick={handleAggiornaConAtokaClick}
                          color="secondary"
                          endIcon={null}
                          fullWidth={false}
                          size="small"
                          startIcon={null}
                          variant="outlined"
                        >
                          Aggiorna con Atoka
                        </ColoredButton>
                      )}
                  </>
                ) : null}
                {!props.isSideModal && hasPermission("elimina_azienda") && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      deleteDialog.open();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                  >
                    Elimina
                  </ColoredButton>
                )}
              </div>
              <Card
                style={{
                  marginBottom: 16,
                }}
              >
                <CardContent>
                  <FormCliente
                    readOnly={!editing}
                    source={cliente}
                  ></FormCliente>
                </CardContent>
              </Card>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Contatti
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="accordion-contatti"
                  >
                    <Grid container={true} item={false} spacing={3}>
                      <Grid container={false} item={true} md={4} xs={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("telefono").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("telefono")[0]
                          }
                          label="Telefono 1"
                          onBlur={() => {
                            cliente.runValidation("telefono");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "telefono",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("telefono", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} xs={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("telefono2").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("telefono2")[0]
                          }
                          label="Telefono 2"
                          onBlur={() => {
                            cliente.runValidation("telefono2");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "telefono2",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("telefono2", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} xs={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={cliente.getValidationErrors("fax").length > 0}
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("fax")[0]}
                          label="Fax"
                          onBlur={() => {
                            cliente.runValidation("fax");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "fax",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("fax", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} xs={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("email").length > 0
                          }
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("email")[0]}
                          label="Email azienda"
                          onBlur={() => {
                            cliente.runValidation("email");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "email",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("email", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} xs={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={cliente.getValidationErrors("pec").length > 0}
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("pec")[0]}
                          label="PEC"
                          onBlur={() => {
                            cliente.runValidation("pec");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "pec",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("pec", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} xs={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("website").length > 0
                          }
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("website")[0]}
                          label="Website"
                          onBlur={(event) => {
                            cliente.runValidation("website");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "website",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("website", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Typography
                      style={{
                        marginTop: 22,
                      }}
                      display="block"
                      variant="subtitle1"
                    >
                      Referenti:
                    </Typography>

                    <DataTable
                      source={referenti}
                      columns={[
                        {
                          path: ["contatto", "name"],
                          header: "Contatto",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <ContattoPicker
                                variant={variant}
                                readOnly={readOnly}
                                source={rowSource}
                                sourceKey="idContatto"
                                canCreate={true}
                              />
                            );
                          },
                        },
                        {
                          path: ["ruolo"],
                          header: "Ruolo",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <DizionarioSoftInput
                                className=""
                                style={{}}
                                variant={variant}
                                readOnly={readOnly}
                                source={rowSource}
                                sourceKey="ruolo"
                                fullWidth={true}
                                dizionarioSoft="ruoloReferente"
                              />
                            );
                          },
                        },
                        {
                          path: ["email"],
                          header: "Email",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <TextField
                                disabled={
                                  rowSource.loading || rowSource.editing
                                }
                                error={
                                  rowSource.getValidationErrors("email")
                                    .length > 0
                                }
                                fullWidth={true}
                                helperText={
                                  rowSource.getValidationErrors("email")[0]
                                }
                                onBlur={(event) => {
                                  rowSource.runValidation("email");
                                }}
                                onChange={(event) => {
                                  const value = event.target.value;

                                  rowSource.changeValue(
                                    "email",

                                    value
                                  );
                                }}
                                type="text"
                                value={rowSource.getValue("email", "")}
                                variant={variant}
                                InputProps={{
                                  readOnly: readOnly,
                                }}
                              />
                            );
                          },
                        },
                        {
                          path: ["cellulare"],
                          header: "Cellulare",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 4,
                                }}
                              >
                                <TextField
                                  disabled={
                                    rowSource.loading || rowSource.editing
                                  }
                                  error={
                                    rowSource.getValidationErrors("cellulare")
                                      .length > 0
                                  }
                                  fullWidth={true}
                                  helperText={
                                    rowSource.getValidationErrors(
                                      "cellulare"
                                    )[0]
                                  }
                                  onBlur={(event) => {
                                    rowSource.runValidation("cellulare");
                                  }}
                                  onChange={(event) => {
                                    const value = event.target.value;

                                    rowSource.changeValue(
                                      "cellulare",

                                      value
                                    );
                                  }}
                                  type="text"
                                  value={rowSource.getValue("cellulare", "")}
                                  variant={variant}
                                  InputProps={{
                                    readOnly: readOnly,
                                  }}
                                />
                              </div>
                            );
                          },
                        },
                        {
                          path: ["telefono"],
                          header: "Telefono",
                          disableColumnFilter: false,
                          disableGlobalFilter: false,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 4,
                                }}
                              >
                                <TextField
                                  disabled={
                                    rowSource.loading || rowSource.editing
                                  }
                                  error={
                                    rowSource.getValidationErrors("telefono")
                                      .length > 0
                                  }
                                  fullWidth={true}
                                  helperText={
                                    rowSource.getValidationErrors("telefono")[0]
                                  }
                                  onBlur={(event) => {
                                    rowSource.runValidation("telefono");
                                  }}
                                  onChange={(event) => {
                                    const value = event.target.value;

                                    rowSource.changeValue(
                                      "telefono",

                                      value
                                    );
                                  }}
                                  type="text"
                                  value={rowSource.getValue("telefono", "")}
                                  variant={variant}
                                  InputProps={{
                                    readOnly: readOnly,
                                  }}
                                />
                              </div>
                            );
                          },
                        },
                        {
                          path: ["isRiceveInformative"],
                          header: "Riceve informative",
                          disableColumnFilter: true,
                          disableGlobalFilter: true,
                          width: 100,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={rowSource.getValue(
                                      "isRiceveInformative",
                                      false
                                    )}
                                    disabled={
                                      rowSource.loading || rowSource.editing
                                    }
                                    onChange={(event) => {
                                      const value = event.target.checked;

                                      rowSource.changeValue(
                                        "isRiceveInformative",

                                        value
                                      );
                                    }}
                                    size="small"
                                    value={rowSource.getValue(
                                      "isRiceveInformative",
                                      ""
                                    )}
                                  />
                                }
                                disabled={readOnly}
                              />
                            );
                          },
                        },
                        {
                          path: ["isConsensoGDPR"],
                          header: "Consenso GDPR",
                          disableColumnFilter: true,
                          disableGlobalFilter: true,
                          width: 100,
                          sortable: true,
                          type: "text",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={rowSource.getValue(
                                      "isConsensoGDPR",
                                      false
                                    )}
                                    disabled={
                                      rowSource.loading || rowSource.editing
                                    }
                                    onChange={(event) => {
                                      const value = event.target.checked;

                                      rowSource.changeValue(
                                        "isConsensoGDPR",

                                        value
                                      );
                                    }}
                                    size="small"
                                    value={rowSource.getValue(
                                      "isConsensoGDPR",
                                      ""
                                    )}
                                  />
                                }
                                disabled={readOnly}
                              />
                            );
                          },
                        },
                        {
                          path: ["id"],
                          header: "Azioni",
                          renderCell: (value, row, rowIndex, rowSource) => {
                            return (
                              <div>
                                <IconButton
                                  to={`/contatto/${rowSource.getValue(
                                    "idContatto"
                                  )}`}
                                  component={Link}
                                >
                                  <LaunchIcon />
                                </IconButton>
                                {editing ? (
                                  <IconButton
                                    onClick={(event) => {
                                      rowSource.delete();
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}{" "}
                              </div>
                            );
                          },
                          disableColumnFilter: true,
                          disableGlobalFilter: true,
                          width: 100,
                          sortable: true,
                          type: "text",
                        },
                      ]}
                      topToolbar={null}
                      bottomToolbar={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "0.75em",
                          }}
                        >
                          {editing ? (
                            hasPermission("modifica_referenti") ? (
                              <ColoredButton
                                style={{
                                  marginRight: 8,
                                }}
                                disabled={false}
                                onClick={(event) => {
                                  referenti.create();
                                }}
                                color="secondary"
                                endIcon={null}
                                fullWidth={false}
                                size="small"
                                startIcon={<AddIcon />}
                                variant="outlined"
                              >
                                Aggiungi referente
                              </ColoredButton>
                            ) : null
                          ) : null}{" "}
                        </div>
                      }
                      showFilterRow={false}
                      pageSize={1000000}
                      size="small"
                      expandableRows={false}
                      expandRowsOnClick={false}
                      isRowExpandable={null}
                      renderExpandedRow={null}
                      messages={{
                        noRows: "Nessun referente",
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Indirizzi
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container={true} item={false} spacing={3}>
                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("indirizzo").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("indirizzo")[0]
                          }
                          label="Indirizzo Sede Legale"
                          onBlur={(event) => {
                            cliente.runValidation("indirizzo");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "indirizzo",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("indirizzo", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={1} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={cliente.getValidationErrors("cap").length > 0}
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("cap")[0]}
                          label="CAP"
                          onBlur={(event) => {
                            cliente.runValidation("cap");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "cap",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("cap", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={2} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("comune").length > 0
                          }
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("comune")[0]}
                          label="Comune"
                          onBlur={(event) => {
                            cliente.runValidation("comune");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "comune",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("comune", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid item md={1} sm={12}>
                        <DizionarioSoftInput
                          className="input-dati-atoka"
                          size="small"
                          variant={variant}
                          readOnly={
                            readOnly || cliente.loading || cliente.editing
                          }
                          label="Provincia"
                          source={cliente}
                          sourceKey="provincia"
                          fullWidth
                          dizionarioSoft="provincia"
                          prohibitNewValues
                        />
                      </Grid>

                      <Grid item md={2} sm={12}>
                        <DizionarioSoftInput
                          className="input-dati-atoka"
                          size="small"
                          variant={variant}
                          readOnly={
                            readOnly || cliente.loading || cliente.editing
                          }
                          label="Regione"
                          source={cliente}
                          sourceKey="regione"
                          fullWidth
                          dizionarioSoft="regione"
                          prohibitNewValues
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("areaGeografica")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("areaGeografica")[0]
                          }
                          label="Area geografica"
                          onBlur={(event) => {
                            cliente.runValidation("areaGeografica");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "areaGeografica",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("areaGeografica", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container={true} item={false} spacing={3}>
                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("indirizzoFatturazione")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "indirizzoFatturazione"
                            )[0]
                          }
                          label="Indirizzo Fatturazione"
                          onBlur={(event) => {
                            cliente.runValidation("indirizzoFatturazione");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "indirizzoFatturazione",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("indirizzoFatturazione", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={1} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("capFatturazione")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("capFatturazione")[0]
                          }
                          label="CAP"
                          onBlur={(event) => {
                            cliente.runValidation("capFatturazione");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "capFatturazione",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("capFatturazione", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={2} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("comuneFatturazione")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("comuneFatturazione")[0]
                          }
                          label="Comune"
                          onBlur={(event) => {
                            cliente.runValidation("comuneFatturazione");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "comuneFatturazione",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("comuneFatturazione", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid item md={1} sm={12}>
                        <DizionarioSoftInput
                          className="input-dati-atoka"
                          size="small"
                          variant={variant}
                          readOnly={
                            readOnly || cliente.loading || cliente.editing
                          }
                          label="Provincia"
                          source={cliente}
                          sourceKey="provinciaFatturazione"
                          fullWidth
                          dizionarioSoft="provincia"
                          prohibitNewValues
                        />
                      </Grid>

                      <Grid container={false} item={true} md={2} sm={12} />

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("numeroUnitaLocali")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("numeroUnitaLocali")[0]
                          }
                          label="Unità locali oltre alla sede legale"
                          onBlur={(event) => {
                            cliente.runValidation("numeroUnitaLocali");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "numeroUnitaLocali",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("numeroUnitaLocali", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container={true} item={false} spacing={3}>
                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors(
                              "indirizzoSedeOperativa"
                            ).length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "indirizzoSedeOperativa"
                            )[0]
                          }
                          label="Indirizzo Sede operativa"
                          onBlur={(event) => {
                            cliente.runValidation("indirizzoSedeOperativa");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "indirizzoSedeOperativa",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("indirizzoSedeOperativa", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                      <Grid container={false} item={true} md={1} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("capSedeOperativa")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("capSedeOperativa")[0]
                          }
                          label="CAP"
                          onBlur={(event) => {
                            cliente.runValidation("capSedeOperativa");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "capSedeOperativa",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("capSedeOperativa", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                      <Grid container={false} item={true} md={2} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("comuneSedeOperativa")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "comuneSedeOperativa"
                            )[0]
                          }
                          label="Comune"
                          onBlur={(event) => {
                            cliente.runValidation("comuneSedeOperativa");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "comuneSedeOperativa",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("comuneSedeOperativa", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                      <Grid item md={1} sm={12}>
                        <DizionarioSoftInput
                          className="input-dati-atoka"
                          size="small"
                          variant={variant}
                          readOnly={
                            readOnly || cliente.loading || cliente.editing
                          }
                          label="Provincia"
                          source={cliente}
                          sourceKey="provinciaSedeOperativa"
                          fullWidth
                          dizionarioSoft="provincia"
                          prohibitNewValues
                        />
                      </Grid>
                      <Grid container={false} item={true} md={2} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("regioneSedeOperativa")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "regioneSedeOperativa"
                            )[0]
                          }
                          label="Regione Sede operativa"
                          onBlur={(event) => {
                            cliente.runValidation("regioneSedeOperativa");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "regioneSedeOperativa",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("regioneSedeOperativa", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                      <Grid container={false} item={true} md={3} sm={12} />
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Dettagli impresa
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container={true} item={false} spacing={3}>
                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("codiceATECO").length >
                            0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("codiceATECO")[0]
                          }
                          label="Codice ATECO"
                          onBlur={(event) => {
                            cliente.runValidation("codiceATECO");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "codiceATECO",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("codiceATECO", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={7} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors(
                              "descrizioneCodiceATECO"
                            ).length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "descrizioneCodiceATECO"
                            )[0]
                          }
                          label="Descrizione Codice ATECO"
                          onBlur={(event) => {
                            cliente.runValidation("descrizioneCodiceATECO");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "descrizioneCodiceATECO",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("descrizioneCodiceATECO", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("codiceNace").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("codiceNace")[0]
                          }
                          label="Codice NACE"
                          onBlur={(event) => {
                            cliente.runValidation("codiceNace");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "codiceNace",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("codiceNace", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("numeroRea").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("numeroRea")[0]
                          }
                          label="Numero REA"
                          onBlur={(event) => {
                            cliente.runValidation("numeroRea");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "numeroRea",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("numeroRea", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("provinciaRea").length >
                            0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("provinciaRea")[0]
                          }
                          label="Provincia REA"
                          onBlur={(event) => {
                            cliente.runValidation("provinciaRea");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "provinciaRea",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("provinciaRea", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} sm={12}>
                        <DateInput
                          className="input-dati-atoka"
                          source={cliente}
                          sourceKey="sulMercatoDal"
                          readOnly={readOnly}
                          fullWidth={true}
                          variant={variant}
                          label="Sul mercato dal"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} xs={12}>
                        <FormControlLabel
                          className="input-dati-atoka"
                          style={{
                            marginTop: 15,
                          }}
                          control={
                            <Checkbox
                              checked={cliente.getValue(
                                "startupInnovativa",
                                false
                              )}
                              disabled={cliente.loading || cliente.editing}
                              onChange={(event) => {
                                const value = event.target.checked;

                                cliente.changeValue(
                                  "startupInnovativa",

                                  value
                                );
                              }}
                              size="small"
                              value={cliente.getValue("startupInnovativa", "")}
                            />
                          }
                          disabled={readOnly}
                          label="Startup Innovativa"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} xs={12}>
                        <FormControlLabel
                          className="input-dati-atoka"
                          style={{
                            marginTop: 15,
                          }}
                          control={
                            <Checkbox
                              checked={cliente.getValue("operativa", false)}
                              disabled={cliente.loading || cliente.editing}
                              onChange={(event) => {
                                const value = event.target.checked;

                                cliente.changeValue(
                                  "operativa",

                                  value
                                );
                              }}
                              size="small"
                              value={cliente.getValue("operativa", "")}
                            />
                          }
                          disabled={readOnly}
                          label="Operativa"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={2} sm={12}>
                        <NumberInput
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("numeroDipendenti")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("numeroDipendenti")[0]
                          }
                          label="Numero dipendenti"
                          onBlur={(event) => {
                            cliente.runValidation("numeroDipendenti");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "numeroDipendenti",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("numeroDipendenti", "")}
                          variant={variant}
                          source={cliente}
                          sourceKey="numeroDipendenti"
                          readOnly={readOnly}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("dataChisuraBilancio")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "dataChisuraBilancio"
                            )[0]
                          }
                          label="Data chiusura bilancio"
                          onBlur={(event) => {
                            cliente.runValidation("dataChisuraBilancio");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "dataChisuraBilancio",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("dataChisuraBilancio", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <NumberInput
                          currency={true}
                          source={cliente}
                          className="input-dati-atoka"
                          sourceKey="ricavi"
                          readOnly={readOnly}
                          fullWidth={true}
                          variant={variant}
                          label="Ricavi"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <NumberInput
                          percent={true}
                          source={cliente}
                          sourceKey="ricaviEsteroPercentuale"
                          readOnly={readOnly}
                          fullWidth={true}
                          variant={variant}
                          label="Ricavi Estero (%)"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <NumberInput
                          currency={true}
                          source={cliente}
                          sourceKey="ricaviEstero"
                          readOnly={readOnly}
                          fullWidth={true}
                          variant={variant}
                          label="Ricavi Estero"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={6} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("gruppi").length > 0
                          }
                          fullWidth={true}
                          helperText={cliente.getValidationErrors("gruppi")[0]}
                          label="Gruppi"
                          onBlur={(event) => {
                            cliente.runValidation("gruppi");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue("gruppi", value);
                          }}
                          type="text"
                          value={cliente.getValue("gruppi", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <NumberInput
                          currency={true}
                          source={cliente}
                          className="input-dati-atoka"
                          sourceKey="ricaviDelGruppoConsolidati"
                          readOnly={readOnly}
                          fullWidth={true}
                          variant={variant}
                          label="Ricavi del gruppo (consolidati)"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <NumberInput
                          currency={true}
                          source={cliente}
                          className="input-dati-atoka"
                          sourceKey="ricaviDelGruppoCalcolati"
                          readOnly={readOnly}
                          fullWidth={true}
                          variant={variant}
                          label="Ricavi del gruppo (calcolati)"
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} sm={12}>
                        <FormControl
                          style={{
                            width: "100%",
                          }}
                          disabled={readOnly}
                          error={
                            cliente.getValidationErrors("dimensioneAzienda")
                              .length > 0
                          }
                          size="medium"
                        >
                          <InputLabel
                            error={
                              cliente.getValidationErrors("dimensioneAzienda")
                                .length > 0
                            }
                            variant={variant}
                          >
                            Dimensione azienda
                          </InputLabel>
                          <AsyncSelect
                            autoWidth={true}
                            displayEmpty={false}
                            label="Dimensione azienda"
                            native={false}
                            onChange={(event) => {
                              const value = event.target.value;

                              cliente.changeValue(
                                "dimensioneAzienda",

                                value || null
                              );

                              setTimeout(
                                () => {
                                  cliente.runValidation("dimensioneAzienda");
                                },

                                0
                              );
                            }}
                            onClose={(event) => {
                              cliente.runValidation("dimensioneAzienda");
                            }}
                            value={cliente.getValue("dimensioneAzienda") || ""}
                            variant={variant}
                            sourceVariable="cliente"
                            sourceKey="dimensioneAzienda"
                            disabled={readOnly}
                          >
                            {DimensioneAzienda.map((option, index) => {
                              return (
                                <MenuItem
                                  key={option.value}
                                  dense={false}
                                  disableGutters={false}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </AsyncSelect>
                          {cliente.getValidationErrors(
                            "dimensioneAzienda"
                          )[0] ? (
                            <FormHelperText>
                              {
                                cliente.getValidationErrors(
                                  "dimensioneAzienda"
                                )[0]
                              }
                            </FormHelperText>
                          ) : null}{" "}
                        </FormControl>
                      </Grid>

                      {}

                      <Grid container={false} item={true} md={4} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors(
                              "ratingRegioneLombardia"
                            ).length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "ratingRegioneLombardia"
                            )[0]
                          }
                          label="Rating Regione Lombardia"
                          onBlur={(event) => {
                            cliente.runValidation("ratingRegioneLombardia");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "ratingRegioneLombardia",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("ratingRegioneLombardia", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={4} sm={12}>
                        <TextField
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("codiceUnivocoSDI")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("codiceUnivocoSDI")[0]
                          }
                          label="Codice univoco SDI"
                          onBlur={(event) => {
                            cliente.runValidation("codiceUnivocoSDI");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue("codiceUnivocoSDI", value);
                          }}
                          type="text"
                          value={cliente.getValue("codiceUnivocoSDI", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Altri Dati Atoka
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container={true} item={false} spacing={3}>
                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors(
                              "atokaTelefoniVerificati"
                            ).length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "atokaTelefoniVerificati"
                            )[0]
                          }
                          label="Telefoni verificati"
                          onBlur={(event) => {
                            cliente.runValidation("atokaTelefoniVerificati");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaTelefoniVerificati",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue(
                            "atokaTelefoniVerificati",
                            ""
                          )}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("atokaFaxes").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("atokaFaxes")[0]
                          }
                          label="Faxes"
                          onBlur={(event) => {
                            cliente.runValidation("atokaFaxes");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaFaxes",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("atokaFaxes", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("atokaEcommerce")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("atokaEcommerce")[0]
                          }
                          label="Ecommerce"
                          onBlur={(event) => {
                            cliente.runValidation("atokaEcommerce");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaEcommerce",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("atokaEcommerce", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("atokaUrl").length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("atokaUrl")[0]
                          }
                          label="URL Atoka"
                          onBlur={(event) => {
                            cliente.runValidation("atokaUrl");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaUrl",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("atokaUrl", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("atokaEmailInfo")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("atokaEmailInfo")[0]
                          }
                          label="Email Info"
                          onBlur={(event) => {
                            cliente.runValidation("atokaEmailInfo");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaEmailInfo",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("atokaEmailInfo", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("atokaEmailAcquisti")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors("atokaEmailAcquisti")[0]
                          }
                          label="Email Acquisti"
                          onBlur={(event) => {
                            cliente.runValidation("atokaEmailAcquisti");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaEmailAcquisti",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("atokaEmailAcquisti", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors("atokaEmailDirezione")
                              .length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "atokaEmailDirezione"
                            )[0]
                          }
                          label="Email Direzione"
                          onBlur={(event) => {
                            cliente.runValidation("atokaEmailDirezione");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaEmailDirezione",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue("atokaEmailDirezione", "")}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>

                      <Grid container={false} item={true} md={3} sm={12}>
                        <TextField
                          className="input-dati-atoka"
                          disabled={cliente.loading || cliente.editing}
                          error={
                            cliente.getValidationErrors(
                              "atokaEmailAmministrazione"
                            ).length > 0
                          }
                          fullWidth={true}
                          helperText={
                            cliente.getValidationErrors(
                              "atokaEmailAmministrazione"
                            )[0]
                          }
                          label="Email Amministrazione"
                          onBlur={(event) => {
                            cliente.runValidation("atokaEmailAmministrazione");
                          }}
                          onChange={(event) => {
                            const value = event.target.value;

                            cliente.changeValue(
                              "atokaEmailAmministrazione",

                              value
                            );
                          }}
                          type="text"
                          value={cliente.getValue(
                            "atokaEmailAmministrazione",
                            ""
                          )}
                          variant={variant}
                          InputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Conti bancari
                </AccordionSummary>

                <AccordionDetails>
                  <DataTable
                    source={banche}
                    columns={[
                      {
                        path: ["banca"],
                        header: "Banca",
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        sortable: true,
                        type: "text",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <TextField
                              disabled={rowSource.loading || rowSource.editing}
                              error={
                                rowSource.getValidationErrors("banca").length >
                                0
                              }
                              fullWidth={true}
                              helperText={
                                rowSource.getValidationErrors("banca")[0]
                              }
                              onBlur={(event) => {
                                rowSource.runValidation("banca");
                              }}
                              onChange={(event) => {
                                const value = event.target.value;

                                rowSource.changeValue(
                                  "banca",

                                  value
                                );
                              }}
                              type="text"
                              value={rowSource.getValue("banca", "")}
                              variant={variant}
                              InputProps={{
                                readOnly: readOnly,
                              }}
                            />
                          );
                        },
                      },
                      {
                        path: ["iban"],
                        header: "IBAN",
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        sortable: true,
                        type: "text",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <TextField
                              disabled={rowSource.loading || rowSource.editing}
                              error={
                                rowSource.getValidationErrors("iban").length > 0
                              }
                              fullWidth={true}
                              helperText={
                                rowSource.getValidationErrors("iban")[0]
                              }
                              onBlur={(event) => {
                                rowSource.runValidation("iban");
                              }}
                              onChange={(event) => {
                                const value = event.target.value;

                                rowSource.changeValue(
                                  "iban",

                                  value
                                );
                              }}
                              type="text"
                              value={rowSource.getValue("iban", "")}
                              variant={variant}
                              InputProps={{
                                readOnly: readOnly,
                              }}
                            />
                          );
                        },
                      },
                      {
                        path: ["intestatario"],
                        header: "Intestatario",
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        sortable: true,
                        type: "text",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <TextField
                              disabled={rowSource.loading || rowSource.editing}
                              error={
                                rowSource.getValidationErrors("intestatario")
                                  .length > 0
                              }
                              fullWidth={true}
                              helperText={
                                rowSource.getValidationErrors("intestatario")[0]
                              }
                              onBlur={(event) => {
                                rowSource.runValidation("intestatario");
                              }}
                              onChange={(event) => {
                                const value = event.target.value;

                                rowSource.changeValue(
                                  "intestatario",

                                  value
                                );
                              }}
                              type="text"
                              value={rowSource.getValue("intestatario", "")}
                              variant={variant}
                              InputProps={{
                                readOnly: readOnly,
                              }}
                            />
                          );
                        },
                      },
                      {
                        path: ["swift"],
                        header: "Swift/BIC",
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        sortable: true,
                        type: "text",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <TextField
                              disabled={rowSource.loading || rowSource.editing}
                              error={
                                rowSource.getValidationErrors("swift").length >
                                0
                              }
                              fullWidth={true}
                              helperText={
                                rowSource.getValidationErrors("swift")[0]
                              }
                              onBlur={(event) => {
                                rowSource.runValidation("swift");
                              }}
                              onChange={(event) => {
                                const value = event.target.value;

                                rowSource.changeValue(
                                  "swift",

                                  value
                                );
                              }}
                              type="text"
                              value={rowSource.getValue("swift", "")}
                              variant={variant}
                              InputProps={{
                                readOnly: readOnly,
                              }}
                            />
                          );
                        },
                      },
                      {
                        path: ["id"],
                        header: "Azioni",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <div>
                              <IconButton
                                onClick={(event) => {
                                  rowSource.delete();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          );
                        },
                        disableColumnFilter: true,
                        disableGlobalFilter: true,
                        sortable: true,
                        type: "text",
                      },
                    ]}
                    topToolbar={null}
                    bottomToolbar={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "0.75em",
                        }}
                      >
                        {editing ? (
                          <ColoredButton
                            style={{
                              marginRight: 8,
                            }}
                            disabled={false}
                            onClick={(event) => {
                              banche.create();
                            }}
                            color="secondary"
                            endIcon={null}
                            fullWidth={false}
                            size="small"
                            startIcon={<AddIcon />}
                            variant="outlined"
                          >
                            Aggiungi conto bancario
                          </ColoredButton>
                        ) : null}{" "}
                      </div>
                    }
                    showFilterRow={false}
                    pageSize={1000000}
                    size="small"
                    expandableRows={false}
                    expandRowsOnClick={false}
                    isRowExpandable={null}
                    renderExpandedRow={null}
                    messages={{
                      noRows: "Nessun banca",
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Mandati/Offerte
                </AccordionSummary>

                <AccordionDetails>
                  <div>
                    <ColoredButton
                      style={{
                        marginBottom: 10,
                      }}
                      disabled={false}
                      onClick={null}
                      color="default"
                      fullWidth={false}
                      size="medium"
                      variant="outlined"
                      to={`/mandati/new?clienteId=${cliente.getValue("id")}`}
                      component={Link}
                    >
                      Crea offerta/mandato
                    </ColoredButton>

                    <ClienteMandatiTable clienteId={cliente.getValue("id")} />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Documenti
                </AccordionSummary>

                <AccordionDetails>
                  <DataTable
                    source={documenti}
                    columns={[
                      {
                        path: ["nome", "nome"],
                        header: "Nome",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <DizionarioSelect
                              source={rowSource}
                              sourceKey="idNomeDocumento"
                              readOnly={readOnly}
                              loadDizionarioAction={getNomiDocumentoAction}
                            />
                          );
                        },
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        sortable: false,
                        type: "text",
                      },
                      {
                        path: ["allegato"],
                        header: "Allegato",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <FileBox
                              source={rowSource}
                              sourceKey="file"
                              readOnly={readOnly}
                            />
                          );
                        },
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        sortable: false,
                        type: "text",
                      },
                      {
                        path: ["file", "versioneCorrente", "createdAt"],
                        header: "Data caricamento",
                        renderCell: (value) => {
                          return value
                            ? moment(value).format("DD/MM/YYYY")
                            : "";
                        },
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        width: 190,
                        sortable: false,
                        type: "text",
                      },
                      {
                        path: ["dataScadenza"],
                        header: "Data scadenza",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <DateInput
                              className=""
                              inputVariant="outlined"
                              fullWidth={true}
                              size="small"
                              variant="outlined"
                              disabled={readOnly}
                              source={rowSource}
                              sourceKey="dataScadenza"
                            />
                          );
                        },
                        disableColumnFilter: false,
                        disableGlobalFilter: false,
                        width: 190,
                        sortable: false,
                        type: "text",
                      },
                      {
                        path: ["azioni"],
                        header: "",
                        renderCell: (value, row, rowIndex, rowSource) => {
                          return (
                            <div>
                              {!readOnly ? (
                                <IconButton
                                  onClick={(event) => {
                                    rowSource.delete();
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}{" "}
                            </div>
                          );
                        },
                        disableColumnFilter: true,
                        disableGlobalFilter: true,
                        width: 60,
                        sortable: false,
                        type: "text",
                      },
                    ]}
                    bottomToolbar={[
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "0.75em",
                        }}
                      >
                        {editing ? (
                          <ColoredButton
                            style={{
                              marginRight: 8,
                            }}
                            disabled={false}
                            onClick={(event) => {
                              documenti.create();
                            }}
                            color="secondary"
                            endIcon={null}
                            fullWidth={false}
                            size="small"
                            startIcon={<AddIcon />}
                            variant="outlined"
                          >
                            Aggiungi documento
                          </ColoredButton>
                        ) : null}{" "}
                      </div>,
                    ]}
                    showFilterRow={false}
                    pageSize={1000000}
                    size="small"
                    expandableRows={false}
                    expandRowsOnClick={false}
                    isRowExpandable={null}
                    renderExpandedRow={null}
                    messages={{
                      noRows: "Nessun documento",
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              {hasPermission("visualizza_fattura") ? (
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Fatture
                  </AccordionSummary>

                  <AccordionDetails>
                    <FattureTablePerCliente
                      {...{}}
                      idCliente={cliente.getValue("id")}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : null}{" "}
              {}
              <Accordion
                defaultExpanded={jumpToInterazioni}
                ref={interazioniRef}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Contatti/Interazioni
                </AccordionSummary>

                <AccordionDetails>
                  <ClienteInterazioni
                    clienteId={cliente.getValue("id")}
                    aziendaSource={cliente}
                    onLoadSuccess={() => {
                      if (jumpToInterazioni && interazioniRef.current) {
                        interazioniRef.current.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }}
                    onEditSuccess={props.onInterazioniEditSuccess}
                    onCreateSuccess={props.onInterazioneCreateSuccess}
                    onDeleteSuccess={props.onInterazioneDeleteSuccess}
                  />
                </AccordionDetails>
              </Accordion>
              <DialogDeleteCliente dialog={deleteDialog} source={cliente} />
            </Container>
          )}{" "}
        </div>
      )}{" "}
    </div>
  );
}
