import React from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { createContatto as createContattoAction } from "app/actions";
import { Dialog, Grid, TextField } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";

export default function CreateContattoDialog(props) {
  const showToast = useToast();

  const contatto = useDataSource({
    initialData: {
      idCliente: props.idCliente,
      stato: "Contatto",
    },
    createAction: createContattoAction,
    onCreateSuccess: (response) => {
      props.onContattoCreated(response.data);

      showToast("Contatto cliente creato con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onCreateError: (response) => {
      showToast("Errore durante la creazione del contatto cliente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  return (
    <Dialog maxWidth="md" open={props.dialog.isOpen}>
      <DialogTitle>Crea nuovo contatto cliente</DialogTitle>

      <DialogContent>
        <Grid container={true} item={false} spacing={2}>
          <Grid container={false} item={true} sm={3} xs={12}>
            <DizionarioSoftInput
              className=""
              style={{}}
              variant="outlined"
              readOnly={false}
              source={contatto}
              sourceKey="titolo"
              label="Titolo"
              fullWidth={true}
              dizionarioSoft="titoloContatto"
            />
          </Grid>

          <Grid container={false} item={true} sm={4} xs={12}>
            <TextField
              disabled={contatto.loading || contatto.editing}
              error={contatto.getValidationErrors("nome").length > 0}
              fullWidth={true}
              helperText={contatto.getValidationErrors("nome")[0]}
              label="Nome"
              onBlur={(event) => {
                contatto.runValidation("nome");
              }}
              onChange={(event) => {
                const value = event.target.value;

                contatto.changeValue(
                  "nome",

                  value
                );
              }}
              size="small"
              type="text"
              value={contatto.getValue("nome", "")}
              variant="outlined"
              InputProps={{
                readOnly: false,
              }}
            />
          </Grid>

          <Grid container={false} item={true} sm={5} xs={12}>
            <TextField
              disabled={contatto.loading || contatto.editing}
              error={contatto.getValidationErrors("cognome").length > 0}
              fullWidth={true}
              helperText={contatto.getValidationErrors("cognome")[0]}
              label="Cognome"
              onBlur={(event) => {
                contatto.runValidation("cognome");
              }}
              onChange={(event) => {
                const value = event.target.value;

                contatto.changeValue(
                  "cognome",

                  value
                );
              }}
              size="small"
              type="text"
              value={contatto.getValue("cognome", "")}
              variant="outlined"
              InputProps={{
                readOnly: false,
              }}
            />
          </Grid>

          <Grid container={false} item={true} sm={6} xs={12}>
            <TextField
              disabled={contatto.loading || contatto.editing}
              error={contatto.getValidationErrors("email").length > 0}
              fullWidth={true}
              helperText={contatto.getValidationErrors("email")[0]}
              label="Email"
              onBlur={(event) => {
                contatto.runValidation("email");
              }}
              onChange={(event) => {
                const value = event.target.value;

                contatto.changeValue(
                  "email",

                  value
                );
              }}
              size="small"
              type="text"
              value={contatto.getValue("email", "")}
              variant="outlined"
              InputProps={{
                readOnly: false,
              }}
            />
          </Grid>

          <Grid container={false} item={true} sm={6} xs={12}>
            <TextField
              disabled={contatto.loading || contatto.editing}
              error={contatto.getValidationErrors("telefono").length > 0}
              fullWidth={true}
              helperText={contatto.getValidationErrors("telefono")[0]}
              label="Telefono"
              onBlur={(event) => {
                contatto.runValidation("telefono");
              }}
              onChange={(event) => {
                const value = event.target.value;

                contatto.changeValue(
                  "telefono",

                  value
                );
              }}
              size="small"
              type="text"
              value={contatto.getValue("telefono", "")}
              variant="outlined"
              InputProps={{
                readOnly: false,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            contatto.cancel();

            props.dialog.close();
          }}
          size="small"
          variant="outlined"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={contatto.loading || contatto.editing}
          onClick={(event) => {
            contatto.commit();
          }}
          size="small"
          variant="outlined"
        >
          Crea
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
