import React from "react";
import LabelledOutline from "app/components/common/LabelledOutline";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";
import DataDiScadenzaTipo from "app/constants/DataDiScadenzaTipo.json";
import DateInput from "app/components/common/DateInput";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import { formatAttivitaPreventCircularDependecies } from "app/utils/formatAttivitaPreventCircularDependecies";

export type DataDiScadenzaPickerProps = {
  title: string;
  readOnly?: boolean;
  source: any;
  dateTypeSourceKey: string;
  dateSourceKey: string;
  dateDeltaSourceKey: string;
  dateIdFormatAttivita: string;
  attivitaId: number;
  arrayAttivita: any[];
};

export default function DataDiScadenzaPicker({
  title,
  readOnly = false,
  source,
  dateTypeSourceKey,
  dateSourceKey,
  dateDeltaSourceKey,
  dateIdFormatAttivita,
  attivitaId,
  arrayAttivita,
}: DataDiScadenzaPickerProps) {
  return (
    <LabelledOutline label={title}>
      <div className={readOnly ? "crea-readOnly" : ""}>
        <Grid
          style={{
            padding: 0,
          }}
          alignItems="center"
          container={true}
          item={false}
          spacing={1}
        >
          <Grid container={false} item={true} xs={6}>
            <FormControl
              disabled={readOnly}
              error={source.getValidationErrors(dateTypeSourceKey).length > 0}
              fullWidth={true}
              margin="dense"
              size="small"
            >
              <InputLabel
                error={source.getValidationErrors(dateTypeSourceKey).length > 0}
                variant="outlined"
              >
                {}
              </InputLabel>
              <AsyncSelect
                onChange={(event) => {
                  const value = event.target.value;

                  source.changeValue(dateTypeSourceKey, value || null);

                  setTimeout(
                    () => {
                      source.runValidation(dateTypeSourceKey);
                    },

                    0
                  );
                }}
                onClose={() => {
                  source.runValidation(dateTypeSourceKey);
                }}
                value={source.getValue(dateTypeSourceKey) || ""}
                variant="outlined"
                disabled={readOnly}
              >
                {DataDiScadenzaTipo.map((option) => {
                  return (
                    <MenuItem
                      key={option.value}
                      dense={false}
                      disableGutters={false}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })}
              </AsyncSelect>
              {source.getValidationErrors(dateTypeSourceKey)[0] ? (
                <FormHelperText>
                  {source.getValidationErrors(dateTypeSourceKey)[0]}
                </FormHelperText>
              ) : null}{" "}
            </FormControl>
          </Grid>
          {!source.getValue(dateTypeSourceKey) ||
          source.getValue(dateTypeSourceKey) === "simpleDate" ? (
            <Grid
              style={{
                paddingTop: 8,
              }}
              container={false}
              item={true}
              xs={6}
            >
              <DateInput
                className=""
                fullWidth={true}
                size="small"
                autoOk={true}
                variant="outlined"
                source={source}
                sourceKey={dateSourceKey}
                disabled={readOnly}
              />
            </Grid>
          ) : (
            <DeltaDateSelector
              readOnly={readOnly}
              source={source}
              dateDeltaSourceKey={dateDeltaSourceKey}
              dateIdFormatAttivita={dateIdFormatAttivita}
              attivitaId={attivitaId}
              arrayAttivita={arrayAttivita}
            />
          )}{" "}
        </Grid>
      </div>
    </LabelledOutline>
  );
}

type DeltaDateSelectorProps = {
  readOnly?: boolean;
  source: any;
  dateDeltaSourceKey: string;
  dateIdFormatAttivita: string;
  attivitaId: number;
  arrayAttivita: any[];
};

function DeltaDateSelector({
  readOnly,
  source,
  dateDeltaSourceKey,
  dateIdFormatAttivita,
  attivitaId,
  arrayAttivita,
}: DeltaDateSelectorProps) {
  return (
    <>
      <Grid container={false} item={true} xs={3}>
        <TextField
          disabled={readOnly}
          error={source.getValidationErrors(dateDeltaSourceKey).length > 0}
          helperText={source.getValidationErrors(dateDeltaSourceKey)[0]}
          margin="dense"
          multiline={false}
          onBlur={() => {
            source.runValidation(dateDeltaSourceKey);
          }}
          onChange={(event) => {
            const value = event.target.value;
            source.changeValue(
              dateDeltaSourceKey,
              Math.max(0, parseInt(value))
            );
          }}
          size="small"
          value={source.getValue(dateDeltaSourceKey, "")}
          variant="outlined"
          InputProps={{
            readOnly: readOnly,
            inputComponent: NumberFormatCustom,
          }}
          inputProps={{
            min: 0,
          }}
        />
      </Grid>

      <Grid
        style={{
          textAlign: "center",
        }}
        container={false}
        item={true}
        xs={3}
      >
        giorni dopo
      </Grid>

      <Grid container={false} item={true} xs={12}>
        <FormControl
          disabled={readOnly}
          error={source.getValidationErrors(dateIdFormatAttivita).length > 0}
          fullWidth={true}
          size="small"
        >
          <InputLabel
            error={source.getValidationErrors(dateIdFormatAttivita).length > 0}
            variant="outlined"
          >
            {}
          </InputLabel>
          <AsyncSelect
            onChange={(event) => {
              const value = event.target.value;

              source.changeValue(dateIdFormatAttivita, value || null);

              setTimeout(
                () => {
                  source.runValidation(dateIdFormatAttivita);
                },

                0
              );
            }}
            onClose={() => {
              source.runValidation(dateIdFormatAttivita);
            }}
            value={source.getValue(dateIdFormatAttivita) || ""}
            variant="outlined"
            disabled={readOnly}
          >
            <MenuItem value="">
              <em></em>
            </MenuItem>
            {formatAttivitaPreventCircularDependecies(attivitaId, arrayAttivita)
              .map((item) => {
                return { value: item.id, label: item.nome };
              })
              .map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    dense={false}
                    disableGutters={false}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
          </AsyncSelect>
          {source.getValidationErrors(dateIdFormatAttivita)[0] ? (
            <FormHelperText>
              {source.getValidationErrors(dateIdFormatAttivita)[0]}
            </FormHelperText>
          ) : null}{" "}
        </FormControl>
      </Grid>
    </>
  );
}
