import React, { ReactNode } from "react";
import { Select } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select/Select";

export type AsyncSelectProps = {
  loading?: boolean;
  children: ReactNode;
} & SelectProps;

export const AsyncSelect = ({
  loading = false,
  children,
  ...props
}: AsyncSelectProps) => {
  if (loading) {
    return <Select {...props} value="" />;
  } else {
    return <Select {...props}>{children}</Select>;
  }
};
