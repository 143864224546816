import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import { createFattureFromPagamenti as createFattureFromPagamentiAction } from "app/actions";
import { useApi } from "app/hooks/useApi";
import { useToast } from "app/hooks/useToast";
import AbstractTabellaFatture from "./AbstractTabellaFatture";

export default function DialogCreaFatture(props) {
  const { dialog, pagamentiArray, onDone } = props;
  const showToast = useToast();

  const [aggregatedData, setAggregatedData] = useState([]);

  const createFattureApi = useApi(createFattureFromPagamentiAction, {
    onError: () => {
      showToast("Errore durante la creazione delle fatture", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const getToSubmit = () => {
    const toSubmit = aggregatedData
      .map((fattura) => {
        if (fattura.__selected) {
          return {
            ...fattura,
            pagamenti: fattura.pagamenti
              .map((pagamento) => {
                if (pagamento.__selected) {
                  return { ...pagamento };
                }
                return null;
              })
              .filter((x) => x),
          };
        }
        return null;
      })
      .filter((x) => x);
    return toSubmit;
  };

  const createFatture = async () => {
    const toSubmit = getToSubmit()
      .map((fattura) => {
        if (fattura.__selected) {
          return {
            ...fattura,
            pagamenti: fattura.pagamenti
              .map((pagamento) => {
                if (pagamento.__selected) {
                  return { ...pagamento };
                }
                return null;
              })
              .filter((x) => x),
          };
        }
        return null;
      })
      .filter((x) => x);
    await createFattureApi.callApi(toSubmit);
    dialog.close();
    onDone();
  };

  useEffect(() => {
    let newAggrData = [];
    if (dialog.isOpen && pagamentiArray) {
      const aggr = aggregateData(pagamentiArray);
      newAggrData = Object.values(aggr);
    }
    setAggregatedData(newAggrData);
  }, [dialog.isOpen, pagamentiArray]);

  const selectFattura = (index, selected) => {
    const newAggrData = [...aggregatedData];
    let importoMaturato = 0;
    let importoEffettivo = 0;
    newAggrData[index] = {
      ...newAggrData[index],
      __selected: selected,
      pagamenti: newAggrData[index].pagamenti.map((p) => {
        if (p.stato === "maturato-standby") {
          return p;
        }
        p = {
          ...p,
          __selected: selected,
        };
        if (p.__selected) {
          importoMaturato += p.importoMaturato;
          importoEffettivo += p.importoEffettivo;
        }
        return p;
      }),
    };
    newAggrData[index].importoMaturato = importoMaturato;
    newAggrData[index].importoEffettivo = importoEffettivo;
    setAggregatedData(newAggrData);
  };

  const selectPagamentoFattura = (index, indexP, selected) => {
    const newAggrData = [...aggregatedData];
    let atLeastOneSelected = false;
    let importoMaturato = 0;
    let importoEffettivo = 0;
    newAggrData[index] = {
      ...newAggrData[index],
      pagamenti: newAggrData[index].pagamenti.map((p, i) => {
        if (i === indexP) {
          p = {
            ...p,
            __selected: selected,
          };
        }
        if (p.__selected) {
          atLeastOneSelected = true;
          importoMaturato += p.importoMaturato;
          importoEffettivo += p.importoEffettivo;

          const hasMarcheDaBollo =
            p.mlMilestonePagamento?.milestonePagamento?.hasMarcheDaBollo ||
            false;
          if (hasMarcheDaBollo) {
            const { numeroMarcheDaBollo = 0, valoreMarcaDaBollo = 0 } = p;
            importoEffettivo += numeroMarcheDaBollo * valoreMarcaDaBollo;
          }
        }
        return p;
      }),
    };
    newAggrData[index].__selected = atLeastOneSelected;
    newAggrData[index].importoMaturato = importoMaturato;
    newAggrData[index].importoEffettivo = importoEffettivo;
    setAggregatedData(newAggrData);
  };

  const changeImportoEffettivo = (index, indexP, newValue) => {
    const newAggrData = [...aggregatedData];
    let atLeastOneSelected = false;
    let importoMaturato = 0;
    let importoEffettivo = 0;
    newAggrData[index] = {
      ...newAggrData[index],
      pagamenti: newAggrData[index].pagamenti.map((p, i) => {
        if (i === indexP) {
          p = {
            ...p,
            importoEffettivo: newValue,
            stato:
              p.importoMaturato === newValue
                ? p.__initialStato
                : "in-approvazione",
          };
        }
        if (p.__selected) {
          atLeastOneSelected = true;
          importoMaturato += p.importoMaturato;
          importoEffettivo += p.importoEffettivo;
        }
        return p;
      }),
    };
    newAggrData[index].__selected = atLeastOneSelected;
    newAggrData[index].importoMaturato = importoMaturato;
    newAggrData[index].importoEffettivo = importoEffettivo;
    setAggregatedData(newAggrData);
  };

  const onFatturaNoteChanged = (index, newValue) => {
    const newAggrData = [...aggregatedData];
    newAggrData[index] = {
      ...newAggrData[index],
      note: newValue,
    };
    setAggregatedData(newAggrData);
  };

  const onPagamentoNoteChanged = (index, indexP, newValue) => {
    const newAggrData = [...aggregatedData];
    newAggrData[index] = {
      ...newAggrData[index],
      pagamenti: newAggrData[index].pagamenti.map((p, i) => {
        if (i === indexP) {
          p = {
            ...p,
            note: newValue,
          };
        }
        return p;
      }),
    };
    setAggregatedData(newAggrData);
  };

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="lg"
      open={dialog.isOpen}
      scroll="paper"
    >
      <DialogTitle>Crea fatture</DialogTitle>

      <DialogContent>
        <AbstractTabellaFatture
          fatture={aggregatedData}
          canSelectPagamento
          selectFattura={selectFattura}
          isFatturaSelected={(i) => aggregatedData[i].__selected}
          selectPagamento={selectPagamentoFattura}
          canEditImportoEffettivo
          onImportoEffettivoChange={changeImportoEffettivo}
          onFatturaNoteChanged={onFatturaNoteChanged}
          onPagamentoNoteChanged={onPagamentoNoteChanged}
        />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={() => {
            dialog.close();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={createFattureApi.loading || getToSubmit().length === 0}
          onClick={() => {
            const n = getToSubmit().length;
            const str =
              n > 1
                ? `Verranno create ${n} fatture`
                : "Verrà creata una fattura";
            const ok = window.confirm(str + ". Confermi la creazione?");
            if (ok) {
              createFatture();
            }
          }}
          color="primary"
        >
          Crea fatture
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

export function aggregateData(pagamentiArray) {
  // AGGREGA PAGAMENTI PER CLIENTE, ecc.
  const aggr = {};
  const fakeIdTerminiPagamentoMap = {};
  let fakeIdTerminiPagamentoCount = 0;
  function addPagamento(pagamento) {
    const cliente = pagamento.mandatoCliente?.cliente;
    if (!cliente) {
      // necessario se il mandato è stato cancellato, altrimenti da eccezione
      return;
    }

    const idCliente = cliente.id;
    const mandatoCliente = pagamento.mandatoCliente;
    if (!mandatoCliente) {
      return; // non dovrebbe mai succedere!
    }
    const banca = mandatoCliente.banca;
    const idBanca = banca?.id || null;
    const terminiPagamento = mandatoCliente.terminiPagamento ?? "";
    if (!fakeIdTerminiPagamentoMap[terminiPagamento]) {
      fakeIdTerminiPagamentoMap[terminiPagamento] =
        ++fakeIdTerminiPagamentoCount;
    }
    const fakeIdTerminiPagamento = fakeIdTerminiPagamentoMap[terminiPagamento];
    const idTipoPagamento = mandatoCliente.idTipoPagamento;

    const aggrKey = `${idCliente}-${
      idBanca || 0
    }-${fakeIdTerminiPagamento}-${idTipoPagamento}`;

    let fattura = aggr[aggrKey];
    if (!fattura) {
      fattura = {
        idCliente: idCliente,
        idBanca: idBanca,
        terminiPagamento: terminiPagamento,
        banca: banca,
        cliente: cliente,
        importoMaturato: 0,
        importoEffettivo: 0,
        pagamenti: [],
      };
      aggr[aggrKey] = fattura;
    }
    pagamento.__initialStato = pagamento.stato;
    fattura.pagamenti.push(pagamento);
  }

  pagamentiArray.forEach(addPagamento);
  return aggr;
}
