import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import FormatStrumentoStato from "app/constants/FormatStrumentoStato";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getFormatStrumento as getFormatStrumentoAction,
  createFormatStrumento as createFormatStrumentoAction,
  editFormatStrumento as editFormatStrumentoAction,
  deleteFormatStrumento as deleteFormatStrumentoAction,
  duplicateFormatStrumento as duplicateFormatStrumentoAction,
} from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormHelperText,
  Dialog,
} from "@material-ui/core";
import BackButton from "app/components/common/BackButton";
import { ColoredButton } from "app/elements/ColoredButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { StatoPicker } from "app/components/StatoPicker";
import FileBox from "app/components/common/FileBox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import LineaPicker from "app/components/common/LineaPicker";
import LineaStato from "app/constants/LineaStato";
import BadgeLineaStato from "app/components/common/BadgeLineaStato";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import AddIcon from "@material-ui/icons/Add";
import MilestonePagamentiAccordion from "app/components/FormatStrumenti/MilestonePagamentiAccordion";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import NumberInput from "../common/NumberInput";
import { useEffectOnSearchValue } from "../../utils/extractURLSearchValue";
import LaunchIcon from "@material-ui/icons/Launch";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSubmitDataSource } from "../../hooks/DataSource/useSubmitDataSource";

export default function FormatStrumento(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const history = useHistory();

  const { id } = props.useIdFromProps ? props : useParams();

  const isNew = id === "new";

  const [editing, setEditing] = useState(isNew);

  const readOnly = !editing;

  const formatStrumento = useDataSource({
    initialData: {
      stato: "in-preparazione",
    },
    autoLoad: !isNew,

    loadAction: getFormatStrumentoAction,
    onCreateSuccess: (response) => {
      setEditing(false);

      showToast("Format strumento creato con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push(`/formatStrumenti/${response.data.id}`);
    },
    onCreateError: (response) => {
      showToast("Errore durante la creazione del format strumento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    createAction: createFormatStrumentoAction,
    editAction: editFormatStrumentoAction,
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Format strumento modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: (response) => {
      showToast("Errore durante la modifica del format strumento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    deleteAction: deleteFormatStrumentoAction,
    onDeleteSuccess: (response) => {
      showToast("Format strumenti eliminato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/formatStrumenti");
    },
    onDeleteError: (response) => {
      showToast(
        response?.message
          ? `Errore: ${response.message}`
          : "Errore durante l'eliminazione del format strumento",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
    itemId: id,
  });

  const duplicateFormatStrumento = useSubmitDataSource({
    submitAction: duplicateFormatStrumentoAction,
    onSubmitSuccess: (response) => {
      showToast("Format strumento duplicato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/formatStrumenti/" + response.data.id);
    },
    onSubmitError: () => {
      showToast("Errore durante la duplicazione del format strumento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const maybeAlreadySold =
    formatStrumento.getValue("stato") !== "in-preparazione" &&
    formatStrumento.getValue("stato") !== "in-revisione";

  const formatStrumentoLinee = useDataSource({
    parent: formatStrumento,
    pathInParent: ["fsl"],
    initialData: [],
  });

  const deleteDialog = useDialog();

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_format_strumenti") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_format_strumenti")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_format_strumenti"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <>
      <Container
        className={readOnly ? "crea-readOnly" : ""}
        disableGutters={true}
        fixed={false}
        maxWidth="xl"
      >
        <h2
          className="page-title-header page-title-header-sticky"
          style={{
            marginTop: -20,
            marginBottom: 0,
          }}
        >
          <BackButton defaultBackLink="" />
          Format strumento
        </h2>
        {formatStrumento.loading ? (
          "Caricamento format strumento in corso ..."
        ) : formatStrumento.loadError ? (
          "Errore durante il caricamento del format strumento"
        ) : (
          <>
            <div
              className="page-title-header-sticky-buttons-row"
              style={{
                display: "flex",
                marginBottom: "1em",
              }}
            >
              <Typography
                style={{
                  flex: 1,
                }}
                component="h2"
                gutterBottom={true}
                variant="h6"
              >
                {formatStrumento.getValue("nome")}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 8,
                  maxHeight: 38,
                }}
              >
                {!isNew &&
                  readOnly &&
                  formatStrumento.getValue("stato") === "in-vendita" &&
                  hasPermission("crea_offerta_mandato") && (
                    <ColoredButton
                      color="default"
                      size="medium"
                      variant="outlined"
                      to={`/mandati/new?formatStrumentoId=${formatStrumento.getValue(
                        "id"
                      )}`}
                      component={Link}
                    >
                      Crea offerta/mandato
                    </ColoredButton>
                  )}
                {props.isSideModal && readOnly && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    color="secondary"
                    size="small"
                    startIcon={<LaunchIcon />}
                    variant="outlined"
                    to={`/formatStrumenti/${id}?edit=true`}
                    component={Link}
                  >
                    Apri
                  </ColoredButton>
                )}
                {readOnly ? (
                  <>
                    {hasPermission("modifica_format_strumenti") && (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={(event) => {
                          setEditing(!editing);
                        }}
                        startIcon={<EditIcon />}
                        variant="outlined"
                      >
                        Modifica
                      </ColoredButton>
                    )}
                    {hasPermission("duplica_format_strumento") && (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          duplicateFormatStrumento.submit(
                            formatStrumento.getValue("id")
                          );
                        }}
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                      >
                        Duplica
                      </ColoredButton>
                    )}
                  </>
                ) : (
                  <>
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={(event) => {
                        if (isNew) {
                          history.push("/formatStrumenti");
                        } else {
                          formatStrumento.cancel();

                          setEditing(false);
                        }
                      }}
                      color="secondary"
                      size="small"
                      startIcon={<CancelIcon />}
                      variant="outlined"
                    >
                      {isNew
                        ? "Torna alla lista format strumenti"
                        : "Annulla modifiche"}
                    </ColoredButton>

                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={(event) => {
                        formatStrumento.commit();
                      }}
                      color="secondary"
                      size="small"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                    >
                      {isNew ? "Crea" : "Salva"}
                    </ColoredButton>
                  </>
                )}
                {!isNew &&
                  !props.isSideModal &&
                  hasPermission("elimina_format_strumenti") && (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={(event) => {
                        deleteDialog.open();
                      }}
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                    >
                      Elimina
                    </ColoredButton>
                  )}
              </div>
            </div>

            <Card style={{ marginBottom: 16 }}>
              <CardContent>
                <Grid container={true} item={false} spacing={2}>
                  <Grid container={false} item={true} md={6} xs={12}>
                    <TextField
                      disabled={readOnly}
                      error={
                        formatStrumento.getValidationErrors("nome").length > 0
                      }
                      fullWidth={true}
                      helperText={
                        formatStrumento.getValidationErrors("nome")[0]
                      }
                      label="Nome format strumento"
                      onBlur={(event) => {
                        formatStrumento.runValidation("nome");
                      }}
                      onChange={(event) => {
                        const value = event.target.value;

                        formatStrumento.changeValue(
                          "nome",

                          value
                        );
                      }}
                      size="small"
                      value={formatStrumento.getValue("nome", "")}
                      variant="outlined"
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <StatoPicker
                      label="Stato"
                      source={formatStrumento}
                      stati={FormatStrumentoStato}
                      disabled={!readOnly}
                    />
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <FileBox
                      readOnly={readOnly}
                      source={formatStrumento}
                      sourceKey="contratto"
                      title="Contratto"
                    />
                  </Grid>

                  <Grid container={false} item={true} md={6} xs={12}>
                    <FileBox
                      readOnly={readOnly}
                      source={formatStrumento}
                      sourceKey="templateSimulazione"
                      title="Template simulazione"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <LineeAccordion
              formatStrumentoLinee={formatStrumentoLinee}
              formatStrumento={formatStrumento}
              editing={editing}
              maybeAlreadySold={maybeAlreadySold}
            />

            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Milestone pagamenti
              </AccordionSummary>

              <AccordionDetails>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {formatStrumentoLinee.data
                    .filter((formatStrumentoLinea) => {
                      return formatStrumentoLinea.idLinea;
                    })
                    .map((formatStrumentoLinea, index) => {
                      return (
                        <MilestonePagamentiAccordion
                          key={formatStrumentoLinea.id}
                          readOnly={readOnly || maybeAlreadySold}
                          formatStrumentoLinea={formatStrumentoLinee.getChildDataSource(
                            [
                              {
                                id: formatStrumentoLinea.id,
                              },
                            ]
                          )}
                        />
                      );
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}{" "}
      </Container>

      <Dialog maxWidth="md" open={deleteDialog.isOpen}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {'Sei sicuro di voler eliminare il format strumento "' +
              formatStrumento.getValue("nome") +
              '"?'}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={(event) => {
              deleteDialog.close();
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={(event) => {
              formatStrumento.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

function LineeAccordion({
  formatStrumentoLinee,
  formatStrumento,
  editing,
  maybeAlreadySold,
}) {
  const readOnly = !editing;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Linee</AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={formatStrumentoLinee}
          columns={[
            {
              path: ["idLinea"],
              header: "Nome linea",
              disableColumnFilter: true,
              disableGlobalFilter: true,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <LineaPicker
                    readOnly={readOnly || !rowSource.getValue("__isNew")}
                    idLinea={rowSource.getValue("idLinea")}
                    filterLinea={(linea) => {
                      if (linea.stato !== "go") {
                        return false;
                      }

                      const alreadyPicked = formatStrumentoLinee.data.find(
                        (fsl) => fsl.idLinea === linea.id
                      );
                      if (alreadyPicked) {
                        return false;
                      }

                      if (linea.legge.isPluriennale) {
                        const annualitaAlreadyPicked =
                          formatStrumentoLinee.data.find(
                            (fsl) =>
                              fsl.idLinea &&
                              fsl.linea.legge.id === linea.idLegge
                          );

                        if (annualitaAlreadyPicked) {
                          return false;
                        }
                      }

                      return true;
                    }}
                    onChange={(linea) => {
                      rowSource.changeValue("linea", linea);
                      rowSource.changeValue("idLinea", linea ? linea.id : null);

                      if (linea) {
                        if (linea.isPluriennale) {
                          /* Il checkbox nel campo “Pluriannuale max” è settato a yes di default e non deve essere possibile modificarlo per una legge pluriannuale  */
                        }
                      }

                      return null;
                    }}
                    error={rowSource.getValidationErrors("idLinea")?.[0]}
                  />
                );
              },
            },
            {
              path: ["linea", "stato"],
              header: "Stato",
              sortable: true,
              width: 250,
              type: "enum",
              options: LineaStato,
              renderCell: (value, row, rowIndex, rowSource) => {
                return <BadgeLineaStato stato={value} />;
              },
            },
            {
              path: ["pluriennaleMax"],
              header: "Pluriennale max",
              disableColumnFilter: true,
              disableGlobalFilter: true,
              sortable: true,
              type: "number",
              renderCell: (value, row, rowIndex, rowSource) => {
                return rowSource.getValue("linea", null) ===
                  null ? null : rowSource.getValue([
                    "linea",
                    "isPluriennale",
                  ]) ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rowSource.getValue(
                            "hasPluriennaleMax",
                            false
                          )}
                          onChange={(event) => {
                            const value = event.target.checked;

                            rowSource.changeValue(
                              "pluriennaleMax",
                              value ? 1 : null
                            );
                            rowSource.changeValue("hasPluriennaleMax", value);
                          }}
                          size="small"
                        />
                      }
                      disabled={readOnly}
                    />
                    {rowSource.getValue("hasPluriennaleMax", false) ? (
                      <TextField
                        disabled={readOnly}
                        error={
                          rowSource.getValidationErrors("pluriennaleMax")
                            .length > 0
                        }
                        fullWidth={true}
                        helperText={
                          rowSource.getValidationErrors("pluriennaleMax")[0]
                        }
                        margin="dense"
                        onBlur={(event) => {
                          rowSource.runValidation("pluriennaleMax");
                        }}
                        onChange={(event) => {
                          const value = event.target.value;

                          rowSource.changeValue(
                            "pluriennaleMax",

                            value
                          );
                        }}
                        size="small"
                        value={rowSource.getValue("pluriennaleMax", "")}
                        variant="outlined"
                        InputProps={{
                          readOnly: readOnly,
                          inputComponent: NumberFormatCustom,
                        }}
                        inputProps={{
                          thousandSeparator: false,
                        }}
                      />
                    ) : null}{" "}
                  </div>
                ) : (
                  "Legge non pluriennale"
                );
              },
            },

            {
              path: "",
              header: "Anno iniziale",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "number",
              renderCell: (value, row, rowIndex, rowSource) => {
                return rowSource.getValue(["linea", "isPluriennale"]) ? (
                  rowSource.getValue("hasPluriennaleMax", false) ? (
                    <TextField
                      disabled={readOnly}
                      error={
                        rowSource.getValidationErrors("annoIniziale").length > 0
                      }
                      fullWidth={true}
                      helperText={
                        rowSource.getValidationErrors("annoIniziale")[0]
                      }
                      margin="dense"
                      onBlur={(event) => {
                        rowSource.runValidation("annoIniziale");
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        rowSource.changeValue("annoIniziale", value);
                      }}
                      size="small"
                      value={rowSource.getValue("annoIniziale", "")}
                      variant="outlined"
                      InputProps={{
                        readOnly: readOnly,
                        inputComponent: NumberFormatCustom,
                      }}
                      inputProps={{
                        thousandSeparator: false,
                      }}
                    />
                  ) : null
                ) : null;
              },
            },
            {
              path: ["id"],
              header: "Investimento previsto",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: 4,
                      maxWidth: "99%",
                      whiteSpace: "normal",
                    }}
                  >
                    <NumberInput
                      variant="outlined"
                      size="small"
                      label="Min"
                      disabled={!editing}
                      value={rowSource.getValue("minInvestimentoPrevisto", "")}
                      min={0}
                      onChange={(event) => {
                        rowSource.changeValue(
                          "minInvestimentoPrevisto",
                          event.target.value
                        );
                      }}
                      InputProps={{
                        readOnly: readOnly,
                        inputComponent: NumberFormatCustom,
                      }}
                      error={
                        rowSource.getValidationErrors("minInvestimentoPrevisto")
                          .length > 0
                      }
                      helperText={
                        rowSource.getValidationErrors(
                          "minInvestimentoPrevisto"
                        )[0]
                      }
                    />
                    <NumberInput
                      variant="outlined"
                      size="small"
                      label="Max"
                      disabled={!editing}
                      value={rowSource.getValue("maxInvestimentoPrevisto", "")}
                      min={0}
                      onChange={(event) => {
                        rowSource.changeValue(
                          "maxInvestimentoPrevisto",
                          event.target.value
                        );
                      }}
                      InputProps={{
                        readOnly: readOnly,
                        inputComponent: NumberFormatCustom,
                      }}
                      error={
                        rowSource.getValidationErrors("maxInvestimentoPrevisto")
                          .length > 0
                      }
                      helperText={
                        rowSource.getValidationErrors(
                          "maxInvestimentoPrevisto"
                        )[0]
                      }
                    />
                  </div>
                );
              },
            },

            !readOnly &&
              !maybeAlreadySold && {
                path: ["id"],
                header: "Azioni",
                width: 64,
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <IconButton
                      disabled={readOnly}
                      onClick={(event) => {
                        rowSource.delete();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  );
                },
                disableColumnFilter: true,
                disableGlobalFilter: true,
                sortable: false,
                type: "text",
              },
          ]}
          bottomToolbar={
            <div>
              {formatStrumento.getValidationErrors("fsl")?.[0] ? (
                <FormHelperText
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={true}
                  error={true}
                  filled={false}
                  focused={false}
                  required={false}
                  variant="standard"
                >
                  {formatStrumento.getValidationErrors("fsl")?.[0] || null}{" "}
                </FormHelperText>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.75em",
                }}
              >
                {editing && !maybeAlreadySold && (
                  <ColoredButton
                    onClick={() => {
                      formatStrumentoLinee.create();
                    }}
                    color="secondary"
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Aggiungi linea
                  </ColoredButton>
                )}
              </div>
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          expandRowsOnClick={false}
          isRowExpandable={null}
          renderExpandedRow={null}
          messages={{
            noRows: "Nessuna linea",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
