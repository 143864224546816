import { useMemo } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { Button, ButtonGroup, Container, Typography } from "@material-ui/core";
import { getAnalisiCommerciale } from "app/actions";
import {
  PivotRowGetter,
  PivotTable,
  PivotTableColumn,
} from "../PivotTable/PivotTable";
import { sum } from "lodash";
import { useLoadApi } from "app/hooks/useLoadApi";
import { ContrattiCell } from "./ContrattiCell";
import moment from "moment";
import UserFilterSelector from "../common/UserFilterSelector";
import AnnoPicker from "../common/AnnoPicker";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";

const getPivotRowsLinea: PivotRowGetter<any> = (d) => {
  return {
    key: d.idLinea,
    label: d.nomeLinea,
  };
};
const getPivotRowsCommerciale: PivotRowGetter<any> = (d) => {
  return {
    key: d.idCommerciale,
    label: d.commerciale,
  };
};

const getPivotRowsAmbito: PivotRowGetter<any> = (d) => {
  return {
    key: d.ambitoDiApplicazione,
    label: d.ambitoDiApplicazione,
  };
};

export function AnalisiCommerciale(props) {
  const hasPermission = useCheckPermission();

  const canSeeAll = hasPermission("visualizza_analisi_commerciale_tutti");

  // save for history change!
  const [anno, setAnno] = useStateWithSaveCurrentState(
    moment().year(),
    "analisi_commerciali_anno",
    "all_object"
  );

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    null,
    "analisi_commerciali_user",
    "all_object"
  );

  const [groupBy, setGroupBy] = useStateWithSaveCurrentState(
    "user",
    "analisi_commerciali_groupby",
    "all_object"
  );

  const api = useLoadApi(getAnalisiCommerciale, {
    anno,
    idCommerciale: userFilter,
  });

  const columns = useMemo(() => {
    return createColumns(anno);
  }, [anno]);

  let pivotRowsGetter: [PivotRowGetter<any>, PivotRowGetter<any>];
  let pivotRowsLabel: [string, string];

  if (groupBy === "user") {
    pivotRowsGetter = [getPivotRowsCommerciale, getPivotRowsLinea];
    pivotRowsLabel = ["Commerciale", "Linea"];
  } else {
    pivotRowsGetter = [getPivotRowsAmbito, getPivotRowsLinea];
    pivotRowsLabel = ["Ambito", "Linea"];
  }

  if (!hasPermission(["visualizza_analisi_commerciale"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          marginBottom: 10,
        }}
      >
        <h1
          className="page-title-header"
          style={{
            marginTop: -20,
          }}
        >
          Analisi Commerciale
        </h1>{" "}
        <div style={{ marginLeft: "auto" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <div style={{ position: "absolute", top: -16 }}>
              <Typography
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
                variant="caption"
              >
                Raggruppa per
              </Typography>
            </div>
            <ButtonGroup color="primary" size="small" disableElevation>
              <Button
                variant={groupBy === "user" ? "contained" : "outlined"}
                onClick={() => setGroupBy("user")}
              >
                Commerciale
              </Button>
              <Button
                variant={groupBy === "ambito" ? "contained" : "outlined"}
                onClick={() => setGroupBy("ambito")}
              >
                Ambito
              </Button>
            </ButtonGroup>
          </div>
          <UserFilterSelector
            returnValue="id"
            defaultSelection="all"
            label="Operatore"
            onChange={setUserFilter}
            value={userFilter}
            canAllEAltriUtenti={canSeeAll}
            userRole="Commerciale"
          />
          <AnnoPicker anno={anno} setAnno={setAnno} />
        </div>
      </div>

      {api.loading ? (
        "Caricamento dati..."
      ) : api.error ? (
        "Errore durante il caricamento dei dati"
      ) : (
        <PivotTable
          pivotRowsGetter={pivotRowsGetter}
          pivotRowsLabel={pivotRowsLabel}
          data={api.data || []}
          columns={columns}
        />
      )}
    </Container>
  );
}
function createColumns(anno: number): PivotTableColumn<any>[] {
  const filterOfferta = (d) =>
    d.statoMandato === "offerta" || d.statoMandato === "contratto"; // TODO: va bene cosi? perche i contratti sono anche "offerte" che poi si sono trasformate

  const filterContratto = (d) => d.statoMandato === "contratto";

  const width = 100;

  const result: PivotTableColumn<any>[] = [
    {
      key: "offerte",
      label: "# Offerte",
      render: (data) => <CellRenderer data={data} isOfferta />,
      filter: filterOfferta,
      width,
    },
    {
      key: "contratti",
      label: "# Contratti",
      render: (data) => <CellRenderer data={data} />,
      filter: filterContratto,
      width,
    },
  ];

  const partial_colonnePerStato_Mandato = [
    {
      key: "offerte",
      label: "# Offerte",
      render: (data) => <CellRenderer data={data} isOfferta noColor />,
      filter: filterOfferta,
      statoClienteKey: "statoClienteAllaCreazioneOfferta",
    },
    {
      key: "contratti",
      label: "# Contratti",
      render: (data) => <CellRenderer data={data} noColor />,
      filter: filterContratto,
      statoClienteKey: "statoClienteAllaFirma",
    },
  ];
  const partial_colonnePerStato_Cliente = [
    {
      key: "nuovo",
      label: "1°",
      statoClienteValue: "nuovo",
    },
    {
      key: "inattivo",
      label: "Riattivazioni",
      statoClienteValue: "inattivo",
    },
    {
      key: "attivo",
      label: "Già clienti",
      statoClienteValue: "attivo",
    },
  ];

  partial_colonnePerStato_Mandato.forEach((partial_colonnaPerStato_Mandato) => {
    partial_colonnePerStato_Cliente.forEach(
      (partial_colonnaPerStato_Cliente) => {
        result.push({
          key: `${partial_colonnaPerStato_Mandato.key}-${partial_colonnaPerStato_Cliente.key}`,
          filter: (d) =>
            partial_colonnaPerStato_Mandato.filter(d) &&
            d[partial_colonnaPerStato_Mandato.statoClienteKey] ===
              partial_colonnaPerStato_Cliente.statoClienteValue,
          label: (
            <>
              {partial_colonnaPerStato_Mandato.label}
              <br />
              {partial_colonnaPerStato_Cliente.label}
            </>
          ),
          render: partial_colonnaPerStato_Mandato.render,
          width,
        });
      }
    );
  });

  return result;
}

function CellRenderer({
  data,
  isOfferta = false,
  noColor = false,
}: {
  data: any[];
  isOfferta?: boolean;
  noColor?: boolean;
}) {
  if (!data || !data.length) {
    return null;
  }
  const n = sum(data.map((x) => x.count));
  const importo = sum(data.map((x) => x.importo));

  return (
    <ContrattiCell
      numeroDiContratti={n}
      importo={importo}
      labels={isOfferta ? ["offerta", "offerte"] : ["contratto", "contratti"]}
      color={noColor ? undefined : isOfferta ? "#3890F5" : "#4caf50"}
    />
  );
}
