import React, { useEffect, useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { editBudget as editBudgetAction } from "app/actions";
import { Container, Dialog, Grid } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import BudgetPicker from "../common/BudgetPicker";
import { useApi } from "app/hooks/useApi";

// onClose = (reload: boolean) => void
export default function DialogEditBudgetState({
  budgetList: _budgetList,
  anno,
  open,
  onClose,
}) {
  const showToast = useToast();

  const editApi = useApi(editBudgetAction);

  const [editData, setEditData] = useState({
    attuale: 0,
    approvato: 0,
  });
  const [loading, setLoading] = useState(false);

  const changeValue = (key, value) => {
    setEditData((d) => {
      return {
        ...d,
        [key]: value,
      };
    });
  };

  const budgetList = useMemo(() => {
    const budgetList = (_budgetList || []).filter((x) => {
      return x.anno === anno;
    });

    return budgetList;
  }, [anno, _budgetList]);

  useEffect(() => {
    if (open) {
      let attualeId = 0;
      let approvatoId = 0;

      (budgetList || []).forEach((b) => {
        if (b.anno !== anno) {
          return;
        }
        if (b.approvato) {
          approvatoId = b.id;
        }
        if (b.attuale) {
          attualeId = b.id;
        }
      });

      setEditData({
        attuale: attualeId,
        approvato: approvatoId,
      });
    } else {
      setEditData({
        attuale: 0,
        approvato: 0,
      });
    }
  }, [open, anno, budgetList]);

  const save = async () => {
    setLoading(true);
    try {
      const { approvato, attuale } = editData;
      if (approvato) {
        await editApi.callApi(approvato, {
          approvato: true,
        });
      }
      if (attuale) {
        await editApi.callApi(attuale, {
          attuale: true,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      showToast("Errore durante la modifica", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
      return;
    }

    showToast("Budget modificato", {
      color: "success",
      horizontal: "left",
      vertical: "bottom",
    });

    setLoading(false);
    onClose(true);
  };

  const cancel = () => {
    onClose(false);
  };

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="md"
      open={open}
      scroll="paper"
    >
      <DialogTitle>
        Budget
        <br />
        Anno {anno}
      </DialogTitle>

      <DialogContent>
        <Container disableGutters>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <BudgetPicker
                budgetList={budgetList}
                budgetId={editData.approvato || null}
                setBudgetId={(budgetId) => changeValue("approvato", budgetId)}
                label="Budget Approvato"
                fullWidth
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12} style={{ marginTop: 20 }}>
              <BudgetPicker
                budgetList={budgetList}
                budgetId={editData.attuale || null}
                setBudgetId={(budgetId) => changeValue("attuale", budgetId)}
                label="Budget Attuale"
                fullWidth
              />
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            cancel();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={loading}
          onClick={(event) => {
            save();
          }}
          color="primary"
        >
          Salva
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
