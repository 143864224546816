/*
returns:
    [
        {
            idAttivita
            valore
            valoreInvestimento
            variabile: {
                agevolazione: {
                    nome: {
                        nome
                    }
                }
                nome: {
                    nome
                }
            }
        }
    ]
 */

export function getAgevolazioniEVariabiliDaAttivita(_idLinea, procedure) {
  if (!Array.isArray(procedure)) {
    return [];
  }

  const result = [];
  procedure.forEach((procedura) => {
    const idLinea = procedura.formatProcedura?.idLinea;
    if (idLinea === _idLinea) {
      procedura.attivita?.forEach((attivita) => {
        attivita.variabili?.forEach((variabile) => {
          result.push(variabile);
        });
      });
    }
  });
  return result;
}
