import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import apiMiddleware from "./middleware/api";

function getPreloadedState() {
  let preloadedState;
  try {
    preloadedState =
      window.__PRELOADED_STATE__ && JSON.parse(window.__PRELOADED_STATE__);
  } catch (err) {
    console.log(err);
  }
  delete window.__PRELOADED_STATE__;
  return preloadedState || {};
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  getPreloadedState(),
  composeEnhancers(applyMiddleware(thunk, apiMiddleware))
);

export default store;
