import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from "react";
import "./PivotTable.scss";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { orderBy } from "lodash";

export type PivotTableProps<D> = {
  data: D[];
  pivotRowsGetter:
    | [PivotRowGetter<D> | PivotRowGetterWithSorting<D>]
    | [PivotRowGetter<D> | PivotRowGetterWithSorting<D>, PivotRowGetter<D>];
  subheaderKeyGetter?: (data: D) => string;
  subheaderRenderer?: (data: D) => React.ReactNode;
  hideRowsGetter?: (data: D) => boolean;
  pivotRowsLabel: [any] | [any, any];
  columns: PivotTableColumn<D>[];
  showTotal?: boolean;
  totalColumnLabel?: string;
  totalColumnWidth?: string | number;
  totalRender?: (data: D[]) => any;
  expandedDefault?: boolean;
};

type Many<T> = T | Array<T>;

export type PivotRowGetter<D> = (
  d: D
) => { key: string; label: any } | { key: string; label: any }[];

export type PivotRowGetterWithSorting<D> = {
  getter: (d: D) => { key: string; label: any } | { key: string; label: any }[];
  sortBy?: Many<(d: D[]) => any>;
  order?: Many<"asc" | "desc">;
};

export type PivotTableColumn<D> =
  | {
      key: string;
      label: any;
      render: (data: D[], rowKeys: any[]) => any;
      filter: (data: D, rowKeys: any[]) => boolean;
      width?: number | string;
      style?: CSSProperties;
    }
  | {
      // static column
      label: any;
      render: (data: D[], rowKeys: any[]) => any;
      width?: number | string;
      style?: CSSProperties;
    };

export type PivotTableRow<D> = {
  key: string;
  label: any;
  rows: D[];
};

export function PivotTable<D>(props: PivotTableProps<D>) {
  const {
    data,
    pivotRowsGetter,
    subheaderKeyGetter,
    subheaderRenderer,
    hideRowsGetter,
    pivotRowsLabel,
    columns,
    showTotal,
    totalColumnLabel,
    totalColumnWidth,
    totalRender,
    expandedDefault = false,
  } = props;

  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );

  const isExpanded = (key: string) => {
    return expandedKeys[key] ?? expandedDefault;
  };

  const toggleExpandedKeys = (key: string) => {
    setExpandedKeys((expandedKeys) => {
      return {
        ...expandedKeys,
        [key]: !(expandedKeys[key] ?? expandedDefault),
      };
    });
  };

  useEffect(() => {
    setExpandedKeys({});
  }, [pivotRowsGetter[0], pivotRowsGetter[1]]);

  const pivotRows: (PivotTableRow<D> & { pivotRows?: PivotTableRow<D>[] })[] =
    useMemo(() => {
      const pivotRows1 = extractPivotRows(data, pivotRowsGetter[0]);

      return pivotRows1.map((x) => {
        if (pivotRowsGetter[1]) {
          const pivotRows2 = extractPivotRows(x.rows, pivotRowsGetter[1]);
          return {
            ...x,
            pivotRows: pivotRows2,
          };
        }
        return x;
      });
    }, [data, pivotRowsGetter[0], pivotRowsGetter[1]]);

  const totalRenderRef = useRef(totalRender);
  totalRenderRef.current = totalRender;

  const PivotTableColumns = useMemo(() => {
    return ({ data, rowKeys }: { data: D[]; rowKeys: any[] }) => {
      return (
        <>
          {columns.map((x, index) => {
            if (isPivotTablePivotColumn(x)) {
              const filteredData = data.filter((d) => x.filter(d, rowKeys));
              const content = x.render(filteredData, rowKeys);
              return (
                <td key={x.key} style={x.style}>
                  {content}
                </td>
              );
            } else {
              const content = x.render(data, rowKeys);
              return (
                <td key={index} style={x.style}>
                  {content}
                </td>
              );
            }
          })}
          {showTotal && <td>{totalRenderRef.current?.(data) || null}</td>}
        </>
      );
    };
  }, [columns, showTotal]);

  let _lastSubheaderKey: string | null = null;
  const rows = pivotRows.map((pivotRow1) => {
    const expanded = isExpanded(pivotRow1.key);
    const firstData = pivotRow1.pivotRows?.[0]?.rows?.[0];

    let subheader: React.ReactNode | null = null;
    if (firstData && subheaderKeyGetter && subheaderRenderer) {
      const key = subheaderKeyGetter(firstData);
      if (_lastSubheaderKey !== key) {
        _lastSubheaderKey = key;
        subheader = subheaderRenderer(firstData);
      }
    }

    const shouldHideRows =
      hideRowsGetter && firstData && hideRowsGetter(firstData);

    return (
      <React.Fragment key={pivotRow1.key}>
        {subheader ? (
          <tr>
            <td colSpan={columns.length + (pivotRowsGetter.length > 1 ? 3 : 2)}>
              {subheader}
            </td>
          </tr>
        ) : null}

        {shouldHideRows ? null : (
          <>
            <tr key={pivotRow1.key} className="pivot-row-1">
              {pivotRowsGetter.length > 1 && (
                <td className="expand-td">
                  <IconButton
                    onClick={() => {
                      toggleExpandedKeys(pivotRow1.key);
                    }}
                    size="small"
                  >
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </td>
              )}
              <td className="pivot-col-1">{pivotRow1.label}</td>
              {pivotRowsGetter.length > 1 && <td className="pivot-col-2"></td>}
              <PivotTableColumns
                data={pivotRow1.rows}
                rowKeys={[pivotRow1.key]}
              />
            </tr>

            {pivotRowsGetter.length > 1 &&
              expanded &&
              (pivotRow1.pivotRows || []).map((pivotRow2) => {
                return (
                  <tr key={pivotRow2.key} className="pivot-row-2">
                    <td colSpan={2} className="pivot-col-1"></td>
                    <td className="pivot-col-2">{pivotRow2.label}</td>
                    <PivotTableColumns
                      data={pivotRow2.rows}
                      rowKeys={[pivotRow1.key, pivotRow2.key]}
                    />
                  </tr>
                );
              })}
          </>
        )}
      </React.Fragment>
    );
  });

  return (
    <div className="pivot-table-container">
      <table className="pivot-table">
        <thead>
          <tr>
            {pivotRowsGetter.length > 1 && <th className="expand-td"></th>}
            <th className="pivot-col-1">{pivotRowsLabel[0] || null}</th>
            {pivotRowsGetter.length > 1 && (
              <th className="pivot-col-2">{pivotRowsLabel[1] || null}</th>
            )}
            {columns.map((x, index) => {
              return (
                <th
                  key={isPivotTablePivotColumn(x) ? x.key : index}
                  style={{
                    width: x.width,
                    ...(x.style || {}),
                  }}
                >
                  {x.label}
                </th>
              );
            })}
            {showTotal && (
              <th
                style={
                  totalColumnWidth ? { width: totalColumnWidth } : undefined
                }
              >
                {totalColumnLabel || null}
              </th>
            )}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

function extractPivotRows<D>(
  data: D[],
  pivotRowGetter: PivotRowGetter<D> | PivotRowGetterWithSorting<D>
): PivotTableRow<D>[] {
  const {
    getter: func,
    sortBy = undefined,
    order = undefined,
  } = typeof pivotRowGetter === "function"
    ? { getter: pivotRowGetter }
    : pivotRowGetter;

  const found: { [key: string]: PivotTableRow<D> } = {};
  const result: PivotTableRow<D>[] = [];

  data.forEach((d) => {
    const _objOrArray = func(d);
    const array: {
      key: string;
      label: any;
    }[] = Array.isArray(_objOrArray) ? _objOrArray : [_objOrArray];

    array.forEach((obj) => {
      if (!found[obj.key]) {
        const row: PivotTableRow<D> = { ...obj, rows: [] };
        found[obj.key] = row;
        result.push(row);
      }
      found[obj.key].rows.push(d);
    });
  });

  if (sortBy) {
    const sortByGetterFunction: (
      f: (d: D[]) => any
    ) => (x: PivotTableRow<D>) => any = (f: (d: D[]) => any) => (x) => {
      return f(x.rows);
    };

    return orderBy(
      result,
      Array.isArray(sortBy)
        ? sortBy.map((f) => sortByGetterFunction(f))
        : [sortByGetterFunction(sortBy)],
      order ? (Array.isArray(order) ? order : [order]) : undefined
    );
  }

  return result;
}

function isPivotTablePivotColumn<D>(d: PivotTableColumn<D>): d is {
  key: string;
  label: any;
  render: (data: D[], rowKeys: any[]) => any;
  filter: (data: D, rowKeys: any[]) => boolean;
  width?: number | string;
} {
  return typeof (d as any).key !== "undefined";
}
