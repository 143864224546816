import NumberFormat from 'react-number-format'

export function NumberFormatCustom (props) {
  const { inputRef, onChange, ...other } = props

  const thousandSeparator = typeof props.thousandSeparator === 'undefined' ? true : props.thousandSeparator

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue
          }
        })
      }}
      thousandSeparator={thousandSeparator}
    />
  )
}
