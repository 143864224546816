import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "../../elements/DataTable";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import LineaStato from "../../constants/LineaStato.json";
import BadgeLineaStato from "../common/BadgeLineaStato";
import { dizionarioToOptions } from "../../utils/dizionarioToOptions";
import { ColoredButton } from "../../elements/ColoredButton";
import AddIcon from "@material-ui/icons/Add";
import { useCheckPermission } from "../../hooks/useCheckPermission";
import { useDataSource } from "../../hooks/DataSource/DataSource";
import { getTipologieProcedura as getTipologieProceduraAction } from "../../actions";
import { useEstendiLineaDialog } from "../Linea/EstendiLineaDialog";
import DateRangeIcon from "@material-ui/icons/DateRange";

export default function LineeAccordion({ legge, editing, regimi }) {
  const hasPermission = useCheckPermission();

  const tipologieProcedura = useDataSource({
    initialData: [],
    loadAction: getTipologieProceduraAction,
    autoLoad: true,
  });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Linee</AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={legge}
          sourceKey="linee"
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 80,
              header: " ",
              renderCell: (_, row) => {
                return (
                  <LineaActions
                    isPluriennale={legge.getValue("isPluriennale")}
                    linea={row}
                  />
                );
              },
              type: "text",
            },
            {
              path: ["nome"],
              header: "Nome",
              width: 400,
              sortable: true,
              type: "text",
            },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              width: 250,
              type: "enum",
              options: LineaStato,
              renderCell: (value) => {
                return <BadgeLineaStato stato={value} />;
              },
            },
            {
              sortable: true,
              path: ["anno"],
              width: 250,
              header: "Anno",
              type: "number",
            },
            {
              sortable: true,
              path: ["tipologiaProcedura", "nome"],
              width: 250,
              header: "Tipologia procedura",
              type: "enum",
              options: dizionarioToOptions(tipologieProcedura),
            },
            {
              sortable: true,
              path: ["regime", "nome"],
              width: 250,
              header: "Regime",
              type: "enum",
              options: dizionarioToOptions(regimi),
            },
          ]}
          topToolbar={null}
          bottomToolbar={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75em",
              }}
            >
              {(!legge.getValue("isPluriennale") ||
                legge.getValue("linee", []).length === 0) &&
              !editing ? (
                hasPermission("crea_linea") ? (
                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    onClick={null}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                    to={`/linee/new?idLegge=${legge.getValue("id")}`}
                    component={Link}
                  >
                    Aggiungi linea
                  </ColoredButton>
                ) : null
              ) : null}{" "}
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          isRowExpandable={false}
          messages={{
            noRows: "Nessuna linea",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function LineaActions({ isPluriennale, linea }) {
  const lineaDataSource = useDataSource({
    autoLoad: false,
    initialData: linea,
  });
  const { openEstendiLineaDialog, estendiLineaDialog } =
    useEstendiLineaDialog(lineaDataSource);
  return (
    <>
      <IconButton to={`/linee/${linea.id}`} component={Link}>
        <LaunchIcon />
      </IconButton>
      {isPluriennale && (
        <Tooltip title="Estendi">
          <IconButton onClick={openEstendiLineaDialog}>
            <DateRangeIcon />
          </IconButton>
        </Tooltip>
      )}
      {estendiLineaDialog}
    </>
  );
}
