import React, { useCallback } from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getProcedure as getProcedureAction } from "app/actions";
import { Container } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { useHistory } from "react-router-dom";
import proceduraHasVersioneSuccessiva from "app/utils/proceduraHasVersioneSuccessiva";
import StarIcon from "@material-ui/icons/Star";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import useSideModal from "../common/useSideModal";
import Procedura from "./Procedura";
import TableActionButtons from "../common/TableActionButtons";
import ProceduraStato from "app/constants/ProceduraStato.json";
import BadgeProceduraStato from "../common/BadgeProceduraStato";

export default function ProcedureTable() {
  const { openSideModal, sideModal } = useSideModal();
  const history = useHistory();

  const procedure = useDataSource({
    loadAction: getProcedureAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const handleProceduraUpdated = useCallback(
    (procedura) => {
      procedure.changeValue(procedura.id, procedura);
    },
    [procedure]
  );

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_commessa"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {sideModal}
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        Commesse
      </h1>
      {procedure.loading ? (
        "Caricamento lista commesse ..."
      ) : procedure.loadError ? (
        "Errore durante il caricamento della lista commesse"
      ) : (
        <DataTable
          source={procedure}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 130,
              header: " ",
              renderCell: (value, row) => {
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TableActionButtons
                      link={`/procedure/${value}`}
                      openSideModal={() => {
                        openSideModal(
                          Procedura,
                          {
                            id: value,
                            useIdFromProps: true,
                            onEditSuccess: handleProceduraUpdated,
                            edit: true,
                          },
                          value
                        );
                      }}
                      canEdit={__hasPermission("modifica_commessa")}
                      editItem={() =>
                        history.push(`/procedure/${value}?edit=true`)
                      }
                    />
                    {proceduraHasVersioneSuccessiva(row) ? (
                      <StarIcon
                        style={{
                          marginBottom: -9,
                          color: "#ffd600",
                        }}
                      />
                    ) : null}{" "}
                  </div>
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            { path: ["nome"], header: "Nome", type: "text", sortable: true },
            {
              path: ["mandato", "clientePrincipale", "ragioneSociale"],
              header: "Cliente",
              type: "text",
              sortable: true,
            },
            {
              path: ["operatore", "cognome"],
              header: "Operatore",
              type: "text",
              sortable: true,
            },
            {
              path: ["stato"],
              header: "Stato",
              type: "enum",
              width: 250,
              sortable: true,
              options: ProceduraStato,
              defaultHiddenFilterOptions: {
                type: "enum",
                values: ["archiviata"],
                isNot: true,
              },
              renderCell: (value) => {
                return <BadgeProceduraStato stato={value} />;
              },
              renderRawValue: (value) => {
                return (
                  ProceduraStato.find((stato) => stato.value === value)
                    ?.label ?? value
                );
              },
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              <DataTableExportButtons filename={"Commesse"} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessuna commessa",
          }}
        />
      )}{" "}
    </Container>
  );
}
