import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getMandatiForCliente as getMandatiForClienteAction } from "app/actions";
import { Container, IconButton } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import MandatoStato from "app/constants/MandatoStato";
import BadgeMandatoStato from "app/components/common/BadgeMandatoStato";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { useToast } from "../../hooks/useToast";

export default function ClienteMandatiTable(props) {
  const showToast = useToast();
  const mandati = useDataSource({
    initialData: [],
    loadAction: getMandatiForClienteAction,
    itemId: props.clienteId,
    onLoadError: (response) => {
      showToast("Errore durante il caricamento dei mandati dell'azienda", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  return (
    <Container
      style={{
        _height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {mandati.loading ? (
        "Caricamento lista mandati ..."
      ) : mandati.loadError ? (
        "Errore durante il caricamento della lista mandati"
      ) : (
        <DataTable
          source={mandati}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 40,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <IconButton to={`/mandati/${value}`} component={Link}>
                    <LaunchIcon />
                  </IconButton>
                );
              },
              type: "text",
            },
            { path: ["nome"], header: "Nome", type: "text" },
            {
              path: ["formatStrumento", "nome"],
              header: "Format strumento",
              type: "text",
            },
            {
              path: ["stato"],
              type: "enum",
              options: MandatoStato.filter((x) => x),
              header: "Stato mandato",
              renderCell: (value, row, rowIndex, rowSource) => {
                return <BadgeMandatoStato stato={value} />;
              },
            },
            {
              path: ["commerciale", "cognome"],
              header: "Commerciale",
              type: "text",
            },
            {
              path: ["procedure", 0, "operatore", "cognome"],
              header: "Operatore",
              type: "text",
            },
          ]}
          topToolbar={null}
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessun mandato",
          }}
        />
      )}{" "}
    </Container>
  );
}
