import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import FileBox from "app/components/common/FileBox";
import DocumentoClientePickerForAttivita from "app/components/common/DocumentoClientePickerForAttivita";
import DocumentoClientePickerForProcedura from "app/components/common/DocumentoClientePickerForProcedura";
import { AsyncSelect } from "app/elements/AsyncSelect";
import DocumentoAttivitaStato from "app/constants/DocumentoAttivitaStato.json";
import DeleteIcon from "@material-ui/icons/Delete";
import { ColoredButton } from "app/elements/ColoredButton";
import AddIcon from "@material-ui/icons/Add";
import { TableColumn } from "../../elements/DataTable/types";

export type AccordionDocumentiProps = {
  title?: string;
  documenti: any;
  readOnly?: boolean;
  canAddDocumenti?: boolean;
  canRemoveDocumenti?: boolean;
  parentKey?: "idAttivita" | "idProcedura";
  showTemplate?: boolean;
  parentId?: number;
  tipoNuovoDocumento?:
    | "documentoCommessaInterno"
    | "documentoFaseNegoziazioneOfferta";
};

export default function AccordionDocumenti({
  title = "Documenti da caricare",
  documenti,
  readOnly = false,
  canAddDocumenti = false,
  canRemoveDocumenti = false,
  showTemplate = false,
  parentKey,
  parentId,
  tipoNuovoDocumento,
}: AccordionDocumentiProps) {
  const readOnlyOrSaving = readOnly;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>

      <AccordionDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginBottom: 8,
          }}
        >
          <DataTable
            source={documenti}
            columns={
              [
                {
                  path: ["nome"],
                  header: "Nome documento",
                  sortable: false,
                  type: "text",
                  renderCell: (value, row, rowIndex, rowSource) => {
                    const opzionale = rowSource.getValue("opzionale", false);
                    return rowSource.getValue("idFormatDocumentoAttivita") ? (
                      <div>
                        <span>{value}</span>
                        {opzionale && (
                          <span style={{ fontStyle: "italic", color: "gray" }}>
                            {" "}
                            (opzionale)
                          </span>
                        )}
                      </div>
                    ) : (
                      <TextField
                        autoComplete=""
                        color="primary"
                        defaultValue=""
                        disabled={readOnly}
                        error={rowSource.getValidationErrors("nome").length > 0}
                        helperText={rowSource.getValidationErrors("nome")[0]}
                        onBlur={() => {
                          rowSource.runValidation("nome");
                        }}
                        onChange={(event) => {
                          const value = event.target.value;

                          rowSource.changeValue("nome", value);
                        }}
                        size="small"
                        value={rowSource.getValue("nome", "")}
                        variant={readOnly ? "standard" : "outlined"}
                        InputProps={{
                          readOnly,
                        }}
                        fullWidth
                      />
                    );
                  },
                },
                showTemplate && {
                  path: ["fileIdTemplate"],
                  header: "Template",
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return rowSource.getValue("idFormatDocumentoAttivita") ? (
                      <FileBox
                        source={rowSource}
                        sourceKey="template"
                        readOnly
                      />
                    ) : (
                      ""
                    );
                  },
                  type: "text",
                },
                {
                  path: ["fileId"],
                  header: "File",
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return rowSource.getValue("tipo") === "documentoCliente" ? (
                      parentKey === "idAttivita" ? (
                        <DocumentoClientePickerForAttivita
                          idAttivita={parentId}
                          source={rowSource}
                          sourceKey="file"
                          readOnly={readOnlyOrSaving}
                          onFileRemoved={() =>
                            rowSource.changeValue("stato", "non-caricato")
                          }
                          onFileAdded={() =>
                            rowSource.changeValue("stato", "caricato")
                          }
                        />
                      ) : (
                        <DocumentoClientePickerForProcedura
                          idProcedura={parentId}
                          source={rowSource}
                          sourceKey="file"
                          readOnly={readOnlyOrSaving}
                          onFileRemoved={() =>
                            rowSource.changeValue("stato", "non-caricato")
                          }
                          onFileAdded={() =>
                            rowSource.changeValue("stato", "caricato")
                          }
                        />
                      )
                    ) : (
                      <FileBox
                        source={rowSource}
                        sourceKey="file"
                        readOnly={readOnlyOrSaving}
                        onFileRemoved={() =>
                          rowSource.changeValue("stato", "non-caricato")
                        }
                        onFileAdded={() =>
                          rowSource.changeValue("stato", "caricato")
                        }
                      />
                    );
                  },
                  type: "text",
                },
                {
                  path: ["stato"],
                  header: "Stato",
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return (
                      <FormControl
                        disabled={readOnlyOrSaving}
                        error={
                          rowSource.getValidationErrors("stato").length > 0
                        }
                        fullWidth
                        size="small"
                      >
                        <InputLabel
                          error={
                            rowSource.getValidationErrors("stato").length > 0
                          }
                          variant="outlined"
                        >
                          {}
                        </InputLabel>
                        <AsyncSelect
                          autoWidth
                          onChange={(event) => {
                            const value = event.target.value;

                            rowSource.changeValue("stato", value || null);

                            setTimeout(() => {
                              rowSource.runValidation("stato");
                            }, 0);
                          }}
                          onClose={() => {
                            rowSource.runValidation("stato");
                          }}
                          value={rowSource.getValue("stato") || ""}
                          variant="outlined"
                          disabled={readOnlyOrSaving}
                        >
                          {DocumentoAttivitaStato.map((option) => {
                            const file = rowSource.getValue("file", null);
                            const isFileSelected =
                              !!file && !!file.idVersioneCorrente;
                            return (
                              <MenuItem
                                key={option.value}
                                disabled={
                                  option.requiresFile !== isFileSelected
                                }
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            );
                          })}{" "}
                        </AsyncSelect>
                        {rowSource.getValidationErrors("stato")[0] ? (
                          <FormHelperText>
                            {rowSource.getValidationErrors("stato")[0]}
                          </FormHelperText>
                        ) : null}{" "}
                      </FormControl>
                    );
                  },
                  sortable: false,
                  type: "text",
                },
                canRemoveDocumenti && {
                  path: ["id"],
                  header: "Azioni",
                  width: 64,
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return (
                      <div>
                        {!rowSource.getValue("idFormatDocumentoAttivita") ? (
                          <IconButton
                            disabled={readOnly}
                            onClick={() => {
                              rowSource.delete();
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}{" "}
                      </div>
                    );
                  },
                  disableColumnFilter: true,
                  disableGlobalFilter: true,
                  sortable: false,
                  type: "text",
                },
              ].filter((x) => !!x) as TableColumn[]
            }
            pageSize={1000000}
            size="small"
            messages={{
              noRows: "Nessun documento da caricare",
            }}
          />
          <div>
            {canAddDocumenti && !readOnly && (
              <ColoredButton
                style={{
                  marginTop: 8,
                }}
                onClick={() => {
                  documenti.create({
                    tipo: tipoNuovoDocumento,
                  });
                }}
                color="secondary"
                size="small"
                startIcon={<AddIcon />}
                variant="outlined"
              >
                Aggiungi documento
              </ColoredButton>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
