import React from "react";
import {
  Container,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import UserPicker from "app/components/common/UserPicker";
import ContattoPicker from "app/components/common/ContattoPicker";
import RichTextEditor from "app/components/common/RichTextEditor";

export default function FormCliente(props) {
  const variant = props.readOnly ? "standard" : "outlined";

  return (
    <Container disableGutters={true} fixed={false} maxWidth={false}>
      <Grid container={true} item={false} spacing={3}>
        <Grid container={false} item={true} lg={6} md={6} xs={12}>
          <TextField
            className="input-dati-atoka"
            disabled={props.source.loading || props.source.editing}
            error={
              props.source.getValidationErrors("ragioneSociale").length > 0
            }
            fullWidth={true}
            helperText={props.source.getValidationErrors("ragioneSociale")[0]}
            label="Ragione Sociale"
            onBlur={() => {
              props.source.runValidation("ragioneSociale");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "ragioneSociale",

                value
              );
            }}
            type="text"
            value={props.source.getValue("ragioneSociale", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid container={false} item={true} lg={3} md={3} xs={12}>
          <DizionarioSoftInput
            className="input-dati-atoka"
            style={{}}
            variant={variant}
            readOnly={props.readOnly}
            source={props.source}
            sourceKey="tipoAzienda"
            label="Tipo azienda"
            fullWidth={true}
            dizionarioSoft="tipoAzienda"
          />
        </Grid>
        <Grid container={false} item={true} md={4} xs={12}>
          <TextField
            className="input-dati-atoka"
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("partitaIva").length > 0}
            fullWidth={true}
            helperText={
              props.source.getValidationErrors("partitaIva")[0] ===
              "Questo valore deve essere univoco"
                ? "Esiste già un’azienda con la stessa Partita IVA nel sistema"
                : props.source.getValidationErrors("partitaIva")[0]
            }
            label="Partita Iva"
            onBlur={() => {
              props.source.runValidation("partitaIva");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "partitaIva",

                value
              );
            }}
            type="text"
            value={props.source.getValue("partitaIva", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid container={false} item={true} md={5} xs={12}>
          <TextField
            className="input-dati-atoka"
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("cf").length > 0}
            fullWidth={true}
            helperText={
              props.source.getValidationErrors("cf")[0] ===
              "Questo valore deve essere univoco"
                ? "Esiste già un’azienda con lo stesso codice fiscale nel sistema"
                : props.source.getValidationErrors("cf")[0]
            }
            label="Codice Fiscale"
            onBlur={() => {
              props.source.runValidation("cf");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "cf",

                value
              );
            }}
            type="text"
            value={props.source.getValue("cf", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid container={false} item={true} md={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.source.getValue("isCliente", false)}
                disabled={props.source.loading || props.source.editing}
                onChange={(event) => {
                  const value = event.target.checked;

                  props.source.changeValue(
                    "isCliente",

                    value
                  );
                }}
                size="small"
                value={props.source.getValue("isCliente", "")}
              />
            }
            disabled={true}
            label="Cliente"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={props.source.getValue("isFornitore", false)}
                disabled={props.source.loading || props.source.editing}
                onChange={(event) => {
                  const value = event.target.checked;

                  props.source.changeValue(
                    "isFornitore",

                    value
                  );
                }}
                size="small"
                value={props.source.getValue("isFornitore", "")}
              />
            }
            disabled={props.readOnly}
            label="Fornitore"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={props.source.getValue("isPartner", false)}
                disabled={props.source.loading || props.source.editing}
                onChange={(event) => {
                  const value = event.target.checked;

                  props.source.changeValue(
                    "isPartner",

                    value
                  );
                }}
                size="small"
                value={props.source.getValue("isPartner", "")}
              />
            }
            disabled={props.readOnly}
            label="Partner"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={props.source.getValue("isConsensoGDPR", false)}
                disabled={props.source.loading || props.source.editing}
                onChange={(event) => {
                  const value = event.target.checked;

                  props.source.changeValue(
                    "isConsensoGDPR",

                    value
                  );
                }}
                size="small"
                value={props.source.getValue("isConsensoGDPR", "")}
              />
            }
            disabled={props.readOnly}
            label="Consenso GDPR"
          />
        </Grid>
        <Grid container={false} item={true} md={4} xs={12}>
          <UserPicker
            variant={variant}
            readOnly={props.readOnly}
            source={props.source}
            sourceKey="idCommerciale"
            label="Commerciale"
          />
        </Grid>
        <Grid container={false} item={true} md={4} xs={12}>
          <ContattoPicker
            variant={variant}
            readOnly={props.readOnly}
            source={props.source}
            sourceKey="idSegnalatore"
            label="Segnalatore"
          />
        </Grid>
        <Grid container={false} item={true} md={4} xs={12}>
          <ContattoPicker
            variant={variant}
            readOnly={props.readOnly}
            source={props.source}
            sourceKey="idCommercialistaContatto"
            label="Commercialista"
          />
        </Grid>
        <Grid container={false} item={true} md={9} sm={12}>
          <RichTextEditor
            __to_reload={props.source?.getValue("note")}
            source={props.source}
            sourceKey="note"
            readOnly={props.readOnly}
            label="Note"
          />
        </Grid>
        <Grid container={false} item={true} md={3} sm={12}>
          <DizionarioSoftInput
            variant={variant}
            readOnly={props.readOnly}
            source={props.source}
            sourceKey="keywords"
            label="Keywords"
            fullWidth
            dizionarioSoft="keywordsCliente"
            multiple
          />
        </Grid>
        {!props.isCreateForm ? (
          <>
            <Grid container={false} item={true} lg={4} md={4} sm={12}>
              <TextField
                disabled={true}
                error={props.source.getValidationErrors("idCrea").length > 0}
                fullWidth={true}
                helperText={props.source.getValidationErrors("idCrea")[0]}
                label="Id CREA"
                onBlur={() => {
                  props.source.runValidation("idCrea");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  props.source.changeValue(
                    "idCrea",

                    value
                  );
                }}
                type="text"
                value={props.source.getValue("idCrea", "")}
                variant={variant}
              />
            </Grid>

            <Grid container={false} item={true} lg={4} md={4} sm={12}>
              <TextField
                disabled={true}
                error={props.source.getValidationErrors("preCodice").length > 0}
                fullWidth={true}
                helperText={props.source.getValidationErrors("preCodice")[0]}
                label="Pre codice"
                onBlur={() => {
                  props.source.runValidation("preCodice");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  props.source.changeValue(
                    "preCodice",

                    value
                  );
                }}
                type="text"
                value={props.source.getValue("preCodice", "")}
                variant={variant}
              />
            </Grid>
          </>
        ) : null}{" "}
      </Grid>
    </Container>
  );
}
