// <script>

export default function authReducer(state = {}, action) {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        loggingIn: true,
      };

    case "LOGIN_SUCCESS":
      return {
        loggedIn: true,
        user: action.response.data,
      };

    case "LOGIN_FAILURE":
      return {};

    case "LOGOUT_REQUEST":
      return {
        ...state,
        loggingOut: true,
      };

    case "LOGOUT_SUCCESS":
      return {};

    case "LOGOUT_FAILURE":
      return {
        ...state,
        loggingOut: undefined,
      };

    default:
      return state;
  }
}
