import React, { useCallback } from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getAllInterazioniWithScadenza as getAllInterazioniWithScadenzaAction } from "app/actions";
import { Container, IconButton, Typography } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import Cliente from "app/components/Clienti/Cliente";
import TabIcon from "@material-ui/icons/Tab";
import RichTextEditor from "app/components/common/RichTextEditor";
import DashboardColoredCell from "app/components/Dashboard/DashboardColoredCell";
import { DashboardFilterUser } from "./DashboardFilterUser";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { useApi } from "../../hooks/useApi";

export default function InterazioniClientiDashboard(props) {
  const openSideModal = props.openSideModal;

  const interazioni = useDataSource({
    loadAction: getAllInterazioniWithScadenzaAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const loadInterazioniClienti = useApi(getAllInterazioniWithScadenzaAction);
  const handleInterazioniUpdated = useCallback(() => {
    loadInterazioniClienti.callApi().then((response) => {
      const freshInterazioni = response.data;
      const toRemove = Object.fromEntries(
        interazioni.data.map((i) => [i.id, true])
      );
      for (const interazione of freshInterazioni) {
        delete toRemove[interazione.id];

        const exists = interazioni.data.find((i) => i.id === interazione.id);
        if (exists) {
          interazioni.changeValue(interazione.id, interazione);
        } else {
          interazioni.create(interazione);
        }
      }

      for (const interazioneId of Object.keys(toRemove)) {
        interazioni.delete(parseInt(interazioneId));
      }
    });
  }, [interazioni]);

  return (
    <Container
      style={{
        _height: "calc(50vh - 80px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {interazioni.loading ? (
        "Caricamento lista interazioni..."
      ) : interazioni.loadError ? (
        "Errore durante il caricamento della lista interazioni"
      ) : (
        <DataTable
          source={interazioni}
          columns={[
            {
              path: ["idAzienda"],
              sortable: false,
              disableColumnFilter: true,
              width: 105,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div>
                    <IconButton
                      to={`/azienda/${value}?interazioni=true`}
                      component={Link}
                    >
                      <LaunchIcon />
                    </IconButton>

                    <IconButton
                      onClick={(event) => {
                        openSideModal(
                          Cliente,
                          {
                            id: value,
                            useIdFromProps: true,
                            onInterazioniEditSuccess: handleInterazioniUpdated,
                            onInterazioneCreateSuccess:
                              handleInterazioniUpdated,
                            onInterazioneDeleteSuccess:
                              handleInterazioniUpdated,
                            edit: true,
                            interazioni: true,
                          },
                          value
                        );
                      }}
                    >
                      <TabIcon />
                    </IconButton>
                  </div>
                );
              },
              type: "text",
            },
            {
              sortable: false,
              path: ["azienda", "ragioneSociale"],
              header: "Azienda",
              type: "text",
            },
            {
              sortable: false,
              disableColumnFilter: true,
              path: ["descrizione"],
              header: "Descrizione",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <RichTextEditor
                    __to_reload={value}
                    source={rowSource}
                    sourceKey="descrizione"
                    readOnly={true}
                    className="RichTextEditor-in-table"
                  />
                );
              },
              type: "text",
            },
            {
              sortable: false,
              path: ["utente", "cognome"],
              header: "Utente",
              type: "text",
            },
            {
              path: ["dataProgrammata"],
              type: "date",
              header: "Data Programmata",
              renderCell: (value, row, rowIndex, rowSource) => {
                return <DashboardColoredCell value={value} />;
              },
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
                width: "100%",
                display: "inline-block",
              }}
            >
              <Typography gutterBottom={false} noWrap={false} variant="h6">
                Interazioni con clienti
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <DashboardFilterUser path={["utente", "cognome"]} />
                </div>
              </Typography>
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          pageSize={10}
          size="small"
          initialSortBy={[['["dataProgrammata"]', "ASC"]]}
          saveCurrentState="tInterazioniClienti"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessuna interazione",
          }}
        />
      )}{" "}
    </Container>
  );
}
