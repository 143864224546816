import React, { useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getDizionarioSoft as getDizionarioSoftAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export type DizionarioSoftInputProps = {
  label: string;
  variant: "outlined" | "standard" | "filled";
  source: any;
  sourceKey: string;
  readOnly: boolean;
  dizionarioSoft: string;
  multiple?: boolean;
  prohibitNewValues?: boolean;
};

export default function DizionarioSoftInput({
  label,
  variant,
  source,
  sourceKey,
  readOnly,
  dizionarioSoft,
  multiple = false,
  prohibitNewValues = false,
  ...otherProps
}: DizionarioSoftInputProps) {
  const showToast = useToast();
  const [loadedStarted, setLoadedStarted] = useState(false);

  const dizionario = useDataSource({
    initialData: [],
    loadAction: getDizionarioSoftAction,
    autoLoad: false,
    itemId: dizionarioSoft || "...",
    onLoadSuccess: () => {
      setLoadedStarted(true);
    },
    onLoadError: () => {
      showToast("Errore durante il caricamento del dizionario", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const sourceError = source.getValidationErrors(sourceKey)[0] || null;

  return (
    <Autocomplete
      disabled={readOnly}
      fullWidth={true}
      loading={dizionario.loading}
      loadingText="Caricamento..."
      multiple={multiple}
      options={dizionario.data}
      size="small"
      freeSolo={!prohibitNewValues}
      noOptionsText={prohibitNewValues ? "Nessuna opzione" : undefined}
      value={source.getValue(sourceKey) || (multiple ? [] : "")}
      autoSelect
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            {...otherProps}
            variant={variant || "outlined"}
            label={label}
            error={!!sourceError}
            helperText={sourceError}
          />
        );
      }}
      getOptionLabel={(option) => {
        return `${option || ""}`;
      }}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {option}
          </div>
        );
      }}
      onChange={(event, newValue) => {
        if (!newValue) {
          source.changeValue(sourceKey, null);
          return;
        }

        source.changeValue(sourceKey, newValue || null);
      }}
      onFocus={() => {
        if (!loadedStarted) {
          setLoadedStarted(true);
          dizionario.load();
        }
      }}
    />
  );
}
