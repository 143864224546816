import React from "react";
import UserFilterSelector from "../common/UserFilterSelector";

export function ScadenziarioFilters({ userFilter, selectUser }) {
  //   const isWeekly = !isDaily;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
      <UserFilterSelector
        returnValue="id"
        defaultSelection="all"
        onChange={selectUser}
        label="Operatore"
        value={userFilter}
      />
    </div>
  );
}
