export default function BeneLabel({
  bene,
  showPreText = false,
  showIcon = false,
  inline = false,
  style = {},
  fontWeight = "bold",
}) {
  return (
    <div style={{ display: inline ? "inline-block" : "block", ...style }}>
      {showIcon && (
        <img
          src="/imgs/bene-icon.png"
          style={{
            height: 13,
            marginBottom: -2,
            marginRight: 3,
          }}
        />
      )}
      {showPreText && (
        <span style={{ fontSize: "0.95em", color: "#555" }}>Bene: </span>
      )}
      <span style={{ fontWeight: fontWeight }}>{bene.nome}</span>
    </div>
  );
}
