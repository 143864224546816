import { useState } from 'react'

export type UseDialogReturnType = {
  isOpen: boolean
  open: () => void
  close: () => void
}

export function useDialog (): UseDialogReturnType {
  const [isOpen, setIsOpen] = useState(false)

  function open () {
    setIsOpen(true)
  }

  function close () {
    setIsOpen(false)
  }

  return {
    isOpen,
    open,
    close
  }
}
