import { useCallback, useEffect, useRef, useState } from "react";
import {
  DataSourceCreateOptions,
  DataSourceInterface,
  useDataSource,
} from "./DataSource";
import { find, set } from "lodash";
import { QueryFilters } from "server/utils/tableUtils/requestFilter";

export function usePickerDataSource(
  props: DataSourceCreateOptions & {
    onInitialLoadSuccess?;
    onAllLoadSuccess?;
  } = {},
  searchFields: string[],
  selectedId: number | null
): DataSourceInterface & { onSearchTextChange: (v: string | null) => void } {
  const propsRef = useRef<typeof props>(props);
  propsRef.current = props;
  const onInitialLoadSuccessCalledRef = useRef<boolean>(false);

  if (props.onLoadSuccess) {
    console.error(
      "usePickerDataSource: onLoadSuccess - don't use: use onInitialLoadSuccess or onAllLoadSuccess"
    );
  }

  const onLoadSuccess = useCallback((response: any) => {
    if (!onInitialLoadSuccessCalledRef.current) {
      propsRef.current.onInitialLoadSuccess?.(response);
      onInitialLoadSuccessCalledRef.current = true;
    }
    propsRef.current.onAllLoadSuccess?.(response);
  }, []);

  const dataSource = useDataSource({
    ...props,
    onLoadSuccess,
    autoLoad: false,
  });

  const loadedOnceRef = useRef<boolean>(false);
  const lastSelectedId = useRef<number | null>(selectedId);
  const lastSearchText = useRef<string | null>(null);

  let [searchText, onSearchTextChange] = useState<string | null>("");
  searchText = searchText || "";

  useEffect(() => {
    if (!loadedOnceRef.current) {
      dataSource.load(
        createQuery(dataSource, searchFields, searchText, selectedId)
      );
    } else if (
      lastSelectedId.current !== selectedId ||
      lastSearchText.current !== searchText
    ) {
      const found =
        selectedId && find(dataSource.data, (x) => x.id === selectedId);
      if (lastSearchText.current !== searchText || (selectedId && !found)) {
        dataSource.load(
          createQuery(dataSource, searchFields, searchText, selectedId)
        );
      }
    }
    loadedOnceRef.current = true;
    lastSelectedId.current = selectedId;
    lastSearchText.current = searchText;
  }, [selectedId, searchText]);

  return { ...dataSource, onSearchTextChange };
}

function createQuery(
  dataSource: DataSourceInterface,
  searchFields: string[],
  searchText: string | null,
  selectedId: number | null
): any {
  const query: QueryFilters = {
    pagination: {
      page: 0,
      pageSize: 50,
    },
  };

  if (searchText) {
    query.globalSearch = {
      value: searchText,
      textCols: searchFields,
    };
  }
  if (selectedId) {
    query.includeIds = [selectedId];
  }

  const queryString = JSON.stringify(query);
  return { query: "?q=" + encodeURIComponent(queryString) };
}
