import React, { useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useDialog } from "app/hooks/useDialog";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  editFattura as editFatturaAction,
  deleteFatturaAnnullata as deleteFatturaAnnullataAction,
} from "app/actions";
import { useApi } from "app/hooks/useApi";
import DialogFattura from "app/components/Fatture/DialogFattura";
import { DataTable } from "app/elements/DataTable";
import { Button, Checkbox, IconButton, Tooltip } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import moment from "moment";
import FatturaStato from "app/constants/FatturaStato";
import BadgeFatturaStato from "app/components/common/BadgeFatturaStato";
import numeral from "numeral";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import DialogInvioMassivo from "./DialogInvioMassivo";
import DialogPagamentoMassivo from "./DialogPagamentoMassivo";

export default function FattureTable_abstract_internal(props) {
  const hasPermission = useCheckPermission();

  const { fatture } = props;

  const showToast = useToast();

  const fatturaDialog = useDialog();
  const invioMassivoDialog = useDialog();
  const pagamentoMassivoDialog = useDialog();

  const [editingFatturaId, setEditingFatturaId] = useState(null);

  const editingFattura = useDataSource({
    parent: fatture,
    pathInParent: [
      {
        id: editingFatturaId,
      },
    ],
    initialData: {},
    editAction: editFatturaAction,
    onEditSuccess: (response) => {
      showToast("Fattura modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      fatturaDialog.close();
    },
    onEditError: (response) => {
      showToast("Errore durante la modifica della fattura", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const deleteFatturaAnnullataApi = useApi(deleteFatturaAnnullataAction, {
    onSuccess: (response) => {
      showToast("Fattura eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      fatture.load();
    },
    onError: (response) => {
      showToast("Errore durante l'eliminazione della fattura", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_fattura"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return fatture.loading ? (
    "Caricamento lista fatture ..."
  ) : fatture.loadError ? (
    "Errore durante il caricamento della lista fatture"
  ) : (
    <>
      <DialogFattura
        dialog={fatturaDialog}
        fatturaSource={editingFattura}
        isApprovare={props.isApprovare}
        onDeleteFatturaAnnullata={() =>
          deleteFatturaAnnullataApi.callApi(editingFattura.data.id)
        }
      />

      <DialogInvioMassivo
        dialog={invioMassivoDialog}
        fattureSource={fatture}
        onInvioMassivo={() => {
          fatture.load();
        }}
      />

      <DialogPagamentoMassivo
        dialog={pagamentoMassivoDialog}
        fattureSource={fatture}
        onPagamentoMassivo={() => {
          fatture.load();
        }}
      />

      <DataTable
        source={fatture}
        footerRows={2}
        columns={[
          {
            path: ["id"],
            sortable: false,
            disableColumnFilter: true,
            width: 50,
            header: " ",
            renderCell: (value, row, rowIndex, rowSource) => {
              return (
                <div>
                  {hasPermission("modifica_fattura") ? (
                    <IconButton
                      onClick={(event) => {
                        setEditingFatturaId(row.id);

                        fatturaDialog.open();
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  ) : null}{" "}
                </div>
              );
            },
            type: "text",
            hiddenInExport: true,
          },
          {
            path: ["numeroFattura"],
            width: 180,
            header: "Numero fattura",
            renderFooterColSpan: 4,
            renderFooterCell: (rowIndex, rows, source) => {
              return rowIndex === 0 ? "Subtotale Pagina" : "TOTALE";
            },
            type: "text",
          },
          {
            path: ["dataFattura"],
            width: 120,
            header: "Data",
            renderCell: (value, row, rowIndex, rowSource) => {
              return value ? moment(value).format("DD/MM/YYYY") : "";
            },
            type: "text",
          },
          {
            path: ["stato"],
            header: "Stato",
            sortable: true,
            width: 170,
            type: "enum",
            options: FatturaStato,
            renderCell: (value, row, rowIndex, rowSource) => {
              return <BadgeFatturaStato stato={value} />;
            },
            renderRawValue: (value) => {
              return (
                FatturaStato.find((stato) => stato.value === value)?.label ??
                value
              );
            },
          },
          {
            path: ["dataPagamento"],
            width: 80,
            header: "Pagata",
            renderCell: (value, row, rowIndex, rowSource) => {
              const pagata = !!value;
              let content = (
                <Checkbox
                  checked={pagata}
                  readOnly
                  color="primary"
                  style={{ pointerEvents: "none" }}
                />
              );

              if (pagata) {
                const data = moment(value).format("DD/MM/YYYY");
                content = (
                  <Tooltip title={data} placement="right">
                    {content}
                  </Tooltip>
                );
              }

              return (
                <div
                  style={
                    {
                      // display: "flex",
                      // justifyContent: "center",
                    }
                  }
                >
                  {content}
                </div>
              );
            },
            renderRawValue: (value) => value,
            type: "boolean",
          },
          !props.hideCliente
            ? {
                path: ["cliente", "ragioneSociale"],
                width: 200,
                header: "Cliente",
                type: "text",
              }
            : null,
          {
            path: ["banca", "banca"],
            width: 180,
            header: "Conto bancario",
            type: "text",
          },
          {
            path: ["terminiPagamento"],
            width: 180,
            header: "Termini di pagamento",
            type: "text",
          },
          {
            path: ["importoMaturato"],
            width: 190,
            disableColumnFilter: true,
            disableGlobalFilter: true,
            type: "number",
            header: "Importo maturato",
            align: "right",
            renderCell: (value, row, rowIndex, rowSource) => {
              return typeof value === "number"
                ? numeral(value).format("0,0.00") + " €"
                : "";
            },
            renderRawValue: (value) => value,
            exportNumberFormat: { thousandSeparator: true, decimals: 2 },
            renderFooterCell: (rowIndex, rows, source) => {
              const array = (rowIndex === 0 ? rows : source.data) || [];

              const footerValue = array.reduce(
                (s, x) => s + (x.importoMaturato || 0),
                0
              );

              return typeof footerValue === "number"
                ? numeral(footerValue).format("0,0.00") + " €"
                : "";
            },
          },
          {
            path: ["importoEffettivo"],
            width: 190,
            disableColumnFilter: true,
            disableGlobalFilter: true,
            type: "number",
            header: "Importo effettivo",
            align: "right",
            renderCell: (value, row, rowIndex, rowSource) => {
              return typeof value === "number"
                ? numeral(value).format("0,0.00") + " €"
                : "";
            },
            renderRawValue: (value) => value,
            exportNumberFormat: { thousandSeparator: true, decimals: 2 },
            renderFooterCell: (rowIndex, rows, source) => {
              const array = (rowIndex === 0 ? rows : source.data) || [];

              const footerValue = array.reduce(
                (s, x) => s + (x.importoEffettivo || 0),
                0
              );

              return typeof footerValue === "number"
                ? numeral(footerValue).format("0,0.00") + " €"
                : "";
            },
          },
        ]}
        topToolbar={
          <div
            style={{
              padding: "1em",
              display: "flex",
              gap: 4,
            }}
          >
            {props.exportFilename ? (
              <DataTableExportButtons filename={props.exportFilename} />
            ) : null}
            {props.showInvioMassivo && (
              <Button
                variant="outlined"
                onClick={() => invioMassivoDialog.open()}
              >
                Invio massivo
              </Button>
            )}
            {props.showPagamentoMassivo && (
              <Button
                variant="outlined"
                onClick={() => pagamentoMassivoDialog.open()}
              >
                Registra fatture pagate
              </Button>
            )}
          </div>
        }
        bottomToolbar={
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            />

            <DataTablePagination />
          </div>
        }
        showFilterRow={true}
        size="small"
        saveCurrentState="t"
        messages={{
          textSearch: "Filtra",
          noRows: "Nessuna fattura",
        }}
      />
    </>
  );
}
