import React from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getClientiAttivita as getClientiAttivitaAction } from "app/actions";
import DocumentoClientePicker from "app/components/common/DocumentoClientePicker";

export default function DocumentoClientePickerForAttivita(props) {
  const showToast = useToast();

  const clienti = useDataSource({
    initialData: [],
    itemId: props.idAttivita,
    loadAction: getClientiAttivitaAction,
    autoLoad: !!props.idAttivita,
    onLoadError: () => {
      showToast("Errore nel caricare la lista dei clienti dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const cliente = (clienti.data && clienti.data[0]) || null;
  return cliente ? (
    <DocumentoClientePicker
      idCliente={cliente.id}
      source={props.source}
      sourceKey={props.sourceKey}
      readOnly={props.readOnly}
      onFileRemoved={props.onFileRemoved}
      onFileAdded={props.onFileAdded}
    />
  ) : (
    <div />
  );
}
