import React, { useCallback } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import useSideModal from "app/components/common/useSideModal";
import { Container } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { Link, useHistory } from "react-router-dom";
import Mandato from "app/components/Mandato/Mandato";
import proceduraHasVersioneSuccessiva from "app/utils/proceduraHasVersioneSuccessiva";
import StarIcon from "@material-ui/icons/Star";
import MandatoStato from "app/constants/MandatoStato";
import BadgeMandatoStato from "app/components/common/BadgeMandatoStato";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import TableActionButtons from "./common/TableActionButtons";

export default function MandatiTable_abstract(props) {
  const hasPermission = useCheckPermission();
  const history = useHistory();
  const mandati = props.mandati;

  const { openSideModal, sideModal } = useSideModal();

  const handleMandatoUpdated = useCallback(
    (mandato) => {
      mandati.changeValue(mandato.id, mandato);
    },
    [mandati]
  );

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_offerta_mandato"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {sideModal}
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        {props.title || "Offerte/Mandati"}{" "}
      </h1>
      {mandati.loading ? (
        "Caricamento lista mandati ..."
      ) : mandati.loadError ? (
        "Errore durante il caricamento della lista mandati"
      ) : (
        <DataTable
          source={mandati}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 145,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TableActionButtons
                      link={`/mandati/${value}`}
                      openSideModal={() => {
                        openSideModal(
                          Mandato,
                          {
                            id: value,
                            useIdFromProps: true,
                            onEditSuccess: handleMandatoUpdated,
                            edit: true,
                          },
                          value
                        );
                      }}
                      canEdit={hasPermission("modifica_offerta_mandato")}
                      editItem={() =>
                        history.push(`/mandati/${value}?edit=true`)
                      }
                    />
                    {(row?.procedure || []).filter((p) => {
                      return proceduraHasVersioneSuccessiva(p);
                    }).length > 0 ? (
                      <StarIcon
                        style={{
                          marginBottom: -9,
                          marginRight: 10,
                          color: "#ffd600",
                        }}
                      />
                    ) : null}{" "}
                  </div>
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            { path: ["nome"], header: "Nome", type: "text" },
            {
              path: ["clientePrincipale", "ragioneSociale"],
              header: "Cliente",
              type: "text",
            },
            {
              path: ["formatStrumento", "nome"],
              header: "Format strumento",
              type: "text",
            },
            {
              path: ["stato"],
              type: "enum",
              options: MandatoStato.filter((x) => x),
              header: "Stato",
              renderCell: (value, row, rowIndex, rowSource) => {
                return <BadgeMandatoStato stato={value} />;
              },
              renderRawValue: (value) => {
                const statoObj = MandatoStato.find(
                  (stato) => stato.value === value
                );
                return statoObj?.label || value;
              },
            },
            {
              path: ["commerciale", "cognome"],
              header: "Commerciale",
              type: "text",
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              {hasPermission("crea_offerta_mandato") ? (
                <ColoredButton
                  disabled={false}
                  onClick={null}
                  color="default"
                  fullWidth={false}
                  size="small"
                  variant="outlined"
                  to="/mandati/new"
                  component={Link}
                >
                  Crea offerta/mandato
                </ColoredButton>
              ) : null}{" "}
              <DataTableExportButtons filename={props.exportFilename} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessun mandato",
          }}
        />
      )}{" "}
    </Container>
  );
}
