import React from "react";
import { ColumnFilterOptionsComponent } from "./index";
import { TextColumnFilter } from "app/elements/DataTable/filters/types";
import {
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "./TableFilter.scss";
import clsx from "clsx";

const TextFilter: ColumnFilterOptionsComponent<TextColumnFilter> = ({
  filterOptions,
  setFilter,
  messages,
}) => {
  const filterText = (filterOptions && filterOptions.filterText) || "";
  const hideNot = filterOptions?.hideNot ?? false;
  const isNot = (filterOptions && filterOptions.isNot) || false;
  const label = messages.textSearch || "Search...";

  const onTextFilterInputChanged = (event) => {
    const value = event.target.value;
    if (!value) {
      setFilter(null as any);
    } else {
      const lowerCaseValue = value.toLowerCase();
      setFilter({
        type: "text",
        emptySelected: false,
        search: lowerCaseValue,
        filterText: value,
        isNot,
      });
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      style={{ padding: 0 }}
      className="portalgen-textFilter"
    >
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        value={filterText}
        onChange={onTextFilterInputChanged}
        label={label}
        // type="search"
        endAdornment={
          <div className="filter-clear-and-not-buttons-container">
            {hideNot ? null : (
              <button
                className={clsx(
                  "table-filter-not-button",
                  isNot && "filter-enabled"
                )}
                onClick={() => {
                  if (isNot && !filterText) {
                    setFilter(null as any);
                  } else {
                    setFilter({ ...filterOptions, isNot: !isNot });
                  }
                }}
              >
                NOT
              </button>
            )}
            {filterText && (
              <IconButton onClick={() => setFilter(null as any)}>
                <HighlightOffIcon />
              </IconButton>
            )}
          </div>
        }
      />
    </FormControl>
  );
};

export default TextFilter;
