import { useMemo } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { Button, ButtonGroup, Container, Typography } from "@material-ui/core";
import { getAnalisiCommerciale } from "app/actions";
import {
  PivotRowGetter,
  PivotRowGetterWithSorting,
  PivotTable,
  PivotTableColumn,
} from "../PivotTable/PivotTable";
import { sum } from "lodash";
import { useLoadApi } from "app/hooks/useLoadApi";
import { ContrattiCell } from "../AnalisiCommerciale/ContrattiCell";
import moment from "moment";
import UserFilterSelector from "../common/UserFilterSelector";
import AnnoPicker from "../common/AnnoPicker";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";
import numeral from "numeral";

const getPivotRowsProvincia: PivotRowGetter<any> = (d) => {
  return {
    key: d.provincia,
    label: d.provincia,
  };
};
const getPivotRowsTrimestre: PivotRowGetter<any> = (d) => {
  return {
    key: d.trimestre,
    label: d.trimestre,
  };
};

const getPivotRowsDimensioneAzienda: PivotRowGetter<any> = (d) => {
  return {
    key: d.dimensioneAzienda,
    label: d.dimensioneAzienda,
  };
};

function trimestreName(n) {
  return n + "° trimestre";
}

export function AnalisiCommercialeSmallTable({
  anno,
  groupBy,
  data: _data,
  nMonth,
}: {
  anno: number;
  groupBy: "provincia" | "dimensioneAzienda" | "trimestre";
  data: any[];
  nMonth: number;
}) {
  const columns = useMemo(() => {
    return createColumns(anno, nMonth);
  }, [anno, nMonth]);

  const data = useMemo(() => {
    const data = (_data || []).map((x) => {
      const mString = x.month.substring(5);
      const m = parseInt(mString);
      const t = Math.floor((m - 1) / 3) + 1;
      const trimestre = t + "° trimestre";
      return { ...x, trimestre };
    });
    if (groupBy === "trimestre") {
      return [
        {
          trimestre: trimestreName(1),
        },
        {
          trimestre: trimestreName(2),
        },
        {
          trimestre: trimestreName(3),
        },
        {
          trimestre: trimestreName(4),
        },
        ...data,
      ];
    }
    return data;
  }, [_data, groupBy]);

  let pivotRowsGetter: [PivotRowGetter<any> | PivotRowGetterWithSorting<any>];
  let pivotRowsLabel: [string];

  const sortByImporto = (data: any[]): number =>
    sum(data.map((x) => (x.statoMandato === "contratto" ? x.importo : 0)));

  if (groupBy === "provincia") {
    pivotRowsGetter = [
      {
        getter: getPivotRowsProvincia,
        sortBy: sortByImporto,
        order: "desc",
      },
    ];
    pivotRowsLabel = ["Provincia"];
  } else if (groupBy === "dimensioneAzienda") {
    pivotRowsGetter = [
      {
        getter: getPivotRowsDimensioneAzienda,
        sortBy: sortByImporto,
        order: "desc",
      },
    ];
    pivotRowsLabel = ["Dimensione impresa"];
  } else {
    pivotRowsGetter = [getPivotRowsTrimestre];
    pivotRowsLabel = ["Trimestre"];
  }

  return (
    <PivotTable
      pivotRowsGetter={pivotRowsGetter}
      pivotRowsLabel={pivotRowsLabel}
      data={data || []}
      columns={columns}
    />
  );
}

function createColumns(anno: number, nMonth: number): PivotTableColumn<any>[] {
  const filterOfferta = (d) =>
    d.statoMandato === "offerta" || d.statoMandato === "contratto"; // TODO: va bene cosi? perche i contratti sono anche "offerte" che poi si sono trasformate

  const filterContratto = (d) => d.statoMandato === "contratto";

  const width = 100;

  const result: PivotTableColumn<any>[] = [
    // {
    //   key: "fatturato",
    //   label: "Fatturato",
    //   render: (data) => {
    //     if (!data || !data.length) {
    //       return null;
    //     }
    //     const importo = sum(data.map((x) => x.importo));
    //     return numeral(importo).format("0,0") + " €";
    //   },
    //   filter: filterContratto,
    //   width,
    // },
    {
      key: "offerte",
      label: "# Offerte",
      render: (data) => <CellRenderer data={data} isOfferta />,
      filter: filterOfferta,
      width,
    },
    {
      key: "contratti",
      label: "# Contratti",
      render: (data) => <CellRenderer data={data} />,
      filter: filterContratto,
      width,
    },
    {
      key: "avg_contratto",
      label: "Valore Medio Contratto",
      render: (data) => {
        if (!data || !data.length) {
          return null;
        }
        const n = sum(data.map((x) => x.count));
        const importo = sum(data.map((x) => x.importo));
        return numeral(importo / n).format("0,0") + " €";
      },
      filter: filterContratto,
      width,
    },
    {
      key: "avg_contratto",
      label: "Chiusura media mensile",
      render: (data) => {
        if (!data || !data.length) {
          return null;
        }
        const importo = sum(data.map((x) => x.importo));
        return numeral(importo / nMonth).format("0,0") + " €";
      },
      filter: filterContratto,
      width,
    },
  ];

  return result;
}

function CellRenderer({
  data,
  isOfferta = false,
  noColor = false,
}: {
  data: any[];
  isOfferta?: boolean;
  noColor?: boolean;
}) {
  if (!data || !data.length) {
    return null;
  }
  const n = sum(data.map((x) => x.count));
  const importo = sum(data.map((x) => x.importo));

  return (
    <ContrattiCell
      numeroDiContratti={n}
      importo={importo}
      labels={isOfferta ? ["offerta", "offerte"] : ["contratto", "contratti"]}
      color={noColor ? undefined : isOfferta ? "#3890F5" : "#4caf50"}
    />
  );
}
