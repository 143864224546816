import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

let reloadDueToSessionExpire = false;

export function onSessionExpired() {
  reloadDueToSessionExpire = true;
}

type UncommittedChangesConfirmationContextType = {
  setShouldAskConfirmation: (
    shouldAsk: boolean,
    contextConsumerId: string
  ) => void;
  shouldAskConfirmation: boolean;
};

const UncommittedChangesConfirmationContext =
  createContext<UncommittedChangesConfirmationContextType>({
    setShouldAskConfirmation: () =>
      console.warn("missing UncommittedChangesConfirmationWrapper"),
    shouldAskConfirmation: false,
  });

export const UncommittedChangesConfirmationWrapper = ({
  children,
  confirmationMessage,
  shouldAskConfirmation,
  setShouldAskConfirmation: _setShouldAskConfirmation,
}) => {
  const shouldAskConfirmationByConsumerRef = useRef({});

  const setShouldAskConfirmation = (
    shouldAsk: boolean,
    contextConsumerId: string
  ) => {
    shouldAskConfirmationByConsumerRef.current[contextConsumerId] = shouldAsk;
    const newShouldAskConfirmation = Object.values(
      shouldAskConfirmationByConsumerRef.current
    ).includes(true);
    if (newShouldAskConfirmation !== shouldAskConfirmation) {
      _setShouldAskConfirmation(newShouldAskConfirmation);
    }
  };

  // to prevent the user from reloading the page or closing the tab we have to listen for the 'beforeunload'
  useEffect(() => {
    const eventListener = (e) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      if (shouldAskConfirmation && !reloadDueToSessionExpire) {
        e.preventDefault();
        e.returnValue = "";
      } else {
        delete e.returnValue;
      }
    };

    window.addEventListener("beforeunload", eventListener);
    return () => window.removeEventListener("beforeunload", eventListener);
  }, [shouldAskConfirmation]);

  return (
    <UncommittedChangesConfirmationContext.Provider
      value={{
        setShouldAskConfirmation,
        shouldAskConfirmation,
      }}
    >
      {/* to prevent the user from changing route we have to use the Prompt component */}
      <Prompt when={shouldAskConfirmation} message={confirmationMessage} />
      {children}
    </UncommittedChangesConfirmationContext.Provider>
  );
};

export const useUncommittedChangesConfirmationContext = () => {
  const consumerIdRef = useRef(uuidv4());
  const context = useContext(UncommittedChangesConfirmationContext);
  return {
    setShouldAskConfirmation: (shouldAsk: boolean) =>
      context.setShouldAskConfirmation(shouldAsk, consumerIdRef.current),
    shouldAskConfirmation: context.shouldAskConfirmation,
  } as const;
};
