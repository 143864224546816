import React from "react";
import { useToast } from "app/hooks/useToast";
import { useHistory } from "react-router-dom";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { createContatto as createContattoAction } from "app/actions";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormContatto from "app/components/Contatti/FormContatto";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";

export default function DialogCreaContatto(props) {
  const showToast = useToast();

  const history = useHistory();

  const contatto = useDataSource({
    initialData: { ...(props.initialData || {}) },
    createAction: createContattoAction,
    onCreateSuccess: (response) => {
      showToast("Contatto creato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      if (props.onCreateSuccess) {
        props.onCreateSuccess(response.data);
      } else {
        history.push(`/contatto/${response.data.id}`);
      }
    },
    onCreateError: (response) => {
      showToast("Errore durante la creazione del contatto", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="lg"
      open={props.newContattoId}
      scroll="paper"
    >
      <DialogTitle>Crea contatto</DialogTitle>

      <DialogContent>
        <FormContatto readOnly={false} source={contatto} />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            props.cancel();

            contatto.clear();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={contatto.loading || contatto.editing}
          onClick={(event) => {
            contatto.commit();
          }}
          color="primary"
        >
          Salva
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
