import { useEffect, useRef } from "react";

export default function useUpdateImportiPrevisti(mandatoSource) {
  const ignoreRef = useRef(false);
  useEffect(() => {
    if (!ignoreRef.current) {
      const beni = mandatoSource.data?.beni || [];
      const mandatoLineeBeni = mandatoSource.data?.mandatoLineeBeni || [];
      const mandatoLinee = mandatoSource.data?.mandatoLinee || [];
      //   console.log("BENI", beni, mandatoLineeBeni, mandatoLinee);

      const hasBene = {};
      mandatoLineeBeni.forEach((mlb) => {
        hasBene[mlb.idLinea] = hasBene[mlb.idLinea] || {};
        hasBene[mlb.idLinea][mlb.idBene] = true;
      });

      const toUpdate = [];

      for (let index = 0; index < mandatoLinee.length; index++) {
        const mandatoLinea = mandatoLinee[index];
        if (mandatoLinea.linea?.isMultibene) {
          const valoreInvestimentoPrevisto = beni.reduce((s, bene) => {
            if (hasBene[mandatoLinea.idLinea]?.[bene.id]) {
              s = s + (bene.valore || 0);
            }
            return s;
          }, 0);

          if (
            (valoreInvestimentoPrevisto || 0) !==
            (mandatoLinea.valoreInvestimentoPrevisto || 0)
          ) {
            toUpdate.push({
              path: ["mandatoLinee", index, "valoreInvestimentoPrevisto"],
              value: valoreInvestimentoPrevisto,
            });
          }
        }
      }

      if (toUpdate.length) {
        console.log("toUpdate", toUpdate);
        try {
          ignoreRef.current = true;
          // toUpdate.forEach(({ path, value }) => {
          //   mandatoSource.changeValue(path, value);
          // });
        } catch (err) {
        } finally {
          ignoreRef.current = false;
        }
      }
    }
  }, [
    mandatoSource.data?.beni,
    mandatoSource.data?.mandatoLineeBeni,
    mandatoSource.data?.mandatoLinee,
  ]);
}
