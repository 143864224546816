import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { useDataTreeContext } from "app/contexts/DataTreeContext";

function NodeRendererDefault(props) {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
    ...otherProps
  } = props as any;
  const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;

  const { source, renderNode, readOnly } = useDataTreeContext();
  const nodeSource = useDataSource({
    parent: source,
    initialData: [],
    pathInParent: [{ id: node.id }],
  });
  const nodeContent =
    typeof renderNode === "function"
      ? renderNode(node, path, nodeSource)
      : "No renderNode function";

  let handle;
  if (!readOnly && canDrag) {
    if (typeof node.children === "function" && node.expanded) {
      // Show a loading symbol on the handle when the children are expanded
      //  and yet still defined by a function (a callback to fetch the children)
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={clsx("rst__loadingCirclePoint", rowDirectionClass)}
              />
            ))}
          </div>
        </div>
      );
    } else {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(<div className="rst__moveHandle" />, {
        dropEffect: "copy",
      });
    }
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle: any = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === "rtl") {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  return (
    <div style={{ height: "100%" }} {...otherProps}>
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === "function") && (
          <div>
            <button
              type="button"
              aria-label={node.expanded ? "Collapse" : "Expand"}
              className={clsx(
                node.expanded ? "rst__collapseButton" : "rst__expandButton",
                rowDirectionClass
              )}
              style={buttonStyle}
              onClick={() =>
                toggleChildrenVisibility({
                  node,
                  path,
                  treeIndex,
                })
              }
            />

            {node.expanded && !isDragging && (
              <div
                style={{ width: scaffoldBlockPxWidth }}
                className={clsx("rst__lineChildren", rowDirectionClass)}
              />
            )}
          </div>
        )}

      <div className={clsx("rst__rowWrapper", rowDirectionClass)}>
        {/* Set the row preview to be used during drag and drop */}
        {connectDragPreview(
          <div
            className={clsx(
              "rst__row",
              isLandingPadActive && "rst__rowLandingPad",
              isLandingPadActive && !canDrop && "rst__rowCancelPad",
              isSearchMatch && "rst__rowSearchMatch",
              isSearchFocus && "rst__rowSearchFocus",
              rowDirectionClass,
              className
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            {handle}

            {nodeContent}
          </div>
        )}
      </div>
    </div>
  );
}

NodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  rowDirection: "ltr",
};

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
};

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== "function" &&
    older.children.some(
      (child) => child === younger || isDescendant(child, younger)
    )
  );
}

export default NodeRendererDefault;
