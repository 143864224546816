import React from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormUtente from "app/components/Utenti/FormUtente";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";

export default function DialogCreaUtente(props) {
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="md"
      open={props.dialog.isOpen}
      scroll="paper"
    >
      <DialogTitle>Crea utente</DialogTitle>

      <DialogContent>
        <FormUtente readOnly={false} source={props.utenteSource} />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            props.dialog.close();

            props.onCancel();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={props.utenteSource.loading || props.utenteSource.editing}
          onClick={(event) => {
            props.utenteSource.commit();
          }}
          color="primary"
        >
          Crea
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
