// copied from https://stackoverflow.com/questions/58402130/how-can-i-set-an-static-outlined-div-similar-to-material-uis-outlined-textfield/58421725#58421725

import React, {useState, useRef, useEffect, ReactNode} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const styles: any = {
  root: {
    position: "relative",
  },
  contentWrapper: {
    position: "relative",
  },
  content: {
    padding: "10px 8px 0 8px",
    borderRadius: 4,
  },
  inputLabel: {
    position: "absolute",
    left: 0,
    top: 0,
    // slight alteration to spec spacing to match visual spec result
    transform: "translate(0, 24px) scale(1)",
  },

  "@global fieldset.customLabelledOutline": {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
};

type LabelledOutlineProps = {
  classes?: {
    root?: string;
    contentWrapper?: string;
    content?: string;
    inputLabel?: string;
  };
  id?: string;
  label?: any;
  children: ReactNode;
  className?: any;
  variant?: any;
  error?: any;
};

const LabelledOutline = ({
  classes = {},
  id,
  label,
  children,
  className,
  variant = "outlined",
  error,
}: LabelledOutlineProps) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef = useRef<any>(null);
  useEffect(() => {
    if (labelRef.current) {
      setLabelWidth(labelRef.current.offsetWidth);
    }
  }, [label, labelRef.current]);

  return (
    <div className={clsx(className, classes.root)}>
      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant={variant}
        className={classes.inputLabel}
        shrink
      >
        {label}
      </InputLabel>
      <div className={classes.contentWrapper}>
        <div id={id} className={classes.content}>
          {children}
          <NotchedOutline
            className="customLabelledOutline"
            notched
            labelWidth={labelWidth}
            classes={
              {
                // notchedOutline: 'customLabelledOutline'
              }
            }
          />
        </div>
      </div>
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </div>
  );
};
export default withStyles(styles)(LabelledOutline);
