import React, { useCallback, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { dizionarioToOptions } from "app/utils/dizionarioToOptions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getLinee as getLineeAction,
  getEnti as getEntiAction,
  getAmbitiDiApplicazione as getAmbitiDiApplicazioneAction,
  getTipologieProcedura as getTipologieProceduraAction,
  getRegimi as getRegimiAction,
  duplicateLinea as duplicateLineaAction,
  deleteLinea as deleteLineaAction,
} from "app/actions";
import useSideModal from "app/components/common/useSideModal";
import { useToast } from "app/hooks/useToast";
import { useHistory } from "react-router-dom";
import { useSubmitDataSource } from "app/hooks/DataSource/useSubmitDataSource";
import { Container, Dialog, Typography } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import TableActionButtons from "app/components/common/TableActionButtons";
import Linea from "app/components/Linea/Linea";
import LineaStato from "app/constants/LineaStato.json";
import BadgeLineaStato from "app/components/common/BadgeLineaStato";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";
import { useApi } from "../../hooks/useApi";

export default function LineeTable() {
  useCreaconsultingTitle("Linee");
  const hasPermission = useCheckPermission();

  const linee = useDataSource({
    loadAction: getLineeAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const lineeApi = useApi(getLineeAction);
  const handleLineaUpdated = useCallback(
    (linea) => {
      lineeApi.callApi().then((response) => {
        const freshLinea = response.data?.find((l) => l.id === linea.id);
        if (freshLinea) {
          linee.changeValue(freshLinea.id, freshLinea);
        }
      });
      linee.changeValue(linea.id, linea);
    },
    [linee]
  );

  const enti = useDataSource({
    initialData: [],
    loadAction: getEntiAction,
    autoLoad: true,
  });

  const ambitiDiApplicazione = useDataSource({
    initialData: [],
    loadAction: getAmbitiDiApplicazioneAction,
    autoLoad: true,
  });

  const tipologieProcedura = useDataSource({
    initialData: [],
    loadAction: getTipologieProceduraAction,
    autoLoad: true,
  });

  const regimi = useDataSource({
    initialData: [],
    loadAction: getRegimiAction,
    autoLoad: true,
  });

  const { openSideModal, sideModal } = useSideModal();

  const showToast = useToast();

  const [deletingRowSource, setDeletingRowSource] = useState(null);

  const history = useHistory();

  const duplicateLinea = useSubmitDataSource({
    submitAction: duplicateLineaAction,
    onSubmitSuccess: (response) => {
      showToast("Linea duplicata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/linee/" + response.data.id);
    },
    onSubmitError: () => {
      showToast("Errore durante la duplicazione della linea", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_linea"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {sideModal}

      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        Linee
      </h1>

      {linee.loading ? (
        "Caricamento lista linee ..."
      ) : linee.loadError ? (
        "Errore durante il caricamento della lista linee"
      ) : (
        <DataTable
          source={linee}
          rowSourceOptions={{
            deleteAction: deleteLineaAction,
            onDeleteSuccess: () => {
              setDeletingRowSource(null);

              showToast("Linea eliminata con successo", { color: "success" });
            },

            onDeleteError: (response) => {
              setDeletingRowSource(null);

              showToast(
                response.constraintsViolated
                  ? "Non puoi eliminare questa linea perchè è già in utilizzo"
                  : "Errore durante l'eliminazione",
                { color: "error" }
              );
            },
          }}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 200,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TableActionButtons
                    link={`/linee/${value}`}
                    openSideModal={() =>
                      openSideModal(
                        Linea,
                        {
                          id: value,
                          useIdFromProps: true,
                          onEditSuccess: handleLineaUpdated,
                          edit: true,
                        },
                        value
                      )
                    }
                    canDelete={
                      rowSource.getValue("stato") === "in-preparazione"
                    }
                    deleteItem={() => setDeletingRowSource(rowSource)}
                    canDuplicate={hasPermission("duplica_linea")}
                    duplicateItem={
                      rowSource.getValue("isPluriennale")
                        ? null
                        : () => duplicateLinea.submit(rowSource.getValue("id"))
                    }
                    canEstendiLinea={hasPermission("estendi_linea")}
                    estendiLinea={
                      rowSource.getValue("isPluriennale") &&
                      (() =>
                        history.push(
                          "/linee/" +
                            rowSource.getValue("id") +
                            "?estendiLinea=true"
                        ))
                    }
                    canEdit={hasPermission("modifica_linea")}
                    editItem={() =>
                      history.push(
                        "/linee/" + rowSource.getValue("id") + "?edit=true"
                      )
                    }
                  />
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            {
              path: ["nome"],
              header: "Nome",
              width: 400,
              sortable: true,
              type: "text",
            },
            {
              path: ["legge", "nome"],
              header: "Legge",
              width: 250,
              sortable: true,
              type: "text",
            },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              width: 250,
              type: "enum",
              options: LineaStato,
              defaultHiddenFilterOptions: {
                type: "enum",
                values: ["archiviata", "non-vendibile"],
                isNot: true,
              },
              renderCell: (value) => {
                return <BadgeLineaStato stato={value} />;
              },
              renderRawValue: (value) => {
                return (
                  LineaStato.find((stato) => stato.value === value)?.label ??
                  value
                );
              },
            },
            {
              sortable: true,
              path: ["anno"],
              width: 250,
              header: "Anno",
              type: "number",
            },
            {
              sortable: true,
              path: ["legge", "ente", "nome"],
              width: 250,
              header: "Ente",
              type: "enum",
              options: dizionarioToOptions(enti),
            },
            {
              sortable: true,
              path: ["legge", "ambitoDiApplicazione", "nome"],
              width: 250,
              header: "Ambito di applicazione",
              type: "enum",
              options: dizionarioToOptions(ambitiDiApplicazione),
            },
            {
              sortable: true,
              path: ["tipologiaProcedura", "nome"],
              width: 250,
              header: "Tipologia procedura",
              type: "enum",
              options: dizionarioToOptions(tipologieProcedura),
            },
            {
              sortable: true,
              path: ["regime", "nome"],
              width: 250,
              header: "Regime",
              type: "enum",
              options: dizionarioToOptions(regimi),
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              <DataTableExportButtons filename={"Linee"} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessuna linea",
          }}
        />
      )}

      <Dialog maxWidth="md" open={deletingRowSource}>
        <DialogTitle>Conferma eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {deletingRowSource
              ? 'Sei sicuro di voler eliminare la linea "' +
                deletingRowSource.getValue("nome") +
                '"?'
              : null}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={() => {
              setDeletingRowSource(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={() => {
              deletingRowSource.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
