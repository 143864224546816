import React, { useCallback } from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFormatProcedure as getFormatProcedureAction } from "app/actions";
import { Container } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { useHistory } from "react-router-dom";
import FormatProceduraStato from "app/constants/FormatProceduraStato";
import BadgeFormatProceduraStato from "app/components/common/BadgeFormatProceduraStato";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import useSideModal from "../common/useSideModal";
import FormatProcedura from "./FormatProcedura";
import TableActionButtons from "../common/TableActionButtons";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";

export default function FormatProceduraTable() {
  useCreaconsultingTitle("Format Procedura Commessa");
  const { openSideModal, sideModal } = useSideModal();
  const history = useHistory();

  const formatProcedure = useDataSource({
    loadAction: getFormatProcedureAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const handleFormatProceduraUpdated = useCallback(
    (formatProcedura) => {
      formatProcedure.changeValue(formatProcedura.id, formatProcedura);
    },
    [formatProcedure]
  );

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_format_procedura"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters
    >
      {sideModal}
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        Format procedure commessa
      </h1>
      {formatProcedure.loading ? (
        "Caricamento lista format procedure commesse..."
      ) : formatProcedure.loadError ? (
        "Errore durante il caricamento della lista format procedure commesse"
      ) : (
        <DataTable
          source={formatProcedure}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 100,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                const formatProcedura = rowSource;

                const canChangeStato =
                  formatProcedura.data?.linea &&
                  (formatProcedura.data.linea.idFormatProceduraCorrente ===
                    formatProcedura.data.id ||
                    formatProcedura.data.linea
                      .idFormatProceduraInLavorazione ===
                      formatProcedura.data.id);

                const canEdit =
                  formatProcedura.data &&
                  formatProcedura.data.stato === "in-preparazione" &&
                  canChangeStato;

                // const showCreaNuovaVersione =
                //   getShowCreaNuovaVersioneInProcedura(formatProcedura.data);

                return (
                  <TableActionButtons
                    link={`/formatProcedure/${value}`}
                    openSideModal={() => {
                      openSideModal(
                        FormatProcedura,
                        {
                          id: value,
                          useIdFromProps: true,
                          onEditSuccess: handleFormatProceduraUpdated,
                          edit: true,
                        },
                        value
                      );
                    }}
                    canEdit={
                      canEdit && __hasPermission("modifica_format_procedura")
                    }
                    editItem={() =>
                      history.push(`/formatProcedure/${value}?edit=true`)
                    }

                    // creaNuovaVersione=

                    // showCreaNuovaVersione &&
                    //   hasPermission("crea_versione_format_procedura")
                  />
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            { path: ["nome"], header: "Nome", type: "text" },
            {
              path: ["linea", "nome"],
              header: "Linea",
              renderCell: (value, row, rowIndex, rowSource) => {
                return rowSource.getValue(["linea", "nome"]);
              },
              type: "text",
            },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              width: 250,
              type: "enum",
              options: FormatProceduraStato,
              renderCell: (value, row, rowIndex, rowSource) => {
                return <BadgeFormatProceduraStato stato={value} />;
              },
              renderRawValue: (value) => {
                return (
                  FormatProceduraStato.find((stato) => stato.value === value)
                    ?.label ?? value
                );
              },
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              <DataTableExportButtons filename={"Format procedure commessa"} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessun format procedura commesse",
          }}
        />
      )}{" "}
    </Container>
  );
}
