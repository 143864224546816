import React from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";

export default function DialogDeleteUtente(props) {
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="sm"
      onClose={null}
      open={props.dialog.isOpen}
      scroll="paper"
    >
      <DialogTitle>Elimina/disattiva utente</DialogTitle>

      <DialogContent>
        Sei sicuro di voler eliminare/disattivare l'utente?
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            props.dialog.close();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          onClick={(event) => {
            props.onConfirm();
          }}
          color="primary"
        >
          Elimina/disattiva
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
