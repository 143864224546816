import React from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  creaAttivita as creaAttivitaAction,
  editAttivita as editAttivitaAction,
} from "app/actions";
import { Dialog, Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AttivitaForm from "app/components/Attivita/AttivitaForm";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import SaveIcon from "@material-ui/icons/Save";

export default function AttivitaDialog(props) {
  const showToast = useToast();

  const attivita = useDataSource({
    parent: props.attivitaArraySource,
    pathInParent: [
      {
        id: props.idAttivita,
      },
    ],
    initialData: {},
    itemId: props.idAttivita,
    createAction: creaAttivitaAction,
    onCreateSuccess: (response) => {
      showToast("Attività creata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onAttivitaChanged();

      props.onClose();
    },
    onCreateError: (response) => {
      showToast("Errore durante la creazione dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editAttivitaAction,
    onEditSuccess: (response) => {
      showToast("Attività modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onAttivitaChanged();

      props.onClose();
    },
    onEditError: (response) => {
      showToast(
        response.message
          ? `Errore: ${response.message}`
          : "Errore durante la modifica dell'attività",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  const procedura = props.proceduraSource;

  const saveFunction = async () => {
    await attivita.commit();

    return null;
  };

  const saving = attivita.editing;

  const readOnlyOrSaving = props.readOnly ? props.readOnly : attivita.editing;

  return (
    <Dialog maxWidth="lg" open={props.idAttivita}>
      <DialogTitle>
        {attivita.getValue("__isNew")
          ? "Crea nuova attività"
          : "Modifica attività"}
      </DialogTitle>

      <DialogContent>
        <div
          style={{
            marginBottom: "1em",
          }}
        >
          <Typography
            style={{
              fontSize: "1.2em",
              lineHeight: 1.2,
            }}
            component="h2"
            gutterBottom={true}
            variant="h6"
          >
            {procedura.getValue(["nome"], "")}
          </Typography>

          <Typography
            style={{
              fontSize: "1.2em",
              lineHeight: 1.2,
            }}
            component="h2"
            gutterBottom={true}
            variant="h6"
          >
            {procedura.getValue(
              ["mandato", "clientePrincipale", "ragioneSociale"],
              ""
            )}
          </Typography>
        </div>

        <AttivitaForm
          attivita={attivita}
          readOnly={props.readOnly}
          readOnlyOrSaving={readOnlyOrSaving}
        />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          disabled={saving}
          onClick={(event) => {
            attivita.cancel();

            props.onClose(false);
          }}
          color="secondary"
          variant="outlined"
        >
          {props.readOnly ? "Chiudi" : "Annulla"}
        </ColoredButton>

        {!props.readOnly ? (
          <ColoredButton
            style={{
              marginLeft: 8,
            }}
            disabled={saving}
            onClick={(event) => {
              saveFunction();
            }}
            color="secondary"
            endIcon={null}
            fullWidth={false}
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Salva
          </ColoredButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
