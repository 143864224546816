import React from "react";
import moment from "moment";

const labelStyle = {
  fontSize: "0.65em",
  lineHeight: 1,
  marginTop: -4,
  color: "gray",
};

export default function AttivitaDate(props) {
  const attivita = props.attivita;

  const dataScadenza =
    attivita.dataScadenzaPerentoria || attivita.dataScadenzaProgetto || null;
  const dataEffettiva = attivita.dataEffettiva || null;

  const color1 = getDataScadenzaColor(dataScadenza, dataEffettiva);
  const color2 = !dataEffettiva ? gray : green;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <div
        style={{
          flex: 1,
          color: color1,
        }}
      >
        <div style={labelStyle}>Data di scadenza</div>
        {dataScadenza ? moment(dataScadenza).format("DD/MM/YYYY") : "-"}{" "}
      </div>

      <div
        style={{
          flex: 1,
          color: color2,
        }}
      >
        <div style={labelStyle}>Data effettiva</div>
        {dataEffettiva ? moment(dataEffettiva).format("DD/MM/YYYY") : "-"}{" "}
      </div>
    </div>
  );
}

function getDataScadenzaColor(dataScadenza, dataEffettiva) {
  const now = new Date().toISOString();
  const now_meno_10d = moment().add(-10, "days").toDate().toISOString();
  return !dataScadenza
    ? gray
    : dataEffettiva
    ? gray
    : dataScadenza < now_meno_10d
    ? black
    : dataScadenza < now
    ? orange
    : red;
}

const gray = "gray";
const green = "green";
const red = "red";
const black = "black";
const orange = "orange";
