import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getAttivita as getAttivitaAction,
  editAttivita as editAttivitaAction,
} from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import { Container, Typography, IconButton, Dialog } from "@material-ui/core";
import BackButton from "app/components/common/BackButton";
import LaunchIcon from "@material-ui/icons/Launch";
import { ColoredButton } from "app/elements/ColoredButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import AttivitaForm from "app/components/Attivita/AttivitaForm";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useEffectOnSearchValue } from "app/utils/extractURLSearchValue";

export default function Attivita(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const history = useHistory();

  const { id } = props.useIdFromProps ? props : useParams();

  const isNew = id === "new";

  const [editing, setEditing] = useState(isNew);

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_attivita") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_attivita")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const readOnly = !editing;

  const attivita = useDataSource({
    loadAction: getAttivitaAction,
    editAction: editAttivitaAction,
    itemId: id,
    autoLoad: !isNew,

    initialData: {},
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Attività modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: (response) => {
      showToast(
        response.message
          ? `Errore: ${response.message}`
          : "Errore durante la modifica dell'attività",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
    onDeleteError: (response) => {
      showToast("Errore durante l'eliminazione dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const readOnlyOrSaving = readOnly || attivita.saving || attivita.editing;

  console.log("attività", attivita.data);

  const deleteDialog = useDialog();

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_attivita"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <>
      <Container disableGutters={true} fixed={false} maxWidth="xl">
        {attivita.loading ? (
          "Caricamento attività in corso ..."
        ) : attivita.loadError ? (
          "Errore durante il caricamento dell'attività"
        ) : (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: "1em",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <h2
                  className="page-title-header"
                  style={{
                    marginTop: -20,
                    marginBottom: 0,
                  }}
                >
                  <BackButton defaultBackLink="" />
                  Attività
                </h2>

                <Typography component="h2" gutterBottom={true} variant="h6">
                  {attivita.getValue("nome", "")}
                </Typography>

                <Typography
                  style={{
                    fontSize: "1.2em",
                    lineHeight: 1.2,
                  }}
                  component="h2"
                  gutterBottom={true}
                  variant="h6"
                >
                  {attivita.getValue(["procedura", "nome"], "")}

                  <IconButton
                    to={`/procedure/${attivita.getValue(
                      ["procedura", "id"],
                      ""
                    )}`}
                    component={Link}
                  >
                    <LaunchIcon />
                  </IconButton>
                </Typography>

                <Typography
                  style={{
                    fontSize: "1.2em",
                    lineHeight: 1.2,
                  }}
                  component="h2"
                  gutterBottom={true}
                  variant="h6"
                >
                  {attivita.getValue(
                    [
                      "procedura",
                      "mandato",
                      "clientePrincipale",
                      "ragioneSociale",
                    ],
                    ""
                  )}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 8,
                }}
              >
                {props.isSideModal && (
                  <ColoredButton
                    disabled={false}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<LaunchIcon />}
                    variant="outlined"
                    to={`/attivita/${id}?edit=true`}
                    component={Link}
                  >
                    Apri
                  </ColoredButton>
                )}
                {readOnly ? (
                  hasPermission("modifica_attivita") ? (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={(event) => {
                        setEditing(!editing);
                      }}
                      startIcon={<EditIcon />}
                      variant="outlined"
                    >
                      Modifica
                    </ColoredButton>
                  ) : null
                ) : (
                  <>
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={(event) => {
                        if (isNew) {
                          history.push("/attivita");
                        } else {
                          attivita.cancel();
                          setEditing(false);
                        }
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<CancelIcon />}
                      variant="outlined"
                    >
                      {[
                        isNew
                          ? "Torna alla lista attività"
                          : "Annulla le modifiche",
                      ]}
                    </ColoredButton>

                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={(event) => {
                        attivita.commit();
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                    >
                      {isNew ? "Crea" : "Salva"}
                    </ColoredButton>
                  </>
                )}{" "}
              </div>
            </div>

            <AttivitaForm
              attivita={attivita}
              readOnly={readOnly}
              readOnlyOrSaving={readOnlyOrSaving}
            />
          </>
        )}{" "}
      </Container>

      <Dialog maxWidth="md" open={deleteDialog.isOpen}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {"Sei sicuro di voler eliminare l'attivita \"" +
              attivita.getValue("nome") +
              '"?'}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={(event) => {
              deleteDialog.close();
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={(event) => {
              attivita.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
