import { RangeColumnFilter } from './types'

export function applyRangeFilter (row, value, options: RangeColumnFilter) {
  if (typeof value !== 'number') {
    if (!value) {
      return false
    }
    try {
      value = parseFloat(value)
    } catch (e) {
      value = null
    }
  }
  if (typeof value !== 'number') {
    return false
  }
  const { min, max } = options
  return value <= max && value >= min
}
