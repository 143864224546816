import React, { useState, useRef, useEffect } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useParams, Link } from "react-router-dom";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getFormatProcedura as getFormatProceduraAction,
  editFormatProcedura as editFormatProceduraAction,
  getFormatAttivitaProcedura as getFormatAttivitaProceduraAction,
  deleteFormatAttivita as deleteFormatAttivitaAction,
  saveFormatAttivitaOrder as saveFormatAttivitaOrderAction,
} from "app/actions";
import { useApi } from "app/hooks/useApi";
import { useDialog } from "app/hooks/useDialog";
import BackButton from "app/components/common/BackButton";
import DialogFormatAttivita from "app/components/FormatProcedure/DialogFormatAttivita";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
} from "@material-ui/core";
import { ColoredButton } from "app/elements/ColoredButton";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import FileBox from "app/components/common/FileBox";
import { StatoPicker } from "app/components/StatoPicker";
import FormatProceduraStato from "app/constants/FormatProceduraStato";
import RichTextEditor from "app/components/common/RichTextEditor";
import AccordionDocumentiRichiesti from "app/components/FormatProcedure/AccordionDocumentiRichiesti";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTree } from "app/elements/DataTree";
import AddIcon from "@material-ui/icons/Add";
import AttivitaIcons from "app/components/common/AttivitaIcons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getShowCreaNuovaVersioneInProcedura,
  useCreaNuovaVersioneFormatProceduraDialog,
} from "./CreaNuovaVersioneFormatProcedure";
import LaunchIcon from "@material-ui/icons/Launch";
import { useEffectOnSearchValue } from "../../utils/extractURLSearchValue";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";

export default function FormatProcedura(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const { id } = props.useIdFromProps ? props : useParams();

  const [editing, setEditing] = useState(false);

  const readOnly = !editing;

  const readOnlyNomeInTree = true;

  const formatProcedura = useDataSource({
    loadAction: getFormatProceduraAction,
    itemId: id,
    autoLoad: !!id,
    initialData: {},
    editAction: editFormatProceduraAction,
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Format procedura commesse modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: (response) => {
      setEditing(false);

      showToast(
        response.message
          ? response.message
          : "Errore durante la modifica del format procedura commesse",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
          autoHideDuration: response.autoHideDuration,
        }
      );
    },
  });

  const formatAttivita = useDataSource({
    initialData: [],
    itemId: id,
    loadAction: getFormatAttivitaProceduraAction,
    autoLoad: !!id,
  });

  const deleteFormatAttivitaApi = useApi(deleteFormatAttivitaAction, {
    onSuccess: (response) => {
      showToast("Attività eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      formatAttivita.load();
    },
    onError: (response) => {
      showToast("Errore durante l'eliminazione dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  useCreaconsultingTitle(formatProcedura.getValue("nome"));

  const formatDocumenti = useDataSource({
    parent: formatProcedura,
    pathInParent: ["formatDocumenti"],
    initialData: [],
  });

  const [editingFormatAttivitaId, setEditingFormatAttivitaId] = useState(null);

  const isMultibene = formatProcedura.data?.linea?.isMultibene;

  const [deleteAttivitaFunc, setDeleteAttivitaFunc] = useState(null);

  const deleteAttivitaDialog = useDialog();

  const saveFormatAttivitaOrderApi = useApi(saveFormatAttivitaOrderAction);

  const canChangeStato =
    formatProcedura.data?.linea &&
    (formatProcedura.data.linea.idFormatProceduraCorrente ===
      formatProcedura.data.id ||
      formatProcedura.data.linea.idFormatProceduraInLavorazione ===
        formatProcedura.data.id);

  const canEdit =
    formatProcedura.data &&
    formatProcedura.data.stato === "in-preparazione" &&
    canChangeStato;

  const showCreaNuovaVersione = getShowCreaNuovaVersioneInProcedura(
    formatProcedura.data
  );

  const {
    creaNuovaVersioneFormatProceduraDialog,
    openCreaNuovaVersioneFormatProceduraDialog,
  } = useCreaNuovaVersioneFormatProceduraDialog(formatProcedura.data);

  const __saveFormatAttivitaOrderOnCreate = async () => {
    const orderedArray = formatAttivita.data;

    await saveFormatAttivitaOrderApi.callApi(
      formatProcedura.getValue("id", 0),
      orderedArray
    );
  };

  const saveFormatAttivitaOrderOnCreateRef = useRef();
  saveFormatAttivitaOrderOnCreateRef.current =
    __saveFormatAttivitaOrderOnCreate;

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_format_procedura") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_format_procedura")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_format_procedura"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <>
      <div className={readOnly ? "crea-readOnly" : ""}>
        <h2
          className="page-title-header page-title-header-sticky"
          style={{
            marginTop: -20,
            marginBottom: 0,
          }}
        >
          <BackButton defaultBackLink="" />
          Format procedura commessa
        </h2>

        <DialogFormatAttivita
          readOnly={readOnly}
          formatProcedura={formatProcedura}
          arraySource={formatAttivita}
          idFormatAttivita={editingFormatAttivitaId}
          onClose={() => {
            setEditingFormatAttivitaId(null);
          }}
          onCreateSuccess={() => {
            setTimeout(() => {
              saveFormatAttivitaOrderOnCreateRef.current();
            }, 100);
          }}
          isMultibene={isMultibene}
        />

        <Container
          className={readOnly ? "crea-readOnly" : ""}
          disableGutters={true}
          fixed={false}
          maxWidth="xl"
        >
          {formatProcedura.loading ? (
            "Caricamento format procedura in corso ..."
          ) : formatProcedura.loadError ? (
            "Errore durante il caricamento del format procedura commesse"
          ) : (
            <>
              <div
                className="page-title-header-sticky-buttons-row"
                style={{
                  display: "flex",
                  marginBottom: "1em",
                }}
              >
                <Typography
                  style={{
                    flex: 1,
                  }}
                  component="h2"
                  gutterBottom={true}
                  variant="h6"
                >
                  {formatProcedura.getValue("nome")}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: 8,
                  }}
                >
                  {props.isSideModal && (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      color="secondary"
                      size="small"
                      startIcon={<LaunchIcon />}
                      variant="outlined"
                      to={`/formatProcedure/${id}`}
                      component={Link}
                    >
                      Apri
                    </ColoredButton>
                  )}
                  {readOnly ? (
                    <>
                      {showCreaNuovaVersione &&
                        hasPermission("crea_versione_format_procedura") && (
                          <ColoredButton
                            variant="outlined"
                            onClick={() => {
                              openCreaNuovaVersioneFormatProceduraDialog();
                            }}
                          >
                            Crea nuova versione
                          </ColoredButton>
                        )}

                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        variant="outlined"
                        to={"/linee/" + formatProcedura.getValue("idLinea")}
                        component={Link}
                      >
                        Vai alla linea
                      </ColoredButton>

                      {canEdit ? (
                        hasPermission("modifica_format_procedura") ? (
                          <ColoredButton
                            style={{
                              marginLeft: 8,
                            }}
                            onClick={(event) => {
                              setEditing(!editing);
                            }}
                            startIcon={<EditIcon />}
                            variant="outlined"
                          >
                            Modifica
                          </ColoredButton>
                        ) : null
                      ) : null}
                    </>
                  ) : (
                    <>
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        disabled={false}
                        onClick={(event) => {
                          formatProcedura.cancel();

                          formatAttivita.cancel();

                          setEditing(false);
                        }}
                        color="secondary"
                        endIcon={null}
                        fullWidth={false}
                        size="small"
                        startIcon={<CancelIcon />}
                        variant="outlined"
                      >
                        Annulla le modifiche
                      </ColoredButton>

                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        disabled={false}
                        onClick={(event) => {
                          formatProcedura.commit();

                          const _func = async () => {
                            const orderedArray = formatAttivita.data;

                            await saveFormatAttivitaOrderApi.callApi(
                              formatProcedura.getValue("id", 0),
                              orderedArray
                            );

                            formatAttivita.load();
                          };

                          _func();
                        }}
                        color="secondary"
                        endIcon={null}
                        fullWidth={false}
                        size="small"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Salva
                      </ColoredButton>
                    </>
                  )}{" "}
                </div>
              </div>

              <Card style={{ marginBottom: 16 }}>
                <CardContent>
                  <Grid container={true} item={false} spacing={4}>
                    <Grid container={false} item={true} lg={8} md={6} xs={12}>
                      <TextField
                        disabled={readOnly}
                        error={
                          formatProcedura.getValidationErrors("nome").length > 0
                        }
                        fullWidth={true}
                        helperText={
                          formatProcedura.getValidationErrors("nome")[0]
                        }
                        label="Nome format procedura commesse"
                        onBlur={(event) => {
                          formatProcedura.runValidation("nome");
                        }}
                        onChange={(event) => {
                          const value = event.target.value;

                          formatProcedura.changeValue(
                            "nome",

                            value
                          );
                        }}
                        value={formatProcedura.getValue("nome", "")}
                        variant="outlined"
                        InputProps={{
                          readOnly: readOnly,
                        }}
                      />
                    </Grid>

                    <Grid container={false} item={true} lg={4} md={6} xs={12}>
                      <FileBox
                        source={formatProcedura}
                        sourceKey="documentoProduzione"
                        title="Documenti - produzione"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>

                  <Grid container={true} item={false} spacing={4}>
                    <Grid
                      style={{
                        paddingTop: 10,
                      }}
                      container={false}
                      item={true}
                      lg={6}
                      md={6}
                      sm={12}
                    >
                      <StatoPicker
                        label="Stato"
                        source={formatProcedura}
                        stati={FormatProceduraStato}
                        disabled={!readOnly || !canChangeStato}
                        isChangePrevented={(oldStato, newStato) => {
                          let msg = null;

                          if (
                            newStato === "attivo" &&
                            formatProcedura.getValue("idVersionePrecedente")
                          ) {
                            if (
                              !formatProcedura.getValue("motivoRevisione") ||
                              !formatProcedura.getValue("istruzioniOperative")
                            ) {
                              msg =
                                "Il motivo della revisione e le istruzioni operative sono obbligatorie per rendere attiva una nuova versione.";
                            }
                          }

                          return msg;
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container={true} item={false} spacing={4}>
                    <Grid container={false} item={true} lg={4} md={6} xs={12}>
                      <RichTextEditor
                        __to_reload={formatProcedura?.getValue(
                          "motivoRevisione"
                        )}
                        source={formatProcedura}
                        sourceKey="motivoRevisione"
                        readOnly={readOnly}
                        label="Motivo revisione"
                      />
                    </Grid>

                    <Grid container={false} item={true} lg={8} md={6} xs={12}>
                      <RichTextEditor
                        __to_reload={formatProcedura?.getValue(
                          "istruzioniOperative"
                        )}
                        source={formatProcedura}
                        sourceKey="istruzioniOperative"
                        readOnly={readOnly}
                        label="Istruzioni operative"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <AccordionDocumentiRichiesti
                formatDocumenti={formatDocumenti}
                readOnly={readOnly}
              />

              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Attività
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <DataTree
                      source={formatAttivita}
                      sourceKey={null}
                      onChange={null}
                      canDrop={(value) => {
                        const { node, nextParent } = value;
                        console.log("canDrop: ", node, nextParent);
                        if (nextParent && nextParent.isRipetiPerOgniBene) {
                          return false;
                        }
                        return true;
                      }}
                      maxDepth={2}
                      fullWidth={true}
                      itemParentKey="idParent"
                      defaultExpanded={true}
                      readOnly={readOnly}
                      topToolbar={null}
                      bottomToolbar={
                        !readOnly ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "0.75em",
                            }}
                          >
                            <ColoredButton
                              style={{
                                marginLeft: 8,
                              }}
                              disabled={false}
                              onClick={(event) => {
                                const newFA = {
                                  idParent: null,
                                  isNonEliminabile: true,
                                  dataScadenzaPerentoriaType: "simpleDate",
                                  dataScadenzaProgettoType: "simpleDate",
                                };

                                newFA.idFormatProcedura =
                                  formatProcedura.getValue("id");

                                const newFAId = formatAttivita.create(newFA);

                                setEditingFormatAttivitaId(newFAId);
                              }}
                              color="secondary"
                              endIcon={null}
                              fullWidth={false}
                              size="small"
                              startIcon={<AddIcon />}
                              variant="outlined"
                            >
                              Aggiungi attività
                            </ColoredButton>
                          </div>
                        ) : null
                      }
                      renderNode={(node, path, nodeSource) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              borderTop: "1px solid lightgray",
                              borderBottom: "1px solid lightgray",
                              borderLeft: readOnly
                                ? "1px solid lightgray"
                                : null,
                              borderRadius: 2,
                              width: "100%",
                              outline: nodeSource.getValidationErrors([], true)
                                ?.length
                                ? "2px solid #f44336"
                                : "none",
                            }}
                          >
                            <div
                              style={
                                path.length > 1
                                  ? {
                                      width: 0,
                                    }
                                  : {
                                      width: 44,
                                      borderRight: "1px solid lightgray",
                                    }
                              }
                            />
                            <div
                              style={{
                                flex: 1,
                                padding: readOnlyNomeInTree ? 8 : 0,
                                borderRight: "1px solid lightgray",
                              }}
                            >
                              {node.isRipetiPerOgniBene ? (
                                <img
                                  src="/imgs/bene-icon.png"
                                  style={{
                                    height: 13,
                                    marginBottom: -2,
                                    marginRight: 3,
                                  }}
                                />
                              ) : null}
                              {readOnlyNomeInTree ? (
                                node.nome
                              ) : (
                                <TextField
                                  style={{
                                    margin: 0,
                                  }}
                                  disabled={
                                    nodeSource.loading || nodeSource.editing
                                  }
                                  error={
                                    nodeSource.getValidationErrors("nome")
                                      .length > 0
                                  }
                                  fullWidth={true}
                                  helperText={
                                    nodeSource.getValidationErrors("nome")[0]
                                  }
                                  onBlur={(event) => {
                                    nodeSource.runValidation("nome");
                                  }}
                                  onChange={(event) => {
                                    const value = event.target.value;

                                    nodeSource.changeValue(
                                      "nome",

                                      value
                                    );
                                  }}
                                  size="small"
                                  type="text"
                                  value={nodeSource.getValue("nome", "")}
                                  variant="outlined"
                                  InputProps={{
                                    readOnly: readOnlyNomeInTree,
                                  }}
                                />
                              )}{" "}
                            </div>
                            <div
                              style={{
                                flex: 0,
                                padding: 8,
                                borderRight: "1px solid lightgray",
                              }}
                            >
                              <AttivitaIcons attivita={node} />
                            </div>
                            {readOnly ? (
                              <div
                                style={{
                                  width: 38,
                                  marginTop: 6,
                                  marginLeft: 4,
                                  borderRight: "1px solid lightgray",
                                }}
                              >
                                <IconButton
                                  onClick={(event) => {
                                    setEditingFormatAttivitaId(node.id);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: 72,
                                  marginTop: 6,
                                  marginLeft: 4,
                                  borderRight: "1px solid lightgray",
                                }}
                              >
                                <IconButton
                                  onClick={(event) => {
                                    setEditingFormatAttivitaId(node.id);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  onClick={(event) => {
                                    setDeleteAttivitaFunc(() => {
                                      return async () => {
                                        await deleteFormatAttivitaApi.callApi(
                                          node.id
                                        );
                                      };
                                    });

                                    deleteAttivitaDialog.open();
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            )}{" "}
                          </div>
                        );
                      }}
                      messages={{
                        noData: "Nessuna attività",
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}{" "}
        </Container>
      </div>

      <Dialog maxWidth="md" open={deleteAttivitaDialog.isOpen}>
        <DialogTitle>Conferma eliminazione attività</DialogTitle>

        <DialogContent>
          <Typography>
            {"Sei sicuro di voler eliminare questa attività?"}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            disabled={deleteFormatAttivitaApi.loading}
            onClick={(event) => {
              deleteAttivitaDialog.close();
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            disabled={deleteFormatAttivitaApi.loading}
            onClick={(event) => {
              const _func = async () => {
                if (deleteAttivitaFunc) {
                  await deleteAttivitaFunc();
                }

                deleteAttivitaDialog.close();
              };

              _func();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>

      {creaNuovaVersioneFormatProceduraDialog}
    </>
  );
}
