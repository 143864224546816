import React from "react";
import TextField, {
  TextFieldProps,
} from "@material-ui/core/TextField/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { useDataTableContext } from "app/contexts/DataTableContext";

type DataTableGlobalFilterProps = {} & TextFieldProps;

export const DataTableGlobalFilter = (props: DataTableGlobalFilterProps) => {
  const { globalFilter, setGlobalFilter } = useDataTableContext();
  return (
    <TextField
      value={globalFilter}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search..."
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: <SearchIcon />,
        ...props.InputProps,
      }}
      {...props}
    />
  );
};
