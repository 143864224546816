import { matchSorter } from "match-sorter";
import { ColumnFilter, ColumnFilterFunction } from "./types";
import { ColumnFilters, TableColumnArray } from "../types";
import { applyRangeFilter } from "./range";
import { applyTextFilter } from "./text";
import { applyDateTimeRangeFilter } from "./DateTimeRange";
import { applyBooleanFilter } from "./boolean";
import { applyShowOnlyEmptyFilter } from "./showOnlyEmpty";
import { applyEnumFilter } from "./enum";

export function filterRowsByGlobalFilter(
  rows,
  columns: TableColumnArray,
  globalFilter,
  source
) {
  const filterableColumns = columns.filter(
    (c) => c.path && !c.disableGlobalFilter
  );

  return matchSorter(
    rows.map((row) => ({
      ...row,
      _rowSource: source.getChildDataSource([{ id: row.id }]),
    })),
    globalFilter,
    {
      keys: filterableColumns.map(
        (col) => (item) => (item as any)._rowSource.getValue(col.path)
      ),
    }
  );
}

const filterFunctionByType: Record<
  string,
  ColumnFilterFunction<ColumnFilter>
> = {
  range: applyRangeFilter as any,
  text: applyTextFilter as any,
  "datetime-range": applyDateTimeRangeFilter as any,
  boolean: applyBooleanFilter as any,
  "show-only-empty": applyShowOnlyEmptyFilter as any,
  enum: applyEnumFilter as any,
};

export function filterRowsByColumn(
  rows,
  columns: TableColumnArray,
  columnFilters: ColumnFilters,
  source
) {
  return rows.filter((row, rowIndex) => {
    const rowSource = source.getChildDataSource([{ id: row.id }]);
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const filterOptions =
        (columnFilters && columnFilters[i]) ||
        column.defaultHiddenFilterOptions;

      if (filterOptions) {
        const column = columns[i];
        const filterFunction = filterFunctionByType[filterOptions.type];
        if (!filterFunction) {
          continue;
        }
        const _value = rowSource.getValue(column.path);
        const valueToFilter =
          filterOptions.type === "text" && column.renderRawValue
            ? column.renderRawValue(_value, row, rowIndex, rowSource)
            : _value;
        const filterResult = filterFunction(
          row,
          valueToFilter,
          filterOptions,
          column
        );
        if (
          (filterOptions.isNot && filterResult) ||
          (!filterOptions.isNot && !filterResult)
        ) {
          return false;
        }
      }
    }
    return true;
  });
}
