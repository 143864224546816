import React, { useState, useEffect } from 'react'
import { useDataTree, UseDataTreeParams } from 'app/hooks/useDataTree'
import { DataTreeContext } from 'app/contexts/DataTreeContext'
import { DataTreeContainer } from './DataTreeContainer'
import { DataTreeToolbar } from './DataTreeToolbar'
import { DataTreeContent } from './DataTreeContent'

type DataTreeProps = {
  topToolbar?: React.ReactNode
  bottomToolbar?: React.ReactNode
} & UseDataTreeParams

export const DataTree = ({
  topToolbar = null,
  bottomToolbar = null,
  ...params
}: DataTreeProps) => {
  const { context } = useDataTree(params)

  if (topToolbar) {
    topToolbar = (
      <DataTreeToolbar>
        {topToolbar}
      </DataTreeToolbar>
    )
  }

  if (bottomToolbar) {
    bottomToolbar = (
      <DataTreeToolbar>
        {bottomToolbar}
      </DataTreeToolbar>
    )
  }

  return (
    <DataTreeContext.Provider value={context}>
      <DataTreeContainer>
        {topToolbar}
        <DataTreeContent/>
        {bottomToolbar}
      </DataTreeContainer>
    </DataTreeContext.Provider>
  )
}
