import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getVariabileInfo as getVariabileInfoAction } from "app/actions";

export default function VariabileAgevolazioneValue2(props) {
  let variabile;

  if (props.useVariable) {
    variabile = props.variabile;
  } else {
    const idVariabile = props.idVariabile;

    const variabileDS = useDataSource({
      initialData: {},
      loadAction: getVariabileInfoAction,
      itemId: idVariabile,
      autoLoad: !!idVariabile,
    });

    variabile = variabileDS.data;
  }

  const agevolazione = variabile?.agevolazione;

  const hasInfo = Boolean(agevolazione?.nome?.nome && variabile?.nome?.nome);
  return (
    <span style={props.style}>
      {hasInfo ? (
        <span>
          {!props.noParentesi ? "(" : null}{" "}
          {`${agevolazione?.nome?.nome} - ${variabile?.nome?.nome}`}{" "}
          {!props.noParentesi ? ")" : null}{" "}
        </span>
      ) : null}{" "}
    </span>
  );
}
