import React from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import useSideModal from "app/components/common/useSideModal";
import { Grid } from "@material-ui/core";
import OpportunitaDashboard from "app/components/Dashboard/OpportunitaDashboard";
import OpportunitaLineaScadenzaDashboard from "app/components/Dashboard/OpportunitaLineaScadenzaDashboard";
import LineeScadenzaDashboard from "app/components/Dashboard/LineeScadenzaDashboard";
import AttivitaScadenzaDashboard from "app/components/Dashboard/AttivitaScadenzaDashboard";
import InterazioniClientiDashboard from "app/components/Dashboard/InterazioniClientiDashboard";
import NewsBanner from "./NewsBanner";
import CommesseDaAssegnareDashboard from "./CommesseDaAssegnareDashboard";

export default function Dashboard(props) {
  const hasPermission = useCheckPermission();

  const { openSideModal, sideModal } = useSideModal();
  return (
    <Grid container={true} spacing={3}>
      {sideModal}{" "}
      {hasPermission("landing_page_commesse_da_assegnare") ? (
        <Grid item={true} lg={6} md={12} sm={12} xs={12}>
          <CommesseDaAssegnareDashboard openSideModal={openSideModal} />
        </Grid>
      ) : null}{" "}
      {hasPermission("landing_page_offerte_dormienti") ? (
        <Grid item={true} lg={6} md={12} sm={12} xs={12}>
          <OpportunitaDashboard openSideModal={openSideModal} />
        </Grid>
      ) : null}{" "}
      {hasPermission("landing_page_offerte_in_scadenza") ? (
        <Grid item={true} lg={6} md={12} sm={12} xs={12}>
          <OpportunitaLineaScadenzaDashboard openSideModal={openSideModal} />
        </Grid>
      ) : null}{" "}
      {hasPermission("landing_page_linee_in_scadenza") ? (
        <Grid item={true} lg={6} md={12} sm={12} xs={12}>
          <LineeScadenzaDashboard openSideModal={openSideModal} />
        </Grid>
      ) : null}{" "}
      {hasPermission("landing_page_attivita_in_scadenza") ? (
        <Grid item={true} lg={6} md={12} sm={12} xs={12}>
          <AttivitaScadenzaDashboard openSideModal={openSideModal} />
        </Grid>
      ) : null}{" "}
      {hasPermission("landing_page_interazioni_con_clienti") ? (
        <Grid item={true} lg={6} md={12} sm={12} xs={12}>
          <InterazioniClientiDashboard openSideModal={openSideModal} />
        </Grid>
      ) : null}
      <NewsBanner {...{}} />
    </Grid>
  );
}
