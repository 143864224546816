import React from "react";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import DescriptionIcon from "@material-ui/icons/Description";
import TodayIcon from "@material-ui/icons/Today";
import LinkIcon from "@material-ui/icons/Link";

export default function AttivitaIcons(props) {
  const attivita = props.attivita;

  const arrayDocs = [].concat(
    attivita.formatDocumenti || [],
    attivita.documenti || [],
    attivita.formatAttivita?.formatDocumenti || []
  );

  const idMilestonePagamento =
    attivita.idMlMilestonePagamento || attivita.idMilestonePagamento;

  const dataScadenzaPerentoria = attivita.dataScadenzaPerentoria;

  const dataScadenzaProgetto = attivita.dataScadenzaProgetto;

  const dataScadenzaPerentoriaLinked =
    attivita.dataScadenzaPerentoriaType === "delta" ||
    attivita.formatAttivita?.dataScadenzaPerentoriaType === "delta";

  const dataScadenzaProgettoLinked =
    attivita.dataScadenzaProgettoType === "delta" ||
    attivita.formatAttivita?.dataScadenzaProgettoType === "delta";

  const hasDataScadenzaPerentoria =
    dataScadenzaPerentoria || dataScadenzaPerentoriaLinked;

  const hasDataScadenzaProgetto =
    dataScadenzaProgetto || dataScadenzaProgettoLinked;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <LocalAtmIcon
        style={{
          color: "#139143",
          visibility: idMilestonePagamento ? "visible" : "hidden",
        }}
      />

      <DescriptionIcon
        style={{
          color: "#beced1",
          visibility: arrayDocs.length ? "visible" : "hidden",
        }}
      />

      <TodayIcon
        style={{
          color: "#0b8dd4",
          visibility: hasDataScadenzaProgetto ? "visible" : "hidden",
        }}
      />

      <LinkIcon
        style={{
          color: "#707070",
          visibility:
            hasDataScadenzaProgetto && dataScadenzaProgettoLinked
              ? "visible"
              : "hidden",
          position: "absolute",
          left: 63,
          zIndex: 1,
          zoom: 0.8,
          top: 20,
        }}
      />

      <TodayIcon
        style={{
          color: "#de300d",
          visibility: hasDataScadenzaPerentoria ? "visible" : "hidden",
        }}
      />

      <LinkIcon
        style={{
          color: "#707070",
          visibility:
            hasDataScadenzaPerentoria && dataScadenzaPerentoriaLinked
              ? "visible"
              : "hidden",
          position: "absolute",
          left: 93,
          zIndex: 1,
          zoom: 0.8,
          top: 20,
        }}
      />
    </div>
  );
}
