import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { TableColumn } from "./types";
import {
  makeSetSortBy,
  useDataTableContext,
} from "app/contexts/DataTableContext";

type DataTableHeadCellProps = {
  children: React.ReactNode;
  column: TableColumn;
};

export function DataTableHeadCell({
  children,
  column,
}: DataTableHeadCellProps) {
  const context = useDataTableContext();
  const [sortDir, setSortDir] = makeSetSortBy(context, column);

  const muiDirection: "asc" | "desc" = sortDir
    ? (sortDir.toLowerCase() as "asc" | "desc")
    : "asc";

  const cycleSort = () => {
    if (!sortDir) {
      setSortDir("ASC");
    } else if (sortDir === "ASC") {
      setSortDir("DESC");
    } else {
      setSortDir(null);
    }
  };

  const align = (column as any)?.align;

  const cellStyle: any = { width: column.width };
  if (typeof cellStyle.width === "number") {
    cellStyle.width = Math.max(cellStyle.width, 80);
  } else {
    cellStyle.minWidth = 80;
  }

  return (
    <TableCell align={align} style={cellStyle} sortDirection={muiDirection}>
      <TableSortLabel
        active={column.sortable && !!sortDir}
        direction={muiDirection}
        onClick={cycleSort}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
  // return (
  //   <TableCell
  //     style={{ width: column.width }}
  //     // className={cellClass}
  //     // scope="col"
  //     // sortDirection={ariaSortDirection}
  //     // data-colindex={index}
  //     // data-tableid={tableId}
  //     // onMouseDown={closeTooltip}
  //     // {...otherProps}
  //     >
  //     {/* {options.sort && sort
  //       ? (
  //       <span className={classes.contentWrapper}>
  //         <Tooltip
  //           title={getTooltipTitle()}
  //           placement="bottom"
  //           open={sortTooltipOpen}
  //           onOpen={() => (dragging ? setSortTooltipOpen(false) : setSortTooltipOpen(true))}
  //           onClose={() => setSortTooltipOpen(false)}
  //           classes={{
  //             tooltip: classes.tooltip,
  //             popper: classes.mypopper,
  //           }}>
  //           <Button
  //             variant="text"
  //             onKeyUp={handleKeyboardSortInput}
  //             onClick={handleSortClick}
  //             className={classes.toolButton}
  //             data-testid={`headcol-${index}`}
  //             ref={isDraggingEnabled() ? dragRef : null}>
  //             <div className={classes.sortAction}>
  //               <div
  //                 className={clsx({
  //                   [classes.data]: true,
  //                   [classes.sortActive]: sortActive,
  //                   [classes.dragCursor]: isDraggingEnabled(),
  //                 })}> */}
  //                 {children}
  //               {/* </div>
  //               <div className={classes.sortAction}>
  //                 <TableSortLabel {...sortLabelProps} />
  //               </div>
  //             </div>
  //           </Button>
  //         </Tooltip>
  //         {hint && (
  //           <Tooltip title={hint}>
  //             <HelpIcon
  //               className={!sortActive ? classes.hintIconAlone : classes.hintIconWithSortIcon}
  //               fontSize="small"
  //             />
  //           </Tooltip>
  //         )}
  //       </span>
  //         )
  //       : (
  //       <div className={hint ? classes.sortAction : null} ref={isDraggingEnabled() ? dragRef : null}>
  //         {children}
  //         {hint && (
  //           <Tooltip
  //             title={hint}
  //             placement="bottom-end"
  //             open={hintTooltipOpen}
  //             onOpen={() => showHintTooltip()}
  //             onClose={() => setHintTooltipOpen(false)}
  //             classes={{
  //               tooltip: classes.tooltip,
  //               popper: classes.mypopper,
  //             }}
  //             enterDelay={300}>
  //             <HelpIcon className={classes.hintIconAlone} fontSize="small" />
  //           </Tooltip>
  //         )}
  //       </div>
  //         )} */}
  //   </TableCell>
  // )
}
