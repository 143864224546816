import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import moment from "moment";
import classNames from "classnames";

type DateInputProps = { source: any } & any;

function DateInput({
  style,
  disabled,
  error,
  fullWidth,
  source,
  sourceKey,
  helperText,
  label,
  readOnly,
  onChange,
  value,
  variant,
  minDate,
  maxDate,
  calcolataAutomaticamente = false,
  ...others
}: DateInputProps) {
  const others2: any = {};
  const [hasInputError, setHasInputError] = useState(false);

  if (source && sourceKey) {
    const errs = source.getValidationErrors(sourceKey);
    others2.error = errs.length > 0;
    others2.helperText = errs[0];
    others2.onClose = () => {
      source.runValidation(sourceKey);
    };
    others2.onChange = (value) => {
      console.log(value);
      if (!value) {
        setHasInputError(false);
        source.changeValue(sourceKey, null);
      } else if (moment.isMoment(value)) {
        if (value.isValid()) {
          setHasInputError(false);
          source.changeValue(sourceKey, value ? value.toISOString() : null);
        } else {
          setHasInputError(true);
          source.changeValue(sourceKey, null);
        }
        return;
      }
      if (!value) {
        source.changeValue(sourceKey, null);
        // source.runValidation(sourceKey)
      } else if (Date.parse(value)) {
        source.changeValue(sourceKey, value ? value.toISOString() : null);
        // source.runValidation(sourceKey)
      } else {
        source.changeValue(sourceKey, null);
      }
    };
    others2.onBlur = (e) => {
      source.runValidation(sourceKey);
      others.onBlur && others.onBlur(e);
    };
    others2.value = source.getValue(sourceKey, null);

    others2.error = others2.error || hasInputError;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
      }}
    >
      <KeyboardDatePicker
        autoOk
        format="DD/MM/YYYY"
        InputAdornmentProps={{ position: "start" }}
        TextFieldComponent={TextFieldComponent}
        style={style}
        disabled={readOnly || disabled}
        error={error || hasInputError}
        fullWidth={fullWidth}
        helperText={helperText}
        label={label}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        inputVariant={variant}
        minDate={minDate}
        maxDate={maxDate}
        {...others}
        {...others2}
      />
      {calcolataAutomaticamente && (
        <Tooltip title="Data calcolata automaticamente">
          <FontDownloadIcon style={{ color: "gray" }} />
        </Tooltip>
      )}
    </div>
  );
}

export default DateInput;

function TextFieldComponent({ className, ...inheritProps }) {
  // console.log(inheritProps)
  if (inheritProps.readOnly) {
    inheritProps = { ...inheritProps };
    delete inheritProps.onChange;
  }
  return (
    <TextField
      {...inheritProps}
      className={classNames(className, "data-input-text-field")}
    />
  );
}
