import React from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getCliente as getClienteAction,
  useOtherFile as useOtherFileAction,
} from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import { upperFirst } from "lodash";
import { useApi } from "app/hooks/useApi";
import LabelledOutline from "app/components/common/LabelledOutline";
import {
  CircularProgress,
  Tooltip,
  IconButton,
  Dialog,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { DataTable } from "app/elements/DataTable";
import { ColoredButton } from "app/elements/ColoredButton";
import FileBox from "app/components/common/FileBox";
import moment from "moment";
import DialogActions from "@material-ui/core/DialogActions";
import CancelIcon from "@material-ui/icons/Cancel";

export default function DocumentoClientePicker(props) {
  const showToast = useToast();

  const cliente = useDataSource({
    initialData: {},
    itemId: props.idCliente,
    loadAction: getClienteAction,
    autoLoad: !!props.idCliente,
    onLoadError: () => {
      showToast("Impossibile caricare la lista dei documenti del cliente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const dialog = useDialog();

  const currentFileObj = props.source.getValue(props.sourceKey, null);

  const hasFile = currentFileObj && currentFileObj.versioneCorrente;

  const onFileUploaded = (fileObj) => {
    props.source.changeValue(props.sourceKey, fileObj);

    props.source.changeValue(
      "fileId" + upperFirst(props.sourceKey),
      fileObj.id
    );

    props.onFileAdded?.();

    dialog.close();
  };

  const useOtherFileApi = useApi(useOtherFileAction, {
    onSuccess: (response) => {
      onFileUploaded(response.data);
    },
  });

  const selectFile = (fileObj) => {
    const targetFileObj = props.source.getValue(props.sourceKey, null);

    useOtherFileApi.callApi({
      useFileId: fileObj.id,
      targetFileId: targetFileObj ? targetFileObj.id : null,
    });
  };

  const isUploading = useOtherFileApi.loading;
  return (
    <>
      <LabelledOutline label={props.title}>
        <div
          style={{
            paddingBottom: 8,
            flex: 1,
            display: "flex",
          }}
        >
          {hasFile ? (
            isUploading ? (
              <>
                <CircularProgress
                  style={{
                    margin: 12,
                  }}
                  size={24}
                />

                <div
                  style={{
                    flex: 1,
                    alignSelf: "center",
                  }}
                >
                  Upload in corso ...
                </div>
              </>
            ) : (
              <>
                <a
                  style={{
                    display: "flex",
                    flex: 1,
                    color: "inherit",
                  }}
                  href={"/api/files/" + currentFileObj.id}
                  download={currentFileObj.versioneCorrente.nome}
                >
                  <Tooltip title="Scarica file">
                    <IconButton
                      style={{
                        marginRight: 6,
                      }}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </Tooltip>

                  <div
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      wordBreak: "break-all",
                    }}
                  >
                    {currentFileObj.versioneCorrente.nome}
                  </div>
                </a>

                <div>
                  {!props.readOnly ? (
                    <>
                      <Tooltip title="Sostituisci file">
                        <IconButton
                          onClick={() => {
                            dialog.open();
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Elimina file">
                        <IconButton
                          onClick={() => {
                            props.source.changeValue(props.sourceKey, {
                              ...currentFileObj,
                              versioneCorrente: null,
                              idVersioneCorrente: null,
                            });
                            props.onFileRemoved?.();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null}{" "}
                </div>
              </>
            )
          ) : props.readOnly ? (
            <div
              style={{
                flex: 1,
                alignSelf: "center",
              }}
            >
              <IconButton
                style={{
                  marginRight: 6,
                  pointerEvents: "none",
                }}
              >
                <CloudOffIcon />
              </IconButton>
              Nessun file
            </div>
          ) : isUploading ? (
            <>
              <CircularProgress
                style={{
                  margin: 12,
                }}
                size={24}
              />

              <div
                style={{
                  flex: 1,
                  alignSelf: "center",
                }}
              >
                Upload in corso ...
              </div>
            </>
          ) : (
            <>
              <Tooltip title="Seleziona o carica documento">
                <IconButton
                  style={{
                    marginRight: 6,
                  }}
                  onClick={() => {
                    dialog.open();
                  }}
                >
                  <CloudUploadIcon />
                </IconButton>
              </Tooltip>

              <div
                style={{
                  flex: 1,
                  alignSelf: "center",
                }}
              >
                Carica file
              </div>
            </>
          )}{" "}
        </div>
      </LabelledOutline>

      <Dialog maxWidth="xl" open={dialog.isOpen}>
        <DialogTitle>Documenti cliente</DialogTitle>

        <DialogContent>
          <DataTable
            source={cliente}
            sourceKey="documenti"
            columns={[
              {
                path: ["id"],
                header: "Seleziona",
                disableColumnFilter: false,
                disableGlobalFilter: false,
                width: 140,
                sortable: true,
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <ColoredButton
                      disabled={!rowSource.data?.file?.versioneCorrente}
                      onClick={() => {
                        selectFile(rowSource.data?.file);
                      }}
                      fullWidth={true}
                      size="small"
                      variant="outlined"
                    >
                      Seleziona
                    </ColoredButton>
                  );
                },
                type: "text",
              },
              {
                path: ["nome", "nome"],
                header: "Nome",
                disableColumnFilter: false,
                disableGlobalFilter: false,
                sortable: true,
                type: "text",
              },
              {
                path: ["fileId"],
                header: "File",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return <FileBox source={rowSource} sourceKey="file" />;
                },
                disableColumnFilter: true,
                disableGlobalFilter: true,
                type: "text",
              },
              {
                path: ["file", "versioneCorrente", "createdAt"],
                header: "Data caricamento",
                renderCell: (value) => {
                  return value ? moment(value).format("DD/MM/YYYY") : "";
                },
                disableColumnFilter: false,
                disableGlobalFilter: false,
                width: 190,
                sortable: false,
                type: "text",
              },
              {
                path: ["dataScadenza"],
                header: "Data scadenza",
                renderCell: (value) => {
                  return value ? moment(value).format("DD/MM/YYYY") : "";
                },
                disableColumnFilter: false,
                disableGlobalFilter: false,
                width: 190,
                sortable: false,
                type: "text",
              },
            ]}
            showFilterRow={false}
            pageSize={1000000}
            size="small"
            expandableRows={false}
            messages={{
              noRows: "Nessun documento",
            }}
          />
        </DialogContent>

        <DialogActions>
          <ColoredButton
            disabled={false}
            onClick={() => {
              dialog.close();
            }}
            color="secondary"
            endIcon={null}
            fullWidth={false}
            startIcon={<CancelIcon />}
            variant="outlined"
          >
            Annulla
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
