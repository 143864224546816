import { each } from "lodash";
import moment from "moment";
import React, { PureComponent, useMemo } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import numeral from "numeral";

type GraphDataEntry = {
  month: number;
  name: string;
  importoContratti: number | undefined;
  importoOfferte: number | undefined;
  importoTotaleContrattiBudget: number;
};

const colorBudget = "#3890F5";
const colorContratto = "#4caf50";
const colorOfferta = "#92a5a8";

export function BudgetChart({ data, anno }) {
  const now = useMemo(() => moment().year(), []);
  const nowMonth = useMemo(() => moment().month() + 1, []);

  const graphData = useMemo(() => {
    const graphData: GraphDataEntry[] = [];
    const monthNames = moment().localeData().months();

    for (let m = 1; m <= 12; m++) {
      const name = monthNames[m - 1];
      graphData.push({
        month: m,
        name: name,
        importoContratti: 0,
        importoOfferte: 0,
        importoTotaleContrattiBudget: 0,
      });
    }

    data = data || [];

    data.forEach((d) => {
      const { mesi } = d;
      each(mesi, (meseData, mString) => {
        const m = parseInt(mString);
        const {
          importoContratti,
          importoOfferte,
          importoTotaleContrattiBudget,
        } = meseData;

        const graphDataEntry = graphData[m - 1];
        if (graphDataEntry) {
          graphDataEntry.importoTotaleContrattiBudget +=
            importoTotaleContrattiBudget || 0;
          graphDataEntry.importoContratti += importoContratti || 0;
          graphDataEntry.importoOfferte += importoOfferte || 0;
        }
      });
    });

    let last: GraphDataEntry | null = null;
    graphData.forEach((entry) => {
      if (last) {
        entry.importoTotaleContrattiBudget += last.importoTotaleContrattiBudget;
        entry.importoContratti =
          (entry.importoContratti || 0) + (last.importoContratti || 0);
        entry.importoOfferte =
          (entry.importoOfferte || 0) + (last.importoOfferte || 0);
      }
      last = entry;

      if (anno > now || (anno === now && entry.month > nowMonth)) {
        entry.importoContratti = undefined;
        entry.importoOfferte = undefined;
      }
    });

    return graphData;
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        data={graphData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />

        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis tickFormatter={formatEuro} />

        <Tooltip formatter={formatEuro} />
        <Legend />

        {/*  offerte e contracts */}
        <Area
          type="monotone"
          dataKey="importoContratti"
          stackId="1"
          fill={colorContratto}
          stroke={colorContratto}
          name="Contratti"
        />

        <Area
          type="monotone"
          dataKey="importoOfferte"
          stackId="1"
          fill={colorOfferta}
          stroke={colorOfferta}
          name="Offerte"
        />

        {/* Budget */}
        <Line
          type="monotone"
          dataKey="importoTotaleContrattiBudget"
          stroke={colorBudget}
          strokeWidth={3}
          name="Budget"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function formatEuro(value) {
  if (typeof value === "number") {
    return numeral(value).format("0,0") + " €";
  }
  return value;
}
