import React, { useState } from "react";
import { useToast } from "app/hooks/useToast";
import { Link } from "react-router-dom";
import { ColoredButton } from "app/elements/ColoredButton";
import Grid from "@material-ui/core/Grid";
import FormatProcedureStatoLabel from "./FormatProcedure/FormatProcedureStatoLabel";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import { deleteFormatProcedura as deleteFormatProceduraAction } from "app/actions";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { orderBy } from "lodash";
import moment from "moment";
import { useApi } from "app/hooks/useApi";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import {
  getShowCreaNuovaVersione,
  useCreaNuovaVersioneFormatProceduraDialog,
} from "./FormatProcedure/CreaNuovaVersioneFormatProcedure";

// PROPS: linea (data source), editing
export default function LineaRiquadroProcedure(props) {
  const hasPermission = useCheckPermission();
  const { linea, editing, reload } = props;

  const [showHistory, setShowHistory] = useState(false);

  const showToast = useToast();

  const deleteFPApi = useApi(deleteFormatProceduraAction, {
    onError: () => {
      showToast("Errore durante l'eliminazione del format procedura commessa", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const deleteFormatProcedura = async (idFp) => {
    await deleteFPApi.callApi(idFp);
    reload && reload();
  };

  // -------

  const formatProceduraCorrente = linea.getValue(
    "formatProceduraCorrente",
    null
  );
  const formatProceduraInLavorazione = linea.getValue(
    "formatProceduraInLavorazione",
    null
  );
  const formatProcedureHistory =
    linea.getValue("formatProcedureHistory", null) || [];

  const formatProcedura1 =
    formatProceduraCorrente || formatProceduraInLavorazione;
  const formatProcedura2 = formatProceduraCorrente
    ? formatProceduraInLavorazione
    : null;

  const showCreaNuovaVersione = getShowCreaNuovaVersione(linea.data);

  const {
    creaNuovaVersioneFormatProceduraDialog,
    openCreaNuovaVersioneFormatProceduraDialog,
  } = useCreaNuovaVersioneFormatProceduraDialog(formatProceduraCorrente);

  const renderFormatProceduraButton = (fp) => {
    return (
      <ColoredButton
        disabled={editing}
        variant="outlined"
        to={"/formatProcedure/" + fp.id}
        component={Link}
      >
        Format Procedura Commesse{" "}
        <span
          style={{
            textTransform: "none",
            fontSize: 10,
            marginBottom: 1,
            marginLeft: 12,
          }}
        >
          (v{fp.versione} - <FormatProcedureStatoLabel stato={fp.stato} />)
        </span>
      </ColoredButton>
    );
  };

  return (
    <div
      style={{
        minHeight: 48,
        width: "100%",
      }}
    >
      {formatProcedura1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderFormatProceduraButton(formatProcedura1)}
          </Grid>
        </Grid>
      )}
      {formatProcedura2 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderFormatProceduraButton(formatProcedura2)}
            {formatProcedura1 && (
              <>
                <Button
                  color="inherit"
                  variant="text"
                  style={{ fontSize: "0.7em", marginLeft: 20 }}
                  onClick={() => {
                    const ok = window.confirm(
                      "Vuoi eliminare la versione in preparazione?"
                    );
                    if (ok) {
                      deleteFormatProcedura(formatProceduraInLavorazione.id);
                    }
                  }}
                >
                  Elimina versione in preparazione
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {showCreaNuovaVersione &&
        hasPermission("crea_versione_format_procedura") && (
          <div style={{ marginTop: 10 }}>
            <Button
              color="primary"
              onClick={() => {
                openCreaNuovaVersioneFormatProceduraDialog();
              }}
            >
              Crea nuova versione
            </Button>
          </div>
        )}

      {formatProcedureHistory.length > 0 && (
        <div
          style={{
            marginTop: 10,
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ marginTop: 15 }}>
            <IconButton
              onClick={() => {
                setShowHistory(!showHistory);
              }}
            >
              <AccessTimeIcon />
            </IconButton>
          </div>
          {showHistory && (
            <div
              style={{
                marginTop: 10,
                width: "100%",
                marginLeft: 50,
                maxWidth: 500,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Versione</TableCell>
                    <TableCell>Entrata in vigore</TableCell>
                    <TableCell>Stato</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(formatProcedureHistory, ["id"], ["desc"]).map(
                    (fp) => (
                      <TableRow key={fp.id}>
                        <TableCell component="th" scope="row">
                          v{fp.versione}
                        </TableCell>
                        <TableCell>{getUltimaEntrataInVigore(fp)}</TableCell>
                        <TableCell>
                          <FormatProcedureStatoLabel stato={fp.stato} />
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            to={"/formatProcedure/" + fp.id}
                            component={Link}
                            // onClick={() => {
                            //   history.push("/formatProcedure/" + fp.id);
                            // }}
                          >
                            Apri
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      )}

      {creaNuovaVersioneFormatProceduraDialog}
    </div>
  );
}

function getUltimaEntrataInVigore(formatProcedura) {
  const statoReasons = formatProcedura.statoReasons;
  const ultimaEntrataInVigore = statoReasons.find(
    (stato) => stato.stato === "attivo"
  );
  if (!ultimaEntrataInVigore) {
    return "";
  }
  return moment(ultimaEntrataInVigore.date).format("DD/MM/YYYY");
}
