import { EnumColumnFilter } from './types'
import { TableColumn } from '../types'

export function applyEnumFilter (row, value, options: EnumColumnFilter, column: TableColumn) {
  const { values } = options
  if (!values || values.length === 0) {
    return true
  }

  return values.includes(value)
}
