import React, { useState, useEffect } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getProcedure as getProcedureAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { find } from "lodash";

// returnValue = 'id' (default),
// defaultSelection = 'all' (default)
export default function ProceduraFilterSelector(props) {
  const {
    onChange,
    returnValue = "id",
    defaultSelection = "all",
    label,
    value,
  } = props;

  const showToast = useToast();

  const procedure = useDataSource({
    initialData: [],
    loadAction: getProcedureAction,
    autoLoad: true,
    onLoadSuccess: (response) => {},
    onLoadError: (response) => {
      showToast("Error durante il caricamento della lista procedure", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const initalValue =
    typeof value !== "undefined" && value !== null
      ? value
      : defaultSelection === "all"
      ? "###ALL"
      : null;

  const getValue = (procedura) => {
    return procedura.id;
  };

  const handleChange = (event) => {
    let newValue = event.target.value;
    let procedura;
    const proceduraId =
      newValue && newValue !== "###ALL" && parseInt(newValue, 10);
    if (!newValue || isNaN(proceduraId)) {
      newValue = "###ALL";
    }

    if (newValue === "###ALL") {
      procedura = null;
    } else {
      procedura = find(procedure.data, (u) => u.id === proceduraId) || null;
    }
    const v = procedura ? getValue(procedura) : null;
    onChange(v);
  };

  console.log(procedure.data);

  return (
    <FormControl
      variant="standard"
      style={{ marginLeft: 8, marginRight: 8, marginTop: 0, marginBottom: 0 }}
    >
      {label && (
        <InputLabel style={{ position: "absolute", marginTop: -11 }}>
          {label}
        </InputLabel>
      )}

      <Select
        defaultValue={initalValue}
        onChange={handleChange}
        size="small"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <MenuItem value="###ALL">
          <em>Tutti</em>
        </MenuItem>
        <hr />

        {procedure.data.map((p) => {
          return (
            <MenuItem key={p.id} value={p.id}>
              {p.nome}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
