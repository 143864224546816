import React, { useCallback, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { dizionarioToOptions } from "app/utils/dizionarioToOptions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getLeggi as getLeggiAction,
  getEnti as getEntiAction,
  getAmbitiDiApplicazione as getAmbitiDiApplicazioneAction,
  duplicateLegge as duplicateLeggeAction,
  deleteLegge as deleteLeggeAction,
} from "app/actions";
import { useToast } from "app/hooks/useToast";
import useSideModal from "app/components/common/useSideModal";
import { useSubmitDataSource } from "app/hooks/DataSource/useSubmitDataSource";
import { useHistory, Link } from "react-router-dom";
import { Container, Dialog, Typography } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import TableActionButtons from "app/components/common/TableActionButtons";
import Legge from "app/components/Legge/Legge";
import LeggeStato from "app/constants/LeggeStato";
import BadgeLeggeStato from "app/components/common/BadgeLeggeStato";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import useCreaconsultingTitle from "../hooks/useCreaconsultingTitle";

export default function LeggiTable() {
  useCreaconsultingTitle("Leggi");

  const hasPermission = useCheckPermission();

  const leggi = useDataSource({
    loadAction: getLeggiAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const enti = useDataSource({
    initialData: [],
    loadAction: getEntiAction,
    autoLoad: true,
  });

  const ambitiDiApplicazione = useDataSource({
    initialData: [],
    loadAction: getAmbitiDiApplicazioneAction,
    autoLoad: true,
  });

  const showToast = useToast();

  const { openSideModal, sideModal } = useSideModal();

  const [deletingRowSource, setDeletingRowSource] = useState(null);

  const duplicateLegge = useSubmitDataSource({
    submitAction: duplicateLeggeAction,
    onSubmitSuccess: (response) => {
      showToast("Legge duplicata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/leggi/" + response.data.id);
    },
    onSubmitError: () => {
      showToast("Errore durante la duplicazione della legge", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const handleLeggeUpdated = useCallback(
    (legge) => {
      leggi.changeValue(legge.id, legge);
    },
    [leggi]
  );

  const history = useHistory();

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_legge"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {sideModal}

      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        Leggi
      </h1>

      {leggi.loading ? (
        "Caricamento lista leggi ..."
      ) : leggi.loadError ? (
        "Errore durante il caricamento della lista leggi"
      ) : (
        <DataTable
          source={leggi}
          rowSourceOptions={{
            deleteAction: deleteLeggeAction,
            onDeleteSuccess: () => {
              setDeletingRowSource(null);

              showToast("Legge eliminata con successo", { color: "success" });
            },

            onDeleteError: (response) => {
              setDeletingRowSource(null);

              showToast(
                response.constraintsViolated
                  ? "Non puoi eliminare questa legge perchè è già in utilizzo"
                  : "Errore durante l'eliminazione",
                { color: "error" }
              );
            },
          }}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 200,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TableActionButtons
                    link={`/leggi/${value}`}
                    openSideModal={() =>
                      openSideModal(
                        Legge,
                        {
                          id: value,
                          useIdFromProps: true,
                          onEditSuccess: handleLeggeUpdated,
                          edit: true,
                        },
                        value
                      )
                    }
                    canDelete={
                      (!rowSource.getValue("linee") ||
                        rowSource.getValue("linee").length === 0) &&
                      hasPermission("elimina_legge")
                    }
                    deleteItem={() => setDeletingRowSource(rowSource)}
                    canDuplicate={hasPermission("duplica_legge")}
                    duplicateItem={() =>
                      duplicateLegge.submit(rowSource.getValue("id"))
                    }
                    canEdit={hasPermission("modifica_legge")}
                    editItem={() =>
                      history.push(
                        "/leggi/" + rowSource.getValue("id") + "?edit=true"
                      )
                    }
                  />
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            {
              path: ["nome"],
              header: "Nome",
              width: 300,
              sortable: true,
              type: "text",
            },
            {
              path: ["intervento"],
              header: "Intervento",
              width: 300,
              sortable: true,
              type: "text",
            },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              width: 250,
              type: "enum",
              options: LeggeStato,
              defaultHiddenFilterOptions: {
                type: "enum",
                values: ["archiviata"],
                isNot: true,
              },
              renderCell: (value) => {
                return <BadgeLeggeStato stato={value} />;
              },
              renderRawValue: (value) => {
                return (
                  LeggeStato.find((stato) => stato.value === value)?.label ??
                  value
                );
              },
            },
            {
              sortable: true,
              path: ["anno"],
              width: 250,
              header: "Anno",
              type: "number",
            },
            {
              sortable: true,
              path: ["ente", "nome"],
              width: 250,
              header: "Ente",
              type: "enum",
              options: dizionarioToOptions(enti),
            },
            {
              sortable: true,
              path: ["ambitoDiApplicazione", "nome"],
              width: 250,
              header: "Ambito di applicazione",
              type: "enum",
              options: dizionarioToOptions(ambitiDiApplicazione),
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              {hasPermission("crea_legge") ? (
                <ColoredButton
                  disabled={false}
                  onClick={null}
                  color="default"
                  fullWidth={false}
                  size="small"
                  variant="outlined"
                  to="/leggi/new"
                  component={Link}
                >
                  Crea legge
                </ColoredButton>
              ) : null}{" "}
              <DataTableExportButtons filename={"Leggi"} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessuna legge",
          }}
        />
      )}

      <Dialog maxWidth="md" open={deletingRowSource}>
        <DialogTitle>Conferma eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {deletingRowSource
              ? 'Sei sicuro di voler eliminare la legge "' +
                deletingRowSource.getValue("nome") +
                '"?'
              : null}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={() => {
              setDeletingRowSource(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={() => {
              deletingRowSource.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
