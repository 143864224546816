import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import AbstractTabellaFatture from "./AbstractTabellaFatture";
import { ColoredButton } from "../../elements/ColoredButton";
import { useApi } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { pagamentoMassivoFatture } from "../../actions";
import moment from "moment";
import DateInput from "../common/DateInput";

type DialogPagamentoMassivoProps = {
  dialog: any;
  fattureSource: any;
  onPagamentoMassivo: () => void;
};

export default function DialogPagamentoMassivo({
  dialog,
  fattureSource,
  onPagamentoMassivo,
}: DialogPagamentoMassivoProps) {
  const fattureInviate = useMemo(
    () => fattureSource.data.filter((f) => f.stato === "inviata"),
    [fattureSource.data]
  );

  const [daPagare, setDaPagare] = useState<number[]>([]);
  const [date, setDate] = useState(moment());
  const canSend = daPagare.length > 0;

  const showToast = useToast();
  const pagamentoMassivoApi = useApi(pagamentoMassivoFatture, {
    onError: () => {
      showToast("Errore durante l'aggiornamento massivo delle fatture", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  function closeDialog() {
    setDaPagare([]);
    dialog.close();
  }

  async function doPagamentoMassivo() {
    try {
      await pagamentoMassivoApi.callApi({
        dataPagamento: date,
        fattureIds: daPagare,
      });
      onPagamentoMassivo();
      closeDialog();
    } catch (e) {}
  }

  return (
    <Dialog open={dialog.isOpen} fullWidth maxWidth="lg">
      <DialogTitle>Registrazione fatture pagate</DialogTitle>

      <DialogContent>
        <AbstractTabellaFatture
          fatture={fattureInviate}
          canSelectFattura
          selectFattura={(index, selected) => {
            const fattura = fattureInviate[index];
            if (selected) {
              setDaPagare([...daPagare, fattura.id]);
            } else {
              setDaPagare(daPagare.filter((id) => id !== fattura.id));
            }
          }}
          isFatturaSelected={(index) => {
            const fattura = fattureInviate[index];
            return daPagare.includes(fattura.id);
          }}
        />
      </DialogContent>

      <DialogActions>
        <div style={{ display: "flex", width: "100%", paddingLeft: 16 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              color: canSend ? undefined : "gray",
            }}
          >
            <Typography style={{ minWidth: 180 }}>
              Paga {daPagare.length}{" "}
              {daPagare.length === 1 ? "fattura" : "fatture"} in data{" "}
            </Typography>

            <DateInput
              variant="outlined"
              inputVariant="outlined"
              fullWidth
              size="small"
              value={date}
              onChange={setDate}
              disabled={!canSend}
            />
          </div>

          <div style={{ flex: 1 }} />
          <ColoredButton onClick={closeDialog} color="secondary">
            Annulla
          </ColoredButton>
          <ColoredButton
            onClick={doPagamentoMassivo}
            color="secondary"
            disabled={!canSend}
          >
            Aggiorna stato {daPagare.length === 1 ? "fattura" : "fatture"}
          </ColoredButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}
