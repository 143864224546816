import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFatturePerCliente as getFatturePerClienteAction } from "app/actions";
import FattureTable_abstract_internal from "./FattureTable_abstract_internal";

export default function FattureTablePerCliente(props) {
  const fatture = useDataSource({
    loadAction: getFatturePerClienteAction,
    itemId: props.idCliente,
    initialData: [],
  });

  return (
    <FattureTable_abstract_internal fatture={fatture} hideCliente={true} />
  );
}
