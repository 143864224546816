import { useRef, useState } from "react";
import { ValidationError } from "yup";

export function useValidation(validator, data) {
  const [validationErrors, setValidationErrors] = useState([]);
  const inputRef = useRef<{ validator: any; data: any }>();
  inputRef.current = { validator, data };

  function runValidation(key) {
    // @ts-ignore:
    if (!inputRef.current.validator) {
      return;
    }

    try {
      // @ts-ignore:
      inputRef.current.validator.validateSyncAt(key, inputRef.current.data, {
        abortEarly: false,
      });
      // @ts-ignore:
      setValidationErrors(mergeValidationErrors(validationErrors, [], key));
    } catch (e) {
      if (e instanceof ValidationError) {
        setValidationErrors(
          // @ts-ignore:
          mergeValidationErrors(validationErrors, e.inner, key)
        );
      }
    }
  }

  function getValidationErrors(key, isParialPath: boolean = false) {
    return (
      validationErrors
        .filter((e: any) => {
          if (isParialPath) {
            if (typeof e.path === "string" && e.path.startsWith(key)) {
              const remaining = (e.path as string).substring(key.length);
              return (
                remaining === "" || remaining[0] === "[" || remaining[0] === "."
              );
            }
            return false;
          } else {
            return e.path === key;
          }
        })
        // @ts-ignore:
        .map((e) => e.message)
    );
  }

  return {
    runValidation,
    getValidationErrors,
    setValidationErrors,
  } as const;
}

function mergeValidationErrors(previousErrors, newErrors, key) {
  const otherFieldsErrors = previousErrors.filter((e) => e.path !== key);
  return [...otherFieldsErrors, ...newErrors];
}
