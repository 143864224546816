import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { moveCard } from "@asseinfo/react-kanban";
import TBoard from "react-trello";

import "@asseinfo/react-kanban/dist/styles.css";
import moment from "moment";
import { findIndex, keyBy, max, min, orderBy, drop } from "lodash";
import "./fixReactTrello";

function applySortCardsByToCardData(sortCardsBy) {
  // sortCardsBy = [...sortCardsBy, ['updatedAt', 'asc']]
  return sortCardsBy.map((sort) => {
    const [field, direction] = sort;
    return [`data.${field}`, direction];
  });
}

function aggregateCards(columns, cards, getCardAggrKey, _sortCardsBy) {
  const columnsByKey = keyBy(columns, (column) => column.id);
  const cardsWithoutKey = [];

  cards.forEach((card) => {
    const key = getCardAggrKey(card.data);
    const column = columnsByKey[key];
    if (key != null && column) {
      column.cards.push(card);
    } else if (key == null) {
      cardsWithoutKey.push(card);
    } else {
      console.log("Not found column for card", card);
    }
  });

  const sortCardsBy = applySortCardsByToCardData(_sortCardsBy);

  columns.forEach((column) => {
    column.cards = orderBy(column.cards, ...sortCardsBy);
  });

  return orderBy(cardsWithoutKey, ...sortCardsBy);
}

export const NO_AGGR_KEY_COLUMN_ID = "___no_aggr_key___";

export function createBoard(
  data,
  getColumnTitle,
  getCardAggrKey,
  sortCardsBy,
  groupByDay,
  showSenzaData,
  preventDragOnSenzaData,
  forceStart = null,
  forceEnd = null,
  filterCardsWithoutKeys = (x) => x,
  shouldSkipFirstPastColumnsWithAllAttivitaCompletato = false
) {
  const cards = data.map((d) => ({
    id: d.id,
    data: d,
  }));

  const board = {
    columns: [],
  };

  if (!cards.length && !forceEnd && !forceStart) {
    return board;
  }

  const allKeys = cards
    .map((card) => getCardAggrKey(card.data))
    .filter((x) => x != null);
  const minDate = min([...allKeys, forceStart]);
  const maxDate = max([
    ...allKeys,
    forceEnd && moment(forceEnd).startOf("isoWeek").toISOString(),
  ]);
  // console.log("minDate", minDate);
  // console.log("maxDate", maxDate);

  const start = moment(minDate).startOf("isoWeek");
  const end = moment(maxDate).startOf("isoWeek").add(7, "days");
  let startOfWeek = start;

  let weeksAfterEndData = 4;
  const currentWeek = groupByDay
    ? moment().startOf("day").toISOString()
    : moment().startOf("isoWeek").toISOString();

  for (
    let index = 0;
    /* eslint-disable-next-line no-unmodified-loop-condition */
    startOfWeek.isBefore(end) || (!forceEnd && weeksAfterEndData-- > 0);
    index++
  ) {
    const aggrKey = startOfWeek.toISOString();

    const col = {
      id: aggrKey,
      title: getColumnTitle(aggrKey),
      cards: [],
      isCurrentWeek: aggrKey === currentWeek,
    };
    board.columns.push(col);

    startOfWeek = moment(startOfWeek).add(groupByDay ? 1 : 7, "days");
  }

  const cardsWithoutKey_ = aggregateCards(
    board.columns,
    cards,
    getCardAggrKey,
    sortCardsBy
  );

  if (shouldSkipFirstPastColumnsWithAllAttivitaCompletato) {
    board.columns = skipFirstPastColumnsWithAllAttivitaCompletato(
      board.columns
    );
  }

  const cardsWithoutKey = cardsWithoutKey_.filter(filterCardsWithoutKeys);
  if (showSenzaData && cardsWithoutKey.length) {
    const col = {
      id: NO_AGGR_KEY_COLUMN_ID,
      title: getColumnTitle(null),
      cards: cardsWithoutKey,
      droppable: !preventDragOnSenzaData,
    };
    board.columns = [col, ...board.columns];
  }

  return board;
}

export function createBoardWithColumns(
  data,
  columns,
  getCardAggrKey,
  sortCardsBy,
  filterCardsWithoutKeys = (x) => x
) {
  const cards = data.map((d) => ({
    id: d.id,
    data: d,
  }));

  const board = {
    columns: [],
  };

  if (columns) {
    columns.forEach((_col) => {
      const col = { ..._col, cards: [] };
      board.columns.push(col);
    });
  }

  const cardsWithoutKey_ = aggregateCards(
    board.columns,
    cards,
    getCardAggrKey,
    sortCardsBy
  );

  const cardsWithoutKey = cardsWithoutKey_.filter(filterCardsWithoutKeys);
  if (cardsWithoutKey.length) {
    const col = board.columns.find((x) => x.id === NO_AGGR_KEY_COLUMN_ID);
    if (col) {
      col.cards = cardsWithoutKey;
    }
  }

  return board;
}

function skipFirstPastColumnsWithAllAttivitaCompletato(columns) {
  const currentWeek = moment().startOf("isoWeek").toISOString();
  let columnsToSkipCount = 0;
  for (const column of columns) {
    const attivita = column.cards.map((card) => card.data);
    if (isAllAttivitaCompletate(attivita) && column.id < currentWeek) {
      columnsToSkipCount++;
    } else {
      break;
    }
  }
  return drop(columns, columnsToSkipCount);
}

function isAllAttivitaCompletate(attivita) {
  return attivita.filter((a) => a.stato !== "completato").length === 0;
}

export function Kanban(props) {
  const {
    data,
    getColumnTitle,
    getCardAggrKey,
    sortCardsBy,
    groupByDay,
    showSenzaData,
    preventDragOnSenzaData,
    forceStart,
    forceEnd,
    filterCardsWithoutKeys,
    shouldSkipFirstPastColumnsWithAllAttivitaCompletato,
    forceColumns,
  } = props;

  const [state, setState] = useState({ board: null, columnsById: {} });
  const { board, columnsById } = state;

  const setBoard = (board) => {
    setState({
      board,
      columnsById: keyBy(board.columns, "id"),
    });
  };

  useEffect(() => {
    // console.log("Board useEffect on change data");
    // TODO: TEMP
    // if (!board) {
    //   data.forEach((d) => {
    //     d.dataPrevista = moment()
    //       .add(Math.random() * 100, "days")
    //       .toISOString();
    //   });
    // }
    if (forceColumns) {
      setBoard(
        createBoardWithColumns(data, forceColumns, getCardAggrKey, sortCardsBy)
      );
    } else {
      setBoard(
        createBoard(
          data,
          getColumnTitle,
          getCardAggrKey,
          sortCardsBy,
          groupByDay,
          showSenzaData,
          preventDragOnSenzaData,
          forceStart,
          forceEnd,
          filterCardsWithoutKeys,
          shouldSkipFirstPastColumnsWithAllAttivitaCompletato
        )
      );
    }
  }, [forceColumns, data, groupByDay]);

  return (
    <KanbanControlled
      {...props}
      board={board}
      setBoard={setBoard}
      columnsById={columnsById}
    />
  );
}

export function KanbanControlled(props) {
  const {
    disabled,
    className,
    style,
    CardComponent,
    onCardDragEnd,

    // controlled props:
    board,
    setBoard,
    columnsById,
    groupByDay,
    showSenzaData,
    shouldSkipFirstPastColumnsWithAllAttivitaCompletato,
    scrollToCurrentWeek = false,
  } = props;

  const containerRef = useScrollToCurrentWeek(
    scrollToCurrentWeek,
    board,
    groupByDay,
    showSenzaData,
    shouldSkipFirstPastColumnsWithAllAttivitaCompletato
  );

  // function handleCardMove(card, source, destination) {
  //   const updatedBoard = moveCard(board, source, destination);
  //   if (onCardDragEnd) {
  //     const columnTo = columnsById[destination.toColumnId];
  //     onCardDragEnd(card.data, {
  //       fromColumnId: source.fromColumnId,
  //       fromPosition: source.fromPosition,
  //       toColumnId: destination.toColumnId,
  //       toPosition: destination.toPosition,
  //       columnCards: columnTo.cards.map((c) => c.data),
  //     });
  //   }
  //   setBoard(updatedBoard);
  // }

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position) => {
    const oldColumn = columnsById[sourceLaneId]; // find (board.columns, c => c.id === sourceLaneId)
    const oldPosition = findIndex(oldColumn.cards, (c) => c.id === cardId);
    const card = oldColumn.cards[oldPosition];
    const source = { fromPosition: oldPosition, fromColumnId: sourceLaneId };
    const destination = { toPosition: position, toColumnId: targetLaneId };
    const updatedBoard = moveCard(board, source, destination);
    if (onCardDragEnd) {
      const columnTo = columnsById[destination.toColumnId];
      const result = onCardDragEnd(card.data, {
        fromColumnId: source.fromColumnId,
        fromPosition: source.fromPosition,
        toColumnId: destination.toColumnId,
        toPosition: destination.toPosition,
        columnCards: columnTo.cards.map((c) => c.data),
      });
      if (result === false) {
        // setBoard({ ...board, columns: [...board.columns] });
        return false; // cancel move!
      }
    }
    setBoard(updatedBoard);
  };

  const data = useMemo(() => {
    return board
      ? {
          lanes: board.columns.map((x) => {
            return {
              disallowAddingCard: true,
              ...x,
            };
          }),
        }
      : null;
  }, [board]);

  const TCard = useMemo(() => {
    return ({ data }) => {
      return <CardComponent data={data} />;
    };
  }, [CardComponent]);

  return (
    <div
      className={classNames("creaconsulting-kanban", className)}
      style={style}
      ref={containerRef}
    >
      {/* {board && (
        <Board
          disableColumnDrag={true}
          onCardDragEnd={handleCardMove}
          renderCard={(card, { removeCard, dragging }) => (
            <CardComponent
              data={card.data}
              removeCard={removeCard}
              dragging={dragging}
            />
          )}
        >
          {board}
        </Board>
      )} */}
      {data && (
        <TBoard
          data={data}
          handleDragEnd={handleDragEnd}
          editable={!disabled}
          cardDraggable={!disabled}
          components={{
            Card: TCard,
          }}
        />
      )}
    </div>
  );
}

function useScrollToCurrentWeek(
  scrollToCurrentWeek,
  board,
  groupByDay,
  showSenzaData,
  shouldSkipFirstPastColumnsWithAllAttivitaCompletato
) {
  const containerRef = useRef();
  const currentWeekColumnIndex = useMemo(() => {
    return board?.columns?.findIndex((c) => c.isCurrentWeek) || -1;
  }, [board]);

  useEffect(() => {
    if (
      !containerRef.current ||
      currentWeekColumnIndex === -1 ||
      !scrollToCurrentWeek
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      const container = containerRef.current?.querySelector(
        ".smooth-dnd-container.horizontal"
      );
      const item = container?.children?.[currentWeekColumnIndex];
      if (item) {
        item.scrollIntoView({
          block: "end", // vertical
          inline: "center", // horizontal
        });
      }
    }, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    containerRef.current,
    currentWeekColumnIndex,
    scrollToCurrentWeek,
    groupByDay,
    showSenzaData,
    shouldSkipFirstPastColumnsWithAllAttivitaCompletato,
  ]);

  return containerRef;
}
