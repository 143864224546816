import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

export function useStateWithSaveCurrentState<T>(
  initialValue: T,
  saveCurrentState: string | null | undefined,
  keysToKeep: (keyof T)[] | "all_object" = []
): [T, Dispatch<SetStateAction<T>>] {
  const history = useHistory();
  const savedValueRef = useRef<string>();

  const [state, _setState] = useState(() => {
    if (saveCurrentState) {
      const savedState = getSavedState(history, saveCurrentState, keysToKeep);
      if (typeof savedState !== "undefined") {
        if (keysToKeep === "all_object") {
          initialValue = savedState;
        } else {
          initialValue = {
            ...initialValue,
            ...savedState,
          };
        }
      }
    }

    savedValueRef.current = prepareStateValue(initialValue, keysToKeep);
    return initialValue;
  });

  const _saveState = (s) => {
    const sString = prepareStateValue(s, keysToKeep);
    if (sString === savedValueRef.current) {
      return;
    }
    console.log("saveState", sString);

    saveState(history, saveCurrentState, sString);
    savedValueRef.current = sString;
  };

  const setState = (s) => {
    if (typeof s === "function") {
      _setState((prevS) => {
        const newS = s(prevS);
        _saveState(newS);
        return newS;
      });
    } else {
      _saveState(s);
      _setState(s);
    }
  };

  if (!saveCurrentState) {
    return [state, _setState];
  }

  return [state, setState];
}

function prepareStateValue(state, keysToKeep) {
  let r;
  if (Array.isArray(keysToKeep)) {
    r = {};
    keysToKeep.forEach((k) => {
      r[k] = state[k];
    });
  } else {
    r = state;
  }
  return JSON.stringify(r);
}

function saveState(history, saveCurrentState, sString) {
  //   location.hash = generateHash(saveCurrentState, sString);
  const s = history.location.state || {};
  const pathname = history.location.pathname;

  const newState = { ...s, [saveCurrentState]: sString };
  history.replace(pathname, newState);
}

function getSavedState(history, saveCurrentState, keysToKeep) {
  //   return parseHash(saveCurrentState, location.hash);
  const s = history.location.state;

  if (s && typeof s === "object" && s[saveCurrentState]) {
    try {
      const json = JSON.parse(s[saveCurrentState]);
      if (keysToKeep === "all_object" || typeof json === "object") {
        return json;
      }
    } catch (err) {}
  }
  return undefined;
}

// // HASH
// function generateHash(saveCurrentState: string, sString: string) {
//   return "$_" + saveCurrentState + "=" + encodeURIComponent(sString) + "$_";
// }

// function parseHash(saveCurrentState: string, hash: string | null | undefined) {
//   if (!hash) {
//     return null;
//   }
//   const parsedArray = hash.split("$_");
//   const parsed = parsedArray.filter((x) =>
//     x.startsWith(saveCurrentState + "=")
//   )[0];
//   if (!parsed) {
//     return null;
//   }
//   const jsonString = decodeURIComponent(
//     parsed.substring(saveCurrentState.length + 1)
//   );
//   try {
//     const json = JSON.parse(jsonString);
//     if (typeof json === "object") {
//       return json;
//     }
//   } catch (err) {}

//   return null;
// }
