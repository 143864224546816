import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import RuoliUtente from "app/components/Utenti/RuoliUtente";
import { Alert } from "@material-ui/lab";

export default function FormUtente(props) {
  return (
    <Container disableGutters={true} fixed={false} maxWidth="xl">
      <Grid container={true} item={false} spacing={3}>
        <Grid container={false} item={true} md={6} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("nome").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("nome")[0]}
            label="Nome"
            onBlur={(event) => {
              props.source.runValidation("nome");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "nome",

                value
              );
            }}
            type="text"
            value={props.source.getValue("nome", "")}
            variant="outlined"
          />
        </Grid>

        <Grid container={false} item={true} md={6} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("cognome").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("cognome")[0]}
            label="Cognome"
            onBlur={(event) => {
              props.source.runValidation("cognome");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "cognome",

                value
              );
            }}
            type="text"
            value={props.source.getValue("cognome", "")}
            variant="outlined"
          />
        </Grid>

        <Grid container={false} item={true} md={6} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("email").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("email")[0]}
            label="Email"
            onBlur={(event) => {
              props.source.runValidation("email");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "email",

                value
              );
            }}
            type="text"
            value={props.source.getValue("email", "")}
            variant="outlined"
          />
        </Grid>

        <Grid container={false} item={true} md={6} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("password").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("password")[0]}
            label="Password"
            onBlur={(event) => {
              props.source.runValidation("password");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "password",

                value
              );
            }}
            type="text"
            value={props.source.getValue("password", "")}
            variant="outlined"
          />
        </Grid>

        <Grid container={false} item={true} md={6} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={
              props.source.getValidationErrors("defaultWeeklyAvaibility")
                .length > 0
            }
            fullWidth={true}
            helperText={
              props.source.getValidationErrors("defaultWeeklyAvaibility")[0]
            }
            label="Disponibilità settimanale (ore)"
            onBlur={(event) => {
              props.source.runValidation("defaultWeeklyAvaibility");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "defaultWeeklyAvaibility",

                value
              );
            }}
            type="text"
            value={props.source.getValue("defaultWeeklyAvaibility", "")}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container={true} item={false} spacing={3}>
        <Grid container={false} item={true} md={6} xs={12}>
          <RuoliUtente readOnly={false} source={props.source} />
        </Grid>
      </Grid>
      {props.source.error ? (
        <Alert
          color="error"
          iconMapping={{}}
          onClose={null}
          role="alert"
          severity="error"
        >
          {`Errore: ${props.source.error.message}`}{" "}
        </Alert>
      ) : null}{" "}
    </Container>
  );
}
