// from: https://github.com/tannerlinsley/react-table/blob/master/src/sortTypes.js
import { find } from "lodash";
import moment from "moment";
import type { TableColumnArray, SortBy } from "./types";
import { DataSource, serializePath } from "../../hooks/DataSource/DataSource";

const reSplitAlphaNumeric = /([0-9]+)/gm;

function compareBasic(a, b) {
  return a === b ? 0 : a > b ? 1 : -1;
}

function toString(a) {
  if (typeof a === "number") {
    if (isNaN(a) || a === Infinity || a === -Infinity) {
      return "";
    }
    return String(a);
  }
  if (typeof a === "string") {
    return a;
  }
  return "";
}

// Mixed sorting is slow, but very inclusive of many edge cases.
// It handles numbers, mixed alphanumeric combinations, and even
// null, undefined, and Infinity
const alphanumeric = (a, b) => {
  // Force to strings (or "" for unsupported types)
  a = toString(a);
  b = toString(b);

  // Split on number groups, but keep the delimiter
  // Then remove falsey split values
  a = a.split(reSplitAlphaNumeric).filter(Boolean);
  b = b.split(reSplitAlphaNumeric).filter(Boolean);

  // While
  while (a.length && b.length) {
    const aa = a.shift().toLowerCase();
    const bb = b.shift().toLowerCase();

    const an = parseInt(aa, 10);
    const bn = parseInt(bb, 10);

    const combo = [an, bn].sort();

    // Both are string
    if (isNaN(combo[0])) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }
      continue;
    }

    // One is a string, one is a number
    if (isNaN(combo[1])) {
      return isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }

  return a.length - b.length;
};

export function sortFnDatetime(a, b) {
  a = moment(a).toDate().getTime();
  b = moment(b).toDate().getTime();

  return compareBasic(a, b);
}

// function basic (a, b) {
//   return compareBasic(a, b)
// }

function getSortFunction(column) {
  if (column.sortByFunction) {
    return column.sortByFunction;
  }
  // https://github.com/tannerlinsley/react-table/blob/master/src/sortTypes.js
  if (column.type === "date") {
    return sortFnDatetime;
  } else {
    return alphanumeric;
  }
}

export function sortRows(
  rows,
  columns: TableColumnArray,
  sortByArray: [SortBy],
  source: DataSource
) {
  const sortColumns = sortByArray
    .map((sortBy) => {
      const [serializedPath] = sortBy;
      const column = find(
        columns,
        (c) => serializePath(c.path) === serializedPath
      );
      if (!column) {
        console.warn(
          `useTable sort error: column "${serializedPath}" not found`
        );
        return null;
      }
      return [column, sortBy] as const;
    })
    .filter((columnWithSortBy) => !!columnWithSortBy);
  return [...rows].sort((rowA, rowB) => {
    for (const sortColumn of sortColumns) {
      if (!sortColumn) {
        continue;
      }
      const [column, sortBy] = sortColumn;
      const [, /* sortPath */ sortDir] = sortBy;
      const sortFn = getSortFunction(column);

      const sortRowFn = (rowA, rowB) => {
        const a = source
          .getChildDataSource([{ id: rowA.id }])
          .getValue(column.path);
        const b = source
          .getChildDataSource([{ id: rowB.id }])
          .getValue(column.path);
        return sortFn(a, b, rowA, rowB);
      };

      const sortInt = sortRowFn(rowA, rowB);
      if (sortInt !== 0) {
        const desc = sortDir === "DESC";
        return desc ? -sortInt : sortInt;
      }
    }
    return rowA.id - rowB.id || 0; // TODO: default order?
  });
}
