import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getPagamentiDaFatturare as getPagamentiDaFatturareAction } from "app/actions";
import PagamentiTable_abstract from "./PagamentiTable_abstract";

export default function PagamentiDaFatturareTable(props) {
  const pagamenti = useDataSource({
    loadAction: getPagamentiDaFatturareAction,
    initialData: [],
  });

  return (
    <PagamentiTable_abstract
      title="Pagamenti da fatturare"
      pagamenti={pagamenti}
      showCreaFattura={true}
      exportFilename="Pagamenti da fatturare"
    />
  );
}
