import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BookIcon from "@material-ui/icons/Book";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MoneyIcon from "@material-ui/icons/Money";
import EventIcon from "@material-ui/icons/Event";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import BarChartIcon from "@material-ui/icons/BarChart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import classNames from "classnames";
import { ListSubheader, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "block",
    flexGrow: 1,
    overflowY: "auto",
    "& .MuiDivider-root + .MuiDivider-root": {
      display: "none",
    },
  },
  nested: {
    paddingLeft: 32,
  },
  listItem: {
    "& .MuiListItemIcon-root": {
      minWidth: 36,
    },
  },
  sectionTitle: {
    lineHeight: "1.4rem",
    paddingBottom: 4,
    paddingTop: 2,
    background: "white",
  },
}));

function NavListItem({ to, children, exact = false, className }) {
  const classes = useStyles();
  return (
    <ListItem
      button
      component={NavLink}
      activeClassName="Mui-selected"
      to={to}
      exact={exact}
      className={classNames(className, classes.listItem)}
    >
      {children}
    </ListItem>
  );
}

export default function Sidebar() {
  const classes = useStyles();
  const hasPermission = useCheckPermission();
  return (
    <div className={classes.root}>
      {hasPermission("visualizza_menu_dashboard") && (
        <NavListItem to="/" exact>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </NavListItem>
      )}

      <Divider />

      {hasPermission("visualizza_menu_aziende") && (
        <NavListItem to="/aziende">
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Aziende" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_aziende") && (
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavListItem to="/contatti" className={classes.nested}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Contatti" />
            </NavListItem>

            <NavListItem to="/clienti-attivi" className={classes.nested}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Clienti attivi" />
            </NavListItem>

            <NavListItem to="/clienti-inattivi" className={classes.nested}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Clienti inattivi" />
            </NavListItem>
          </List>
        </Collapse>
      )}

      {hasPermission("visualizza_menu_rubrica") && (
        <NavListItem to="/rubrica">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Rubrica" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_rubrica") && (
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavListItem to="/lead" className={classes.nested}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Lead" />
            </NavListItem>
          </List>
        </Collapse>
      )}

      <Divider />

      {(hasPermission("visualizza_menu_scadenziario") ||
        hasPermission("visualizza_menu_offerte_e_mandati") ||
        hasPermission("visualizza_menu_scadenziario")) && (
        <ListSubheader component="div" className={classes.sectionTitle}>
          Commerciale
        </ListSubheader>
      )}

      {hasPermission("visualizza_menu_scadenziario") && (
        <NavListItem to="/scadenziario">
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Scadenziario commerciale" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_offerte_e_mandati") && (
        <NavListItem to="/mandati" exact>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Mandati" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_offerte_e_mandati") && (
        <>
          <NavListItem to="/opportunita">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Offerte" />
          </NavListItem>

          <List component="div" disablePadding>
            <NavListItem to="/opportunita-in-scadenza">
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary="Offerte in scadenza" />
            </NavListItem>
          </List>
        </>
      )}

      {hasPermission("visualizza_menu_scadenziario") && (
        <NavListItem to="/scadenziario-kanban">
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary="Kanban commerciale" />
        </NavListItem>
      )}

      <Divider />

      {(hasPermission("visualizza_menu_commesse") ||
        hasPermission("visualizza_menu_attivita")) && (
        <ListSubheader component="div" className={classes.sectionTitle}>
          Tecnico
        </ListSubheader>
      )}

      {/* //TODO: Commesse da assegnare */}

      {hasPermission("visualizza_menu_commesse") && (
        <NavListItem to="/procedure">
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Commesse" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_attivita") && (
        <NavListItem to="/attivita">
          <ListItemIcon>
            <ViewColumnIcon />
          </ListItemIcon>
          <ListItemText primary="Attività" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_attivita") && (
        <NavListItem to="/carico_lavoro">
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Carico di lavoro" />
        </NavListItem>
      )}

      <Divider />

      {(hasPermission("visualizza_menu_leggi") ||
        hasPermission("visualizza_menu_linee") ||
        hasPermission("visualizza_menu_format_procedura_commesse") ||
        hasPermission("visualizza_menu_format_strumenti")) && (
        <ListSubheader component="div" className={classes.sectionTitle}>
          Centro Studi
        </ListSubheader>
      )}

      {hasPermission("visualizza_menu_leggi") && (
        <NavListItem to="/leggi">
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Leggi" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_linee") && (
        <NavListItem to="/linee">
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Linee" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_format_procedura_commesse") && (
        <NavListItem to="/formatProcedure">
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                Format Procedura <br /> Commesse
              </>
            }
          />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_format_strumenti") && (
        <NavListItem to="/formatStrumenti">
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Format strumenti" />
        </NavListItem>
      )}

      <Divider />

      {hasPermission("visualizza_menu_pagamenti_e_fatture") && (
        <ListSubheader component="div" className={classes.sectionTitle}>
          Amministrazione
        </ListSubheader>
      )}

      {hasPermission("visualizza_menu_pagamenti_e_fatture") && (
        <NavListItem to="/pagamenti">
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Pagamenti" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_pagamenti_e_fatture") && (
        <NavListItem to="/pagamenti_da_fatturare">
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Pagamenti da fatturare" />
        </NavListItem>
      )}

      {hasPermission("visualizza_menu_pagamenti_e_fatture") && (
        <NavListItem to="/fatture">
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Fatture" />
        </NavListItem>
      )}
      {hasPermission("visualizza_menu_pagamenti_e_fatture") && (
        <NavListItem to="/fatture_da_approvare">
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Fatture da approvare" />
        </NavListItem>
      )}

      <Divider />

      {hasPermission("visualizza_dashboard_statistiche") && (
        <NavListItem to="/dashboard_commerciale">
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard commerciale" />
        </NavListItem>
      )}

      {hasPermission("visualizza_budget") && (
        <NavListItem to="/budget">
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Budget" />
        </NavListItem>
      )}

      {hasPermission("visualizza_volumi_contratti") && (
        <NavListItem to="/volumi_contratti">
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Volumi Contratti" />
        </NavListItem>
      )}

      {hasPermission("visualizza_analisi_commerciale") && (
        <NavListItem to="/analisi_commerciale">
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Analisi commerciale" />
        </NavListItem>
      )}

      <Divider />

      {hasPermission("gestioneUtenti") && (
        <>
          <NavListItem to="/utenti">
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Utenti" />
          </NavListItem>
        </>
      )}
      {hasPermission("gestioneRuoliPermessi") && (
        <>
          <NavListItem to="/ruoli_permessi">
            <ListItemIcon>
              <SupervisedUserCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Ruoli/Permessi" />
          </NavListItem>
        </>
      )}
      <Divider />

      {hasPermission("visualizza_menu_dizionari") && (
        <NavListItem to="/impostazioniDizionari">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Impostazioni dizionari" />
        </NavListItem>
      )}
      {hasPermission("visualizza_menu_news") && (
        <NavListItem to="/impostazioniNews">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Impostazioni news" />
        </NavListItem>
      )}
    </div>
  );
}
