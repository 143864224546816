import type { Cliente } from "db/Cliente";
import moment from "moment";

function filterVerifiedMail(x, type?) {
  return (x) => {
    return (x || [])
      .filter((x) => x.verified && x.type === type)
      .map((x) => x.address)
      .join(", ");
  };
}

export const atokaDataMapping: {
  label: string;
  atoka: string;
  dbAttribute: keyof Cliente;
  atokaAggr?: (x: any) => any;
  type?: "date" | "boolean" | "integer";
}[] = [
  {
    label: "Partita IVA",
    atoka: "base.vat",
    dbAttribute: "partitaIva",
  },
  {
    label: "Codice Fiscale",
    atoka: "base.taxId",
    dbAttribute: "cf",
  },
  {
    label: "Ragione Sociale",
    atoka: "base.legalName",
    dbAttribute: "ragioneSociale",
  },
  {
    label: "Natura Giuridica",
    atoka: "base.legalForms",
    atokaAggr: (x) => {
      x = x && x[x.length - 1];
      return (x && x.name) || "";
    },
    dbAttribute: "tipoAzienda",
  },

  {
    label: "Pec",
    atoka: "base.pec",
    atokaAggr: (x) => (Array.isArray(x) ? x.join(", ") : null),
    dbAttribute: "pec",
  },
  {
    label: "Website",
    atoka: "web.websites",
    atokaAggr: (x) => {
      x = x && x[0];
      return (x && x.url) || "";
    },
    dbAttribute: "website",
  },

  // non c'è
  // {
  //   label: 'ecommerce',
  //   atoka: '',
  //   dbAttribute: ''
  // },
  {
    label: "URL Atoka",
    atoka: "id",
    atokaAggr: (id) => `https://atoka.io/public/it/azienda/${id}/`,
    dbAttribute: "atokaUrl",
  },

  {
    label: "Indirizzo",
    atoka: "base.registeredAddress.fullAddress",
    dbAttribute: "indirizzo",
  },
  {
    label: "CAP",
    atoka: "base.registeredAddress.postcode",
    dbAttribute: "cap",
  },
  {
    label: "Comune",
    atoka: "base.registeredAddress.municipality",
    dbAttribute: "comune",
  },
  {
    label: "Provincia",
    atoka: "base.registeredAddress.province",
    dbAttribute: "provincia",
  },
  {
    label: "Regione",
    atoka: "base.registeredAddress.region",
    dbAttribute: "regione",
  },
  {
    label: "Area Geografica",
    atoka: "base.registeredAddress.macroregion",
    dbAttribute: "areaGeografica",
  },
  {
    label: "numeroUnitaLocaliOltreAllaSedeLegale",
    atoka: "locations.count",
    dbAttribute: "numeroUnitaLocali",
  },

  {
    label: "Codice ATECO2007",
    atoka: "base.ateco",
    atokaAggr: (x) => {
      x = x && x[0];
      return (x && x.code) || "";
    },
    dbAttribute: "codiceATECO",
  },
  {
    label: "Descrizione ATECO2007",
    atoka: "base.ateco",
    atokaAggr: (x) => {
      x = x && x[0];
      return (x && x.description) || "";
    },
    dbAttribute: "descrizioneCodiceATECO",
  },

  {
    label: "Codice NACE",
    atoka: "base.nace",
    atokaAggr: (x) => {
      x = x && x[0];
      return (x && x.code) || "";
    },
    dbAttribute: "codiceNace",
  },
  {
    label: "Numero REA",
    atoka: "base.rea",
    dbAttribute: "numeroRea",
  },

  // non c'è!
  // {
  //   label: 'provinciaREA',
  //   atoka: '',
  //   dbAttribute: ''
  // },

  {
    label: "Sul Mercato Da",
    atoka: "base.founded",
    dbAttribute: "sulMercatoDal",
    type: "date",
  },

  {
    label: "Startup Innovativa",
    atoka: "base.startup",
    dbAttribute: "startupInnovativa",
    type: "boolean",
  },
  {
    label: "Operativa",
    atoka: "active",
    dbAttribute: "operativa",
    type: "boolean",
  },
  {
    label: "Numero Dipendenti",
    atoka: "economics.employees",
    atokaAggr: (x) => {
      x = x && x[0];
      return x ? x.value : null;
    },
    dbAttribute: "numeroDipendenti",
    type: "integer",
  },
  {
    label: "Data Chiusura Bilancio",
    atoka: "economics.balanceSheets",
    dbAttribute: "dataChisuraBilancio",
    atokaAggr: (x) => {
      x = x && x[0] && x[0].date;
      const parsed = moment(x);
      if (parsed.isValid()) {
        return parsed.format("DD/MM/YYYY");
      }
      return x ? x.value : null;
    },
  },
  {
    label: "Ricavi",
    atoka: "economics.balanceSheets[0].revenue",
    dbAttribute: "ricavi",
  },

  {
    label: "Telefoni Verificati",
    atoka: "contacts.phones",
    atokaAggr: (x) => {
      return (x || [])
        .filter((x) => x.verified)
        .map((x) => x.number)
        .join(", ");
    },
    dbAttribute: "atokaTelefoniVerificati",
  },
  {
    label: "Email Verificate Info",
    atoka: "contacts.emails",
    atokaAggr: filterVerifiedMail("info"),
    dbAttribute: "atokaEmailInfo",
  },
  {
    label: "Email Verificate Acquisti",
    atoka: "contacts.emails",
    atokaAggr: filterVerifiedMail("purchases"),
    dbAttribute: "atokaEmailAcquisti",
  },
  {
    label: "Email Verificate Direzione",
    atoka: "contacts.emails",
    atokaAggr: filterVerifiedMail("management"),
    dbAttribute: "atokaEmailDirezione",
  },
  {
    label: "Email Verificate Amministrazione",
    atoka: "contacts.emails",
    atokaAggr: filterVerifiedMail("administration"),
    dbAttribute: "atokaEmailAmministrazione",
  },
  {
    label: "Faxes",
    atoka: "contacts.faxes",
    atokaAggr: (x) => (Array.isArray(x) ? x.join(", ") : null),
    dbAttribute: "atokaFaxes",
  },
];
