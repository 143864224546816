import React, { useEffect, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useApi } from "app/hooks/useApi";
import { useHistory, Link } from "react-router-dom";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  createCliente as createClienteAction,
  atokaSearch as atokaSearchAction,
  atokaGetData as atokaGetDataAction,
} from "app/actions";
import {
  Container,
  Dialog,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormCliente from "app/components/Clienti/FormCliente";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import LaunchIcon from "@material-ui/icons/Launch";
import Cliente from "app/components/Clienti/Cliente";
import TabIcon from "@material-ui/icons/Tab";
import useSideModal from "app/components/common/useSideModal";
import { atokaDataMapping } from "../../../atoka/atokaDataMapping";
import { get } from "lodash";

const _getAtokaValueWithObj = (data, x) => {
  let v = data && get(data, x.atoka);
  if (x.atokaAggr) {
    v = x.atokaAggr(v);
  }
  return v;
};

export default function AtokaSearch(props) {
  const [submitting, setSubmitting] = useState(false);
  const [search, setSearch] = useState(props.partitaIva ?? "");
  useEffect(() => {
    if (props.partitaIva && props.open) {
      setSearch(props.partitaIva);
    } else if (!props.open) {
      setSubmitting(false);
    }
  }, [props.partitaIva, props.open]);
  const { openSideModal, sideModal } = useSideModal();

  // search
  const [atokaSearch, setAtokaSearch] = useState({
    loading: false,
    data: null,
    error: null,
  });
  const atokaSearchApi = useApi(atokaSearchAction);

  // get data from atoka
  const [atokaGetData, setAtokaGetData] = useState({
    loading: false,
    data: null,
    error: null,
  });
  const atokaGetDataApi = useApi(atokaGetDataAction);

  const isUpdate = props.isUpdate;
  let showRetrievedData = false;

  const showToast = useToast();

  const history = useHistory();

  const cliente = isUpdate
    ? props.dataSource
    : useDataSource({
        initialData: {},
        createAction: createClienteAction,
        onCreateSuccess: (response) => {
          showToast("Cliente creato", {
            color: "success",
            horizontal: "left",
            vertical: "bottom",
          });

          history.push(`/azienda/${response.data.id}`);
          setSubmitting(false);
        },
        onCreateError: (response) => {
          showToast("Errore durante la creazione del cliente", {
            color: "error",
            horizontal: "left",
            vertical: "bottom",
          });
          setSubmitting(false);
        },
      });

  let stepState = "search";
  if (cliente.getValue("atokaId")) {
    stepState = isUpdate ? "update" : "create";
    if (atokaGetDataApi.data) {
      showRetrievedData = true;
    }
  }

  useEffect(async () => {
    if (!props.open) {
      setAtokaSearch({ loading: false, data: null, error: null });
      setAtokaGetData({ loading: false, data: null, error: null });
    } else if (stepState === "update") {
      const atokaId = cliente.getValue("atokaId");
      setAtokaGetData({ loading: true, data: null, error: null });
      const response = await atokaGetDataApi.callApi({
        atokaId: atokaId,
      });
      console.log(response);
      setAtokaGetData({
        loading: false,
        data: response.data,
        error: null,
        _dataImportEnabled: atokaDataMapping.map((x, index) => {
          return typeof x.defaultEnabled === "undefined"
            ? true
            : x.defaultEnabled;
        }),
      });
    } else if (stepState === "create") {
      const atokaId = cliente.getValue("atokaId");
      setAtokaGetData({ loading: true, data: null, error: null });
      const response = await atokaGetDataApi.callApi({
        atokaId: atokaId,
      });
      console.log(response);
      setAtokaGetData({ loading: false, data: response.data, error: null });
      // update new cliente
      for (let index = 0; index < atokaDataMapping.length; index++) {
        const x = atokaDataMapping[index];
        const value = _getAtokaValueWithObj(response.data, x);
        cliente.changeValue(x.dbAttribute, value);
      }
      cliente.changeValue("___createdFromAtoka", true);
    }
  }, [props.open, stepState]);

  const doSearch = async () => {
    if (!search) {
      return;
    }
    setAtokaSearch({ loading: true, data: null, error: null });
    const response = await atokaSearchApi.callApi({
      search: search,
    });
    console.log(response);
    setAtokaSearch({ loading: false, data: response.data, error: null });
  };

  const _startGetData = async (d) => {
    cliente.changeValue("atokaId", d.id);
  };

  const startCreateAzienda = (d) => {
    _startGetData(d);
  };
  const startUpdateAzienda = (d) => {
    _startGetData(d);
  };

  const isDataImportEnabled = (index) => {
    return (
      (atokaGetData._dataImportEnabled &&
        atokaGetData._dataImportEnabled[index]) ||
      false
    );
  };
  const setDataImportEnabled = (index, enabled) => {
    const n = [...atokaGetData._dataImportEnabled];
    n[index] = enabled;
    setAtokaGetData({
      ...atokaGetData,
      _dataImportEnabled: n,
    });
  };

  const getAtokaValueWithObj = (x) => {
    return _getAtokaValueWithObj(atokaGetData.data, x);
  };

  let atokaDataMappingChanged = atokaDataMapping;

  if (stepState === "update" && atokaGetData.data) {
    atokaDataMappingChanged = atokaDataMapping.filter((x) => {
      const attuale = cliente.getValue(x.dbAttribute, "");
      const nuovo = getAtokaValueWithObj(x);
      const changed = attuale ? !nuovo || attuale !== nuovo : !!nuovo;
      return changed;
    });
  }

  const updateClienteFromData = async () => {
    for (let index = 0; index < atokaDataMappingChanged.length; index++) {
      const x = atokaDataMappingChanged[index];
      if (isDataImportEnabled(index)) {
        const value = getAtokaValueWithObj(x);
        cliente.changeValue(x.dbAttribute, value);
      }
    }
    cliente.changeValue("___updatedFromAtoka", true);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 10);
    });
  };

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="lg"
      open={props.open}
      scroll="paper"
    >
      {sideModal}

      <DialogTitle>
        {stepState === "search" && "Cerca azienda in Atoka"}
        {stepState === "update" && "Aggiorna azienda con Atoka"}
      </DialogTitle>

      <DialogContent>
        {stepState === "search" && (
          <Container disableGutters={true} fixed={false} maxWidth={false}>
            <Grid container={true} item={false} spacing={3}>
              <Grid
                container={false}
                item={true}
                md={12}
                direction="row"
                style={{ display: "flex" }}
              >
                <TextField
                  fullWidth={true}
                  helperText={
                    "Usa la Partita IVA per trovare un'azienda nel database Atoka"
                  }
                  label="Cerca azienda..."
                  onChange={(event) => {
                    const value = event.target.value;
                    setSearch(value);
                  }}
                  type="text"
                  value={search}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      doSearch();
                    }
                  }}
                />
                <div>
                  <ColoredButton
                    disabled={atokaSearch.loading}
                    onClick={doSearch}
                    color="primary"
                    style={{ marginLeft: 20, marginTop: 26 }}
                    variant="outlined"
                  >
                    Cerca
                  </ColoredButton>
                </div>
              </Grid>
            </Grid>

            {atokaSearch.loading && (
              <Grid container={true} item={false} spacing={3}>
                <Grid container={false} item={true} md={12}>
                  Ricerca in corso...
                </Grid>
              </Grid>
            )}

            {atokaSearch.data && (
              <Grid container={true} item={false} spacing={3}>
                <Grid container={false} item={true} md={12}>
                  <h4>Risultati ricerca</h4>
                  {atokaSearch.data.length === 0 && <div>Nessun risultato</div>}
                  {atokaSearch.data.map((d, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          padding: "10px 6px",
                          border: "1px solid #ccc",
                          borderTop: index !== 0 ? "0px" : "1px solid #ccc",
                        }}
                      >
                        <div style={{ fontSize: "1.1rem" }}>{d.name}</div>
                        <div>{d.fullAddress}</div>

                        {!isUpdate && d.creaId && (
                          <div style={{ color: "#444" }}>
                            Azienda già presente.
                            <IconButton
                              to={`/azienda/${d.creaId}`}
                              component={Link}
                            >
                              <LaunchIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                openSideModal(
                                  Cliente,
                                  {
                                    id: d.creaId,
                                    useIdFromProps: true,
                                    showOpenButton: true,
                                    edit: true,
                                  },
                                  d.creaId
                                );
                              }}
                            >
                              <TabIcon />
                            </IconButton>
                          </div>
                        )}
                        {!isUpdate && !d.creaId && (
                          <div style={{ color: "#444" }}>
                            <ColoredButton
                              onClick={() => startCreateAzienda(d)}
                              color="primary"
                              variant="text"
                              style={{ marginTop: 4 }}
                            >
                              Importa azienda
                            </ColoredButton>
                          </div>
                        )}

                        {isUpdate && (
                          <div style={{ color: "#444" }}>
                            <ColoredButton
                              onClick={() => startUpdateAzienda(d)}
                              color="primary"
                              variant="text"
                              style={{ marginTop: 4 }}
                            >
                              Usa questa azienda
                            </ColoredButton>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Container>
        )}

        {(stepState === "update" || stepState === "create") && (
          <Container disableGutters={true} fixed={false} maxWidth={false}>
            {atokaGetData.loading && (
              <Grid container={true} item={false} spacing={3}>
                <Grid container={false} item={true} md={12}>
                  Caricamento dati in corso...
                </Grid>
              </Grid>
            )}

            {stepState === "update" && atokaGetData.data && (
              <>
                {!submitting &&
                  atokaDataMappingChanged.map((x, index) => {
                    return (
                      <Grid
                        key={index}
                        container={true}
                        item={false}
                        spacing={3}
                        style={{ marginTop: 10 }}
                      >
                        <Grid
                          container={false}
                          item={true}
                          md={12}
                          style={{ marginBottom: -40 }}
                        >
                          <h4>{x.label}</h4>
                        </Grid>
                        <Grid container={false} item={true} md={5}>
                          <TextField
                            fullWidth={true}
                            label="Valore attuale"
                            readOnly
                            type="text"
                            value={cliente.getValue(x.dbAttribute, "")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid container={false} item={true} md={5}>
                          <TextField
                            fullWidth={true}
                            label="Nuovo valore ATOKA"
                            type="text"
                            readOnly
                            value={getAtokaValueWithObj(x)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid container={false} item={true} md={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isDataImportEnabled(index)}
                                onChange={(e) =>
                                  setDataImportEnabled(index, e.target.checked)
                                }
                                name={"isDataImportEnabled_" + index}
                                color="primary"
                              />
                            }
                            label="Aggiorna"
                          />
                        </Grid>
                      </Grid>
                    );
                  })}

                {submitting ? (
                  <div>Aggiornamento in corso ...</div>
                ) : atokaDataMappingChanged.length === 0 ? (
                  <div>
                    Nessun dato cambiato rispetto a quelli attualmente presenti.
                  </div>
                ) : null}
              </>
            )}

            {stepState === "create" && atokaGetData.data && (
              <>
                {submitting ? (
                  <div>Creazione in corso ...</div>
                ) : (
                  <Grid container={true} item={false} spacing={3}>
                    <Grid container={false} item={true} md={12}>
                      <FormCliente
                        readOnly={false}
                        source={cliente}
                        isCreateForm={true}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Container>
        )}
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            props.cancel();

            if (isUpdate) {
              cliente.cancel();
            } else {
              cliente.clear();
            }
            setAtokaSearch({ loading: false, data: null, error: null });
            setAtokaGetData({ loading: false, data: null, error: null });
          }}
          color="secondary"
          disabled={atokaSearch.loading || atokaGetData.loading || submitting}
        >
          Annulla
        </ColoredButton>

        {showRetrievedData && atokaDataMappingChanged.length > 0 && (
          <ColoredButton
            disabled={cliente.loading || cliente.editing || submitting}
            onClick={async (event) => {
              setSubmitting(true);
              if (isUpdate) {
                await updateClienteFromData();
              }
              cliente.commit();
            }}
            color="primary"
          >
            Salva
          </ColoredButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
