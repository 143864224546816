import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getMandatiOnlyOpportunita as getMandatiOnlyOpportunitaAction } from "app/actions";
import MandatiTable_abstract from "app/components/MandatiTable_abstract";
import useCreaconsultingTitle from "../hooks/useCreaconsultingTitle";

export default function OpportunitaTable(props) {
  const mandati = useDataSource({
    loadAction: getMandatiOnlyOpportunitaAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  useCreaconsultingTitle(`Offerte`);

  return (
    <MandatiTable_abstract
      isOpportunita={true}
      mandati={mandati}
      title="Offerte"
      exportFilename="Offerte"
    />
  );
}
