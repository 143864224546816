export const defaultIntervalloPromemoriaRicontatto = 30 * 2;

export function getIntervalloPromemoriaRicontatto(entity, utente) {
  const intervalloPromemoriaRicontatto =
    entity?.intervalloPromemoriaRicontatto ||
    utente?.intervalloPromemoriaRicontatto ||
    defaultIntervalloPromemoriaRicontatto;
  if (intervalloPromemoriaRicontatto > 0) {
    return intervalloPromemoriaRicontatto;
  }
  return null;
}
