import React from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useUser } from "app/hooks/useUser";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import Sidebar from "app/components/Sidebar";
import { logout as logoutAction } from "app/actions";

const drawerWidth = 240;
const drawerWidthClosed = 72;

const logo = {
  background: "url('/logo.png')",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "58px",
  backgroundSize: "contain",
  height: 52,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxHeight: "100vh",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbarLeftLogo: {
    flexGrow: 1,
    ...logo,
  },
  // toolbarRightLogo: {
  //   ...logo,
  // },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  rightContentDiv: {
    flexGrow: 1,
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    maxWidth: `calc(100vw - ${drawerWidthClosed}px)`,
  },
  rightContentDivShift: {
    width: `calc(100vw - ${drawerWidth}px)`,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  contentWithContainerFullHeight: {
    flex: 1,
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerFullHeight: {
    flexGrow: 1,
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

function getAvatarLetters(user) {
  const getParts = (text, max = 1) => {
    text = text || "";
    const array = text.split(" ").filter((x) => x);
    array.splice(max, Infinity);
    return array.map((x) => x.charAt(0).toUpperCase()).join("");
  };
  const letters =
    [getParts(user.nome, 1), getParts(user.cognome, 2)]
      .filter((x) => x)
      .join("") || "U";
  return letters || "U";
}

export default function MainPage({ children, containerFullHeight }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useUser();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  const toolbarColor =
    window.widuEnv === "test"
      ? "#FF0000"
      : window.widuEnv === "migrazione"
      ? "#FFA500"
      : "#0084A9";

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ backgroundColor: toolbarColor }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {/* <div className={classes.toolbarRightLogo}/>  */}
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

          <IconButton color="inherit" onClick={handleProfileMenuOpen}>
            <Avatar>{getAvatarLetters(user)}</Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarLeft}>
          <div className={classes.toolbarLeftLogo} />
          {/* <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton> */}
        </div>
        <Divider />
        <Sidebar />
      </Drawer>
      <div
        className={clsx(
          classes.rightContentDiv,
          open && classes.rightContentDivShift
        )}
      >
        <div className={classes.appBarSpacer} />
        <main
          className={clsx(
            classes.content,
            containerFullHeight && classes.contentWithContainerFullHeight
          )}
        >
          <Container
            maxWidth={false}
            // maxWidth="lg"
            className={clsx(
              classes.container,
              containerFullHeight && classes.containerFullHeight
            )}
          >
            {children}
          </Container>
        </main>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem component={Link} href="/gestione-utente">
          Gestione utente
        </MenuItem>
      </Menu>
    </div>
  );
}
