import React from "react";
import { Container, Grid } from "@material-ui/core";
import UserPicker from "app/components/common/UserPicker";
import DateInput from "app/components/common/DateInput";
import RichTextEditor from "app/components/common/RichTextEditor";
import moment from "moment";
import AziendaMandatoContattoPicker from "./AziendaMandatoContattoPicker";

export default function EventoAgendaForm({
  source,
  readOnly,
  hideDataEffettiva = false,
  hideStato = false,
  canChangeRelations = false,
}) {
  const evento = source.data;
  const done = !!evento.dataEffettiva;

  let color = null;
  let statoText;
  if (done) {
    color = "#4caf50";
    statoText = "Completato";
  } else {
    color = "#eb2a2a";
    statoText = "Non completato";
  }

  return (
    <Container disableGutters>
      <Grid container spacing={3}>
        <Grid container item lg={12} md={12} xs={12} spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <UserPicker
              variant="outlined"
              readOnly={readOnly}
              source={source}
              sourceKey="idUtente"
              label="Utente"
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} xs={12} spacing={3}>
          <Grid item lg={4} sm={12} xs={12}>
            <DateInput
              label="Data programmata"
              inputVariant="outlined"
              fullWidth
              size="small"
              variant="outlined"
              disabled={readOnly}
              source={source}
              sourceKey="dataProgrammata"
            />
          </Grid>
          {!hideDataEffettiva && (
            <Grid item lg={4} sm={12} xs={12}>
              <DateInput
                label="Data effettiva"
                inputVariant="outlined"
                fullWidth
                size="small"
                variant="outlined"
                disabled={readOnly}
                source={source}
                sourceKey="dataEffettiva"
                maxDate={moment().endOf("day")}
              />
            </Grid>
          )}
          {!hideStato && (
            <Grid item lg={4} sm={12} xs={12}>
              <div
                style={{ display: "flex", alignItems: "center", paddingTop: 6 }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    backgroundColor: color,
                    margin: 8,
                    minWidth: 25,
                    minHeight: 25,
                  }}
                />
                {statoText}
              </div>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} className="with-textfield-black-color">
          <AziendaMandatoContattoPicker
            source={source}
            idAziendaKey="idAzienda"
            idMandatoKey="idMandato"
            idContattoKey="idContatto"
            readOnly={!canChangeRelations}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <RichTextEditor
            __to_reload={source.getValue("descrizione")}
            source={source}
            sourceKey="descrizione"
            readOnly={readOnly}
            label="Descrizione"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
