import React, { useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getCliente as getClienteAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { orderBy } from "lodash";

export default function ClienteBancaPicker(props) {
  const showToast = useToast();

  const [selectedBanca, setSelectedBanca] = useState(null);

  console.log("props", props);

  const cliente = useDataSource({
    initialData: {},
    loadAction: getClienteAction,
    itemId: props.idCliente,
    autoLoad: props.idCliente > 0,
    onLoadSuccess: (response) => {
      const banca = response.data.banche.find((b) => {
        return b.id === props.source.getValue(props.sourceKey);
      });

      if (banca) {
        setSelectedBanca(banca);
      } else {
        setSelectedBanca(null);
      }
    },
    onLoadError: (response) => {
      showToast("Error durante il caricamento delle banche del cliente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const banche = useDataSource({
    parent: cliente,
    pathInParent: ["banche"],
    initialData: [],
  });

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;

  const options = useMemo(() => {
    const d = banche.data || [];

    d.forEach((option) => {
      const label = option?.banca || "";
      option._labelCaseIns = label.toLowerCase();
    });

    return orderBy(d, ["_labelCaseIns"], ["asc"]);
  }, [banche.data]);

  return (
    <Autocomplete
      disabled={props.readOnly}
      fullWidth={true}
      loading={cliente.loading}
      loadingText="Caricamento lista banche ..."
      multiple={false}
      noOptionsText="Nessuna banca"
      onClose={null}
      options={options}
      size="small"
      value={selectedBanca}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={props.variant || "outlined"}
            error={!!sourceError}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option?.banca || "";
      }}
      renderOption={(option) => {
        return option?.banca || "";
      }}
      onChange={(event, value) => {
        setSelectedBanca(value);

        props.source.changeValue(props.sourceKey, value?.id ?? null);
      }}
    />
  );
}
