import React from "react";
import {
  Container,
  Chip,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { getUserRoles } from "common/roles";
import { permissionsByRole } from "common/permissionsByRole";

export default function RuoliUtente(props) {
  return (
    <Container disableGutters={true} fixed={false} maxWidth="xl">
      {props.readOnly ? (
        <div>
          {getUserRoles(props).map(([role], index) => {
            return (
              <Chip
                key={role}
                clickable={false}
                color="primary"
                label={role}
                size="small"
                variant="default"
              />
            );
          })}{" "}
        </div>
      ) : (
        <FormControl
          disabled={false}
          error={false}
          focused={false}
          fullWidth={true}
          margin="normal"
          variant="outlined"
        >
          <FormLabel color="primary" disabled={false} error={false}>
            Ruoli
          </FormLabel>

          <FormGroup>
            {Object.keys(permissionsByRole).map((role, index) => {
              return (
                <FormControlLabel
                  key={role}
                  control={
                    <Switch
                      checked={props.source.getValue(["roles", role], false)}
                      disabled={props.source.loading || props.source.editing}
                      onChange={(event) => {
                        const value = event.target.checked;

                        props.source.changeValue(
                          ["roles", role],

                          value
                        );
                      }}
                      value={props.source.getValue(["roles", role], "")}
                    />
                  }
                  label={role}
                />
              );
            })}{" "}
          </FormGroup>
        </FormControl>
      )}{" "}
    </Container>
  );
}
