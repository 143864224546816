import React, { Fragment, useState } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import numeral from "numeral";
import moment from "moment";
import BadgePagamentoStato from "app/components/common/BadgePagamentoStato";
import NumberInput from "app/components/common/NumberInput";
import CampoNoteFattureEPagamenti from "./CampoNoteFattureEPagamenti";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import DeleteIcon from "@material-ui/icons/Delete";
import BeneLabel from "../common/BeneLabel";
import { some } from "lodash";

type SelectPagamentoFatturaProps = {
  canSelectPagamento?: boolean;
  isPagamentoSelected?: (
    indexFattura: number,
    indexPagamento: number
  ) => boolean;
  selectPagamento?: (
    indexFattura: number,
    indexPagamento: number,
    selected: boolean
  ) => void;
};

type RemovePagamentoFatturaProps = {
  canRemovePagamento?: boolean;
  onRemovePagamento?: (
    indexFattura: number,
    indexPagamento: number,
    removed: boolean
  ) => void;
  isPagamentoRemoved?: (
    indexFattura: number,
    indexPagamento: number
  ) => boolean;
};

type SelectFatturaProps = {
  canSelectFattura?: boolean;
  selectFattura?: (indexFattura: number, selected: boolean) => void;
  isFatturaSelected?: (indexFattura: number) => boolean;
};

type InsertNumeroFatturaProps = {
  canInsertNumeroFattura?: boolean;
  onNumeroFatturaChange?: (indexFattura: number, numeroFattura: string) => void;
  getNumeroFattura?: (indexFattura: number) => string;
  showNumeroFatturaErrorIfEmpty?: boolean;
};

type AbstractTabellaFattureProps = {
  fatture: any[];
  canEditImportoEffettivo?: boolean;
  onImportoEffettivoChange?: (
    indexFattura: number,
    indexPagamento: number,
    value: number | null
  ) => void;
  showImportiFattura?: boolean;
  onFatturaNoteChanged?: any;
  showNoteFattura?: boolean;
  onPagamentoNoteChanged?: any;
  showNotePagamento?: boolean;
} & SelectPagamentoFatturaProps &
  RemovePagamentoFatturaProps &
  SelectFatturaProps &
  InsertNumeroFatturaProps;

export default function AbstractTabellaFatture({
  fatture,
  canSelectPagamento = false,
  selectFattura,
  isPagamentoSelected,
  selectPagamento,
  canEditImportoEffettivo = false,
  onImportoEffettivoChange,
  showImportiFattura = true,
  onFatturaNoteChanged,
  showNoteFattura = true,
  onPagamentoNoteChanged,
  showNotePagamento = true,
  canRemovePagamento,
  onRemovePagamento,
  isPagamentoRemoved,
  canSelectFattura = false,
  isFatturaSelected,
  canInsertNumeroFattura = false,
  onNumeroFatturaChange,
  getNumeroFattura,
  showNumeroFatturaErrorIfEmpty = false,
}: AbstractTabellaFattureProps) {
  const [pagamentiEditing, setPagamentiEditing] = useState({});

  const _canSelectPagamento = (pagamento) => {
    return pagamento.stato !== "maturato-standby";
  };

  const _canSelectFattura = (fattura) => {
    console.log(fattura);
    return some(fattura.pagamenti, _canSelectPagamento);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {(canSelectFattura || canSelectPagamento) && (
              <TableCell></TableCell>
            )}
            {canInsertNumeroFattura && <TableCell>Numero fattura</TableCell>}
            <TableCell>Cliente</TableCell>
            <TableCell>Conto bancario</TableCell>
            <TableCell>Termine di pagamento</TableCell>
            {showImportiFattura && <TableCell>Importo maturato</TableCell>}
            {showImportiFattura && <TableCell>Importo effettivo</TableCell>}
            {showNoteFattura && <TableCell>Note</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {fatture.map((fattura, index) => (
            <Fragment key={index}>
              <TableRow>
                {(canSelectFattura || canSelectPagamento) && (
                  <TableCell component="th" scope="row">
                    {selectFattura && (
                      <Checkbox
                        checked={isFatturaSelected?.(index) || false}
                        onChange={(event) => {
                          const value = event.target.checked;
                          selectFattura(index, value);
                        }}
                        size="small"
                        disabled={!_canSelectFattura(fattura)}
                      />
                    )}
                  </TableCell>
                )}
                {canInsertNumeroFattura && (
                  <TableCell>
                    <TextField
                      variant="outlined"
                      style={{ width: 80 }}
                      disabled={!isFatturaSelected?.(index)}
                      value={getNumeroFattura?.(index)}
                      onChange={(e) =>
                        onNumeroFatturaChange?.(index, e.target.value)
                      }
                      error={
                        showNumeroFatturaErrorIfEmpty &&
                        !getNumeroFattura?.(index)
                      }
                      helperText={
                        showNumeroFatturaErrorIfEmpty &&
                        !getNumeroFattura?.(index) &&
                        "Numero mancante"
                      }
                    />
                  </TableCell>
                )}
                <TableCell>{fattura.cliente.ragioneSociale}</TableCell>
                <TableCell>
                  {fattura.banca ? (
                    <div>
                      {fattura.banca.banca}
                      <br />
                      <div
                        style={{
                          fontSize: "0.75em",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: 190,
                        }}
                      >
                        {fattura.banca.iban}
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{fattura.terminiPagamento}</TableCell>
                {showImportiFattura && (
                  <TableCell align="right">
                    {`${numeral(fattura.importoMaturato).format("0,0.00")} €`}
                  </TableCell>
                )}
                {showImportiFattura && (
                  <TableCell align="right">
                    {`${numeral(fattura.importoEffettivo).format("0,0.00")} €`}
                  </TableCell>
                )}
                {showNoteFattura && (
                  <TableCell>
                    <CampoNoteFattureEPagamenti
                      value={fattura.note}
                      onChange={(value) => onFatturaNoteChanged?.(index, value)}
                      disabled={
                        !onFatturaNoteChanged
                          ? true
                          : canSelectPagamento
                          ? !fattura.__selected
                          : false
                      }
                    />
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell colSpan={7} style={{ paddingLeft: 70 }}>
                  {fattura.pagamenti.length > 0 && (
                    <PagamentiTable
                      indexFattura={index}
                      pagamenti={fattura.pagamenti}
                      canSelectPagamento={canSelectPagamento}
                      showNotePagamento={showNotePagamento}
                      canEditImportoEffettivo={canEditImportoEffettivo}
                      selectPagamento={selectPagamento}
                      isPagamentoSelected={isPagamentoSelected}
                      pagamentiEditing={pagamentiEditing}
                      onImportoEffettivoChange={onImportoEffettivoChange}
                      setPagamentiEditing={setPagamentiEditing}
                      onPagamentoNoteChanged={onPagamentoNoteChanged}
                      canRemovePagamento={canRemovePagamento}
                      onRemovePagamento={onRemovePagamento}
                      isPagamentoRemoved={isPagamentoRemoved}
                      _canSelectPagamento={_canSelectPagamento}
                    />
                  )}
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function PagamentiTable({
  indexFattura,
  pagamenti,
  canSelectPagamento,
  showNotePagamento,
  canEditImportoEffettivo,
  selectPagamento,
  isPagamentoSelected,
  pagamentiEditing,
  onImportoEffettivoChange,
  setPagamentiEditing,
  onPagamentoNoteChanged,
  canRemovePagamento = false,
  onRemovePagamento,
  isPagamentoRemoved = () => false,
  _canSelectPagamento,
}: {
  indexFattura: number;
  pagamenti: any[];
  showNotePagamento: boolean;
  canEditImportoEffettivo: boolean;
  pagamentiEditing: any;
  onImportoEffettivoChange?: (
    indexFattura: number,
    indexPagamento: number,
    value: number | null
  ) => void;
  setPagamentiEditing?: any;
  onPagamentoNoteChanged?: any;
  _canSelectPagamento: any;
} & SelectPagamentoFatturaProps &
  RemovePagamentoFatturaProps) {
  const hasActionsColumn = canSelectPagamento || canRemovePagamento;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {hasActionsColumn && <TableCell></TableCell>}
          <TableCell>Data maturazione</TableCell>
          <TableCell>Mandato/Commessa</TableCell>
          <TableCell>Linea</TableCell>
          <TableCell>Milestone di pagamento</TableCell>
          <TableCell>Stato</TableCell>
          <TableCell>Importo maturato</TableCell>
          <TableCell>Importo effettivo</TableCell>
          {showNotePagamento && <TableCell>Note</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {pagamenti.map((pagamento, indexP) => {
          const pagamentoRemoved = isPagamentoRemoved(indexFattura, indexP);
          const { numeroMarcheDaBollo = 0, valoreMarcaDaBollo = 0 } = pagamento;

          if (pagamento.fittizio) {
            return (
              <TableRow
                style={{
                  textDecoration: pagamentoRemoved ? "line-through" : "initial",
                }}
                key={indexP}
              >
                {/* {hasActionsColumn && (
                  <TableCell>
                    {selectPagamento && canSelectPagamento && (
                      <Checkbox
                        checked={
                          (isPagamentoSelected
                            ? isPagamentoSelected(indexFattura, indexP)
                            : pagamento.__selected) || false
                        }
                        onChange={(event) => {
                          const value = event.target.checked;
                          selectPagamento(indexFattura, indexP, value);
                        }}
                        size="small"
                        disabled={!_canSelectPagamento(pagamento)}
                      />
                    )}
                    {onRemovePagamento && canRemovePagamento && (
                      <Tooltip
                        title={
                          pagamentoRemoved
                            ? "Mantieni pagamento"
                            : "Rimuovi pagamento"
                        }
                      >
                        <IconButton
                          onClick={() => {
                            onRemovePagamento(
                              indexFattura,
                              indexP,
                              !pagamentoRemoved
                            );
                          }}
                        >
                          {pagamentoRemoved ? (
                            <RestoreFromTrashIcon />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                )} */}

                <TableCell
                  colSpan={7}
                  style={{ paddingTop: 4, paddingBottom: 4 }}
                >
                  <em>Pagamento creato in Mago: {pagamento.note}</em>
                </TableCell>

                <TableCell align="right">
                  {`${numeral(pagamento.importoEffettivo).format("0,0.00")} €`}
                </TableCell>

                <TableCell></TableCell>
              </TableRow>
            );
          }

          return (
            <TableRow
              style={{
                textDecoration: pagamentoRemoved ? "line-through" : "initial",
              }}
              key={indexP}
            >
              {hasActionsColumn && (
                <TableCell>
                  {selectPagamento && canSelectPagamento && (
                    <Checkbox
                      checked={
                        (isPagamentoSelected
                          ? isPagamentoSelected(indexFattura, indexP)
                          : pagamento.__selected) || false
                      }
                      onChange={(event) => {
                        const value = event.target.checked;
                        selectPagamento(indexFattura, indexP, value);
                      }}
                      size="small"
                      disabled={!_canSelectPagamento(pagamento)}
                    />
                  )}
                  {onRemovePagamento && canRemovePagamento && (
                    <Tooltip
                      title={
                        pagamentoRemoved
                          ? "Mantieni pagamento"
                          : "Rimuovi pagamento"
                      }
                    >
                      <IconButton
                        onClick={() => {
                          onRemovePagamento(
                            indexFattura,
                            indexP,
                            !pagamentoRemoved
                          );
                        }}
                      >
                        {pagamentoRemoved ? (
                          <RestoreFromTrashIcon />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              )}
              <TableCell>
                {pagamento.dataMaturazione
                  ? moment(pagamento.dataMaturazione).format("DD/MM/YYYY")
                  : ""}
              </TableCell>
              <TableCell>
                {pagamento.procedura?.nome ??
                  pagamento.mandatoCliente?.mandato?.nome}
              </TableCell>
              <TableCell>
                {pagamento.mlMilestonePagamento?.mandatoLinea?.linea?.nome}
              </TableCell>
              <TableCell>
                {pagamento.mlMilestonePagamento?.milestonePagamento?.nome?.nome}
                {pagamento.attivita?.bene ? (
                  <div>
                    <BeneLabel
                      bene={pagamento.attivita.bene}
                      showIcon
                      style={{ fontSize: "0.9em" }}
                      fontWeight="normal"
                    />
                  </div>
                ) : null}
                {numeroMarcheDaBollo && (
                  <div
                    style={{
                      position: "absolute",
                      color: "gray",
                      marginTop: 10,
                    }}
                  >
                    <em>
                      {numeroMarcheDaBollo}
                      {numeroMarcheDaBollo === 1 ? " marca" : " marche"} da
                      bollo da
                      {` ${numeral(valoreMarcaDaBollo).format("0,0.00")} €`}
                    </em>
                  </div>
                )}
              </TableCell>
              <TableCell>
                <BadgePagamentoStato stato={pagamento.stato} />
              </TableCell>
              <TableCell align="right">
                {`${numeral(pagamento.importoMaturato).format("0,0.00")} €`}
              </TableCell>
              <TableCell align="right">
                {canEditImportoEffettivo ? (
                  pagamentiEditing[pagamento.id] ? (
                    <span>
                      <NumberInput
                        fullWidth
                        variant="outlined"
                        currency
                        value={pagamento.importoEffettivo ?? null}
                        onChange={(event) => {
                          const value = event.target.value;
                          onImportoEffettivoChange?.(
                            indexFattura,
                            indexP,
                            value ?? null
                          );
                        }}
                      />
                    </span>
                  ) : (
                    <span>
                      {numeral(pagamento.importoEffettivo).format("0,0.00")}
                      {" €"}
                      <span
                        style={{
                          marginLeft: 10,
                          zoom: 0.6,
                        }}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setPagamentiEditing({
                              ...pagamentiEditing,
                              [pagamento.id]: true,
                            });
                          }}
                        >
                          Modifica
                        </Button>
                      </span>
                    </span>
                  )
                ) : (
                  `${numeral(pagamento.importoEffettivo).format("0,0.00")} €`
                )}
                {numeroMarcheDaBollo && (
                  <div
                    style={{
                      position: "absolute",
                      color: "gray",
                      marginTop: 10,
                      paddingLeft: 16,
                    }}
                  >
                    <em>
                      {`${numeral(
                        numeroMarcheDaBollo * valoreMarcaDaBollo
                      ).format("0,0.00")} €`}
                    </em>
                  </div>
                )}
              </TableCell>
              {showNotePagamento && (
                <TableCell>
                  <CampoNoteFattureEPagamenti
                    value={pagamento.note}
                    onChange={(value) =>
                      onPagamentoNoteChanged?.(indexFattura, indexP, value)
                    }
                    disabled={
                      !onPagamentoNoteChanged
                        ? true
                        : canSelectPagamento
                        ? !(isPagamentoSelected
                            ? isPagamentoSelected(indexFattura, indexP)
                            : pagamento.__selected)
                        : false
                    }
                  />
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
