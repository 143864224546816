import { useState, useEffect } from "react";
import { getTreeFromFlatData, getFlatDataFromTree } from "react-sortable-tree";
import { omit, isNil } from "lodash";

export type UseDataTreeParams = {
  source;
  sourceKey?: string;
  itemKey?: string;
  itemParentKey?: string;
  renderNode;
  defaultExpanded?: boolean;
  maxDepth?: number;
  readOnly: boolean;
  messages?: {
    noData?: string;
  };
};

export function useDataTree({
  source,
  sourceKey,
  itemKey = "id",
  itemParentKey = "parentId",
  defaultExpanded = false,
  messages = {},
  ...params
}: UseDataTreeParams) {
  const data = sourceKey ? source.getValue(sourceKey) : source.data;
  const [state, setState] = useState({
    treeData: convertFlatDataToTreeData(
      data,
      itemKey,
      itemParentKey,
      defaultExpanded
    ),
  });

  useEffect(() => {
    setState({
      ...state,
      treeData: convertFlatDataToTreeData(
        data,
        itemKey,
        itemParentKey,
        defaultExpanded
      ),
    });
  }, [data]);

  function setTreeData(newTreeData) {
    source._changeContent(
      convertTreeDataToFlatData(newTreeData, itemKey, itemParentKey)
    );
    setState({
      ...state,
      treeData: newTreeData,
    });
  }

  return {
    context: {
      ...state,
      ...params,
      source,
      setTreeData,
      messages,
    },
  } as const;
}

function convertFlatDataToTreeData(
  flatData: any[],
  itemKey: string,
  itemParentKey: string,
  defaultExpanded: boolean
) {
  return getTreeFromFlatData({
    flatData,
    rootKey: null,
    getKey: (node) => node[itemKey],
    getParentKey: (node) => node[itemParentKey],
  }).map((node) => {
    if (isNil(node.expanded)) {
      return { ...node, expanded: defaultExpanded };
    } else {
      return node;
    }
  });
}

function convertTreeDataToFlatData(
  treeData: any[],
  itemKey: string,
  itemParentKey: string
) {
  return getFlatDataFromTree({
    treeData,
    getNodeKey: (node) => node[itemKey],
    ignoreCollapsed: false,
  }).map((obj) => ({
    ...omit(obj.node, ["children"]),
    [itemParentKey]: obj.parentNode?.id || null,
  }));
}
