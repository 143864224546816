import React from "react";
import ContattoStato from "app/constants/ContattoStato";
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";
import UserPicker from "app/components/common/UserPicker";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import DateInput from "app/components/common/DateInput";
import RichTextEditor from "app/components/common/RichTextEditor";

export default function FormContatto(props) {
  const variant = props.readOnly ? "standard" : "outlined";

  return (
    <Container disableGutters={true} fixed={false} maxWidth={false}>
      <Grid container={true} item={false} spacing={3}>
        <Grid container={true} item={true} lg={12} md={12} xs={12}>
          <Grid container={false} item={true} lg={4} md={6} xs={12}>
            <div
              style={{
                display: "flex",
              }}
            >
              <FormControl
                style={{
                  flex: 1,
                  marginRight: 8,
                }}
                disabled={props.readOnly}
                error={props.source.getValidationErrors("stato").length > 0}
                margin="dense"
                size="medium"
              >
                <InputLabel
                  error={props.source.getValidationErrors("stato").length > 0}
                  variant={variant}
                >
                  Stato
                </InputLabel>
                <AsyncSelect
                  autoWidth={true}
                  displayEmpty={false}
                  label="Stato"
                  native={false}
                  onChange={(event) => {
                    const value = event.target.value;

                    props.source.changeValue(
                      "stato",

                      value || null
                    );

                    setTimeout(
                      () => {
                        props.source.runValidation("stato");
                      },

                      0
                    );
                  }}
                  onClose={(event) => {
                    props.source.runValidation("stato");
                  }}
                  value={props.source.getValue("stato") || ""}
                  variant={variant}
                  sourceVariable="props.source"
                  sourceKey="stato"
                  disabled={props.readOnly}
                >
                  {ContattoStato.map((option, index) => {
                    return (
                      <MenuItem
                        key={option.value}
                        dense={false}
                        disableGutters={false}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </AsyncSelect>
                {props.source.getValidationErrors("stato")[0] ? (
                  <FormHelperText>
                    {props.source.getValidationErrors("stato")[0]}
                  </FormHelperText>
                ) : null}{" "}
              </FormControl>
            </div>
          </Grid>

          <Grid container={false} item={true} lg={8} md={6} xs={12}>
            <UserPicker
              variant={variant}
              readOnly={props.readOnly}
              source={props.source}
              sourceKey="idCommerciale"
              label="Commerciale"
            />
          </Grid>
        </Grid>

        <Grid container={false} item={true} lg={2} md={2} xs={12}>
          <DizionarioSoftInput
            className=""
            style={{}}
            variant={variant}
            readOnly={props.readOnly}
            source={props.source}
            sourceKey="titolo"
            label="Titolo"
            fullWidth={true}
            dizionarioSoft="titoloContatto"
          />
        </Grid>

        <Grid container={false} item={true} lg={4} md={3} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("nome").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("nome")[0]}
            label="Nome"
            onBlur={(event) => {
              props.source.runValidation("nome");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "nome",

                value
              );
            }}
            type="text"
            value={props.source.getValue("nome", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>

        <Grid container={false} item={true} lg={4} md={4} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("cognome").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("cognome")[0]}
            label="Cognome"
            onBlur={(event) => {
              props.source.runValidation("cognome");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "cognome",

                value
              );
            }}
            type="text"
            value={props.source.getValue("cognome", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>

        <Grid container={false} item={true} lg={2} md={3} xs={12}>
          <DateInput
            source={props.source}
            sourceKey="dataDiNascita"
            readOnly={props.readOnly}
            fullWidth={true}
            variant={variant}
            label="Data di nascita"
          />
        </Grid>

        <Grid container={false} item={true} md={4} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("email").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("email")[0]}
            label="Email personale"
            onBlur={(event) => {
              props.source.runValidation("email");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "email",

                value
              );
            }}
            type="text"
            value={props.source.getValue("email", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>

        <Grid container={false} item={true} md={4} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("cellulare").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("cellulare")[0]}
            label="Cellulare personale"
            onBlur={(event) => {
              props.source.runValidation("cellulare");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "cellulare",

                value
              );
            }}
            type="text"
            value={props.source.getValue("cellulare", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>

        <Grid container={false} item={true} md={4} xs={12}>
          <TextField
            disabled={props.source.loading || props.source.editing}
            error={props.source.getValidationErrors("telefono").length > 0}
            fullWidth={true}
            helperText={props.source.getValidationErrors("telefono")[0]}
            label="Telefono personale"
            onBlur={(event) => {
              props.source.runValidation("telefono");
            }}
            onChange={(event) => {
              const value = event.target.value;

              props.source.changeValue(
                "telefono",

                value
              );
            }}
            type="text"
            value={props.source.getValue("telefono", "")}
            variant={variant}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>

        <Grid container={false} item={true} md={12} xs={12}>
          <RichTextEditor
            __to_reload={props.source?.getValue("note")}
            source={props.source}
            sourceKey="note"
            readOnly={props.readOnly}
            label="Note"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
