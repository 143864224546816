import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DateInput from "app/components/common/DateInput";
import {
  getAllNews as getAllNewsAction,
  editNews as editNewsAction,
  deleteNews as deleteNewsAction,
  createNews as createNewsAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import UserPicker from "app/components/common/UserPicker";
import ClienteMandatoPicker from "app/components/common/ClienteMandatoPicker";
import RichTextEditor from "app/components/common/RichTextEditor";
import { useUser } from "app/hooks/useUser";
import { Tooltip, IconButton, Dialog, Typography } from "@material-ui/core";
import { ColoredButton } from "app/elements/ColoredButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useToast } from "app/hooks/useToast";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& input": {
      color: "rgba(0, 0, 0, 0.77) !important",
    },
  },
  table: {
    minWidth: 650,
    "& .editor-with-toolbar .editor": {
      margin: 0,
    },
  },
}));

export default function NewsEditor({ clienteId, idMandato = null }) {
  const showToast = useToast();
  const classes = useStyles();

  const news = useDataSource({
    initialData: [],
    loadAction: getAllNewsAction,
    onLoadError: (response) => {
      showToast("Errore durante il caricamento delle news", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const [deletingRowSource, setDeletingRowSource] = useState(null);

  const readOnlyOrSaving = false;

  const dataToShow = news.data || [];

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "0.75em",
        }}
      >
        <ColoredButton
          style={{
            marginRight: 8,
          }}
          disabled={false}
          onClick={(event) => {
            const newNew = {
              _isActive: true,
              attiva: true,
            };
            news.create(newNew, { first: true });
          }}
          color="secondary"
          endIcon={null}
          fullWidth={false}
          size="small"
          startIcon={<AddIcon />}
          variant="outlined"
        >
          Aggiungi
        </ColoredButton>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 150 }}>Attiva</TableCell>
              <TableCell style={{ width: 300 }}>Titolo</TableCell>
              <TableCell style={{ width: 400 }}>Descrizione</TableCell>
              <TableCell style={{ width: 150 }}>Nascondibile</TableCell>
              <TableCell style={{ width: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.map((row, index) => (
              <NewsRow
                key={row.id || `index${index}`}
                readOnly={readOnlyOrSaving}
                data={row}
                source={news}
                setDeletingRowSource={setDeletingRowSource}
                classes={classes}
              />
            ))}
            {dataToShow.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    align="center"
                    variant="body1"
                    style={{ color: "gray" }}
                  >
                    <em>Nessuna news</em>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog maxWidth="md" open={deletingRowSource}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {deletingRowSource
              ? "Sei sicuro di voler eliminare questa news?"
              : null}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={(event) => {
              setDeletingRowSource(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={(event) => {
              deletingRowSource.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function NewsRow({ readOnly, data, source, setDeletingRowSource, classes }) {
  const showToast = useToast();
  // const [_reloadC, _setRC] = useState(0)
  // const reload = () => {
  //   _setRC(_reloadC + 1)
  // }

  const news = useDataSource({
    parent: source,
    pathInParent: [{ id: data.id }],
    itemId: data.id,
    initialData: {},
    createAction: createNewsAction,
    onCreateSuccess: () => {
      setDeletingRowSource(null);
      showToast("News creata con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editNewsAction,
    onEditSuccess: (response) => {
      setDeletingRowSource(null);
      showToast("News salvata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onEditError: (response) => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    deleteAction: deleteNewsAction,
    onDeleteSuccess: (response) => {
      setDeletingRowSource(null);
      showToast("News eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onDeleteError: (response) => {
      showToast("Errore durante l'eliminazione della news", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const readOnlyOrSaving = readOnly || !news.getValue("_isActive");

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={news.getValue("attiva", false)}
          onChange={(event) => {
            const value = event.target.checked;
            news.changeValue("attiva", value);
          }}
          size="small"
          value={news.getValue("attiva", false)}
          disabled={readOnlyOrSaving}
        />
      </TableCell>
      <TableCell>
        <TextField
          error={news.getValidationErrors("titolo").length > 0}
          fullWidth={true}
          helperText={news.getValidationErrors("titolo")[0]}
          label="Titolo"
          onBlur={(event) => {
            news.runValidation("titolo");
          }}
          onChange={(event) => {
            const value = event.target.value;
            news.changeValue("titolo", value);
          }}
          type="text"
          value={news.getValue("titolo", "")}
          variant={readOnlyOrSaving ? "standard" : "outlined"}
          readOnly={readOnlyOrSaving}
          disabled={readOnlyOrSaving}
        />
      </TableCell>

      <TableCell>
        <RichTextEditor
          __to_reload={news.getValue("descrizione")}
          source={news}
          sourceKey="descrizione"
          readOnly={readOnlyOrSaving}
          label={null}
        />
      </TableCell>

      <TableCell>
        <Checkbox
          checked={news.getValue("canMarkAsRead", false)}
          onChange={(event) => {
            const value = event.target.checked;
            news.changeValue("canMarkAsRead", value);
          }}
          size="small"
          value={news.getValue("canMarkAsRead", false)}
          disabled={readOnlyOrSaving}
        />
      </TableCell>

      <TableCell>
        <div
          style={{
            maxWidth: 200,
            display: "flex",
            justifyContent: "end",
          }}
        >
          {news.getValue("_isActive") ? (
            <>
              <Tooltip title="Salva le modifiche">
                <IconButton
                  onClick={(event) => {
                    news.commit();
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Annulla le modifiche">
                <IconButton
                  onClick={(event) => {
                    if (news.getValue("__isNew")) {
                      source.delete({
                        tempId: data.id,
                      });
                    } else {
                      news.cancel();
                      // reload()
                    }
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Modifica">
                <IconButton
                  onClick={(event) => {
                    news.changeValue("_isActive", true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Elimina">
                <IconButton
                  onClick={(event) => {
                    setDeletingRowSource(news);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
