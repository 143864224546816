import { TextColumnFilter } from './types'

export function applyTextFilter (row, value: string, options: TextColumnFilter) {
  if (!options.search && !options.emptySelected) {
    return true
  }

  if (typeof value === 'string' && value !== '') {
    return stringMatch(value, options.search)
  } else if (!value) {
    return options.emptySelected
  }
  return false
}

function stringMatch (string, lowerCaseValue) {
  return string.toLowerCase().indexOf(lowerCaseValue) !== -1
}
