import * as trellosmoothdnd from "trello-smooth-dnd";
import { Container } from "react-trello";

function fixReactTrello() {
  const { constants } = trellosmoothdnd as any;
  const { containerInstance } = constants;

  function fixContainer(_this) {
    // const _container = _this.container;
    const containerDiv = _this.containerDiv;
    const container = containerDiv[containerInstance];
    // console.log("container", container);
    if (container) {
      const layout = container.layout;
      //   console.log("layout", layout);

      if (layout.__creaFixed) {
        return;
      }

      const { getAxisValue, getContainerRectangles } = layout;

      const isInVisibleRect = (x, y) => {
        const { rect, visibleRect } = getContainerRectangles();
        let { left, top, right, bottom } = visibleRect;
        const orientation = _this.props.orientation;

        // if there is no wrapper in rect size will be 0 and wont accept any drop
        // so make sure at least there is 30px difference
        if (bottom - top < 2) {
          bottom = top + 30;
        }
        const containerRect = rect;
        if (orientation === "vertical") {
          return (
            x > containerRect.left && x < containerRect.right
            // &&
            // y > top &&
            // y < bottom
          );
        } else {
          return (
            x > left &&
            x < right &&
            y > containerRect.top &&
            y < containerRect.bottom
          );
        }
      };

      const getPosition = (position) => {
        return isInVisibleRect(position.x, position.y)
          ? getAxisValue(position)
          : null;
      };

      layout.isInVisibleRect = isInVisibleRect;
      layout.getPosition = getPosition;
      layout.__creaFixed = true;
    }
  }

  const __componentDidMount = Container.prototype.componentDidMount;
  Container.prototype.componentDidMount = function () {
    __componentDidMount.call(this);
    fixContainer(this);
  };

  const __componentDidUpdate = Container.prototype.componentDidUpdate;
  Container.prototype.componentDidUpdate = function () {
    __componentDidUpdate.call(this);
    fixContainer(this);
  };
}

fixReactTrello();
