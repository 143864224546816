import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getMandatiOnlyAttivi as getMandatiOnlyAttiviAction } from "app/actions";
import MandatiTable_abstract from "app/components/MandatiTable_abstract";
import useCreaconsultingTitle from "../hooks/useCreaconsultingTitle";

export default function MandatiTable(props) {
  const mandati = useDataSource({
    loadAction: getMandatiOnlyAttiviAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  useCreaconsultingTitle(`Mandati`);

  return (
    <MandatiTable_abstract
      isOpportunita={false}
      mandati={mandati}
      title="Mandati"
      exportFilename="Mandati"
    />
  );
}
