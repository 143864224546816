import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  filterRowsByColumn,
  filterRowsByGlobalFilter,
} from "../elements/DataTable/filters";
import { sortRows } from "../elements/DataTable/sortBy";
import type { TableColumnArray } from "../elements/DataTable/types";
import { DataTableContextType } from "../contexts/DataTableContext";
import {
  DataSourceCreateOptions,
  serializePath,
} from "./DataSource/DataSource";
import { find, flatMap } from "lodash";
import { useStateWithSaveCurrentState } from "./useStateWithSaveCurrentState";
import { ColumnFilter } from "app/elements/DataTable/filters/types";
import { QueryFilters, Sorting } from "server/utils/tableUtils/requestFilter";

const noData = [];

export type UseTableParams = {
  server?: boolean;
  columns: TableColumnArray;
  showFilterRow?: boolean;
  pageSize?: number;
  size: "small" | "medium";
  footerRows?: number;

  source;
  sourceKey?;
  filterRow?: (rowIndex: number, row: any) => boolean;

  rowSourceOptions?: DataSourceCreateOptions;

  expandableRows?: boolean;
  expandRowsOnClick?: boolean;
  isRowExpandable?: (rowIndex: number, row: any, source: any) => boolean;
  renderExpandedRow?: (rowIndex: number, row: any, source: any) => any;
  rowsDefaultExpanded?: boolean;

  messages: DataTableMessages;

  initialSortBy?: string[];

  saveCurrentState?: string | null | undefined;
};

export type DataTableMessages = {
  noRows?: string;
  search?: string;
  filters?: {};
  rowsPerPage?: string;
  textSearch?: string;
};

type TableStateType = {
  globalFilter: string;
  columnFilters: any;
  sortBy: string[];
  page: number;
} & UseTableParams;

// TODO: i parametri iniziano a essere molti. Passiamo direttamente tutte le prop di DataTable?
export function useTable({
  source,
  sourceKey,
  columns: _columns,
  pageSize = 100,
  footerRows,
  isRowExpandable = () => true,
  messages = {},
  rowSourceOptions = {},
  initialSortBy = [],
  saveCurrentState,
  filterRow,
  server,
  ...params
}: UseTableParams) {
  // columns may contain other arrays generated by .map. This allows for convenient definition of dynamic columns
  const columns = flatMap(_columns).filter((column) => !!column);

  const [tableState, setTableState] =
    useStateWithSaveCurrentState<TableStateType>(
      // @ts-ignore:
      {
        server: false, // not used...
        globalFilter: "",
        columnFilters: {},
        sortBy: initialSortBy,
        page: 0,
        pageSize,
        isRowExpandable,
        columns,
        source,
        messages,
        initialSortBy,
        ...params,
      },
      saveCurrentState,
      ["globalFilter", "columnFilters", "sortBy", "page", "pageSize"]
    );
  const allData =
    (source && (sourceKey ? source.getValue(sourceKey, []) : source.data)) ||
    noData;

  const prevQuery = useRef<string | null>(null);

  useEffect(() => {
    if (server) {
      const query: QueryFilters = {
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize || 100,
        },
      };

      function getPathString(p: any) {
        if (Array.isArray(p)) {
          return p.join(".");
        }
        return p;
      }

      if (tableState.globalFilter) {
        query.globalSearch = {
          value: tableState.globalFilter,
          textCols: tableState.columns.map((c) => getPathString(c.path)),
        };
      }

      if (tableState.columnFilters) {
        Object.entries(tableState.columnFilters).forEach(
          ([colIndex, _filter]) => {
            const col = tableState.columns[parseInt(colIndex, 10)];
            if (_filter && col) {
              const colPath = getPathString(col.path);
              const filter = _filter as ColumnFilter;
              switch (filter.type) {
                case "boolean":
                  if (!!filter.trueSelected !== !!filter.falseSelected) {
                    query.filters = query.filters || {};
                    query.filters[colPath] = {
                      op: filter.isNot ? "eq" : "ne",
                      value: !!filter.trueSelected,
                    };
                  }
                  break;
                case "text":
                  query.filters = query.filters || {};
                  query.filters[colPath] = {
                    op: filter.isNot ? "notILike" : "iLike",
                    value: filter.search,
                  };
                  break;
                case "range":
                  query.filters = query.filters || {};
                  if (
                    filter.min !== null &&
                    filter.max !== null &&
                    filter.min !== -Infinity &&
                    filter.max !== Infinity
                  ) {
                    query.filters[colPath] = {
                      op: filter.isNot ? "notBetween" : "between",
                      value: [filter.min, filter.max],
                    };
                  } else if (filter.min !== null && filter.min !== -Infinity) {
                    query.filters[colPath] = {
                      op: filter.isNot ? "lte" : "gte",
                      value: filter.min,
                    };
                  } else if (filter.max !== null && filter.max !== Infinity) {
                    query.filters[colPath] = {
                      op: filter.isNot ? "gte" : "lte",
                      value: filter.max,
                    };
                  }
                  break;
                case "datetime-range":
                  query.filters = query.filters || {};
                  query.filters[colPath] = {
                    op: filter.isNot ? "notBetween" : "between",
                    value: [filter.min, filter.max],
                  };
                  break;
                case "enum":
                  query.filters = query.filters || {};
                  query.filters[colPath] = {
                    op: filter.isNot ? "notIn" : "in",
                    value: filter.values,
                  };
                  break;
              }
            }
          }
        );
      }

      if (tableState.sortBy) {
        const sortBy: Sorting[] = tableState.sortBy
          .map((sortBy): Sorting | null => {
            const [serializedPath, dir] = sortBy;
            const column = find(
              tableState.columns,
              (c) => serializePath(c.path) === serializedPath
            );
            if (!column) {
              console.warn(
                `useTable sort error: column "${serializedPath}" not found`
              );
              return null;
            }
            return { column: column.path.join("."), order: dir } as Sorting;
          })
          .filter((x) => x) as Sorting[];

        if (sortBy.length > 0) {
          query.sort = sortBy;
        }
      }

      const queryString = JSON.stringify(query);
      if (prevQuery.current !== queryString) {
        console.log("*****LOAD API", tableState, "\nQUERY:", queryString);
        prevQuery.current = queryString;

        source.load({ query: "?q=" + encodeURIComponent(queryString) });
      }
    }
  }, [server, tableState]);

  const { filteredRowsCount, rows, filteredRows } = usePrepareRows(
    server,
    source?.loadResponse?.count || 0,
    allData,
    columns,
    // @ts-ignore:
    tableState,
    source,
    filterRow
  );

  // keep params in 'sync', needed for nested tables (where a table is rendered inside a renderExpandedRow)
  useEffect(() => {
    // @ts-ignore:
    setTableState({
      ...tableState,
      ...params,
    });
  }, [...Object.values(params)]);

  const setGlobalFilter = useCallback(
    (text) => {
      // @ts-ignore:
      setTableState({
        ...tableState,
        globalFilter: text || "",
        page: 0,
      });
    },
    [setTableState, tableState]
  );

  const setColumnFilters = useCallback(
    (newColumnFilters) => {
      // @ts-ignore:
      setTableState({
        ...tableState,
        columnFilters: newColumnFilters || {},
        page: 0,
      });
    },
    [setTableState, tableState]
  );

  const setSortBy = useCallback(
    (newSortBy) => {
      // @ts-ignore:
      setTableState({
        ...tableState,
        sortBy: newSortBy || [],
        page: 0,
      });
    },
    [setTableState, tableState]
  );

  const setPage = useCallback(
    (newPage) => {
      // @ts-ignore:
      setTableState({
        ...tableState,
        page: newPage,
      });
    },
    [setTableState, tableState]
  );

  const setPageSize = useCallback(
    (newPageSize) => {
      // @ts-ignore:
      setTableState({
        ...tableState,
        pageSize: newPageSize,
        page: 0,
      });
    },
    [setTableState, tableState]
  );

  // TODO: altre cose:
  // - hide/unhide column
  // - filter singola colonna https://react-table.tanstack.com/docs/api/useFilters
  // - column width https://react-table.tanstack.com/docs/api/useResizeColumns
  // - column order https://react-table.tanstack.com/docs/api/useColumnOrder
  // - caso pagination/sort/filters/globalFilter fatto in server...

  // https://react-table.tanstack.com/docs/api/useTable

  const context: DataTableContextType = {
    ...(tableState as any),
    filteredRowsCount,
    // @ts-ignore:
    filteredRows,
    allRows: allData,
    rows,
    source,
    sourceKey,
    footerRows,
    rowSourceOptions,

    columns, // this needs to update at every render, since a renderCell may be using some hooks
    // @ts-ignore:
    totalColumns: columns.length + (tableState.expandableRows ? 1 : 0), // TODO: add a column if we show the checkbox

    setGlobalFilter,
    setColumnFilters,
    setSortBy,
    setPage,
    setPageSize,

    messages,
  };

  return {
    context,
  } as const;
}

function usePrepareRows(
  server: boolean | undefined,
  serverCount: any,
  allData: any[],
  columns,
  { globalFilter, sortBy, columnFilters, page, pageSize },
  source,
  filterRow
) {
  let rows = allData;
  rows = useMemo(() => {
    if (server || !filterRow) {
      return rows;
    }
    return rows.filter((r, index) => filterRow(index, r));
  }, [rows, filterRow]);

  rows = useMemo(() => {
    if (server || !globalFilter) {
      return rows;
    }
    return filterRowsByGlobalFilter(rows, columns, globalFilter, source);
  }, [rows, columns, globalFilter]);

  rows = useMemo(() => {
    if (server || !columnFilters) {
      return rows;
    }
    return filterRowsByColumn(rows, columns, columnFilters, source);
  }, [rows, columns, columnFilters]);
  const filteredRowsCount = server ? serverCount : rows.length;

  rows = useMemo(() => {
    if (server || sortBy.length === 0) {
      return rows;
    }
    return sortRows(rows, columns, sortBy, source);
  }, [rows, columns, sortBy]);
  const filteredRows = rows;

  rows = useMemo(() => {
    if (server) {
      return rows;
    }
    return rows.slice(page * pageSize, (page + 1) * pageSize);
  }, [rows, page, pageSize]);

  return { filteredRowsCount, rows, filteredRows } as const;
}
