import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getPagamenti as getPagamentiAction } from "app/actions";
import PagamentiTable_abstract from "./PagamentiTable_abstract";

export default function PagamentiTable(props) {
  const pagamenti = useDataSource({
    loadAction: getPagamentiAction,
    initialData: [],
  });

  return (
    <PagamentiTable_abstract
      title="Pagamenti"
      pagamenti={pagamenti}
      exportFilename="Pagamenti"
    />
  );
}
