import { useMemo } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  lighten,
} from "@material-ui/core";
import {
  getAnalisiCommerciale,
  getDashboardCommerciale_getCardData,
} from "app/actions";
import {
  PivotRowGetter,
  PivotTable,
  PivotTableColumn,
} from "../PivotTable/PivotTable";
import { each, sum, uniq } from "lodash";
import { useLoadApi } from "app/hooks/useLoadApi";
import moment from "moment";
import UserFilterSelector from "../common/UserFilterSelector";
import AnnoPicker from "../common/AnnoPicker";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";
import numeral from "numeral";
import "./DashboardCommerciale.scss";
import { AnalisiCommercialeSmallTable } from "./AnalisiCommercialeSmallTable";

function safeDiv(n, d) {
  if (!d) {
    return "-";
  }
  return n / d;
}

export function DashboardCommerciale(props) {
  const hasPermission = useCheckPermission();

  const canSeeAll = hasPermission("visualizza_dashboard_statistiche_tutti");

  const now = useMemo(() => moment().year(), []);
  const nowMonth = useMemo(() => moment().month() + 1, []);

  // save for history change!
  const [anno, setAnno] = useStateWithSaveCurrentState(
    moment().year(),
    "dashboard_commerciale_anno",
    "all_object"
  );

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    null,
    "dashboard_commerciale_user",
    "all_object"
  );

  const [ambitoFilter, setAmbitoFilter] = useStateWithSaveCurrentState(
    null,
    "dashboard_commerciale_ambito",
    "all_object"
  );

  const api = useLoadApi(getDashboardCommerciale_getCardData, {
    anno,
    idCommerciale: userFilter,
  });

  const { analisiCommerciale, ambiti, budget } = useMemo(() => {
    let { analisiCommerciale, ambiti, budget } = (api.data || {}) as any;

    if (analisiCommerciale && ambitoFilter) {
      analisiCommerciale = analisiCommerciale.filter((x) => {
        return x.ambitoDiApplicazione === ambitoFilter;
      });
    }
    if (budget && ambitoFilter) {
      budget = budget.filter((x) => {
        return x.ambito === ambitoFilter;
      });
    }

    return { analisiCommerciale, ambiti, budget };
  }, [api.data, ambitoFilter, anno]);

  if (!hasPermission(["visualizza_dashboard_statistiche"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          marginBottom: 10,
        }}
      >
        <h1
          className="page-title-header"
          style={{
            marginTop: -20,
          }}
        >
          Dashboard Commerciale
        </h1>{" "}
        <div style={{ marginLeft: "auto" }}>
          <AmbitoSelect
            value={ambitoFilter}
            onChange={setAmbitoFilter}
            valori={ambiti}
          />
          <UserFilterSelector
            returnValue="id"
            defaultSelection="all"
            label="Operatore"
            onChange={setUserFilter}
            value={userFilter}
            canAllEAltriUtenti={canSeeAll}
            userRole="Commerciale"
          />
          <AnnoPicker anno={anno} setAnno={setAnno} />
        </div>
      </div>

      {api.loading ? (
        "Caricamento dati..."
      ) : api.error ? (
        "Errore durante il caricamento dei dati"
      ) : (
        <DashboardCommercialeInner
          analisiCommerciale={analisiCommerciale}
          anno={anno}
          budget={budget}
        />
      )}
    </Container>
  );
}

function DashboardCommercialeInner({ analisiCommerciale, anno, budget }) {
  const lightenValue = 0.7;
  const lightenValueSuccessOrFail = 0.2;

  const now = useMemo(() => moment().year(), []);
  const nowMonth = useMemo(() => moment().month() + 1, []);

  const nMonth = useMemo(() => {
    let nMonth = 12;
    if (anno === now) {
      nMonth = nowMonth;
    }
    return nMonth;
  }, [anno]);

  const r = useMemo(() => {
    const r = {
      importoContratti: 0,
      nContratti: 0,
      importoOfferte: 0,
      nOfferte: 0,

      importoContratti1: 0,
      nContratti1: 0,
      nOfferte1: 0,
      importoContrattiRiattivazioni: 0,
      nContrattiRiattivazioni: 0,
      nOfferteRiattivazioni: 0,
      importoContrattiGiaClienti: 0,
      nContrattiGiaClienti: 0,
      nOfferteGiaClienti: 0,
    };

    analisiCommerciale.forEach((x) => {
      const {
        statoMandato,
        count,
        importo,
        statoClienteAllaFirma,
        statoClienteAllaCreazioneOfferta,
      } = x;

      if (statoMandato === "contratto") {
        r.nContratti += count;
        r.importoContratti += importo;

        if (statoClienteAllaCreazioneOfferta === "nuovo") {
          r.importoContratti1 += importo;
          r.nContratti1 += count;
        } else if (statoClienteAllaCreazioneOfferta === "inattivo") {
          r.importoContrattiRiattivazioni += importo;
          r.nContrattiRiattivazioni += count;
        } else if (statoClienteAllaCreazioneOfferta === "attivo") {
          r.importoContrattiGiaClienti += importo;
          r.nContrattiGiaClienti += count;
        }
      } else if (statoMandato === "offerta") {
        r.nOfferte += count;
        r.importoOfferte += importo;
        if (statoClienteAllaCreazioneOfferta === "nuovo") {
          r.nOfferte1 += count;
        } else if (statoClienteAllaCreazioneOfferta === "inattivo") {
          r.nOfferteRiattivazioni += count;
        } else if (statoClienteAllaCreazioneOfferta === "attivo") {
          r.nOfferteGiaClienti += count;
        }
      }
    });

    return r;
  }, [analisiCommerciale]);

  const valoreMedioContratto = safeDiv(r.importoContratti, r.nContratti);
  const chiusuraMediaMensile = safeDiv(r.importoContratti, nMonth);

  const percContratti1Chiusi = safeDiv(
    r.nContratti1,
    r.nContratti1 + r.nOfferte1
  );
  const percContrattiRiattivazioniChiusi = safeDiv(
    r.nContrattiRiattivazioni,
    r.nContrattiRiattivazioni + r.nOfferteRiattivazioni
  );
  const percContrattiGiaClientiChiusi = safeDiv(
    r.nContrattiGiaClienti,
    r.nContrattiGiaClienti + r.nOfferteGiaClienti
  );

  const r2 = useMemo(() => {
    const meseCondition = anno === now ? nowMonth : 12;
    const meseString = moment().localeData().months()[meseCondition - 1];

    const r2 = {
      numeroContrattiBudget: 0,
      importoTotaleContrattiBudget: 0,
      numeroContrattiBudgetNow: 0,
      importoTotaleContrattiBudgetNow: 0,
      meseString,
    };

    (budget || []).forEach((x) => {
      each(x.mesi, (x2, mString) => {
        const m = parseInt(mString);
        const { importoTotaleContrattiBudget, numeroContrattiBudget } = x2;

        r2.importoTotaleContrattiBudget += importoTotaleContrattiBudget || 0;
        r2.numeroContrattiBudget += numeroContrattiBudget || 0;

        if (m <= meseCondition) {
          r2.importoTotaleContrattiBudgetNow +=
            importoTotaleContrattiBudget || 0;
          r2.numeroContrattiBudgetNow += numeroContrattiBudget || 0;
        }
      });
    });

    return r2;
  }, [budget]);

  const budgetReached =
    r.importoContratti >= r2.importoTotaleContrattiBudgetNow;

  return (
    <Grid className="DashboardCommerciale" container spacing={3}>
      <Grid item sm={12} md={6}>
        <SimpleNumberCard
          title="Valore medio contratto"
          value={valoreMedioContratto}
          suffix="€"
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <SimpleNumberCard
          title="Chiusura media mensile"
          value={chiusuraMediaMensile}
          suffix="€"
        />
      </Grid>

      {/* space */}
      <Grid item sm={12} md={12} style={{ height: 20 }} />

      <Grid item sm={12} md={4}>
        <TwoNumberCard
          title="Contratti 1°"
          value={r.importoContratti1}
          suffix="€"
          value2={r.nContratti1}
          suffix2="contratti"
          extra={
            <PercentExtraWithFootnote
              footnoteNumber={1}
              perc={safeDiv(r.importoContratti1, r.importoContratti)}
            />
          }
          extra2={
            <PercentExtraWithFootnote
              footnoteNumber={2}
              perc={percContratti1Chiusi}
            />
          }
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <TwoNumberCard
          title="Contratti Riattivazione"
          value={r.importoContrattiRiattivazioni}
          suffix="€"
          value2={r.nContrattiRiattivazioni}
          suffix2="contratti"
          extra={
            <PercentExtraWithFootnote
              footnoteNumber={1}
              perc={safeDiv(
                r.importoContrattiRiattivazioni,
                r.importoContratti
              )}
            />
          }
          extra2={
            <PercentExtraWithFootnote
              footnoteNumber={2}
              perc={percContrattiRiattivazioniChiusi}
            />
          }
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <TwoNumberCard
          title="Contratti Già Clienti"
          value={r.importoContrattiGiaClienti}
          suffix="€"
          value2={r.nContrattiGiaClienti}
          suffix2="contratti"
          extra={
            <PercentExtraWithFootnote
              footnoteNumber={1}
              perc={safeDiv(r.importoContrattiGiaClienti, r.importoContratti)}
            />
          }
          extra2={
            <PercentExtraWithFootnote
              footnoteNumber={2}
              perc={percContrattiGiaClientiChiusi}
            />
          }
        />
      </Grid>
      <Grid item sm={12} md={12} className="footnote-helper">
        <p>
          <i>
            <sup>1</sup> % di tutti i contratti chiusi
          </i>
        </p>
        <p>
          <i>
            <sup>2</sup> % delle offerte chiuse
          </i>
        </p>
      </Grid>

      {/* space */}
      <Grid item sm={12} md={12} style={{ height: 20 }} />

      <Grid item sm={12} md={4}>
        <TwoNumberCard
          title="TOTALE"
          value={r.importoContratti}
          suffix="€"
          value2={r.nContratti}
          suffix2="contratti"
          color={lighten("#ff9800", lightenValue)}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <TwoNumberCard
          title="BUDGET (totale)"
          value={r2.importoTotaleContrattiBudget}
          suffix="€"
          value2={r2.numeroContrattiBudget}
          suffix2="contratti"
          color={lighten("#3890F5", lightenValue)}
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <TwoNumberCard
          title={`BUDGET (${r2.meseString} ${anno})`}
          value={r2.importoTotaleContrattiBudgetNow}
          suffix="€"
          value2={r2.numeroContrattiBudgetNow}
          suffix2="contratti"
          color={lighten(
            budgetReached ? "#4caf50" : "#e57373",
            lightenValueSuccessOrFail
          )}
        />
      </Grid>

      {/* space */}
      <Grid item sm={12} md={12} style={{ height: 20 }} />

      {/*  Tabelle */}
      {analisiCommerciale && (
        <>
          <Grid
            className="DashboardCommerciale-analisiCommerciale-table"
            item
            sm={12}
            md={12}
            xl={4}
          >
            <AnalisiCommercialeSmallTable
              anno={anno}
              data={analisiCommerciale}
              groupBy="trimestre"
              nMonth={nMonth}
            />
          </Grid>
          <Grid
            className="DashboardCommerciale-analisiCommerciale-table"
            item
            sm={12}
            lg={6}
            xl={4}
          >
            <AnalisiCommercialeSmallTable
              anno={anno}
              data={analisiCommerciale}
              groupBy="provincia"
              nMonth={nMonth}
            />
          </Grid>
          <Grid
            className="DashboardCommerciale-analisiCommerciale-table"
            item
            sm={12}
            lg={6}
            xl={4}
          >
            <AnalisiCommercialeSmallTable
              anno={anno}
              data={analisiCommerciale}
              groupBy="dimensioneAzienda"
              nMonth={nMonth}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

function AmbitoSelect({ value, onChange, valori }) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    let v;
    if (newValue === "###ALL") {
      v = null;
    } else {
      v = newValue;
    }
    onChange(v);
  };

  return (
    <FormControl
      variant="standard"
      size="small"
      style={{ marginLeft: 8, marginRight: 8, marginTop: 0, marginBottom: 0 }}
    >
      <InputLabel style={{ position: "absolute", marginTop: -11 }}>
        Ambito
      </InputLabel>

      <Select
        value={value || "###ALL"}
        onChange={handleChange}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <MenuItem value="###ALL">
          <em>Tutti</em>
        </MenuItem>
        {valori ? <hr /> : null}

        {valori
          ? valori.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
}

function SimpleNumberCard({ title, value, suffix = "" }) {
  if (suffix) {
    suffix = " " + suffix;
  }

  const valueString =
    typeof value === "number"
      ? numeral(value).format("0,0") + suffix
      : value || "";

  return (
    <Card className="DashboardCommerciale-card DashboardCommerciale-SimpleNumberCard">
      <CardContent>
        <Typography className="title" color="textSecondary">
          {title}
        </Typography>
        <Typography className="value" variant="h4" component="h2">
          {valueString}
        </Typography>
      </CardContent>
    </Card>
  );
}

function TwoNumberCard({
  title,
  value,
  suffix = "",
  value2,
  suffix2 = "",
  extra = null as any,
  extra2 = null as any,
  color = null as any,
}) {
  if (suffix2 === "contratti" && value2 === 1) {
    suffix2 = "contratto";
  }

  if (suffix) {
    suffix = " " + suffix;
  }
  if (suffix2) {
    suffix2 = " " + suffix2;
  }

  const valueString =
    typeof value === "number" ? numeral(value).format("0,0") + suffix : "";
  const value2String =
    typeof value2 === "number" ? numeral(value2).format("0,0") + suffix2 : "";

  return (
    <Card
      className="DashboardCommerciale-card DashboardCommerciale-TwoNumberCard"
      style={{
        backgroundColor: color || undefined,
      }}
    >
      <CardContent>
        <Typography className="title" color="textSecondary">
          {title}
        </Typography>
        <div className="value-container">
          <Typography className="value" variant="h4" component="h2">
            {valueString}
          </Typography>
          {extra && <div className="extra-container">{extra}</div>}
        </div>
        <div className="value-container">
          <Typography className="value2" variant="h5" component="h2">
            {value2String}
          </Typography>
          {extra2 && <div className="extra-container">{extra2}</div>}
        </div>
      </CardContent>
    </Card>
  );
}

function PercentExtraWithFootnote({
  perc,
  footnoteNumber,
  useParentesis = true,
}) {
  let percString =
    typeof perc === "number" ? (perc * 100).toFixed(0) + " %" : perc || "";

  if (useParentesis) {
    percString = `(${percString})`;
  }

  return (
    <div className="DashboardCommerciale-PercentExtraWithFootnote">
      {percString}
      {footnoteNumber && <sup>{footnoteNumber}</sup>}
    </div>
  );
}
