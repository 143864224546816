import React from "react";
import ProceduraStato from "app/constants/ProceduraStato";
import { Typography } from "@material-ui/core";

export default function BadgeProceduraStato(props) {
  const color =
    ProceduraStato.find((stato) => stato.value === props.stato)?.color ?? null;

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Typography
        style={{
          background: color,
          border: "1px solid transparent",
          borderRadius: 4,
          padding: "3px 5px",
          margin: "8px 8px",
        }}
        align="inherit"
        color="inherit"
        display="inline"
        gutterBottom={true}
        noWrap={false}
        paragraph={false}
        variant="caption"
      >
        {ProceduraStato.find((stato) => stato.value === props.stato)?.label ??
          props.stato}{" "}
      </Typography>
    </div>
  );
}
