import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getLinea as getLineaAction } from "app/actions";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";

export default function MilestoneDiPagamentoSelect(props) {
  const {
    readOnly,
    idLinea,
    source,
    sourceKey,
    milestonePagamenti /* source! */,
  } = props;

  const linea = useDataSource({
    loadAction: getLineaAction,
    itemId: idLinea,
    autoLoad: !!idLinea,

    initialData: {},
  });

  let milestonePagamentiData;

  if (linea.data && linea.data.milestonePagamenti) {
    milestonePagamentiData = linea.data.milestonePagamenti;
  } else {
    milestonePagamentiData = milestonePagamenti.data;
  }

  return (
    <FormControl
      disabled={readOnly}
      error={source.getValidationErrors(sourceKey).length > 0}
      fullWidth={true}
      size="small"
    >
      <InputLabel
        error={source.getValidationErrors(sourceKey).length > 0}
        variant="outlined"
      >
        Milestone di pagamento
      </InputLabel>
      <AsyncSelect
        label="Milestone di pagamento"
        onChange={(event) => {
          const value = event.target.value;

          source.changeValue(
            sourceKey,

            value || null
          );

          setTimeout(
            () => {
              source.runValidation(sourceKey);
            },

            0
          );
        }}
        onClose={() => {
          source.runValidation(sourceKey);
        }}
        value={source.getValue(sourceKey) || ""}
        variant="outlined"
        disabled={readOnly}
      >
        <MenuItem value="">
          <em>Nessuna milestone di pagamento</em>
        </MenuItem>
        {(milestonePagamentiData || [])
          .map((milestone) => {
            return milestone.allaFirma
              ? null
              : { value: milestone.id, label: getNomeMilestone(milestone) };
          })
          .filter((x) => x)
          .map((option) => {
            return (
              <MenuItem
                key={option.value}
                dense={false}
                disableGutters={false}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            );
          })}
      </AsyncSelect>
      {source.getValidationErrors(sourceKey)[0] ? (
        <FormHelperText>
          {source.getValidationErrors(sourceKey)[0]}
        </FormHelperText>
      ) : null}{" "}
    </FormControl>
  );
}

function getNomeMilestone(milestone) {
  let nome = milestone.nome.nome;

  if (milestone.variabile && milestone.variabile.agevolazione) {
    const varNome = milestone.variabile.nome.nome;
    const agNome = milestone.variabile.agevolazione.nome.nome;
    nome = `${nome} (${agNome} - ${varNome})`;
  }

  return nome;
}
