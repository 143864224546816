export default function proceduraHasVersioneSuccessiva(p) {
  if (!p) {
    return false;
  }
  const versioneSuccessiva = p.formatProcedura?.versioneSuccessiva;
  if (versioneSuccessiva) {
    return versioneSuccessiva.stato === "attivo";
  }
  return false;
}
