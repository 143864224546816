import React, { useEffect, useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import {
  getAttivitaForKanbanAll as getAttivitaForKanbanAllAction,
  getAttivitaForKanbanMe as getAttivitaForKanbanMeAction,
  getAttivitaForKanbanUser as getAttivitaForKanbanUserAction,
  getAvailabilityUtenti as getAvailabilityUtentiAction,
  saveAvailabilityUtente as saveAvailabilityUtenteAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  Avatar,
  Container,
  Drawer,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CaricoBarChart from "./CaricoBarChart";
import CaricoUserSection from "./CaricoUserSection";
import CaricoPerScadenzaSection from "./CaricoPerScadenzaSection";
import {
  createBoard,
  createBoardWithColumns,
  NO_AGGR_KEY_COLUMN_ID,
} from "../Kanban/Kanban";
import {
  AttivitaKanbanInternal,
  weeklyAggregation,
} from "../Attivita/AttivitaKanban";
import { keyBy, orderBy } from "lodash";
import useSideModal from "app/components/common/useSideModal";
import { makeStyles } from "@material-ui/core/styles";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import moment from "moment";
import { useApi } from "app/hooks/useApi";
import {
  getPriorityIndexForAttivita,
  priorityDescriptions,
  priorityLabels,
} from "../Attivita/AttivitaKanbanCard";
import { useUser } from "app/hooks/useUser";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import clsx from "clsx";
import { userHasRole } from "../../../common/roles";

const showTempRules = false;

const leftColumnWidth = 200;
const weekColumnWidth = 290;
const rightColumnWidth = 100;

const kanbanBoardStyle = {
  paddingLeft: leftColumnWidth, // + 5,
  paddingRight: rightColumnWidth, // + 5,
};

const useStyles = makeStyles({
  kanbanContainer: {
    "& .react-kanban-board": kanbanBoardStyle,
    "& .react-trello-board": kanbanBoardStyle,
  },

  drawer_container: {
    "& .react-kanban-board": kanbanBoardStyle,
    "& .react-trello-board": kanbanBoardStyle,
  },
  drawer: {
    width: 0,
    marginLeft: 120,
  },
  backdrop: {
    display: "none",
  },
  drawerToolbar: {
    background: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
  },
});

export function getTipoDaAttivita(attivita) {
  const linea = attivita.procedura?.formatProcedura?.linea;
  if (attivita.formatAttivita) {
    const { formatAttivita } = attivita;
    return {
      key: "formatAttivita-" + formatAttivita.id,
      isFormatAttivita: true,
      nome: formatAttivita.nome,
      nomeLegenda: linea
        ? `${linea.nome} - ${formatAttivita.nome}`
        : formatAttivita.nome,
      description: linea?.nome || "",
    };
  } else {
    return {
      key: "attivita-" + attivita.id,
      isAttivita: true,
      nome: attivita.nome,
      nomeLegenda: linea ? `${linea.nome} - ${attivita.nome}` : attivita.nome,
      description: attivita.procedura
        ? " (" + attivita.procedura.nome + ")"
        : "",
    };
  }
}

export default function CaricoDiLavoro() {
  const classes = useStyles();
  const showToast = useToast();
  const me = useUser();
  const hasPermission = useCheckPermission();

  // const [isAll, showAllUsers] = useState(true);
  const [userFilter, setUserFilter] = useState(null);
  const selectUser = (userId) => setUserFilter(userId);

  const attivitaSource = useDataSource({
    initialData: [],
    loadAction: () => {
      if (!userFilter) {
        return getAttivitaForKanbanAllAction();
      } else if (me.id === userFilter) {
        return getAttivitaForKanbanMeAction();
      } else {
        return getAttivitaForKanbanUserAction(userFilter);
      }
    },
  });

  const [{ firstLoad, loading, loadError }, setAttivita] = useState({
    firstLoad: true,
    // attivita: null,
    loading: true,
    loadError: null,
  });

  useEffect(() => {
    if (!firstLoad) {
      setAttivita({
        // attivita: null,
        loading: true,
        loadError: null,
      });
      attivitaSource.load();
    }
  }, [userFilter]);

  const allAttivita = attivitaSource.data;

  useEffect(() => {
    if (!attivitaSource.loading && loading) {
      setAttivita({
        // attivita: null,
        loading: false,
        loadError: attivitaSource.loadError,
      });
    }
  }, [attivitaSource.loading, attivitaSource.loadError]);

  const [boardWithAllAttivita, attivita] = useMemo(() => {
    const boardWithAllAttivita = createBoard(
      allAttivita,
      weeklyAggregation.getColumnTitle,
      weeklyAggregation.getCardAggrKey,
      weeklyAggregation.sortCardsBy,
      false,
      false,
      false,
      moment().format("YYYY-MM-DD"),
      moment().add(10, "d").format("YYYY-MM-DD"),
      (x) => x,
      true
    );

    boardWithAllAttivita.columns = boardWithAllAttivita.columns.filter(
      (column) => column.id !== NO_AGGR_KEY_COLUMN_ID
    );

    const minK = boardWithAllAttivita.columns.length
      ? boardWithAllAttivita.columns[0].id
      : undefined;
    const attivita = allAttivita
      ? allAttivita.filter((data) => {
          const k = weeklyAggregation.getCardAggrKey(data);
          return k >= minK;
        })
      : allAttivita;

    return [boardWithAllAttivita, attivita];
  }, [allAttivita]);

  // kanban data

  const columns = useMemo(() => {
    const board = createBoardWithColumns(
      attivita,
      boardWithAllAttivita?.columns || [],
      weeklyAggregation.getCardAggrKey,
      weeklyAggregation.sortCardsBy
    );

    return board?.columns;
  }, [attivita, boardWithAllAttivita?.columns]);

  const { openSideModal, sideModal, sideModalInfo } = useSideModal();

  // users
  const [users, setUsers] = useState([]);

  const getAvailabilityUtentiApi = useApi(getAvailabilityUtentiAction, {
    onError: () => {
      showToast("Errore durante il caricamento degli utenti", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });
  const saveAvailabilityUtenteApi = useApi(saveAvailabilityUtenteAction, {
    onError: () => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const updateAvailability = async ({ user, week, availability }) => {
    setUsers(
      users.map((u) => {
        if (u.id === user.id) {
          return {
            ...u,
            weeklyAvailability: {
              ...(u.weeklyAvailability || {}),
              [week]: availability,
            },
          };
        }
        return u;
      })
    );
    await saveAvailabilityUtenteApi.callApi(user.id, {
      week,
      availability,
    });
  };

  useEffect(() => {
    let stop = false;

    getAvailabilityUtentiApi.callApi().then((data) => {
      if (stop) {
        return;
      }
      const users = data.data;
      if (users) {
        const usersBack = users.filter((u) => userHasRole(u, "Back"));
        setUsers(orderBy(usersBack, ["cognome", "nome"], ["asc", "asc"]));
      }
    });

    return () => {
      stop = true;
    };
  }, []);

  // drawer

  const [{ drawerOpen, filter: kanbanFilter }, setDrawerOpen] = useState({
    drawerOpen: false,
  });
  const closeDrawer = () => {
    setDrawerOpen({ drawerOpen: false });
  };
  const openKanban = (filter) => {
    setDrawerOpen({ drawerOpen: true, filter });
  };

  const kanbanFilterFunction = useMemo(() => {
    if (kanbanFilter) {
      if (kanbanFilter.user) {
        const userId = kanbanFilter.user.id;
        return (attivita) => {
          return attivita.idOperatore === userId;
        };
      }
      if (typeof kanbanFilter.priorityIndex === "number") {
        const priorityIndex = kanbanFilter.priorityIndex;
        return (attivita) => {
          const pi = getPriorityIndexForAttivita(attivita);
          return pi === priorityIndex;
        };
      }
      if (kanbanFilter.tipoAttivita) {
        const tipoAttivita = kanbanFilter.tipoAttivita.key;
        return (attivita) => {
          const ta = getTipoDaAttivita(attivita).key;
          return ta === tipoAttivita;
        };
      }
    }
    return null;
  }, [kanbanFilter]);

  // user e tipi di attivita da mostrare nel grafico

  let chartUsers = users;
  let chartFilterFunction = null;
  if (kanbanFilter && kanbanFilter.user) {
    chartUsers = [kanbanFilter.user];
    chartFilterFunction = kanbanFilterFunction;
  }
  const columnsForChart = useMemo(() => {
    if (kanbanFilter && kanbanFilter.user) {
      let _attivita = attivita;
      if (chartFilterFunction) {
        _attivita = _attivita.filter(chartFilterFunction);
      }
      const _board = createBoardWithColumns(
        _attivita,
        columns,
        weeklyAggregation.getCardAggrKey,
        weeklyAggregation.sortCardsBy
      );

      const _columns = _board.columns;

      return _columns.filter((column) => column.id !== NO_AGGR_KEY_COLUMN_ID);
    }
    return columns;
  }, [
    columns,
    kanbanFilter && kanbanFilter.user,
    attivita,
    chartFilterFunction,
  ]);

  // tipi di attivita
  const tipiDiAttivita = useMemo(() => {
    const _attivita = attivita;

    // if (chartFilterFunction) {
    //   _attivita = attivita.filter(chartFilterFunction);
    // }

    const tipi = {};
    _attivita.forEach((a) => {
      const tipo = getTipoDaAttivita(a);
      tipi[tipo.key] = tipo;
    });
    return tipi;
  }, [attivita, chartFilterFunction]);

  let content;

  const canEdit = hasPermission(["modifica_carico_di_lavoro"]);

  if (!hasPermission(["visualizza_carico_di_lavoro"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }

  if (loading) {
    content = "Caricamento attività...";
  } else if (loadError) {
    content = "Errore durante il caricamento delle attività";
  } else {
    const nColumns = columns.length;
    const width =
      leftColumnWidth + weekColumnWidth * nColumns + rightColumnWidth;

    content = (
      <>
        <div
          className="CaricoDiLavoro-kanban-container-Left"
          style={{ width: leftColumnWidth }}
        >
          <div style={{ position: "relative", width: width }}>
            <RealContent
              leftColumnWidth={leftColumnWidth}
              weekColumnWidth={weekColumnWidth}
              columnsForChart={columnsForChart}
              tipiDiAttivita={tipiDiAttivita}
              chartUsers={chartUsers}
              users={users}
              columns={columns}
              openKanban={openKanban}
              updateAvailability={updateAvailability}
            />
          </div>
        </div>

        <FixedSyncedBottomScrollBar
          scrollableContentWidth={width}
          leftColumnWidth={leftColumnWidth}
        />

        <ScrollSyncPane>
          <div
            className="CaricoDiLavoro-kanban-container-main"
            style={{
              position: "relative",
              overflowX: "auto",
              marginLeft: -8,
              marginRight: -8,
            }}
          >
            <div style={{ position: "relative", width: width }}>
              {/* TEMP */}
              {showTempRules && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      left: leftColumnWidth - 1,
                      top: 0,
                      bottom: 0,
                      width: 2,
                      backgroundColor: "#ff000050",
                      zIndex: 10000,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: width - rightColumnWidth - 1,
                      top: 0,
                      bottom: 0,
                      width: 2,
                      backgroundColor: "#ff000050",
                      zIndex: 10000,
                    }}
                  />
                </>
              )}
              {/* END TEMP */}
              <RealContent
                leftColumnWidth={leftColumnWidth}
                weekColumnWidth={weekColumnWidth}
                columnsForChart={columnsForChart}
                tipiDiAttivita={tipiDiAttivita}
                chartUsers={chartUsers}
                users={users}
                columns={columns}
                openKanban={openKanban}
                updateAvailability={updateAvailability}
                scrollToCurrentWeek
              />
            </div>
          </div>
        </ScrollSyncPane>
        <UserBottomDrawer
          drawerOpen={drawerOpen}
          closeDrawer={closeDrawer}
          canEdit={canEdit}
          attivitaSource={attivitaSource}
          openSideModal={openSideModal}
          sideModalInfo={sideModalInfo}
          kanbanFilterFunction={kanbanFilterFunction}
          classes={classes}
          kanbanFilter={kanbanFilter}
          columns={columns}
        />
      </>
    );
  }

  return (
    <ScrollSync>
      <Container
        className="CaricoDiLavoro"
        disableGutters={true}
        fixed={false}
        maxWidth={false}
      >
        {sideModal}

        <div style={{ display: "flex" }}>
          <Typography
            style={{
              flex: 1,
              marginLeft: 30,
            }}
            component="h2"
            gutterBottom={true}
            variant="h5"
          >
            Carico di lavoro
          </Typography>
          <div>
            {/* <ButtonGroup color="primary" size="small">
              <Button
                onClick={() => showAllUsers(false)}
                variant={isOnlyMe ? "contained" : "outlined"}
              >
                Io
              </Button>
              <Button
                onClick={() => showAllUsers(true)}
                variant={isAll ? "contained" : "outlined"}
              >
                Tutti
              </Button>
            </ButtonGroup> */}
            {/* <UserFilterSelector
              returnValue="id"
              defaultSelection={"all"}
              onChange={selectUser}
            /> */}
          </div>
        </div>

        {content}
      </Container>
    </ScrollSync>
  );
}

function RealContent({
  leftColumnWidth,
  weekColumnWidth,
  columnsForChart,
  tipiDiAttivita,
  chartUsers,
  users,
  columns,
  openKanban,
  updateAvailability,
  scrollToCurrentWeek = false,
}) {
  return (
    <>
      <CaricoBarChart
        leftColumnWidth={leftColumnWidth}
        weekColumnWidth={weekColumnWidth}
        rightColumnWidth={rightColumnWidth}
        columns={columnsForChart}
        tipiDiAttivita={tipiDiAttivita}
        users={chartUsers}
        openKanban={openKanban}
      />

      <Typography variant="h6" style={{ paddingLeft: 20, marginTop: 40 }}>
        Per utente:
      </Typography>
      <CaricoUserSection
        leftColumnWidth={leftColumnWidth}
        weekColumnWidth={weekColumnWidth}
        rightColumnWidth={rightColumnWidth}
        users={users}
        columns={columns}
        openKanban={openKanban}
        updateAvailability={updateAvailability}
        scrollToCurrentWeek={scrollToCurrentWeek}
      />

      <Typography variant="h6" style={{ paddingLeft: 20, marginTop: 40 }}>
        Per scadenza:
      </Typography>
      <CaricoPerScadenzaSection
        leftColumnWidth={leftColumnWidth}
        weekColumnWidth={weekColumnWidth}
        rightColumnWidth={rightColumnWidth}
        users={users}
        columns={columns}
        openKanban={openKanban}
      />

      {/* KANBAN */}
      <div className="CaricoDiLavoro-kanban-container-spacer"></div>
    </>
  );
}

function FixedSyncedBottomScrollBar({
  appDrawerWidth = 240,
  scrollableContentWidth,
  leftColumnWidth,
}) {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: appDrawerWidth,
        right: 0,
        zIndex: 100000,
        display: "flex",
      }}
    >
      <ScrollSyncPane>
        <div
          style={{
            flex: 1,
            overflowX: "scroll",
          }}
        >
          <div
            style={{
              height: 10,
              width: scrollableContentWidth - leftColumnWidth,
            }}
          />
        </div>
      </ScrollSyncPane>
    </div>
  );
}

function UserBottomDrawer({
  drawerOpen,
  closeDrawer,
  canEdit,
  attivitaSource,
  openSideModal,
  sideModalInfo,
  kanbanFilterFunction,
  classes,
  kanbanFilter,
  columns,
}) {
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [kanbanScroll, setKanbanScrollContainer] =
    useKanbanScrollFromContainer();

  return (
    <Drawer
      anchor="bottom"
      open={drawerOpen}
      className={classes.drawer}
      ModalProps={{
        className: clsx(
          "CaricoDiLavoro-kanban-drawer",
          !drawerExpanded && "CaricoDiLavoro-kanban-drawer-collapsed"
        ),
        disableEnforceFocus: true,
        keepMounted: true,
      }}
      BackdropProps={{
        invisible: true,
        className: classes.backdrop,
      }}
    >
      <Paper
        className={clsx(
          "CaricoDiLavoro-kanban-drawer-container ",
          classes.drawer_container,
          !drawerExpanded && "CaricoDiLavoro-kanban-drawer-container-collapsed"
        )}
      >
        <div className={classes.drawerToolbar}>
          <IconButton
            onClick={() => {
              setDrawerExpanded(!drawerExpanded);
            }}
          >
            {/* Icons are swapped on purpose, as our drawer expands from the bottom */}
            {drawerExpanded ? (
              <Tooltip title="Espandi">
                <ExpandMoreIcon />
              </Tooltip>
            ) : (
              <ExpandLessIcon />
            )}
          </IconButton>

          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>

        <div
          ref={(el) => setKanbanScrollContainer(el)}
          style={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 1,
            flexGrow: 1,
          }}
        >
          <div style={{ display: "flex" }}>
            <Typography
              style={{
                flex: "1 1",
                marginLeft: 16,
                flexBasis: "unset",
              }}
              component="h2"
              gutterBottom={true}
              variant="h5"
            >
              Kanban Attività
            </Typography>
            {kanbanFilter && (
              <div className="CaricoDiLavoro-kanban-filter">
                <div className="filter-pre">Filtro:</div>
                <div className="filter">
                  {kanbanFilter.user && (
                    <div>
                      <div
                        style={{
                          display: "inline-flex",
                          width: 25,
                          height: 25,
                          marginLeft: 30,
                          marginRight: 8,
                        }}
                      >
                        <Avatar
                          style={{
                            display: "inline-flex",
                            position: "absolute",
                            width: 25,
                            height: 25,
                            fontSize: 17,
                            marginTop: 6,
                          }}
                        >
                          {kanbanFilter.user.cognome?.toUpperCase().charAt(0)}
                        </Avatar>
                      </div>
                      {kanbanFilter.user.nome} {kanbanFilter.user.cognome}
                    </div>
                  )}
                  {typeof kanbanFilter.priorityIndex === "number" && (
                    <div>
                      <span style={{ marginLeft: 15 }}>
                        {priorityLabels[kanbanFilter.priorityIndex]}
                      </span>
                      <span style={{ fontSize: "0.8em", marginLeft: 6 }}>
                        {priorityDescriptions[kanbanFilter.priorityIndex]}{" "}
                      </span>
                    </div>
                  )}
                  {kanbanFilter.tipoAttivita && (
                    <div>
                      <span style={{ fontSize: "0.8em", marginLeft: 15 }}>
                        {kanbanFilter.tipoAttivita.isFormatAttivita
                          ? "Format Attività"
                          : kanbanFilter.tipoAttivita.isAttivita
                          ? "Attività"
                          : ""}
                        :
                      </span>
                      <span style={{ marginLeft: 6 }}>
                        {kanbanFilter.tipoAttivita.nome}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {kanbanScroll && (
            <ScrollSyncPane attachTo={kanbanScroll}>
              <div></div>
            </ScrollSyncPane>
          )}

          <AttivitaKanbanInternal
            disabled={!canEdit}
            attivitaSource={attivitaSource}
            showSenzaData={false}
            openSideModal={openSideModal}
            sideModalInfo={sideModalInfo}
            dataFilter={kanbanFilterFunction}
            forceColumns={columns}
          />
        </div>
      </Paper>
    </Drawer>
  );
}

function useKanbanScrollFromContainer() {
  const [kanbanScrollContainer, setKanbanScrollContainer] = useState(null);
  const [kanbanScroll, setKanbanScroll] = useState(null);
  useEffect(() => {
    if (kanbanScrollContainer && !kanbanScroll) {
      let timeout = null;
      function setIfPresent() {
        const el =
          kanbanScrollContainer &&
          kanbanScrollContainer.querySelector(".react-trello-board");
        if (el) {
          setKanbanScroll(el);
        } else {
          timeout = setTimeout(() => {
            setIfPresent();
          }, 10);
        }
      }
      setIfPresent();
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [kanbanScrollContainer, kanbanScroll]);
  return [kanbanScroll, setKanbanScrollContainer];
}
