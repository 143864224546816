import RichTextEditor from "app/components/common/RichTextEditor";

export default function CampoNoteFattureEPagamenti({
  value,
  onChange,
  disabled,
}) {
  const fakeSource = {
    getValue: (k) => {
      return value;
    },
    changeValue: (k, newString) => {
      if ((newString || "") !== (value || "")) onChange(newString);
    },
  };

  return (
    <div style={{ minWidth: 270 }}>
      <RichTextEditor
        __to_reload={value}
        source={fakeSource}
        sourceKey="note"
        readOnly={disabled}
      />
    </div>
  );
}
