import { useSelector } from "react-redux";

function getUserSelector(state) {
  return state.auth.user || null;
}

export function useUser() {
  const user = useSelector(getUserSelector);
  return user;
}
