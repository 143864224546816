import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFatture as getFattureAction } from "app/actions";
import FattureTable_abstract from "./FattureTable_abstract";

export default function FattureTable(props) {
  const fatture = useDataSource({
    loadAction: getFattureAction,
    initialData: [],
  });

  return (
    <FattureTable_abstract
      title="Fatture"
      fatture={fatture}
      exportFilename="Fatture"
    />
  );
}
