import React, { useEffect, useRef } from "react";
import { useDataTableContext } from "app/contexts/DataTableContext";
import { useUser } from "app/hooks/useUser";
import { isEqual, findIndex } from "lodash";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import UserFilterSelector from "../common/UserFilterSelector";

export function DashboardFilterUser({ path, filterByDefault = true }) {
  const { rows, columnFilters, setColumnFilters, columns, source } =
    useDataTableContext();
  // console.log(' useDataTableContext()',  useDataTableContext())
  // console.log(' useDataTableContext().source.loading',  useDataTableContext().source.loadSuccess)
  const user = useUser();
  const userValue = user.cognome;
  const loading = source && source.loading;
  const isLoaded = useRef(false);

  const columnIndex = findIndex(columns, (c) => isEqual(c.path, path));

  //   const isOnlyMe =
  //     columnFilters[columnIndex] &&
  //     columnFilters[columnIndex].search === userValue;
  //   const isAll = !columnFilters[columnIndex];

  //   const filter = (showAll) => {
  //     const newFilters = { ...columnFilters };
  //     if (showAll) {
  //       delete newFilters[columnIndex];
  //     } else {
  //       newFilters[columnIndex] = {
  //         type: "text",
  //         emptySelected: false,
  //         search: userValue,
  //         filterText: userValue,
  //       };
  //     }
  //     setColumnFilters(newFilters);
  //   };

  const selectUser = (userValue) => {
    console.log(userValue);
    const newFilters = { ...columnFilters };
    if (!userValue) {
      delete newFilters[columnIndex];
    } else {
      newFilters[columnIndex] = {
        type: "text",
        emptySelected: false,
        search: userValue.toLowerCase(),
        filterText: userValue,
      };
    }
    setColumnFilters(newFilters);
  };

  useEffect(() => {
    if (!isLoaded.current && !loading) {
      isLoaded.current = true;
      if (filterByDefault) {
        // setTimeout(() => filter(false), 1);
        setTimeout(() => selectUser(userValue), 1);
      }
    }
  }, [loading]);

  return (
    <div>
      {/* <ButtonGroup color="primary" size="small">
        <Button
          onClick={() => filter(false)}
          variant={isOnlyMe ? "contained" : "outlined"}
        >
          Io
        </Button>
        <Button
          onClick={() => filter(true)}
          variant={isAll ? "contained" : "outlined"}
        >
          Tutti
        </Button>
      </ButtonGroup> */}

      <UserFilterSelector
        returnValue="cognome"
        defaultSelection={filterByDefault ? "me" : "all"}
        onChange={selectUser}
      />
    </div>
  );
}
