import React, { useState, useEffect, useMemo } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getUtenti as getUtentiAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Avatar } from "@material-ui/core";
import { orderBy } from "lodash";

function addLabel(option) {
  const label = `${option.cognome || ""} ${option.nome || ""}`.trim();
  option._label = label;
  option._labelCaseIns = label.toLowerCase();
}

type UserPickerProps = {
  source: any;
  sourceKey: string;
  className?: string;
  variant?: "outlined" | "standard" | "filled";
  label?: string;
  readOnly?: boolean;
  role?: string;
};

export default function UserPicker(props: UserPickerProps) {
  const showToast = useToast();

  const [selectedUser, setSelectedUser] = useState(null);

  const utenti = useDataSource({
    initialData: [],
    loadAction: getUtentiAction,
    autoLoad: false,
    onLoadSuccess: (response) => {
      const utente = response.data.find((u) => {
        return u.id === props.source.getValue(props.sourceKey);
      });

      if (utente) {
        setSelectedUser(utente);
      } else {
        setSelectedUser(null);
      }
    },
    onLoadError: (response) => {
      showToast("Error durante il caricamento della lista utenti", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  useEffect(() => {
    utenti.load();
  }, [props.source.getValue(props.sourceKey) || null]);

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;

  const options = useMemo(() => {
    let d = (utenti.data || []).filter(
      (u) => !u.disattivato
    ); /* no user disattivati */

    if (props.role) {
      d = d.filter((u) => props.role && !!u.roles?.[props.role]);
    }

    d.forEach((option) => {
      addLabel(option);
    });

    return orderBy(d, ["_labelCaseIns"], ["asc"]);
  }, [utenti.loading, utenti.data, props.role]);

  const value = useMemo(() => {
    if (selectedUser) {
      addLabel(selectedUser);
    }
    return selectedUser;
  }, [selectedUser]);

  return (
    <Autocomplete
      className={props.className || ""}
      disabled={props.readOnly}
      fullWidth={true}
      loading={utenti.loading}
      loadingText="Caricamento lista utenti ..."
      multiple={false}
      noOptionsText="Nessun utente"
      options={options}
      size="small"
      value={value}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={props.variant || "outlined"}
            label={props.label}
            error={!!sourceError}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option._label;
      }}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Avatar
              style={{
                width: 24,
                height: 24,
                marginRight: 8,
              }}
            >
              {option._label.toUpperCase().charAt(0)}
            </Avatar>

            {option._label}
          </div>
        );
      }}
      onChange={(event, value) => {
        setSelectedUser(value);

        props.source.changeValue(props.sourceKey, value ? value.id : null);
      }}
    />
  );
}
