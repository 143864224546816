import React, { useEffect, useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useHistory } from "react-router-dom";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { createBudget as createBudgetAction } from "app/actions";
import { Container, Dialog, Grid } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import AnnoPicker from "../common/AnnoPicker";
import moment from "moment";
import BudgetPicker from "../common/BudgetPicker";
import { useApi } from "app/hooks/useApi";

export default function DialogCreaBudget(props) {
  const showToast = useToast();

  const now = useMemo(() => moment().year(), []);

  const createApi = useApi(createBudgetAction);

  const [createData, setCreateData] = useState({
    anno: now,
    baseId: props.initialBaseId || null,
  });

  const [loading, setLoading] = useState(false);

  const changeValue = (key, value) => {
    setCreateData((d) => {
      return {
        ...d,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (typeof props.initialBaseId !== "undefined") {
      if (createData.baseId !== props.initialBaseId) {
        changeValue("baseId", props.initialBaseId);
      }
    }
  }, [props.initialBaseId]);

  const reset = () => {
    setCreateData({
      anno: now,
      baseId: props.initialBaseId || null,
    });
  };

  const create = async () => {
    let response;
    setLoading(true);
    try {
      response = await createApi.callApi(createData);
    } catch (err) {
      console.log(err);
      setLoading(false);
      showToast("Errore durante la creazione del budget", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
      return;
    }

    showToast("Budget creato", {
      color: "success",
      horizontal: "left",
      vertical: "bottom",
    });

    setLoading(false);
    reset();
    props.created(response.data);
  };

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="md"
      open={props.open}
      scroll="paper"
    >
      <DialogTitle>Crea budget</DialogTitle>

      <DialogContent>
        <Container disableGutters>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <AnnoPicker
                anno={createData.anno}
                setAnno={(anno) => changeValue("anno", anno)}
                aggiungiProssimoAnno
                fullWidth
              />
            </Grid>

            {props.budgetList && props.budgetList.length > 0 && (
              <Grid item lg={12} md={12} xs={12} style={{ marginTop: 20 }}>
                <BudgetPicker
                  budgetList={props.budgetList}
                  budgetId={createData.baseId}
                  setBudgetId={(budgetId) => changeValue("baseId", budgetId)}
                  label="Budget di partenza"
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={(event) => {
            reset();
            props.cancel();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={loading}
          onClick={(event) => {
            create();
          }}
          color="primary"
        >
          Salva
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
