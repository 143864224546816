// Action key that carries API call info interpreted by this Redux middleware.
import baseUrl from "app/utils/baseUrl";
import { every } from "lodash";
import { onSessionExpired } from "app/contexts/UncommittedChangesConfirmationContext";

export const CALL_API = "CALL_API";

// Fetches an API response
async function callApi(endpoint, method, body, abortController) {
  const headers = {
    Accept: "application/json",
  };

  if (body && !(body instanceof FormData)) {
    body = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }
  endpoint = baseUrl + endpoint;

  const response = await fetch(endpoint, {
    method,
    body,
    headers: headers,
    credentials: "include",
    signal: abortController && abortController.signal,
  });

  if (response.status === 401) {
    onSessionExpired();
    window.location.href = "/login";
    return;
  }

  const json = await response.json();

  if (!response.ok) {
    return Promise.reject(json);
  }

  return json;
}

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint, method } = callAPI;
  const { body, types, abortController } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  method = method || "GET";

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!every(types, (type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));

  return callApi(endpoint, method, body, abortController).then(
    (response) =>
      next(
        actionWith({
          type: successType,
          callApiSuccess: true,
          response,
        })
      ),

    (error) =>
      next(
        actionWith({
          type: failureType,
          callApiSuccess: false,
          response: error instanceof Error ? { message: error.message } : error,
        })
      )
  );
};
