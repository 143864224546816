import React, { useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { getClienti as getClientiAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { orderBy } from "lodash";
import { usePickerDataSource } from "app/hooks/DataSource/usePickerDataSource";

export default function ClientePicker(props) {
  const showToast = useToast();

  const [selectedCliente, setSelectedCliente] = useState(null);

  const clienti = usePickerDataSource(
    {
      initialData: [],
      loadAction: getClientiAction,
      autoLoad: true,
      onInitialLoadSuccess: (response) => {
        const cliente = response.data.find((c) => {
          return c.id === props.source.getValue(props.sourceKey);
        });

        if (cliente) {
          setSelectedCliente(cliente);
        } else {
          setSelectedCliente(null);
        }
      },
      onLoadError: () => {
        showToast("Error durante il caricamento della lista aziende", {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        });
      },
    },
    ["ragioneSociale"],
    props.source.getValue(props.sourceKey) || null
  );

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;

  const options = useMemo(() => {
    const d = clienti.data || [];

    d.forEach((option) => {
      const label = `${option.ragioneSociale || ""}`;
      option._labelCaseIns = label.toLowerCase();
    });

    return orderBy(d, ["_labelCaseIns"], ["asc"]);
  }, [clienti.data]);

  return (
    <Autocomplete
      disabled={props.readOnly}
      fullWidth={true}
      loading={clienti.loading}
      loadingText="Caricamento lista aziende ..."
      multiple={false}
      noOptionsText="Nessuna azienda"
      onClose={null}
      options={options}
      size="small"
      value={selectedCliente}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={props.variant || "outlined"}
            label={props.label}
            error={!!sourceError}
            helperText={sourceError}
            InputLabelProps={{ shrink: props.shrink }}
          />
        );
      }}
      getOptionLabel={(option) => {
        return `${option.ragioneSociale || ""}`;
      }}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {`${option.ragioneSociale || ""}`}
          </div>
        );
      }}
      onChange={(event, value) => {
        setSelectedCliente(value || null);

        props.source.changeValue(props.sourceKey, value?.id || null);
        props?.onChange?.(value?.id || null);
      }}
      onInputChange={(e) => {
        if (e) {
          clienti.onSearchTextChange(e?.target?.value || null);
        }
      }}
    />
  );
}
