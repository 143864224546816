import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { ColumnFilterOptionsComponent } from "./index";
import { BooleanColumnFilter } from "app/elements/DataTable/filters/types";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginTop: -8,
    },
    formControlLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginBottom: -12,
      marginTop: 0,
      "& input": {
        pointerEvents: "none",
      },
    },
    checkbox: {
      padding: 0,
      marginTop: 4,
      marginLeft: 8,
    },
  })
);

// TODO: add button to clear filter
const BooleanFilter: ColumnFilterOptionsComponent<BooleanColumnFilter> = ({
  filterOptions,
  setFilter,
  messages,
}) => {
  const classes = useStyles();

  const allSelected = !filterOptions;

  const onBooleanOption = (option, checked) => {
    const newFilterOptions = {
      type: "boolean",
      trueSelected: true,
      falseSelected: true,
      ...((filterOptions as any) || {}),
      [option]: checked,
    };
    if (newFilterOptions.trueSelected && newFilterOptions.falseSelected) {
      setFilter(null as any);
    } else {
      setFilter(newFilterOptions);
    }
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={allSelected || filterOptions.trueSelected || false}
            onChange={(event) =>
              onBooleanOption("trueSelected", event.target.checked)
            }
            color="primary"
          />
        }
        label={<CheckBoxOutlinedIcon />}
      />
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={allSelected || filterOptions.falseSelected || false}
            onChange={(event) =>
              onBooleanOption("falseSelected", event.currentTarget.checked)
            }
            color="primary"
          />
        }
        label={<CheckBoxOutlineBlankOutlinedIcon />}
      />
    </div>
  );
};

export default BooleanFilter;
