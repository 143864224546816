// RITORNA LE ATTIVITA CHE POSSONO ESSERE USATE COME DATA RELATIVA NELL'
// ATTIVITA CORRENTE
// return: objs with _disabled = true/false
export function formatAttivitaPreventCircularDependecies(
  currentAttivitaId,
  attivitaArray
) {
  // idAttività -> list of idAttività che lo usano
  const usedByMap = {};
  const addToMap = (idAttivita, toIdAttività) => {
    usedByMap[toIdAttività] = usedByMap[toIdAttività] || new Set();
    usedByMap[toIdAttività].add(idAttivita);
  };

  attivitaArray.forEach((att) => {
    if (
      att.dataScadenzaPerentoriaType === "delta" &&
      att.dsPerentoriaIdFormatAttivita
    ) {
      addToMap(att.id, att.dsPerentoriaIdFormatAttivita);
    }
    if (
      att.dataScadenzaProgettoType === "delta" &&
      att.dsProgettoIdFormatAttivita
    ) {
      addToMap(att.id, att.dsProgettoIdFormatAttivita);
    }
  });

  const setOfUsingId = new Set();

  const findUnwantedNodes = (id) => {
    setOfUsingId.add(id);
    const set = usedByMap[id];
    usedByMap[id] = null;

    if (set) {
      set.forEach((v) => {
        findUnwantedNodes(v);
      });
    }
  };

  findUnwantedNodes(currentAttivitaId);

  console.log("formatAttivitaPreventCircularDependecies", {
    attivitaArray,
    currentAttivitaId,
    setOfUsingId,
  });

  const usableAttivitaArray = attivitaArray
    .filter((x) => x.id !== currentAttivitaId)
    .filter((x) => !setOfUsingId.has(x.id));
  // .map(x => {
  //   return {
  //     ...x,
  //     _disabled: setOfUsingId.has(x.id)
  //   }
  // })

  return usableAttivitaArray;
}
