import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { globalScope } from "react-async";

type UseApiState = {
  loading: boolean;
  response: any;
  data: any;
  error: any;
};

const initialState = {
  loading: false,
  response: null,
  data: null,
  error: null,
};

type UseApiCallbackArgument = {
  onSuccess?: (state: UseApiState) => void;
  onError?: (state: UseApiState) => void;
};

const nop = () => {};

export function useApi(
  action,
  { onSuccess = nop, onError = nop }: UseApiCallbackArgument = {}
) {
  const [state, setState] = useState(initialState);
  const callIdRef = useRef(0);
  const abortControllerRef = useRef<any>(null);
  const dispatch = useDispatch();

  const { loading, data, response, error } = state;

  const callApi = async (...params) => {
    callIdRef.current++;
    abortControllerRef.current?.abort();
    const callId = callIdRef.current;

    abortControllerRef.current =
      "AbortController" in globalScope
        ? new globalScope.AbortController!()
        : null;

    if (!loading) {
      setState({
        loading: true,
        response: null,
        error: null,
        data,
        params,
      } as any);
    }

    const nextAction = await dispatch(
      action(...params, abortControllerRef.current)
    );
    if (callId !== callIdRef.current) {
      return;
    }

    const { response } = nextAction;
    if (nextAction.callApiSuccess) {
      const newState = {
        loading: false,
        response: response,
        data: response?.data || null,
        error: null,
        params,
      };
      setState(newState);
      onSuccess(newState);
      return response;
    } else {
      const newState = {
        loading: false,
        response: response,
        data: null,
        error: response,
        params,
      };
      setState(newState);
      onError(newState);
      throw response; // TODO: wrap in error?
      // TODO: identify type of error: transport or server?
    }
  };

  return {
    callApi,
    loading,
    data,
    error,
    response,
  };
}
