import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClienteInterazioni from "../Clienti/ClienteInterazioni";
import React from "react";

export function ContattiInterazioniAccordion(props) {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Contatti/Interazioni
      </AccordionSummary>

      <AccordionDetails>
        <ClienteInterazioni
          clienteId={props.procedura.getValue([
            "mandato",
            "idClientePrincipale",
          ])}
          idMandato={props.procedura.getValue(["mandato", "id"])}
        />
      </AccordionDetails>
    </Accordion>
  );
}
