import { EventoAgenda, getColorAndLabel } from "./EventoAgenda";
import classNames from "classnames";
import { Avatar, lighten } from "@material-ui/core";
import RichTextEditor from "../common/RichTextEditor";
import moment from "moment";
import PersonIcon from "@material-ui/icons/Person";

export function ScadenziarioKanbanCard({
  data,
  onClick,
}: {
  data: EventoAgenda;
  onClick: (data: EventoAgenda) => void;
}) {
  const event = data;

  const value: any = event.descrizione;
  const user = event.utente;
  let refText = "";

  if (event.azienda) {
    refText = `${event.azienda.ragioneSociale || ""}`;
  } else if (event.contatto) {
    refText = `${event.contatto.cognome || ""} ${event.contatto.nome || ""}`;
  }

  const { backgroundColor, statusLabel } = getColorAndLabel(event);

  const statusStyle = {
    backgroundColor,
  };

  const dataProgrammata = event.dataProgrammata;
  const dataEffettiva = event.dataEffettiva;

  /* 
export default function ScadenziarioKanbanCard({
  data,
  removeCard,
  dragging,
  // custom props...
  openAttivita,
}) {
  const procedura = data.procedura?.nome || "";
  const attivita = data.nome;
  const bene = data.bene || null;
  const dataEffettiva = data.dataEffettiva;
  const dataPrevista = data.dataPrevista;
  const orePreviste = data.orePreviste;
  const dataScadenzaProgetto = data.dataScadenzaProgetto;
  const dataScadenzaPerentoria = data.dataScadenzaPerentoria;
  const operatore = data.operatore;
  const clientePrincipale = data.procedura?.mandato?.clientePrincipale;

  const saving = data.saving;
  // if (saving) {
  //   setTimeout(() => {
  //     debugger;
  //   }, 1);
  // }
  */

  return (
    <div
      className={classNames("ScadenziarioKanbanCard", "react-kanban-card")}
      onClick={() => {
        onClick(data);
      }}
    >
      <div className="ScadenziarioKanbanCard-statusColor" style={statusStyle}>
        <div>{statusLabel}</div>
      </div>
      {/* {saving && (
        <div className="ScadenziarioKanbanCard-saving-bg">
          <CircularProgress color="inherit" />
        </div>
      )} */}
      <span>
        <div className="ScadenziarioKanbanCard-title">
          <RichTextEditor
            readonlyValue={value}
            readOnly={true}
            className="RichTextEditor-for-event"
          />
        </div>
      </span>

      <div
        className="react-kanban-card__description"
        style={{ padding: 0, paddingTop: 4 }}
      >
        {refText && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PersonIcon
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                fontSize: 12,
                opacity: 0.8,
              }}
            />
            <span style={{ fontSize: "0.9em" }}>{refText}</span>
          </div>
        )}
        {user && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                fontSize: 12,
              }}
            >
              {user.cognome?.toUpperCase().charAt(0)}
            </Avatar>
            <span style={{ fontSize: "0.9em" }}>
              {user.cognome} {user.nome}
            </span>
          </div>
        )}

        {refText && user && (
          <div className="ScadenziarioKanbanCard-separator"></div>
        )}

        {dataProgrammata && (
          <ScadenziarioKanbanCardDescRow
            title="Data programmata"
            value={moment(dataProgrammata).format("DD/MM/YYYY")}
          />
        )}
        {dataEffettiva && (
          <ScadenziarioKanbanCardDescRow
            title="Data effettiva"
            value={moment(dataEffettiva).format("DD/MM/YYYY")}
          />
        )}
      </div>
    </div>
  );
}

function ScadenziarioKanbanCardDescRow({ title, value }) {
  return (
    <div className="ScadenziarioKanbanCard-desc-row">
      <span className="ScadenziarioKanbanCard-desc-row-title">{title}</span>{" "}
      <span className="ScadenziarioKanbanCard-desc-row-value">{value}</span>
    </div>
  );
}
