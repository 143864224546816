import React from "react";
import { find } from "lodash";

export default function VariabileAgevolazioneValue(props) {
  const agevolazioni = props.agevolazioni;

  const idVariabile = props.idVariabile;

  const idAgevolazioneByIdVariabile = {};

  agevolazioni.data.forEach((agevolazione) => {
    agevolazione.variabili.forEach((variabile) => {
      idAgevolazioneByIdVariabile[variabile.id] = agevolazione.id;
    });
  });

  const idAgevolazione = idAgevolazioneByIdVariabile[idVariabile];

  const agevolazione = find(agevolazioni?.data, (a) => a.id === idAgevolazione);

  const variabile = find(agevolazione?.variabili, (v) => v.id === idVariabile);

  console.log(idVariabile, idAgevolazione, agevolazione, variabile);

  const hasInfo = Boolean(agevolazione?.nome?.nome && variabile?.nome?.nome);
  return (
    <span>
      {hasInfo
        ? `(${agevolazione?.nome?.nome} - ${variabile?.nome?.nome})`
        : null}{" "}
    </span>
  );
}
