import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { Link, useParams } from "react-router-dom";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  editProcedura as editProceduraAction,
  getAttivitaProcedura as getAttivitaProceduraAction,
  getProcedura as getProceduraAction,
} from "app/actions";
import { some } from "lodash";
import proceduraHasVersioneSuccessiva from "app/utils/proceduraHasVersioneSuccessiva";
import BackButton from "app/components/common/BackButton";
import ProceduraAggiornaVersioneDialog from "app/components/Procedure/ProceduraAggiornaVersioneDialog";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { ColoredButton } from "app/elements/ColoredButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileBox from "app/components/common/FileBox";
import UserPicker from "app/components/common/UserPicker";
import RichTextEditor from "app/components/common/RichTextEditor";
import AccordionDocumenti from "app/components/Attivita/AccordionDocumenti";
import AccordionListaBeni from "app/components/Mandato/AccordionListaBeni";
import { ContattiInterazioniAccordion } from "./ContattiInterazioniAccordion";
import { AttivitaAccordion } from "./AttivitaAccordion";
import LaunchIcon from "@material-ui/icons/Launch";
import { useEffectOnSearchValue } from "../../utils/extractURLSearchValue";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";
import ProceduraStato from "app/constants/ProceduraStato.json";
import { StatoPicker } from "app/components/StatoPicker";
import MandatoLineaPrevisioneBenefici from "app/components/Mandato/MandatoLineaPrevisioneBenefici";

export default function Procedura(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const { id } = props.useIdFromProps ? props : useParams();

  const [editing, setEditing] = useState(false);

  const readOnly = !editing;

  const procedura = useDataSource({
    loadAction: getProceduraAction,
    editAction: editProceduraAction,
    itemId: id,
    autoLoad: !!id,
    initialData: {},
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Commessa modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      attivita.load();
      props.onEditSuccess?.(response.data);
    },
    onEditError: () => {
      showToast("Errore durante la modifica della commessa", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });
  useCreaconsultingTitle(`Commessa ${procedura.getValue("nome", "")}`);

  const isMultibene = some(procedura?.data?.mandato?.mandatoLinee, (ml) => {
    return (
      ml.idLinea === procedura?.data?.formatProcedura?.idLinea &&
      ml.linea?.isMultibene
    );
  });

  const hasMigrateToNewVersion = proceduraHasVersioneSuccessiva(procedura.data);

  const documenti = useDataSource({
    parent: procedura,
    pathInParent: ["documenti"],
    initialData: [],
  });

  const documentiFaseNegoziazione = useDataSource({
    parent: procedura,
    pathInParent: ["mandato", "documentiFaseNegoziazione"],
    initialData: [],
  });

  const formatProcedura = useDataSource({
    parent: procedura,
    pathInParent: ["formatProcedura"],
    initialData: {},
  });

  const attivita = useDataSource({
    initialData: [],
    itemId: id,
    loadAction: getAttivitaProceduraAction,
    autoLoad: !!id,
  });

  const variant = readOnly ? "standard" : "outlined";

  const mandato = useDataSource({
    parent: procedura,
    pathInParent: ["mandato"],
    initialData: {},
  });

  const [aggiornaVersione, setAggiornaVersione] = useState(false);

  const onVersioneUpdated = () => {
    procedura.load();

    attivita.load();
  };

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_commessa") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_commessa")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const mandatoLineaSource = useDataSource({
    parent: procedura,
    pathInParent: [
      "mandato",
      "mandatoLinee",
      { idMandato: mandato.getValue("id") },
    ],
    initialData: {},
  });

  return (
    <>
      <h2
        className="page-title-header page-title-header-sticky"
        style={{
          marginTop: -20,
          marginBottom: 0,
        }}
      >
        <BackButton defaultBackLink="" />
        Commessa
      </h2>

      <ProceduraAggiornaVersioneDialog
        procedura={procedura}
        open={aggiornaVersione}
        onClose={() => {
          setAggiornaVersione(false);
        }}
        onUpdated={onVersioneUpdated}
      />

      <Container disableGutters={true} fixed={false} maxWidth="xl">
        {procedura.loading ? (
          "Caricamento commessa in corso ..."
        ) : procedura.loadError ? (
          "Errore durante il caricamento della commessa"
        ) : (
          <>
            <div
              className="page-title-header-sticky-buttons-row"
              style={{
                display: "flex",
                marginBottom: "1em",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                {editing ? (
                  <TextField
                    disabled={procedura.loading || procedura.editing}
                    error={procedura.getValidationErrors("nome").length > 0}
                    fullWidth={true}
                    helperText={procedura.getValidationErrors("nome")[0]}
                    label="Nome commessa"
                    onBlur={() => {
                      procedura.runValidation("nome");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      procedura.changeValue(
                        "nome",

                        value
                      );
                    }}
                    type="text"
                    value={procedura.getValue("nome", "")}
                    variant={variant}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    style={{ marginTop: 8 }}
                  />
                ) : (
                  <Typography
                    style={{
                      flex: 1,
                    }}
                    component="h2"
                    gutterBottom={true}
                    variant="h6"
                  >
                    {procedura.getValue("nome")}
                  </Typography>
                )}

                <Typography
                  style={{
                    flex: 1,
                    fontSize: "1.2em",
                    lineHeight: 1.2,
                    marginTop: 8,
                  }}
                  component="h2"
                  gutterBottom={true}
                  variant="h6"
                >
                  {procedura.getValue(
                    ["mandato", "clientePrincipale", "ragioneSociale"],
                    ""
                  )}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "start",
                  marginTop: 8,
                  marginBottom: 8,
                  marginLeft: 8,
                }}
              >
                <StatoPicker
                  label="Stato"
                  source={procedura}
                  stati={ProceduraStato}
                  disabled={!readOnly}
                  isChangePrevented={(oldStato, newStato) => {
                    const needsPermission =
                      (oldStato === "archiviata" && newStato === "in-corso") ||
                      (oldStato === "in-corso" && newStato === "stand-by") ||
                      (oldStato === "stand-by" && newStato === "in-corso");
                    if (
                      needsPermission &&
                      !hasPermission("riapri_sospendi_commessa")
                    ) {
                      return "Non hai il permesso per poter passare allo stato selezionato.";
                    }

                    return null;
                  }}
                />
                <ColoredButton
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={false}
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  href={`/api/procedure/${procedura.getValue("id")}/export-pdf`}
                  target="_blank"
                >
                  Esporta PDF
                </ColoredButton>
                {hasMigrateToNewVersion ? (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      setAggiornaVersione(true);
                    }}
                    startIcon={
                      <StarIcon
                        style={{
                          color: "#ffd600",
                        }}
                      />
                    }
                    variant="outlined"
                  >
                    Nuova versione
                  </ColoredButton>
                ) : null}{" "}
                {hasPermission("visualizza_offerta_mandato") ? (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    variant="outlined"
                    to={"/mandati/" + procedura.getValue("idMandato")}
                    component={Link}
                  >
                    Vai al mandato
                  </ColoredButton>
                ) : null}{" "}
                {props.isSideModal && readOnly && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    color="secondary"
                    size="small"
                    startIcon={<LaunchIcon />}
                    variant="outlined"
                    to={`/procedure/${id}?edit=true`}
                    component={Link}
                  >
                    Apri
                  </ColoredButton>
                )}
                {readOnly ? (
                  hasPermission("modifica_commessa") ? (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={() => {
                        setEditing(!editing);
                      }}
                      startIcon={<EditIcon />}
                      variant="outlined"
                    >
                      Modifica
                    </ColoredButton>
                  ) : null
                ) : (
                  <>
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        procedura.cancel();

                        setEditing(false);
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      startIcon={<CancelIcon />}
                      variant="outlined"
                    >
                      Annulla modifiche
                    </ColoredButton>

                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        procedura.commit();
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      startIcon={<SaveIcon />}
                      variant="outlined"
                    >
                      Salva
                    </ColoredButton>
                  </>
                )}{" "}
              </div>
            </div>

            <Grid
              style={{
                marginTop: "2em",
              }}
              container={true}
              item={false}
              spacing={2}
            >
              <Grid container={false} item={true} md={4} sm={12}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Documenti commessa
                  </AccordionSummary>

                  <AccordionDetails>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <FileBox
                        source={procedura}
                        sourceKey={["formatProcedura", "documentoProduzione"]}
                        title="Documenti - produzione"
                        readOnly={true}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid container={false} item={true} md={4} sm={12}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Conteggi vari
                  </AccordionSummary>

                  <AccordionDetails>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <FileBox
                        source={procedura}
                        sourceKey="conteggiVari"
                        title="File conteggi vari"
                        readOnly={readOnly}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid container={false} item={true} md={4} sm={12}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Operatore
                  </AccordionSummary>

                  <AccordionDetails>
                    <UserPicker
                      source={procedura}
                      sourceKey="idOperatore"
                      readOnly={readOnly}
                      role="Back"
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>

            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Informazioni sul format procedura commessa
              </AccordionSummary>

              <AccordionDetails>
                <Grid container={true} item={false} spacing={4}>
                  <Grid container={false} item={true} lg={4} md={6} xs={12}>
                    <RichTextEditor
                      __to_reload={formatProcedura?.getValue("motivoRevisione")}
                      source={formatProcedura}
                      sourceKey="motivoRevisione"
                      readOnly={true}
                      label="Motivo revisione"
                    />
                  </Grid>

                  <Grid container={false} item={true} lg={8} md={6} xs={12}>
                    <RichTextEditor
                      __to_reload={formatProcedura?.getValue(
                        "istruzioniOperative"
                      )}
                      source={formatProcedura}
                      sourceKey="istruzioniOperative"
                      readOnly={true}
                      label="Istruzioni operative"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Previsione benefici
              </AccordionSummary>

              <AccordionDetails>
                {mandatoLineaSource.getValue("id") && (
                  <MandatoLineaPrevisioneBenefici
                    readOnly
                    mandatoLineaSource={mandatoLineaSource}
                    procedure={[procedura.data]}
                    statoMandato={mandato.getValue("statoMandato")}
                  />
                )}
              </AccordionDetails>
            </Accordion>

            {documentiFaseNegoziazione.data.length > 0 && (
              <AccordionDocumenti
                title="Documenti raccolti in fase di negoziazione"
                documenti={documentiFaseNegoziazione}
                readOnly
              />
            )}

            <AccordionDocumenti
              documenti={documenti}
              parentKey="idProcedura"
              parentId={procedura.getValue("id")}
              readOnly={readOnly}
              canAddDocumenti
              canRemoveDocumenti
              tipoNuovoDocumento="documentoCommessaInterno"
              showTemplate
            />

            {isMultibene ? (
              <AccordionListaBeni
                mandato={mandato}
                readOnly={true}
                __readOnly={readOnly}
              />
            ) : null}

            <ContattiInterazioniAccordion procedura={procedura} />

            <AttivitaAccordion
              readOnly={readOnly}
              attivitaDataSource={attivita}
              proceduraDataSource={procedura}
            />

            <Accordion
              style={{
                display: "none",
              }}
              defaultExpanded={true}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Riepilogo
              </AccordionSummary>

              <AccordionDetails />
            </Accordion>
          </>
        )}{" "}
      </Container>
    </>
  );
}
