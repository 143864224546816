import {
  Backdrop,
  Box,
  Container,
  FormControl,
  InputLabel,
  lighten,
  MenuItem,
  Paper,
  Popper,
  Select,
  Typography,
  Grid,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { saveIntervalloPromemoriaRicontatto } from "app/actions";
import { useApi } from "app/hooks/useApi";
import { useToast } from "app/hooks/useToast";
import {
  defaultIntervalloPromemoriaRicontatto,
  getIntervalloPromemoriaRicontatto,
} from "common/intervalloPromemoriaRicontattoConstants";
import moment from "moment";
import { useMemo, useState } from "react";
import { ColoredButton } from "../../elements/ColoredButton";
import { autoColor } from "../Scadenziario/EventoAgenda";

export function PromemoriaRicontattoSettings({
  azienda,
  utente,
  onClose,
  onSettingsSaved,
}) {
  const showToast = useToast();

  const [intervalloUtente, setIntervalloUtente] = useState(
    utente?.intervalloPromemoriaRicontatto
  );
  const [intervalloAzienda, setIntervalloAzienda] = useState(
    azienda?.intervalloPromemoriaRicontatto
  );

  const intervalloPromemoriaRicontatto = getIntervalloPromemoriaRicontatto(
    azienda,
    utente
  );

  let durationString;
  if (intervalloPromemoriaRicontatto) {
    durationString = `Ogni ${intervalloPromemoriaRicontatto} giorni`;
  } else {
    durationString = "Non impostato";
  }

  const [loading, setLoading] = useState(false);
  const saveApi = useApi(saveIntervalloPromemoriaRicontatto, {
    onError: () => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onSuccess: () => {
      showToast("Impostazioni aggiornate!", { color: "success" });
    },
  });

  const save = async () => {
    try {
      setLoading(true);
      await saveApi.callApi({
        userId: utente?.id,
        aziendaId: azienda?.id,
        intervalloUtente,
        intervalloAzienda,
      });
      onSettingsSaved && onSettingsSaved();
      onClose && onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="md"
      style={{
        // width: "85vw",
        padding: 16,
      }}
    >
      <Grid container={true} spacing={2}>
        <Grid item={true} sm={12}>
          <Typography variant="h6">Promemoria ricontatto</Typography>
          <Typography style={{ marginTop: 8 }}>
            L'intervallo attualmente definito è: <i>{durationString}</i>
            {azienda && (
              <>
                <br />
                Puoi modificare qui ogni quanto vuoi ricevere il promemoria per
                l'azienda <b>{azienda.ragioneSociale}</b>.
              </>
            )}
            <br />
            <small>(Il valore avrà effetto per i prossimi promemoria)</small>
          </Typography>
        </Grid>
      </Grid>

      <Grid container={true} spacing={2}>
        {utente && (
          <Grid item={true} sm={12} style={{ marginTop: 16 }}>
            <IntervalloSelector
              disabled={loading}
              label={
                <>
                  Intervallo di default per utente{" "}
                  <b>
                    {utente.nome} {utente.cognome}
                  </b>
                </>
              }
              value={intervalloUtente}
              onChange={setIntervalloUtente}
              isUtente
            />
          </Grid>
        )}
        {azienda && (
          <Grid item={true} sm={12} style={{ marginTop: 16 }}>
            <IntervalloSelector
              disabled={loading}
              label={
                <>
                  Intervallo specifico per l'azienda{" "}
                  <b>{azienda.ragioneSociale}</b>
                </>
              }
              value={intervalloAzienda}
              onChange={setIntervalloAzienda}
            />
          </Grid>
        )}
      </Grid>

      <Grid container={true} spacing={2}>
        <Grid item={true} sm={12} style={{ marginTop: 16 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ColoredButton
              style={{
                marginLeft: "auto",
              }}
              disabled={loading}
              onClick={(event) => {
                onClose && onClose();
              }}
              color="secondary"
              size="small"
              variant="outlined"
            >
              Annulla
            </ColoredButton>

            <ColoredButton
              style={{
                marginLeft: 8,
              }}
              disabled={loading}
              onClick={(event) => {
                save();
              }}
              color="secondary"
              size="small"
              variant="outlined"
            >
              Salva
            </ColoredButton>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export function PromemoriaRicontattoSettingsButton({
  azienda,
  utente,
  onSettingsSaved = null,
}) {
  const intervalloPromemoriaRicontatto = getIntervalloPromemoriaRicontatto(
    azienda,
    utente
  );

  let durationString;
  if (intervalloPromemoriaRicontatto) {
    durationString = `Ogni ${intervalloPromemoriaRicontatto} giorni`;
  } else {
    durationString = "Non impostata";
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  if (!azienda && !utente) {
    return null;
  }

  return (
    <>
      <ColoredButton
        variant="outlined"
        onClick={handleClick}
        startIcon={<SettingsIcon />}
        color={"default"}
        style={
          {
            // background: lighten(autoColor, 0.75),
          }
        }
      >
        <div
          style={{
            lineHeight: 1.3,
            textAlign: "left",
          }}
        >
          <div
            style={{
              fontSize: "0.7em",
            }}
          >
            Promemoria ricontatto
          </div>
          <div
            style={{
              textTransform: "none",
              fontSize: "0.9em",
            }}
          >
            {durationString}
          </div>
        </div>
      </ColoredButton>
      <Backdrop open={open} style={{ zIndex: 10000, opacity: 0.5 }}></Backdrop>
      <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 10001 }}>
        <Paper>
          <PromemoriaRicontattoSettings
            azienda={azienda}
            utente={utente}
            onClose={handleClick}
            onSettingsSaved={onSettingsSaved}
          />
        </Paper>
      </Popper>
    </>
  );
}

const IntervalloSelectorValues = [
  { v: 7, l: "una settimana" },
  { v: 14, l: "2 settimane" },
  { v: 21, l: "3 settimane" },
  { v: 28, l: "un mese" },
  { v: 60, l: "2 mesi" },
  { v: 90, l: "3 mesi" },
  { v: 120, l: "4 mesi" },
  { v: 150, l: "5 mesi" },
  { v: 180, l: "6 mesi" },
  { v: 210, l: "7 mesi" },
  { v: 240, l: "8 mesi" },
  { v: 270, l: "9 mesi" },
  { v: 300, l: "10 mesi" },
  { v: 330, l: "11 mesi" },
  { v: 360, l: "12 mesi" },
];

function IntervalloSelector({
  label,
  value,
  onChange,
  isUtente = false,
  disabled,
}) {
  const value2 = value === null ? "default" : value === -1 ? "disabled" : value;

  return (
    <FormControl variant="standard" style={{ minWidth: 350 }}>
      <InputLabel style={{ position: "relative", marginBottom: -4 }}>
        {label}
      </InputLabel>

      <Select
        disabled={disabled}
        value={value2}
        onChange={(e) => {
          const v = e.target.value;
          let n;
          if (v === "disabled") {
            n = -1;
          } else if (v === "default") {
            n = null;
          } else {
            n = parseInt(v as string, 10);
          }
          onChange(n);
        }}
        MenuProps={{
          style: {
            zIndex: 10002,
          },
        }}
        style={{
          marginTop: 4,
        }}
      >
        <MenuItem value="disabled">
          <em>Disabilita</em>
        </MenuItem>
        <MenuItem value="default">
          <em>
            {"Default" +
              (isUtente
                ? " (" + defaultIntervalloPromemoriaRicontatto + " giorni)"
                : "")}
          </em>
        </MenuItem>

        {IntervalloSelectorValues.map((p) => {
          return (
            <MenuItem key={p.v} value={p.v}>
              {p.l}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
