import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DateInput from "app/components/common/DateInput";
import {
  getInterazioniCliente as getInterazioniClienteAction,
  editInterazioniCliente as editInterazioniClienteAction,
  deleteInterazioniCliente as deleteInterazioniClienteAction,
  createInterazioniCliente as createInterazioniClienteAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import UserPicker from "app/components/common/UserPicker";
import ClienteMandatoPicker from "app/components/common/ClienteMandatoPicker";
import RichTextEditor from "app/components/common/RichTextEditor";
import { useUser } from "app/hooks/useUser";
import { Tooltip, IconButton, Dialog, Typography } from "@material-ui/core";
import { ColoredButton } from "app/elements/ColoredButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useToast } from "app/hooks/useToast";
import moment from "moment";
import { PromemoriaRicontattoSettingsButton } from "../EventoAgenda/PromemoriaRicontattoSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& input": {
      color: "rgba(0, 0, 0, 0.77) !important",
    },
  },
  table: {
    minWidth: 650,
    "& .editor-with-toolbar .editor": {
      margin: 0,
    },
  },
  dataCell: {
    width: 200,
    [theme.breakpoints.up(1500)]: {
      width: 400,
    },
  },
  dataCellContent: {
    [theme.breakpoints.up(1500)]: {
      display: "flex",
      flexDirection: "row",
      "& > *": {
        marginRight: 5,
      },
      "& > :nth(0)": {
        marginRight: 0,
      },
    },
  },
}));

export default function ClienteInterazioni({
  clienteId,
  idMandato = null,
  aziendaSource,
  onLoadSuccess,
  onEditSuccess,
  onCreateSuccess,
  onDeleteSuccess,
}) {
  const showToast = useToast();
  const classes = useStyles();

  const user = useUser();
  const userId = user && user.id;

  const interazioni = useDataSource({
    initialData: [],
    itemId: clienteId,
    loadAction: getInterazioniClienteAction,
    onLoadSuccess: () => {
      if (onLoadSuccess) {
        setTimeout(onLoadSuccess, 10);
      }
    },
    onLoadError: () => {
      showToast("Errore durante il caricamento delle interazioni cliente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const [deletingRowSource, setDeletingRowSource] = useState(null);

  const readOnlyOrSaving = false;

  const showMandatoSelector = !idMandato;

  const dataToShow = (interazioni.data || []).filter((i) => {
    if (idMandato) {
      return i.idMandato === idMandato;
    }
    return true;
  });

  return (
    <div key={clienteId} className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "0.75em",
        }}
      >
        <ColoredButton
          style={{
            marginRight: 8,
          }}
          disabled={false}
          onClick={(event) => {
            const newI = {
              _isActive: true,
              idUtente: userId,
              idAzienda: clienteId,
            };

            if (idMandato) {
              newI.idMandato = idMandato;
            }

            interazioni.create(newI, { first: true });
          }}
          color="secondary"
          endIcon={null}
          fullWidth={false}
          size="small"
          startIcon={<AddIcon />}
          variant="outlined"
        >
          Aggiungi
        </ColoredButton>
        <div style={{ marginLeft: "auto" }}>
          {aziendaSource && (
            <PromemoriaRicontattoSettingsButton
              azienda={aziendaSource.data}
              utente={aziendaSource.getValue("commerciale")}
              onSettingsSaved={() => {
                aziendaSource.load();
              }}
            />
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }} className={classes.dataCell}>
                Data
              </TableCell>
              <TableCell style={{ width: 300 }}>Descrizione</TableCell>
              <TableCell style={{ width: 250 }}>
                {"Utente"}
                {showMandatoSelector && "/Mandato"}
              </TableCell>
              <TableCell style={{ width: 100 }}>Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.map((row) => (
              <InterazioneRow
                key={row.id}
                readOnly={readOnlyOrSaving}
                data={row}
                source={interazioni}
                userId={userId}
                setDeletingRowSource={setDeletingRowSource}
                classes={classes}
                clienteId={clienteId}
                showMandatoSelector={showMandatoSelector}
                onEditSuccess={onEditSuccess}
                onCreateSuccess={onCreateSuccess}
                onDeleteSuccess={onDeleteSuccess}
              />
            ))}
            {dataToShow.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography
                    align="center"
                    variant="body1"
                    style={{ color: "gray" }}
                  >
                    <em>Nessuna interazione</em>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog maxWidth="md" open={deletingRowSource}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {deletingRowSource
              ? "Sei sicuro di voler eliminare questa interazione?"
              : null}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={(event) => {
              setDeletingRowSource(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={() => {
              deletingRowSource.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function InterazioneRow({
  readOnly,
  data,
  source,
  setDeletingRowSource,
  classes,
  clienteId,
  showMandatoSelector,
  onEditSuccess,
  onCreateSuccess,
  onDeleteSuccess,
}) {
  const showToast = useToast();

  const interazione = useDataSource({
    parent: source,
    pathInParent: [{ id: data.id }],
    itemId: data.id,
    initialData: {},
    createAction: createInterazioniClienteAction,
    onCreateSuccess: (response) => {
      setDeletingRowSource(null);
      showToast("Interazione creata con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      onCreateSuccess?.(response.data);
    },
    editAction: editInterazioniClienteAction,
    onEditSuccess: (response) => {
      setDeletingRowSource(null);
      showToast("Interazione salvata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      onEditSuccess?.(response.data);
    },
    onEditError: (response) => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    deleteAction: deleteInterazioniClienteAction,
    onDeleteSuccess: (response) => {
      setDeletingRowSource(null);
      showToast("Interazione eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      onDeleteSuccess?.();
    },
    onDeleteError: (response) => {
      showToast("Errore durante l'eliminazione dell'interazione", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const readOnlyOrSaving = readOnly || !interazione.getValue("_isActive");

  return (
    <TableRow>
      <TableCell>
        <div className={classes.dataCellContent}>
          <DateInput
            className=""
            label="Data programmata"
            inputVariant="outlined"
            fullWidth={true}
            size="small"
            variant="outlined"
            disabled={readOnlyOrSaving}
            source={interazione}
            sourceKey="dataProgrammata"
          />
          <DateInput
            className=""
            label="Data effettiva"
            inputVariant="outlined"
            fullWidth={true}
            size="small"
            variant="outlined"
            disabled={readOnlyOrSaving}
            source={interazione}
            sourceKey="dataEffettiva"
            maxDate={moment().endOf("day")}
          />
        </div>
      </TableCell>
      <TableCell>
        <RichTextEditor
          __to_reload={interazione.getValue("descrizione")}
          source={interazione}
          sourceKey="descrizione"
          readOnly={readOnlyOrSaving}
          label={null}
        />
      </TableCell>
      <TableCell align="right">
        <UserPicker
          variant="outlined"
          readOnly={readOnlyOrSaving}
          source={interazione}
          sourceKey="idUtente"
          label="Utente"
        />

        {showMandatoSelector && (
          <ClienteMandatoPicker
            variant="outlined"
            readOnly={readOnlyOrSaving}
            source={interazione}
            sourceKey="idMandato"
            clienteId={clienteId}
            label="Mandato"
          />
        )}
      </TableCell>
      <TableCell>
        <div
          style={{
            maxWidth: 200,
            display: "flex",
            justifyContent: "end",
          }}
        >
          {interazione.getValue("_isActive") ? (
            <>
              <Tooltip title="Salva le modifiche">
                <IconButton
                  onClick={(event) => {
                    interazione.commit();
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Annulla le modifiche">
                <IconButton
                  onClick={(event) => {
                    if (interazione.getValue("__isNew")) {
                      source.delete({
                        tempId: data.id,
                      });
                    } else {
                      interazione.cancel();
                      // reload()
                    }
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Modifica">
                <IconButton
                  onClick={(event) => {
                    interazione.changeValue("_isActive", true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Elimina">
                <IconButton
                  onClick={(event) => {
                    setDeletingRowSource(interazione);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
