import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css"; // TODO: move elsewhere
import { useDataTreeContext } from "app/contexts/DataTreeContext";
import theme from "./theme/index";

export function DataTreeContent() {
  const { treeData, setTreeData, maxDepth, messages, canDrag, canDrop } =
    useDataTreeContext();

  /* TODO: disattivato virtualized temporaneamente, c'e' un incompatibilita' con React 17
   *  se il bug non viene risolto velocemente, qui c'e' una soluzione che usa una patch:
   *  https://github.com/frontend-collective/react-sortable-tree/issues/821
   */
  return (
    <div>
      {treeData.length > 0 ? (
        <SortableTree
          treeData={treeData}
          onChange={setTreeData}
          canDrag={canDrag}
          canDrop={canDrop}
          isVirtualized={false}
          theme={theme}
          maxDepth={maxDepth}
        />
      ) : (
        <div style={{ width: "100%", textAlign: "center", color: "gray" }}>
          <em>{messages.noData || "NO DATA!"}</em>
        </div>
      )}
    </div>
  );
}
