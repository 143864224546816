import React, { useMemo, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import { UncommittedChangesConfirmationWrapper } from "../../contexts/UncommittedChangesConfirmationContext";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  container: {
    width: 800,
    maxWidth: "calc(100vw - 200px)",
    padding: "4px 6px",
    overflowX: "auto",
    "& > *": {
      maxWidth: "100%",
    },
  },
  drawer: {
    width: 0,
  },
  backdrop: {
    display: "none",
  },
  transparentBackdrop: {
    backgroundColor: "transparent",
  },
  closeContainer: {
    position: "fixed",
    top: 0,
    marginLeft: -40,
    width: 40,
    background: "white",
    zIndex: 10000000,
  },
});

const confirmationMessage =
  "Attenzione, ci sono delle modifiche non salvate. Sei sicuro di voler uscire dalla pagina?";

export default function useSideModal() {
  const classes = useStyles();
  const [info, setInfo] = useState(null);
  const [shouldAskConfirmation, setShouldAskConfirmation] = useState(false);

  function askConfirmationIfNeeded() {
    if (shouldAskConfirmation) {
      return window.confirm(confirmationMessage);
    }
    return true;
  }

  const closeDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!askConfirmationIfNeeded()) {
      return;
    }
    setInfo(null);
  };

  const openSideModal = useMemo(() => {
    return (Component, props, key = "") => {
      setInfo({
        Component,
        props,
        key,
      });
    };
  }, [setInfo]);

  let sideModalContent = null;
  if (info) {
    sideModalContent = (
      <info.Component key={info.key} isSideModal {...(info.props || {})} />
    );
  }

  const sideModal = (
    <Drawer
      anchor="right"
      open={!!sideModalContent}
      onClose={closeDrawer}
      className={classes.drawer}
      ModalProps={{ disableEnforceFocus: true }}
      BackdropProps={
        shouldAskConfirmation
          ? { className: classes.transparentBackdrop }
          : {
              invisible: true,
              className: classes.backdrop,
            }
      }
    >
      <Paper className={classes.container}>
        <div className={classes.closeContainer}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <UncommittedChangesConfirmationWrapper
          confirmationMessage={confirmationMessage}
          shouldAskConfirmation={shouldAskConfirmation}
          setShouldAskConfirmation={setShouldAskConfirmation}
        >
          {sideModalContent}
        </UncommittedChangesConfirmationWrapper>
      </Paper>
    </Drawer>
  );

  return { openSideModal, sideModal, sideModalInfo: info };
}
