import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import capitalize from "lodash/capitalize";
import cx from "clsx";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import type { Omit } from "@material-ui/types";

export type ColorTypes =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | "default"
  | "inherit"
  | "info";

type ColoredButtonProps = { color?: ColorTypes } & Omit<
  MuiButtonProps,
  "color"
>;

const useStyles = makeStyles((theme) =>
  createStyles({
    outlinedSuccess: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    outlinedError: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    outlinedWarning: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
    outlinedInfo: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.success.dark,
      },
    },
    containedError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    containedWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    containedInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.info.dark,
      },
    },
  })
);

const allowedColors = ["default", "inherit", "primary", "secondary"] as const;

export const ColoredButton: React.FC<ColoredButtonProps> = ({
  children,
  color,
  className,
  ...props
}) => {
  const classes = useStyles();
  const colorProp =
    allowedColors.indexOf(color as (typeof allowedColors)[number]) > -1
      ? (color as (typeof allowedColors)[number])
      : undefined;

  let colorClassName = null;
  if (color && !colorProp && (!props.variant || props.variant !== "text")) {
    colorClassName = classes[`${props.variant}${capitalize(color)}`];
    if (process.env.NODE_ENV !== "production") {
      if (!colorClassName) {
        console.error("ColoredButton unknown color: " + color);
      }
    }
  }

  return (
    <MuiButton
      {...props}
      color={colorProp}
      className={cx(className, colorClassName)}
    >
      {children}
    </MuiButton>
  );
};

ColoredButton.displayName = "ColoredButton";
