import React, { useState } from "react";
import { upperFirst } from "lodash";
import LabelledOutline from "app/components/common/LabelledOutline";
import { FilePicker } from "app/elements/FilePicker";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useToast } from "app/hooks/useToast";

type FileBoxProps = {
  source: any;
  sourceKey: string;
  title?: string;
  readOnly?: boolean;
  onFileRemoved?: () => void;
  onFileAdded?: () => void;
};

export default function FileBox({
  readOnly = false,
  onFileRemoved = () => {},
  onFileAdded = () => {},
  ...props
}: FileBoxProps) {
  const showToast = useToast();

  const fileObj = props.source.getValue(props.sourceKey, null);

  const hasFile = fileObj && fileObj.versioneCorrente;

  const [uploadState, setUploadState] = useState<
    null | "uploading" | "success" | "error"
  >(null);

  const uploadFile = async (file) => {
    setUploadState("uploading");

    const form = new FormData();

    form.append("file", file);

    const url = "/api/files" + (fileObj ? "/" + fileObj.id : "");

    const onFileUploaded = (fileObj) => {
      props.source.changeValue(props.sourceKey, fileObj);

      props.source.changeValue(
        "fileId" + upperFirst(props.sourceKey),
        fileObj.id
      );

      onFileAdded();
    };

    const options = {
      method: fileObj ? "POST" : "PUT",
      body: form,
    };

    try {
      const r = await fetch(url, options);
      if (r.ok) {
        setUploadState("success");
        const json = await r.json();
        onFileUploaded(json.data);
      } else {
        handleUploadError(r.status);
      }
    } catch (e) {
      handleUploadError();
    }
  };

  function handleUploadError(status?: number | undefined) {
    setUploadState("error");
    const msg =
      status === 413
        ? "Errore: il file è troppo grande"
        : "Errore durante il caricamento del file";
    showToast(msg, {
      color: "error",
      horizontal: "left",
      vertical: "bottom",
    });
  }

  const sourceKeyForError = "fileId" + upperFirst(props.sourceKey);

  const sourceError =
    props.source.getValidationErrors(sourceKeyForError)[0] || null;
  return (
    <LabelledOutline label={props.title} error={sourceError}>
      <FilePicker
        style={{
          display: "flex",
          width: "100%",
        }}
        content={(openFilePicker) => {
          return (
            <div
              style={{
                paddingBottom: 8,
                flex: 1,
                display: "flex",
              }}
            >
              {hasFile ? (
                uploadState === "uploading" ? (
                  <UploadingFile />
                ) : (
                  <FileDownloadAndActions
                    fileObj={fileObj}
                    canReplaceAndRemove={!readOnly}
                    openFilePicker={openFilePicker}
                    onRemoveFile={() => {
                      props.source.changeValue(props.sourceKey, {
                        ...fileObj,
                        versioneCorrente: null,
                        idVersioneCorrente: null,
                      });
                      onFileRemoved();
                    }}
                  />
                )
              ) : readOnly ? (
                <NoFile />
              ) : uploadState === "uploading" ? (
                <UploadingFile />
              ) : (
                <SelectFile openFilePicker={openFilePicker} />
              )}{" "}
            </div>
          );
        }}
        onFiles={(files) => {
          uploadFile(files[0]);
        }}
        readOnly={uploadState === "uploading"}
      />
    </LabelledOutline>
  );
}

function UploadingFile() {
  return (
    <>
      <CircularProgress
        style={{
          margin: 12,
        }}
        size={24}
      />

      <div
        style={{
          flex: 1,
          alignSelf: "center",
        }}
      >
        Upload in corso ...
      </div>
    </>
  );
}

function FileDownloadAndActions({
  fileObj,
  canReplaceAndRemove,
  openFilePicker,
  onRemoveFile,
}: {
  fileObj: any;
  canReplaceAndRemove: boolean;
  openFilePicker: () => void;
  onRemoveFile: () => void;
}) {
  return (
    <>
      <a
        style={{
          display: "flex",
          flex: 1,
          color: "inherit",
        }}
        href={"/api/files/" + fileObj.id}
        download={fileObj.versioneCorrente.nome}
      >
        <Tooltip title="Scarica file">
          <IconButton
            style={{
              marginRight: 6,
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>

        <div
          style={{
            flex: 1,
            alignSelf: "center",
            wordBreak: "break-all",
          }}
        >
          {fileObj.versioneCorrente.nome}
        </div>
      </a>

      <div>
        {canReplaceAndRemove && (
          <>
            <Tooltip title="Sostituisci file">
              <IconButton
                onClick={() => {
                  openFilePicker();
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Elimina file">
              <IconButton onClick={onRemoveFile}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}{" "}
      </div>
    </>
  );
}

function NoFile() {
  return (
    <div
      style={{
        flex: 1,
        alignSelf: "center",
      }}
    >
      <IconButton
        style={{
          marginRight: 6,
          pointerEvents: "none",
        }}
      >
        <CloudOffIcon />
      </IconButton>
      Nessun file
    </div>
  );
}

function SelectFile({ openFilePicker }: { openFilePicker: () => void }) {
  return (
    <>
      <Tooltip title="Carica file">
        <IconButton
          style={{
            marginRight: 6,
          }}
          onClick={() => {
            openFilePicker();
          }}
        >
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>

      <div
        style={{
          flex: 1,
          alignSelf: "center",
        }}
      >
        Carica file
      </div>
    </>
  );
}
