import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DateInput from "app/components/common/DateInput";
import {
  getInterazioniContatto as getInterazioniContattoAction,
  editInterazioniContatto as editInterazioniContattoAction,
  deleteInterazioniContatto as deleteInterazioniContattoAction,
  createInterazioniContatto as createInterazioniContattoAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import UserPicker from "app/components/common/UserPicker";
import RichTextEditor from "app/components/common/RichTextEditor";
import { useUser } from "app/hooks/useUser";
import { Tooltip, IconButton, Dialog, Typography } from "@material-ui/core";
import { ColoredButton } from "app/elements/ColoredButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useToast } from "app/hooks/useToast";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& input": {
      color: "rgba(0, 0, 0, 0.77) !important",
    },
  },
  table: {
    minWidth: 650,
    "& .editor-with-toolbar .editor": {
      margin: 0,
    },
  },
  dataCell: {
    width: 200,
    [theme.breakpoints.up(1500)]: {
      width: 400,
    },
  },
  dataCellContent: {
    [theme.breakpoints.up(1500)]: {
      display: "flex",
      flexDirection: "row",
      "& > *": {
        marginRight: 5,
      },
      "& > :nth(0)": {
        marginRight: 0,
      },
    },
  },
}));

export default function ContattoInterazioni({ contattoId }) {
  const showToast = useToast();
  const classes = useStyles();

  const user = useUser();
  const userId = user && user.id;

  const interazioni = useDataSource({
    initialData: [],
    itemId: contattoId,
    loadAction: getInterazioniContattoAction,
    onLoadError: () => {
      showToast("Errore durante il caricamento delle interazioni", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const [deletingRowSource, setDeletingRowSource] = useState(null);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "0.75em",
        }}
      >
        <ColoredButton
          style={{
            marginRight: 8,
          }}
          disabled={false}
          onClick={(event) => {
            const newI = {
              _isActive: true,
              idUtente: userId,
              idContatto: contattoId,
            };
            interazioni.create(newI, { first: true });
          }}
          color="secondary"
          endIcon={null}
          fullWidth={false}
          size="small"
          startIcon={<AddIcon />}
          variant="outlined"
        >
          Aggiungi
        </ColoredButton>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }} className={classes.dataCell}>
                Data
              </TableCell>
              <TableCell style={{ width: 300 }}>Descrizione</TableCell>
              <TableCell style={{ width: 250 }}>Utente</TableCell>
              <TableCell style={{ width: 100 }}>Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interazioni.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography
                    align="center"
                    variant="body1"
                    style={{ color: "gray" }}
                  >
                    <em>Nessuna interazione</em>
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              interazioni.data.map((row) => (
                <InterazioneRow
                  key={row.id}
                  data={row}
                  source={interazioni}
                  userId={userId}
                  setDeletingRowSource={setDeletingRowSource}
                  classes={classes}
                  contattoId={contattoId}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog maxWidth="md" open={deletingRowSource}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {deletingRowSource
              ? "Sei sicuro di voler eliminare questa interazione?"
              : null}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={() => {
              setDeletingRowSource(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={() => {
              deletingRowSource.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function InterazioneRow({ data, source, setDeletingRowSource, classes }) {
  const showToast = useToast();

  const interazione = useDataSource({
    parent: source,
    pathInParent: [{ id: data.id }],
    itemId: data.id,
    initialData: {},
    createAction: createInterazioniContattoAction,
    onCreateSuccess: () => {
      setDeletingRowSource(null);
      showToast("Interazione creata con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editInterazioniContattoAction,
    onEditSuccess: () => {
      setDeletingRowSource(null);
      showToast("Interazione salvata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onEditError: () => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    deleteAction: deleteInterazioniContattoAction,
    onDeleteSuccess: () => {
      setDeletingRowSource(null);
      showToast("Interazione eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onDeleteError: () => {
      showToast("Errore durante l'eliminazione dell'interazione", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const readOnlyOrSaving = !interazione.getValue("_isActive");

  return (
    <TableRow>
      <TableCell>
        <div className={classes.dataCellContent}>
          <DateInput
            label="Data programmata"
            inputVariant="outlined"
            fullWidth
            size="small"
            variant="outlined"
            disabled={readOnlyOrSaving}
            source={interazione}
            sourceKey="dataProgrammata"
          />
          <DateInput
            label="Data effettiva"
            inputVariant="outlined"
            fullWidth
            size="small"
            variant="outlined"
            disabled={readOnlyOrSaving}
            source={interazione}
            sourceKey="dataEffettiva"
            maxDate={moment().endOf("day")}
          />
        </div>
      </TableCell>
      <TableCell>
        <RichTextEditor
          __to_reload={interazione.getValue("descrizione")}
          source={interazione}
          sourceKey="descrizione"
          readOnly={readOnlyOrSaving}
          label={null}
        />
      </TableCell>
      <TableCell align="right">
        <UserPicker
          variant="outlined"
          readOnly={readOnlyOrSaving}
          source={interazione}
          sourceKey="idUtente"
          label="Utente"
        />
      </TableCell>
      <TableCell>
        <div
          style={{
            maxWidth: 200,
            display: "flex",
            justifyContent: "end",
          }}
        >
          {interazione.getValue("_isActive") ? (
            <>
              <Tooltip title="Salva le modifiche">
                <IconButton
                  onClick={() => {
                    interazione.commit();
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Annulla le modifiche">
                <IconButton
                  onClick={(event) => {
                    if (interazione.getValue("__isNew")) {
                      source.delete({
                        tempId: data.id,
                      });
                    } else {
                      interazione.cancel();
                      // reload()
                    }
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Modifica">
                <IconButton
                  onClick={(event) => {
                    interazione.changeValue("_isActive", true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Elimina">
                <IconButton
                  onClick={(event) => {
                    setDeletingRowSource(interazione);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
