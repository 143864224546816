import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFattureDaApprovare as getFattureDaApprovareAction } from "app/actions";
import FattureTable_abstract from "./FattureTable_abstract";

export default function FattureDaApprovareTable(props) {
  const fatture = useDataSource({
    loadAction: getFattureDaApprovareAction,
    initialData: [],
  });

  return (
    <FattureTable_abstract
      title="Fatture da approvare"
      fatture={fatture}
      isApprovare={true}
      exportFilename="Fatture da approvare"
    />
  );
}
