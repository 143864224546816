import React from 'react'
import { ShowOnlyEmpty } from './utils'

// const min = Infinity
// const max = -Infinity

// function parseValue (v, isMin) {
//   if (v && typeof v === 'string') {
//     return v
//   }
//   return isMin ? min : max
// }

export default function DateFilter ({
  classes,
  filterOptions,
  setFilter,
  messages
}) {
  // const minValue = filterOptions && typeof filterOptions.min === 'string' ? filterOptions.min : min
  // const maxValue = filterOptions && typeof filterOptions.max === 'string' ? filterOptions.max : max

  // const onChangeDateTimeRange = (values) => {
  //   const _minValue = parseValue(values[0], true)
  //   const _maxValue = parseValue(values[1], false)
  //   if (_minValue === min && _maxValue === max) {
  //     setFilter(null)
  //   } else {
  //     setFilter({
  //       type: 'datetime-range',
  //       min: _minValue,
  //       max: _maxValue
  //     })
  //   }
  // }

  return (
    <>
      <div>
      {/* <div className={classes.dualInputContainer}> */}
        {/* //TODO: date filter */}
        {/* <DataTypeInput
          value={minValue}
          onChange={value => onChangeDateTimeRange([value, maxValue])}
          field={field}
          className={classes.dualInput}
          placeholder={null}
        />
        <span>{' - '}</span>
        <DataTypeInput
          value={maxValue}
          onChange={value => onChangeDateTimeRange([minValue, value])}
          field={field}
          className={classes.dualInput}
          placeholder={null}
        /> */}
      </div>
      {/* <ShowOnlyEmpty filterOptions={filterOptions} setFilter={setFilter} messages={messages} /> */}
    </>
  )
}
