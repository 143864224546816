import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import TabIcon from "@material-ui/icons/Tab";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EditIcon from "@material-ui/icons/Edit";

export default function TableActionButtons(props) {
  const {
    link,
    openSideModal,
    canDelete,
    deleteItem,
    canDuplicate,
    duplicateItem,
    canEstendiLinea,
    estendiLinea,
    canEdit,
    editItem,
  } = props;

  return (
    <div>
      {link && (
        <Tooltip title="Apri">
          <IconButton to={link} component={Link}>
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      )}

      {openSideModal && (
        <Tooltip title="Anteprima">
          <IconButton
            onClick={(event) => {
              openSideModal();
            }}
          >
            <TabIcon />
          </IconButton>
        </Tooltip>
      )}

      {deleteItem && (
        <Tooltip title="Elimina">
          <span>
            <IconButton
              disabled={!canDelete}
              onClick={(event) => {
                canDelete && deleteItem();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {duplicateItem && (
        <Tooltip title="Duplica">
          <span>
            <IconButton
              disabled={!canDuplicate}
              onClick={(event) => {
                if (canDuplicate) {
                  const ok = window.confirm("Conferma duplicazione?");
                  if (ok) {
                    duplicateItem();
                  }
                }
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {estendiLinea && (
        <Tooltip title="Estendi">
          <span>
            <IconButton
              disabled={!canEstendiLinea}
              onClick={(event) => {
                if (canEstendiLinea) {
                  estendiLinea();
                }
              }}
            >
              <DateRangeIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {editItem && (
        <Tooltip title="Modifica">
          <span>
            <IconButton
              disabled={!canEdit}
              onClick={(event) => {
                canEdit && editItem();
              }}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
}
