import React from "react";
import { Typography } from "@material-ui/core";

export default function StatoContattoBadge(props) {
  let color = null;

  if (props.stato === "Cliente") {
    color = "#4caf50";
  } else if (props.stato === "Lead") {
    color = "#e57373";
  } else if (props.stato === "Contatto") {
    color = "#ff9800";
  }
  return (
    <Typography
      style={{
        background: color,
        border: "1px solid transparent",
        borderRadius: 4,
        padding: "3px 5px",
        margin: "8px 8px",
      }}
      align="inherit"
      color="inherit"
      display="inline"
      gutterBottom={true}
      noWrap={false}
      paragraph={false}
      variant="caption"
    >
      {props.stato}{" "}
    </Typography>
  );
}
