import { ShowOnlyEmptyColumnFilter } from "./types";

export function applyShowOnlyEmptyFilter(
  row,
  value,
  options: ShowOnlyEmptyColumnFilter
) {
  const hide = (options as any).hide;
  const isEmpty =
    value === null ||
    typeof value === "undefined" ||
    (typeof value === "string" && value === "");
  return hide ? !isEmpty : isEmpty;
}
