import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  getNewsPerUtente as getNewsPerUtenteAction,
  markNewsAsRead as markNewsAsReadAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { useToast } from "app/hooks/useToast";
import { Alert, AlertTitle } from "@material-ui/lab";
import RichTextEditor from "app/components/common/RichTextEditor";
import Collapse from "@material-ui/core/Collapse";
import { useApi } from "app/hooks/useApi";

const commonRootStyle = {
  overflowY: "auto",
  maxHeight: "30vh",
};

const useStyles = makeStyles((theme) => ({
  root: {
    ...commonRootStyle,
    position: "fixed",
    bottom: 0,
    left: 240,
    right: 0,
    background: "#fafafa",
    zIndex: 1,
  },
  rootPlaceholder: {
    ...commonRootStyle,
    visibility: "hidden",
    width: "100%",
  },

  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  newsContainer: {
    width: "100%",
    padding: 8,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },

  newsDescriptionContainer: {
    marginLeft: -10,
    marginRight: -10,
    marginTop: -5,
    marginBottom: -10,
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .editor-with-toolbar .editor": {
      marginBottom: 0,
      background: "unset",
      border: "none",
    },
  },
}));

export default function NewsEditor({ clienteId, idMandato = null }) {
  const showToast = useToast();
  const classes = useStyles();

  const [hidden, setHidden] = useState({});

  const news = useDataSource({
    initialData: [],
    loadAction: getNewsPerUtenteAction,
    onLoadError: (response) => {
      showToast("Errore durante il caricamento delle news", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const markNewsAsReadApi = useApi(markNewsAsReadAction, {
    onError: () => {},
  });

  const dataToShow = news.data || [];

  if (dataToShow.length === 0) {
    return null;
  }

  const content = (
    <Paper className={classes.paper}>
      <div className={classes.newsContainer}>
        {dataToShow.map((news) => {
          const isOpen = !hidden[news.id];

          const otherProps = {};

          if (news.canMarkAsRead) {
            otherProps.onClose = () => {
              setHidden({ ...hidden, [news.id]: true });
              markNewsAsReadApi.callApi(news.id, {});
            };
          }

          return (
            <Collapse in={isOpen}>
              <Alert color="info" {...otherProps}>
                <AlertTitle>{news.titolo}</AlertTitle>
                <div className={classes.newsDescriptionContainer}>
                  <RichTextEditor
                    readOnly={true}
                    readonlyValue={news.descrizione}
                    label={null}
                  />
                </div>
              </Alert>
            </Collapse>
          );
        })}
      </div>
    </Paper>
  );

  return (
    <>
      <div className={classes.rootPlaceholder}>{content}</div>
      <div className={classes.root}>{content}</div>
    </>
  );
}
