import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'

export function DataTableContainer ({ children }) {
  return (
    <TableContainer component={Paper}>
      {children}
    </TableContainer>
  )
}
