import React from "react";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
  DataTableContextType,
  useDataTableContext,
} from "app/contexts/DataTableContext";
import {
  ExportColumns,
  ExportRows,
  ExportType,
  exportValues,
} from "./export/exportValues";

type ExportKey =
  | "excel"
  | "excel-filter"
  | "csv"
  | "csv-filter"
  | "pdf"
  | "pdf-filter";

function getExportTypeFromExportKey(key: ExportKey): ExportType {
  switch (key) {
    case "excel":
    case "csv":
    case "pdf":
      return key;
    case "excel-filter":
      return "excel";
    case "csv-filter":
      return "csv";
    case "pdf-filter":
      return "pdf";
  }
}

const options: { key: ExportKey; label: string }[] = [
  {
    label: "Excel",
    key: "excel",
  },
  {
    label: "CSV",
    key: "csv",
  },
  {
    label: "PDF",
    key: "pdf",
  },
  {
    label: "Excel (righe filtrate)",
    key: "excel-filter",
  },
  {
    label: "CSV (righe filtrate)",
    key: "csv-filter",
  },
  {
    label: "PDF (righe filtrate)",
    key: "pdf-filter",
  },
];

export const DataTableExportButtons = (props: { filename: string }) => {
  const context: any = useDataTableContext();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    key: ExportKey
  ) => {
    exportData(key, context, props.filename);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        variant="outlined"
        size="small"
      >
        Esporta
        <ArrowDropDownIcon style={{ fontSize: "20px" }} />
      </Button>
      <Popper
        style={{
          zIndex: 1000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      disabled={false}
                      onClick={(event) =>
                        handleMenuItemClick(event, option.key)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

function exportData(
  key: ExportKey,
  context: DataTableContextType,
  filename: string
) {
  const { source, columns, rowSourceOptions, sourceKey } = context;

  const rows =
    key.indexOf("-filter") > 0 ? context.filteredRows : context.allRows;

  const getRowSource = (row) => {
    const rowSource = source.getChildDataSource(
      sourceKey ? [sourceKey, { id: row.id }] : [{ id: row.id }],
      {
        itemId: row.id,
        initialData: {},
        ...rowSourceOptions,
      }
    );
    return rowSource;
  };

  const data: ExportRows = [];
  const exportColumns: ExportColumns = columns.filter((column) => {
    if (column.hidden || column.hiddenInExport) {
      return false;
    }
    return true;
  });

  rows.forEach((row, rowIndex) => {
    const rowData: any[] = [];
    exportColumns.forEach((column, colIndex) => {
      if (column.hidden || column.hiddenInExport) {
        return;
      }
      const rowSource = getRowSource(row);
      const value = rowSource.getValue(column.path);
      const cellContent = column.renderRawValue
        ? column.renderRawValue(value, row, rowIndex, rowSource)
        : column.renderCell
        ? column.renderCell(value, row, rowIndex, rowSource)
        : value;

      rowData.push(cellContent);
    });
    data.push(rowData);
  });

  // console.log({ key, exportColumns, rows });
  // console.log("result", data);

  exportValues(
    data,
    exportColumns,
    {
      filename: filename,
    },
    getExportTypeFromExportKey(key)
  );
}
