import React, { useRef } from "react";

export const FilePicker = ({ style, content, onFiles, readOnly }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function openFilePicker() {
    if (fileInputRef.current && !readOnly) {
      fileInputRef.current.click();
    }
  }

  function handleFileFromFileInput(e) {
    onFiles(fileInputRef.current!.files);
    fileInputRef.current!.value = ""; // reset the input field so if you removed it you can re-add the same file
  }

  function handleFileDrop(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!readOnly) {
      const dt = e.dataTransfer;
      const files = dt.files;

      onFiles(files);
    }
  }

  function handleDrag(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleFileDrop}
      style={style}
    >
      {content ? content(openFilePicker) : null}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileFromFileInput}
      />
    </div>
  );
};
