import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { TableColumn } from "./types";
import { TableFilter } from "../TableFilter";
import {
  makeSetFilter,
  useDataTableContext,
} from "app/contexts/DataTableContext";
import { Button } from "@material-ui/core";

type DataTableFilterCellProps = {
  column: TableColumn;
  columnIndex: number;
};

export function DataTableFilterCell({
  column,
  columnIndex,
}: DataTableFilterCellProps) {
  const context = useDataTableContext();
  return (
    <TableCell
    // className={cellClass}
    // scope="col"
    // sortDirection={ariaSortDirection}
    // data-colindex={index}
    // data-tableid={tableId}
    // onMouseDown={closeTooltip}
    // {...otherProps}
    >
      {/* {options.sort && sort
        ? (
        <span className={classes.contentWrapper}>
          <Tooltip
            title={getTooltipTitle()}
            placement="bottom"
            open={sortTooltipOpen}
            onOpen={() => (dragging ? setSortTooltipOpen(false) : setSortTooltipOpen(true))}
            onClose={() => setSortTooltipOpen(false)}
            classes={{
              tooltip: classes.tooltip,
              popper: classes.mypopper,
            }}>
            <Button
              variant="text"
              onKeyUp={handleKeyboardSortInput}
              onClick={handleSortClick}
              className={classes.toolButton}
              data-testid={`headcol-${index}`}
              ref={isDraggingEnabled() ? dragRef : null}>
              <div className={classes.sortAction}>
                <div
                  className={clsx({
                    [classes.data]: true,
                    [classes.sortActive]: sortActive,
                    [classes.dragCursor]: isDraggingEnabled(),
                  })}> */}

      <TableFilter
        column={column}
        path={column.path}
        type={column.type}
        filterOptions={context.columnFilters[columnIndex]}
        setFilter={makeSetFilter(context, columnIndex)}
        messages={context.messages}
      />
      {/* </div>
                <div className={classes.sortAction}>
                  <TableSortLabel {...sortLabelProps} />
                </div>
              </div>
            </Button>
          </Tooltip>
          {hint && (
            <Tooltip title={hint}>
              <HelpIcon
                className={!sortActive ? classes.hintIconAlone : classes.hintIconWithSortIcon}
                fontSize="small"
              />
            </Tooltip>
          )}
        </span>
          )
        : (
        <div className={hint ? classes.sortAction : null} ref={isDraggingEnabled() ? dragRef : null}>
          {children}
          {hint && (
            <Tooltip
              title={hint}
              placement="bottom-end"
              open={hintTooltipOpen}
              onOpen={() => showHintTooltip()}
              onClose={() => setHintTooltipOpen(false)}
              classes={{
                tooltip: classes.tooltip,
                popper: classes.mypopper,
              }}
              enterDelay={300}>
              <HelpIcon className={classes.hintIconAlone} fontSize="small" />
            </Tooltip>
          )}
        </div>
          )} */}
    </TableCell>
  );
}

export function TableClearAllColumnFiltersCell() {
  const { columnFilters, setColumnFilters } = useDataTableContext();
  const hasFilters = Object.values(columnFilters).filter((f) => !!f).length > 0;
  return (
    <TableCell>
      {hasFilters && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setColumnFilters({})}
            style={{
              lineHeight: "15px",
            }}
          >
            Rimuovi filtri
          </Button>
        </div>
      )}
    </TableCell>
  );
}
