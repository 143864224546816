import { useUser } from './useUser'
import { userHasAllPermissions } from 'common/roles'

export function useCheckPermission () {
  const user = useUser()

  return function hasPermission (permission) {
    return userHasAllPermissions(user, Array.isArray(permission) ? permission : [permission])
  }
}
