import React, { useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useDialog } from "app/hooks/useDialog";
import { Container, IconButton } from "@material-ui/core";
import DialogCreaFatture from "app/components/Fatture/DialogCreaFatture";
import DialogEditPagamento from "app/components/Pagamenti/DialogEditPagamento";
import { DataTable } from "app/elements/DataTable";
import LaunchIcon from "@material-ui/icons/Launch";
import moment from "moment";
import PagamentoStato from "app/constants/PagamentoStato";
import BadgePagamentoStato from "app/components/common/BadgePagamentoStato";
import numeral from "numeral";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import BeneLabel from "../common/BeneLabel";

export default function PagamentiTable_abstract(props) {
  const hasPermission = useCheckPermission();

  const { pagamenti, showCreaFattura } = props;

  const newFatturaDialog = useDialog();

  const editPagamentoDialog = useDialog();

  const [pagamentoSelezionato, setPagamentoSelezionato] = useState(null);

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_pagamento"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters
    >
      {showCreaFattura ? (
        <DialogCreaFatture
          dialog={newFatturaDialog}
          pagamentiArray={pagamenti.data}
          onDone={() => {
            pagamenti.load();
          }}
        />
      ) : null}
      <DialogEditPagamento
        dialog={editPagamentoDialog}
        pagamento={pagamentoSelezionato}
        onDone={() => {
          pagamenti.load();
        }}
      />
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        {props.title}
      </h1>
      {pagamenti.loading ? (
        "Caricamento lista pagamenti ..."
      ) : pagamenti.loadError ? (
        "Errore durante il caricamento della lista pagamenti"
      ) : (
        <DataTable
          source={pagamenti}
          footerRows={2}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 50,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div>
                    {hasPermission("modifica_pagamento") ? (
                      <IconButton
                        onClick={(event) => {
                          setPagamentoSelezionato(row);

                          editPagamentoDialog.open();
                        }}
                      >
                        <LaunchIcon />
                      </IconButton>
                    ) : null}{" "}
                  </div>
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            {
              path: ["dataMaturazione"],
              type: "date",
              width: 120,
              header: "Data",
              renderCell: (value, row, rowIndex, rowSource) => {
                return value ? moment(value).format("DD/MM/YYYY") : "";
              },
              renderFooterColSpan: 4,
              renderFooterCell: (rowIndex, rows, source) => {
                return rowIndex === 0 ? "Subtotale Pagina" : "TOTALE";
              },
            },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              width: 170,
              type: "enum",
              options: PagamentoStato,
              renderCell: (value, row, rowIndex, rowSource) => {
                return <BadgePagamentoStato stato={value} />;
              },
              renderRawValue: (value) => {
                return (
                  PagamentoStato.find((stato) => stato.value === value)
                    ?.label ?? value
                );
              },
            },
            {
              path: ["procedura", "nome"],
              width: 200,
              header: "Mandato/Commessa",
              type: "text",
              renderCell: (value, row) =>
                value || row.mandatoCliente.mandato.nome,
              renderRawValue: (value, row) =>
                value || row.mandatoCliente.mandato.nome,
            },
            {
              path: ["mandatoCliente", "cliente", "ragioneSociale"],
              width: 200,
              header: "Cliente",
              type: "text",
            },
            {
              path: ["procedura", "mandato", "commerciale", "cognome"],
              width: 200,
              header: "Commerciale",
              type: "text",
            },
            {
              path: ["procedura", "operatore", "cognome"],
              width: 200,
              header: "Operatore",
              type: "text",
            },
            {
              path: ["procedura", "formatProcedura", "linea", "nome"],
              width: 230,
              header: "Linea",
              type: "text",
            },
            {
              path: [
                "mlMilestonePagamento",
                "milestonePagamento",
                "nome",
                "nome",
              ],
              width: 230,
              header: "Milestone di pagamento",
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                if (row.attivita?.bene) {
                  return (
                    <div>
                      {value}
                      <div>
                        <BeneLabel
                          bene={row.attivita.bene}
                          showIcon
                          style={{ fontSize: "0.8em" }}
                          fontWeight="normal"
                        />
                      </div>
                    </div>
                  );
                }
                return value;
              },
            },
            {
              path: [
                "procedura",
                "mandato",
                "mandatoClienti",
                {
                  idCliente: 2,
                },
                "terminiPagamento",
              ],
              width: 180,
              header: "Termini di pagamento",
              type: "text",
            },
            {
              path: ["importoPrevisto"],
              width: 230,
              disableColumnFilter: true,
              disableGlobalFilter: true,
              type: "number",
              header: "Importo previsionale",
              align: "right",
              renderCell: (value, row, rowIndex, rowSource) => {
                return typeof value === "number"
                  ? numeral(value).format("0,0.00") + " €"
                  : "";
              },
              renderRawValue: (value) => value,
              exportNumberFormat: { thousandSeparator: true, decimals: 2 },
              renderFooterCell: (rowIndex, rows, source) => {
                const array = (rowIndex === 0 ? rows : source.data) || [];

                const footerValue = array.reduce(
                  (s, x) => s + (x.importoPrevisto || 0),
                  0
                );

                return typeof footerValue === "number"
                  ? numeral(footerValue).format("0,0.00") + " €"
                  : "";
              },
            },
            {
              path: ["importoMaturato"],
              width: 230,
              disableColumnFilter: true,
              disableGlobalFilter: true,
              type: "number",
              header: "Importo maturato",
              align: "right",
              renderCell: (value, row, rowIndex, rowSource) => {
                return typeof value === "number"
                  ? numeral(value).format("0,0.00") + " €"
                  : "";
              },
              renderRawValue: (value) => value,
              exportNumberFormat: { thousandSeparator: true, decimals: 2 },
              renderFooterCell: (rowIndex, rows, source) => {
                const array = (rowIndex === 0 ? rows : source.data) || [];

                const footerValue = array.reduce(
                  (s, x) => s + (x.importoMaturato || 0),
                  0
                );

                return typeof footerValue === "number"
                  ? numeral(footerValue).format("0,0.00") + " €"
                  : "";
              },
            },
            {
              path: ["importoEffettivo"],
              width: 230,
              disableColumnFilter: true,
              disableGlobalFilter: true,
              type: "number",
              header: "Importo effettivo",
              align: "right",
              renderCell: (value, row, rowIndex, rowSource) => {
                return typeof value === "number"
                  ? numeral(value).format("0,0.00") + " €"
                  : "";
              },
              renderRawValue: (value) => value,
              exportNumberFormat: { thousandSeparator: true, decimals: 2 },
              renderFooterCell: (rowIndex, rows, source) => {
                const array = (rowIndex === 0 ? rows : source.data) || [];

                const footerValue = array.reduce(
                  (s, x) => s + (x.importoEffettivo || 0),
                  0
                );

                return typeof footerValue === "number"
                  ? numeral(footerValue).format("0,0.00") + " €"
                  : "";
              },
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              {showCreaFattura ? (
                hasPermission("crea_fattura") ? (
                  <ColoredButton
                    disabled={!pagamenti.data.length}
                    onClick={(event) => {
                      newFatturaDialog.open();
                    }}
                    color="default"
                    fullWidth={false}
                    size="small"
                    variant="outlined"
                  >
                    Crea Fatture
                  </ColoredButton>
                ) : null
              ) : null}{" "}
              <DataTableExportButtons filename={props.exportFilename} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessun pagamento",
          }}
        />
      )}{" "}
    </Container>
  );
}
