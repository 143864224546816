import {
  cloneDeep,
  dropRight,
  get,
  last,
  mapValues,
  PropertyPath,
  set,
  isNil,
} from "lodash";
import fpPullAt from "lodash/fp/pullAt";
import fpSet from "lodash/fp/set";
import { v4 as uuid } from "uuid";
import { useState } from "react";

const tempIdPrefix = "new-";

export function newTempId() {
  return tempIdPrefix + uuid();
}

export function isTempId(id: number | string) {
  return isNil(id) || (typeof id === "string" && id.startsWith(tempIdPrefix));
}

export function omitArray(object, path) {
  if (path.length === 0) {
    return null;
  }
  const parentPath = dropRight(path);
  const parent = nullablePathGet(object, parentPath as PropertyPath);

  if (!Array.isArray(parent)) {
    throw new Error("parent object is not an array");
  }

  // @ts-ignore:
  const modifiedParent = fpPullAt(last(path), parent);
  if (parentPath.length === 0) {
    return modifiedParent;
  } else {
    const copy = cloneDeep(object);
    set(copy, parentPath as PropertyPath, modifiedParent);
    return copy;
  }
}

export function recursivelyOmitTempId(data) {
  if (!data || typeof data !== "object") {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(recursivelyOmitTempId);
  }
  return mapValues(data, (value, key) => {
    if (key === "tempId" && isTempId(value)) {
      return undefined;
    }
    return recursivelyOmitTempId(value);
  });
}

export function nullablePathGet(obj, path) {
  if (path.length === 0) {
    return obj;
  }
  return get(obj, path);
}

export function nullablePathSet(path, data, obj) {
  if (path.length === 0) {
    return data;
  }
  return fpSet(path, data, obj);
}

export function usePatchableState<T>(initialValue: T) {
  const [state, setState] = useState(initialValue);
  return [
    state,
    (patchOrFn) => {
      setState((state) => ({
        ...state,
        ...(typeof patchOrFn === "function" ? patchOrFn(state) : patchOrFn),
      }));
    },
  ] as const;
}

export const nop = () => {};
