import React, { useEffect, useMemo, useState } from "react";
import {
  editEventoAgenda,
  getEventiAgenda,
  getEventiAgendaByUser,
  getMieiEventiAgenda,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { Avatar, Container, Typography } from "@material-ui/core";
import moment from "moment";
import { useUser } from "app/hooks/useUser";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";
import Calendar, { CalendarAccessors } from "../Calendar/Calendar";
import RichTextEditor from "../common/RichTextEditor";
import PersonIcon from "@material-ui/icons/Person";
import EventoAgendaDialog from "../EventoAgenda/EventoAgendaDialog";
import { useToast } from "app/hooks/useToast";
import { ScadenziarioFilters } from "./ScadenziarioFilters";
import { EventoAgenda, getColorAndLabel } from "./EventoAgenda";
import { ColoredButton } from "../../elements/ColoredButton";

const accessors: CalendarAccessors<EventoAgenda> = {
  isAllDay: (e) => true,
  getStart: (e) => moment(e.dataProgrammata || e.dataEffettiva).toDate(),
  getEnd: (e) =>
    moment(e.dataProgrammata || e.dataEffettiva)
      .add(1, "h")
      .toDate(),
  getTitle: (e) => <EventTitle event={e} />,
  getEventProps: (event, isSelected) => {
    const { backgroundColor } = getColorAndLabel(event);

    const style = {
      backgroundColor: backgroundColor,
    };
    return {
      style: style,
    };
  },
};

export default function ScadenziarioCalendar() {
  const me = useUser();
  const showToast = useToast();
  const hasPermission = useCheckPermission();

  const [editingEventoId, setEditingEventoId] = useState<null | number | "new">(
    null
  );

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    me.id,
    "scad_user",
    "all_object"
  );

  const selectUser = (userId) => setUserFilter(userId);

  const [startDate] = useState(() => moment().startOf("isoWeek").toISOString());

  // @ts-ignore
  const eventiAgendaSource = useDataSource({
    initialData: [],
    loadAction: () => {
      let action;
      if (!userFilter) {
        action = getEventiAgenda();
      } else if (me.id === userFilter) {
        action = getMieiEventiAgenda();
      } else {
        action = getEventiAgendaByUser(userFilter);
      }

      return async function actionWithFilteredResult(dispatch, getState) {
        const result = await action(dispatch, getState);

        // if (result?.response?.data && Array.isArray(result?.response?.data)) {
        //   result.response.data = result.response.data.filter((attivita) => {
        //     return true;
        //   });
        // }
        return result;
      };
    },
  });

  const [{ firstLoad, loading, loadError }, setLoading] = useState({
    firstLoad: true,
    loading: true,
    loadError: null,
  });

  useEffect(() => {
    if (!firstLoad) {
      setLoading({
        firstLoad: false,
        loading: true,
        loadError: null,
      });
      eventiAgendaSource.load();
    }
  }, [userFilter]);

  useEffect(() => {
    if (!eventiAgendaSource.loading && loading) {
      setLoading({
        firstLoad: false,
        loading: false,
        loadError: eventiAgendaSource.loadError,
      });
    }
  }, [eventiAgendaSource.loading, eventiAgendaSource.loadError]);

  const eventiAgenda = useMemo(() => {
    return createEventiAgenda(eventiAgendaSource.data);
  }, [eventiAgendaSource.data]);

  const canEdit = false; // TODO: hasPermission(["modifica_scadenziario......?????"]);

  const onSelectEvent = (event: EventoAgenda) => {
    setEditingEventoId(event.id);
  };

  const [onEventDropEventoId, setOnEventDropEventoId] = useState<number | null>(
    null
  );
  const onEventDropEventoSourceOptions: any = {
    parent: eventiAgendaSource,
    pathInParent: [{ id: onEventDropEventoId }],
    itemId: onEventDropEventoId,
    initialData: {},
    editAction: editEventoAgenda,
    onEditSuccess: () => {
      showToast("Evento modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onEditError: () => {
      showToast("Errore durante la modifica dell'evento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  };
  const onEventDropEventoSource = useDataSource(onEventDropEventoSourceOptions);
  useEffect(() => {
    if (onEventDropEventoId) {
      onEventDropEventoSource.commit().then(() => {
        setOnEventDropEventoId(null);
      });
    }
  }, [onEventDropEventoId]);

  const onEventDrop = (event: EventoAgenda, start: Date, end: Date) => {
    if (onEventDropEventoId) {
      return;
    }
    eventiAgendaSource.changeValue(
      [
        { id: event.id },
        "dataProgrammata", // usiamo sempre questo
      ],
      moment(start).toISOString()
    );
    setOnEventDropEventoId(event.id);
  };

  if (!hasPermission(["visualizza_scadenziario"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }

  let content: any;
  if (loading && firstLoad) {
    content = "Caricamento agenda...";
  } else if (loadError) {
    content = "Errore durante il caricamento degli eventi";
  } else {
    content = (
      <Calendar
        style={{ flex: 1 }}
        defaultDate={startDate}
        events={eventiAgenda}
        accessors={accessors}
        onSelectEvent={onSelectEvent}
        draggable
        onEventDrop={onEventDrop}
      />
    );
  }
  return (
    <Container
      className="ScadenziarioCalendar"
      disableGutters
      fixed={false}
      maxWidth={false}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <EventoAgendaDialog
        arraySource={eventiAgendaSource}
        eventoId={editingEventoId}
        onClose={() => {
          setEditingEventoId(null);
        }}
      />

      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <Typography
          style={{
            marginLeft: 30,
          }}
          component="h2"
          gutterBottom
          variant="h5"
        >
          Scadenziario
        </Typography>
        <div style={{ flex: 1 }} />
        <ColoredButton
          onClick={() => setEditingEventoId("new")}
          variant="outlined"
        >
          Crea evento
        </ColoredButton>
        <ScadenziarioFilters userFilter={userFilter} selectUser={selectUser} />
      </div>

      {content}
    </Container>
  );
}

function createEventiAgenda(events: any): EventoAgenda[] {
  return events.filter(
    (x: EventoAgenda) => x.dataProgrammata || x.dataEffettiva
  );
}

function EventTitle({ event }: { event: EventoAgenda }) {
  const value: any = event.descrizione;
  const user = event.utente;
  let refText = "";

  if (event.azienda) {
    refText = `${event.azienda.ragioneSociale || ""}`;
  } else if (event.contatto) {
    refText = `${event.contatto.cognome || ""} ${event.contatto.nome || ""}`;
  }

  return (
    <div className="EventTitle">
      {/* 
        TODO: 

        farei un riga con nome azienda/contatto
        se c'è anche mandato, metto anche quello? però è utile?
        
      */}

      <RichTextEditor
        readonlyValue={value}
        readOnly={true}
        className="RichTextEditor-for-event"
      />
      <div className="EventTitle-bottom">
        {refText && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PersonIcon
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                fontSize: 12,
                opacity: 0.8,
              }}
            />
            <span style={{ fontSize: "0.9em" }}>{refText}</span>
          </div>
        )}
        {user && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                width: 14,
                height: 14,
                marginRight: 3,
                fontSize: 12,
              }}
            >
              {user.cognome?.toUpperCase().charAt(0)}
            </Avatar>
            <span style={{ fontSize: "0.9em" }}>
              {user.cognome} {user.nome}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
