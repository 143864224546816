import React, { useCallback, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory } from "react-router-dom";
import {
  getContatti as getContattiAction,
  createContatto as createContattoAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import useSideModal from "app/components/common/useSideModal";
import { Container, IconButton } from "@material-ui/core";
import DialogCreaContatto from "app/components/Contatti/DialogCreaContatto";
import { DataTable } from "app/elements/DataTable";
import Contatto from "app/components/Contatti/Contatto";
import ContattoStato from "app/constants/ContattoStato";
import StatoContattoBadge from "app/components/common/StatoContattoBadge";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTableGlobalFilter } from "app/elements/DataTable/DataTableGlobalFilter";
import SearchIcon from "@material-ui/icons/Search";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import TableActionButtons from "../common/TableActionButtons";

export default function ContattiTable(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const history = useHistory();

  const _getContattiAction = props.getContattiAction || getContattiAction;

  const contatti = useDataSource({
    initialData: [],
    loadAction: _getContattiAction,
    createAction: createContattoAction,
    onCreateSuccess: (response) => {
      showToast("Contatto creato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push(`/contatto/${response.data.id}`);
    },
    onCreateError: () => {
      showToast("Errore durante la creazione del contatto", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    skipCheckUncommittedChanged: true,
  });

  const handleContattoUpdated = useCallback(
    (contatto) => {
      console.log("contatto changed", contatto);
      contatti.changeValue(contatto.id, contatto);
    },
    [contatti]
  );

  const [newContattoId, setNewContattoId] = useState(null);

  const { openSideModal, sideModal } = useSideModal();

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_contatto"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px - 400px)",
      }}
      disableGutters
      maxWidth={false}
    >
      <DialogCreaContatto
        contatti={contatti}
        newContattoId={newContattoId}
        cancel={() => {
          setNewContattoId(null);
        }}
      />
      {sideModal}

      <h1
        className="page-title-header"
        style={{
          marginTop: -16,
        }}
      >
        {props.title || "Rubrica"}{" "}
      </h1>

      <div>
        <DataTable
          source={contatti}
          columns={[
            {
              path: ["id"],
              width: 105,
              sortable: false,
              disableColumnFilter: true,
              header: " ",
              renderCell: (value) => {
                return (
                  <TableActionButtons
                    link={`/contatto/${value}`}
                    openSideModal={() => {
                      openSideModal(
                        Contatto,
                        {
                          id: value,
                          useIdFromProps: true,
                          onEditSuccess: handleContattoUpdated,
                          edit: true,
                        },
                        value
                      );
                    }}
                    canEdit={hasPermission("modifica_contatto")}
                    editItem={() =>
                      history.push(`/contatto/${value}?edit=true`)
                    }
                  />
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            {
              sortable: true,
              path: ["nome"],
              width: 150,
              header: "Nome",
              type: "text",
            },
            {
              sortable: true,
              path: ["cognome"],
              width: 150,
              header: "Cognome",
              type: "text",
            },
            {
              sortable: true,
              path: ["email"],
              width: 250,
              header: "Email",
              type: "text",
            },
            {
              sortable: true,
              path: ["cellulare"],
              width: 180,
              header: "Cellulare",
              type: "text",
            },
            {
              sortable: true,
              path: ["telefono"],
              width: 180,
              header: "Telefono",
              type: "text",
            },
            {
              sortable: true,
              path: ["stato"],
              width: 130,
              header: "Stato",
              type: "enum",
              options: ContattoStato,
              renderCell: (value) => {
                return <StatoContattoBadge stato={value} />;
              },
              renderRawValue: (value) => value,
            },
          ]}
          topToolbar={
            <Container
              style={{
                marginBottom: 10,
                paddingTop: 16,
                display: "flex",
              }}
              disableGutters={false}
              fixed={false}
              maxWidth={false}
            >
              <div>
                {hasPermission("crea_contatto") ? (
                  <ColoredButton
                    disabled={false}
                    onClick={() => {
                      setNewContattoId("new-123");
                    }}
                    color="default"
                    fullWidth={false}
                    size="small"
                    variant="outlined"
                  >
                    Crea Contatto
                  </ColoredButton>
                ) : null}{" "}
                <DataTableExportButtons
                  filename={props.exportFilename || "Rubrica"}
                />
              </div>

              <DataTableGlobalFilter
                style={{
                  marginLeft: "auto",
                }}
                placeholder="Cerca..."
                size="small"
                variant="outlined"
                startAdornment={<SearchIcon />}
              />
            </Container>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            noRows: contatti.loading ? "Caricamento..." : "Nessun contatto",
            rowsPerPage: "Righe per pagina",
            search: "Filtra",
          }}
        />
      </div>
    </Container>
  );
}
