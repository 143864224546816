import React from "react";
import { Dialog, CircularProgress } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Alert } from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";

export default function DialogDeleteContatto(props) {
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="sm"
      onClose={null}
      open={props.dialog.isOpen}
      scroll="paper"
    >
      <DialogTitle>Elimina cliente</DialogTitle>

      <DialogContent>
        {"Sei sicuro di voler eliminare il cliente?"}

        {props.deleteError ? (
          <Alert
            color="error"
            iconMapping={{}}
            onClose={null}
            role="alert"
            severity="error"
          >
            {props.deleteError}{" "}
          </Alert>
        ) : null}
      </DialogContent>

      <DialogActions>
        <ColoredButton
          disabled={props.source.deleting}
          onClick={(event) => {
            props.dialog.close();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          disabled={props.deleting}
          onClick={(event) => {
            props.source.delete();
          }}
          color="primary"
          endIcon={
            props.deleting ? (
              <CircularProgress color="primary" size={20} />
            ) : undefined
          }
        >
          Elimina
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
