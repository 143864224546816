import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import LineaStato from "app/constants/LineaStato.json";
import {
  extractURLSearchValueAsNumber,
  useEffectOnSearchValue,
} from "app/utils/extractURLSearchValue";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getLegge as getLeggeAction,
  getLinea as getLineaAction,
  createLinea as createLineaAction,
  editLinea as editLineaAction,
  deleteLinea as deleteLineaAction,
  duplicateLinea as duplicateLineaAction,
  getTipologieProcedura as getTipologieProceduraAction,
  getRegimi as getRegimiAction,
  getNomiAgevolazione as getNomiAgevolazioneAction,
  getNomiVariabile as getNomiVariabileAction,
  getNomiMilestone as getNomiMilestoneAction,
} from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import { useSubmitDataSource } from "app/hooks/DataSource/useSubmitDataSource";
import {
  Container,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  Dialog,
} from "@material-ui/core";
import BackButton from "app/components/common/BackButton";
import LaunchIcon from "@material-ui/icons/Launch";
import { ColoredButton } from "app/elements/ColoredButton";
import DateRangeIcon from "@material-ui/icons/DateRange";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { StatoPicker } from "app/components/StatoPicker";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import DateInput from "app/components/common/DateInput";
import DizionarioSelect from "app/components/common/DizionarioSelect";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LineaRiquadroProcedure from "app/components/LineaRiquadroProcedure";
import { DataTable } from "app/elements/DataTable";
import AddIcon from "@material-ui/icons/Add";
import VariabileAgevolazionePicker from "app/components/common/VariabileAgevolazionePicker";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useEstendiLineaDialog } from "./EstendiLineaDialog";
import NumberInput from "../common/NumberInput";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";

export default function Linea(props) {
  const hasPermission = useCheckPermission();
  const showToast = useToast();
  const history = useHistory();

  const { id } = props.useIdFromProps ? props : useParams();

  const isNew = id === "new";

  const [editing, setEditing] = useState(isNew);
  const readOnly = !editing;

  const idLegge = extractURLSearchValueAsNumber("idLegge");

  const leggeForNewLinea = useDataSource({
    loadAction: getLeggeAction,
    itemId: idLegge,
    initialData: {},
    autoLoad: isNew,
  });

  const linea = useDataSource({
    loadAction: getLineaAction,
    createAction: createLineaAction,
    deleteAction: deleteLineaAction,
    editAction: editLineaAction,
    itemId: id,
    autoLoad: !isNew,

    initialData: {
      idLegge: idLegge,
      stato: "in-preparazione",
    },
    onCreateSuccess: (response) => {
      setEditing(false);

      showToast("Linea creata con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push(`/linee/${response.data.id}`);
    },
    onCreateError: (response) => {
      showToast(
        response.message
          ? "Errore: " + response.message
          : "Errore durante la creazione della linea",
        { color: "error", horizontal: "left", vertical: "bottom" }
      );
    },
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Linea modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: () => {
      showToast("Errore durante la modifica della linea", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onDeleteSuccess: () => {
      showToast("Linea eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/linee");
    },
    onDeleteError: (response) => {
      showToast(
        response?.message || "Errore durante l'eliminazione della linea",
        { color: "error", horizontal: "left", vertical: "bottom" }
      );
    },
  });

  useCreaconsultingTitle(
    isNew ? "Nuova linea" : `Linea ${linea.getValue("nome")}`
  );

  const agevolazioni = useDataSource({
    parent: linea,
    pathInParent: ["agevolazioni"],
    initialData: [],
  });

  const milestonePagamenti = useDataSource({
    parent: linea,
    pathInParent: ["milestonePagamenti"],
    initialData: [],
  });

  const deleteDialog = useDialog();
  const { openEstendiLineaDialog, estendiLineaDialog } =
    useEstendiLineaDialog(linea);

  const isVariabileUsed = (variabile) => {
    return (
      milestonePagamenti.data.findIndex((milestone) => {
        return milestone.idVariabile === variabile.id;
      }) >= 0
    );
  };

  const duplicateLinea = useSubmitDataSource({
    submitAction: duplicateLineaAction,
    onSubmitSuccess: (response) => {
      showToast("Linea duplicata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/linee/" + response.data.id);
    },
    onSubmitError: () => {
      showToast("Errore durante la duplicazione della linea", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const stato = linea.getValue("stato");

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_linea") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_linea")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  useEffectOnSearchValue(
    "estendiLinea",
    () =>
      linea.getValue("isPluriennale") &&
      hasPermission("estendi_linea") &&
      openEstendiLineaDialog(),
    { waitForSourceLoaded: linea }
  );

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_linea"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      className={readOnly ? "crea-readOnly" : ""}
      disableGutters={true}
      fixed={false}
      maxWidth="xl"
    >
      <h2
        className="page-title-header page-title-header-sticky "
        style={{
          marginTop: -20,
          marginBottom: 0,
        }}
      >
        <BackButton defaultBackLink="" />
        Linea
      </h2>
      {linea.loading ? (
        "Caricamento linea in corso ..."
      ) : linea.loadError ? (
        "Errore durante il caricamento della linea"
      ) : (
        <>
          <div
            className="page-title-header-sticky-buttons-row"
            style={{
              display: "flex",
              marginBottom: "1em",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <Typography
                style={{
                  flex: 1,
                }}
                component="h2"
                gutterBottom={true}
                variant="h6"
              >
                {linea.getValue("nome")}
              </Typography>

              <Typography
                style={{
                  flex: 1,
                }}
                component="h5"
                gutterBottom={true}
                variant="subtitle1"
              >
                <span
                  style={{
                    fontSize: "0.9rem",
                    fontStyle: "italic",
                  }}
                >
                  {"Legge: "}
                </span>

                <span>
                  {isNew
                    ? leggeForNewLinea.getValue(["nome"])
                    : linea.getValue(["legge", "nome"])}{" "}
                </span>

                <IconButton
                  to={`/leggi/${linea.getValue(["legge", "id"])}`}
                  component={Link}
                >
                  <LaunchIcon />
                </IconButton>
              </Typography>
            </div>

            <div
              style={{
                display: "block",
                justifyContent: "flex-end",
                marginBottom: 8,
                marginTop: "auto",
              }}
            >
              {props.isSideModal && readOnly && (
                <ColoredButton
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={false}
                  color="secondary"
                  endIcon={null}
                  fullWidth={false}
                  size="small"
                  startIcon={<LaunchIcon />}
                  variant="outlined"
                  to={`/linee/${id}?edit=true`}
                  component={Link}
                >
                  Apri
                </ColoredButton>
              )}
              {readOnly ? (
                <>
                  {linea.getValue("isPluriennale") &&
                    hasPermission("estendi_linea") && (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          openEstendiLineaDialog();
                        }}
                        startIcon={<DateRangeIcon />}
                        variant="outlined"
                      >
                        Estendi linea
                      </ColoredButton>
                    )}

                  {hasPermission("duplica_linea") &&
                  !linea.getValue("isPluriennale") ? (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={() => {
                        duplicateLinea.submit(linea.getValue("id"));
                      }}
                      startIcon={<FileCopyIcon />}
                      variant="outlined"
                    >
                      Duplica
                    </ColoredButton>
                  ) : null}
                  {hasPermission("modifica_linea") ? (
                    <ColoredButton
                      style={{
                        marginLeft: 8,
                      }}
                      onClick={() => {
                        setEditing(!editing);
                      }}
                      startIcon={<EditIcon />}
                      variant="outlined"
                    >
                      Modifica
                    </ColoredButton>
                  ) : null}
                </>
              ) : (
                <>
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      if (isNew) {
                        history.push("/linee");
                      } else {
                        linea.cancel();
                        setEditing(false);
                      }
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<CancelIcon />}
                    variant="outlined"
                  >
                    {[
                      isNew ? "Torna alla lista linee" : "Annulla le modifiche",
                    ]}
                  </ColoredButton>

                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      linea.commit();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    {isNew ? "Crea" : "Salva"}
                  </ColoredButton>
                </>
              )}{" "}
              {!isNew &&
                !props.isSideModal &&
                hasPermission("elimina_linea") && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      deleteDialog.open();
                    }}
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                  >
                    Elimina
                  </ColoredButton>
                )}
            </div>
          </div>

          <Card>
            <CardContent>
              <Grid container={true} item={false} spacing={2}>
                <Grid container={false} item={true} lg={7} md={6} sm={12}>
                  <TextField
                    disabled={readOnly}
                    error={linea.getValidationErrors("nome").length > 0}
                    fullWidth={true}
                    helperText={linea.getValidationErrors("nome")[0]}
                    label="Nome linea"
                    margin="none"
                    onBlur={() => {
                      linea.runValidation("nome");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      linea.changeValue(
                        "nome",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={linea.getValue("nome", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
                <Grid container={false} item={true} lg={3} md={4} sm={12}>
                  <StatoPicker
                    label="Stato"
                    source={linea}
                    stati={LineaStato}
                    disabled={!readOnly || isNew}
                  />
                </Grid>
                <Grid container={false} item={true} lg={4} md={4} sm={12}>
                  <TextField
                    disabled={readOnly || stato !== "in-preparazione"}
                    error={linea.getValidationErrors("anno").length > 0}
                    fullWidth={true}
                    helperText={linea.getValidationErrors("anno")[0]}
                    label="Anno"
                    onBlur={() => {
                      linea.runValidation("anno");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      linea.changeValue(
                        "anno",

                        value
                      );
                    }}
                    size="small"
                    value={linea.getValue("anno", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly || stato !== "in-preparazione",

                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      thousandSeparator: false,
                    }}
                  />
                </Grid>
                <Grid container={false} item={true} lg={6} md={6} sm={12}>
                  <DateInput
                    source={linea}
                    sourceKey="scadenza"
                    readOnly={readOnly}
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    label="Scadenza di vendita"
                  />
                </Grid>
                <Grid container={false} item={true} sm={6} xs={12}>
                  <DizionarioSelect
                    source={linea}
                    sourceKey="idTipologiaProcedura"
                    readOnly={readOnly}
                    label="Tipologia procedure"
                    loadDizionarioAction={getTipologieProceduraAction}
                  />
                </Grid>
                <Grid container={false} item={true} sm={6} xs={12}>
                  <DizionarioSelect
                    source={linea}
                    sourceKey="idRegime"
                    readOnly={readOnly}
                    label="Regime"
                    loadDizionarioAction={getRegimiAction}
                  />
                </Grid>
                {!isNew ? (
                  <>
                    <Grid container={false} item={true} sm={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={linea.getValue("isPluriennale", false)}
                            disabled={linea.loading || linea.editing}
                            onChange={(event) => {
                              const value = event.target.checked;

                              linea.changeValue(
                                "isPluriennale",

                                value
                              );
                            }}
                            size="small"
                            value={linea.getValue("isPluriennale", "")}
                          />
                        }
                        disabled={true}
                        label="Pluriennale"
                      />
                    </Grid>

                    <Grid container={false} item={true} sm={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={linea.getValue("isMultibene", false)}
                            disabled={linea.loading || linea.editing}
                            onChange={(event) => {
                              const value = event.target.checked;

                              linea.changeValue(
                                "isMultibene",

                                value
                              );
                            }}
                            size="small"
                            value={linea.getValue("isMultibene", "")}
                          />
                        }
                        disabled={true}
                        label="Multibene"
                      />
                    </Grid>

                    <Grid container={false} item={true} sm={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={linea.getValue("isMulticliente", false)}
                            disabled={linea.loading || linea.editing}
                            onChange={(event) => {
                              const value = event.target.checked;

                              linea.changeValue(
                                "isMulticliente",

                                value
                              );
                            }}
                            size="small"
                            value={linea.getValue("isMulticliente", "")}
                          />
                        }
                        disabled={true}
                        label="Multicliente"
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container={false} item={true} sm={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={leggeForNewLinea.getValue(
                              "isPluriennale",
                              false
                            )}
                            disabled={
                              leggeForNewLinea.loading ||
                              leggeForNewLinea.editing
                            }
                            onChange={(event) => {
                              const value = event.target.checked;

                              leggeForNewLinea.changeValue(
                                "isPluriennale",

                                value
                              );
                            }}
                            size="small"
                            value={leggeForNewLinea.getValue(
                              "isPluriennale",
                              ""
                            )}
                          />
                        }
                        disabled={true}
                        label="Pluriennale"
                      />
                    </Grid>

                    <Grid container={false} item={true} sm={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={leggeForNewLinea.getValue(
                              "isMultibene",
                              false
                            )}
                            disabled={
                              leggeForNewLinea.loading ||
                              leggeForNewLinea.editing
                            }
                            onChange={(event) => {
                              const value = event.target.checked;

                              leggeForNewLinea.changeValue(
                                "isMultibene",

                                value
                              );
                            }}
                            size="small"
                            value={leggeForNewLinea.getValue("isMultibene", "")}
                          />
                        }
                        disabled={true}
                        label="Multibene"
                      />
                    </Grid>

                    <Grid container={false} item={true} sm={4} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={leggeForNewLinea.getValue(
                              "isMulticliente",
                              false
                            )}
                            disabled={
                              leggeForNewLinea.loading ||
                              leggeForNewLinea.editing
                            }
                            onChange={(event) => {
                              const value = event.target.checked;

                              leggeForNewLinea.changeValue(
                                "isMulticliente",

                                value
                              );
                            }}
                            size="small"
                            value={leggeForNewLinea.getValue(
                              "isMulticliente",
                              ""
                            )}
                          />
                        }
                        disabled={true}
                        label="Multicliente"
                      />
                    </Grid>
                  </>
                )}{" "}
              </Grid>
            </CardContent>
          </Card>

          <Grid
            style={{
              marginTop: 16,
            }}
            container={true}
            item={false}
            spacing={2}
          >
            <Grid container={false} item={true} lg={12} sm={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Format Procedura Commesse
                </AccordionSummary>

                <AccordionDetails>
                  {linea.getValue("stato") ? (
                    linea.getValue("stato") === "in-preparazione" ||
                    linea.getValue("stato") === "in-valutazione" ? (
                      <Typography variant="body2">
                        Si possono creare format procedure commessa solo quando
                        lo stato della linea è in GO
                      </Typography>
                    ) : (
                      <LineaRiquadroProcedure
                        linea={linea}
                        editing={editing}
                        reload={() => linea.load()}
                      />
                    )
                  ) : null}{" "}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <AgevolazioniEImportiAccordion
            agevolazioni={agevolazioni}
            editing={editing}
            linea={linea}
            isVariabileUsed={isVariabileUsed}
          />

          <MilestonePagamentiAccordion
            milestonePagamenti={milestonePagamenti}
            linea={linea}
            agevolazioni={agevolazioni}
            readOnly={readOnly}
            editing={editing}
          />

          {!isNew ? (
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Format strumenti
              </AccordionSummary>

              <AccordionDetails>
                <DataTable
                  source={linea}
                  sourceKey="fsl"
                  columns={[
                    {
                      path: ["formatStrumento", "id"],
                      header: "",
                      disableColumnFilter: true,
                      width: 48,
                      renderCell: (value) => {
                        return (
                          <IconButton
                            to={"/formatStrumenti/" + value}
                            component={Link}
                          >
                            <LaunchIcon />
                          </IconButton>
                        );
                      },
                      type: "text",
                    },
                    {
                      path: ["formatStrumento", "nome"],
                      header: "Nome",
                      disableColumnFilter: false,
                      disableGlobalFilter: false,
                      sortable: true,
                      type: "text",
                    },
                  ]}
                  topToolbar={null}
                  bottomToolbar={null}
                  showFilterRow={true}
                  pageSize={10}
                  size="small"
                  expandableRows={false}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}

          <Dialog maxWidth="md" open={deleteDialog.isOpen}>
            <DialogTitle>Conferma di eliminazione</DialogTitle>

            <DialogContent>
              <Typography>
                {`Sei sicuro di voler eliminare la linea ${linea.getValue(
                  "nome"
                )}?`}{" "}
              </Typography>
            </DialogContent>

            <DialogActions>
              <ColoredButton
                onClick={() => {
                  deleteDialog.close();
                }}
                color="secondary"
                variant="outlined"
              >
                Annulla
              </ColoredButton>

              <ColoredButton
                onClick={() => {
                  linea.delete();
                }}
                color="primary"
                variant="outlined"
              >
                Elimina
              </ColoredButton>
            </DialogActions>
          </Dialog>

          {estendiLineaDialog}
        </>
      )}{" "}
    </Container>
  );
}

function AgevolazioniEImportiAccordion({
  agevolazioni,
  editing,
  linea,
  isVariabileUsed,
}) {
  const readOnly = !editing;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Agevolazioni e importi
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={agevolazioni}
          columns={[
            {
              path: ["idNomeAgevolazione"],
              header: "Nome",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <DizionarioSelect
                    source={rowSource}
                    sourceKey="idNomeAgevolazione"
                    showNullOption={false}
                    readOnly={readOnly}
                    label="Agevolazione"
                    loadDizionarioAction={getNomiAgevolazioneAction}
                    onChange={(value, nomiAgevolazione) => {
                      rowSource.changeValue(
                        "nome",

                        nomiAgevolazione.getValue([
                          {
                            id: value,
                          },
                        ])
                      );

                      rowSource.changeValue(
                        "idNomeAgevolazione",

                        value
                      );
                    }}
                  />
                );
              },
            },
            !readOnly
              ? {
                  path: ["id"],
                  header: "Azioni",
                  disableColumnFilter: false,
                  disableGlobalFilter: false,
                  sortable: false,
                  type: "text",
                  width: 64,
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return (
                      <IconButton
                        onClick={() => {
                          if (
                            rowSource.data.variabili
                              .map(isVariabileUsed)
                              .findIndex((variabileUsed) => {
                                return variabileUsed;
                              }) >= 0
                          ) {
                            window.alert(
                              "Una o più variabili di questa agevolazione non possono essere cancellate perchè usate in una milestone"
                            );
                          } else {
                            rowSource.delete();
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    );
                  },
                }
              : null,
          ]}
          topToolbar={null}
          bottomToolbar={
            <div>
              {linea.getValidationErrors("agevolazioni")?.[0] ? (
                <FormHelperText
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={true}
                  error={true}
                  filled={false}
                  focused={false}
                  required={false}
                  variant="standard"
                >
                  {linea.getValidationErrors("agevolazioni")?.[0] || null}{" "}
                </FormHelperText>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.75em",
                }}
              >
                {editing ? (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      agevolazioni.create({
                        variabili: [],
                      });
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Aggiungi agevolazione
                  </ColoredButton>
                ) : null}
              </div>
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={true}
          expandRowsOnClick={true}
          isRowExpandable={() => {
            return true;
          }}
          renderExpandedRow={(rowIndex, row, rowSource) => {
            return (
              <AgevolazioneExpandedRow
                rowSource={rowSource}
                editing={editing}
                isVariabileUsed={isVariabileUsed}
              />
            );
          }}
          rowsDefaultExpanded
          messages={{
            noRows: "Nessuna agevolazione",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function AgevolazioneExpandedRow({ rowSource, editing, isVariabileUsed }) {
  const readOnly = !editing;
  return (
    <div
      style={{
        padding: ".5em .5em .5em 5em",
        backgroundColor: "lightgray",
      }}
    >
      <DataTable
        source={rowSource.getChildDataSource(["variabili"])}
        columns={[
          {
            path: ["idNomeVariabile"],
            header: "Nome importo",
            disableColumnFilter: true,
            disableGlobalFilter: true,
            sortable: false,
            type: "text",
            renderCell: (value, row, rowIndex, rowSource) => {
              return (
                <DizionarioSelect
                  source={rowSource}
                  sourceKey="idNomeVariabile"
                  readOnly={readOnly}
                  showNullOption={false}
                  label="Importo"
                  loadDizionarioAction={getNomiVariabileAction}
                  onChange={(value, nomiVariabile) => {
                    rowSource.changeValue(
                      "nome",

                      nomiVariabile.getValue([
                        {
                          id: value,
                        },
                      ])
                    );

                    rowSource.changeValue(
                      "idNomeVariabile",

                      value
                    );
                  }}
                />
              );
            },
          },
          {
            path: ["definitaInFaseDiOfferta"],
            header: "Definizione",
            renderCell: (value, row, rowIndex, rowSource) => {
              return (
                <FormControlLabel
                  disabled={readOnly}
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) =>
                        rowSource.changeValue(
                          "definitaInFaseDiOfferta",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Definita in fase di offerta"
                />
              );
            },
          },
          !readOnly
            ? {
                path: ["id"],
                header: "Azioni",
                disableColumnFilter: false,
                disableGlobalFilter: false,
                sortable: false,
                type: "text",
                width: 64,
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <IconButton
                      onClick={() => {
                        if (isVariabileUsed(rowSource.data)) {
                          window.alert(
                            "Questa variabile non può essere cancellata perchè utilizzata in una milestone"
                          );
                        } else {
                          rowSource.delete();
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  );
                },
              }
            : null,
        ]}
        topToolbar={null}
        bottomToolbar={
          <div>
            {rowSource.getValidationErrors("variabili")?.[0] ? (
              <FormHelperText
                style={{
                  marginLeft: 8,
                }}
                disabled={true}
                error={true}
                filled={false}
                focused={false}
                required={false}
                variant="standard"
              >
                {rowSource.getValidationErrors("variabili")?.[0] || null}{" "}
              </FormHelperText>
            ) : null}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75em",
              }}
            >
              {editing ? (
                <ColoredButton
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={false}
                  onClick={() => {
                    rowSource.getChildDataSource(["variabili"]).create({
                      idAgevolazione: rowSource.getValue("id"),
                    });
                  }}
                  color="secondary"
                  endIcon={null}
                  fullWidth={false}
                  size="small"
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Aggiungi importo
                </ColoredButton>
              ) : null}{" "}
            </div>
          </div>
        }
        showFilterRow={false}
        pageSize={1000000}
        size="small"
        expandableRows={false}
        expandRowsOnClick={false}
        isRowExpandable={false}
        renderExpandedRow={null}
        messages={{
          noRows: "Nessuna variabile",
        }}
      />
    </div>
  );
}

function MilestonePagamentiAccordion({
  milestonePagamenti,
  linea,
  agevolazioni,
  editing,
  readOnly,
}) {
  const maybeAlreadySold =
    linea.getValue("stato") === "go" ||
    linea.getValue("stato") === "chiusa" ||
    linea.getValue("stato") === "non-vendibile" ||
    linea.getValue("stato") === "archiviata";
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Milestone pagamenti
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={milestonePagamenti}
          columns={[
            {
              path: ["idNomeMilestone"],
              header: "Nome milestone",
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <DizionarioSelect
                    source={rowSource}
                    sourceKey="idNomeMilestone"
                    readOnly={readOnly || (maybeAlreadySold && !row.__isNew)}
                    label="Milestone pagamento"
                    loadDizionarioAction={getNomiMilestoneAction}
                  />
                );
              },
            },
            {
              path: ["allaFirma"],
              header: "Pagamento",
              width: 155,
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rowSource.getValue("allaFirma", false)}
                        disabled={
                          rowSource.loading ||
                          rowSource.editing ||
                          (maybeAlreadySold && !row.__isNew)
                        }
                        onChange={(event) => {
                          const allaFirma = event.target.checked;
                          rowSource.changeValue("allaFirma", allaFirma);
                        }}
                        size="small"
                        value={rowSource.getValue("allaFirma", "")}
                      />
                    }
                    disabled={
                      readOnly ||
                      (
                        rowSource.getValue("mandatiLineeMilestonesPagamenti") ||
                        []
                      ).length > 0 ||
                      !!rowSource.getValue("formatAttivita") ||
                      (maybeAlreadySold && !row.__isNew)
                    }
                    label="alla firma"
                  />
                );
              },
              type: "text",
            },
            {
              path: ["hasMarcheDaBollo"],
              header: "Marche da bollo",
              width: 220,
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <MarcheDaBolloInput
                    readOnly={readOnly}
                    milestoneSource={rowSource}
                  />
                );
              },
            },
            {
              path: ["idVariabile"],
              header: "Importo Agevolazione",
              renderCell: (value, row, rowIndex, rowSource) => {
                return rowSource.getValue("allaFirma") ? (
                  ""
                ) : (
                  <VariabileAgevolazionePicker
                    agevolazioni={agevolazioni?.data}
                    readOnly={readOnly || (maybeAlreadySold && !row.__isNew)}
                    variant="outlined"
                    source={rowSource}
                    idVariabileKey="idVariabile"
                  />
                );
              },
              type: "text",
            },
            !readOnly && {
              path: ["id"],
              header: "Azioni",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: false,
              type: "text",
              width: 64,
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <IconButton
                    disabled={maybeAlreadySold && !row.__isNew}
                    onClick={() => {
                      rowSource.delete();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              },
            },
          ]}
          bottomToolbar={
            <div>
              {linea.getValidationErrors("milestonePagamenti")?.[0] ? (
                <FormHelperText
                  style={{
                    marginLeft: 8,
                  }}
                  disabled
                  error
                  variant="standard"
                >
                  {linea.getValidationErrors("milestonePagamenti")?.[0] || null}{" "}
                </FormHelperText>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.75em",
                }}
              >
                {editing && (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      milestonePagamenti.create();
                    }}
                    color="secondary"
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Aggiungi milestone pagamento
                  </ColoredButton>
                )}
              </div>
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          expandRowsOnClick={false}
          isRowExpandable={null}
          renderExpandedRow={null}
          messages={{
            noRows: "Nessuna milestone pagamento",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function MarcheDaBolloInput({ readOnly, milestoneSource }) {
  const hasMarcheDaBollo =
    milestoneSource.getValue("hasMarcheDaBollo") || false;
  const error = milestoneSource.getValidationErrors("hasMarcheDaBollo")[0];

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <FormControlLabel
          style={{ marginRight: hasMarcheDaBollo ? 0 : undefined }}
          label={hasMarcheDaBollo ? "" : "Marche da bollo"}
          control={
            <Checkbox
              checked={hasMarcheDaBollo}
              onChange={(event) => {
                const value = event.target.checked;
                milestoneSource.changeValue("hasMarcheDaBollo", value);
              }}
              size="small"
            />
          }
          disabled={readOnly}
        />

        {hasMarcheDaBollo && (
          <>
            <NumberInput
              label="Numero"
              size="small"
              fullWidth
              variant="outlined"
              source={milestoneSource}
              sourceKey="numeroMarcheDaBollo"
              readOnly={readOnly}
              disabled={readOnly}
              min={0}
            />
            <NumberInput
              label="Valore"
              source={milestoneSource}
              sourceKey="valoreMarcaDaBollo"
              readOnly={readOnly}
              disabled={readOnly}
              fullWidth
              variant="outlined"
              currency
            />
          </>
        )}
      </div>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}
