import React, { Fragment } from "react";
import {
  useSnackbar,
  SnackbarOrigin,
  VariantType,
  OptionsObject,
} from "notistack";

export function useToast() {
  const { enqueueSnackbar } = useSnackbar();

  const showToast = (
    content: string | string[],
    {
      color,
      vertical = "bottom",
      horizontal = "left",
      autoHideDuration = 5000,
    }: {
      color: VariantType;
      vertical?: SnackbarOrigin["vertical"];
      horizontal?: SnackbarOrigin["horizontal"];
      autoHideDuration?: OptionsObject["autoHideDuration"];
    }
  ) => {
    const contentToShow = Array.isArray(content) ? (
      <span>
        {content.map((c, i) => (
          <Fragment key={i}>
            {c}
            <br />
          </Fragment>
        ))}
      </span>
    ) : (
      content
    );

    enqueueSnackbar(contentToShow, {
      variant: color,
      autoHideDuration,
      anchorOrigin: {
        vertical,
        horizontal,
      },
    });
  };

  return showToast;
}
