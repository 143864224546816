import TextField from "@material-ui/core/TextField";
import React from "react";
import NumberFormat from "react-number-format";
// https://www.npmjs.com/package/react-number-format

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
    />
  );
}

function NumberFormatCustomEuro(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      prefix="€"
    />
  );
}

function NumberFormatCustomPercent(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      suffix="%"
    />
  );
}

type NumberInputProps = {
  style?: any;
  disabled?: boolean;
  error?: any;
  fullWidth?: boolean;
  source?: any;
  sourceKey?: any;
  helperText?: any;
  label?: any;
  onChange?: any;
  value?: any;
  currency?: any;
  percent?: any;
  min?: any;
  max?: any;
  size?: any;
  thousandSeparator?: any;
  variant?: any;
  InputLabelProps?: any;
};

export default function NumberInput({
  style,
  disabled = false,
  error,
  fullWidth = false,
  source,
  sourceKey,
  helperText,
  label,
  onChange,
  value,
  currency,
  percent,
  min,
  max,
  size,
  thousandSeparator,
  ...others
}: NumberInputProps) {
  const others2: any = {};
  const inputProps: any = {};

  let inputComponent = NumberFormatCustom;

  if (currency) {
    inputComponent = NumberFormatCustomEuro;
  } else if (percent) {
    inputComponent = NumberFormatCustomPercent;
  }

  if (source && sourceKey) {
    others2.error = source.getValidationErrors(sourceKey).length > 0;
    others2.helperText = source.getValidationErrors(sourceKey)[0];
    others2.onBlur = () => {
      source.runValidation(sourceKey);
    };
    others2.onChange = (event) => {
      const value = event.target.value;
      source.changeValue(sourceKey, value ?? null);
    };
    others2.value = source.getValue(sourceKey, "");
  }

  if (typeof min === "number" || typeof max === "number") {
    min = typeof min === "number" ? min : -Infinity;
    max = typeof max === "number" ? max : Infinity;
    inputProps.isAllowed = (values) => {
      const { formattedValue, floatValue } = values;
      if (floatValue == null) {
        return formattedValue === "";
      }
      return floatValue >= min && floatValue <= max;
    };
  }

  inputProps.thousandSeparator =
    typeof thousandSeparator !== "undefined" ? thousandSeparator : ".";
  inputProps.decimalSeparator = ",";

  return (
    <TextField
      style={style}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      label={label}
      onChange={onChange}
      value={value}
      size={size}
      {...others}
      {...others2}
      InputProps={{
        inputComponent: inputComponent,
        inputProps: inputProps,
      }}
    />
  );
}
