import React, { useEffect, useRef } from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import EditorWithToolbar from "./draftjs/EditorWithToolbar";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

function createEditorState(string) {
  if (!string) {
    return EditorState.createEmpty();
  }

  try {
    const json = JSON.parse(string);
    const state = convertFromRaw(json);
    return EditorState.createWithContent(state);
  } catch (err) {
    return EditorState.createEmpty();
  }
}

const isEmptyDraftJs = (contentState) => {
  const hasText = contentState.hasText() && contentState.getPlainText() !== "";
  return !hasText;
};

function getStringValue(editorState) {
  const state = editorState.getCurrentContent();
  if (isEmptyDraftJs(state)) {
    return null;
  }
  const json = convertToRaw(state);
  return JSON.stringify(json);
}

export default function RichTextEditor({
  label = undefined,
  readOnly,
  source = undefined,
  sourceKey = undefined,
  className,
  readonlyValue = null,
  marginBottom = 5,
}) {
  const _getValue = () => {
    if (source) {
      return source.getValue(sourceKey);
    } else {
      return readonlyValue;
    }
  };

  const setupEditorState = () => {
    const string = _getValue() || "";
    const e = createEditorState(string);
    return [e, getStringValue(e)];
  };

  const [[editorState, stringValue], setState] =
    React.useState(setupEditorState);

  const string = _getValue() || "";
  const prevValue = useRef(string);

  useEffect(() => {
    // if(sourceKey==="note"){
    //   console.log("prevValue.current !== string && stringValue !== string", prevValue.current !== string , stringValue !== string)
    // }

    if (
      // prevValue.current !== string &&
      stringValue !== string
    ) {
      setState(setupEditorState());
    }
  }, [prevValue.current, string, stringValue]);

  prevValue.current = string;

  const onChange = (editorState) => {
    const newString = getStringValue(editorState);
    source && !readOnly && source.changeValue(sourceKey, newString || null);
    setState([editorState, newString]);
  };

  // if(sourceKey==="note"){
  //   console.log(stringValue)
  // }

  // onChange: function onChange(event) {
  //   var value = event.target.value;
  //   props.source.changeValue('stato', value || null);
  // },
  // onClose: function onClose(event) {
  //   props.source.runValidation('stato');
  // },

  return (
    <FormControl
      className={className}
      component="fieldset"
      style={{ width: "100%" }}
    >
      {label && (
        <FormLabel component="legend" style={{ marginBottom }}>
          {label}
        </FormLabel>
      )}
      <EditorWithToolbar
        editorState={editorState}
        onChange={onChange}
        readOnly={readOnly}
      />
    </FormControl>
  );
}
