import React, { useCallback } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFormatStrumenti as getFormatStrumentiAction } from "app/actions";
import { Container } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { Link, useHistory } from "react-router-dom";
import FormatStrumentoStato from "app/constants/FormatStrumentoStato";
import BadgeFormatStrumentoStato from "app/components/common/BadgeFormatStrumentoStato";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import TableActionButtons from "../common/TableActionButtons";
import FormatStrumento from "./FormatStrumento";
import useSideModal from "../common/useSideModal";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";
import { useApi } from "../../hooks/useApi";

export default function FormatStrumentoTable() {
  useCreaconsultingTitle(`Format strumenti`);

  const hasPermission = useCheckPermission();
  const history = useHistory();

  const formatStrumenti = useDataSource({
    loadAction: getFormatStrumentiAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const loadFormatStrumentiApi = useApi(getFormatStrumentiAction);
  const handleFormatStrumentoUpdated = useCallback(
    (formatStrumento) => {
      loadFormatStrumentiApi.callApi().then((response) => {
        const freshFormatStrumento = response.data?.find(
          (f) => f.id === formatStrumento.id
        );
        if (freshFormatStrumento) {
          formatStrumenti.changeValue(
            freshFormatStrumento.id,
            freshFormatStrumento
          );
        }
      });
    },
    [formatStrumenti]
  );

  const { openSideModal, sideModal } = useSideModal();

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_format_strumenti"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {sideModal}
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        Format strumenti
      </h1>
      {formatStrumenti.loading ? (
        "Caricamento lista format strumenti ..."
      ) : formatStrumenti.loadError ? (
        "Errore durante il caricamento della lista format strumenti"
      ) : (
        <DataTable
          source={formatStrumenti}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 100,
              header: " ",
              renderCell: (value) => {
                return (
                  <TableActionButtons
                    link={`/formatStrumenti/${value}`}
                    openSideModal={() => {
                      openSideModal(
                        FormatStrumento,
                        {
                          id: value,
                          useIdFromProps: true,
                          onEditSuccess: handleFormatStrumentoUpdated,
                          edit: true,
                        },
                        value
                      );
                    }}
                    canEdit={hasPermission("modifica_format_strumenti")}
                    editItem={() =>
                      history.push(`/formatStrumenti/${value}?edit=true`)
                    }
                  />
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            { path: ["nome"], header: "Nome", type: "text", width: 250 },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              width: 200,
              type: "enum",
              options: FormatStrumentoStato,
              renderCell: (value) => {
                return <BadgeFormatStrumentoStato stato={value} />;
              },
              renderRawValue: (value) => {
                return (
                  FormatStrumentoStato.find((stato) => stato.value === value)
                    ?.label ?? value
                );
              },
            },
            {
              type: "number",
              width: 180,
              path: ["num_offerte"],
              header: "# Offerte",
              disableColumnFilter: true,
            },
            {
              type: "number",
              width: 180,
              path: ["num_mandati"],
              header: "# Mandati",
              disableColumnFilter: true,
            },
            {
              type: "number",
              width: 180,
              path: ["num_procedure_in_corso"],
              header: "# Commesse attive",
              disableColumnFilter: true,
            },
            {
              type: "number",
              width: 180,
              path: ["num_procedure_completate"],
              header: "# Commesse completate",
              disableColumnFilter: true,
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
              }}
            >
              {hasPermission("crea_format_strumenti") ? (
                <ColoredButton
                  disabled={false}
                  onClick={null}
                  color="default"
                  fullWidth={false}
                  size="small"
                  variant="outlined"
                  to="/formatStrumenti/new"
                  component={Link}
                >
                  Crea format strumento
                </ColoredButton>
              ) : null}{" "}
              <DataTableExportButtons filename={"Format strumenti"} />
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessun format strumento",
          }}
        />
      )}{" "}
    </Container>
  );
}
