import React from "react";
import { useToast } from "app/hooks/useToast";
import { useHistory } from "react-router-dom";
import { ColoredButton } from "app/elements/ColoredButton";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import { useDialog } from "app/hooks/useDialog";
import { useSubmitDataSource } from "app/hooks/DataSource/useSubmitDataSource";
import { createNuovaVersioneFormatProcedura as creaNuovaVersioneAction } from "app/actions";

export function useCreaNuovaVersioneFormatProceduraDialog(
  formatProceduraCorrente
) {
  const showToast = useToast();
  const history = useHistory();

  const dialog = useDialog();

  const creaNuovaVersioneFunc = useSubmitDataSource({
    submitAction: creaNuovaVersioneAction,
    activeByDefault: true,
    onSubmitSuccess: (response) => {
      dialog.close();

      showToast("Nuova versione creata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/formatProcedure/" + response.data.id);

      //   creaNuovaVersioneFunc.cancel();
      //   creaNuovaVersioneFunc.activate();
    },
    onSubmitError: () => {
      showToast("Errore durante la creazione di una nuova versione", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const creaNuovaVersioneFormatProceduraDialog = (
    <Dialog maxWidth="md" open={dialog.isOpen}>
      <DialogTitle>Crea nuova versione Format Procedura Commessa</DialogTitle>

      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={creaNuovaVersioneFunc.getValue(
                "changeOldToInattivo",
                false
              )}
              disabled={
                creaNuovaVersioneFunc.loading || creaNuovaVersioneFunc.editing
              }
              onChange={(event) => {
                const value = event.target.checked;
                creaNuovaVersioneFunc.changeValue("changeOldToInattivo", value);
              }}
              size="small"
              value={creaNuovaVersioneFunc.getValue("changeOldToInattivo", "")}
            />
          }
          label="Imposta il format procedura corrente come inattivo"
        />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={() => {
            dialog.close();
          }}
          color="secondary"
          variant="outlined"
          disabled={
            creaNuovaVersioneFunc.loading || creaNuovaVersioneFunc.editing
          }
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          onClick={() => {
            creaNuovaVersioneFunc.submit(formatProceduraCorrente.id);
          }}
          color="primary"
          variant="outlined"
          disabled={
            creaNuovaVersioneFunc.loading || creaNuovaVersioneFunc.editing
          }
        >
          Crea nuova versione
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );

  const openCreaNuovaVersioneFormatProceduraDialog = () => dialog.open();

  return {
    creaNuovaVersioneFormatProceduraDialog,
    openCreaNuovaVersioneFormatProceduraDialog,
  };
}

export function getShowCreaNuovaVersione(linea) {
  if (!linea || !linea.formatProceduraCorrente) {
    return false;
  }

  return _getShowCreaNuovaVersioneInternal(
    linea,
    linea.formatProceduraCorrente
  );
}

export function getShowCreaNuovaVersioneInProcedura(procedura) {
  if (!procedura || !procedura.linea) {
    return false;
  }
  const linea = procedura.linea;

  if (procedura.id !== linea.idFormatProceduraCorrente) {
    return false;
  }

  return _getShowCreaNuovaVersioneInternal(linea, procedura);
}

function _getShowCreaNuovaVersioneInternal(linea, formatProceduraCorrente) {
  const hasFormatProceduraInLavorazione =
    !!linea.idFormatProceduraInLavorazione;

  const showCreaNuovaVersione =
    formatProceduraCorrente &&
    formatProceduraCorrente.stato !== "in-preparazione" &&
    !hasFormatProceduraInLavorazione;

  return showCreaNuovaVersione;
}
