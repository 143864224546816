import React, { useRef, useMemo, useEffect, useState } from "react";
import moment from "moment";

const colors = ["#F53838", "#F59938", "#F5DB38"];

function valueToString(value) {
  return value ? moment(value).format("DD/MM/YYYY") : "";
}

function getColor(thresholds, value) {
  if (value === null) {
    return null;
  }
  const m = moment(value);
  const index = thresholds.findIndex((threshold) => m.isBefore(threshold));
  const color = index === -1 ? colors[colors.length - 1] : colors[index];
  return color + "80";
}

export default function DashboardColoredCell({ value }) {
  const thresholds = useMemo(() => {
    const now = moment();
    // const now = moment('2022-03-24')
    return [moment(now).add(5, "day"), moment(now).add(30, "day")];
  }, []);

  const divRef = useRef(null);
  const trRef = useRef(null);
  const colorRef = useRef(null);

  const _getState = () => {
    return {
      valueString: valueToString(value),
      color: getColor(thresholds, value),
    };
  };

  const [{ valueString, color }, setState] = useState(_getState);
  colorRef.current = color;

  useEffect(() => {
    setState(_getState());
  }, [value, thresholds]);

  const checkColor = () => {
    const tr = trRef.current;
    if (tr) {
      const backgroundColor = tr.style.backgroundColor;
      if (backgroundColor !== colorRef.current) {
        tr.style.backgroundColor = colorRef.current;
      }
    }
  };

  useEffect(() => {
    checkColor();
  }, [trRef.current, colorRef.current]);

  const onRef = (ref) => {
    divRef.current = ref;
    const tr = divRef.current ? divRef.current.closest("tr") : null;
    if (trRef.current !== tr) {
      trRef.current = tr;
      checkColor();
    }
  };

  return <div ref={onRef}>{valueString}</div>;
}
