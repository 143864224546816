import { useEffect } from "react";

export default function useCreaconsultingTitle(
  title: string | null | undefined
) {
  title = title ? `wi.du - ${title}` : "wi.du";

  if (document.title !== title) document.title = title;

  useEffect(function () {
    return function () {
      document.title = "wi.du";
    };
  }, []);
}
