import moment from "moment";
import { useMemo, CSSProperties, useState, useEffect } from "react";
import classNames from "classnames";
import { moveCard } from "@asseinfo/react-kanban";
import TBoard from "react-trello";
import { find, findIndex } from "lodash";
import "./fixReactTrello";

export type SKanbanColumn<D> = {
  id: string;
  title: string;
  cards: SKanbanCard<D>[];
  droppable?: boolean;
};

export type SKanbanCard<D> = {
  id: string;
  data: D;
};

export type SKanbanBoard<D> = {
  columns: SKanbanColumn<D>[];
};

type SKanbanProps<D> = {
  board: SKanbanBoard<D>;
  CardComponent: React.ComponentType<{ data: D }>;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  onCardDragEnd?: (data: D, columnDate: string) => boolean | void;
};

export function SKanban<D>({
  board: _board,
  CardComponent,
  className,
  style,
  disabled,
  onCardDragEnd,
}: SKanbanProps<D>) {
  // const {
  //   // controlled props:
  //   board,
  //   setBoard,
  //   columnsById,
  // } = props;

  const [board, setBoard] = useState(_board);

  useEffect(() => {
    if (_board !== board) {
      setBoard(_board);
    }
  }, [_board]);

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position) => {
    const oldColumn = find(board.columns, (c) => c.id === sourceLaneId);
    if (!oldColumn) {
      return false;
    }
    const oldPosition = findIndex(oldColumn.cards, (c) => c.id === cardId);
    if (oldPosition === -1) {
      return false;
    }
    const card = oldColumn.cards[oldPosition];
    const source = { fromPosition: oldPosition, fromColumnId: sourceLaneId };
    const destination = { toPosition: position, toColumnId: targetLaneId };
    const updatedBoard = moveCard(board, source, destination);
    if (onCardDragEnd) {
      const key = targetLaneId as string;
      const dateString = key.startsWith("date--")
        ? targetLaneId.substring("date--".length)
        : null;
      const result = onCardDragEnd(card.data, dateString);
      if (result === false) {
        return false; // cancel move!
      }
    }
    setBoard(updatedBoard);
  };

  const data = useMemo(() => {
    return board
      ? {
          lanes: board.columns.map((x) => {
            return {
              disallowAddingCard: true,
              ...x,
            };
          }),
        }
      : null;
  }, [board]);

  const TCard = useMemo(() => {
    return ({ data }) => {
      return <CardComponent data={data} />;
    };
  }, [CardComponent]);

  return (
    <div
      className={classNames("creaconsulting-kanban", className)}
      style={style}
    >
      {data && (
        <TBoard
          data={data}
          handleDragEnd={handleDragEnd}
          editable={!disabled}
          cardDraggable={!disabled}
          components={{
            Card: TCard,
          }}
        />
      )}
    </div>
  );
}
