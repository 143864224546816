import React from "react";
import { Typography } from "@material-ui/core";

export default function StatoUtenteBadge(props) {
  const utente = props.utente;

  const stato = utente.disattivato ? "Disattivato" : "Attivo";

  const color = utente.disattivato ? "#e57373" : "#4caf50";

  return (
    <Typography
      style={{
        background: color,
        border: "1px solid transparent",
        borderRadius: 4,
        padding: "3px 5px",
        margin: "8px 8px",
      }}
      align="inherit"
      color="inherit"
      display="inline"
      gutterBottom={true}
      noWrap={false}
      paragraph={false}
      variant="caption"
    >
      {stato}{" "}
    </Typography>
  );
}
