import React, { useCallback, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory } from "react-router-dom";
import {
  getClienti as getClientiAction,
  createCliente as createClienteAction,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import useSideModal from "app/components/common/useSideModal";
import { Container } from "@material-ui/core";
import DialogCreaCliente from "app/components/Clienti/DialogCreaCliente";
import AtokaSearch from "app/components/Clienti/AtokaSearch";
import { DataTable } from "app/elements/DataTable";
import Cliente from "app/components/Clienti/Cliente";
import StatoClienteBadge from "app/components/common/StatoClienteBadge";
import DimensioneAzienda from "app/constants/DimensioneAzienda";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTableGlobalFilter } from "app/elements/DataTable/DataTableGlobalFilter";
import SearchIcon from "@material-ui/icons/Search";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";
import TableActionButtons from "../common/TableActionButtons";

export default function ClientiTable(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const history = useHistory();

  const _getClientiAction = props.getClientiAction || getClientiAction;

  const clienti = useDataSource({
    initialData: [],
    loadAction: _getClientiAction,
    createAction: createClienteAction,
    onCreateSuccess: (response) => {
      showToast("Cliente creato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push(`/azienda/${response.data.id}`);
    },
    onCreateError: (response) => {
      showToast("Errore durante la creazione del cliente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    skipCheckUncommittedChanged: true,
    autoLoad: false, // table pagination
  });

  const handleClienteUpdated = useCallback(
    (cliente) => {
      clienti.changeValue(cliente.id, cliente);
    },
    [clienti]
  );

  const [newClienteId, setNewClienteId] = useState(null);

  const [openAtokaCreate, setOpenAtokaCreate] = useState(false);

  const { openSideModal, sideModal } = useSideModal();

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_azienda"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        __height: "calc(100vh - 160px)",
        height: "calc(100vh - 160px - 400px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      <DialogCreaCliente
        clienti={clienti}
        newClienteId={newClienteId}
        cancel={() => {
          setNewClienteId(null);
        }}
      />

      <AtokaSearch
        open={openAtokaCreate}
        cancel={() => {
          setOpenAtokaCreate(false);
        }}
      />
      {sideModal}

      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        {props.title || "Aziende"}{" "}
      </h1>

      <div>
        <DataTable
          source={clienti}
          columns={[
            {
              path: ["id"],
              width: 105,
              sortable: false,
              disableColumnFilter: true,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TableActionButtons
                    link={`/azienda/${value}`}
                    openSideModal={() => {
                      openSideModal(
                        Cliente,
                        {
                          id: value,
                          useIdFromProps: true,
                          onEditSuccess: handleClienteUpdated,
                          edit: true,
                        },
                        value
                      );
                    }}
                    canEdit={hasPermission("modifica_azienda")}
                    editItem={() => history.push(`/azienda/${value}?edit=true`)}
                  />
                );
              },
              type: "text",
              hiddenInExport: true,
            },
            {
              sortable: true,
              path: ["ragioneSociale"],
              width: 300,
              header: "Ragione Sociale",
              type: "text",
            },
            {
              sortable: true,
              path: ["commerciale", "cognome"],
              width: 300,
              header: "Commerciale",
              type: "text",
            },
            {
              sortable: true,
              path: ["regione"],
              header: "Regione",
              width: 150,
              type: "text",
            },
            {
              sortable: true,
              path: ["comune"],
              header: "Comune",
              width: 150,
              type: "text",
            },
            {
              sortable: true,
              path: ["cap"],
              width: 150,
              header: "CAP",
              type: "number",
            },
            {
              sortable: true,
              path: ["cf"],
              width: 180,
              header: "Codice Fiscale",
              type: "text",
            },
            {
              sortable: true,
              path: ["partitaIva"],
              width: 180,
              header: "Partita IVA",
              type: "text",
            },
            {
              sortable: true,
              path: ["email"],
              width: 250,
              header: "Email",
              type: "text",
            },
            {
              sortable: true,
              path: ["telefono"],
              width: 180,
              header: "Telefono",
              type: "text",
            },
            {
              sortable: true,
              path: ["keywords"],
              width: 180,
              header: "Keywords",
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <>
                    {Array.isArray(value)
                      ? value.map((x) => <span>{x} </span>)
                      : value}
                  </>
                );
              },
            },
            {
              sortable: true,
              path: ["stato"],
              width: 160,
              header: "Stato",
              renderCell: (value, row, rowIndex, rowSource) => {
                return <StatoClienteBadge stato={value} />;
              },
              renderRawValue: (value) => value,
              type: "text",
            },
            {
              sortable: true,
              path: ["dimensioneAzienda"],
              width: 190,
              header: "Dimensione",
              type: "enum",
              options: DimensioneAzienda,
            },
          ]}
          topToolbar={
            <Container
              style={{
                marginBottom: 10,
                paddingTop: 16,
                display: "flex",
              }}
              disableGutters={false}
              fixed={false}
              maxWidth="lg"
            >
              <div
                style={{
                  marginTop: 10,
                }}
              >
                {hasPermission("crea_azienda") ? (
                  <>
                    <ColoredButton
                      disabled={false}
                      onClick={(event) => {
                        setNewClienteId("new-123");
                      }}
                      color="default"
                      fullWidth={false}
                      size="small"
                      variant="outlined"
                    >
                      Crea Azienda
                    </ColoredButton>{" "}
                    {hasPermission("scarica_dati_atoka") &&
                      window.atokaEnabled && (
                        <ColoredButton
                          disabled={false}
                          onClick={(event) => {
                            setOpenAtokaCreate(true);
                          }}
                          color="default"
                          fullWidth={false}
                          size="small"
                          variant="outlined"
                        >
                          Crea con Atoka
                        </ColoredButton>
                      )}
                  </>
                ) : null}{" "}
                <DataTableExportButtons
                  filename={props.exportFilename || "Aziende"}
                />
              </div>

              <DataTableGlobalFilter
                style={{
                  marginLeft: "auto",
                }}
                placeholder="Cerca..."
                size="small"
                variant="outlined"
                startAdornment={<SearchIcon />}
              />
            </Container>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          size="small"
          saveCurrentState="t"
          messages={{
            noRows: clienti.loading ? "Caricamento..." : "Nessuna azienda",
            rowsPerPage: "Righe per pagina",
            search: "Filtra",
          }}
          server
        />
      </div>
    </Container>
  );
}
