import React, { useMemo } from "react";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function AnnoPicker({
  anno,
  setAnno,
  aggiungiProssimoAnno = false,
  label = "Anno",
  fullWidth = undefined,
}) {
  const start = 2023;
  const now = useMemo(() => moment().year(), []);
  const next = now + 1;
  const end = aggiungiProssimoAnno ? next : now;

  const options = useMemo(() => {
    const options = [];
    for (let y = start; y <= end; y++) {
      options.push({
        value: y,
        label: y,
      });
    }
    return options;
  }, [start, end]);

  return (
    <FormControl
      variant="standard"
      style={{ marginLeft: 8, marginRight: 8, marginTop: 0, marginBottom: 0 }}
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel style={{ position: "absolute", marginTop: -11 }}>
          {label}
        </InputLabel>
      )}

      <Select
        value={anno}
        onChange={(e) => {
          setAnno(parseInt(e.target.value, 10));
        }}
        size="small"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
