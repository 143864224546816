import TableCell from "@material-ui/core/TableCell";
import { useCallback, useEffect, useState } from "react";

export function DataTableBodyCell({ column, children }) {
  const align = column?.align;

  const [cell, setCell] = useState();

  return (
    <TableCell
      ref={useCallback((el) => setCell(el), [])}
      align={align}
      style={{
        minWidth: column.minWidth || undefined,
        maxWidth: column.maxWidth || undefined,
      }}
    >
      {children}
      <TableCellTooltip cell={cell}>{children}</TableCellTooltip>
    </TableCell>
  );
}

function isOverflow(el: HTMLElement): boolean {
  const curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === "visible") {
    el.style.overflow = "hidden";
  }

  const isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverflow;
  return isOverflowing;
}

function TableCellTooltip({ children, cell }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (cell) {
      const f = (event) => {
        const overflowing = cell && isOverflow(cell);
        if (overflowing && !show) {
          setShow(true);
        } else if (!overflowing && show) {
          setShow(false);
        }
      };
      cell.addEventListener("mouseover", f);
      return () => {
        cell.removeEventListener("mouseover", f);
      };
    }
  }, [cell]);

  if (!show) {
    return null;
  }

  return <div className="data-table-tooltip-container">{children}</div>;
}
