import NodeContentRenderer from './nodeContentRenderer'
import TreeNodeRenderer from './treeNodeRenderer'

export default {
  nodeContentRenderer: NodeContentRenderer,
  treeNodeRenderer: TreeNodeRenderer,
  // scaffoldBlockPxWidth: 25,
  // rowHeight: 25,
  // slideRegionSize: 50,
}
