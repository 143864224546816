import React from "react";
import { Container } from "@material-ui/core";
import FattureTable_abstract_internal from "./FattureTable_abstract_internal";
import { useCheckPermission } from "app/hooks/useCheckPermission";

export default function FattureTable_abstract(props) {
  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_fattura"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        {props.title}
      </h1>

      <FattureTable_abstract_internal
        fatture={props.fatture}
        isApprovare={props.isApprovare}
        exportFilename={props.exportFilename}
        showInvioMassivo={props.showInvioMassivo}
        showPagamentoMassivo={props.showPagamentoMassivo}
      />
    </Container>
  );
}
