import React, { useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getUtenti as getUtentiAction,
  createUtente as createUtenteAction,
  editUtente as editUtenteAction,
  deleteUtente as deleteUtenteAction,
  activateUtente as activateUtenteAction,
} from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import { Container, IconButton } from "@material-ui/core";
import DialogCreaUtente from "app/components/Utenti/DialogCreaUtente";
import DialogEditUtente from "app/components/Utenti/DialogEditUtente";
import DialogDeleteUtente from "app/components/Utenti/DialogDeleteUtente";
import DialogAttivaUtente from "app/components/Utenti/DialogAttivaUtente";
import { DataTable } from "app/elements/DataTable";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import StatoUtenteBadge from "app/components/Utenti/StatoUtenteBadge";
import RuoliUtente from "app/components/Utenti/RuoliUtente";
import { ColoredButton } from "app/elements/ColoredButton";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";
import { getUserRoles } from "common/roles";
import { DataTableExportButtons } from "app/elements/DataTable/DataTableExportButtons";

export default function UtentiTable(props) {
  const showToast = useToast();

  const utenti = useDataSource({
    loadAction: getUtentiAction,
    initialData: [],
  });

  const [newUtenteId, setNewUtenteId] = useState(null);

  const newUtenteDialog = useDialog();

  const [editingUtenteId, setEditingUtenteId] = useState(null);

  const editingUtenteDialog = useDialog();

  const newUtente = useDataSource({
    parent: utenti,
    pathInParent: [
      {
        tempId: newUtenteId,
      },
    ],
    initialData: {},
    createAction: createUtenteAction,
    onCreateSuccess: (response) => {
      showToast("Utente creato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      newUtenteDialog.close();
    },
    onCreateError: (response) => {
      showToast("Errore durante la creazione dell'utente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const editingUtente = useDataSource({
    parent: utenti,
    pathInParent: [
      {
        id: editingUtenteId,
      },
    ],
    initialData: {},
    editAction: editUtenteAction,
    onEditSuccess: (response) => {
      showToast("Utente modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      editingUtenteDialog.close();
    },
    onEditError: (response) => {
      showToast("Errore durante la modifica dell'utente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const deleteUtenteDialog = useDialog();

  const [deletingUtenteId, setDeletingUtenteId] = useState(null);

  const deleteUtente = useDataSource({
    initialData: {},
    parent: utenti,
    pathInParent: [
      {
        id: deletingUtenteId,
      },
    ],
    itemId: deletingUtenteId,
    deleteAction: deleteUtenteAction,
    onDeleteSuccess: (response) => {
      deleteUtenteDialog.close();

      showToast("Utente eliminato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      utenti.load();
    },
    onDeleteError: (response) => {
      showToast("Errore durante l'eliminazione dell'utente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const activateUtenteDialog = useDialog();

  const [activateUtenteId, setActivateUtenteId] = useState(null);

  const activateUtente = useDataSource({
    initialData: {},
    parent: utenti,
    pathInParent: [
      {
        id: activateUtenteId,
      },
    ],
    itemId: activateUtenteId,
    deleteAction: activateUtenteAction,
    onDeleteSuccess: (response) => {
      activateUtenteDialog.close();

      showToast("Utente attivato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      utenti.load();
    },
    onDeleteError: (response) => {
      showToast("Errore durante l'attivazione dell'utente", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth="xl"
    >
      <DialogCreaUtente
        utenteSource={newUtente}
        dialog={newUtenteDialog}
        onCancel={() => {
          utenti.cancel();
        }}
      />

      <DialogEditUtente
        utenteSource={editingUtente}
        dialog={editingUtenteDialog}
      />

      <DialogDeleteUtente
        onConfirm={() => {
          deleteUtente.delete();
        }}
        dialog={deleteUtenteDialog}
      />

      <DialogAttivaUtente
        onConfirm={() => {
          activateUtente.delete();
        }}
        dialog={activateUtenteDialog}
      />

      <DataTable
        source={utenti}
        columns={[
          {
            path: ["id"],
            width: 100,
            sortable: false,
            disableColumnFilter: true,
            header: " ",
            renderCell: (value, row, rowIndex, rowSource) => {
              return (
                <div>
                  <IconButton
                    onClick={(event) => {
                      setEditingUtenteId(row.id);

                      editingUtenteDialog.open();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  {!row.disattivato ? (
                    <IconButton
                      onClick={(event) => {
                        setDeletingUtenteId(row.id);

                        deleteUtenteDialog.open();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(event) => {
                        setActivateUtenteId(row.id);

                        activateUtenteDialog.open();
                      }}
                    >
                      <ToggleOnIcon />
                    </IconButton>
                  )}{" "}
                </div>
              );
            },
            type: "text",
            hiddenInExport: true,
          },
          {
            path: ["disattivato"],
            width: 150,
            header: "Stato",
            renderCell: (value, row, rowIndex, rowSource) => {
              return (
                <div>
                  <StatoUtenteBadge readOnly={true} utente={row} />
                </div>
              );
            },
            renderRawValue: (value, row) => {
              const stato = row.disattivato ? "Disattivato" : "Attivo";
              return stato;
            },
            type: "text",
          },
          { path: ["nome"], width: 200, header: "Nome", type: "text" },
          { path: ["cognome"], width: 200, header: "Cognome", type: "text" },
          { path: ["email"], width: 200, header: "email", type: "text" },
          {
            path: ["roles"],
            width: 350,
            header: "Ruoli",
            sortable: false,
            renderCell: (value, row, rowIndex, rowSource) => {
              return (
                <div style={{ whiteSpace: "normal" }}>
                  <RuoliUtente readOnly={true} roles={row.roles} />
                </div>
              );
            },
            renderRawValue: (value, row) => {
              const roles = getUserRoles(row)
                .map(([role], index) => role)
                .join(", ");
              return roles;
            },
            type: "text",
          },
          {
            path: ["defaultWeeklyAvaibility"],
            width: 180,
            header: "Disponibilità settimanale (ore)",
            type: "number",
          },
        ]}
        topToolbar={
          <div
            style={{
              padding: "1em",
            }}
          >
            <ColoredButton
              style={{
                margin: 8,
              }}
              disabled={false}
              onClick={(event) => {
                setNewUtenteId(
                  utenti.create({
                    roles: {},
                  })
                );

                newUtenteDialog.open();
              }}
              color="default"
              fullWidth={false}
              size="small"
              variant="outlined"
            >
              Crea Utente
            </ColoredButton>{" "}
            <DataTableExportButtons filename={"Utenti"} />
          </div>
        }
        bottomToolbar={
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            />

            <DataTablePagination />
          </div>
        }
        showFilterRow={true}
        size="small"
        saveCurrentState="t"
      />
    </Container>
  );
}
