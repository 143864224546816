import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import LeggeStato from "app/constants/LeggeStato.json";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getLegge as getLeggeAction,
  createLegge as createLeggeAction,
  editLegge as editLeggeAction,
  deleteLegge as deleteLeggeAction,
  duplicateLegge as duplicateLeggeAction,
  getRegimi as getRegimiAction,
  getEnti as getEntiAction,
  getAmbitiDiApplicazione as getAmbitiDiApplicazioneAction,
} from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import { useSubmitDataSource } from "app/hooks/DataSource/useSubmitDataSource";
import { useEffectOnSearchValue } from "app/utils/extractURLSearchValue";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
} from "@material-ui/core";
import BackButton from "app/components/common/BackButton";
import { ColoredButton } from "app/elements/ColoredButton";
import LaunchIcon from "@material-ui/icons/Launch";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { AsyncSelect } from "app/elements/AsyncSelect";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import DizionarioSelect from "app/components/common/DizionarioSelect";
import NumberInput from "app/components/common/NumberInput";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileBox from "app/components/common/FileBox";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LineeAccordion from "./LineeAccordion";
import useCreaconsultingTitle from "../../hooks/useCreaconsultingTitle";

export default function Legge(props) {
  const hasPermission = useCheckPermission();
  const showToast = useToast();
  const history = useHistory();

  const { id } = props.useIdFromProps ? props : useParams();

  const isNew = id === "new";

  const [editing, setEditing] = useState(isNew);
  const readOnly = !editing;

  const legge = useDataSource({
    loadAction: getLeggeAction,
    createAction: createLeggeAction,
    deleteAction: deleteLeggeAction,
    editAction: editLeggeAction,
    itemId: id,
    autoLoad: !!id && !isNew,

    initialData: {
      stato: "in-elaborazione",
    },
    onCreateSuccess: (response) => {
      setEditing(false);

      showToast("Legge creata con successo", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push(`/leggi/${response.data.id}`);
    },
    onCreateError: (response) => {
      showToast(
        response.message
          ? "Errore: " + response.message
          : "Errore durante la creazione della legge",
        { color: "error", horizontal: "left", vertical: "bottom" }
      );
    },
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Legge modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: () => {
      showToast("Errore durante la modifica della legge", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onDeleteSuccess: () => {
      showToast("Legge eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/leggi");
    },
    onDeleteError: (response) => {
      showToast(
        response?.message || "Errore durante l'eliminazione della legge",
        { color: "error", horizontal: "left", vertical: "bottom" }
      );
    },
  });
  useCreaconsultingTitle(
    isNew ? "Nuova legge" : `Legge ${legge.getValue("nome")}`
  );

  const agevolazioni = useDataSource({
    parent: legge,
    pathInParent: ["agevolazioni"],
    initialData: [],
  });

  const milestonePagamenti = useDataSource({
    parent: legge,
    pathInParent: ["milestonePagamenti"],
    initialData: [],
  });

  const deleteDialog = useDialog();

  const isVariabileUsed = (variabile) => {
    return (
      milestonePagamenti.data.findIndex((milestone) => {
        return milestone.idVariabile === variabile.id;
      }) >= 0
    );
  };

  const duplicateLegge = useSubmitDataSource({
    submitAction: duplicateLeggeAction,
    onSubmitSuccess: (response) => {
      showToast("Legge duplicata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/leggi/" + response.data.id);
    },
    onSubmitError: () => {
      showToast("Errore durante la duplicazione della legge", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const stato = legge.getValue("stato");

  const canEditIsPluriennaleEtc = !legge.getValue("linee")?.length;

  const regimi = useDataSource({
    initialData: [],
    loadAction: getRegimiAction,
    autoLoad: true,
  });

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_legge") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_legge")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_legge"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      className={readOnly ? "crea-readOnly" : ""}
      disableGutters={true}
      fixed={false}
      maxWidth="xl"
    >
      <h2
        className="page-title-header page-title-header-sticky"
        style={{
          marginTop: -20,
          marginBottom: 0,
        }}
      >
        <BackButton defaultBackLink="" />
        Legge
      </h2>
      {legge.loading ? (
        "Caricamento legge in corso ..."
      ) : legge.loadError ? (
        "Errore durante il caricamento della legge"
      ) : (
        <>
          <div
            className="page-title-header-sticky-buttons-row"
            style={{
              display: "flex",
              marginBottom: "1em",
            }}
          >
            <Typography
              style={{
                flex: 1,
              }}
              component="h2"
              gutterBottom={true}
              variant="h6"
            >
              {legge.getValue("nome")}
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 8,
              }}
            >
              {props.isSideModal && readOnly && (
                <ColoredButton
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={false}
                  color="secondary"
                  endIcon={null}
                  fullWidth={false}
                  size="small"
                  startIcon={<LaunchIcon />}
                  variant="outlined"
                  to={`/leggi/${id}?edit=true`}
                  component={Link}
                >
                  Apri
                </ColoredButton>
              )}
              {readOnly ? (
                <>
                  <div>
                    {hasPermission("duplica_legge") ? (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          duplicateLegge.submit(legge.getValue("id"));
                        }}
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                      >
                        Duplica
                      </ColoredButton>
                    ) : null}{" "}
                  </div>

                  <div>
                    {hasPermission("modifica_legge") ? (
                      <ColoredButton
                        style={{
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          setEditing(!editing);
                        }}
                        startIcon={<EditIcon />}
                        variant="outlined"
                      >
                        Modifica
                      </ColoredButton>
                    ) : null}{" "}
                  </div>
                </>
              ) : (
                <>
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      if (isNew) {
                        history.push("/leggi");
                      } else {
                        legge.cancel();
                        setEditing(false);
                      }
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<CancelIcon />}
                    variant="outlined"
                  >
                    {[
                      isNew ? "Torna alla lista leggi" : "Annulla le modifiche",
                    ]}
                  </ColoredButton>

                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      legge.commit();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    {isNew ? "Crea" : "Salva"}
                  </ColoredButton>
                </>
              )}{" "}
              {!isNew &&
              (!legge.getValue("linee") ||
                legge.getValue("linee").length === 0) ? (
                hasPermission("elimina_legge") ? (
                  <ColoredButton
                    style={{
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      deleteDialog.open();
                    }}
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                  >
                    Elimina
                  </ColoredButton>
                ) : null
              ) : null}{" "}
            </div>
          </div>

          <Card>
            <CardContent>
              <Grid container={true} item={false} spacing={2}>
                <Grid container={false} item={true} lg={4} md={3} sm={12}>
                  <TextField
                    disabled={readOnly}
                    error={legge.getValidationErrors("nome").length > 0}
                    fullWidth={true}
                    helperText={legge.getValidationErrors("nome")[0]}
                    label="Nome legge"
                    margin="none"
                    onBlur={() => {
                      legge.runValidation("nome");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      legge.changeValue(
                        "nome",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={legge.getValue("nome", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>

                <Grid container={false} item={true} lg={4} md={3} sm={12}>
                  <TextField
                    disabled={readOnly}
                    error={legge.getValidationErrors("intervento").length > 0}
                    fullWidth={true}
                    helperText={legge.getValidationErrors("intervento")[0]}
                    label="Intervento"
                    margin="none"
                    onBlur={() => {
                      legge.runValidation("intervento");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      legge.changeValue(
                        "intervento",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={legge.getValue("intervento", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>

                <Grid container={false} item={true} lg={2} md={3} sm={12}>
                  <FormControl
                    disabled={true}
                    error={legge.getValidationErrors("stato").length > 0}
                    fullWidth={true}
                    margin="none"
                    size="small"
                  >
                    <InputLabel
                      error={legge.getValidationErrors("stato").length > 0}
                      variant="outlined"
                    >
                      Stato
                    </InputLabel>
                    <AsyncSelect
                      autoWidth={true}
                      label="Stato"
                      native={false}
                      onChange={(event) => {
                        const value = event.target.value;

                        legge.changeValue(
                          "stato",

                          value || null
                        );

                        setTimeout(
                          () => {
                            legge.runValidation("stato");
                          },

                          0
                        );
                      }}
                      onClose={() => {
                        legge.runValidation("stato");
                      }}
                      value={legge.getValue("stato") || ""}
                      variant="outlined"
                      sourceVariable="legge"
                      sourceKey="stato"
                      disabled={true}
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      {LeggeStato.map((option) => {
                        return (
                          <MenuItem
                            key={option.value}
                            dense={false}
                            disableGutters={false}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </AsyncSelect>
                    {legge.getValidationErrors("stato")[0] ? (
                      <FormHelperText>
                        {legge.getValidationErrors("stato")[0]}
                      </FormHelperText>
                    ) : null}{" "}
                  </FormControl>
                </Grid>

                <Grid container={false} item={true} lg={2} md={3} sm={12}>
                  <TextField
                    disabled={readOnly || stato !== "in-elaborazione"}
                    error={legge.getValidationErrors("anno").length > 0}
                    fullWidth={true}
                    helperText={legge.getValidationErrors("anno")[0]}
                    label="Anno"
                    margin="none"
                    onBlur={() => {
                      legge.runValidation("anno");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      legge.changeValue(
                        "anno",

                        value
                      );
                    }}
                    size="small"
                    value={legge.getValue("anno", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly || stato !== "in-elaborazione",

                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      thousandSeparator: false,
                    }}
                  />
                </Grid>

                <div
                  style={{
                    minHeight: 30,
                    width: "100%",
                  }}
                />

                <Grid container={false} item={true} sm={6} xs={12}>
                  <DizionarioSelect
                    source={legge}
                    sourceKey="idEnte"
                    readOnly={readOnly}
                    label="Ente"
                    loadDizionarioAction={getEntiAction}
                  />
                </Grid>

                <Grid container={false} item={true} sm={6} xs={12}>
                  <DizionarioSelect
                    source={legge}
                    sourceKey="idAmbitoDiApplicazione"
                    readOnly={readOnly}
                    label="Ambito di applicazione"
                    loadDizionarioAction={getAmbitiDiApplicazioneAction}
                  />
                </Grid>

                <Grid container={false} item={true} sm={4} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legge.getValue("isPluriennale", false)}
                        disabled={legge.loading || legge.editing}
                        onChange={(event) => {
                          const value = event.target.checked;

                          legge.changeValue("isPluriennale", value);
                        }}
                        size="small"
                        value={legge.getValue("isPluriennale", "")}
                      />
                    }
                    disabled={
                      readOnly ||
                      !canEditIsPluriennaleEtc ||
                      stato !== "in-elaborazione"
                    }
                    label="Pluriennale"
                  />
                </Grid>

                <Grid container={false} item={true} sm={4} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legge.getValue("isMultibene", false)}
                        disabled={legge.loading || legge.editing}
                        onChange={(event) => {
                          const value = event.target.checked;

                          legge.changeValue(
                            "isMultibene",

                            value
                          );
                        }}
                        size="small"
                        value={legge.getValue("isMultibene", "")}
                      />
                    }
                    disabled={
                      readOnly ||
                      !canEditIsPluriennaleEtc ||
                      stato !== "in-elaborazione"
                    }
                    label="Multibene"
                  />
                </Grid>

                <Grid container={false} item={true} sm={4} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legge.getValue("isMulticliente", false)}
                        disabled={legge.loading || legge.editing}
                        onChange={(event) => {
                          const value = event.target.checked;

                          legge.changeValue(
                            "isMulticliente",

                            value
                          );
                        }}
                        size="small"
                        value={legge.getValue("isMulticliente", "")}
                      />
                    }
                    disabled={
                      readOnly ||
                      !canEditIsPluriennaleEtc ||
                      stato !== "in-elaborazione"
                    }
                    label="Multicliente"
                  />
                </Grid>

                <Grid
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  container={false}
                  item={true}
                  lg={10}
                  md={6}
                  sm={12}
                >
                  <TextField
                    disabled={readOnly}
                    error={
                      legge.getValidationErrors("pathCartellaLegge").length > 0
                    }
                    fullWidth={true}
                    helperText={
                      legge.getValidationErrors("pathCartellaLegge")[0]
                    }
                    label="Percorso cartella legge"
                    onBlur={() => {
                      legge.runValidation("pathCartellaLegge");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      legge.changeValue(
                        "pathCartellaLegge",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={legge.getValue("pathCartellaLegge", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>

                <Grid
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  container={false}
                  item={true}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <TextField
                    disabled={readOnly}
                    error={
                      legge.getValidationErrors("dataPresentazione").length > 0
                    }
                    fullWidth={true}
                    helperText={
                      legge.getValidationErrors("dataPresentazione")[0]
                    }
                    label="Data Presentazione"
                    onBlur={() => {
                      legge.runValidation("dataPresentazione");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      legge.changeValue(
                        "dataPresentazione",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={legge.getValue("dataPresentazione", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>

                <Grid
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  container={false}
                  item={true}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <NumberInput
                    currency={true}
                    source={legge}
                    sourceKey="dotazioneFondo"
                    size="small"
                    label="Dotazione fondo"
                    readOnly={readOnly}
                    disabled={readOnly}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  container={false}
                  item={true}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <TextField
                    disabled={readOnly}
                    error={
                      legge.getValidationErrors("riferimentoNormativo").length >
                      0
                    }
                    fullWidth={true}
                    helperText={
                      legge.getValidationErrors("riferimentoNormativo")[0]
                    }
                    label="Riferimento normativo"
                    onBlur={() => {
                      legge.runValidation("riferimentoNormativo");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      legge.changeValue(
                        "riferimentoNormativo",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={legge.getValue("riferimentoNormativo", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Grid
            style={{
              marginTop: 16,
            }}
            container={true}
            item={false}
            spacing={2}
          >
            <Grid container={false} item={true} lg={12} sm={12}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Scheda di sintesi
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      minHeight: 48,
                      width: "100%",
                    }}
                  >
                    <FileBox
                      source={legge}
                      sourceKey="schedaDiSintesi"
                      readOnly={readOnly}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {!isNew && (
            <LineeAccordion legge={legge} editing={editing} regimi={regimi} />
          )}

          <Dialog maxWidth="md" open={deleteDialog.isOpen}>
            <DialogTitle>Conferma eliminazione</DialogTitle>

            <DialogContent>
              <Typography>
                {`Sei sicuro di voler eliminare la legge ${legge.getValue(
                  "nome"
                )}?`}{" "}
              </Typography>
            </DialogContent>

            <DialogActions>
              <ColoredButton
                onClick={() => {
                  deleteDialog.close();
                }}
                color="secondary"
                variant="outlined"
              >
                Annulla
              </ColoredButton>

              <ColoredButton
                onClick={() => {
                  legge.delete();
                }}
                color="primary"
                variant="outlined"
              >
                Elimina
              </ColoredButton>
            </DialogActions>
          </Dialog>
        </>
      )}{" "}
    </Container>
  );
}
