import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextFilter from "./TextFilter";
import NumberFilter from "./NumberFilter";
import BooleanFilter from "./BooleanFilter";
import EnumFilter from "./EnumFilter";
import DateFilter from "./DateFilter";
import { ColumnFilter } from "app/elements/DataTable/filters/types";
import clsx from "clsx";
import { TableColumn } from "app/elements/DataTable/types";

// export const messages = defineMessages({
//   textSearch: {
//     id: 'DataTypeFilter.text.search',
//     defaultMessage: 'Search...'
//   },
//   selectAll: {
//     id: 'DataTypeFilter.text.options.selectAll',
//     defaultMessage: 'Select all'
//   },
//   selectAllFiltered: {
//     id: 'DataTypeFilter.text.options.selectAllFiltered',
//     defaultMessage: 'Select all search results'
//   },
//   empty: {
//     id: 'DataTypeFilter.text.options.empty',
//     defaultMessage: 'Empty'
//   }
// })

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  buttons: {
    display: "flex",
  },
  buttonRight: {
    marginLeft: "auto",
  },
}));

export const useDualInputStyles = makeStyles((theme) => ({
  dualInputContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    "& > span": {
      padding: theme.spacing(0.5),
    },
  },
  dualInput: {
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0,
    width: "unset",
    "& input": {
      width: "unset",
    },
    "& .MuiInputAdornment-root .MuiButtonBase-root": {
      padding: 0,
    },
  },
}));

type TableFilterProps<T extends ColumnFilter> = {
  column?: TableColumn;
  path?: any;
  type: T["type"];
  filterOptions: T;
  setFilter: (newFilter: T) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  close?: any;
  messages: any;
  // eslint-disable-next-line react/no-unused-prop-types
  field?: any;
};

export type ColumnFilterOptionsComponent<T extends ColumnFilter> = (
  props: TableFilterProps<T>
) => React.ReactNode;

const optionsComponentsByFieldType = {
  text: TextFilter,
  number: NumberFilter,
  boolean: BooleanFilter,
  date: DateFilter,
  datetime: DateFilter,
  enum: EnumFilter,
};

const wrapperWidthByType = {
  datetime: 470,
  date: 330,
  time: 290,
  text: "100%",
};

export function TableFilter({
  column,
  path,
  type,
  filterOptions,
  setFilter,
  messages,
}: TableFilterProps<any>) {
  const classes = useStyles();

  if (!path) {
    return <>{"You can't filter this column"}</>;
  }

  const OptionComponent = optionsComponentsByFieldType[type];

  // switch (type) {
  //
  // case 'boolean':
  //   filterElement = (
  //     <BooleanFilter
  //       classes={classes}
  //       field={field}
  //       filterOptions={filterOptions}
  //       setFilter={setFilter}
  //       messages={messages}
  //     />
  //   )
  //   break
  //
  // case 'datetime':
  // case 'date':
  // case 'time':
  //   filterElement = (
  //     <DateFilter
  //       classes={classes}
  //       field={field}
  //       type={type}
  //       filterOptions={filterOptions}
  //       setFilter={setFilter}
  //       messages={messages}
  //     />
  //   )
  //   break
  // }

  return (
    <div
      className={clsx(classes.root, "portalgen-cellFilter")}
      style={{ width: wrapperWidthByType[type] }}
    >
      <div className={classes.container}>
        <OptionComponent
          path={path}
          filterOptions={filterOptions}
          setFilter={setFilter}
          messages={messages}
          column={column}
        />
      </div>
    </div>
  );
}
