import React from "react";
import ClientiTable from "app/components/Clienti/ClientiTable";
import { getClientiClientiAttivi as getClientiAction } from "app/actions";

export default function ClientiTable_ClientiAttivi(props) {
  return (
    <ClientiTable
      title="Aziende - Clienti attivi"
      getClientiAction={getClientiAction}
      exportFilename="Contatti attivi"
    />
  );
}
