import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Avatar } from "@material-ui/core";
import {
  getPriorityIndexForAttivita,
  priorityDescriptions,
  priorityLabels,
} from "../Attivita/AttivitaKanbanCard";
import {
  DEFAULT_WEEKLY_AVAIBILITY,
  getColorPercent,
} from "./CaricoUserSection";

const useStyles = makeStyles({
  table: {},
});

export default function CaricoPerScadenzaSection({
  columns,
  users,
  leftColumnWidth,
  weekColumnWidth,
  rightColumnWidth,
  openKanban,
}) {
  const classes = useStyles();

  return (
    <>
      <Table className={"CaricoScadenzaSection_table " + classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: leftColumnWidth,
                maxWidth: leftColumnWidth,
                borderTop: 0,
              }}
            ></TableCell>
            {columns.map((column) => {
              return (
                <TableCell
                  key={column.id}
                  style={{ width: weekColumnWidth }}
                  align="left"
                >
                  {column.title}
                </TableCell>
              );
            })}
            <TableCell
              align="left"
              style={{ width: rightColumnWidth, borderColor: "transparent" }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {priorityLabels.map((priorityLabel, priorityIndex) => {
            return (
              <TableRow key={priorityIndex} style={{ height: 50 }}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ cursor: "pointer" }}
                  onClick={() => openKanban({ priorityIndex: priorityIndex })}
                >
                  <div>{priorityLabel}</div>
                  <div style={{ fontSize: "0.8em" }}>
                    {priorityDescriptions[priorityIndex]}{" "}
                  </div>
                </TableCell>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      style={{ width: weekColumnWidth }}
                      align="left"
                    >
                      <CaricoPriority
                        priorityIndex={priorityIndex}
                        column={column}
                        users={users}
                      />
                    </TableCell>
                  );
                })}
                <TableCell
                  align="left"
                  style={{ borderColor: "transparent" }}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

function CaricoPriority({ priorityIndex, column, users }) {
  const available = users.reduce(
    (acc, user) =>
      acc +
      (user.weeklyAvailability?.[column.id] ??
        user.defaultWeeklyAvaibility ??
        DEFAULT_WEEKLY_AVAIBILITY),
    0
  );

  const { caricoH, cumulativo, nAttivita } = column.cards.reduce(
    ({ caricoH, cumulativo, nAttivita }, card) => {
      const pi = getPriorityIndexForAttivita(card.data);
      return {
        caricoH:
          caricoH + (pi === priorityIndex ? card.data.orePreviste || 0 : 0),
        cumulativo:
          cumulativo + (pi <= priorityIndex ? card.data.orePreviste || 0 : 0),
        nAttivita: nAttivita + (pi === priorityIndex ? 1 : 0),
      };
    },
    { caricoH: 0, cumulativo: 0, nAttivita: 0 }
  );

  const percent =
    available === 0
      ? caricoH === 0
        ? " - "
        : 100
      : Math.round((caricoH / available) * 100);

  const percentCum =
    available === 0
      ? cumulativo === 0
        ? " - "
        : 100
      : Math.round((cumulativo / available) * 100);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", justifyItems: "stretch" }}
    >
      <div>
        <div>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {percent}%
          </span>{" "}
          <span>
            ({caricoH}/{available}h)
          </span>
        </div>
        <div style={{ color: "#555" }}>{nAttivita} attività</div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <div style={{ fontSize: "0.8em", fontWeight: "lighter" }}>
          Cumulativo:
        </div>
        <div
          style={{
            fontWeight: "bold",
            color: available === 0 ? "#888" : getColorPercent(percentCum),
          }}
        >
          {percentCum}%
        </div>
      </div>
    </div>
  );
}
