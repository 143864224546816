import { useEffect, useRef } from "react";
import { useApi } from "./useApi";
import { isEqual } from "lodash";

export function useLoadApi(action, ...params) {
  const prevParams = useRef(null);

  const { callApi, loading: _loading, data, error, response } = useApi(action);
  const loading = _loading || prevParams.current === null;

  useEffect(() => {
    if (prevParams.current === null || !isEqual(prevParams.current, params)) {
      // @ts-ignore:
      prevParams.current = params;

      callApi(...params);
    }
  }, [action, params]);

  const reload = async () => {
    return await callApi(...params);
  };

  return {
    reload,
    loading,
    data,
    error,
    response,
  };
}
