import React from "react";
import { useSubmitDataSource } from "app/hooks/DataSource/useSubmitDataSource";
import { login as loginAction } from "app/actions";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Link,
} from "@material-ui/core";
import { ColoredButton } from "app/elements/ColoredButton";

export default function Login(props) {
  const loginData = useSubmitDataSource({
    submitAction: loginAction,
    activeByDefault: true,
  });

  return (
    <Container disableGutters={false} fixed={false} maxWidth="xs">
      <div
        style={{
          marginTop: 60,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <img
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src="/logo.png"
          />
        </div>

        <Typography
          align="inherit"
          color="default"
          display="initial"
          gutterBottom={false}
          noWrap={false}
          paragraph={false}
          variant="h5"
        />

        <form
          style={{
            width: "100%",
            marginTop: 8,
          }}
        >
          <TextField
            autoComplete=""
            autoFocus={true}
            color="default"
            defaultValue=""
            disabled={loginData.loading || loginData.editing}
            error={loginData.getValidationErrors("email").length > 0}
            fullWidth={true}
            helperText={loginData.getValidationErrors("email")[0]}
            label="Email"
            margin="normal"
            onBlur={(event) => {
              loginData.runValidation("email");
            }}
            onChange={(event) => {
              const value = event.target.value;

              loginData.changeValue(
                "email",

                value
              );
            }}
            placeholder=""
            required={true}
            value={loginData.getValue("email", "")}
            variant="outlined"
          />

          <TextField
            autoComplete=""
            autoFocus={true}
            color="default"
            defaultValue=""
            disabled={loginData.loading || loginData.editing}
            error={loginData.getValidationErrors("password").length > 0}
            fullWidth={true}
            helperText={loginData.getValidationErrors("password")[0]}
            label="Password"
            margin="normal"
            onBlur={(event) => {
              loginData.runValidation("password");
            }}
            onChange={(event) => {
              const value = event.target.value;

              loginData.changeValue(
                "password",

                value
              );
            }}
            placeholder=""
            required={true}
            type="password"
            value={loginData.getValue("password", "")}
            variant="outlined"
          />

          <ColoredButton
            style={{
              marginTop: 12,
            }}
            disabled={loginData.loading || loginData.editing}
            onClick={(event) => {
              loginData.submit();
            }}
            color="primary"
            endIcon={null}
            fullWidth={true}
            size="medium"
            startIcon={null}
            variant="contained"
          >
            Accedi
          </ColoredButton>

          <Grid
            alignContent="flex-start"
            alignItems="flex-start"
            container={true}
            item={true}
            justify="flex-start"
            wrap="nowrap"
          >
            {loginData.submitError?.message ? (
              <p style={{ color: "#f44336" }}>
                {loginData.submitError.message}
              </p>
            ) : null}{" "}
          </Grid>

          <Grid
            style={{
              display: "none",
            }}
            alignContent="stretch"
            alignItems="flex-start"
            container={true}
            item={false}
            justify="flex-start"
            wrap="nowrap"
          >
            <Grid
              alignContent="stretch"
              alignItems="flex-start"
              container={false}
              item={false}
              justify="flex-start"
              wrap="nowrap"
            >
              <Link variant="body2">Forgot password?</Link>
            </Grid>

            <Grid
              alignContent="stretch"
              alignItems="flex-start"
              container={false}
              item={false}
              justify="flex-start"
              wrap="nowrap"
            >
              <Link variant="body2">Don't have an account? Sign Up</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
