import React from "react";
import ClientiTable from "app/components/Clienti/ClientiTable";
import { getClientiClientiInattivi as getClientiAction } from "app/actions";

export default function ClientiTable_ClientiInattivi(props) {
  return (
    <ClientiTable
      title="Aziende - Clienti inattivi"
      getClientiAction={getClientiAction}
      exportFilename="Contatti inattivi"
    />
  );
}
