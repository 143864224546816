import { ColumnFilterOptionsComponent } from "./index";
import { EnumColumnFilter } from "../DataTable/filters/types";
import { Autocomplete } from "@material-ui/lab";
import { Chip, TextField } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import "./TableFilter.scss";

const EnumFilter: ColumnFilterOptionsComponent<EnumColumnFilter> = ({
  filterOptions,
  setFilter,
  messages,
  column,
}) => {
  const label = messages.textSearch || "Search...";
  const { options = [] } = column as any;
  const isNot = (filterOptions && filterOptions.isNot) || false;
  const selectedOptions = filterOptions?.values || [];

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      onChange={(e, selectedOptions) => {
        if (selectedOptions.length === 0) {
          setFilter(null as any);
        } else {
          setFilter({
            type: "enum",
            values: selectedOptions.map((option) => (option as any).value),
            isNot,
          });
        }
      }}
      options={options}
      value={options.filter((o) => selectedOptions.includes(o.value))}
      getOptionLabel={(option) => (option as any).label}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div className="filter-clear-and-not-buttons-container">
                  <button
                    className={clsx(
                      "table-filter-not-button",
                      isNot && "filter-enabled"
                    )}
                    onClick={() => {
                      if (isNot && selectedOptions.length === 0) {
                        setFilter(null as any);
                      } else {
                        setFilter({ ...filterOptions, isNot: !isNot });
                      }
                    }}
                  >
                    NOT
                  </button>
                  {params.InputProps.endAdornment}
                </div>
              ),
            }}
          />
        );
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={(option as any).label}
            {...getTagProps({ index })}
            size="small"
          />
        ))
      }
    />
  );
};

export default EnumFilter;
