import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export default function BackButton({ defaultBackLink }) {
  const history = useHistory();
  const activeRef = useRef(false);

  useEffect(() => {
    activeRef.current = true;
    return () => {
      activeRef.current = false;
    };
  }, []);

  let onClick;

  if (history.length > 1) {
    onClick = (event) => {
      history.goBack();
      // setTimeout(() => {
      //   if (activeRef.current) {
      //     activeRef.current = false;
      //     if (defaultBackLink) {
      //       history.replace(defaultBackLink);
      //     }
      //   }
      // }, 50);
    };
  } else if (defaultBackLink) {
    onClick = (event) => {
      history.replace(defaultBackLink);
    };
  } else {
    return null;
  }

  return (
    <IconButton onClick={onClick}>
      <ArrowBackIcon />
    </IconButton>
  );
}
