import React, { useMemo } from "react";
import moment from "moment";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

export default function BudgetPicker({
  budgetList,
  budgetId,
  setBudgetId,
  onCreate = null,
  label = "Budget",
  fullWidth = undefined,
}) {
  const options = useMemo(() => {
    const options = [];

    const budgetList2 = budgetList ? [...budgetList] : [];
    budgetList2.sort(sortBudget);

    for (let index = 0; index < budgetList2.length; index++) {
      const budget = budgetList2[index];
      options.push({
        value: budget.id,
        label: `Budget ${budget.anno} (v${budget.versione})${
          budget.approvato ? " - Approvato" : ""
        }${budget.attuale ? " - Attuale" : ""}`,
      });
    }
    return options;
  }, [budgetList]);

  return (
    <FormControl
      variant="standard"
      style={{ marginLeft: 8, marginRight: 8, marginTop: 0, marginBottom: 0 }}
      fullWidth={fullWidth}
    >
      {label && (
        <InputLabel style={{ position: "absolute", marginTop: -11 }}>
          {label}
        </InputLabel>
      )}

      <Select
        value={budgetId || ""}
        onChange={(e) => {
          const v = e.target.value;
          if (v === "new") {
            onCreate();
          } else {
            setBudgetId(parseInt(v, 10));
          }
        }}
        size="small"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
        {onCreate ? <Divider /> : null}
        {onCreate ? <MenuItem value={"new"}>Crea nuovo</MenuItem> : null}
      </Select>
    </FormControl>
  );
}

function sortBudget(a, b) {
  if (a.anno !== b.anno) {
    return -(a.anno - b.anno);
  }
  return -(a.versione - b.versione);
}
