import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFormatAttivitaProcedura as getFormatAttivitaProceduraAction } from "app/actions";

export default function AttivitaScadenzaGiorniDaInfo(props) {
  const {
    attivita,
    dateSourceKey,
    dateDeltaSourceKey,
    dateTypeSourceKey,
    dateIdFormatAttivita,
  } = props;

  const formatProceduraId = attivita.getValue(
    ["formatAttivita", "idFormatProcedura"],
    0
  );

  const arrayFormatAttivita = useDataSource({
    initialData: [],
    itemId: formatProceduraId,
    loadAction: getFormatAttivitaProceduraAction,
    autoLoad: !!formatProceduraId,
  });

  const attivitaFrom =
    arrayFormatAttivita.data.filter(
      (x) =>
        x.id === attivita.getValue(["formatAttivita", dateIdFormatAttivita], 0)
    )[0] || null;
  return attivita.getValue(["formatAttivita", dateTypeSourceKey]) ===
    "delta" ? (
    <div
      style={{
        color: "#666666",
        fontStyle: "italic",
      }}
    >
      {attivita.getValue(["formatAttivita", dateDeltaSourceKey], "-")}

      {" giorni dopo "}

      {attivitaFrom?.nome || "-"}
    </div>
  ) : null;
}
