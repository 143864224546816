/* eslint-disable multiline-ternary */
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { DataTableHeadCell } from "./DataTableHeadCell";
import { DataTableFootCell } from "./DataTableFootCell";
import { DataTableRow } from "./DataTableRow";
import {
  DataTableFilterCell,
  TableClearAllColumnFiltersCell,
} from "./DataTableFilterCell";
import { useDataTableContext } from "app/contexts/DataTableContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TableFooter } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
  },
}));

export function DataTableContent({
  tableClassName = null,
}: {
  tableClassName: string | null;
}) {
  const {
    rows,
    source,
    columns,
    showFilterRow,
    size,
    expandableRows,
    totalColumns,
    messages,
    footerRows,
  } = useDataTableContext();

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Table
        size={size}
        style={{ tableLayout: "fixed" }}
        className={
          "portalgen-data-table" + (tableClassName ? " " + tableClassName : "")
        }
      >
        <TableHead>
          <TableRow>
            {/* <TableSelectCell
            setHeadCellRef={setCellRef}
            onChange={handleRowSelect.bind(null)}
            indeterminate={isIndeterminate}
            checked={isChecked}
            isHeaderCell={true}
            expandedRows={expandedRows}
            expandableRowsHeader={options.expandableRowsHeader}
            expandableOn={options.expandableRows}
            selectableOn={options.selectableRows}
            fixedHeader={options.fixedHeader}
            fixedSelectColumn={options.fixedSelectColumn}
            selectableRowsHeader={options.selectableRowsHeader}
            selectableRowsHideCheckboxes={options.selectableRowsHideCheckboxes}
            onExpand={toggleAllExpandableRows}
            isRowSelectable={true}
            components={components}
          />  */}
            {expandableRows && <TableCell style={{ width: 32 }} />}
            {columns.map((column, index) => {
              if (column.hidden) {
                return null;
              }
              return (
                <DataTableHeadCell
                  key={index}
                  // index={index}
                  // colPosition={colPos}
                  // type="cell"
                  // setCellRef={setCellRef}
                  // sort={column.sort}
                  // sortDirection={column.name === sortOrder.name ? sortOrder.direction : 'none'}
                  // toggleSort={handleToggleColumn}
                  // hint={column.hint}
                  // print={column.print}
                  // options={options}
                  // column={column}
                  // columns={columns}
                  // updateColumnOrder={updateColumnOrder}
                  // columnOrder={columnOrder}
                  // timers={timers}
                  // draggingHook={[dragging, setDragging]}
                  // draggableHeadCellRefs={draggableHeadCellRefs}
                  // tableRef={tableRef}
                  // tableId={tableId}
                  // components={components}
                  column={column}
                >
                  {column.header}
                </DataTableHeadCell>
              );
            })}
          </TableRow>
          {showFilterRow && (
            <TableRow>
              {columns.map((column, index) => {
                const showClearAllFiltersButton =
                  index === 0 && column.disableColumnFilter;
                if (showClearAllFiltersButton) {
                  return <TableClearAllColumnFiltersCell key={index} />;
                }
                if (column.hidden || column.disableColumnFilter) {
                  return <TableCell key={index} />;
                }
                return (
                  <DataTableFilterCell
                    key={index}
                    column={column}
                    columnIndex={index}
                  />
                );
              })}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 ? (
            rows.map((row, rowIndex) => {
              // const isRowSelected = options.selectableRows !== 'none' ? this.isRowSelected(dataIndex) : false
              // const isRowSelectable = this.isRowSelectable(dataIndex)
              // const bodyClasses = options.setRowProps ? options.setRowProps(row, dataIndex, rowIndex) || {} : {}

              // const processedRow = this.processRow(row, columnOrder)

              return (
                <DataTableRow key={row.id} row={row} rowIndex={rowIndex} />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={totalColumns}>
                <Typography
                  variant="body1"
                  align="center"
                  component="div"
                  style={{ color: "gray" }}
                >
                  <em>{messages.noRows || "NO ROW!!!"}</em>
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {footerRows ? (
          <TableFooter>
            {new Array(footerRows).fill(0).map((_, rowIndex) => {
              // const isRowSelected = options.selectableRows !== 'none' ? this.isRowSelected(dataIndex) : false
              // const isRowSelectable = this.isRowSelectable(dataIndex)
              // const bodyClasses = options.setRowProps ? options.setRowProps(row, dataIndex, rowIndex) || {} : {}

              // const processedRow = this.processRow(row, columnOrder)

              let skipColTillIndex = 0;

              return (
                <TableRow key={rowIndex}>
                  {columns.map((column, index) => {
                    if (column.hidden) {
                      skipColTillIndex++;
                      return null;
                    }
                    if (index < skipColTillIndex) {
                      return null;
                    }

                    const cellContent = column.renderFooterCell
                      ? column.renderFooterCell(rowIndex, rows, source)
                      : null;

                    const colSpan = column.renderFooterColSpan;
                    if (colSpan) {
                      skipColTillIndex = index + colSpan;
                    }

                    return (
                      <DataTableFootCell
                        key={index}
                        column={column}
                        colSpan={colSpan}
                      >
                        {cellContent}
                      </DataTableFootCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableFooter>
        ) : null}
      </Table>
    </div>
  );
}
