import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import AbstractTabellaFatture from "./AbstractTabellaFatture";
import { ColoredButton } from "../../elements/ColoredButton";
import DateInput from "../common/DateInput";
import moment from "moment/moment";
import { useApi } from "../../hooks/useApi";
import { invioMassivoFatture } from "../../actions";
import { useToast } from "../../hooks/useToast";

type DialogInvioMassivoProps = {
  dialog: any;
  fattureSource: any;
  onInvioMassivo: () => void;
};

export default function DialogInvioMassivo({
  dialog,
  fattureSource,
  onInvioMassivo,
}: DialogInvioMassivoProps) {
  const fattureInviabili = useMemo(
    () => fattureSource.data.filter((f) => f.stato === "approvata"),
    [fattureSource.data]
  );

  const [daInviare, setDaInviare] = useState<
    {
      id: number;
      numeroFattura?: string;
    }[]
  >([]);
  const [numeroFatturaValidationError, setNumeroFatturaValidationError] =
    useState(false);
  const [date, setDate] = useState(moment());
  const canSend = daInviare.length > 0;

  const showToast = useToast();
  const invioMassivoApi = useApi(invioMassivoFatture, {
    onError: () => {
      showToast("Errore durante l'invio massivo delle fatture", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  function closeDialog() {
    setDaInviare([]);
    dialog.close();
  }

  async function doInvioMassivo() {
    const missingNumero = daInviare.filter((d) => !d.numeroFattura).length > 0;
    if (missingNumero) {
      setNumeroFatturaValidationError(true);
      return;
    } else {
      setNumeroFatturaValidationError(false);
    }

    try {
      await invioMassivoApi.callApi({
        data: date,
        fattureDaInviare: daInviare,
      });
      onInvioMassivo();
      closeDialog();
    } catch (e) {}
  }

  return (
    <Dialog open={dialog.isOpen} fullWidth maxWidth="lg">
      <DialogTitle>Invio massivo</DialogTitle>

      <DialogContent>
        <AbstractTabellaFatture
          fatture={fattureInviabili}
          canSelectFattura
          selectFattura={(index, selected) => {
            const fattura = fattureInviabili[index];
            if (selected) {
              setDaInviare([
                ...daInviare,
                { id: fattura.id, numeroFattura: fattura.numeroFattura },
              ]);
            } else {
              setDaInviare(daInviare.filter((d) => d.id !== fattura.id));
            }
          }}
          isFatturaSelected={(index) => {
            const fattura = fattureInviabili[index];
            return !!daInviare.find((d) => d.id === fattura.id);
          }}
          canInsertNumeroFattura
          onNumeroFatturaChange={(index, n) => {
            const fattura = fattureInviabili[index];
            const d = daInviare.find((d) => d.id === fattura.id);
            if (d) {
              d.numeroFattura = n;
              setDaInviare([...daInviare]);
            }
          }}
          getNumeroFattura={(index) => {
            const fattura = fattureInviabili[index];
            const d = daInviare.find((d) => d.id === fattura.id);
            return (d ? d.numeroFattura : fattura.numeroFattura) || "";
          }}
          showNumeroFatturaErrorIfEmpty={numeroFatturaValidationError}
        />
      </DialogContent>

      <DialogActions>
        <div style={{ display: "flex", width: "100%", paddingLeft: 16 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              color: canSend ? undefined : "gray",
            }}
          >
            <Typography style={{ minWidth: 180 }}>
              Invia {daInviare.length}{" "}
              {daInviare.length === 1 ? "fattura" : "fatture"} in data{" "}
            </Typography>

            <DateInput
              variant="outlined"
              inputVariant="outlined"
              fullWidth
              size="small"
              value={date}
              onChange={setDate}
              disabled={!canSend}
            />
          </div>

          <div style={{ flex: 1 }} />
          <ColoredButton onClick={closeDialog} color="secondary">
            Annulla
          </ColoredButton>
          <ColoredButton
            onClick={doInvioMassivo}
            color="secondary"
            disabled={!canSend}
          >
            Aggiorna stato {daInviare.length === 1 ? "fattura" : "fatture"}
          </ColoredButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}
