import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useUser } from "app/hooks/useUser";
import { UncommittedChangesConfirmationWrapper } from "app/contexts/UncommittedChangesConfirmationContext";
import MainPage from "app/pages/MainPage";
import Dashboard from "app/components/Dashboard/Dashboard";
import NormalPage from "app/pages/NormalPage";
import Login from "app/components/Login";
import ContattiTable from "app/components/Contatti/ContattiTable";
import ContattiTable_Lead from "app/components/Contatti/ContattiTable_Lead";
import Contatto from "app/components/Contatti/Contatto";
import ClientiTable from "app/components/Clienti/ClientiTable";
import ClientiTable_Contatti from "app/components/Clienti/ClientiTable_Contatti";
import ClientiTable_ClientiAttivi from "app/components/Clienti/ClientiTable_ClientiAttivi";
import ClientiTable_ClientiInattivi from "app/components/Clienti/ClientiTable_ClientiInattivi";
import Cliente from "app/components/Clienti/Cliente";
import UtentiTable from "app/components/Utenti/UtentiTable";
import GestioneUtente from "app/components/GestioneUtente";
import LeggiTable from "app/components/LeggiTable";
import Legge from "app/components/Legge/Legge";
import LineeTable from "app/components/Linea/LineeTable";
import Linea from "app/components/Linea/Linea";
import OpportunitaTable from "app/components/OpportunitaTable";
import FormatProceduraTable from "app/components/FormatProcedure/FormatProceduraTable";
import FormatProcedura from "app/components/FormatProcedure/FormatProcedura";
import FormatStrumentoTable from "app/components/FormatStrumenti/FormatStrumentoTable";
import FormatStrumento from "app/components/FormatStrumenti/FormatStrumento";
import ProcedureTable from "app/components/Procedure/ProcedureTable";
import Procedura from "app/components/Procedure/Procedura";
import MandatiTable from "app/components/MandatiTable";
import Mandato from "app/components/Mandato/Mandato";
import AttivitaKanban from "app/components/Attivita/AttivitaKanban";
import Attivita from "app/components/Attivita/Attivita";
import CaricoDiLavoro from "app/components/CaricoDiLavoro/CaricoDiLavoro";
import PagamentiTable from "app/components/Pagamenti/PagamentiTable";
import PagamentiDaFatturareTable from "app/components/Pagamenti/PagamentiDaFatturareTable";
import FattureTable from "app/components/Fatture/FattureTable";
import FattureDaApprovareTable from "app/components/Fatture/FattureDaApprovareTable";
import ImpostazioniDizionari from "app/components/ImpostazioniDizionari/ImpostazioniDizionari";
import ImpostazioniNews from "app/components/ImpostazioniNews/ImpostazioniNews";
import Err from "app/components/Err";
import ScadenziarioCalendar from "./components/Scadenziario/ScadenziarioCalendar";
import OpportunitaInScadenzaTable from "./components/OpportunitaInScadenzaTable";
import { ScadenziarioKanban } from "./components/Scadenziario/ScadenziarioKanban";
import { VolumiContratti } from "./components/VolumiContratti/VolumiContratti";
import { AnalisiCommerciale } from "./components/AnalisiCommerciale/AnalisiCommerciale";
import { Budget } from "./components/Budget/Budget";
import { DashboardCommerciale } from "./components/DashboardCommerciale/DashboardCommerciale";
import RuoliPermessiTable from "./components/Utenti/RuoliPermessiTable";

export default function App() {
  const user = useUser();
  const [shouldAskConfirmation, setShouldAskConfirmation] = useState(false);
  return (
    <BrowserRouter>
      <UncommittedChangesConfirmationWrapper
        shouldAskConfirmation={shouldAskConfirmation}
        setShouldAskConfirmation={setShouldAskConfirmation}
        confirmationMessage="Attenzione, ci sono delle modifiche non salvate. Sei sicuro di voler uscire dalla pagina?"
      >
        <Switch>
          <Route exact={true} path="/">
            {user ? (
              <MainPage>
                <Dashboard />
              </MainPage>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact={true} path="/login">
            {!user ? (
              <NormalPage>
                <Login />
              </NormalPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/rubrica">
            {user ? (
              <MainPage>
                <ContattiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/lead">
            {user ? (
              <MainPage>
                <ContattiTable_Lead />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/contatto/:id">
            {user ? (
              <MainPage>
                <Contatto />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/aziende">
            {user ? (
              <MainPage>
                <ClientiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/contatti">
            {user ? (
              <MainPage>
                <ClientiTable_Contatti />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/clienti-attivi">
            {user ? (
              <MainPage>
                <ClientiTable_ClientiAttivi />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/clienti-inattivi">
            {user ? (
              <MainPage>
                <ClientiTable_ClientiInattivi />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/azienda/:id">
            {user ? (
              <MainPage>
                <Cliente />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/utenti">
            {user ? (
              <MainPage>
                <UtentiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/ruoli_permessi">
            {user ? (
              <MainPage>
                <RuoliPermessiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/gestione-utente">
            {user ? (
              <MainPage>
                <GestioneUtente />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/leggi">
            {user ? (
              <MainPage>
                <LeggiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/leggi/:id">
            {user ? (
              <MainPage>
                <Legge />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/linee">
            {user ? (
              <MainPage>
                <LineeTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/linee/:id">
            {user ? (
              <MainPage>
                <Linea />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/opportunita">
            {user ? (
              <MainPage>
                <OpportunitaTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/opportunita-in-scadenza">
            {user ? (
              <MainPage>
                <OpportunitaInScadenzaTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/formatProcedure">
            {user ? (
              <MainPage>
                <FormatProceduraTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/formatProcedure/:id">
            {user ? (
              <MainPage>
                <FormatProcedura />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/formatStrumenti">
            {user ? (
              <MainPage>
                <FormatStrumentoTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/formatStrumenti/:id">
            {user ? (
              <MainPage>
                <FormatStrumento />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/procedure">
            {user ? (
              <MainPage>
                <ProcedureTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/procedure/:id">
            {user ? (
              <MainPage>
                <Procedura />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/mandati">
            {user ? (
              <MainPage>
                <MandatiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/mandati/:id">
            {user ? (
              <MainPage>
                <Mandato />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/scadenziario">
            {user ? (
              <MainPage containerFullHeight>
                <ScadenziarioCalendar />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/scadenziario-kanban">
            {user ? (
              <MainPage containerFullHeight>
                <ScadenziarioKanban />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/attivita">
            {user ? (
              <MainPage>
                <AttivitaKanban />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/attivita/:id">
            {user ? (
              <MainPage>
                <Attivita />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/carico_lavoro">
            {user ? (
              <MainPage>
                <CaricoDiLavoro />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/pagamenti">
            {user ? (
              <MainPage>
                <PagamentiTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/pagamenti_da_fatturare">
            {user ? (
              <MainPage>
                <PagamentiDaFatturareTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/fatture">
            {user ? (
              <MainPage>
                <FattureTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/fatture_da_approvare">
            {user ? (
              <MainPage>
                <FattureDaApprovareTable />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/dashboard_commerciale">
            {user ? (
              <MainPage>
                <DashboardCommerciale />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/budget">
            {user ? (
              <MainPage>
                <Budget />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/volumi_contratti">
            {user ? (
              <MainPage>
                <VolumiContratti />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/analisi_commerciale">
            {user ? (
              <MainPage>
                <AnalisiCommerciale />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/impostazioniDizionari">
            {user ? (
              <MainPage>
                <ImpostazioniDizionari />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact={true} path="/impostazioniNews">
            {user ? (
              <MainPage>
                <ImpostazioniNews />
              </MainPage>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route>
            <NormalPage>
              <Err />
            </NormalPage>
          </Route>
        </Switch>
      </UncommittedChangesConfirmationWrapper>
    </BrowserRouter>
  );
}
