import { Container, Typography } from "@material-ui/core";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";
import { useToast } from "app/hooks/useToast";
import { useUser } from "app/hooks/useUser";
import React, { useEffect, useMemo, useState } from "react";
import EventoAgendaDialog from "../EventoAgenda/EventoAgendaDialog";
import { SKanban } from "../Kanban/SKanban";
import { useCreateBoardWithTimeColumns } from "../Kanban/useCreateBoardWithTimeColumns";
import { ScadenziarioFilters } from "./ScadenziarioFilters";
import {
  editEventoAgenda,
  getEventiAgenda,
  getEventiAgendaByUser,
  getMieiEventiAgenda,
} from "app/actions";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { EventoAgenda } from "./EventoAgenda";
import { ScadenziarioKanbanCard } from "./ScadenziarioKanbanCard";
import moment from "moment";
import { ColoredButton } from "../../elements/ColoredButton";

export function ScadenziarioKanban() {
  const me = useUser();
  const showToast = useToast();
  const hasPermission = useCheckPermission();

  const [editingEventoId, setEditingEventoId] = useState<null | number | "new">(
    null
  );

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    me.id,
    "scad_kanban_user",
    "all_object"
  );

  const selectUser = (userId) => setUserFilter(userId);

  const [startDate] = useState(() => moment().startOf("isoWeek").toISOString());

  // @ts-ignore
  const eventiAgendaSource = useDataSource({
    initialData: [],
    loadAction: () => {
      let action;
      if (!userFilter) {
        action = getEventiAgenda();
      } else if (me.id === userFilter) {
        action = getMieiEventiAgenda();
      } else {
        action = getEventiAgendaByUser(userFilter);
      }

      return async function actionWithFilteredResult(dispatch, getState) {
        const result = await action(dispatch, getState);

        // if (result?.response?.data && Array.isArray(result?.response?.data)) {
        //   result.response.data = result.response.data.filter((attivita) => {
        //     return true;
        //   });
        // }
        return result;
      };
    },
  });

  const [{ firstLoad, loading, loadError }, setLoading] = useState({
    firstLoad: true,
    loading: true,
    loadError: null,
  });

  useEffect(() => {
    if (!firstLoad) {
      setLoading({
        firstLoad: false,
        loading: true,
        loadError: null,
      });
      eventiAgendaSource.load();
    }
  }, [userFilter]);

  useEffect(() => {
    if (!eventiAgendaSource.loading && loading) {
      setLoading({
        firstLoad: false,
        loading: false,
        loadError: eventiAgendaSource.loadError,
      });
    }
  }, [eventiAgendaSource.loading, eventiAgendaSource.loadError]);

  const board = useCreateBoardWithTimeColumns<EventoAgenda>(
    eventiAgendaSource.data,
    (x: EventoAgenda) => x.dataProgrammata || x.dataEffettiva,
    {
      // getColumnTitle?: (D) => string;
      startDate: startDate,
      minimumColumns: 6,
      addEmptyFutureColumns: 3,
      showEmptyColumns: true,
      showPreviousCardsColumn: true,
      previousCardsColumnTitle: "Scaduti",
      showWithoutDateColumn: "first",
      withoutDateColumnTitle: "Senza data",
    }
  );

  const filteredBoard = useFilterAttivitaScaduteCompletate(board);

  //   const canEdit = false; // TODO: hasPermission(["modifica_scadenziario......?????"]);

  const [onEventDropEventoId, setOnEventDropEventoId] = useState<number | null>(
    null
  );
  const onEventDropEventoSourceOptions: any = {
    parent: eventiAgendaSource,
    pathInParent: [{ id: onEventDropEventoId }],
    itemId: onEventDropEventoId,
    initialData: {},
    editAction: editEventoAgenda,
    onEditSuccess: () => {
      showToast("Evento modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    onEditError: () => {
      showToast("Errore durante la modifica dell'evento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  };
  const onEventDropEventoSource = useDataSource(onEventDropEventoSourceOptions);
  useEffect(() => {
    if (onEventDropEventoId) {
      onEventDropEventoSource.commit().then(() => {
        setOnEventDropEventoId(null);
      });
    }
  }, [onEventDropEventoId]);

  const onEventDrop = (event: EventoAgenda, dateString: string) => {
    if (onEventDropEventoId) {
      return false;
    }
    eventiAgendaSource.changeValue(
      [
        { id: event.id },
        "dataProgrammata", // usiamo sempre questo
      ],
      dateString
    );
    setOnEventDropEventoId(event.id);
  };

  const CardComponent = useMemo(() => {
    const onCardClick = (event) => {
      setEditingEventoId(event.id);
    };

    return (props) => {
      return <ScadenziarioKanbanCard {...props} onClick={onCardClick} />;
    };
  }, [setEditingEventoId]);

  if (!hasPermission(["visualizza_scadenziario"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }

  let content: any;
  if (loading) {
    content = "Caricamento eventi...";
  } else if (loadError) {
    content = "Errore durante il caricamento degli eventi";
  } else {
    content = (
      <SKanban
        board={filteredBoard}
        CardComponent={CardComponent}
        disabled={onEventDropEventoSource.loading}
        onCardDragEnd={onEventDrop}
      />
    );
  }

  return (
    <Container
      className="ScadenziarioCalendar"
      disableGutters
      fixed={false}
      maxWidth={false}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <EventoAgendaDialog
        arraySource={eventiAgendaSource}
        eventoId={editingEventoId}
        onClose={() => {
          setEditingEventoId(null);
        }}
      />

      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <Typography
          style={{
            marginLeft: 30,
          }}
          component="h2"
          gutterBottom
          variant="h5"
        >
          Kanban Commerciale
        </Typography>
        <div style={{ flex: 1 }} />
        <ColoredButton
          onClick={() => setEditingEventoId("new")}
          variant="outlined"
        >
          Crea evento
        </ColoredButton>
        <ScadenziarioFilters userFilter={userFilter} selectUser={selectUser} />
      </div>

      {content}
    </Container>
  );
}

function useFilterAttivitaScaduteCompletate(board) {
  const [scaduteColumn, ...otherColumns] = board.columns;
  return {
    columns: [
      {
        ...scaduteColumn,
        cards: scaduteColumn.cards.filter(({ data }) => !data.dataEffettiva),
      },
      ...otherColumns,
    ],
  };
}
