import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { DataTable } from "app/elements/DataTable";
import VariabileAgevolazioneValue2 from "app/components/common/VariabileAgevolazioneValue2";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";
import TipoImportoMilestonePagamento from "app/constants/TipoImportoMilestonePagamento";
import MandatoLineaMilestonePagamentiImporto from "app/components/Mandato/MandatoLineaMilestonePagamentiImporto";
import NumberInput from "../common/NumberInput";
import numeral from "numeral";
import BeneLabel from "../common/BeneLabel";

export default function MandatoLineaMilestonesPagamenti(props) {
  const idMandatoLinea = props.idMandatoLinea;

  const formatStrumento = props.formatStrumento;

  const variant = props.variant;

  const readOnly = props.readOnly;

  const mlMilestonesPagamenti = useDataSource({
    initialData: [],
    parent: props.mandatoLineaSource,
    pathInParent: ["mlMilestonesPagamenti"],
  });

  const createCopyIfNeeded = (rowSource, path1 = []) => {
    return (path2) => {
      const path = [
        ...(Array.isArray(path1) ? path1 : [path1]),
        ...(Array.isArray(path2) ? path2 : [path2]),
      ];

      const v = rowSource.getValue(path);

      // console.log("createCopyIfNeeded", rowSource, path, v);

      if (typeof v !== "undefined") {
        const mandatoLineeSource = props.mandatoLineeSource;

        const idParentPluriennale =
          rowSource.data?.milestonePagamento?.idParentPluriennale ||
          rowSource.data?.milestonePagamento?.id;

        const otherPaths = [];

        mandatoLineeSource.data.forEach((row, index) => {
          row.mlMilestonesPagamenti.forEach((mlmp, index2) => {
            if (mlmp === rowSource.data) {
              return;
            }
            if (
              mlmp.milestonePagamento?.idParentPluriennale ===
                idParentPluriennale ||
              mlmp.milestonePagamento?.id === idParentPluriennale
            ) {
              otherPaths.push([
                index,
                "mlMilestonesPagamenti",
                index2,
                ...path,
              ]);
            }
          });
        });

        // console.log(
        //   "createCopyIfNeeded other paths",
        //   mandatoLineeSource,
        //   otherPaths
        // );

        otherPaths.forEach((p) => {
          const v2 = mandatoLineeSource.getValue(p, undefined);
          if (typeof v2 === "undefined" || v2 === null || v2 === "") {
            mandatoLineeSource.changeValue(p, v);
          }
        });
      }
    };
  };

  return (
    <DataTable
      source={mlMilestonesPagamenti}
      tableClassName="MandatoLineaMilestonesPagamenti"
      columns={[
        {
          path: ["milestonePagamento", "nome", "nome"],
          header: "Nome milestone",
          type: "text",
          renderCell: (value, row, rowIndex, rowSource) => {
            return (
              <div>
                {value}{" "}
                <VariabileAgevolazioneValue2
                  idVariabile={rowSource.getValue([
                    "milestonePagamento",
                    "idVariabile",
                  ])}
                />
              </div>
            );
          },
        },
        {
          path: ["tipoImporto"],
          header: "Tipo importo",
          renderCell: (value, row, rowIndex, rowSource) => {
            return (
              <FormControl
                disabled={true}
                error={rowSource.getValidationErrors("tipoImporto").length > 0}
                fullWidth={true}
                size="small"
              >
                <InputLabel
                  error={
                    rowSource.getValidationErrors("tipoImporto").length > 0
                  }
                  variant={variant}
                >
                  {}
                </InputLabel>
                <AsyncSelect
                  onChange={(event) => {
                    const value = event.target.value;

                    rowSource.changeValue(
                      "tipoImporto",

                      value || null
                    );

                    setTimeout(
                      () => {
                        rowSource.runValidation("tipoImporto");
                      },

                      0
                    );
                  }}
                  onClose={(event) => {
                    rowSource.runValidation("tipoImporto");
                  }}
                  value={rowSource.getValue("tipoImporto") || ""}
                  variant={variant}
                  sourceVariable="rowSource"
                  sourceKey="tipoImporto"
                  disabled={true}
                  loading={false}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {TipoImportoMilestonePagamento.map((option, index) => {
                    return (
                      <MenuItem
                        key={option.value}
                        dense={false}
                        disableGutters={false}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </AsyncSelect>
                {rowSource.getValidationErrors("tipoImporto")[0] ? (
                  <FormHelperText>
                    {rowSource.getValidationErrors("tipoImporto")[0]}
                  </FormHelperText>
                ) : null}{" "}
              </FormControl>
            );
          },
          disableColumnFilter: false,
          disableGlobalFilter: false,
          width: 200,
          sortable: true,
          type: "text",
        },
        {
          path: ["allaFirma"],
          header: "Pagamento",
          width: 155,
          renderCell: (value, row, rowIndex, rowSource) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      rowSource.getValue("milestonePagamento")?.allaFirma ||
                      false
                    }
                    size="small"
                  />
                }
                disabled={true}
                label="alla firma"
              />
            );
          },
          type: "text",
        },
        {
          path: ["escludi"],
          header: "Escludi",
          renderCell: (value, row, rowIndex, rowSource) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rowSource.getValue("escludi", false)}
                    disabled={rowSource.loading || rowSource.editing}
                    onChange={(event) => {
                      const value = event.target.checked;

                      rowSource.changeValue(
                        "escludi",

                        value
                      );
                    }}
                    size="small"
                    value={rowSource.getValue("escludi", "")}
                  />
                }
                disabled={readOnly}
              />
            );
          },
          disableColumnFilter: false,
          disableGlobalFilter: false,
          width: 80,
          sortable: true,
          type: "text",
        },
        {
          path: ["valore"],
          header: "Importo/percentuale",
          renderCell: (value, row, rowIndex, rowSource) => {
            return (
              <MandatoLineaMilestonePagamentiImporto
                rowSource={rowSource}
                variant={variant}
                readOnly={readOnly}
                onBlurPath={createCopyIfNeeded(rowSource)}
              />
            );
          },
          disableColumnFilter: false,
          disableGlobalFilter: false,
          sortable: true,
          type: "text",
          width: 250,
        },
        {
          path: ["milestonePagamento", "hasMarcheDaBollo"],
          header: "Marche da bollo",
          width: 260,
          renderCell: (value, row, rowIndex, rowSource) => {
            const { milestonePagamento, pagamenti } = row;
            if (!milestonePagamento.hasMarcheDaBollo) {
              return "-";
            }
            const allaFirma = milestonePagamento?.allaFirma || false;

            if (allaFirma) {
              return (
                <MarcheDaBolloInput
                  readOnly={readOnly}
                  mlMilestonePagamentoSource={rowSource}
                  allaFirma={allaFirma}
                  hasPagamento={allaFirma && !!pagamenti[0]}
                  onBlurPath={createCopyIfNeeded(rowSource)}
                />
              );
            }

            return (
              <MarcheDaBolloList
                milestonePagamento={milestonePagamento}
                pagamenti={pagamenti}
                mlMilestonePagamentoDataSource={rowSource}
              />
            );
          },
          sortable: false,
          disableColumnFilter: true,
          disableGlobalFilter: true,
        },
      ]}
      topToolbar={null}
      bottomToolbar={null}
      showFilterRow={false}
      pageSize={1000000}
      size="small"
      expandableRows={false}
      messages={{
        noRows: "Nessuna milestone pagamento",
      }}
    />
  );
}

function MarcheDaBolloInput({
  readOnly,
  mlMilestonePagamentoSource,
  allaFirma,
  hasPagamento,
  onBlurPath,
}) {
  const source = hasPagamento
    ? mlMilestonePagamentoSource.getChildDataSource(["pagamenti", 0])
    : mlMilestonePagamentoSource;

  readOnly = !allaFirma ? true : hasPagamento ? true : readOnly;

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <NumberInput
          label="Numero"
          size="small"
          fullWidth
          variant="outlined"
          source={source}
          sourceKey="numeroMarcheDaBollo"
          readOnly={readOnly}
          disabled={readOnly}
          min={0}
          onBlur={() => !readOnly && onBlurPath?.("numeroMarcheDaBollo")}
        />
        <NumberInput
          label="Valore"
          source={source}
          sourceKey="valoreMarcaDaBollo"
          readOnly={readOnly}
          disabled={readOnly}
          fullWidth
          variant="outlined"
          currency
          min={0}
          onBlur={() => !readOnly && onBlurPath?.("numeroMarcheDaBollo")}
        />
      </div>
    </div>
  );
}

function MarcheDaBolloList({
  mlMilestonePagamentoDataSource,
  milestonePagamento,
  pagamenti,
  readOnly,
}) {
  let array = pagamenti;
  const hasPagamenti = pagamenti && pagamenti.length > 0;
  if (!hasPagamenti) {
    array = [milestonePagamento];
  }

  readOnly = hasPagamenti || readOnly;

  return (
    <div>
      {array.map((x, index) => {
        const { numeroMarcheDaBollo, valoreMarcaDaBollo } = x;

        const bene = x.attivita?.bene ?? null;

        const beneEl = bene ? (
          <>
            <BeneLabel
              bene={bene}
              showIcon
              style={{ fontSize: "0.9em", display: "inline-block" }}
              fontWeight="normal"
            />
            <span style={{ marginRight: 6 }}>{": "}</span>
          </>
        ) : null;

        return (
          <div
            key={index}
            style={{
              whiteSpace: "break-spaces",
              display: "flex",
              gap: 2,
            }}
          >
            {hasPagamenti ? (
              <>
                {beneEl}
                {numeroMarcheDaBollo ?? " - "}
                {numeroMarcheDaBollo === 1 ? " marca" : " marche"} da bollo da
                {` ${
                  valoreMarcaDaBollo
                    ? numeral(valoreMarcaDaBollo).format("0,0.00")
                    : "-"
                } €`}
              </>
            ) : (
              <>
                <NumberInput
                  label="Numero"
                  source={mlMilestonePagamentoDataSource}
                  sourceKey="numeroMarcheDaBollo"
                  readOnly={readOnly}
                  disabled={readOnly}
                  fullWidth
                  variant="outlined"
                  min={0}
                />
                <NumberInput
                  label="Valore"
                  source={mlMilestonePagamentoDataSource}
                  sourceKey="valoreMarcaDaBollo"
                  readOnly={readOnly}
                  disabled={readOnly}
                  fullWidth
                  variant="outlined"
                  currency
                  min={0}
                />
              </>
            )}
          </div>
        );
      })}

      {hasPagamenti && (
        <div
          style={{
            marginTop: 8,
            marginBottom: -4,
            marginLeft: 4,
            color: "gray",
            whiteSpace: "normal",
          }}
        >
          <em>
            I valori delle marche da bollo sono modificabili nella pagina di
            modifica delle attività
          </em>
        </div>
      )}
    </div>
  );
}
