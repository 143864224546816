import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Avatar, Button, IconButton } from "@material-ui/core";
import NumberInput from "app/components/common/NumberInput";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";

export const DEFAULT_WEEKLY_AVAIBILITY = 35;

const colors = ["#F53838", "#F59938", "#b0a55f", "#333", "#6EF538"];

export function getColorPercent(percent) {
  if (percent >= 100) {
    return colors[0];
  }
  if (percent >= 90) {
    return colors[1];
  }
  if (percent >= 70) {
    return colors[2];
  }
  if (percent <= 30) {
    return colors[4];
  }
  return colors[3];
}

const useStyles = makeStyles({
  table: {},
});

export default function CaricoUserSection({
  users,
  columns,
  leftColumnWidth,
  weekColumnWidth,
  rightColumnWidth,
  openKanban,
  updateAvailability,
  scrollToCurrentWeek = false,
}) {
  const classes = useStyles();
  const currentWeekHeaderCellRef = useRef();
  useScrollToCurrentWeek(currentWeekHeaderCellRef, scrollToCurrentWeek);

  return (
    <>
      <Table className={"CaricoUserSection_table " + classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: leftColumnWidth,
                maxWidth: leftColumnWidth,
                borderTop: 0,
              }}
            ></TableCell>
            {columns.map((column) => {
              return (
                <TableCell
                  key={column.id}
                  style={{ width: weekColumnWidth }}
                  align="left"
                  ref={
                    column.isCurrentWeek ? currentWeekHeaderCellRef : undefined
                  }
                >
                  {column.title}
                </TableCell>
              );
            })}
            <TableCell
              align="left"
              style={{ width: rightColumnWidth, borderColor: "transparent" }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id} style={{ height: 50 }}>
              <TableCell
                component="th"
                scope="row"
                style={{ cursor: "pointer" }}
                onClick={() => openKanban({ user: user })}
              >
                <Avatar
                  style={{
                    display: "inline-flex",
                    width: 18,
                    height: 18,
                    marginRight: 8,
                    fontSize: 12,
                  }}
                >
                  {user.cognome?.toUpperCase().charAt(0)}
                </Avatar>
                {user.nome} {user.cognome}
              </TableCell>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    style={{ width: weekColumnWidth, position: "relative" }}
                    align="left"
                  >
                    <CaricoUser
                      user={user}
                      column={column}
                      updateAvailability={updateAvailability}
                    />
                  </TableCell>
                );
              })}
              <TableCell align="left" style={{ borderColor: "transparent" }} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function CaricoUser({ user, column, updateAvailability }) {
  const [showEdit, setShowEdit] = useState(false);
  const [availabilityValue, setAvailabilityValue] = useState(null);

  const available =
    user.weeklyAvailability?.[column.id] ??
    user.defaultWeeklyAvaibility ??
    DEFAULT_WEEKLY_AVAIBILITY;

  const carico = column.cards.reduce((acc, card) => {
    if (card.data.idOperatore === user.id) {
      return acc + (card.data.orePreviste || 0);
    }
    return acc;
  }, 0);

  const percent =
    available === 0 ? " - " : Math.round((carico / available) * 100);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", justifyItems: "stretch" }}
    >
      {showEdit && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            background: "#fafafa",
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NumberInput
            variant="outlined"
            fullWidth={true}
            label="Disponibilità settimanale"
            min={0}
            style={{ marginTop: 7 }}
            value={availabilityValue}
            onChange={(e) => {
              setAvailabilityValue(e.target.value);
            }}
          />
          <div>
            <IconButton
              size="small"
              variant="outlined"
              onClick={() => {
                updateAvailability({
                  user: user,
                  week: column.id,
                  availability: availabilityValue,
                });
                setShowEdit(false);
              }}
              disabled={typeof availabilityValue !== "number"}
            >
              <SaveIcon />
            </IconButton>
          </div>
          <div>
            <IconButton
              size="small"
              variant="outlined"
              onClick={() => setShowEdit(false)}
            >
              <CancelIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div>
        <span
          style={{
            fontWeight: "bold",
            color: available === 0 ? "#888" : getColorPercent(percent),
          }}
        >
          {percent}%
        </span>{" "}
        <span>
          ({carico}/{available}h)
        </span>
      </div>
      {updateAvailability && (
        <div style={{ marginLeft: "auto" }}>
          <a
            style={{
              fontSize: "0.6em",
              lineHeight: "1.1em",
              wordBreak: "break-word",
              width: 60,
              display: "inline-block",
              marginTop: -8,
              marginBottom: -8,
              cursor: "pointer",
              whiteSpace: "break-spaces",
            }}
            onClick={() => {
              setAvailabilityValue(available);
              setShowEdit(true);
            }}
          >
            Modifica disponibilità
          </a>
        </div>
      )}
    </div>
  );
}

function useScrollToCurrentWeek(currentWeekHeaderCellRef, scrollToCurrentWeek) {
  const [scrolledOnce, setScrolledOnce] = useState(false);
  useEffect(() => {
    if (
      scrolledOnce ||
      !currentWeekHeaderCellRef.current ||
      !scrollToCurrentWeek
    ) {
      return;
    }

    currentWeekHeaderCellRef.current.scrollIntoView({
      block: "end", // vertical
      inline: "center", // horizontal
    });
    setScrolledOnce(true);
  }, [currentWeekHeaderCellRef.current, scrolledOnce, scrollToCurrentWeek]);
}
