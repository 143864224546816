import React, { useState, useMemo } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getContatti as getContattiAction } from "app/actions";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import DialogCreaContatto from "app/components/Contatti/DialogCreaContatto";
import { orderBy } from "lodash";

function addLabel(option) {
  const label = `${option.nome || ""} ${option.cognome || ""}`;
  option._label = label;
  option._labelCaseIns = label.toLowerCase();
}

export default function ContattoPicker(props) {
  const showToast = useToast();

  const [selectedContatto, setSelectedContatto] = useState(null);

  const contatti = useDataSource({
    initialData: [],
    loadAction: getContattiAction,
    autoLoad: true,
    onLoadSuccess: (response) => {
      const contatto = response.data.find((c) => {
        return c.id === props.source.getValue(props.sourceKey);
      });

      if (contatto) {
        setSelectedContatto(contatto);
      } else {
        setSelectedContatto(null);
      }
    },
    onLoadError: () => {
      showToast("Error durante il caricamento della lista contatti", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;

  const AutocompleteComp = props.canCreate
    ? AutocompleteWithCreation
    : Autocomplete;
  const otherProps = props.canCreate ? {} : {};

  const options = useMemo(() => {
    let d = contatti.data || [];
    if (props.filterContatti) {
      d = d.filter(props.filterContatti);
    }

    d.forEach((option) => {
      addLabel(option);
    });

    return orderBy(d, ["_labelCaseIns"], ["asc"]);
  }, [contatti.data, props.filterContatti]);

  const value = useMemo(() => {
    if (selectedContatto) {
      addLabel(selectedContatto);
    }
    return selectedContatto;
  }, [selectedContatto]);

  return (
    <AutocompleteComp
      disabled={props.readOnly}
      fullWidth={true}
      loading={contatti.loading}
      loadingText="Caricamento lista contatti ..."
      multiple={false}
      noOptionsText="Nessun contatto"
      onClose={null}
      options={options}
      size="small"
      value={value}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={props.variant || "outlined"}
            label={props.label}
            error={!!sourceError}
            helperText={sourceError}
            InputLabelProps={{ shrink: props.shrink }}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option._label;
      }}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {option._label}
          </div>
        );
      }}
      onChange={(event, value) => {
        setSelectedContatto(value);

        props.source.changeValue(props.sourceKey, value ? value.id : null);
        props.onChange?.(value?.id || null);
      }}
      {...otherProps}
    />
  );
}

const filter = createFilterOptions();

function AutocompleteWithCreation({
  onChange,
  getOptionLabel,
  renderOption,
  ...props
}) {
  const [newContattoId, setNewContattoId] = useState(null);

  return (
    <>
      <DialogCreaContatto
        newContattoId={newContattoId}
        cancel={() => {
          setNewContattoId(null);
        }}
        onCreateSuccess={(newContatto) => {
          setNewContattoId(null);
          onChange(null, newContatto);
        }}
        initialData={{
          stato: "Contatto",
        }}
      />
      <Autocomplete
        {...props}
        onChange={(event, newValue) => {
          if (newValue && newValue.isCreateNew) {
            setNewContattoId("new-123");
            onChange(event, props.value);
          } else {
            onChange(event, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              isCreateNew: true,
              inputValue: params.inputValue,
              title: "Crea nuovo contatto",
            });
          }

          return filtered;
        }}
        // selectOnFocus
        // clearOnBlur
        // handleHomeEndKeys
        // id="free-solo-with-text-demo"
        // options={top100Films}
        getOptionLabel={(option) => {
          if (option && option.isCreateNew) {
            return option.title;
          }

          // Regular option
          return getOptionLabel(option);
        }}
        renderOption={(option) => {
          if (option && option.isCreateNew) {
            return option.title;
          }

          // Regular option
          return renderOption(option);
        }}
        // style={{ width: 300 }}
        // freeSolo
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     label="Free solo with text demo"
        //     variant="outlined"
        //   />
        // )}
      />
    </>
  );
}
