import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";
import {
  deleteBudget,
  deleteBudgetEntry,
  editBudgetEntry,
  getBudget,
  getBudgetList,
  getLeggi,
  getUtenti as getUtentiAction,
} from "app/actions";
import {
  PivotRowGetter,
  PivotTable,
  PivotTableColumn,
} from "../PivotTable/PivotTable";
import { each, map, mapValues, sortBy, sum, uniq } from "lodash";
import { useLoadApi } from "app/hooks/useLoadApi";
import { ContrattiCell } from "../AnalisiCommerciale/ContrattiCell";
import moment from "moment";
import UserFilterSelector from "../common/UserFilterSelector";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";
import { ColoredButton } from "app/elements/ColoredButton";
import DialogCreaBudget from "./DialogCreaBudget";
import BudgetPicker from "../common/BudgetPicker";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { EditBudgetForm } from "./EditBudgetForm";
import { useApi } from "app/hooks/useApi";
import { BudgetChart } from "./BudgetChart";
import EditIcon from "@material-ui/icons/Edit";
import DialogEditBudgetState from "./DialogEditBudgetState";
import { userHasRole } from "../../../common/roles";
import { useToast } from "../../hooks/useToast";
import "./Budget.scss";
import DeleteIcon from "@material-ui/icons/Delete";

type OpenBudgetEditPopupFunc = (
  target: any,
  anno: number,
  mese: number,
  numeroContratti: number,
  importo: number,
  valoriUtenti: any,
  idLegge: number,
  idLinea: number | null,
  nomeLinea: string,
  idUtente: number | undefined | null
) => void;

export function Budget(props) {
  const hasPermission = useCheckPermission();
  const showToast = useToast();

  const canEdit = hasPermission("modifica_budget");
  const canDelete = hasPermission("elimina_budget");
  const canSeeAll = hasPermission("visualizza_budget_tutti");

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    null,
    "budget_user",
    "all_object"
  );

  const [annuale, setAnnuale] = useStateWithSaveCurrentState(
    true,
    "budget_annuale",
    "all_object"
  );

  const [editing, setEditing] = useState(false);

  const [editingStato, setEditingStato] = useState(false);

  const [creazioneOpen, setCreazioneOpen] = useState(false);

  const utenti = useDataSource({
    initialData: [],
    loadAction: getUtentiAction,
    autoLoad: true,
  });

  const utentiData = useMemo(() => {
    const commerciali = (utenti.data || []).filter((user) =>
      userHasRole(user, "Commerciale")
    );
    return sortBy(commerciali, (user) => `${user.cognome} ${user.nome}`);
  }, [utenti.data]);

  const budgetListApi: {
    reload: () => Promise<any>;
    loading: boolean;
    data: any;
    error: any;
    response: any;
  } = useLoadApi(getBudgetList) as any;

  const [budgetId, _setBudgetId] = useState<null | number>(null);

  const leggiApi = useApi(getLeggi);
  useEffect(() => {
    leggiApi.callApi().then();
  }, []);

  const budgetApi = useApi(getBudget);
  const loadBudgetData = async () => {
    if (budgetId) {
      return budgetApi.callApi(budgetId, {
        idCommerciale: userFilter,
      });
    }
  };

  const setBudgetId = (budgetId: number | null) => {
    _setBudgetId(budgetId);
  };

  useEffect(() => {
    const _budgetId = budgetId || getInitialBudgetId(budgetListApi.data);
    if (_budgetId && budgetId !== _budgetId) {
      setBudgetId(_budgetId);
    }
  }, [budgetListApi.data]);

  useEffect(() => {
    loadBudgetData();
  }, [budgetId, userFilter]);

  const budget = (budgetListApi.data || []).find((x) => x.id === budgetId);

  const deleteBudgetApi = useApi(deleteBudget);
  const doDeleteBudget = async () => {
    if (!budgetId || !budget) {
      return;
    }

    let message = `Sei sicuro di voler eliminare il budget "${budget.anno} (v${budget.versione})"?`;
    const additionalWarnings = [
      budget.approvato && "è un budget approvato",
      budget.attuale && "è quello attuale",
    ].filter((x) => !!x);
    if (additionalWarnings.length > 0) {
      message += " Questo " + additionalWarnings.join(" ed ") + "!";
    }
    if (!window.confirm(message)) {
      return;
    }

    deleteBudgetApi
      .callApi(budgetId)
      .then(() => {
        budgetListApi.reload().then((response) => {
          const budgetIdToShow = getInitialBudgetId(response.data) ?? null;
          setBudgetId(budgetIdToShow);
        });
        showToast("Budget eliminato", {
          color: "success",
          horizontal: "left",
          vertical: "bottom",
        });
      })
      .catch(() => {
        showToast("Errore durante l'eliminazione del budget", {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        });
      });
  };

  const onEditingStatoEnded = (reload: boolean) => {
    if (reload) {
      budgetListApi.reload();
    }
    setEditingStato(false);
  };

  const [ambitoFilter, setAmbitoFilter] = useState<string | null>(null);

  const data = useMemo(() => {
    if (!budgetApi.data || !leggiApi.data) {
      return [];
    }

    let data = [...(budgetApi.data as any)];

    if (ambitoFilter) {
      data = data.filter((x) => {
        return x.ambito === ambitoFilter;
      });
    }

    if (editing) {
      const leggi = leggiApi.data as any[];
      for (const legge of leggi) {
        const oneBudgetLineaExists = data.find((d) => d.idLegge === legge.id);
        if (!oneBudgetLineaExists) {
          data.push({
            _lineaFakePlaceholder: true,
            idLegge: legge.id,
            idLinea: null,
            nomeLegge: legge.nome,
            ambito: legge.ambitoDiApplicazione.nome,
            ente: legge.ente.nome,
          });
        }
      }
    }

    data.sort((a, b) => {
      const leggeResult = a.nomeLegge.localeCompare(b.nomeLegge);
      if (leggeResult !== 0) {
        return leggeResult;
      }

      if (a.idLinea === null) {
        return -1;
      } else if (b.idLinea === null) {
        return 1;
      }

      return a.nomeLinea.localeCompare(b.nomeLinea);
    });

    return data;
  }, [budgetApi.data, ambitoFilter, leggiApi.data, editing]);

  const ambiti = useMemo(() => {
    const map = ((budgetApi.data as any) || []).map((x) => {
      return x.ambito;
    });
    return uniq(map);
  }, [budgetApi.data]);

  const getPivotRowsLinea: PivotRowGetter<any> = useCallback(
    (d) => {
      if (!d.idLinea) {
        return {
          key: `legge-${d.idLegge}`,
          label: (
            <BudgetLeggeLabel
              idLegge={d.idLegge}
              idBudget={budgetId}
              onDeleteBudgetLegge={loadBudgetData}
              editing={editing}
            />
          ),
        };
      }

      return {
        key: d.idLinea,
        label: d.nomeLinea,
      };
    },
    [budgetId, editing]
  );

  const getPivotRowsCommerciale: PivotRowGetter<any> = useMemo(() => {
    let userRows = utentiData.map((user: any) => {
      return {
        key: user.id,
        label: `${user.cognome} ${user.nome}`,
      };
    });

    if (userFilter) {
      userRows = userRows.filter((x) => {
        return x.key === userFilter;
      });
    }

    return (d) => {
      return userRows;
    };
  }, [utentiData, userFilter]);

  type EditingState = {
    anno: number;
    mese: number;
    numeroContratti: number;
    importo: number;
    idLegge: number;
    idLinea: number | null;
    nomeLinea: string | null;
    idUtente: number | undefined | null;
    valoriUtenti: {
      [key: string]: {
        numeroContrattiBudget: number;
        importoTotaleContrattiBudget: number;
      };
    };
  };

  const [editingState, setEditingState] = useState<EditingState | null>(null);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const popoverOpen = Boolean(anchorEl);
  const popoverId =
    popoverOpen && editingState
      ? `budget-edit-popover-${editingState.idLinea}-${editingState.idUtente}-${editingState.mese}`
      : undefined;

  const _openEditPopup: OpenBudgetEditPopupFunc = (
    target: any,
    anno: number,
    mese: number,
    numeroContratti: number,
    importo: number,
    valoriUtenti: any,
    idLegge: number,
    idLinea: number | null,
    nomeLinea: string | null,
    idUtente: number | undefined | null
  ) => {
    console.log(
      "_openEditPopup",
      target,
      anno,
      mese,
      idLinea,
      nomeLinea,
      idUtente
    );
    setAnchorEl(target);
    setEditingState({
      anno,
      mese,
      numeroContratti,
      importo,
      idLegge,
      idLinea,
      nomeLinea,
      idUtente,
      valoriUtenti,
    });
  };

  const openEditPopupRef = useRef<OpenBudgetEditPopupFunc>(_openEditPopup);
  openEditPopupRef.current = _openEditPopup;

  const openEditPopup = useCallback<OpenBudgetEditPopupFunc>(
    (
      target: any,
      anno: number,
      mese: number,
      numeroContratti: number,
      importo: number,
      valoriUtenti: any,
      idLegge: number,
      idLinea: number | null,
      nomeLinea: string,
      idUtente: number | undefined | null
    ) => {
      openEditPopupRef.current(
        target,
        anno,
        mese,
        numeroContratti,
        importo,
        valoriUtenti,
        idLegge,
        idLinea,
        nomeLinea,
        idUtente
      );
    },
    []
  );

  const handleCloseBudget = () => {
    setAnchorEl(null);
    setEditingState(null);
  };

  const handleSaveBudget = async () => {
    await loadBudgetData();
    setAnchorEl(null);
    setEditingState(null);
  };

  if (!hasPermission(["visualizza_volumi_contratti"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  if (utentiData.length <= 0) {
    return (
      <div>
        Il sistema non contiene alcun utente con ruolo commerciale. Aggiungi il
        ruolo commerciale ad almeno un utente per utilizzare lo strumento
        Budget.
      </div>
    );
  }

  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          marginBottom: 10,
        }}
      >
        <h1
          className="page-title-header"
          style={{
            marginTop: -20,
            marginBottom: 0,
          }}
        >
          {budget
            ? `Budget ${budget.anno} (v${budget.versione})${
                budget.approvato ? " - Approvato" : ""
              }${budget.attuale ? " - Attuale" : ""}`
            : "Budget"}
        </h1>
        {canEdit && budget && (
          <div
            style={{
              marginLeft: 5,
              marginTop: -20,
              zoom: 0.7,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setEditingStato(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        )}
        <div style={{ marginLeft: "auto" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <ButtonGroup color="primary" size="small" disableElevation>
              <Button
                variant={!editing && annuale ? "contained" : "outlined"}
                onClick={() => {
                  setAnnuale(true);
                  setEditing(false);
                }}
              >
                Annuale
              </Button>
              <Button
                variant={!editing && !annuale ? "contained" : "outlined"}
                onClick={() => {
                  setAnnuale(false);
                  setEditing(false);
                }}
              >
                Mensile
              </Button>
              {canEdit && (
                <Button
                  variant={editing ? "contained" : "outlined"}
                  onClick={() => {
                    setAnnuale(false);
                    setEditing(true);
                    setUserFilter(null);
                  }}
                >
                  Modifica
                </Button>
              )}
            </ButtonGroup>
          </div>
          {budget && canDelete && (
            <ColoredButton
              variant="outlined"
              color="error"
              onClick={doDeleteBudget}
              style={{ marginLeft: 8 }}
            >
              Elimina
            </ColoredButton>
          )}
          <AmbitoSelect
            value={ambitoFilter}
            onChange={setAmbitoFilter}
            valori={ambiti}
          />
          <UserFilterSelector
            key={editing ? "editing" : "normal"}
            disabled={editing}
            returnValue="id"
            defaultSelection="all"
            label="Operatore"
            onChange={setUserFilter}
            value={userFilter}
            canAllEAltriUtenti={canSeeAll}
            userRole="Commerciale"
          />
          <BudgetPicker
            budgetList={budgetListApi.data || []}
            budgetId={budgetId}
            setBudgetId={(budgetId) => {
              setBudgetId(budgetId);
            }}
            label="Budget"
            onCreate={
              canEdit
                ? ((() => {
                    setCreazioneOpen(true);
                  }) as any)
                : undefined
            }
          />
        </div>
      </div>

      {budgetListApi.loading || utenti.loading ? (
        "Caricamento dati..."
      ) : budgetListApi.error || utenti.loadError ? (
        "Errore durante il caricamento dei dati"
      ) : !budget ? (
        <div>
          <Typography variant="body1">Non ci sono budget creati</Typography>
          <ColoredButton
            onClick={(event) => {
              setCreazioneOpen(true);
            }}
            color="default"
            fullWidth={false}
            size="small"
            variant="outlined"
          >
            Crea budget
          </ColoredButton>
        </div>
      ) : (
        <BudgetInner
          data={data}
          loading={budgetApi.loading}
          error={budgetApi.error}
          budgetId={budget.id}
          anno={budget.anno}
          userFilter={userFilter}
          annuale={annuale}
          editable={editing}
          openEditPopup={openEditPopup}
          getPivotRowsLinea={getPivotRowsLinea}
          getPivotRowsCommerciale={getPivotRowsCommerciale}
          editing={editing}
          idBudget={budgetId}
          loadBudgetData={loadBudgetData}
        />
      )}

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {editingState && (
          <EditBudgetForm
            idBudget={budget.id}
            idLegge={editingState.idLegge}
            idLinea={editingState.idLinea}
            nomeLinea={editingState.nomeLinea}
            utente={
              editingState.idUtente
                ? utentiData.find((x) => x.id === editingState.idUtente)
                : null
            }
            onClose={handleCloseBudget}
            onSave={handleSaveBudget}
            mese={editingState.mese}
            numeroContratti={editingState.numeroContratti}
            importo={editingState.importo}
            valoriUtenti={editingState.valoriUtenti}
            utenti={utentiData}
          />
        )}
      </Popover>

      <DialogCreaBudget
        open={creazioneOpen}
        cancel={() => {
          setCreazioneOpen(false);
        }}
        created={(newBudget) => {
          setCreazioneOpen(false);
          setBudgetId(newBudget.id);
          budgetListApi.reload();
        }}
        budgetList={budgetListApi.data || []}
        initialBaseId={budget?.id ?? null}
      />

      <DialogEditBudgetState
        open={editingStato}
        anno={budget?.anno}
        onClose={onEditingStatoEnded}
        budgetList={budgetListApi.data || []}
      />
    </Container>
  );
}

function BudgetInner(props) {
  const {
    data,
    loading,
    error,
    anno,
    userFilter,
    annuale,
    budgetId,
    editable,
    getPivotRowsLinea,
    getPivotRowsCommerciale,
    openEditPopup,
    editing,
    idBudget,
    loadBudgetData,
  } = props;

  const columns = useMemo(() => {
    return createColumns(anno, annuale, editable, openEditPopup);
  }, [anno, annuale, editable, openEditPopup]);

  const pivotRowsGetter: [PivotRowGetter<any>, PivotRowGetter<any>] = useMemo(
    () => [getPivotRowsLinea, getPivotRowsCommerciale],
    [getPivotRowsLinea, getPivotRowsCommerciale]
  );
  const pivotRowsLabel: [string, string] = useMemo(
    () => ["Linea", "Commerciale"],
    []
  );

  const subheaderKeyGetter = useCallback((data) => data.idLegge, []);
  const subheaderRenderer = useCallback(
    (dataLinea) => (
      <LeggeSubHeader
        idBudget={idBudget}
        data={data}
        idLegge={dataLinea.idLegge}
        nomeLegge={dataLinea.nomeLegge}
        editing={editing}
        onCreatedBudgetLegge={() => loadBudgetData()}
      />
    ),
    [data, editing]
  );
  const hideRowsGetter = useCallback((data) => data._lineaFakePlaceholder, []);

  return (
    <>
      {!data && loading ? (
        "Caricamento dati..."
      ) : error ? (
        "Errore durante il caricamento dei dati"
      ) : (
        <>
          {" "}
          <BudgetChart data={data || []} anno={anno} />
          <PivotTable
            pivotRowsGetter={pivotRowsGetter}
            pivotRowsLabel={pivotRowsLabel}
            data={data || []}
            columns={columns}
            subheaderKeyGetter={subheaderKeyGetter}
            subheaderRenderer={subheaderRenderer}
            hideRowsGetter={hideRowsGetter}
          />
        </>
      )}
    </>
  );
}

function LeggeSubHeader({
  idBudget,
  idLegge,
  nomeLegge,
  data,
  editing,
  onCreatedBudgetLegge,
}: {
  idBudget: number;
  idLegge: number;
  nomeLegge: string;
  data: any[];
  editing: boolean;
  onCreatedBudgetLegge: () => Promise<void>;
}) {
  const showToast = useToast();
  const [loading, setLoading] = useState(false);

  const saveBudgetEntryApi = useApi(editBudgetEntry, {
    onError: () => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  async function createBudgetLegge() {
    try {
      setLoading(true);
      await saveBudgetEntryApi.callApi(idBudget, {
        idLegge: idLegge,
        idLinea: null,
        idUtente: null,
        mese: 1,
        numeroContratti: 0,
        importo: 0,
        espandiMesiSuccessivi: false,
        espandiMesi: {},
        valoriUtenti: {},
      });
      await onCreatedBudgetLegge();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const hasLineaFake = !!data.find(
    (d) =>
      d.idLegge === idLegge && d.idLinea === null && !d._lineaFakePlaceholder
  );
  const hasLineeBudget = !!data.find(
    (d) => d.idLegge === idLegge && !d._lineaFakePlaceholder
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="budget-subheader">
        <Typography variant="subtitle2">
          <strong>{nomeLegge}</strong>
        </Typography>
        {!hasLineaFake && editing && (
          <Button
            className="budget-subheader-button"
            size="small"
            variant="outlined"
            style={{ display: hasLineeBudget ? "none" : "block" }}
            onClick={() => {
              createBudgetLegge().then();
            }}
            disabled={loading}
          >
            Aggiungi budget senza linea
          </Button>
        )}
      </div>

      {!hasLineaFake && !hasLineeBudget ? (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            paddingTop: 8,
            paddingLeft: 48,
            borderTop: "1px solid rgb(224, 224, 224)",
          }}
        >
          <Typography>
            <em>Nessun budget legge</em>
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

function BudgetLeggeLabel({
  idLegge,
  idBudget,
  onDeleteBudgetLegge,
  editing,
}: {
  idLegge: number;
  idBudget: number | null;
  onDeleteBudgetLegge: () => Promise<void>;
  editing: boolean;
}) {
  const showToast = useToast();
  const [loading, setLoading] = useState(false);

  const deleteBudgetEntryApi = useApi(deleteBudgetEntry, {
    onError: () => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  async function deleteBudgetLegge() {
    if (!idBudget) {
      return;
    }
    if (
      !window.confirm("Sei sicuro di voler eliminare il budget senza linea?")
    ) {
      return;
    }

    try {
      setLoading(true);
      await deleteBudgetEntryApi.callApi(idBudget, {
        idLegge: idLegge,
      });
      await onDeleteBudgetLegge();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="budget-legge-nome-cell">
      <Typography>Budget legge</Typography>
      {editing ? (
        <IconButton
          size="small"
          disabled={loading}
          onClick={() => deleteBudgetLegge().then()}
          className="delete-budget-legge-button"
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

function createColumns(
  anno: number,
  isAnnuale: boolean,
  editable: boolean,
  openEditPopup: OpenBudgetEditPopupFunc
): PivotTableColumn<any>[] {
  const result: PivotTableColumn<any>[] = [];

  if (isAnnuale) {
    result.push(
      {
        label: "Ambito",
        width: 200,
        render: (data) => data[0].ambito,
        style: {
          textAlign: "left",
        },
      },
      {
        label: "Ente",
        width: 200,
        render: (data) => data[0].ente,
        style: {
          textAlign: "left",
        },
      },
      // {
      //   label: "Fisso",
      //   width: 200,
      //   render: (data) => data[0].fisso,
      //   style: {
      //     textAlign: "left",
      //   },
      // },
      {
        label: "# Contratti a budget",
        width: 200,
        render: (data, rowKeys) => (
          <CellRenderers.ContrattiABudget data={data} rowKeys={rowKeys} />
        ),
      },
      {
        label: "# Offerte",
        width: 200,
        render: (data, rowKeys) => (
          <CellRenderers.Offerte data={data} rowKeys={rowKeys} />
        ),
      },
      {
        label: "# Contratti effettivi",
        width: 200,
        render: (data, rowKeys) => (
          <CellRenderers.ContrattiEffettivi
            data={data}
            rowKeys={rowKeys}
            anno={anno}
            onlyCell
          />
        ),
      },
      {
        label: "Scostamento",
        width: 150,
        render: (data, rowKeys) => (
          <CellRenderers.ContrattiEffettivi
            data={data}
            rowKeys={rowKeys}
            anno={anno}
            onlyScostamento
          />
        ),
      }
    );
  } else {
    for (let index = 1; index <= 12; index++) {
      const mese = `${index < 10 ? "0" : ""}${index}`;

      const key = `${anno}-${mese}`;
      const label = `${mese}/${anno}`;

      result.push({
        // key: key,
        label: label,
        render: (data, rowKeys) => {
          return (
            <CellRendererComplessivo
              data={data}
              rowKeys={rowKeys}
              mese={index}
              editable={editable}
              onEdit={(target, n, importo) => {
                let valoriUtenti = {};
                data.forEach((d) => {
                  each(d.users, (userData) => {
                    const { idUtente, mesi } = userData;
                    if (!valoriUtenti[idUtente]) {
                      valoriUtenti[idUtente] = {
                        importoTotaleContrattiBudget: 0,
                        numeroContrattiBudget: 0,
                      };
                    }

                    const meseData = mesi[index] || {};

                    const {
                      importoTotaleContrattiBudget,
                      numeroContrattiBudget,
                    } = meseData;

                    valoriUtenti[idUtente].importoTotaleContrattiBudget +=
                      importoTotaleContrattiBudget || 0;
                    valoriUtenti[idUtente].numeroContrattiBudget +=
                      numeroContrattiBudget || 0;
                  });
                });

                valoriUtenti = mapValues(valoriUtenti, (valoriUtente) => {
                  return mapValues(valoriUtente, (v) => v || null);
                });

                const idLinea =
                  typeof rowKeys[0] === "number" ? rowKeys[0] : null;
                openEditPopup(
                  target,
                  anno,
                  index,
                  n,
                  importo,
                  valoriUtenti,
                  data[0].idLegge,
                  idLinea,
                  data[0].nomeLinea,
                  rowKeys[1]
                );
              }}
            />
          );
        },
        // filter: (d) => d.month === key,
      });
    }
  }

  return result;
}

function filterDataByMese(data: any[], mese: number) {
  data = data.map((x) => {
    return x.mesi?.[mese] || {};
  });
  return data;
}

function filterDataByUser(data: any[], rowKeys: any[]) {
  if (rowKeys.length >= 2) {
    const userId = rowKeys[1];
    data = data.map((x) => {
      return x.users?.[userId] || {};
    });
  }
  return data;
}

const colorBudget = "#3890F5";
const colorEffettivoOk = "#4caf50";
const colorEffettivoError = "#e57373";
const colorOfferta = "#92a5a8";

const CellRenderers = {
  ContrattiABudget: ({ data, rowKeys }: { data: any[]; rowKeys: any[] }) => {
    if (!data) {
      return null;
    }

    data = filterDataByUser(data, rowKeys);

    const n = sum(data.map((x) => x.numeroContrattiBudget || 0));
    const importo = sum(data.map((x) => x.importoTotaleContrattiBudget || 0));

    return (
      <ContrattiCell
        numeroDiContratti={n}
        importo={importo}
        labels={["contratto", "contratti"]}
        color={colorBudget}
      />
    );
  },
  ContrattiEffettivi: ({
    data,
    rowKeys,
    anno,
    onlyCell,
    onlyScostamento,
  }: {
    data: any[];
    rowKeys: any[];
    anno: number;
    onlyCell?: boolean;
    onlyScostamento?: boolean;
  }) => {
    if (!data) {
      return null;
    }

    const isBudgetLegge = data.find((d) => d.idLinea === null);
    if (isBudgetLegge) {
      return <Typography> - </Typography>;
    }

    data = filterDataByUser(data, rowKeys);

    const year = moment().year();
    const month = moment().month() + 1;

    const importoMin = sum(
      data.map((x) => {
        if (anno < year) {
          return x.importoTotaleContrattiBudget || 0;
        }
        if (anno > year) {
          return 0;
        }
        return sum(
          map(x.mesi, (x2, meseString) => {
            const mese = parseInt(meseString);
            if (mese > month) {
              return 0;
            }
            return x2.importoTotaleContrattiBudget || 0;
          })
        );
      })
    );

    const n = sum(data.map((x) => x.numeroContratti || 0));
    const importo = sum(data.map((x) => x.importoContratti || 0));

    const scostamentoPercentuale =
      importoMin === 0
        ? "-"
        : // sign
          (importo > importoMin ? "+" : "") +
          // value %
          (((importo - importoMin) * 100) / importoMin).toFixed(0) +
          "%";

    const cell = (
      <ContrattiCell
        numeroDiContratti={n}
        importo={importo}
        labels={["contratto", "contratti"]}
        color={importo >= importoMin ? colorEffettivoOk : colorEffettivoError}
      />
    );

    if (onlyCell) {
      return cell;
    }

    const scostamento = (
      <div
        style={{
          color:
            importoMin === 0
              ? "#bbb"
              : importo > importoMin
              ? colorEffettivoOk
              : importo < importoMin
              ? colorEffettivoError
              : "#bbb",
          fontSize: onlyScostamento ? undefined : "0.8em",
          fontWeight: "bold",
        }}
      >
        {scostamentoPercentuale}
      </div>
    );

    if (onlyScostamento) {
      return scostamento;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 12,
          alignItems: "center",
        }}
      >
        {cell}
        {scostamento}
      </div>
    );
  },
  Offerte: ({ data, rowKeys }: { data: any[]; rowKeys: any[] }) => {
    if (!data) {
      return null;
    }

    const isBudgetLegge = data.find((d) => d.idLinea === null);
    if (isBudgetLegge) {
      return <Typography> - </Typography>;
    }

    data = filterDataByUser(data, rowKeys);

    const n = sum(data.map((x) => x.numeroOfferte || 0));
    const importo = sum(data.map((x) => x.importoOfferte || 0));

    return (
      <ContrattiCell
        numeroDiContratti={n}
        importo={importo}
        labels={["offerta", "offerte"]}
        color={colorOfferta}
      />
    );
  },
};

function CellRendererComplessivo({
  mese,
  data,
  rowKeys,
  editable,
  onEdit,
}: {
  mese: number;
  data: any[];
  rowKeys: any[];
  editable?: boolean;
  onEdit?: any;
}) {
  if (!data) {
    return null;
  }

  const isBudgetLegge = data.find((d) => d.idLinea === null);

  data = filterDataByUser(data, rowKeys);
  data = filterDataByMese(data, mese);

  const numeroContrattiBudget = sum(
    data.map((x) => x.numeroContrattiBudget || 0)
  );
  const importoTotaleContrattiBudget = sum(
    data.map((x) => x.importoTotaleContrattiBudget || 0)
  );

  const numeroContratti = sum(data.map((x) => x.numeroContratti || 0));
  const importoContratti = sum(data.map((x) => x.importoContratti || 0));

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {/* <div style={{ color: colorBudget, fontSize: "0.8em", lineHeight: 0.9 }}>
        Budget
      </div> */}
      <ContrattiCell
        numeroDiContratti={numeroContrattiBudget}
        importo={importoTotaleContrattiBudget}
        labels={["contratto", "contratti"]}
        color={colorBudget}
        editable={editable}
        onEdit={
          onEdit
            ? (target) =>
                onEdit(
                  target,
                  numeroContrattiBudget,
                  importoTotaleContrattiBudget
                )
            : undefined
        }
      />
      {/* <div
        style={{
          marginTop: 4,
        }}
      /> */}
      <Divider
        orientation="vertical"
        style={{
          height: 25,
          margin: 4,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      />
      {isBudgetLegge ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Typography> - </Typography>
        </div>
      ) : (
        <ContrattiCell
          numeroDiContratti={numeroContratti}
          importo={importoContratti}
          labels={["contratto", "contratti"]}
          color={
            importoContratti >= importoTotaleContrattiBudget
              ? colorEffettivoOk
              : colorEffettivoError
          }
        />
      )}
    </div>
  );
}

function getInitialBudgetId(data: any): number | null {
  data = (data || []).map((x) => x);

  // cerca prima quelli di quest'anno
  const now = moment().year();
  const filtered = data.filter((x) => x.anno === now);
  if (filtered.length) {
    data = filtered;
  }

  // cerca quello attuale
  const attuale = data.filter((x) => x.attuale)[0];
  if (attuale) {
    return attuale.id;
  }

  // cerca quello approvato
  const approvato = data.filter((x) => x.approvato)[0];
  if (approvato) {
    return approvato.id;
  }

  data.sort(sortBudget);

  return data[0]?.id;
}

function sortBudget(a, b) {
  if (a.anno !== b.anno) {
    return -(a.anno - b.anno);
  }
  return -(a.versione - b.versione);
}

function AmbitoSelect({ value, onChange, valori }) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    let v;
    if (newValue === "###ALL") {
      v = null;
    } else {
      v = newValue;
    }
    onChange(v);
  };

  return (
    <FormControl
      variant="standard"
      size="small"
      style={{ marginLeft: 8, marginRight: 8, marginTop: 0, marginBottom: 0 }}
    >
      <InputLabel style={{ position: "absolute", marginTop: -11 }}>
        Ambito
      </InputLabel>

      <Select
        value={value || "###ALL"}
        onChange={handleChange}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <MenuItem value="###ALL">
          <em>Tutti</em>
        </MenuItem>
        {valori ? <hr /> : null}

        {valori
          ? valori.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
}
