import React, { useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getFormatStrumenti as getFormatStrumentiAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { orderBy } from "lodash";

export default function StrumentoPicker(props) {
  const showToast = useToast();

  const [selectedStrumento, setSelectedStrumento] = useState(null);

  const strumenti = useDataSource({
    initialData: [],
    loadAction: getFormatStrumentiAction,
    autoLoad: true,
    onLoadSuccess: (response) => {
      const strumento = response.data.find((s) => {
        return s.id === props.source.getValue(props.sourceKey);
      });

      if (strumento) {
        setSelectedStrumento(strumento);
      } else {
        setSelectedStrumento(null);
      }
    },
    onLoadError: (response) => {
      showToast("Error durante il caricamento della lista strumenti", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;

  const options = useMemo(() => {
    const d =
      (props.filterStato
        ? strumenti.data.filter(props.filterStato)
        : strumenti.data) || [];

    d.forEach((o) => {
      o.nomeCI = (o.nome || "").toLowerCase();
    });

    return orderBy(d, ["nomeCI"], ["asc"]);
  }, [props.filterStato, strumenti.data]);

  return (
    <Autocomplete
      disabled={props.readOnly}
      fullWidth={true}
      loading={strumenti.loading}
      loadingText="Caricamento lista strumenti ..."
      multiple={false}
      noOptionsText="Nessuno strumento"
      onClose={null}
      options={options}
      size="small"
      value={selectedStrumento}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={props.variant}
            label="Strumento"
            error={!!sourceError}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option.nome;
      }}
      renderOption={(option) => {
        return option.nome;
      }}
      onChange={(event, value) => {
        setSelectedStrumento(value);

        props.source.changeValue(props.sourceKey, value?.id ?? null);
      }}
    />
  );
}
