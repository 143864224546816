import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import ClientePicker from "../common/ClientePicker";
import ClienteMandatoPicker from "../common/ClienteMandatoPicker";
import ContattoPicker from "../common/ContattoPicker";
import Mandato from "../Mandato/Mandato";
import Cliente from "../Clienti/Cliente";
import Contatto from "../Contatti/Contatto";
import { ColoredButton } from "../../elements/ColoredButton";
import TabIcon from "@material-ui/icons/Tab";
import useSideModal from "../common/useSideModal";
import { v4 as uuid } from "uuid";

type AziendaMandatoContattoPickerProps = {
  source: any;
  idAziendaKey: string;
  idMandatoKey: string;
  idContattoKey: string;
  readOnly?: boolean;
};

export default function AziendaMandatoContattoPicker({
  source,
  idAziendaKey,
  idMandatoKey,
  idContattoKey,
  readOnly = false,
}: AziendaMandatoContattoPickerProps) {
  const { openSideModal, sideModal } = useSideModal();

  const [clientePickerKey, setClientePickerKey] = useState(uuid());
  const [mandatoPickerKey, setMandatoPickerKey] = useState(uuid());
  const [contattoPickerKey, setContattoPickerKey] = useState(uuid());

  return (
    <Grid container spacing={3}>
      {sideModal}
      <Grid item md={4} xs={12}>
        {!source.getValue(idContattoKey) ? (
          <>
            <ClientePicker
              key={clientePickerKey}
              source={source}
              sourceKey={idAziendaKey}
              onChange={(idAzienda) => {
                if (!idAzienda) {
                  source.changeValue(idMandatoKey, null);
                } else {
                  source.changeValue(idContattoKey, null);
                }
              }}
              label="Azienda"
              variant="outlined"
              readOnly={readOnly}
              shrink
            />
            <OpenSideClienteButton
              openSideModal={openSideModal}
              idCliente={source.getValue(idAziendaKey)}
              onEditSuccess={() => {
                setClientePickerKey(uuid());
              }}
            />
          </>
        ) : null}
      </Grid>
      <Grid item md={4} xs={12} className="with-textfield-black-color">
        {source.getValue(idAziendaKey) ? (
          <>
            <ClienteMandatoPicker
              key={mandatoPickerKey}
              variant="outlined"
              readOnly={readOnly}
              source={source}
              sourceKey={idMandatoKey}
              label="Mandato"
              clienteId={source.getValue(idAziendaKey)}
              shrink
            />
            <OpenSideMandatoButton
              openSideModal={openSideModal}
              idMandato={source.getValue(idMandatoKey)}
              onEditSuccess={() => setMandatoPickerKey(uuid())}
            />
          </>
        ) : null}
      </Grid>
      <Grid item md={4} xs={12} className="with-textfield-black-color">
        {!source.getValue(idAziendaKey) ? (
          <>
            <ContattoPicker
              key={contattoPickerKey}
              variant="outlined"
              readOnly={readOnly}
              source={source}
              sourceKey={idContattoKey}
              onChange={(idContatto) => {
                if (idContatto) {
                  source.changeValue(idAziendaKey, null);
                  source.changeValue(idMandatoKey, null);
                }
              }}
              label="Contatto"
              shrink
            />
            <OpenSideContattoButton
              openSideModal={openSideModal}
              idContatto={source.getValue(idContattoKey)}
              onEditSuccess={() => {
                setContattoPickerKey(uuid());
              }}
            />
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}

function OpenSideClienteButton({ idCliente, openSideModal, onEditSuccess }) {
  return (
    <OpenSideModalIconButton
      openSideModal={openSideModal}
      itemComponent={Cliente}
      itemId={idCliente}
      onEditSuccess={onEditSuccess}
    >
      Apri azienda
    </OpenSideModalIconButton>
  );
}

function OpenSideContattoButton({ idContatto, openSideModal, onEditSuccess }) {
  return (
    <OpenSideModalIconButton
      openSideModal={openSideModal}
      itemComponent={Contatto}
      itemId={idContatto}
      onEditSuccess={onEditSuccess}
    >
      Apri contatto
    </OpenSideModalIconButton>
  );
}

function OpenSideMandatoButton({ idMandato, openSideModal, onEditSuccess }) {
  return (
    <OpenSideModalIconButton
      openSideModal={openSideModal}
      itemComponent={Mandato}
      itemId={idMandato}
      onEditSuccess={onEditSuccess}
    >
      Apri mandato
    </OpenSideModalIconButton>
  );
}

function OpenSideModalIconButton({
  itemId,
  itemComponent,
  openSideModal,
  children,
  onEditSuccess,
}) {
  return (
    <div style={{ minHeight: 42 }}>
      {itemId && (
        <ColoredButton
          variant="outlined"
          onClick={() => {
            openSideModal(
              itemComponent,
              {
                id: itemId,
                useIdFromProps: true,
                onEditSuccess: onEditSuccess,
                edit: true,
              },
              itemId
            );
          }}
          startIcon={<TabIcon />}
          style={{ marginBottom: 12 }}
        >
          {children}
        </ColoredButton>
      )}
    </div>
  );
}
