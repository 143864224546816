import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { TableColumn } from "./types";
import {
  makeSetSortBy,
  useDataTableContext,
} from "app/contexts/DataTableContext";

type DataTableFootCellProps = {
  children: React.ReactNode;
  column: TableColumn;
  colSpan?: number;
};

export function DataTableFootCell({
  children,
  column,
  colSpan,
}: DataTableFootCellProps) {
  //   const context = useDataTableContext();

  const align = (column as any)?.align;

  return (
    <TableCell align={align} style={{ width: column.width }} colSpan={colSpan}>
      {children}
    </TableCell>
  );
}
