import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import NumberInput from "app/components/common/NumberInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VariabileAgevolazioneValue2 from "app/components/common/VariabileAgevolazioneValue2";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import numeral from "numeral";

// PROPS:
// rowSource -> datasource
// variant
// readOnly
export default function MandatoLineaMilestonePagamentiImporto(props) {
  const { rowSource, readOnly, variant, onBlurPath } = props;
  // agevolazioni, fslMilestones, idMilestonePagamento,

  const tipoImporto = rowSource.getValue("tipoImporto");

  let content = null;

  if (tipoImporto === "percentuale") {
    // PERCENTUALE

    content = (
      <div>
        <div
          style={{
            display: "flex",
          }}
        >
          <TextField
            style={{
              maxWidth: "10em",
              minWidth: 75,
            }}
            disabled={rowSource.loading || rowSource.editing}
            error={rowSource.getValidationErrors("percentuale").length > 0}
            fullWidth={false}
            helperText={rowSource.getValidationErrors("percentuale")[0]}
            onBlur={(event) => {
              rowSource.runValidation("percentuale");
              onBlurPath?.("percentuale");
            }}
            onChange={(event) => {
              const value = event.target.value;

              rowSource.changeValue(
                "percentuale",

                value
              );
            }}
            size="small"
            value={rowSource.getValue("percentuale", "")}
            variant={variant}
            InputProps={{
              readOnly: readOnly,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,

              inputComponent: NumberFormatCustom,
            }}
            inputProps={{
              min: 0,
              max: 100,
              thousandSeparator: ".",
              decimalSeparator: ",",
            }}
          />

          <div
            style={{
              padding: 8,
            }}
          >
            {" di"}
          </div>

          <div
            style={{
              padding: 8,
              fontWeight: "bold",
            }}
          >
            <VariabileAgevolazioneValue2
              noParentesi={true}
              idVariabile={rowSource.getValue([
                "milestonePagamento",
                "idVariabile",
              ])}
            />
          </div>
        </div>
        <MinListino
          min={rowSource.getValue("min")}
          minImportoApprovato={rowSource.getValue("minImportoApprovato")}
          listino={rowSource.getValue("listino")}
          format="0,0.[00]"
          symbol="%"
        />
      </div>
    );
  } else if (tipoImporto === "percentuale-scaglioni") {
    // SCAGLIONI

    const scaglioni = rowSource.getValue("scaglioni") || [];

    content = (
      <div
        style={{
          // display: 'flex',
          minWidth: 200,
          width: "100%",
        }}
      >
        Percentuali relative a:
        <div
          style={{
            padding: 8,
            fontWeight: "bold",
          }}
        >
          <VariabileAgevolazioneValue2
            noParentesi={true}
            idVariabile={rowSource.getValue([
              "milestonePagamento",
              "idVariabile",
            ])}
          />
        </div>
        <hr />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fino a</TableCell>
              <TableCell>Percentuale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scaglioni.map((scaglione, scaglioneIndex) => {
              const isLast = scaglioneIndex === scaglioni.length - 1;

              return (
                <TableRow>
                  <TableCell>
                    {isLast
                      ? " ∞ "
                      : `${numeral(scaglione.finoA).format("0,0.[00]")} €`}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <NumberInput
                        percent={true}
                        source={rowSource}
                        sourceKey={["scaglioni", scaglioneIndex, "percentuale"]}
                        disabled={props.readOnly}
                        style={{
                          marginRight: 8,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        min={0}
                        onBlur={() => {
                          onBlurPath?.([
                            "scaglioni",
                            scaglioneIndex,
                            "percentuale",
                          ]);
                        }}
                      />
                    </div>
                    <MinListino
                      min={scaglione.min}
                      minImportoApprovato={scaglione.minImportoApprovato}
                      listino={scaglione.listino}
                      format="0,0.[00]"
                      symbol="%"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  } else {
    // VALORE

    content = (
      <div>
        <TextField
          disabled={rowSource.loading || rowSource.editing}
          error={rowSource.getValidationErrors("valore").length > 0}
          fullWidth={false}
          helperText={rowSource.getValidationErrors("valore")[0]}
          onBlur={(event) => {
            rowSource.runValidation("valore");
            onBlurPath?.("valore");
          }}
          onChange={(event) => {
            const value = event.target.value;
            rowSource.changeValue("valore", value);
          }}
          size="small"
          value={rowSource.getValue("valore", "")}
          variant={variant}
          InputProps={{
            readOnly: readOnly,
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            inputComponent: NumberFormatCustom,
          }}
          inputProps={{
            thousandSeparator: ".",
            decimalSeparator: ",",
            min: 0,
          }}
        />
        <MinListino
          min={rowSource.getValue("min")}
          minImportoApprovato={rowSource.getValue("minImportoApprovato")}
          listino={rowSource.getValue("listino")}
          format="0,0.[00]"
          symbol="€"
        />
      </div>
    );
  }

  return content;
}

function MinListino({ min, listino, minImportoApprovato, format, symbol }) {
  const minString = numeral(min).format(format);
  const listinoString = numeral(listino).format(format);

  if (minImportoApprovato) {
    const minImportoApprovatoString =
      numeral(minImportoApprovato).format(format);

    return (
      <div>
        {`Min: `}
        <span style={{ textDecoration: "line-through" }}>{`${minString}`}</span>
        {` ${minImportoApprovatoString} ${symbol} - Listino: ${listinoString} ${symbol}`}
      </div>
    );
  }

  return (
    <div>{`Min: ${minString} ${symbol} - Listino: ${listinoString} ${symbol}`}</div>
  );
}
