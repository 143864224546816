import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getLinea as getLineaAction } from "app/actions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import TipoImportoMilestonePagamento from "app/constants/TipoImportoMilestonePagamento";
import VariabileAgevolazioneValue from "app/components/common/VariabileAgevolazioneValue";
import { AsyncSelect } from "app/elements/AsyncSelect";
import MilestonePagamentiImporto from "app/components/FormatStrumenti/MilestonePagamentiImporto";

export default function MilestonePagamentiAccordion(props) {
  const idLinea = props.formatStrumentoLinea.getValue("idLinea");

  const linea = useDataSource({
    initialData: {},
    itemId: idLinea,
    loadAction: getLineaAction,
    autoLoad: !!idLinea,
  });

  const agevolazioni = useDataSource({
    parent: linea,
    pathInParent: ["agevolazioni"],
    initialData: [],
  });

  const fslMilestones = useDataSource({
    parent: props.formatStrumentoLinea,
    pathInParent: ["fslMilestones"],
    initialData: [],
  });

  const findFslMilestone = (milestoneId) => {
    return fslMilestones.data.find((includedMilestone) => {
      return milestoneId === includedMilestone.idMilestonePagamento;
    });
  };

  const isMilestoneIncluded = (milestoneId) => {
    return !!findFslMilestone(milestoneId);
  };

  return (
    <Accordion
      style={{
        width: "100%",
      }}
      defaultExpanded={true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {linea.getValue("nome")}
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={linea}
          sourceKey="milestonePagamenti"
          columns={[
            {
              path: ["nome", "nome"],
              header: "Nome milestone",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div
                    style={{
                      textDecoration: isMilestoneIncluded(
                        rowSource.getValue("id")
                      )
                        ? "initial"
                        : "line-through",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isMilestoneIncluded(
                            rowSource.getValue("id")
                          )}
                          onChange={(event) => {
                            const value = event.target.checked;

                            if (value) {
                              fslMilestones.create({
                                idMilestonePagamento: rowSource.getValue("id"),
                                tipoImporto:
                                  TipoImportoMilestonePagamento[0].value,
                                idVariabile: rowSource.getValue("idVariabile"),
                              });
                            } else {
                              fslMilestones.delete({
                                id: fslMilestones.data.find(
                                  (includedMilestone) => {
                                    return (
                                      rowSource.getValue("id") ===
                                      includedMilestone.idMilestonePagamento
                                    );
                                  }
                                ).id,
                              });
                            }
                          }}
                        />
                      }
                      disabled={props.readOnly}
                    />
                    {value}{" "}
                    <VariabileAgevolazioneValue
                      agevolazioni={agevolazioni}
                      idVariabile={rowSource.getValue("idVariabile")}
                    />
                  </div>
                );
              },
            },
            {
              path: ["allaFirma"],
              header: "Pagamento",
              width: 155,
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rowSource.getValue("allaFirma", false)}
                        disabled={rowSource.loading || rowSource.editing}
                        onChange={(event) => {
                          const value = event.target.checked;

                          rowSource.changeValue(
                            "allaFirma",

                            value
                          );
                        }}
                        size="small"
                        value={rowSource.getValue("allaFirma", "")}
                      />
                    }
                    disabled={true}
                    label="alla firma"
                  />
                );
              },
              type: "text",
            },
            {
              path: ["id"],
              header: "Tipo importo",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              width: 200,
              sortable: true,
              renderCell: (value, row, rowIndex, rowSource) => {
                return isMilestoneIncluded(rowSource.getValue("id")) ? (
                  rowSource.getValue("allaFirma") ? (
                    "Valore"
                  ) : (
                    <FormControl
                      disabled={props.readOnly}
                      error={
                        fslMilestones.getValidationErrors([
                          {
                            id: fslMilestones.data.find((includedMilestone) => {
                              return (
                                rowSource.getValue("id") ===
                                includedMilestone.idMilestonePagamento
                              );
                            }).id,
                          },
                          "tipoImporto",
                        ]).length > 0
                      }
                      fullWidth={true}
                      margin="dense"
                      size="small"
                    >
                      <InputLabel
                        error={
                          fslMilestones.getValidationErrors([
                            {
                              id: fslMilestones.data.find(
                                (includedMilestone) => {
                                  return (
                                    rowSource.getValue("id") ===
                                    includedMilestone.idMilestonePagamento
                                  );
                                }
                              ).id,
                            },
                            "tipoImporto",
                          ]).length > 0
                        }
                        variant="outlined"
                      >
                        {}
                      </InputLabel>
                      <AsyncSelect
                        onChange={(event) => {
                          const value = event.target.value;

                          fslMilestones.changeValue(
                            [
                              {
                                id: fslMilestones.data.find(
                                  (includedMilestone) => {
                                    return (
                                      rowSource.getValue("id") ===
                                      includedMilestone.idMilestonePagamento
                                    );
                                  }
                                ).id,
                              },
                              "tipoImporto",
                            ],

                            value || null
                          );

                          setTimeout(
                            () => {
                              fslMilestones.runValidation([
                                {
                                  id: fslMilestones.data.find(
                                    (includedMilestone) => {
                                      return (
                                        rowSource.getValue("id") ===
                                        includedMilestone.idMilestonePagamento
                                      );
                                    }
                                  ).id,
                                },
                                "tipoImporto",
                              ]);
                            },

                            0
                          );
                        }}
                        onClose={(event) => {
                          fslMilestones.runValidation([
                            {
                              id: fslMilestones.data.find(
                                (includedMilestone) => {
                                  return (
                                    rowSource.getValue("id") ===
                                    includedMilestone.idMilestonePagamento
                                  );
                                }
                              ).id,
                            },
                            "tipoImporto",
                          ]);
                        }}
                        value={
                          fslMilestones.getValue([
                            {
                              id: fslMilestones.data.find(
                                (includedMilestone) => {
                                  return (
                                    rowSource.getValue("id") ===
                                    includedMilestone.idMilestonePagamento
                                  );
                                }
                              ).id,
                            },
                            "tipoImporto",
                          ]) || ""
                        }
                        variant="outlined"
                        sourceVariable="fslMilestones"
                        sourceKey={[
                          {
                            id: fslMilestones.data.find((includedMilestone) => {
                              return (
                                rowSource.getValue("id") ===
                                includedMilestone.idMilestonePagamento
                              );
                            }).id,
                          },
                          "tipoImporto",
                        ]}
                        disabled={props.readOnly}
                      >
                        {TipoImportoMilestonePagamento.map((option, index) => {
                          return (
                            <MenuItem
                              key={option.value}
                              dense={false}
                              disableGutters={false}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </AsyncSelect>
                      {fslMilestones.getValidationErrors([
                        {
                          id: fslMilestones.data.find((includedMilestone) => {
                            return (
                              rowSource.getValue("id") ===
                              includedMilestone.idMilestonePagamento
                            );
                          }).id,
                        },
                        "tipoImporto",
                      ])[0] ? (
                        <FormHelperText>
                          {
                            fslMilestones.getValidationErrors([
                              {
                                id: fslMilestones.data.find(
                                  (includedMilestone) => {
                                    return (
                                      rowSource.getValue("id") ===
                                      includedMilestone.idMilestonePagamento
                                    );
                                  }
                                ).id,
                              },
                              "tipoImporto",
                            ])[0]
                          }
                        </FormHelperText>
                      ) : null}{" "}
                    </FormControl>
                  )
                ) : null;
              },
              type: "text",
            },
            {
              path: ["id"],
              header: "Importo/percentuale valore",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "number",
              width: 600,
              renderCell: (value, row, rowIndex, rowSource) => {
                return isMilestoneIncluded(value) ? (
                  <MilestonePagamentiImporto
                    agevolazioni={agevolazioni}
                    fslMilestones={fslMilestones}
                    idMilestonePagamento={rowSource.getValue("id")}
                    readOnly={props.readOnly}
                  />
                ) : null;
              },
            },
          ]}
          bottomToolbar={
            <div
              style={{
                flex: 1,
                minWidth: 350,
              }}
            >
              {props.formatStrumentoLinea.getValidationErrors(
                "fslMilestones"
              )?.[0] ? (
                <FormHelperText
                  style={{
                    marginLeft: 8,
                  }}
                  disabled={true}
                  error={true}
                  filled={false}
                  focused={false}
                  required={false}
                  variant="standard"
                >
                  {props.formatStrumentoLinea.getValidationErrors(
                    "fslMilestones"
                  )?.[0] || null}{" "}
                </FormHelperText>
              ) : null}{" "}
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          expandRowsOnClick={false}
          isRowExpandable={null}
          renderExpandedRow={null}
          messages={{
            noRows: "Questa linea non ha milestone pagamenti",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
