import classNames from "classnames";
import React, { useMemo } from "react";
import {
  BarChart,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
  Label,
  Text,
  Legend,
  Rectangle,
  adaptEventsOfChild,
  Layer,
  Cell,
} from "recharts";
import {
  getColorForPriorityIndex,
  getPriorityIndexForAttivita,
  getTextColorForPriorityIndex,
} from "../Attivita/AttivitaKanbanCard";
import { getTipoDaAttivita } from "./CaricoDiLavoro";
import { DEFAULT_WEEKLY_AVAIBILITY } from "./CaricoUserSection";

// https://recharts.org/en-US/examples/StackedBarChart

export default function CaricoBarChart({
  leftColumnWidth,
  weekColumnWidth,
  rightColumnWidth,
  columns,
  tipiDiAttivita,
  users,
  openKanban,
}) {
  const [nUsers, referencesByWeek] = useMemo(() => {
    const nUsers = users.length;
    const referencesByWeek = {};

    columns.forEach((column) => {
      const available = users.reduce(
        (acc, user) =>
          acc +
          (user.weeklyAvailability?.[column.id] ??
            user.defaultWeeklyAvaibility ??
            DEFAULT_WEEKLY_AVAIBILITY),
        0
      );

      referencesByWeek[column.id] = {
        available: available,
      };
    });

    return [nUsers, referencesByWeek];
  }, [users, columns]);

  const [data, tipiDiAttivitaArray] = useMemo(() => {
    const tipiDiAttivitaArray = Object.values(tipiDiAttivita);

    const data = columns.map((column) => {
      const values = {};
      const priorities = {};

      column.cards.forEach((card) => {
        const priorityIndex = getPriorityIndexForAttivita(card.data);
        const key = getTipoDaAttivita(card.data).key;
        values[key] = (values[key] || 0) + (card.data.orePreviste || 0);
        priorities[key] = priorities[key]
          ? Math.min(priorities[key], priorityIndex)
          : priorityIndex;
      }, {});

      // const reference = referencesByWeek[column.id].available;

      return {
        week: column.title,
        priorities: priorities,
        // reference: reference,
        ...values,
      };
    });

    return [data, tipiDiAttivitaArray];
  }, [columns, tipiDiAttivita]);

  const nColumns = data.length;
  const width = leftColumnWidth + weekColumnWidth * nColumns + rightColumnWidth;
  const height = 400;

  const onClick = (obj) => {
    openKanban({ tipoAttivita: obj.tipo });
  };

  return (
    <>
      <div style={{ width: width, height: height }}>
        {/* <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
          }}
        /> */}
        <ComposedChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: rightColumnWidth,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />
          <YAxis width={leftColumnWidth} />
          <Tooltip />

          {/* <Legend /> */}

          {tipiDiAttivitaArray.map((tipo) => {
            return (
              <ColoredBar
                key={tipo.key}
                dataKey={tipo.key}
                stackId="a"
                name={tipo.nomeLegenda}
                unit="h"
              >
                {/* {data.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    key={`cell-${index}`}
                    onClick={onClick}
                  />
                ))} */}
                <LabelList
                  position="insideTopLeft"
                  valueAccessor={(entry) => {
                    return { tipo, entry };
                  }}
                  content={CustomizedLabel}
                  min={10000}
                  overflow="hidden"
                  clip={true}
                  onClick={onClick}
                  // dataKey={tipo.key}
                />
              </ColoredBar>
            );
          })}

          {/* USED ONLY TO EXTEND THE DOMAIN!! */}
          {columns.map((column, index) => {
            return (
              <ReferenceLine
                key={"fake-ref-" + column.id}
                y={referencesByWeek[column.id].available}
                label={null}
                stroke="red"
                strokeDasharray="3 3"
                ifOverflow="extendDomain"
                shape={ReferenceLineNone}
              />
            );
          })}

          {/* REAL REFERENCE LINE */}
          {columns.map((column, index) => {
            const y = referencesByWeek[column.id].available;

            return (
              <ReferenceLine
                key={"real-ref-" + column.id}
                label={null}
                segment={[
                  {
                    x: column.title,
                    y: y,
                  },
                  {
                    x: (index > 0 ? columns[index - 1] : columns[index + 1])
                      .title,
                    y: y,
                  },
                ]}
                stroke="red"
                strokeDasharray="3 3"
                ifOverflow="extendDomain"
                shape={ReferenceLineShape}
              />
            );
          })}
        </ComposedChart>
      </div>
    </>
  );
}

const CustomizedLabel = ({ x, y, width, height, stroke, value, onClick }) => {
  const { tipo, entry } = value;
  const { nome, description } = tipo;
  const dataKey = tipo.key;

  const priorities = entry?.payload?.priorities;
  const priorityIndex = priorities?.[dataKey];

  let textColor = "#333";

  if (typeof priorityIndex !== "undefined") {
    textColor = getTextColorForPriorityIndex(priorityIndex);
  }

  return (
    <foreignObject
      x={x}
      y={y}
      width={width}
      height={height}
      // fill={stroke}
      // fontSize={15}
      // textAnchor="top"
      overflow="auto"
      // pointerEvents={"none"}
    >
      <div
        style={{
          padding: 5,
          paddingTop: 1,
          paddingBottom: 1,
          width: width,
          height: height,
          cursor: "pointer",
          overflow: "hidden",
          color: textColor,
        }}
        onClick={() => onClick(value)}
      >
        <span style={{ fontStyle: "italic" }}>{description}</span>{" "}
        <span>{nome}</span>
      </div>
    </foreignObject>
  );
  // return (
  //   <text
  //     x={x}
  //     y={y}
  //     dy={20}
  //     dx={10}
  //     fill={stroke}
  //     fontSize={15}
  //     textAnchor="top"
  //   >
  //     {value} {height}
  //   </text>
  // );
};

class ColoredBar extends Bar {
  renderRectanglesStatically(data) {
    const dataKey = this.props.dataKey;
    const newData =
      data &&
      data.map((entry) => {
        const priorities = entry?.payload?.priorities;
        const priorityIndex = priorities?.[dataKey];

        let fill = "#eee";

        if (typeof priorityIndex !== "undefined") {
          fill = getColorForPriorityIndex(priorityIndex);
        }

        return { ...entry, fill };
      });
    return super.renderRectanglesStatically(newData);
  }
}

function ReferenceLineShape(props) {
  const deltaX = Math.abs(props.x2 - props.x1);
  const x = props.x1;
  const d = deltaX * 0.5;
  const line = (
    <line
      {...props}
      className="recharts-reference-line-line"
      x1={x - d}
      x2={x + d}
    />
  );
  return line;
}

function ReferenceLineNone(props) {
  return null;
}
