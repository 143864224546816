import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import NumberInput from "../common/NumberInput";
import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { ColoredButton } from "app/elements/ColoredButton";
import { useToast } from "app/hooks/useToast";
import { useApi } from "app/hooks/useApi";
import { editBudgetEntry } from "app/actions";
import moment from "moment";
import { each, upperFirst } from "lodash";

export function EditBudgetForm({
  idBudget,
  idLegge,
  idLinea,
  nomeLinea,
  utente,
  onClose,
  onSave,
  mese,
  numeroContratti: initialNumeroContratti,
  importo: initialImporto,
  valoriUtenti: initialValoriUtenti,
  utenti,
}) {
  const showToast = useToast();

  const [loading, setLoading] = useState(false);
  const saveBudgetEntryApi = useApi(editBudgetEntry, {
    onError: () => {
      showToast("Errore durante il salvataggio", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const {
    numeroContratti,
    setNumeroContratti,
    importo,
    setImporto,
    valoriUtenti,
    setValoriUtenti,
  } = useValoriBudget(
    initialNumeroContratti,
    initialImporto,
    initialValoriUtenti,
    utenti
  );

  const [espandiMesiSuccessivi, setEspandiMesiSuccessivi] = useState(false);
  const [espandiMesi, setEspandiMesi] = useState({ [mese]: true });

  const save = async () => {
    try {
      setLoading(true);
      await saveBudgetEntryApi.callApi(idBudget, {
        idLegge: idLegge,
        idLinea,
        idUtente: utente ? utente.id : null,
        mese,
        numeroContratti,
        importo,
        espandiMesiSuccessivi,
        espandiMesi,
        valoriUtenti,
      });
      await onSave();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      style={{
        maxWidth: 400,
      }}
    >
      <Grid
        container
        spacing={3}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Grid item lg={12} md={12} xs={12}>
          <b>Modifica Budget</b>
          <div>Linea: {nomeLinea}</div>
          {utente && (
            <div>
              Utente: {utente.cognome} {utente.nome}
            </div>
          )}
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <NumberInput
            disabled={loading}
            fullWidth={true}
            variant={"outlined"}
            label="Numero di contratti"
            InputLabelProps={{ shrink: true }}
            value={numeroContratti ?? null}
            onChange={(event) => {
              const value = event.target.value;
              if (value !== numeroContratti) {
                setNumeroContratti(value ?? null);
              }
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <NumberInput
            disabled={loading}
            currency={true}
            fullWidth={true}
            variant={"outlined"}
            label="Importo totale"
            InputLabelProps={{ shrink: true }}
            value={importo ?? null}
            onChange={(event) => {
              const value = event.target.value;
              if (value !== importo) {
                setImporto(value ?? null);
              }
            }}
          />
        </Grid>

        {mese < 12 && (
          <Grid item lg={12} md={12} xs={12}>
            <FormControl>
              {/* <RadioGroup
                row
                value={espandiMesiSuccessivi ? "mesiSuccessivi" : "mese"}
                onChange={(event) => {
                  const value = event.target.value;
                  setEspandiMesiSuccessivi(value === "mesiSuccessivi");
                }}
              >
                <FormControlLabel
                  value="mese"
                  control={<Radio size="small" />}
                  label={`Solo ${meseString}`}
                />
                <FormControlLabel
                  value="mesiSuccessivi"
                  control={<Radio size="small" />}
                  label="Applica anche ai mesi successivi"
                />
              </RadioGroup> */}

              <FormLabel>Mesi</FormLabel>
              <FormGroup
                row
                style={{
                  marginTop: 5,
                }}
              >
                {new Array(12).fill(0).map((_, i) => {
                  const m = i + 1;
                  const mString = upperFirst(
                    moment().localeData().monthsShort()[m - 1] || ""
                  );
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={espandiMesi[m] ?? false}
                          onChange={(event) => {
                            const value = event.target.checked;
                            setEspandiMesi((em) => {
                              return {
                                ...em,
                                [m]: value,
                              };
                            });
                          }}
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: "0.8rem ",
                            verticalAlign: "middle",
                          }}
                        >
                          {mString}
                        </span>
                      }
                      style={{
                        width: 70,
                        marginTop: -5,
                      }}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Grid>
        )}

        {!utente && (
          <Grid item lg={12} md={12} xs={12}>
            <FormLabel>Utenti</FormLabel>
            <Table style={{ width: "100%" }} size="small">
              <TableBody>
                {utenti.map((u) => {
                  const valoriUtente = valoriUtenti[u.id] || {};
                  const numeroContrattiUtente =
                    valoriUtente.numeroContrattiBudget;
                  const importoUtente =
                    valoriUtente.importoTotaleContrattiBudget;

                  const setNumeroContrattiUtente = (v) => {
                    if (valoriUtente.numeroContrattiBudget !== v) {
                      setValoriUtenti((vu) => {
                        return {
                          ...vu,
                          [u.id]: {
                            numeroContrattiBudget: v,
                            importoTotaleContrattiBudget:
                              vu[u.id]?.importoTotaleContrattiBudget || 0,
                          },
                        };
                      });
                    }
                  };
                  const setImportoUtente = (v) => {
                    if (valoriUtente.importoTotaleContrattiBudget !== v) {
                      setValoriUtenti((vu) => {
                        return {
                          ...vu,
                          [u.id]: {
                            numeroContrattiBudget:
                              vu[u.id]?.numeroContrattiBudget || 0,
                            importoTotaleContrattiBudget: v,
                          },
                        };
                      });
                    }
                  };

                  return (
                    <TableRow key={u.id}>
                      <TableCell component="th" scope="row">
                        {u.cognome} {u.nome}
                      </TableCell>
                      <TableCell style={{ zoom: 0.7, width: 150 }}>
                        <NumberInput
                          disabled={loading}
                          fullWidth={true}
                          variant={"outlined"}
                          label="Numero di contratti"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={numeroContrattiUtente ?? null}
                          onChange={(event) => {
                            const value = event.target.value;
                            setNumeroContrattiUtente(value ?? null);
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ zoom: 0.7, width: 150 }}>
                        <NumberInput
                          disabled={loading}
                          currency={true}
                          fullWidth={true}
                          variant={"outlined"}
                          label="Importo totale"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          value={importoUtente ?? null}
                          onChange={(event) => {
                            const value = event.target.value;
                            setImportoUtente(value ?? null);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={3}
        style={{
          position: "sticky",
          bottom: 0,
          paddingBottom: 10,
          background: "white",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          zIndex: 10,
        }}
      >
        <Grid item lg={12} md={12} xs={12}>
          <ColoredButton
            disabled={loading}
            size="small"
            onClick={(event) => {
              onClose && onClose();
            }}
            color="secondary"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            disabled={loading}
            size="small"
            onClick={(event) => {
              save();
            }}
            color="primary"
          >
            Salva
          </ColoredButton>
        </Grid>
      </Grid>
    </Container>
  );
}

function useValoriBudget(
  initialNumeroContratti: number | null | undefined,
  initialImporto: number | null | undefined,
  initialValoriUtenti: any | null | undefined,
  utenti: any
) {
  const [[numeroContratti, importo], _setNumeroContrattiEImporto] = useState<
    [number | null, number | null]
  >([initialNumeroContratti || null, initialImporto || null]);
  const [valoriUtenti, _setValoriUtenti] = useState<{
    [key: string]: {
      numeroContrattiBudget: number;
      importoTotaleContrattiBudget: number;
    };
  }>(initialValoriUtenti || {});

  const setNumeroContrattiEImporto = useSetStateWithSideEffect(
    _setNumeroContrattiEImporto,
    ([numeroContratti, importo]) => {
      const numeroContrattiPerUtente = Math.ceil(
        (numeroContratti || 0) / utenti.length
      );
      const importoPerUtente = Math.ceil((importo || 0) / utenti.length);

      const valoriUtenti = {};
      //   numeroContratti = 0;
      //   importo = 0;

      utenti.forEach((u) => {
        valoriUtenti[u.id] = {
          numeroContrattiBudget: numeroContrattiPerUtente,
          importoTotaleContrattiBudget: importoPerUtente,
        };
        // numeroContratti += numeroContrattiPerUtente;
        // importo += importoPerUtente;
      });

      _setValoriUtenti(valoriUtenti);
    }
  );

  const setNumeroContratti = makeSetSubStateFunction<
    [number | null, number | null],
    number | null
  >(
    setNumeroContrattiEImporto,
    (x) => x[0],
    (x, [_, x2]) => [x, x2]
  );

  const setImporto = makeSetSubStateFunction<
    [number | null, number | null],
    number | null
  >(
    setNumeroContrattiEImporto,
    (x) => x[1],
    (x, [x1, _]) => [x1, x]
  );

  const setValoriUtenti = useSetStateWithSideEffect(
    _setValoriUtenti,
    (valoriUtenti) => {
      let numeroContratti = 0;
      let importo = 0;
      each(valoriUtenti, (x) => {
        const { importoTotaleContrattiBudget, numeroContrattiBudget } = x;
        numeroContratti += numeroContrattiBudget || 0;
        importo += importoTotaleContrattiBudget || 0;
      });
      _setNumeroContrattiEImporto([numeroContratti, importo]);
    }
  );

  //   const valoriUtentiRef = useRef(valoriUtenti);
  //   const numeroContrattiRef = useRef(numeroContratti);
  //   const importoRef = useRef(importo);

  //   useEffect(() => {
  //     let updateFunc: (() => void) | undefined;

  //     if (valoriUtenti !== valoriUtentiRef.current) {
  //       //   numeroContratti = 0;
  //       //   importo = 0;
  //       //   each(valoriUtenti, (x) => {
  //       //     const { importoTotaleContrattiBudget, numeroContrattiBudget } = x;
  //       //     numeroContratti += numeroContrattiBudget || 0;
  //       //     importo += importoTotaleContrattiBudget || 0;
  //       //   });
  //       //   updateFunc = () => {
  //       //     setNumeroContratti(numeroContratti);
  //       //     setImporto(importo);
  //       //   };
  //     } else if (
  //       numeroContratti !== numeroContrattiRef.current ||
  //       importo !== importoRef.current
  //     ) {
  //     //   const numeroContrattiPerUtente = Math.ceil(
  //     //     (numeroContratti || 0) / utenti.length
  //     //   );
  //     //   const importoPerUtente = Math.ceil((importo || 0) / utenti.length);

  //     //   valoriUtenti = {};
  //     //   //   numeroContratti = 0;
  //     //   //   importo = 0;

  //     //   utenti.forEach((u) => {
  //     //     valoriUtenti[u.id] = {
  //     //       numeroContrattiBudget: numeroContrattiPerUtente,
  //     //       importoTotaleContrattiBudget: importoPerUtente,
  //     //     };
  //     //     // numeroContratti += numeroContrattiPerUtente;
  //     //     // importo += importoPerUtente;
  //     //   });

  //     //   updateFunc = () => {
  //     //     setValoriUtenti(valoriUtenti);
  //     //   };
  //     //   //   setNumeroContratti(numeroContratti);
  //     //   //   setImporto(importo);
  //     }

  //     if (updateFunc) {
  //       valoriUtentiRef.current = valoriUtenti;
  //       numeroContrattiRef.current = numeroContratti;
  //       importoRef.current = importo;
  //       updateFunc();
  //     }
  //   }, [valoriUtenti, numeroContratti, importo]);

  return {
    numeroContratti,
    setNumeroContratti,
    importo,
    setImporto,
    valoriUtenti,
    setValoriUtenti,
  };
}

function useSetStateWithSideEffect<T>(
  originalSetState: Dispatch<SetStateAction<T>>,
  sideEffectFunc: (next: T) => void
) {
  const sideEffectFuncRef = useRef(sideEffectFunc);
  sideEffectFuncRef.current = sideEffectFunc;

  const setState: Dispatch<SetStateAction<T>> = useCallback(
    (setStateAction) => {
      const func: (prevState: T) => T = (prev) => {
        if (typeof setStateAction === "function") {
          return (setStateAction as (prevState: T) => T)(prev);
        } else {
          return setStateAction;
        }
      };

      let next: T = undefined as T;
      let callWithTimeout = true;

      originalSetState((prev) => {
        next = func(prev);
        setTimeout(() => {
          if (callWithTimeout) {
            sideEffectFuncRef.current(next);
          }
        }, 0);
        return next;
      });

      if (typeof next !== "undefined") {
        callWithTimeout = false;
        sideEffectFuncRef.current(next);
      }
    },
    [originalSetState]
  );

  return setState;
}

function makeSetSubStateFunction<T2, T1>(
  originalSetState: Dispatch<SetStateAction<T2>>,
  mapT2toT1: (t2: T2) => T1,
  mapT1toT2: (t1: T1, prevT2: T2) => T2
): Dispatch<SetStateAction<T1>> {
  const setState: Dispatch<SetStateAction<T1>> = useCallback(
    (setStateAction) => {
      const func: (prevState: T1) => T1 = (prev) => {
        if (typeof setStateAction === "function") {
          return (setStateAction as (prevState: T1) => T1)(prev);
        } else {
          return setStateAction;
        }
      };

      originalSetState((prevT2) => {
        const prevT1 = mapT2toT1(prevT2);
        const nextT1 = func(prevT1);
        const nextT2 = mapT1toT2(nextT1, prevT2);
        return nextT2;
      });
    },
    [originalSetState]
  );

  return setState;
}
