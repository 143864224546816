import React, { useCallback } from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getMandatiOnlyOpportunitaDormienti as getMandatiOnlyOpportunitaDormientiAction } from "app/actions";
import { Container, IconButton, Typography } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import Mandato from "app/components/Mandato/Mandato";
import TabIcon from "@material-ui/icons/Tab";
import DashboardColoredCell from "app/components/Dashboard/DashboardColoredCell";
import { DashboardFilterUser } from "./DashboardFilterUser";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";

export default function OpportunitaDashboard(props) {
  const openSideModal = props.openSideModal;

  const mandati = useDataSource({
    loadAction: getMandatiOnlyOpportunitaDormientiAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const handleMandatoUpdated = useCallback(
    (mandato) => {
      mandati.changeValue(mandato.id, mandato);
    },
    [mandati]
  );

  return (
    <Container
      style={{
        _height: "calc(50vh - 80px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {mandati.loading ? (
        "Caricamento lista offerte..."
      ) : mandati.loadError ? (
        "Errore durante il caricamento della lista offerte"
      ) : (
        <DataTable
          source={mandati}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 105,
              header: " ",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div>
                    <IconButton to={`/mandati/${value}`} component={Link}>
                      <LaunchIcon />
                    </IconButton>

                    <IconButton
                      onClick={(event) => {
                        openSideModal(
                          Mandato,
                          {
                            id: value,
                            useIdFromProps: true,
                            onEditSuccess: handleMandatoUpdated,
                            edit: true,
                          },
                          value
                        );
                      }}
                    >
                      <TabIcon />
                    </IconButton>
                  </div>
                );
              },
              type: "text",
            },
            {
              path: ["nome"],
              header: "Nome",
              type: "text",
            },
            {
              sortable: false,
              path: ["clientePrincipale", "ragioneSociale"],
              header: "Cliente",
              type: "text",
            },
            {
              sortable: false,
              path: ["formatStrumento", "nome"],
              header: "Format strumento",
              type: "text",
            },
            {
              sortable: false,
              path: ["commerciale", "cognome"],
              header: "Commerciale",
              type: "text",
            },
            {
              path: ["updatedAt"],
              type: "date",
              header: "Ultima modifica",
              renderCell: (value, row, rowIndex, rowSource) => {
                return <DashboardColoredCell value={value} />;
              },
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
                width: "100%",
                display: "inline-block",
              }}
            >
              <Typography gutterBottom={false} noWrap={false} variant="h6">
                Offerte dormienti
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <DashboardFilterUser path={["commerciale", "cognome"]} />
                </div>
              </Typography>
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          pageSize={10}
          size="small"
          initialSortBy={[['["updatedAt"]', "ASC"]]}
          saveCurrentState="tOpportunita"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessuna offerta",
          }}
        />
      )}{" "}
    </Container>
  );
}
