import { createContext, useContext } from "react";
import {
  ColumnFilters,
  SortBy,
  TableColumn,
  TableColumnArray,
} from "../elements/DataTable/types";
import { ColumnFilter } from "../elements/DataTable/filters/types";
import { DataTableMessages } from "../hooks/useTable";
import {
  DataSourceCreateOptions,
  serializePath,
} from "../hooks/DataSource/DataSource";

export type DataTableContextType<Row = any> = {
  // TODO: magari creiamo sotto oggetti come config, filteringAndSorting, pagination ? giust o per evitare collisioni di
  //  nomi man mano che estendiamo
  // config
  server: boolean;
  columns: TableColumnArray;
  totalColumns: number;

  // dynamic props
  filteredRowsCount: number;
  filteredRows: Row[];
  allRows: Row[];
  rows: Row[];
  source: any;
  sourceKey?: string;
  rowSourceOptions: DataSourceCreateOptions;
  footerRows: number;

  // filtering
  showFilterRow: boolean;
  globalFilter: string;
  setGlobalFilter: (text: string) => void;
  columnFilters: ColumnFilters;
  setColumnFilters: (newColumnFilters: ColumnFilters) => void;

  // sorting
  sortBy: SortBy[];
  setSortBy: (newSortBy: SortBy[]) => void;

  // pagination
  page: number;
  setPage: (newPage: number) => void;
  pageSize: number;
  setPageSize: (newPageSize: number) => void;

  // expandable rows
  isRowExpandable: (rowIndex: number, row: Row, source: any) => boolean;
  renderExpandedRow: (rowIndex: number, row: Row, source: any) => any;

  messages: DataTableMessages;

  size?: any;
  expandableRows?: any;
  rowsDefaultExpanded?: any;
};

export const DataTableContext = createContext<DataTableContextType>(
  null as unknown as DataTableContextType
);

export const useDataTableContext = () => useContext(DataTableContext);

export const makeSetFilter = (
  context: DataTableContextType,
  columnIndex: number
) => {
  return (newFilter: ColumnFilter) => {
    context.setColumnFilters({
      ...context.columnFilters,
      [columnIndex]: newFilter,
    });
  };
};

export const makeSetSortBy = (
  context: DataTableContextType,
  column: TableColumn
) => {
  const serializedColumnPath = serializePath(column.path);
  const currentSortByIndex = context.sortBy.findIndex(
    ([path]) => path === serializedColumnPath
  );
  const currentSortBy =
    currentSortByIndex >= 0 ? context.sortBy[currentSortByIndex] : null;
  const setDir = (newDir: null | "ASC" | "DESC") => {
    const oldSortByArray = context.sortBy;
    let newSortByArray;
    if (currentSortByIndex < 0) {
      newSortByArray = [[serializedColumnPath, newDir as any]];
    } else {
      newSortByArray = newDir ? [[serializedColumnPath, newDir as any]] : [];
      // oldSortByArray[currentSortByIndex] = newDir
      //   ? [serializedColumnPath, newDir]
      //   : (null as any);
    }
    // context.setSortBy(oldSortByArray.filter((sort) => !!sort));
    context.setSortBy(newSortByArray);
  };
  return [currentSortBy && currentSortBy[1], setDir] as const;
};
