import React, { useCallback } from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getLineeWithScadenza as getLineeWithScadenzaAction } from "app/actions";
import { Container, IconButton, Typography } from "@material-ui/core";
import { DataTable } from "app/elements/DataTable";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import Linea from "app/components/Linea/Linea";
import TabIcon from "@material-ui/icons/Tab";
import LineaStato from "app/constants/LineaStato";
import BadgeLineaStato from "app/components/common/BadgeLineaStato";
import DashboardColoredCell from "app/components/Dashboard/DashboardColoredCell";
import { DataTablePagination } from "app/elements/DataTable/DataTablePagination";

export default function LineeScadenzaDashboard(props) {
  const openSideModal = props.openSideModal;

  const linee = useDataSource({
    loadAction: getLineeWithScadenzaAction,
    initialData: [],
    skipCheckUncommittedChanged: true,
  });

  const handleLineaUpdated = useCallback(
    (linea) => {
      linee.changeValue(linea.id, linea);
    },
    [linee]
  );

  return (
    <Container
      style={{
        _height: "calc(50vh - 80px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      {linee.loading ? (
        "Caricamento lista linee..."
      ) : linee.loadError ? (
        "Errore durante il caricamento della lista linee"
      ) : (
        <DataTable
          source={linee}
          columns={[
            {
              path: ["id"],
              sortable: false,
              disableColumnFilter: true,
              width: 105,
              header: " ",
              renderCell: (value) => {
                return (
                  <div>
                    <IconButton to={`/linee/${value}`} component={Link}>
                      <LaunchIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        openSideModal(
                          Linea,
                          {
                            id: value,
                            useIdFromProps: true,
                            onEditSuccess: handleLineaUpdated,
                            edit: true,
                          },
                          value
                        );
                      }}
                    >
                      <TabIcon />
                    </IconButton>
                  </div>
                );
              },
              type: "text",
            },
            { path: ["nome"], header: "Nome", sortable: true, type: "text" },
            {
              path: ["legge", "nome"],
              header: "Legge",
              sortable: true,
              type: "text",
            },
            {
              path: ["stato"],
              header: "Stato",
              sortable: true,
              type: "enum",
              options: LineaStato,
              renderCell: (value) => {
                return <BadgeLineaStato stato={value} />;
              },
            },
            {
              sortable: true,
              disableColumnFilter: true,
              path: ["anno"],
              width: 100,
              header: "Anno",
              type: "number",
            },
            {
              sortable: true,
              path: ["scadenza"],
              width: 100,
              header: "Scadenza",
              type: "date",
              renderCell: (value) => {
                return <DashboardColoredCell value={value} />;
              },
            },
          ]}
          topToolbar={
            <div
              style={{
                padding: "1em",
                width: "100%",
                display: "inline-block",
              }}
            >
              <Typography gutterBottom={false} noWrap={false} variant="h6">
                Linee in scadenza
              </Typography>
            </div>
          }
          bottomToolbar={
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />

              <DataTablePagination />
            </div>
          }
          showFilterRow={true}
          pageSize={10}
          size="small"
          initialSortBy={[['["scadenza"]', "ASC"]]}
          saveCurrentState="tLineeScadenza"
          messages={{
            textSearch: "Filtra",
            noRows: "Nessuna linea in scadenza",
          }}
        />
      )}{" "}
    </Container>
  );
}
