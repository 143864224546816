import React from "react";
import { Typography } from "@material-ui/core";
import LeggeStato from "app/constants/LeggeStato";

export default function BadgeLeggeStato(props) {
  let color = null;

  if (props.stato === "in-elaborazione") {
    color = "#ff9800";
  } else if (props.stato === "attiva") {
    color = "#4caf50";
  } else if (props.stato === "archiviata") {
    color = "#e57373";
  }
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Typography
        style={{
          background: color,
          border: "1px solid transparent",
          borderRadius: 4,
          padding: "3px 5px",
          margin: "8px 8px",
        }}
        align="inherit"
        color="inherit"
        display="inline"
        gutterBottom={true}
        noWrap={false}
        paragraph={false}
        variant="caption"
      >
        {LeggeStato.find((stato) => stato.value === props.stato)?.label ??
          props.stato}{" "}
      </Typography>
    </div>
  );
}
