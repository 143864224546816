import { nop, usePatchableState } from "./utils";
import { useValidation } from "./useValidation";
import fpSet from "lodash/fp/set";
import { get } from "lodash";
import { useApi } from "../useApi";
import { DataSourceInterface } from "./DataSource";

type UseSubmitDataSourceParams = {
  parent: DataSourceInterface;
  activeByDefault?: boolean;

  // TODO: initialData?

  submitAction?;
  onSubmitSuccess?;
  onSubmitError?;
};

export function useSubmitDataSource({
  parent,
  activeByDefault = false,
  submitAction,
  onSubmitSuccess = nop,
  onSubmitError = nop,
}: UseSubmitDataSourceParams) {
  const [state, setState] = usePatchableState({
    submitting: false,
    submitSuccess: false,
    submitResponse: null,
    submitError: null,

    isActive: activeByDefault,

    data: null,
  });

  const submitApiCallbacks = {
    onSuccess(editState) {
      const { response } = editState;
      setState({
        submitting: false,
        submitSuccess: true,
        submitResponse: response,
        data: response.data,
        submitError: null,
        isActive: true, // se no c'è un bug in estendi Linea
      });
      onSubmitSuccess(response);
    },
    onError(editState) {
      setState({
        submitting: false,
        submitSuccess: false,
        submitResponse: null,
        submitError: editState.error,
      });
      onSubmitError(editState.response);
    },
  };

  const submitApi = useApi(submitAction, submitApiCallbacks);

  function activate() {
    setState({
      isActive: true,
    });
  }

  function changeValue(key: number | string, value) {
    if (!state.isActive) {
      throw new Error(
        "You have to call activate() before calling changeValue()"
      );
    }
    setState({
      data: fpSet(key, value, state.data as any),
    });
  }

  function getValue(key, defaultValue = "") {
    const value = get(state.data, key);
    if (value == null) {
      return defaultValue;
    }
    return value;
  }

  function submit(...optionalActionParams) {
    setState({
      submitting: true,
    });
    const args =
      optionalActionParams.length > 0
        ? [...optionalActionParams, state.data]
        : [state.data];
    submitApi.callApi(...args);
  }

  function cancel() {
    setState({
      isActive: false,
      keyInParent: null,
      data: null,
    });
  }

  function deactivate() {
    setState({
      isActive: false,
    });
  }

  return {
    ...state,
    activate,
    changeValue,
    getValue,
    submit,
    cancel,
    deactivate,
    ...useValidation(submitAction?.validator, state.data),
  } as const;
}
