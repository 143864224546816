import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getCliente as getClienteAction } from "app/actions";
import { useDialog } from "app/hooks/useDialog";
import { DataTable } from "app/elements/DataTable";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  IconButton,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";
import MandatoClienteReferenteTipo from "app/constants/MandatoClienteReferenteTipo";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import { ColoredButton } from "app/elements/ColoredButton";
import AddIcon from "@material-ui/icons/Add";
import CreateContattoDialog from "app/components/Mandato/CreateContattoDialog";

export default function MandatoClienteExpandedRow(props) {
  const variant = props.readOnly ? "standard" : "outlined";

  const idCliente = props.mandatoCliente.getValue("idCliente", -1);

  const cliente = useDataSource({
    initialData: {},
    itemId: idCliente,
    loadAction: getClienteAction,
    autoLoad: idCliente > 0,
  });

  const referentiCliente = useDataSource({
    parent: cliente,
    pathInParent: ["referenti"],
    initialData: [],
  });

  const mandatoClienteReferenti = useDataSource({
    parent: props.mandatoCliente,
    pathInParent: ["mandatoClienteReferenti"],
    initialData: [],
  });

  const createContattoDialog = useDialog();

  return (
    <>
      <div
        style={{
          padding: ".5em .5em 2em 5em",
          backgroundColor: "lightgray",
        }}
      >
        <DataTable
          source={props.mandatoCliente}
          sourceKey="mandatoClienteReferenti"
          columns={[
            {
              path: ["idReferente"],
              header: "Referente",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControl
                    disabled={props.readOnly}
                    error={!!rowSource.getValidationErrors("idReferente")[0]}
                    fullWidth={true}
                    size="small"
                  >
                    <InputLabel
                      error={!!rowSource.getValidationErrors("idReferente")[0]}
                      variant={variant}
                    >
                      {}
                    </InputLabel>

                    <AsyncSelect
                      onChange={(event) => {
                        const value = event.target.value;

                        rowSource.changeValue(
                          "idReferente",

                          value
                        );

                        rowSource.changeValue(
                          "referente",

                          referentiCliente.getValue({
                            id: value,
                          })
                        );
                      }}
                      value={value}
                      variant={variant}
                      disabled={props.readOnly}
                      loading={referentiCliente.loading}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {referentiCliente.data.map((option, index) => {
                        return (
                          <MenuItem
                            key={option.id}
                            dense={false}
                            disableGutters={false}
                            value={option.id}
                          >
                            {option.contatto?.nome}
                          </MenuItem>
                        );
                      })}
                    </AsyncSelect>
                  </FormControl>
                );
              },
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
            },
            {
              path: ["tipo"],
              header: "Tipo",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControl
                    disabled={props.readOnly}
                    error={rowSource.getValidationErrors("tipo").length > 0}
                    fullWidth={true}
                    size="small"
                  >
                    <InputLabel
                      error={rowSource.getValidationErrors("tipo").length > 0}
                      variant={variant}
                    >
                      {}
                    </InputLabel>
                    <AsyncSelect
                      onChange={(event) => {
                        const value = event.target.value;

                        rowSource.changeValue(
                          "tipo",

                          value || null
                        );

                        setTimeout(
                          () => {
                            rowSource.runValidation("tipo");
                          },

                          0
                        );
                      }}
                      onClose={(event) => {
                        rowSource.runValidation("tipo");
                      }}
                      value={rowSource.getValue("tipo") || ""}
                      variant={variant}
                      sourceVariable="rowSource"
                      sourceKey="tipo"
                      disabled={props.readOnly}
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      {MandatoClienteReferenteTipo.map((option, index) => {
                        return (
                          <MenuItem
                            key={option.value}
                            dense={false}
                            disableGutters={false}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </AsyncSelect>
                    {rowSource.getValidationErrors("tipo")[0] ? (
                      <FormHelperText>
                        {rowSource.getValidationErrors("tipo")[0]}
                      </FormHelperText>
                    ) : null}{" "}
                  </FormControl>
                );
              },
              disableColumnFilter: false,
              disableGlobalFilter: false,
              type: "text",
            },
            {
              path: ["referente", "email"],
              header: "Email",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              type: "text",
            },
            {
              path: ["referente", "cellulare"],
              header: "Cellulare",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              type: "text",
            },
            {
              path: ["referente", "telefono"],
              header: "Telefono",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              type: "text",
            },
            {
              path: ["id"],
              header: "Azioni",
              width: 100,
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div>
                    <IconButton
                      disabled={props.readOnly}
                      onClick={(event) => {
                        rowSource.delete();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton
                      disabled={!rowSource.getValue(["referente", "contatto"])}
                      to={`/contatto/${
                        rowSource.getValue(["referente", "contatto"])?.id
                      }`}
                      component={Link}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </div>
                );
              },
              type: "text",
            },
          ]}
          topToolbar={null}
          bottomToolbar={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75em",
              }}
            >
              {!props.readOnly ? (
                <>
                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    onClick={(event) => {
                      mandatoClienteReferenti.create();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Aggiungi referente esistente
                  </ColoredButton>

                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    onClick={(event) => {
                      createContattoDialog.open();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Crea nuovo referente
                  </ColoredButton>
                </>
              ) : null}{" "}
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          messages={{
            noRows: "Nessun referente",
          }}
        />
      </div>

      <CreateContattoDialog
        dialog={createContattoDialog}
        idCliente={idCliente}
        onContattoCreated={async (contatto) => {
          await cliente.load();

          mandatoClienteReferenti.create({
            idContatto: contatto.id,
          });

          createContattoDialog.close();
        }}
      />
    </>
  );
}
