import React, { useState, useEffect } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getUtenti as getUtentiAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useUser } from "app/hooks/useUser";
import { find } from "lodash";
import { userHasRole } from "common/roles";

// returnValue = 'id' (default), 'cognome'
// defaultSelection = 'me', 'all' (default)
export default function UserFilterSelector(props) {
  let {
    canAltriUtenti = true,
    canAll = true,
    defaultSelection = "all",
  } = props;
  const {
    canAllEAltriUtenti,
    onChange,
    returnValue = "id",
    label,
    value,
    disabled,
    userRole,
  } = props;

  if (typeof canAllEAltriUtenti !== "undefined") {
    if (typeof props.canAll === "undefined") {
      canAll = canAllEAltriUtenti;
    }
    if (typeof props.canAltriUtenti === "undefined") {
      canAltriUtenti = canAllEAltriUtenti;
    }
  }
  if (!canAll) {
    defaultSelection = "me";
  }

  const showToast = useToast();
  const ME = useUser();

  const utenti = useDataSource({
    initialData: [],
    loadAction: getUtentiAction,
    autoLoad: true,
    onLoadSuccess: (response) => {},
    onLoadError: (response) => {
      showToast("Error durante il caricamento della lista utenti", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  let initalValue =
    typeof value !== "undefined" && value !== null
      ? value
      : defaultSelection === "me"
      ? "###ME"
      : defaultSelection === "all"
      ? "###ALL"
      : null;

  const getValue = (user) => {
    if (returnValue === "cognome") {
      return user.cognome;
    } else {
      return user.id;
    }
  };

  if (initalValue === getValue(ME)) {
    initalValue = "###ME";
  }

  if (initalValue === "###ALL") {
    if (!canAll) {
      initalValue = "###ME";
    }
  }
  if (initalValue && initalValue !== "###ME" && initalValue !== getValue(ME)) {
    if (!canAltriUtenti) {
      initalValue = "###ME";
    }
  }

  const getNewValue = (newValue) => {
    let user;
    const userId =
      newValue &&
      newValue !== "###ALL" &&
      newValue !== "###ME" &&
      parseInt(newValue, 10);
    if (!newValue || isNaN(userId)) {
      newValue = defaultSelection === "me" ? "###ME" : "###ALL";
    }

    if (newValue === "###ALL") {
      user = null;
    } else if (newValue === "###ME") {
      user = ME;
    } else {
      user = find(utenti.data, (u) => u.id === userId) || null;
    }
    const v = user ? getValue(user) : null;
    return v;
  };

  useEffect(() => {
    if (!utenti.data || utenti.loading) {
      return;
    }
    const expected = getNewValue(initalValue);
    if (value !== expected) {
      onChange(expected);
    }
  }, [utenti.data, utenti.loading]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const v = getNewValue(newValue);
    onChange(v);
  };

  // console.log(utenti.data);

  return (
    <FormControl
      variant="standard"
      style={{ marginLeft: 8, marginRight: 8, marginTop: 0, marginBottom: 0 }}
    >
      {label && (
        <InputLabel style={{ position: "absolute", marginTop: -11 }}>
          {label}
        </InputLabel>
      )}

      <Select
        disabled={disabled}
        defaultValue={initalValue}
        onChange={handleChange}
        size="small"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        {canAll && (
          <MenuItem value="###ALL">
            <em>Tutti</em>
          </MenuItem>
        )}
        <MenuItem value="###ME">
          <em>Me stesso</em>
        </MenuItem>
        {canAltriUtenti && <hr />}

        {canAltriUtenti &&
          utenti.data.map((user) => {
            if (userRole) {
              if (!userHasRole(user, userRole)) {
                return null;
              }
            }
            return (
              <MenuItem key={user.id} value={user.id}>
                {user.cognome} {user.nome}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
