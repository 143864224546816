import React from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import EventoAgendaForm from "./EventoAgendaForm";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import SaveIcon from "@material-ui/icons/Save";
import { useToast } from "app/hooks/useToast";
import { createEventoAgenda, editEventoAgenda } from "app/actions";
import { PromemoriaRicontattoSettingsButton } from "./PromemoriaRicontattoSettings";

export default function EventoAgendaDialog({
  arraySource,
  eventoId,
  readOnly = false,
  onClose = () => {},
}) {
  // Ho wrappato EventoAgendaDialog per fare in modo che useDataSource venga reinizializzato.
  // Questo serve perché c'è qualche bug in DataSource (o nel modo in cui è usato in questo file), e senza
  // wrapparlo, succede questo bug:
  // 1. prova a creare un nuovo evento
  // 2. modifica un evento esistente
  // 3. prova a creare un nuovo evento. Il nuovo evento avrà nuovamente i dati inseriti in (1)
  return eventoId ? (
    <EventoAgendaDialogInternal
      arraySource={arraySource}
      eventoId={eventoId}
      readOnly={readOnly}
      onClose={onClose}
    />
  ) : null;
}

function EventoAgendaDialogInternal({
  arraySource,
  eventoId,
  readOnly = false,
  onClose = () => {},
}) {
  const showToast = useToast();
  const isNew = eventoId === "new";

  const source = useDataSource({
    parent: isNew ? undefined : arraySource,
    pathInParent: isNew
      ? undefined
      : [
          {
            id: eventoId,
          },
        ],
    initialData: {},
    itemId: eventoId,
    autoLoad: !isNew,
    createAction: createEventoAgenda,
    onCreateSuccess: () => {
      showToast("Evento creato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      arraySource.load().then();
      onClose();
    },
    onCreateError: () => {
      showToast("Errore durante la creazione dell'evento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editEventoAgenda,
    onEditSuccess: () => {
      showToast("Evento modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      onClose();
    },
    onEditError: () => {
      showToast("Errore durante la modifica dell'evento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const saving = source.editing || source.creating;

  const readOnlyOrSaving = readOnly ? true : saving;

  const saveFunction = async () => {
    await source.commit();
    if (isNew) {
      source.clear();
    }
    return null;
  };

  return eventoId ? (
    <Dialog maxWidth="md" open>
      <DialogTitle>
        {isNew ? "Crea nuovo evento" : "Modifica evento"}
      </DialogTitle>

      <DialogContent>
        <EventoAgendaForm
          source={source}
          readOnly={readOnlyOrSaving}
          hideDataEffettiva={isNew}
          hideStato={isNew}
          canChangeRelations={isNew}
        />
      </DialogContent>

      <DialogActions>
        <div style={{ flex: 1 }}>
          {source.getValue("isPromemoriaRicontatto") ? (
            <PromemoriaRicontattoSettingsButton
              azienda={source.getValue("azienda")}
              utente={source.getValue("utente")}
              onSettingsSaved={() => {
                arraySource.load();
              }}
            />
          ) : null}
        </div>
        <ColoredButton
          disabled={saving}
          onClick={() => {
            if (isNew) {
              source.delete();
            } else {
              source.cancel();
            }

            onClose(false);
          }}
          color="secondary"
          variant="outlined"
        >
          {readOnly ? "Chiudi" : "Annulla"}
        </ColoredButton>

        {!readOnlyOrSaving ? (
          <ColoredButton
            style={{
              marginLeft: 8,
            }}
            disabled={saving}
            onClick={saveFunction}
            color="secondary"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Salva
          </ColoredButton>
        ) : null}
      </DialogActions>
    </Dialog>
  ) : null;
}
