import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { useDataTableContext } from "app/contexts/DataTableContext";

export const DataTablePagination = () => {
  const { filteredRowsCount, page, setPage, pageSize, setPageSize, messages } =
    useDataTableContext();
  return (
    <TablePagination
      count={filteredRowsCount}
      page={page}
      onPageChange={(_, page) => setPage(page)}
      rowsPerPage={pageSize}
      onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
      labelRowsPerPage={messages.rowsPerPage}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} ${(messages as any).displayedRowsSeparator} ${
          count !== -1 ? count : `${to}+}`
        }`
      }
    />
  );
};
