import React, { useState, useMemo } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getLinee as getLineeAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { orderBy } from "lodash";

function addLabel(linea) {
  const { nome, formatProceduraCorrente, legge } = linea;
  const label = legge.isPluriennale
    ? `${legge.nome} (pluriennale)`
    : `${legge.nome} - ${nome} (v${formatProceduraCorrente?.versione || "-"})`;
  linea._label = label;
  linea._labelCaseIns = label.toLowerCase();
}

export default function LineaPicker({
  idLinea,
  onChange,
  filterLinea,
  readOnly,
  variant,
  error,
}) {
  const showToast = useToast();

  const [selectedLinea, setSelectedLinea] = useState(null);

  const linee = useDataSource({
    initialData: [],
    loadAction: getLineeAction,
    autoLoad: true,
    onLoadSuccess: (response) => {
      const linea = response.data.find((l) => {
        return l.id === idLinea;
      });

      if (linea) {
        setSelectedLinea(linea);
      } else {
        setSelectedLinea(null);
      }
    },
    onLoadError: () => {
      showToast("Error durante il caricamento della lista linee", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const options = useMemo(() => {
    let filteredLinee =
      (filterLinea ? linee.data.filter(filterLinea) : linee.data) || [];
    filteredLinee = skipAnnualitaSuccessiveLeggiPluriennali(filteredLinee);

    filteredLinee.forEach((option) => {
      addLabel(option);
    });

    return orderBy(filteredLinee, ["_labelCaseIns"], ["asc"]);
  }, [filterLinea, linee.data]);

  const value = useMemo(() => {
    if (selectedLinea) {
      addLabel(selectedLinea);
    }
    return selectedLinea;
  }, [selectedLinea]);

  return (
    <Autocomplete
      disabled={readOnly}
      fullWidth
      loading={linee.loading}
      loadingText="Caricamento lista linee ..."
      noOptionsText="Nessuna linea"
      options={options}
      size="small"
      value={value}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={variant || "outlined"}
            error={!!error}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option._label;
      }}
      renderOption={(option) => {
        return option._label;
      }}
      onChange={(event, value) => {
        setSelectedLinea(value);
        onChange(value);
      }}
    />
  );
}

function skipAnnualitaSuccessiveLeggiPluriennali(linee) {
  const lineaByIdLeggePlueriennale = {};
  const lineeLeggiPluriennali = linee.filter(
    (linea) => linea.legge.isPluriennale
  );

  for (const linea of lineeLeggiPluriennali) {
    const idLegge = linea.idLegge;
    if (
      !lineaByIdLeggePlueriennale[idLegge] ||
      lineaByIdLeggePlueriennale[idLegge].id > linea.id
    ) {
      lineaByIdLeggePlueriennale[idLegge] = linea;
    }
  }

  return [
    ...linee.filter((linea) => !linea.legge.isPluriennale),
    ...Object.values(lineaByIdLeggePlueriennale),
  ];
}
