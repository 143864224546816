import React from "react";
import ClientiTable from "app/components/Clienti/ClientiTable";
import { getClientiContatti as getClientiAction } from "app/actions";

export default function ClientiTable_Contatti(props) {
  return (
    <ClientiTable
      title="Aziende - Contatti"
      getClientiAction={getClientiAction}
      exportFilename="Contatti"
    />
  );
}
