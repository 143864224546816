import React, { useMemo, useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { getMandatiForCliente as getMandatiForClienteAction } from "app/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { orderBy } from "lodash";

export default function ClienteMandatoPicker(props) {
  const showToast = useToast();

  const [selectedMandato, setSelectedMandato] = useState(null);

  const mandati = useDataSource({
    initialData: [],
    loadAction: getMandatiForClienteAction,
    itemId: props.clienteId,
    onLoadError: (response) => {
      showToast("Errore durante il caricamento dei mandati dell'azienda", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    autoLoad: !!props.clienteId,
    onLoadSuccess: (response) => {
      const mandato = response.data.find((s) => {
        return s.id === props.source.getValue(props.sourceKey);
      });

      if (mandato) {
        setSelectedMandato(mandato);
      } else {
        setSelectedMandato(null);
      }
    },
  });

  const sourceError =
    props.source.getValidationErrors(props.sourceKey)[0] || null;

  const options = useMemo(() => {
    const d =
      (props.filterStato
        ? mandati.data.filter(props.filterStato)
        : mandati.data) || [];

    d.forEach((option) => {
      const label = option.nome || "";
      option._labelCaseIns = label.toLowerCase();
    });

    return orderBy(d, ["_labelCaseIns"], ["asc"]);
  }, [props.filterStato, mandati.data]);

  return (
    <Autocomplete
      disabled={props.readOnly}
      fullWidth={true}
      loading={mandati.loading}
      loadingText="Caricamento lista mandati ..."
      multiple={false}
      noOptionsText="Nessun mandato"
      onClose={null}
      options={options}
      size="small"
      value={selectedMandato}
      renderInput={(inheritProps) => {
        return (
          <TextField
            {...inheritProps}
            variant={props.variant}
            label={props.label}
            error={!!sourceError}
            InputLabelProps={{ shrink: props.shrink }}
          />
        );
      }}
      getOptionLabel={(option) => {
        return option.nome || "";
      }}
      renderOption={(option) => {
        return option.nome || "";
      }}
      onChange={(event, value) => {
        setSelectedMandato(value);

        props.source.changeValue(props.sourceKey, value?.id ?? null);
      }}
    />
  );
}
