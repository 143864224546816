import { useMemo } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { Button, ButtonGroup, Container, Typography } from "@material-ui/core";
import { getVolumiContratti } from "app/actions";
import {
  PivotRowGetter,
  PivotTable,
  PivotTableColumn,
} from "../PivotTable/PivotTable";
import { sum } from "lodash";
import { useLoadApi } from "app/hooks/useLoadApi";
import { ContrattiCell } from "../AnalisiCommerciale/ContrattiCell";
import moment from "moment";
import UserFilterSelector from "../common/UserFilterSelector";
import AnnoPicker from "../common/AnnoPicker";
import { useStateWithSaveCurrentState } from "app/hooks/useStateWithSaveCurrentState";

const getPivotRowsLinea: PivotRowGetter<any> = (d) => {
  return {
    key: d.idLinea,
    label: d.nomeLinea,
  };
};
const getPivotRowsCommerciale: PivotRowGetter<any> = (d) => {
  return {
    key: d.idCommerciale,
    label: d.commerciale,
  };
};

export function VolumiContratti(props) {
  const hasPermission = useCheckPermission();

  const canSeeAll = hasPermission("visualizza_volumi_contratti_tutti");

  // save for history change!
  const [anno, setAnno] = useStateWithSaveCurrentState(
    moment().year(),
    "vol_contratti_anno",
    "all_object"
  );

  // save for history change!
  const [userFilter, setUserFilter] = useStateWithSaveCurrentState(
    null,
    "vol_contratti_user",
    "all_object"
  );

  const [groupBy, setGroupBy] = useStateWithSaveCurrentState(
    "linea",
    "vol_contratti_groupby",
    "all_object"
  );

  const api = useLoadApi(getVolumiContratti, {
    anno,
    idCommerciale: userFilter,
  });

  const columns = useMemo(() => {
    return createColumns(anno);
  }, [anno]);

  const pivotRowsGetter: [PivotRowGetter<any>, PivotRowGetter<any>] = [
    getPivotRowsLinea,
    getPivotRowsCommerciale,
  ];
  const pivotRowsLabel: [string, string] = ["Linea", "Commerciale"];

  if (groupBy === "user") {
    pivotRowsGetter.reverse();
    pivotRowsLabel.reverse();
  }

  if (!hasPermission(["visualizza_volumi_contratti"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }
  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters={true}
      fixed={false}
      maxWidth={false}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          marginBottom: 10,
        }}
      >
        <h1
          className="page-title-header"
          style={{
            marginTop: -20,
          }}
        >
          Volumi Contratti
        </h1>{" "}
        <div style={{ marginLeft: "auto" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <div style={{ position: "absolute", top: -16 }}>
              <Typography
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
                variant="caption"
              >
                Raggruppa per
              </Typography>
            </div>
            <ButtonGroup color="primary" size="small" disableElevation>
              <Button
                variant={groupBy === "linea" ? "contained" : "outlined"}
                onClick={() => setGroupBy("linea")}
              >
                Linea
              </Button>
              <Button
                variant={groupBy === "user" ? "contained" : "outlined"}
                onClick={() => setGroupBy("user")}
              >
                Commerciale
              </Button>
            </ButtonGroup>
          </div>
          <UserFilterSelector
            returnValue="id"
            defaultSelection="all"
            label="Operatore"
            onChange={setUserFilter}
            value={userFilter}
            canAllEAltriUtenti={canSeeAll}
            userRole="Commerciale"
          />
          <AnnoPicker anno={anno} setAnno={setAnno} />
        </div>
      </div>

      {api.loading ? (
        "Caricamento dati..."
      ) : api.error ? (
        "Errore durante il caricamento dei dati"
      ) : (
        <PivotTable
          pivotRowsGetter={pivotRowsGetter}
          pivotRowsLabel={pivotRowsLabel}
          data={api.data || []}
          columns={columns}
          showTotal
          totalColumnLabel="Totale"
          totalColumnWidth={90}
          totalRender={(data) => <CellRenderer data={data} />}
        />
      )}
    </Container>
  );
}
function createColumns(anno: number): PivotTableColumn<any>[] {
  const result: PivotTableColumn<any>[] = [];
  for (let index = 1; index <= 12; index++) {
    const mese = `${index < 10 ? "0" : ""}${index}`;

    const key = `${anno}-${mese}`;
    const label = `${mese}/${anno}`;

    result.push({
      key: key,
      label: label,
      render: (data) => <CellRenderer data={data} />,
      filter: (d) => d.month === key,
      width: 90,
    });
  }
  return result;
}

function CellRenderer({ data }: { data: any[] }) {
  if (!data || !data.length) {
    return null;
  }
  const n = sum(data.map((x) => x.count));
  const importo = sum(data.map((x) => x.importo));

  return <ContrattiCell numeroDiContratti={n} importo={importo} />;
}
