import React from "react";
import OpportunitaLineaScadenzaDashboard from "./Dashboard/OpportunitaLineaScadenzaDashboard";
import useSideModal from "./common/useSideModal";
import { Container } from "@material-ui/core";

export default function OpportunitaInScadenzaTable() {
  const { openSideModal, sideModal } = useSideModal();

  return (
    <Container
      style={{
        height: "calc(100vh - 160px)",
      }}
      disableGutters
    >
      {sideModal}
      <h1
        className="page-title-header"
        style={{
          marginTop: -20,
        }}
      >
        Offerte in scadenza
      </h1>

      <OpportunitaLineaScadenzaDashboard
        openSideModal={openSideModal}
        hideTitle
        pageSize={100}
      />
    </Container>
  );
}
