import React from "react";
import ClienteStato from "app/constants/ClienteStato";
import { Typography } from "@material-ui/core";

export default function StatoClienteBadge(props) {
  const color =
    ClienteStato.find((stato) => stato.value === props.stato)?.color ?? null;

  return (
    <Typography
      style={{
        background: color,
        border: "1px solid transparent",
        borderRadius: 4,
        padding: "3px 5px",
        margin: "8px 8px",
      }}
      align="inherit"
      color="inherit"
      display="inline"
      gutterBottom={true}
      noWrap={false}
      paragraph={false}
      variant="caption"
    >
      {props.stato}{" "}
    </Typography>
  );
}
