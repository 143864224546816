import React from "react";
import ContattiTable from "app/components/Contatti/ContattiTable";
import { getContattiLead } from "app/actions";

export default function ContattiTable_Lead() {
  return (
    <ContattiTable
      title="Contatti - Lead"
      getContattiAction={getContattiLead}
      exportFilename="Lead"
    />
  );
}
