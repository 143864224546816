import "./setupSentry";
import "react-app-polyfill/stable";

import "./disableChromeBackGesture.scss";

import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./locale";

import store from "./store";

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider, IconButton } from "@material-ui/core";
import { Close as IconClose } from "@material-ui/icons";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import { SnackbarProvider, useSnackbar } from "notistack";

// Styles
import "./style/style.scss";

// import components

import App from "./App";

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      style={{ color: "inherit" }}
      size="small"
      onClick={() => closeSnackbar(snackbarKey)}
    >
      <IconClose fontSize={"inherit"} />
    </IconButton>
  );
}

const render = (AppComponent) => {
  const RenderComponent = () => (
    <Provider store={store}>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={"it" /* TODO: locale.... */}
      >
        <ThemeProvider
          theme={useMemo(
            () =>
              createTheme({
                palette: { primary: { main: "#0084A9" } },
                spacing: [0, 2, 4, 8, 16, 32],
                props: {
                  MuiButton: { size: "small" },
                  MuiFilledInput: { margin: "dense" },
                  MuiFormControl: { margin: "dense" },
                  MuiFormHelperText: { margin: "dense" },
                  MuiIconButton: { size: "small" },
                  MuiInputBase: { margin: "dense" },
                  MuiInputLabel: { margin: "dense" },
                  MuiListItem: { dense: true },
                  MuiOutlinedInput: { margin: "dense" },
                  MuiFab: { size: "small" },
                  MuiTable: { size: "small" },
                  MuiTableCell: {
                    sizeSmall: {
                      paddingTop: 3,
                      paddingRight: 5,
                      paddingBottom: 3,
                      paddingLeft: 5,
                    },
                  },
                  MuiTextField: { margin: "dense" },
                  MuiToolbar: { variant: "dense" },
                },
                overrides: {
                  MuiIconButton: {
                    sizeSmall: { marginLeft: 2, marginRight: 2, padding: 2 },
                  },
                  MuiAccordionSummary: {
                    root: {
                      minHeight: 32,
                      maxHeight: 32,
                      "&$expanded": {
                        minHeight: 32,
                      },
                    },
                    content: { minHeight: 32, paddingTop: 8 },
                    // expanded: { minHeight: 32 },
                  },
                  MuiAccordionDetails: {
                    root: { padding: "8px 16px" },
                  },
                },
              }),
            []
          )}
        >
          <CssBaseline />
          <SnackbarProvider
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <AppComponent />
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );

  ReactDOM.render(<RenderComponent />, document.getElementById("root"));
};

render(App);
