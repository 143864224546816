import React, { useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
  IconButton,
  Dialog,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import { ColoredButton } from "app/elements/ColoredButton";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

export default function EditorDizionario(props) {
  const showToast = useToast();

  const dizionario = useDataSource({
    initialData: [],
    loadAction: props.loadAction,
    autoLoad: true,
    onLoadError: (response) => {
      showToast(`Impossibile caricare il dizionario '${props.title}'`, {
        color: "error",
      });
    },
  });

  const canBeDeleted = (rowSource) => {
    return props.filterCanBeDeleted
      ? props.filterCanBeDeleted(rowSource)
      : true;
  };

  const { customColumns = [] } = props;

  const [deletingRowSource, setDeletingRowSource] = useState(null);
  return (
    <>
      <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {props.title}
        </AccordionSummary>

        <AccordionDetails>
          <DataTable
            source={dizionario}
            rowSourceOptions={{
              createAction: props.createAction,
              onCreateSuccess: () => {
                setDeletingRowSource(null);

                showToast("Valore creato con successo", { color: "success" });
              },

              editAction: props.editAction,
              onEditSuccess: () => {
                setDeletingRowSource(null);

                showToast("Valore modificato con successo", {
                  color: "success",
                });
              },

              onEditError: () => {
                showToast("Errore durante il salvataggio", { color: "error" });
              },

              deleteAction: props.deleteAction,
              onDeleteSuccess: () => {
                setDeletingRowSource(null);

                showToast("Valore eliminato con successo", {
                  color: "success",
                });
              },

              onDeleteError: (response) => {
                setDeletingRowSource(null);

                showToast(
                  response.constraintsViolated
                    ? "Non puoi eliminare questo valore perchè è già in utilizzo. Come alternativa puoi disattivarlo cliccando sull'icona per la modifica"
                    : "Errore durante l'eliminazione del valore",
                  { color: "error" }
                );
              },
            }}
            columns={[
              {
                path: ["nome"],
                header: "Valore",
                sortable: true,
                type: "text",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <TextField
                      className={
                        rowSource.getValue("attivo") ? "" : "crea-lineThrough"
                      }
                      disabled={
                        rowSource.loading ||
                        rowSource.editing ||
                        !canBeDeleted(rowSource)
                      }
                      error={rowSource.getValidationErrors("nome").length > 0}
                      fullWidth={true}
                      helperText={rowSource.getValidationErrors("nome")[0]}
                      onBlur={(event) => {
                        rowSource.runValidation("nome");
                      }}
                      onChange={(event) => {
                        const value = event.target.value;

                        rowSource.changeValue(
                          "nome",

                          value
                        );
                      }}
                      size="small"
                      type="text"
                      value={rowSource.getValue("nome", "")}
                      variant={
                        props.canEditNome
                          ? rowSource.getValue("_isActive")
                            ? "outlined"
                            : "standard"
                          : rowSource.getValue("__isNew")
                          ? "outlined"
                          : "standard"
                      }
                      InputProps={{
                        readOnly: props.canEditNome
                          ? !rowSource.getValue("_isActive") ||
                            !rowSource.getValue("__isNew")
                          : !rowSource.getValue("__isNew"),
                      }}
                    />
                  );
                },
              },
              {
                path: ["order"],
                header: props.canOrder ? "Ordine" : "",
                disableColumnFilter: true,
                disableGlobalFilter: true,
                width: props.canOrder ? 120 : 0,
                sortable: true,
                type: "number",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <TextField
                      className={
                        rowSource.getValue("attivo") ? "" : "crea-lineThrough"
                      }
                      style={{
                        display: props.canOrder ? "block" : "none",
                      }}
                      disabled={rowSource.loading || rowSource.editing}
                      error={rowSource.getValidationErrors("order").length > 0}
                      fullWidth={true}
                      helperText={rowSource.getValidationErrors("order")[0]}
                      onBlur={(event) => {
                        rowSource.runValidation("order");
                      }}
                      onChange={(event) => {
                        const value = event.target.value;

                        rowSource.changeValue(
                          "order",

                          value
                        );
                      }}
                      size="small"
                      value={rowSource.getValue("order", "")}
                      variant={
                        props.canEditOrder
                          ? rowSource.getValue("_isActive")
                            ? "outlined"
                            : "standard"
                          : rowSource.getValue("__isNew")
                          ? "outlined"
                          : "standard"
                      }
                      InputProps={{
                        readOnly: props.canEditOrder
                          ? !rowSource.getValue("_isActive")
                          : !rowSource.getValue("__isNew"),

                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  );
                },
              },
              ...customColumns,
              {
                path: ["id"],
                header: "Azioni",
                sortable: true,
                type: "number",
                width: 200,
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <div
                      style={{
                        maxWidth: 200,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {rowSource.getValue("_isActive") ? (
                        <>
                          <div
                            style={{
                              marginRight: 8,
                              flex: 1,
                            }}
                          >
                            {/* {!rowSource.getValue("__isNew") ? (
                              <ColoredButton
                                onClick={(event) => {
                                  rowSource.changeValue(
                                    "attivo",

                                    !rowSource.getValue("attivo")
                                  );
                                }}
                                color="secondary"
                                fullWidth={true}
                                variant="outlined"
                              >
                                {rowSource.getValue("attivo")
                                  ? "Disattiva"
                                  : "Attiva"}
                              </ColoredButton>
                            ) : null}{" "} */}
                          </div>

                          <Tooltip title="Salva le modifiche">
                            <IconButton
                              onClick={(event) => {
                                rowSource.commit();
                              }}
                            >
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Annulla le modifiche">
                            <IconButton
                              onClick={(event) => {
                                if (rowSource.getValue("__isNew")) {
                                  dizionario.delete({
                                    tempId: value,
                                  });
                                } else {
                                  rowSource.cancel();
                                }
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            style={{
                              display: props.canEdit ? "initial" : "none",
                            }}
                            title="Modifica"
                          >
                            <IconButton
                              onClick={(event) => {
                                rowSource.changeValue("_isActive", true);
                              }}
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>

                          {canBeDeleted(rowSource) && (
                            <Tooltip title="Elimina">
                              <IconButton
                                onClick={(event) => {
                                  setDeletingRowSource(rowSource);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}{" "}
                    </div>
                  );
                },
              },
            ]}
            topToolbar={null}
            bottomToolbar={
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "0.75em",
                  }}
                >
                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    onClick={(event) => {
                      dizionario.create({
                        _isActive: true,
                        attivo: true,
                      });
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Aggiungi valore
                  </ColoredButton>
                </div>
              </div>
            }
            showFilterRow={false}
            pageSize={1000000}
            size="small"
            expandableRows={false}
            expandRowsOnClick={false}
            isRowExpandable={null}
            renderExpandedRow={null}
            messages={{
              noRows: "Nessun valore",
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Dialog maxWidth="md" open={deletingRowSource}>
        <DialogTitle>Conferma di eliminazione</DialogTitle>

        <DialogContent>
          <Typography>
            {deletingRowSource
              ? 'Sei sicuro di voler eliminare il valore "' +
                deletingRowSource.getValue("nome") +
                '"?'
              : null}{" "}
          </Typography>
        </DialogContent>

        <DialogActions>
          <ColoredButton
            onClick={(event) => {
              setDeletingRowSource(null);
            }}
            color="secondary"
            variant="outlined"
          >
            Annulla
          </ColoredButton>

          <ColoredButton
            onClick={(event) => {
              deletingRowSource.delete();
            }}
            color="primary"
            variant="outlined"
          >
            Elimina
          </ColoredButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
