import { orderBy, groupBy, uniq } from "lodash";

export const gruppiPermessi = [
  "Generale",
  "Commerciale",
  "Tecnico",
  "Centro Studi",
  "Amministrazione",
  "Landing page",
  "Altri permessi",
] as const;

type PermessoGroup = (typeof gruppiPermessi)[number];

type Permesso = {
  name: string;
  help?: string;
  gruppo: PermessoGroup;
  label: string;
};

const _permessi: Permesso[] = [
  {
    name: "approva_pagamenti",
    gruppo: "Amministrazione",
    label: "Approva pagamenti",
  },
  {
    name: "approva_mandato",
    gruppo: "Commerciale",
    label: "Approva mandato",
  },
  {
    name: "autorizza_mandato",
    gruppo: "Commerciale",
    label: "Autorizza mandato",
  },
  { name: "crea_azienda", gruppo: "Generale", label: "Crea azienda" },
  {
    name: "crea_contatto",
    gruppo: "Generale",
    label: "Crea contatti",
  },
  {
    name: "crea_fattura",
    gruppo: "Amministrazione",
    label: "Crea fattura",
  },
  {
    name: "crea_format_strumenti",
    gruppo: "Centro Studi",
    label: "Crea format strumento",
  },
  { name: "crea_legge", gruppo: "Centro Studi", label: "Crea legge" },
  { name: "crea_linea", gruppo: "Centro Studi", label: "Crea linea" },
  {
    name: "crea_offerta_mandato",
    gruppo: "Commerciale",
    label: "Crea un'offerta/mandato",
  },
  {
    name: "crea_versione_format_procedura",
    gruppo: "Centro Studi",
    label: "Crea versione format procedura",
  },
  {
    name: "duplica_format_strumento",
    gruppo: "Centro Studi",
    label: "Duplica format strumento",
  },
  {
    name: "duplica_legge",
    gruppo: "Centro Studi",
    label: "Duplica una legge",
  },
  {
    name: "duplica_linea",
    gruppo: "Centro Studi",
    label: "Duplica una linea",
  },
  {
    name: "gestioneRuoliPermessi",
    gruppo: "Altri permessi",
    label: "Gestione ruoli permessi",
    help: "Permette di modificare l'assegnazione di ruoli/permessi di questa pagina",
  },
  {
    name: "duplica_offerta_mandato",
    gruppo: "Commerciale",
    label: "Duplica offerta mandato",
  },
  {
    name: "elimina_azienda",
    gruppo: "Generale",
    label: "Elimina azienda",
  },
  {
    name: "elimina_budget",
    gruppo: "Amministrazione",
    label: "Elimina budget",
  },
  {
    name: "elimina_contatto",
    gruppo: "Generale",
    label: "Elimina contatto",
  },
  {
    name: "elimina_format_strumenti",
    gruppo: "Centro Studi",
    label: "Elimina format strumenti",
  },
  { name: "elimina_legge", gruppo: "Centro Studi", label: "Elimina legge" },
  { name: "elimina_linea", gruppo: "Centro Studi", label: "Elimina linea" },
  {
    name: "elimina_offerta_mandato",
    gruppo: "Commerciale",
    label: "Elimina offerta mandato",
  },
  { name: "estendi_linea", gruppo: "Centro Studi", label: "Estendi linea" },
  {
    name: "gestioneUtenti",
    gruppo: "Altri permessi",
    label: "Gestione utenti",
  },
  {
    name: "landing_page_attivita_in_scadenza",
    gruppo: "Landing page",
    label: "Visualizza attività in scadenza",
  },
  {
    name: "landing_page_commesse_da_assegnare",
    gruppo: "Landing page",
    label: "Visualizza commesse da assegnare",
  },
  {
    name: "landing_page_interazioni_con_clienti",
    gruppo: "Landing page",
    label: "Visualizza interazioni con clienti",
  },
  {
    name: "landing_page_linee_in_scadenza",
    gruppo: "Landing page",
    label: "Visualizza linee in scadenza",
  },
  {
    name: "landing_page_offerte_dormienti",
    gruppo: "Landing page",
    label: "Visualizza offerte dormienti",
  },
  {
    name: "landing_page_offerte_in_scadenza",
    gruppo: "Landing page",
    label: "Visualizza offerte in scadenza",
  },
  {
    name: "modifica_attivita",
    gruppo: "Tecnico",
    label: "Modifica attività",
  },
  {
    name: "modifica_azienda",
    gruppo: "Generale",
    label: "Modifica azienda",
  },
  {
    name: "scarica_dati_atoka",
    gruppo: "Generale",
    label: "Scarica dati atoka",
    help: "Permette di creare o aggiornare un azienda scaricando i dati da Atoka",
  },
  {
    name: "modifica_budget",
    gruppo: "Amministrazione",
    label: "Modifica budget",
  },
  {
    name: "modifica_carico_di_lavoro",
    gruppo: "Tecnico",
    label: "Modifica carico di lavoro",
  },
  {
    name: "modifica_commessa",
    gruppo: "Tecnico",
    label: "Modifica commessa",
  },
  {
    name: "riapri_sospendi_commessa",
    gruppo: "Tecnico",
    label: "Riapri o sospendi commessa",
    help: "Permette i seguenti cambi di stato di una commessa: archiviata ➝ in-corso, in-corso ➝ standby oppure standby ➝ in-corso",
  },
  {
    name: "aggiungi_attivita_commessa_anche_se_non_operatore",
    gruppo: "Tecnico",
    label: "Aggiungi attività ad una commessa",
    help: "Permette di aggiungere un'attività ad una commessa anche quando l'utente non è l'operatore della commessa",
  },
  {
    name: "modifica_contatto",
    gruppo: "Generale",
    label: "Modifica contatto",
  },
  {
    name: "modifica_fattura",
    gruppo: "Amministrazione",
    label: "Modifica fattura",
  },
  {
    name: "modifica_format_procedura",
    gruppo: "Centro Studi",
    label: "Modifica format procedura",
  },
  {
    name: "modifica_format_strumenti",
    gruppo: "Centro Studi",
    label: "Modifica format strumenti",
  },
  { name: "modifica_legge", gruppo: "Centro Studi", label: "Modifica legge" },
  { name: "modifica_linea", gruppo: "Centro Studi", label: "Modifica linea" },
  { name: "modifica_news", gruppo: "Altri permessi", label: "Modifica news" },
  {
    name: "modifica_offerta_mandato",
    gruppo: "Commerciale",
    label: "Modifica offerta mandato",
  },
  {
    name: "modifica_pagamento",
    gruppo: "Amministrazione",
    label: "Modifica pagamento",
  },
  {
    name: "modifica_referenti",
    gruppo: "Generale",
    label: "Modifica referenti",
  },
  {
    name: "visualizza_analisi_commerciale",
    gruppo: "Amministrazione",
    label: "Visualizza analisi commerciale",
    help: "Visualizza la propria analisi commerciale",
  },
  {
    name: "visualizza_analisi_commerciale_tutti",
    gruppo: "Amministrazione",
    label: "Visualizza analisi commerciale tutti",
    help: "Visualizza le analisi commerciali di tutti gli utenti",
  },
  {
    name: "visualizza_attivita",
    gruppo: "Tecnico",
    label: "Visualizza attività",
  },
  {
    name: "visualizza_azienda",
    gruppo: "Generale",
    label: "Visualizza azienda",
  },
  {
    name: "visualizza_budget",
    gruppo: "Amministrazione",
    label: "Visualizza budget",
  },
  {
    name: "visualizza_budget_tutti",
    gruppo: "Amministrazione",
    label: "Visualizza budget tutti",
    help: "Visualizza il udget di tutti gli utente",
  },
  {
    name: "visualizza_carico_di_lavoro",
    gruppo: "Tecnico",
    label: "Visualizza carico di lavoro",
  },
  {
    name: "visualizza_commessa",
    gruppo: "Generale",
    label: "Visualizza commessa",
  },
  {
    name: "visualizza_contatto",
    gruppo: "Generale",
    label: "Visualizza contatto",
  },
  {
    name: "visualizza_dashboard_statistiche",
    gruppo: "Amministrazione",
    label: "Visualizza dashboard statistiche",
  },
  {
    name: "visualizza_dashboard_statistiche_tutti",
    gruppo: "Amministrazione",
    label: "Visualizza dashboard statistiche tutti",
    help: "Visualizza i dati di tutti gli utenti nella dashboard delle statistiche",
  },
  {
    name: "visualizza_dizionari",
    gruppo: "Altri permessi",
    label: "Visualizza dizionari",
  },
  {
    name: "visualizza_fattura",
    gruppo: "Amministrazione",
    label: "Visualizza fattura",
  },
  {
    name: "visualizza_format_procedura",
    gruppo: "Centro Studi",
    label: "Visualizza format procedura",
  },
  {
    name: "visualizza_format_strumenti",
    gruppo: "Centro Studi",
    label: "Visualizza format strumenti",
  },
  {
    name: "visualizza_legge",
    gruppo: "Generale",
    label: "Visualizza legge",
  },
  {
    name: "visualizza_linea",
    gruppo: "Generale",
    label: "Visualizza linea",
  },
  {
    name: "visualizza_menu_attivita",
    gruppo: "Landing page",
    label: "Menu attività",
  },
  {
    name: "visualizza_menu_aziende",
    gruppo: "Landing page",
    label: "Menu aziende",
  },
  {
    name: "visualizza_menu_commesse",
    gruppo: "Landing page",
    label: "Menu commesse",
  },
  {
    name: "visualizza_menu_dashboard",
    gruppo: "Landing page",
    label: "Menu dashboard",
  },
  {
    name: "visualizza_menu_dizionari",
    gruppo: "Landing page",
    label: "Menu dizionari",
  },
  {
    name: "visualizza_menu_format_procedura_commesse",
    gruppo: "Landing page",
    label: "Menu format procedura commesse",
  },
  {
    name: "visualizza_menu_format_strumenti",
    gruppo: "Landing page",
    label: "Menu format strumenti",
  },
  {
    name: "visualizza_menu_leggi",
    gruppo: "Landing page",
    label: "Menu leggi",
  },
  {
    name: "visualizza_menu_linee",
    gruppo: "Landing page",
    label: "Menu linee",
  },
  {
    name: "visualizza_menu_news",
    gruppo: "Landing page",
    label: "Menu news",
  },
  {
    name: "visualizza_menu_offerte_e_mandati",
    gruppo: "Landing page",
    label: "Menu offerte e mandati",
  },
  {
    name: "visualizza_menu_pagamenti_e_fatture",
    gruppo: "Landing page",
    label: "Menu pagamenti e fatture",
  },
  {
    name: "visualizza_menu_rubrica",
    gruppo: "Landing page",
    label: "Menu rubrica",
  },
  {
    name: "visualizza_menu_scadenziario",
    gruppo: "Landing page",
    label: "Menu scadenziario",
  },
  {
    name: "visualizza_offerta_mandato",
    gruppo: "Landing page",
    label: "Visualizza offerta mandato",
  },
  {
    name: "visualizza_pagamento",
    gruppo: "Landing page",
    label: "Visualizza pagamento",
  },
  {
    name: "visualizza_scadenziario",
    gruppo: "Landing page",
    label: "Visualizza Kanban",
  },
  {
    name: "visualizza_utenti",
    gruppo: "Landing page",
    label: "Visualizza utenti",
  },
  {
    name: "visualizza_volumi_contratti",
    gruppo: "Landing page",
    label: "Visualizza volumi contratti",
  },
  {
    name: "visualizza_volumi_contratti_tutti",
    gruppo: "Landing page",
    label: "Visualizza volumi contratti tutti",
  },
];

if (uniq(_permessi.map((p) => p.name)).length !== _permessi.length) {
  throw new Error("uno o più permessi hanno sono duplicati");
}

export const permessi = orderBy(_permessi, "label");

export const permessiByGruppo = groupBy(permessi, "gruppo");
