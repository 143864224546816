import React from "react";
import { ShowOnlyEmpty } from "./utils";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import { ColumnFilterOptionsComponent, useDualInputStyles } from "./index";
import { RangeColumnFilter } from "app/elements/DataTable/filters/types";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
    />
  );
}

const min = -Infinity;
const max = Infinity;

function parseValue(v: string | number, fallback: number) {
  if (typeof v === "string") {
    v = parseFloat(v);
    if (isNaN(v)) {
      v = null as any;
    }
  }
  if (v !== null && typeof v === "number") {
    return v;
  }
  return fallback;
}

function NumberInput(props) {
  const { inputRef, value, onChange, ...otherProps } = props;

  // const languageData = numeral.localeData()
  // const decimalSeparator = languageData.delimiters.decimal
  // const thousandSeparator = languageData.delimiters.thousands

  // return (
  //   <NumberFormat
  //     {...otherProps}

  //     // NumberFormat shows '-Infinity' for -Infinity but empty text for Infinity. Show an empty string
  //     // also for -Infinity for consistency
  //     value={value === min ? '' : value}
  //     getInputRef={inputRef}
  //     onValueChange={(values) => {
  //       const newValue = values.floatValue
  //       if (newValue !== value) {
  //         onChange(newValue, props.name)
  //       }
  //     }}
  //     // thousandSeparator={thousandSeparator}
  //     thousandSeparator={false}// {thousandSeparator}
  //     decimalSeparator={decimalSeparator}
  //   />
  // )

  const inputComponent = NumberFormatCustom;

  return (
    <TextField
      {...otherProps}
      variant="outlined"
      // NumberFormat shows '-Infinity' for -Infinity but empty text for Infinity. Show an empty string
      // also for -Infinity for consistency
      value={value === min ? "" : value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        inputComponent: inputComponent,
      }}
    />
  );
}

// TODO: add button to clear filter
const NumberFilter: ColumnFilterOptionsComponent<RangeColumnFilter> = ({
  field,
  filterOptions,
  setFilter,
  messages,
}) => {
  const classes = useDualInputStyles();

  const minValue =
    filterOptions && typeof filterOptions.min === "number"
      ? filterOptions.min
      : min;
  const maxValue =
    filterOptions && typeof filterOptions.max === "number"
      ? filterOptions.max
      : max;

  const onChangeNumberRange = (values) => {
    const _minValue = parseValue(values[0], min);
    const _maxValue = parseValue(values[1], max);

    if (_minValue === min && _maxValue === max) {
      setFilter(null as any);
    } else {
      setFilter({
        type: "range",
        min: _minValue,
        max: _maxValue,
      });
    }
  };

  return (
    <div className="portalgen-numberFilter">
      <div
        className={clsx(
          "portalgen-dualInputContainer",
          classes.dualInputContainer
        )}
      >
        <NumberInput
          className={classes.dualInput}
          value={minValue}
          onChange={(value) => onChangeNumberRange([value, maxValue])}
          placeholder="min"
        />
        <span>{" - "}</span>
        <NumberInput
          className={classes.dualInput}
          value={maxValue}
          onChange={(value) => onChangeNumberRange([minValue, value])}
          placeholder="max"
        />
      </div>
      {/* <ShowOnlyEmpty filterOptions={filterOptions} setFilter={setFilter} messages={messages}/> */}
    </div>
  );
};

export default NumberFilter;
