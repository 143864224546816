import React, { useRef, useState } from "react";
import { find, size } from "lodash";
import LabelledOutline from "app/components/common/LabelledOutline";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// isChangePrevented(oldStato, newStato) => errorMessage | null | false
export function StatoPicker(props) {
  const { source, stati, label, disabled, isChangePrevented } = props;
  const stato = source.getValue("stato");
  const statoObj = find(stati, (s) => s.value === stato);
  const color = statoObj ? statoObj.color : null;

  const variant = "outlined";

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idRandRef = useRef(Math.round(Math.random() * 1000000));
  const id = open ? "popover-stato-picker-" + idRandRef.current : undefined;

  const [selectedState, setSelectedState] = useState(null);

  const afterEditRef = useRef(null);
  afterEditRef.current = () => {
    if (source.editError) {
      // Reset stato
      const prevState = source.initialData;
      const { stato, statoReason, statoReasons } = prevState;
      source.changeValue("stato", stato);
      source.changeValue("statoReason", statoReason);
      source.changeValue("statoReasons", statoReasons);
    }
  };

  const save = (e) => {
    if (isChangePrevented) {
      const msg = isChangePrevented(stato, selectedState.value);
      if (msg) {
        setAnchorEl(null);
        return;
      }
    }

    source.changeValue("stato", selectedState.value);
    source.changeValue("statoReason", selectedState.reason);
    const statoReasons = [
      {
        date: new Date().toISOString(),
        stato: selectedState.value,
        statoReason: selectedState.reason,
      },
      ...(source?.initialData?.statoReasons || []),
    ];
    source.changeValue("statoReasons", statoReasons);
    setAnchorEl(null);

    // SALVA SUBITO
    setTimeout(async () => {
      await source.commit();
      setTimeout(() => {
        afterEditRef.current();
      }, 10);
    }, 1);
  };

  const canOpenSelector = statoObj?.transitions
    ? size(statoObj.transitions) > 0
    : true;

  return (
    <LabelledOutline label={label} variant={variant}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            paddingBottom: 10,
            flex: 1,
            color: color,
            fontWeight: "bold",
          }}
        >
          {statoObj?.label || stato}
        </div>
        {!disabled && (
          <div style={{ marginTop: -5 }}>
            {/* <Button aria-describedby={id} variant="outlined" color="primary"  size="small" onClick={handleClick}>
            Cambia stato
          </Button> */}
            {canOpenSelector && (
              <IconButton aria-describedby={id} onClick={handleClick}>
                <ArrowDropDownIcon />
              </IconButton>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Container style={{ paddingTop: 20, paddingBottom: 30 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Scegli il nuovo stato:
                </Typography>
                {stati.map((s) => {
                  if (s.value === stato) {
                    return null;
                  }

                  let transitionInfo = {
                    askReason: true,
                  };
                  if (statoObj?.transitions) {
                    transitionInfo = statoObj?.transitions[s.value];
                    if (!transitionInfo) {
                      return null;
                    }
                  }

                  const selected = selectedState?.value === s.value;
                  const reason = (selected && selectedState.reason) || "";

                  return (
                    <div
                      key={s.value}
                      style={{ display: "flex", marginTop: 5 }}
                    >
                      <div
                        style={{
                          color: s.color,
                          padding: 5,
                          marginBottom: 4,
                        }}
                      >
                        {s.label}
                      </div>
                      <div style={{ flex: 1 }}></div>
                      <div style={{ marginRight: 4 }}>
                        <IconButton
                          onClick={() => {
                            setSelectedState({ value: s.value, reason });
                          }}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          marginTop: -12,
                          visibility:
                            selected && transitionInfo.askReason
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <TextField
                          label="Motivazione"
                          value={reason}
                          onChange={(e) => {
                            setSelectedState({
                              value: s.value,
                              reason: e.target.value,
                            });
                          }}
                          size="small"
                          style={{ margin: 0 }}
                          disabled={!transitionInfo.askReason}
                        />
                      </div>
                      <div
                        style={{ visibility: selected ? "visible" : "hidden" }}
                      >
                        <Button
                          aria-describedby={id}
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={save}
                          startIcon={<SaveIcon />}
                          disabled={
                            !!(selected && transitionInfo.askReason && !reason)
                          }
                        >
                          Cambia stato
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </Container>
            </Popover>
          </div>
        )}
      </div>
    </LabelledOutline>
  );
}
