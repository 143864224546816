import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";
import { editPagamento as editPagamentoAction } from "app/actions";
import { useApi } from "app/hooks/useApi";
import { useToast } from "app/hooks/useToast";
import AbstractTabellaFatture from "../Fatture/AbstractTabellaFatture";
import { aggregateData } from "../Fatture/DialogCreaFatture";
import { useCheckPermission } from "../../hooks/useCheckPermission";

export default function DialogCreaFatture(props) {
  const { dialog, pagamento, onDone } = props;
  const hasPermission = useCheckPermission();
  const showToast = useToast();

  const [aggregatedData, setAggregatedData] = useState([]);

  const editPagamentoApi = useApi(editPagamentoAction, {
    onError: () => {
      showToast("Errore durante la modifica del pagamento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const salvaPagamento = async () => {
    const p = aggregatedData[0].pagamenti[0];
    // const { id, importoEffettivo, stato } = p;
    // pagamento.changeValue("importoEffettivo", importoEffettivo);
    // pagamento.changeValue("stato", stato);
    const toSubmit = p;
    await editPagamentoApi.callApi(p.id, toSubmit);
    dialog.close();
    onDone();
  };

  useEffect(() => {
    let newAggrData = [];
    if (dialog.isOpen && pagamento) {
      const aggr = aggregateData([pagamento]);
      newAggrData = Object.values(aggr);
    }
    setAggregatedData(newAggrData);
  }, [dialog.isOpen, pagamento]);

  const changeImportoEffettivo = (index, indexP, newValue) => {
    const newAggrData = [...aggregatedData];
    let atLeastOneSelected = false;
    let importoMaturato = 0;
    let importoEffettivo = 0;
    newAggrData[index] = {
      ...newAggrData[index],
      pagamenti: newAggrData[index].pagamenti.map((p, i) => {
        if (i === indexP) {
          p = {
            ...p,
            importoEffettivo: newValue,
            stato:
              p.importoMaturato === newValue ? "maturato" : "in-approvazione",
          };
        }
        if (p.__selected) {
          atLeastOneSelected = true;
          importoMaturato += p.importoMaturato;
          importoEffettivo += p.importoEffettivo;
        }
        return p;
      }),
    };
    newAggrData[index].__selected = atLeastOneSelected;
    newAggrData[index].importoMaturato = importoMaturato;
    newAggrData[index].importoEffettivo = importoEffettivo;
    setAggregatedData(newAggrData);
  };

  const onPagamentoNoteChanged = (index, indexP, newValue) => {
    const newAggrData = [...aggregatedData];
    newAggrData[index] = {
      ...newAggrData[index],
      pagamenti: newAggrData[index].pagamenti.map((p, i) => {
        if (i === indexP) {
          p = {
            ...p,
            note: newValue,
          };
        }
        return p;
      }),
    };
    setAggregatedData(newAggrData);
  };

  const canEnterStandBy =
    pagamento?.stato === "maturato" || pagamento?.stato === "approvato";
  const canExistStandBy =
    pagamento?.stato === "maturato-standby" ||
    pagamento?.stato === "approvato-standby";

  async function enterStandBy() {
    const p = aggregatedData[0].pagamenti[0];
    await editPagamentoApi.callApi(p.id, {
      stato:
        pagamento.stato === "maturato"
          ? "maturato-standby"
          : "approvato-standby",
    });
    onDone();
    dialog.close();
  }

  async function exitStandBy() {
    const p = aggregatedData[0].pagamenti[0];
    await editPagamentoApi.callApi(p.id, {
      stato: pagamento.stato === "maturato-standby" ? "maturato" : "approvato",
    });
    onDone();
    dialog.close();
  }

  async function approvaPagamento() {
    const p = aggregatedData[0].pagamenti[0];
    await editPagamentoApi.callApi(p.id, {
      stato: "approvato",
    });
    onDone();
    dialog.close();
  }

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      fullScreen={false}
      fullWidth={true}
      maxWidth="lg"
      open={dialog.isOpen}
      scroll="paper"
    >
      <DialogTitle>Modifica pagamento</DialogTitle>

      <DialogContent>
        <AbstractTabellaFatture
          fatture={aggregatedData}
          canEditImportoEffettivo={pagamento && !pagamento.idFattura}
          onImportoEffettivoChange={changeImportoEffettivo}
          showImportiFattura={false}
          showNoteFattura={false}
          onPagamentoNoteChanged={onPagamentoNoteChanged}
        />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={() => {
            dialog.close();
          }}
          color="secondary"
        >
          Annulla
        </ColoredButton>

        {canEnterStandBy && (
          <ColoredButton onClick={enterStandBy} color="secondary">
            Metti in Stand-by
          </ColoredButton>
        )}

        {canExistStandBy && (
          <ColoredButton onClick={exitStandBy} color="secondary">
            Riprendi
          </ColoredButton>
        )}

        {pagamento?.stato === "in-approvazione" &&
          hasPermission("approva_pagamenti") && (
            <ColoredButton onClick={approvaPagamento} color="secondary">
              Approva
            </ColoredButton>
          )}

        <ColoredButton
          disabled={editPagamentoApi.loading}
          onClick={salvaPagamento}
          color="primary"
        >
          Salva pagamento
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
