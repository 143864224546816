import React from "react";
import { Container, Typography, Card, CardContent } from "@material-ui/core";
import NewsEditor from "app/components/ImpostazioniNews/NewsEditor";

export default function ImpostazioniNews(props) {
  return (
    <Container>
      <Typography component="h2" gutterBottom={true} variant="h6">
        Impostazioni news
      </Typography>

      <Container disableGutters={true} fixed={false} maxWidth={false}>
        <Card
          style={{
            marginBottom: 16,
          }}
        >
          <CardContent>
            <div>
              <NewsEditor />
            </div>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
}
