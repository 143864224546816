import React from "react";
import { useTable, UseTableParams } from "app/hooks/useTable";
import { DataTableContext } from "app/contexts/DataTableContext";
import { DataTableContainer } from "./DataTableContainer";
import { DataTableToolbar } from "./DataTableToolbar";
import { DataTableContent } from "./DataTableContent";

type DataTableProps = {
  topToolbar?: React.ReactNode;
  bottomToolbar?: React.ReactNode;
  tableClassName?: string | null | undefined;
} & UseTableParams;

const globalMessages = {
  noRows: "Nessuna riga",
  textSearch: "Filtra",
  rowsPerPage: "Righe per pagina",
  displayedRowsSeparator: "di",
  showOnlyEmptyCells: "Mostra solo celle vuote",
  hideEmptyCells: "Nascondi celle vuote",
};

export const DataTable = ({
  topToolbar = null,
  bottomToolbar = null,
  tableClassName = null,
  messages,
  ...params
}: DataTableProps) => {
  const { context } = useTable({
    ...params,
    messages: {
      ...globalMessages,
      ...messages,
    },
  });

  if (topToolbar) {
    topToolbar = <DataTableToolbar>{topToolbar}</DataTableToolbar>;
  }

  if (bottomToolbar) {
    bottomToolbar = <DataTableToolbar>{bottomToolbar}</DataTableToolbar>;
  }

  return (
    <DataTableContext.Provider value={context}>
      <DataTableContainer>
        {topToolbar}
        <DataTableContent tableClassName={tableClassName} />
        {bottomToolbar}
      </DataTableContainer>
    </DataTableContext.Provider>
  );
};
