import React from "react";
import LabelledOutline from "app/components/common/LabelledOutline";
import { Tooltip, IconButton } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export default function FileBoxSmallReadonly(props) {
  const fileObj = props.fileObj;

  const hasFile = fileObj && fileObj.versioneCorrente;

  return (
    <div style={props.style}>
      <LabelledOutline label={props.title} style={props.style}>
        <div
          style={{
            paddingBottom: 8,
            flex: 1,
            display: "flex",
          }}
        >
          {hasFile ? (
            <a
              style={{
                display: "flex",
                flex: 1,
                color: "inherit",
              }}
              href={"/api/files/" + fileObj.id}
              download={fileObj.versioneCorrente.nome}
            >
              <Tooltip title="Scarica file">
                <IconButton
                  style={{
                    marginRight: 6,
                  }}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>

              <div
                style={{
                  flex: 1,
                  alignSelf: "center",
                  wordBreak: "break-all",
                }}
              >
                {fileObj.versioneCorrente.nome}
              </div>
            </a>
          ) : (
            "Nessun file"
          )}{" "}
        </div>
      </LabelledOutline>
    </div>
  );
}
