import React from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getMyUtente as getMyUtenteAction,
  editMyUtente as editMyUtenteAction,
} from "app/actions";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  CardActions,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ColoredButton } from "app/elements/ColoredButton";

export default function GestioneUtente(props) {
  const showToast = useToast();

  const utente = useDataSource({
    initialData: {},
    loadAction: getMyUtenteAction,
    autoLoad: true,
    onLoadError: (response) => {
      showToast("Errore durante il caricamento", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editMyUtenteAction,
    onEditSuccess: (response) => {
      showToast("Dati utente aggiornati", { color: "success" });
    },
    onEditError: (response) => {
      showToast("Errore durante il salvataggio", { color: "error" });
    },
  });

  return (
    <Container disableGutters={true} fixed={false} maxWidth="xl">
      <Card variant="outlined" raised={true}>
        <CardHeader title="Aggiorna dati utente" />

        <CardContent>
          <Grid container={true} spacing={2}>
            <Grid item={true} md={4} sm={12}>
              <TextField
                disabled={utente.loading || utente.editing}
                error={utente.getValidationErrors("nome").length > 0}
                fullWidth={true}
                helperText={utente.getValidationErrors("nome")[0]}
                label="Nome"
                onBlur={(event) => {
                  utente.runValidation("nome");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  utente.changeValue(
                    "nome",

                    value
                  );
                }}
                type="text"
                value={utente.getValue("nome", "")}
                variant="outlined"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>

            <Grid item={true} md={4} sm={12}>
              <TextField
                disabled={utente.loading || utente.editing}
                error={utente.getValidationErrors("cognome").length > 0}
                fullWidth={true}
                helperText={utente.getValidationErrors("cognome")[0]}
                label="Cognome"
                onBlur={(event) => {
                  utente.runValidation("cognome");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  utente.changeValue(
                    "cognome",

                    value
                  );
                }}
                type="text"
                value={utente.getValue("cognome", "")}
                variant="outlined"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>

            <Grid item={true} md={4} sm={12}>
              <TextField
                disabled={utente.loading || utente.editing}
                error={utente.getValidationErrors("email").length > 0}
                fullWidth={true}
                helperText={utente.getValidationErrors("email")[0]}
                label="Email"
                onBlur={(event) => {
                  utente.runValidation("email");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  utente.changeValue(
                    "email",

                    value
                  );
                }}
                type="email"
                value={utente.getValue("email", "")}
                variant="outlined"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>

            <Grid item={true} md={4} sm={12}>
              <TextField
                disabled={utente.loading || utente.editing}
                error={utente.getValidationErrors("currentPassword").length > 0}
                fullWidth={true}
                helperText={utente.getValidationErrors("currentPassword")[0]}
                label="Password attuale"
                onBlur={(event) => {
                  utente.runValidation("currentPassword");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  utente.changeValue(
                    "currentPassword",

                    value
                  );
                }}
                type="password"
                value={utente.getValue("currentPassword", "")}
                variant="outlined"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>

            <Grid item={true} md={4} sm={12}>
              <TextField
                disabled={utente.loading || utente.editing}
                error={utente.getValidationErrors("newPassword").length > 0}
                fullWidth={true}
                helperText={utente.getValidationErrors("newPassword")[0]}
                label="Nuova password"
                onBlur={(event) => {
                  utente.runValidation("newPassword");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  utente.changeValue(
                    "newPassword",

                    value
                  );
                }}
                type="password"
                value={utente.getValue("newPassword", "")}
                variant="outlined"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>

            <Grid item={true} md={4} sm={12}>
              <TextField
                disabled={utente.loading || utente.editing}
                error={
                  utente.getValidationErrors("confirmNewPassword").length > 0
                }
                fullWidth={true}
                helperText={utente.getValidationErrors("confirmNewPassword")[0]}
                label="Conferma nuova password"
                onBlur={(event) => {
                  utente.runValidation("confirmNewPassword");
                }}
                onChange={(event) => {
                  const value = event.target.value;

                  utente.changeValue(
                    "confirmNewPassword",

                    value
                  );
                }}
                type="password"
                value={utente.getValue("confirmNewPassword", "")}
                variant="outlined"
                InputProps={{
                  readOnly: false,
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        {utente.editError ? (
          <Alert
            style={{
              marginTop: "16px",
            }}
            color="error"
            iconMapping={{}}
            onClose={null}
            role="alert"
            severity="error"
          >
            {utente.editError.message}{" "}
          </Alert>
        ) : null}

        <CardActions
          style={{
            justifyContent: "flex-end",
          }}
        >
          <ColoredButton
            disabled={utente.loading || utente.editing}
            onClick={(event) => {
              utente.commit();
            }}
            color="primary"
            size="medium"
            variant="outlined"
          >
            Salva
          </ColoredButton>
        </CardActions>
      </Card>
    </Container>
  );
}
