import React from "react";
import DocumentoTipo from "app/constants/DocumentoTipo";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import { AsyncSelect } from "app/elements/AsyncSelect";
import FileBox from "app/components/common/FileBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { ColoredButton } from "app/elements/ColoredButton";

export default function AccordionDocumentiRichiesti(props) {
  const formatDocumenti = props.formatDocumenti;

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Documenti richiesti
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={formatDocumenti}
          columns={[
            {
              path: ["nome"],
              header: "Nome documento",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    autoComplete=""
                    disabled={props.readOnly}
                    error={rowSource.getValidationErrors("nome").length > 0}
                    fullWidth={true}
                    helperText={rowSource.getValidationErrors("nome")[0]}
                    onBlur={(event) => {
                      rowSource.runValidation("nome");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue(
                        "nome",

                        value
                      );
                    }}
                    size="small"
                    type="text"
                    value={rowSource.getValue("nome", "")}
                    variant="outlined"
                    InputProps={{
                      readOnly: props.readOnly,
                    }}
                  />
                );
              },
            },
            {
              path: ["tipo"],
              header: "Tipo di documento",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControl
                    disabled={props.readOnly}
                    error={rowSource.getValidationErrors("tipo").length > 0}
                    fullWidth={true}
                    margin="dense"
                    size="small"
                  >
                    <InputLabel
                      error={rowSource.getValidationErrors("tipo").length > 0}
                      variant="outlined"
                    >
                      {}
                    </InputLabel>
                    <AsyncSelect
                      onChange={(event) => {
                        const value = event.target.value;

                        rowSource.changeValue(
                          "tipo",

                          value || null
                        );

                        setTimeout(
                          () => {
                            rowSource.runValidation("tipo");
                          },

                          0
                        );
                      }}
                      onClose={(event) => {
                        rowSource.runValidation("tipo");
                      }}
                      value={rowSource.getValue("tipo") || ""}
                      variant="outlined"
                      sourceVariable="rowSource"
                      sourceKey="tipo"
                      disabled={props.readOnly}
                    >
                      {DocumentoTipo.map((option, index) => {
                        return (
                          <MenuItem
                            key={option.value}
                            dense={false}
                            disableGutters={false}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </AsyncSelect>
                    {rowSource.getValidationErrors("tipo")[0] ? (
                      <FormHelperText>
                        {rowSource.getValidationErrors("tipo")[0]}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                );
              },
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
            },
            {
              path: ["template"],
              header: "Template",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FileBox
                    source={rowSource}
                    sourceKey="template"
                    readOnly={props.readOnly}
                  />
                );
              },
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
            },
            {
              path: ["opzionale"],
              header: "Opzionale",
              width: 90,
              renderCell: (value, row, rowIndex, rowSource) => (
                <Checkbox
                  readOnly={props.readOnly}
                  disabled={props.readOnly}
                  checked={rowSource.getValue("opzionale", false)}
                  onChange={(event) => {
                    const value = event.target.checked;
                    rowSource.changeValue("opzionale", value);
                  }}
                />
              ),
            },
            !props.readOnly
              ? {
                  path: ["id"],
                  header: "Azioni",
                  width: 64,
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return (
                      <div>
                        <IconButton
                          onClick={(event) => {
                            rowSource.delete();
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    );
                  },
                  disableColumnFilter: true,
                  disableGlobalFilter: true,
                  sortable: true,
                  type: "text",
                }
              : null,
          ]}
          topToolbar={null}
          bottomToolbar={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75em",
              }}
            >
              {!props.readOnly ? (
                <ColoredButton
                  onClick={(event) => {
                    formatDocumenti.create();
                  }}
                  variant="outlined"
                >
                  Aggiungi documento
                </ColoredButton>
              ) : null}{" "}
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          expandRowsOnClick={false}
          isRowExpandable={null}
          renderExpandedRow={null}
          messages={{
            noRows: "Nessun documento",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
