import React, { Component } from "react";
import clsx from "clsx";
import Editor from "@draft-js-plugins/editor";
import createToolbarPlugin, {
  Separator,
} from "@draft-js-plugins/static-toolbar";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
} from "@draft-js-plugins/buttons";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "./EditorWithToolbar.scss";

export default class EditorWithToolbar extends Component {
  constructor(props) {
    super(props);

    const linkifyPlugin = createLinkifyPlugin({
      target: "_blank",
      component(props) {
        // eslint-disable-next-line no-alert
        return (
          <a
            {...props}
            onClick={() => {
              window.open(props.href, "_blank", "noopener");
              return false;
            }}
          />
        );
      },
    });

    this.toolbarStructure = [
      BoldButton,
      ItalicButton,
      UnderlineButton,

      Separator,
      CodeButton,

      Separator,
      UnorderedListButton,
      OrderedListButton,
    ];

    const toolbarPlugin = createToolbarPlugin();
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin, linkifyPlugin];
    this.Toolbar = Toolbar;
    this.plugins = plugins;
  }

  focus = () => {
    this.editor.focus();
  };

  _getEditorState = () => {
    return this.props.editorState;
  };

  render() {
    const { className, editorState, ...otherProps } = this.props;
    const { readOnly } = otherProps;
    const Toolbar = this.Toolbar;
    const plugins = this.plugins;
    return (
      <div className={clsx("editor-with-toolbar", className)}>
        <div className="editor" onClick={this.focus}>
          {!readOnly && (
            <>
              <Toolbar>
                {(externalProps) => (
                  <div>
                    {this.toolbarStructure.map((B, key) => {
                      return (
                        <B
                          key={key}
                          {...externalProps}
                          getEditorState={this._getEditorState}
                          __editorStateJustToTriggerRefresh={editorState}
                        />
                      );
                    })}
                  </div>
                )}
              </Toolbar>
              <div style={{ display: "block", height: 15 }} />
            </>
          )}
          <Editor
            ref={(element) => {
              this.editor = element;
            }}
            plugins={plugins}
            editorState={editorState}
            {...otherProps}
          />
        </div>
      </div>
    );
  }
}
