import { difference } from "lodash";

import { permissionsByRole } from "./permissionsByRole";

export function userHasAllPermissions(user, permissions) {
  const userPermissions = getUserRoles(user).flatMap(
    ([role]) => permissionsByRole[role]
  );

  return difference(permissions, userPermissions).length === 0;
}

export function getUserRoles(user) {
  const userRoles = user.roles || {};
  return Object.entries(userRoles).filter(([role, hasRole]) => hasRole);
}

export function userHasRole(user, role) {
  const userRoles = user.roles || {};
  return userRoles[role] || false;
}
