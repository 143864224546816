import React, { useMemo } from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  Container,
  Card,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import BeneLabel from "app/components/common/BeneLabel";
import StatoAttivita from "app/components/common/StatoAttivita";
import numeral from "numeral";
import NumberInput from "app/components/common/NumberInput";
import UserPicker from "app/components/common/UserPicker";
import DateInput from "app/components/common/DateInput";
import moment from "moment";
import AttivitaScadenzaGiorniDaInfo from "./AttivitaScadenzaGiorniDaInfo";
import AccordionDocumenti from "app/components/Attivita/AccordionDocumenti";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import MilestoneDiPagamentoSelect from "../common/MilestoneDiPagamentoSelect";

export default function AttivitaForm({ attivita, readOnly, readOnlyOrSaving }) {
  const documenti = useDataSource({
    parent: attivita,
    pathInParent: ["documenti"],
    initialData: [],
  });

  const variabili = useDataSource({
    parent: attivita,
    pathInParent: ["variabili"],
    initialData: [],
  });

  const milestonePagamento = attivita.getValue([
    "mlMilestonePagamento",
    "milestonePagamento",
  ]);

  const statoIniziale = useMemo(
    () => attivita.getValue("stato"),
    [attivita.getValue("id")]
  );
  const isCompletata = statoIniziale === "completato";

  const initialDataPrevista = useMemo(
    () => attivita.getValue("dataPrevista"),
    []
  );
  const dataPrevistaChanged =
    attivita.getValue("dataPrevista") !== initialDataPrevista;

  return (
    <Container disableGutters>
      <Card variant="outlined" style={{ marginBottom: 8 }}>
        <CardContent>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12}>
              <DizionarioSoftInput
                className=""
                style={{}}
                size="small"
                variant="outlined"
                readOnly={
                  readOnlyOrSaving || !!attivita.getValue("idFormatAttivita")
                }
                label="Nome"
                autoFocus
                source={attivita}
                sourceKey="nome"
                fullWidth
                dizionarioSoft="nomeAttivita"
              />
            </Grid>

            {attivita.getValue("bene") ? (
              <Grid item xs={12}>
                <BeneLabel
                  bene={attivita.getValue("bene")}
                  showPreText
                  showIcon
                />
              </Grid>
            ) : null}

            <Grid item md={3} sm={6} xs={12}>
              Stato:
              <StatoAttivita
                attivitaSource={attivita}
                readOnly={readOnlyOrSaving}
                showText
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              {`Ore previste: ${
                attivita.getValue("orePreviste", "")
                  ? numeral(attivita.getValue("orePreviste", "")).format(
                      "0,0.#"
                    )
                  : "-"
              }`}

              <NumberInput
                style={{
                  marginTop: 8,
                }}
                autoComplete=""
                fullWidth
                label="Ore effettive"
                size="small"
                variant="outlined"
                source={attivita}
                sourceKey="oreEffettive"
                readOnly={readOnlyOrSaving}
                disabled={readOnlyOrSaving}
                min={0}
              />
            </Grid>

            <Grid
              style={{
                marginTop: 8,
              }}
              item
              md={6}
              xs={12}
            >
              <UserPicker
                source={attivita}
                sourceKey="idOperatore"
                label="Operatore"
                readOnly={readOnlyOrSaving}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={3} sm={6} xs={12}>
              <DateInput
                className=""
                label="Data prevista"
                inputVariant="outlined"
                fullWidth
                size="small"
                variant="outlined"
                disabled={readOnlyOrSaving}
                source={attivita}
                sourceKey="dataPrevista"
                calcolataAutomaticamente={
                  !attivita.getValue("dataPrevistaInseritaManualmente") &&
                  attivita.getValue("dataPrevista") &&
                  !dataPrevistaChanged
                }
              />
            </Grid>

            <Grid item lg={3} sm={6} xs={12}>
              <DateInput
                className=""
                label="Data effettiva"
                inputVariant="outlined"
                fullWidth
                size="small"
                variant="outlined"
                maxDate={moment().endOf("day")}
                disabled={readOnlyOrSaving}
                source={attivita}
                sourceKey="dataEffettiva"
              />
            </Grid>

            <Grid item lg={3} sm={6} xs={12}>
              <DateInput
                className=""
                label="Scadenza di progetto"
                inputVariant="outlined"
                fullWidth
                size="small"
                variant="outlined"
                disabled={readOnlyOrSaving}
                source={attivita}
                sourceKey="dataScadenzaProgetto"
              />

              <AttivitaScadenzaGiorniDaInfo
                attivita={attivita}
                dateSourceKey="dataScadenzaProgetto"
                dateDeltaSourceKey="dataScadenzaProgettoDelta"
                dateTypeSourceKey="dataScadenzaProgettoType"
                dateIdFormatAttivita="dsProgettoIdFormatAttivita"
              />
            </Grid>

            <Grid item lg={3} sm={6} xs={12}>
              <DateInput
                className=""
                label="Scadenza perentoria"
                inputVariant="outlined"
                fullWidth
                size="small"
                variant="outlined"
                disabled
                source={attivita}
                sourceKey="dataScadenzaPerentoria"
              />

              <AttivitaScadenzaGiorniDaInfo
                attivita={attivita}
                dateSourceKey="dataScadenzaPerentoria"
                dateDeltaSourceKey="dataScadenzaPerentoriaDelta"
                dateTypeSourceKey="dataScadenzaPerentoriaType"
                dateIdFormatAttivita="dsPerentoriaIdFormatAttivita"
              />
            </Grid>

            {milestonePagamento && (
              <>
                <Grid item md={6} sm={12}>
                  <MilestoneDiPagamentoSelect
                    readOnly
                    idLinea={attivita.getValue([
                      "formatAttivita",
                      "formatProcedura",
                      "idLinea",
                    ])}
                    source={attivita}
                    sourceKey={["mlMilestonePagamento", "idMilestonePagamento"]}
                    milestonePagamenti={{
                      data: [milestonePagamento],
                    }}
                  />
                </Grid>
                {milestonePagamento.hasMarcheDaBollo && (
                  <Grid item md={6} sm={12}>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 4 }}
                    >
                      <NumberInput
                        label="# Marche da bollo"
                        size="small"
                        fullWidth
                        variant="outlined"
                        source={attivita}
                        sourceKey={["pagamento", "numeroMarcheDaBollo"]}
                        readOnly={readOnlyOrSaving}
                        disabled={readOnlyOrSaving}
                        min={0}
                      />
                      <NumberInput
                        label="Valore marca da bollo"
                        source={attivita}
                        sourceKey={["pagamento", "valoreMarcaDaBollo"]}
                        readOnly={readOnlyOrSaving}
                        disabled={readOnlyOrSaving}
                        fullWidth
                        variant="outlined"
                        currency
                      />
                    </div>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </CardContent>
      </Card>

      <AccordionDocumenti
        documenti={documenti}
        parentKey="idAttivita"
        parentId={attivita.getValue("id")}
        readOnly={readOnly || isCompletata}
        showTemplate
      />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Importi da compilare
        </AccordionSummary>

        <AccordionDetails>
          <DataTable
            source={variabili}
            columns={[
              {
                path: ["variabile", "agevolazione", "nome", "nome"],
                header: "Agevolazione",
                disableColumnFilter: false,
                disableGlobalFilter: false,
                sortable: true,
                type: "text",
              },
              {
                path: ["variabile", "nome", "nome"],
                header: "Variabile",
                disableColumnFilter: false,
                disableGlobalFilter: false,
                sortable: true,
                type: "text",
              },
              {
                path: ["valoreInvestimento"],
                header: "Valore (investimento)",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <NumberInput
                      source={rowSource}
                      sourceKey="valoreInvestimento"
                      disabled={readOnlyOrSaving || isCompletata}
                      fullWidth
                      variant="outlined"
                      currency
                    />
                  );
                },
                disableColumnFilter: true,
                disableGlobalFilter: true,
                sortable: false,
                type: "number",
              },
              {
                path: ["valore"],
                header: "Valore (agevolazione)",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <NumberInput
                      source={rowSource}
                      sourceKey="valore"
                      disabled={readOnlyOrSaving || isCompletata}
                      fullWidth
                      variant="outlined"
                      currency
                    />
                  );
                },
                disableColumnFilter: true,
                disableGlobalFilter: true,
                sortable: false,
                type: "number",
              },
            ]}
            pageSize={1000000}
            size="small"
            messages={{
              noRows: "Nessuna variabile",
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
