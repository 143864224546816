import React, { useEffect, useState } from "react";
import { useCheckPermission } from "app/hooks/useCheckPermission";
import { useToast } from "app/hooks/useToast";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDialog } from "app/hooks/useDialog";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  getContatto as getContattoAction,
  editContatto as editContattoAction,
  deleteContatto as deleteContattoAction,
} from "app/actions";
import {
  Container,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import BackButton from "app/components/common/BackButton";
import StatoContattoBadge from "app/components/common/StatoContattoBadge";
import { ColoredButton } from "app/elements/ColoredButton";
import LaunchIcon from "@material-ui/icons/Launch";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FormContatto from "app/components/Contatti/FormContatto";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import ClientePicker from "app/components/common/ClientePicker";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import AddIcon from "@material-ui/icons/Add";
import DialogDeleteContatto from "app/components/Contatti/DialogDeleteContatto";
import ContattoInterazioni from "./ContattoInterazioni";
import { useEffectOnSearchValue } from "../../utils/extractURLSearchValue";

export default function Contatto(props) {
  const hasPermission = useCheckPermission();

  const showToast = useToast();

  const history = useHistory();

  const { id } = props.useIdFromProps ? props : useParams();

  const [editing, setEditing] = useState(false);

  const readOnly = !editing;

  const deleteDialog = useDialog();

  const contatto = useDataSource({
    initialData: {},
    itemId: id,
    loadAction: getContattoAction,
    onLoadError: () => {
      showToast("Errore durante il caricamento del contatto", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editContattoAction,
    onEditSuccess: (response) => {
      setEditing(false);

      showToast("Contatto modificato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onEditSuccess?.(response.data);
    },
    onEditError: () => {
      showToast("Errore durante il salvataggio", { color: "error" });
    },
    deleteAction: deleteContattoAction,
    onDeleteSuccess: () => {
      showToast("Contatto eliminato", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/rubrica");
    },
    onDeleteError: (response) => {
      showToast(
        response?.message
          ? `Errore: ${response.message}`
          : "Errore durante l'eliminazione del contatto",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  const contattiAziendali = useDataSource({
    parent: contatto,
    pathInParent: ["contattiAziendali"],
    initialData: [],
  });

  useEffectOnSearchValue(
    "edit",
    () => hasPermission("modifica_contatto") && setEditing(true)
  );
  useEffect(() => {
    if (props.edit) {
      if (hasPermission("modifica_contatto")) {
        setEditing(true);
      }
    }
  }, [props.edit]);

  const __hasPermission = useCheckPermission();
  if (!__hasPermission(["visualizza_contatto"])) {
    return <div>Non hai i permessi per visualizzare queste informazioni</div>;
  }

  return (
    <div>
      {contatto.loading ? (
        <div>Caricamento...</div>
      ) : (
        <div>
          {contatto.loadError ? (
            <div>{contatto.loadError.message || "Errore nel caricamento"} </div>
          ) : (
            <Container disableGutters fixed={false} maxWidth={false}>
              <h2
                className="page-title-header page-title-header-sticky"
                style={{
                  marginTop: -20,
                  marginBottom: 0,
                }}
              >
                <BackButton defaultBackLink="" />
                Contatto
              </h2>

              <div
                className="page-title-header-sticky-buttons-row "
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StatoContattoBadge stato={contatto.getValue("stato")} />
                <h1
                  style={{
                    display: "inline",
                    flexGrow: 1,
                  }}
                >
                  {contatto.getValue("nome")} {contatto.getValue("cognome")}{" "}
                </h1>
                {props.isSideModal && readOnly && (
                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<LaunchIcon />}
                    variant="outlined"
                    to={`/contatto/${id}?edit=true`}
                    component={Link}
                  >
                    Apri
                  </ColoredButton>
                )}
                {editing ? (
                  <>
                    <ColoredButton
                      style={{
                        marginRight: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        contatto.cancel();

                        setEditing(false);
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<CancelIcon />}
                      variant="outlined"
                    >
                      Annulla modifiche
                    </ColoredButton>

                    <ColoredButton
                      style={{
                        marginRight: 8,
                      }}
                      disabled={false}
                      onClick={() => {
                        contatto.commit();
                      }}
                      color="secondary"
                      endIcon={null}
                      fullWidth={false}
                      size="small"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                    >
                      Salva
                    </ColoredButton>
                  </>
                ) : hasPermission("modifica_contatto") ? (
                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      setEditing(true);
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<EditIcon />}
                    variant="outlined"
                  >
                    Modifica
                  </ColoredButton>
                ) : null}{" "}
                {!props.isSideModal && hasPermission("elimina_contatto") && (
                  <ColoredButton
                    disabled={false}
                    onClick={() => {
                      deleteDialog.open();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                  >
                    Elimina
                  </ColoredButton>
                )}
              </div>

              <Card
                style={{
                  marginBottom: 16,
                }}
              >
                <CardContent>
                  <FormContatto
                    readOnly={!editing}
                    source={contatto}
                  ></FormContatto>
                </CardContent>
              </Card>

              <AziendeAccordion
                contattiAziendali={contattiAziendali}
                hasPermission={hasPermission}
                readOnly={readOnly}
                editing={editing}
              />

              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Contatti/Interazioni
                </AccordionSummary>

                <AccordionDetails>
                  <ContattoInterazioni contattoId={id} />
                </AccordionDetails>
              </Accordion>

              <DialogDeleteContatto dialog={deleteDialog} source={contatto} />
            </Container>
          )}{" "}
        </div>
      )}{" "}
    </div>
  );
}

function AziendeAccordion({
  contattiAziendali,
  hasPermission,
  readOnly,
  editing,
}) {
  const variant = readOnly ? "standard" : "outlined";
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Aziende
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={contattiAziendali}
          columns={[
            {
              path: ["azienda", "ragioneSociale"],
              header: "Azienda",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <ClientePicker
                    variant={variant}
                    readOnly={readOnly}
                    source={rowSource}
                    sourceKey="idAzienda"
                  />
                );
              },
            },
            {
              path: ["ruolo"],
              header: "Ruolo",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <DizionarioSoftInput
                    className=""
                    style={{}}
                    variant={variant}
                    readOnly={readOnly}
                    source={rowSource}
                    sourceKey="ruolo"
                    fullWidth={true}
                    dizionarioSoft="ruoloReferente"
                  />
                );
              },
            },
            {
              path: ["email"],
              header: "Email",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    disabled={rowSource.loading || rowSource.editing}
                    error={rowSource.getValidationErrors("email").length > 0}
                    fullWidth={true}
                    helperText={rowSource.getValidationErrors("email")[0]}
                    onBlur={() => {
                      rowSource.runValidation("email");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue(
                        "email",

                        value
                      );
                    }}
                    type="text"
                    value={rowSource.getValue("email", "")}
                    variant={variant}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                );
              },
            },
            {
              path: ["cellulare"],
              header: "Cellulare",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    disabled={rowSource.loading || rowSource.editing}
                    error={
                      rowSource.getValidationErrors("cellulare").length > 0
                    }
                    fullWidth={true}
                    helperText={rowSource.getValidationErrors("cellulare")[0]}
                    onBlur={() => {
                      rowSource.runValidation("cellulare");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue(
                        "cellulare",

                        value
                      );
                    }}
                    type="text"
                    value={rowSource.getValue("cellulare", "")}
                    variant={variant}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                );
              },
            },
            {
              path: ["telefono"],
              header: "Telefono",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    disabled={rowSource.loading || rowSource.editing}
                    error={rowSource.getValidationErrors("telefono").length > 0}
                    fullWidth={true}
                    helperText={rowSource.getValidationErrors("telefono")[0]}
                    onBlur={() => {
                      rowSource.runValidation("telefono");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue(
                        "telefono",

                        value
                      );
                    }}
                    type="text"
                    value={rowSource.getValue("telefono", "")}
                    variant={variant}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                );
              },
            },
            {
              path: ["isRiceveInformative"],
              header: "Riceve informative",
              disableColumnFilter: true,
              disableGlobalFilter: true,
              width: 100,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rowSource.getValue(
                          "isRiceveInformative",
                          false
                        )}
                        disabled={rowSource.loading || rowSource.editing}
                        onChange={(event) => {
                          const value = event.target.checked;

                          rowSource.changeValue(
                            "isRiceveInformative",

                            value
                          );
                        }}
                        size="small"
                        value={rowSource.getValue("isRiceveInformative", "")}
                      />
                    }
                    disabled={readOnly}
                  />
                );
              },
            },
            {
              path: ["isConsensoGDPR"],
              header: "Consenso GDPR",
              disableColumnFilter: true,
              disableGlobalFilter: true,
              width: 100,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rowSource.getValue("isConsensoGDPR", false)}
                        disabled={rowSource.loading || rowSource.editing}
                        onChange={(event) => {
                          const value = event.target.checked;

                          rowSource.changeValue(
                            "isConsensoGDPR",

                            value
                          );
                        }}
                        size="small"
                        value={rowSource.getValue("isConsensoGDPR", "")}
                      />
                    }
                    disabled={readOnly}
                  />
                );
              },
            },
            {
              path: ["id"],
              header: "Azioni",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <div>
                    <IconButton
                      to={`/azienda/${rowSource.getValue("idAzienda")}`}
                      component={Link}
                    >
                      <LaunchIcon />
                    </IconButton>
                    {editing ? (
                      <IconButton
                        onClick={() => {
                          rowSource.delete();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : null}{" "}
                  </div>
                );
              },
              disableColumnFilter: true,
              disableGlobalFilter: true,
              width: 100,
              sortable: true,
              type: "text",
            },
          ]}
          topToolbar={null}
          bottomToolbar={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75em",
              }}
            >
              {editing ? (
                hasPermission("modifica_referenti") ? (
                  <ColoredButton
                    style={{
                      marginRight: 8,
                    }}
                    disabled={false}
                    onClick={() => {
                      contattiAziendali.create();
                    }}
                    color="secondary"
                    endIcon={null}
                    fullWidth={false}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                  >
                    Aggiungi azienda
                  </ColoredButton>
                ) : null
              ) : null}{" "}
            </div>
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          expandRowsOnClick={false}
          isRowExpandable={null}
          renderExpandedRow={null}
          messages={{
            noRows: "Nessuna azienda",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
