import { lighten } from "@material-ui/core";
import moment from "moment";

export type EventoAgenda = {
  id: number;
  createdAt: string;
  updatedAt: string;
  dataEffettiva: string | null;
  dataProgrammata: string | null;
  descrizione: string | null;
  isPromemoriaRicontatto?: boolean;
  idAzienda: number;
  idContatto: number;
  idMandato: number;
  idUtente: number;
  utente: any;
  azienda: any;
  contatto: any;
  mandato: any;
};

const doneColor = "#4caf50";
const scadutoColor = "#ff9800";
const normalColor = "#3890F5";
export const autoColor = "#e57373";

export function getColorAndLabel(event: EventoAgenda) {
  const done = !!event.dataEffettiva;
  const scaduto =
    !done &&
    (event.dataProgrammata || event.dataEffettiva) &&
    moment(event.dataProgrammata || event.dataEffettiva).isBefore(
      moment().startOf("day")
    );

  const isAuto = event.isPromemoriaRicontatto;

  let color: string, statusLabel: string;

  if (done) {
    color = doneColor;
    statusLabel = "Completato";
  } else if (isAuto) {
    color = autoColor;
    statusLabel = "Automatico";
  } else if (scaduto) {
    color = scadutoColor;
    statusLabel = "Scaduto";
  } else {
    color = normalColor;
    statusLabel = "Interazione";
  }

  const backgroundColor = lighten(color, 0.1);

  return {
    color,
    backgroundColor,
    statusLabel,
  };
}
