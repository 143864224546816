import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";
import AttivitaStato from "app/constants/AttivitaStato";

export default function StatoAttivita(props) {
  const stato = props.attivitaSource.getValue("stato");

  const color =
    AttivitaStato.find((_stato) => _stato.value === stato)?.color ?? null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          borderRadius: "50%",
          backgroundColor: color,
          margin: 8,
          minWidth: 25,
          minHeight: 25,
        }}
      />
      {!props.readOnly ? (
        <FormControl
          disabled={
            props.attivitaSource.loading || props.attivitaSource.editing
          }
          error={props.attivitaSource.getValidationErrors("stato").length > 0}
          fullWidth={true}
        >
          <InputLabel
            error={props.attivitaSource.getValidationErrors("stato").length > 0}
            variant="outlined"
          >
            Stato
          </InputLabel>
          <AsyncSelect
            label="Stato"
            onChange={(event) => {
              const value = event.target.value;

              props.attivitaSource.changeValue(
                "stato",

                value || null
              );

              setTimeout(
                () => {
                  props.attivitaSource.runValidation("stato");
                },

                0
              );
            }}
            onClose={(event) => {
              props.attivitaSource.runValidation("stato");
            }}
            value={props.attivitaSource.getValue("stato") || ""}
            variant="outlined"
            sourceVariable="props.attivitaSource"
            sourceKey="stato"
            disabled={
              props.attivitaSource.loading || props.attivitaSource.editing
            }
          >
            {AttivitaStato.map((option, index) => {
              return (
                <MenuItem
                  key={option.value}
                  dense={false}
                  disableGutters={false}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </AsyncSelect>
          {props.attivitaSource.getValidationErrors("stato")[0] ? (
            <FormHelperText>
              {props.attivitaSource.getValidationErrors("stato")[0]}
            </FormHelperText>
          ) : null}{" "}
        </FormControl>
      ) : props.showText ? (
        AttivitaStato.find((_stato) => _stato.value === stato)?.label ?? stato
      ) : null}{" "}
    </div>
  );
}
