import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Dialog, TextField, Typography } from "@material-ui/core";
import { NumberFormatCustom } from "../../elements/NumberFormatCustom";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "../../elements/ColoredButton";
import { useSubmitDataSource } from "../../hooks/DataSource/useSubmitDataSource";
import { estendiLinea as estendiLineaAction } from "../../actions";
import { useDialog } from "../../hooks/useDialog";
import { useToast } from "../../hooks/useToast";
import { useHistory } from "react-router-dom";

function EstendiLineaDialog({ isOpen, onClose, linea, estendiLinea }) {
  return (
    <Dialog maxWidth="md" open={isOpen}>
      <DialogTitle>Estendi linea</DialogTitle>

      <DialogContent>
        <Typography>
          {"Per quale anno vuoi estendere la linea '" +
            linea.getValue("nome") +
            "'?"}{" "}
        </Typography>

        <TextField
          style={{
            marginTop: 16,
          }}
          autoFocus={true}
          disabled={estendiLinea.loading || estendiLinea.editing}
          error={estendiLinea.getValidationErrors("anno").length > 0}
          fullWidth={true}
          helperText={estendiLinea.getValidationErrors("anno")[0]}
          label="Anno"
          onBlur={() => {
            estendiLinea.runValidation("anno");
          }}
          onChange={(event) => {
            const value = event.target.value;

            estendiLinea.changeValue("anno", value);
          }}
          value={estendiLinea.getValue("anno", "")}
          variant="outlined"
          InputProps={{
            readOnly: false,
            inputComponent: NumberFormatCustom,
          }}
          inputProps={{
            min: linea.getValue("anno"),
            thousandSeparator: false,
          }}
        />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          onClick={() => {
            onClose();
          }}
          color="secondary"
          variant="outlined"
        >
          Annulla
        </ColoredButton>

        <ColoredButton
          onClick={() => {
            estendiLinea.submit(linea.getValue("id"));
          }}
          color="primary"
          variant="outlined"
        >
          Estendi
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}

export function useEstendiLineaDialog(linea) {
  const showToast = useToast();
  const estendiDialog = useDialog();
  const history = useHistory();

  const estendiLinea = useSubmitDataSource({
    submitAction: estendiLineaAction,
    activeByDefault: true,
    onSubmitSuccess: (response) => {
      estendiDialog.close();

      showToast("Linea estesa", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      history.push("/linee/" + response.data.id);
      estendiLinea.cancel();
      estendiLinea.activate();
    },
    onSubmitError: (response) => {
      showToast(
        "Errore durante l'estensione della linea: " + response.message,
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  return {
    openEstendiLineaDialog: () => {
      estendiLinea.changeValue("anno", parseInt(linea.getValue("anno")) + 1);
      estendiDialog.open();
    },
    estendiLineaDialog: (
      <EstendiLineaDialog
        estendiLinea={estendiLinea}
        isOpen={estendiDialog.isOpen}
        onClose={estendiDialog.close}
        linea={linea}
      />
    ),
  };
}
