import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { AsyncSelect } from "app/elements/AsyncSelect";
import { Agevolazione } from "../../types/Agevolazione";

export type VariabileAgevolazionePickerProps = {
  variant?: "filled" | "outlined" | "standard";
  readOnly: boolean;
  agevolazioni?: Agevolazione[];
  source: any;
  idVariabileKey: string;
};

export default function VariabileAgevolazionePicker({
  variant,
  readOnly,
  source,
  idVariabileKey,
  ...props
}: VariabileAgevolazionePickerProps) {
  const agevolazioni = props.agevolazioni || [];
  const idAgevolazioneByIdVariabile = {};

  agevolazioni.forEach((agevolazione) => {
    agevolazione.variabili?.forEach((variabile) => {
      idAgevolazioneByIdVariabile[variabile.id] = agevolazione.id;
    });
  });

  return (
    <div
      className={readOnly ? "crea-readOnly" : ""}
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <AgevolazioneSelector
        source={source}
        idVariabileKey={idVariabileKey}
        agevolazioni={agevolazioni}
        variant={variant}
        readOnly={readOnly}
        idAgevolazioneByIdVariabile={idAgevolazioneByIdVariabile}
      />
      <ImportoSelector
        source={source}
        idVariabileKey={idVariabileKey}
        variant={variant}
        idAgevolazioneByIdVariabile={idAgevolazioneByIdVariabile}
        agevolazioni={agevolazioni}
        readOnly={readOnly}
      />
    </div>
  );
}

function AgevolazioneSelector({
  agevolazioni,
  variant,
  readOnly,
  source,
  idVariabileKey,
  idAgevolazioneByIdVariabile,
}: {
  agevolazioni: Agevolazione[];
  variant?: "filled" | "outlined" | "standard";
  readOnly: boolean;
  source: any;
  idVariabileKey: string;
  idAgevolazioneByIdVariabile: any;
}) {
  return (
    <FormControl
      style={{
        minWidth: 100,
        marginRight: 8,
        flex: 1,
      }}
      disabled={readOnly}
      size="small"
    >
      <InputLabel variant={variant}>Agevolazione</InputLabel>

      <AsyncSelect
        label="Agevolazione"
        native={false}
        onChange={(event) => {
          const value = event.target.value;

          if (value) {
            const agevolazione = agevolazioni.find((a) => a.id === value);
            if (!agevolazione) {
              return;
            }

            source.changeValue(
              idVariabileKey,
              agevolazione.variabili?.filter((v) => v.nome)[0]?.id || null
            );
          } else {
            source.changeValue(idVariabileKey, null);
          }
        }}
        value={
          source.getValue(idVariabileKey)
            ? idAgevolazioneByIdVariabile[source.getValue(idVariabileKey)] || ""
            : ""
        }
        variant={variant}
        disabled={readOnly}
      >
        <MenuItem value="">
          <em></em>
        </MenuItem>
        {agevolazioni
          .filter((agevolazione) => {
            return agevolazione.nome;
          })
          .map((agevolazione) => {
            return {
              label: agevolazione.nome.nome,
              value: agevolazione.id,
            };
          })
          .map((option) => {
            return (
              <MenuItem
                key={option.value}
                dense={false}
                disableGutters={false}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            );
          })}
      </AsyncSelect>
    </FormControl>
  );
}

function ImportoSelector({
  variant,
  source,
  idVariabileKey,
  readOnly,
  idAgevolazioneByIdVariabile,
  agevolazioni,
}: {
  variant?: "filled" | "outlined" | "standard";
  source: any;
  idVariabileKey: string;
  readOnly: boolean;
  idAgevolazioneByIdVariabile: any;
  agevolazioni: Agevolazione[];
}) {
  const idVariabile = source.getValue(idVariabileKey, null);
  const idAgevolazione =
    idVariabile && idAgevolazioneByIdVariabile[idVariabile];
  const agevolazione =
    idAgevolazione && agevolazioni.find((a) => a.id === idAgevolazione);
  const variabili = agevolazione
    ? agevolazione.variabili.filter((v) => v.nome)
    : [];

  return (
    <FormControl
      style={{
        minWidth: 100,
        flex: 1,
      }}
      disabled={readOnly}
      error={source.getValidationErrors(idVariabileKey).length > 0}
      size="small"
    >
      <InputLabel
        error={source.getValidationErrors(idVariabileKey).length > 0}
        variant={variant}
      >
        Importo
      </InputLabel>
      <AsyncSelect
        label="Importo"
        native={false}
        onChange={(event) => {
          const value = event.target.value;

          source.changeValue(idVariabileKey, value || null);

          setTimeout(
            () => {
              source.runValidation(idVariabileKey);
            },

            0
          );
        }}
        onClose={() => {
          source.runValidation(idVariabileKey);
        }}
        value={source.getValue(idVariabileKey) || ""}
        variant={variant}
        disabled={readOnly}
      >
        <MenuItem value="">
          <em></em>
        </MenuItem>
        {variabili
          .map((variabile) => {
            return {
              value: variabile.id,
              label: variabile.nome.nome,
            };
          })
          .map((option) => {
            return (
              <MenuItem
                key={option.value}
                dense={false}
                disableGutters={false}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            );
          })}
      </AsyncSelect>
      {source.getValidationErrors(idVariabileKey)[0] ? (
        <FormHelperText>
          {source.getValidationErrors(idVariabileKey)[0]}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
