import React, { useEffect } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import {
  createFormatAttivita as createFormatAttivitaAction,
  editFormatAttivita as editFormatAttivitaAction,
} from "app/actions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DizionarioSoftInput from "app/components/common/DizionarioSoftInput";
import DataDiScadenzaPicker from "app/components/FormatProcedure/DataDiScadenzaPicker";
import NumberInput from "app/components/common/NumberInput";
import MilestoneDiPagamentoSelect from "app/components/common/MilestoneDiPagamentoSelect";
import AccordionDocumentiRichiesti from "app/components/FormatProcedure/AccordionDocumentiRichiesti";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import VariabileAgevolazionePicker from "app/components/common/VariabileAgevolazionePicker";
import DeleteIcon from "@material-ui/icons/Delete";
import { ColoredButton } from "app/elements/ColoredButton";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";

export default function DialogFormatAttivita(props) {
  const showToast = useToast();

  const formatAttivita = useDataSource({
    parent: props.arraySource,
    pathInParent: [
      {
        id: props.idFormatAttivita,
      },
    ],
    initialData: {},
    itemId: props.idFormatAttivita,
    createAction: createFormatAttivitaAction,
    onCreateSuccess: () => {
      showToast("Attività creata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      if (props.onCreateSuccess) {
        props.onCreateSuccess();
      }

      props.onClose();
    },
    onCreateError: () => {
      showToast("Errore durante la creazione dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
    editAction: editFormatAttivitaAction,
    onEditSuccess: () => {
      showToast("Attività modificata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });

      props.onClose();
    },
    onEditError: () => {
      showToast("Errore durante la modifica dell'attività", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
    },
  });

  const milestonePagamenti = useDataSource({
    parent: props.formatProcedura,
    pathInParent: ["linea", "milestonePagamenti"],
    initialData: [],
  });

  const formatDocumenti = useDataSource({
    parent: formatAttivita,
    pathInParent: ["formatDocumenti"],
    initialData: [],
  });

  const formatVariabili = useDataSource({
    parent: formatAttivita,
    pathInParent: ["formatVariabili"],
    initialData: [],
  });

  const saveFunction = async () => {
    await formatAttivita.commit();

    return null;
  };

  const saving = formatAttivita.editing || formatAttivita.creating;

  const readOnlyOrSaving = props.readOnly ? props.readOnly : saving;

  if (!props.idFormatAttivita) {
    return null;
  }

  return (
    <Dialog maxWidth="lg" open>
      <DialogTitle>
        {formatAttivita.getValue("__isNew")
          ? "Crea nuova attività"
          : "Modifica attività"}
      </DialogTitle>

      <DialogContent
        className={readOnlyOrSaving ? "crea-readOnly" : ""}
        style={{ overflowX: "hidden" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DizionarioSoftInput
              size="small"
              variant="outlined"
              readOnly={readOnlyOrSaving}
              label="Nome attività"
              autoFocus
              source={formatAttivita}
              sourceKey="nome"
              fullWidth
              dizionarioSoft="nomeFormatAttivita"
            />
          </Grid>

          <NonEliminabileFlag
            formatAttivita={formatAttivita}
            readOnlyOrSaving={readOnlyOrSaving}
          />

          <Grid item sm={6} xs={12}>
            {props.isMultibene && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formatAttivita.getValue(
                      "isRipetiPerOgniBene",
                      false
                    )}
                    disabled={formatAttivita.loading || formatAttivita.editing}
                    onChange={(event) => {
                      const value = event.target.checked;

                      formatAttivita.changeValue("isRipetiPerOgniBene", value);
                    }}
                    value={formatAttivita.getValue("isRipetiPerOgniBene", "")}
                  />
                }
                disabled={readOnlyOrSaving}
                label="Ripeti per ogni bene"
              />
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <DataDiScadenzaPicker
              title="Data di scadenza perentoria"
              source={formatAttivita}
              dateSourceKey="dataScadenzaPerentoria"
              dateDeltaSourceKey="dataScadenzaPerentoriaDelta"
              dateTypeSourceKey="dataScadenzaPerentoriaType"
              dateIdFormatAttivita="dsPerentoriaIdFormatAttivita"
              arrayAttivita={props.arraySource.data}
              attivitaId={props.idFormatAttivita}
              readOnly={readOnlyOrSaving}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <DataDiScadenzaPicker
              title="Data di scadenza di progetto"
              source={formatAttivita}
              dateSourceKey="dataScadenzaProgetto"
              dateDeltaSourceKey="dataScadenzaProgettoDelta"
              dateTypeSourceKey="dataScadenzaProgettoType"
              dateIdFormatAttivita="dsProgettoIdFormatAttivita"
              arrayAttivita={props.arraySource.data}
              attivitaId={props.idFormatAttivita}
              readOnly={readOnlyOrSaving}
            />
          </Grid>

          <Grid item md={4} sm={12}>
            <NumberInput
              size="small"
              fullWidth={true}
              label="Ore previste"
              variant="outlined"
              source={formatAttivita}
              sourceKey="orePreviste"
              readOnly={readOnlyOrSaving}
              disabled={readOnlyOrSaving}
              min={0}
            />
          </Grid>

          <Grid item md={8} sm={12}>
            <MilestoneDiPagamentoSelect
              readOnly={readOnlyOrSaving}
              idLinea={props.formatProcedura.getValue(["linea", "id"])}
              source={formatAttivita}
              sourceKey="idMilestonePagamento"
              milestonePagamenti={milestonePagamenti}
            />
          </Grid>
        </Grid>

        <AccordionDocumentiRichiesti
          formatDocumenti={formatDocumenti}
          readOnly={readOnlyOrSaving}
        />

        <ImportiDaCompilareAccordion
          formatVariabili={formatVariabili}
          formatProcedura={props.formatProcedura}
          readOnlyOrSaving={readOnlyOrSaving}
          formatAttivita={formatAttivita}
        />
      </DialogContent>

      <DialogActions>
        <ColoredButton
          disabled={saving}
          onClick={() => {
            if (formatAttivita.getValue("__isNew")) {
              formatAttivita.delete();
            } else {
              formatAttivita.cancel();
            }

            props.onClose(false);
          }}
          color="secondary"
          variant="outlined"
        >
          {props.readOnly ? "Chiudi" : "Annulla"}
        </ColoredButton>

        {!readOnlyOrSaving && (
          <ColoredButton
            style={{
              marginLeft: 8,
            }}
            disabled={saving}
            onClick={() => {
              saveFunction();
            }}
            color="secondary"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Salva
          </ColoredButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function NonEliminabileFlag({ formatAttivita, readOnlyOrSaving }) {
  const formatDocumenti = useDataSource({
    parent: formatAttivita,
    pathInParent: ["formatDocumenti"],
    initialData: [],
  });

  const formatVariabili = useDataSource({
    parent: formatAttivita,
    pathInParent: ["formatVariabili"],
    initialData: [],
  });

  const hasDocumenti = formatDocumenti.data?.length > 0;
  const hasVariabili = formatVariabili.data?.length > 0;
  const hasMilestonePagamento = !!formatAttivita.getValue(
    "idMilestonePagamento"
  );
  const mustBeNonEliminabile =
    hasDocumenti || hasVariabili || hasMilestonePagamento;
  const isNonEliminabile = formatAttivita.getValue("isNonEliminabile", false);

  useEffect(() => {
    if (mustBeNonEliminabile && !isNonEliminabile) {
      formatAttivita.changeValue("isNonEliminabile", true);
    }
  }, [isNonEliminabile, mustBeNonEliminabile]);

  return (
    <Grid item sm={6} xs={12}>
      <FormControlLabel
        disabled={
          mustBeNonEliminabile ||
          readOnlyOrSaving ||
          formatAttivita.loading ||
          formatAttivita.editing
        }
        control={
          <Checkbox
            checked={formatAttivita.getValue("isNonEliminabile", false)}
            onChange={(event) => {
              const value = event.target.checked;

              formatAttivita.changeValue("isNonEliminabile", value);
            }}
            value={formatAttivita.getValue("isNonEliminabile", false)}
          />
        }
        label="Attività non eliminabile"
      />
    </Grid>
  );
}

function ImportiDaCompilareAccordion({
  formatVariabili,
  readOnlyOrSaving,
  formatProcedura,
  formatAttivita,
}) {
  const agevolazioni = useDataSource({
    parent: formatProcedura,
    pathInParent: ["linea", "agevolazioni"],
    initialData: [],
  });

  const agevolazioniSelezionabili =
    agevolazioni?.data?.map((agevolazione) => ({
      ...agevolazione,
      variabili: agevolazione.variabili.filter(
        (variabile) => !variabile.definitaInFaseDiOfferta
      ),
    })) || [];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Importi da compilare
      </AccordionSummary>

      <AccordionDetails>
        <div
          style={{
            width: "100%",
          }}
        >
          <DataTable
            source={formatVariabili}
            columns={[
              {
                path: ["idVariabile"],
                header: "Importo",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <VariabileAgevolazionePicker
                      agevolazioni={agevolazioniSelezionabili}
                      readOnly={readOnlyOrSaving}
                      variant="outlined"
                      source={rowSource}
                      idVariabileKey="idVariabile"
                    />
                  );
                },
                disableColumnFilter: false,
                disableGlobalFilter: false,
                sortable: true,
                type: "text",
              },
              !readOnlyOrSaving
                ? {
                    path: ["id"],
                    header: "Azioni",
                    width: 64,
                    renderCell: (value, row, rowIndex, rowSource) => {
                      return (
                        <div>
                          <IconButton
                            onClick={() => {
                              rowSource.delete();
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      );
                    },
                    disableColumnFilter: true,
                    disableGlobalFilter: true,
                    sortable: true,
                    type: "text",
                  }
                : null,
            ]}
            topToolbar={null}
            bottomToolbar={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.75em",
                }}
              >
                {!readOnlyOrSaving ? (
                  <ColoredButton
                    onClick={() => {
                      formatVariabili.create();
                    }}
                    variant="outlined"
                  >
                    Aggiungi importo
                  </ColoredButton>
                ) : null}{" "}
              </div>
            }
            showFilterRow={false}
            pageSize={1000000}
            size="small"
            expandableRows={false}
            expandRowsOnClick={false}
            isRowExpandable={null}
            renderExpandedRow={null}
            messages={{
              noRows: "Nessuna variabile",
            }}
          />

          <div className="MuiFormHelperText-root MuiFormHelperText-marginDense MuiFormHelperText-contained Mui-error">
            {formatAttivita.getValidationErrors("formatVariabili")?.[0]}{" "}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
