export let permissionsByRole = null;

if (typeof window !== "undefined") {
  // client side!
  permissionsByRole = window.permissionsByRole;
}

/* server side! */
let _loadPermissionsByRolePromise = null;

export async function loadPermissionsByRole(sequelize) {
  if (!_loadPermissionsByRolePromise) {
    const p = new Promise((resolve) => {
      sequelize
        .query('SELECT * FROM "RuoliPermessi"', { type: "SELECT" })
        .then((data) => {
          if (!permissionsByRole || _loadPermissionsByRolePromise === p) {
            permissionsByRole = _createPermissionsByRoleFromDb(data);
          }
          resolve(permissionsByRole);
        });
    });
    _loadPermissionsByRolePromise = p;
  }
  return _loadPermissionsByRolePromise;
}

export async function reloadPermissionsByRole(sequelize) {
  _loadPermissionsByRolePromise = null;
  return loadPermissionsByRole(sequelize);
}

export function updatePermissionsByRole(_permissionsByRole) {
  permissionsByRole = _permissionsByRole;
}

function _createPermissionsByRoleFromDb(data) {
  const _permissionsByRole = {};
  data.forEach((row) => {
    const { ruolo, permesso } = row;
    _permissionsByRole[ruolo] = _permissionsByRole[ruolo] || [];
    _permissionsByRole[ruolo].push(permesso);
  });
  return _permissionsByRole;
}
