import React, { useState } from "react";
import { useToast } from "app/hooks/useToast";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import { useApi } from "app/hooks/useApi";
import { aggiornaVersioneProcedura as aggiornaVersioneProceduraAction } from "app/actions";
import { Dialog, Grid, CircularProgress } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import RichTextEditor from "app/components/common/RichTextEditor";
import DialogActions from "@material-ui/core/DialogActions";
import { ColoredButton } from "app/elements/ColoredButton";

export default function ProceduraAggiornaVersioneDialog(props) {
  const showToast = useToast();

  const formatProceduraSuccessiva = useDataSource({
    parent: props.procedura,
    pathInParent: ["formatProcedura", "versioneSuccessiva"],
    initialData: {},
  });

  const aggiornaVersioneProceduraApi = useApi(aggiornaVersioneProceduraAction, {
    onSuccess: (response) => {
      showToast("Commessa aggiornata alla nuova versione", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      props.onUpdated();
      setSaving(false);
      props.onClose();
    },
    onError: (response) => {
      showToast("Errore durante l'aggiornamento della versione", {
        color: "error",
        horizontal: "left",
        vertical: "bottom",
      });
      setSaving(false);
    },
  });

  const [saving, setSaving] = useState(false);
  return (
    <Dialog fullWidth={true} maxWidth="md" open={props.open}>
      <DialogTitle>
        Vuoi aggiornare questa commessa alla nuova versione?
      </DialogTitle>

      <DialogContent>
        <Grid container={true} item={false} spacing={4}>
          <Grid container={false} item={true} lg={4} md={6} xs={12}>
            <RichTextEditor
              __to_reload={formatProceduraSuccessiva?.getValue(
                "motivoRevisione"
              )}
              source={formatProceduraSuccessiva}
              sourceKey="motivoRevisione"
              readOnly={true}
              label="Motivo revisione"
            />
          </Grid>

          <Grid container={false} item={true} lg={8} md={6} xs={12}>
            <RichTextEditor
              __to_reload={formatProceduraSuccessiva?.getValue(
                "istruzioniOperative"
              )}
              source={formatProceduraSuccessiva}
              sourceKey="istruzioniOperative"
              readOnly={true}
              label="Istruzioni operative"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {saving ? (
          <div
            style={{
              marginRight: 10,
            }}
          >
            <CircularProgress
              style={{
                marginRight: 4,
              }}
              size={12}
            />
            Aggiornando la commessa...
          </div>
        ) : null}

        <ColoredButton
          disabled={saving}
          onClick={(event) => {
            setSaving(true);

            const TIMEOUT = 1000;

            setTimeout(() => {
              aggiornaVersioneProceduraApi.callApi(
                props.procedura.getValue("id"),
                {
                  nextFormatProceduraId:
                    formatProceduraSuccessiva?.getValue("id"),
                }
              );
            }, TIMEOUT);
          }}
          size="small"
          variant="outlined"
        >
          Passa alla nuova versione
        </ColoredButton>

        <ColoredButton
          disabled={saving}
          onClick={(event) => {
            props.onClose();
          }}
          size="small"
          variant="outlined"
        >
          Annulla
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
}
