import moment from "moment";
import { useMemo } from "react";
import { uniqueId } from "lodash";
import { SKanbanBoard, SKanbanCard, SKanbanColumn } from "./SKanban";

const dateAggregationFunc = {
  day: (date: string | Date) => moment(date).startOf("day"),
  week: (date: string | Date) => moment(date).startOf("isoWeek"),
  month: (date: string | Date) => moment(date).startOf("month"),
};

const getDefaultColumnTitleWithDate = {
  day: (date: string) => moment(date).format("DD/MM/YYYY"),
  week: (date: string) => moment(date).format("DD/MM/YYYY"),
  month: (date: string) => moment(date).format("MM/YYYY"),
};

export function useCreateBoardWithTimeColumns<D>(
  data: D[] | null | undefined,
  getCardDate: (D) => string | Date | null | undefined,
  options: {
    getColumnTitle?: (D) => string;
    startDate?: string | undefined;
    endDate?: string | undefined;
    minimumColumns?: number;
    addEmptyFutureColumns?: number;
    groupBy?: "day" | "week" | "month";
    showEmptyColumns?: boolean; // default: true
    showPreviousCardsColumn?: boolean;
    previousCardsColumnTitle?: string;
    showNextCardsColumn?: boolean;
    nextCardsColumnTitle?: string;
    showWithoutDateColumn?: boolean | "last" | "first";
    withoutDateColumnTitle?: string;
    preventDropOnWithoutDateColumn?: boolean; // default: true
  } = {}
): SKanbanBoard<D> {
  const groupBy = options.groupBy || "week";
  const preventDropOnWithoutDateColumn =
    options.preventDropOnWithoutDateColumn ?? true;

  const minimumColumns =
    options.minimumColumns && options.minimumColumns > 0
      ? options.minimumColumns
      : 0;
  const addEmptyFutureColumns =
    options.addEmptyFutureColumns && options.addEmptyFutureColumns > 0
      ? options.addEmptyFutureColumns
      : 0;

  let showEmptyColumns = options.showEmptyColumns ?? true;
  if (
    minimumColumns ||
    addEmptyFutureColumns ||
    options.startDate ||
    options.endDate
  ) {
    showEmptyColumns = true;
  }

  return useMemo(() => {
    const aggrFunc = dateAggregationFunc[groupBy];
    const getColumnTitle =
      options.getColumnTitle || getDefaultColumnTitleWithDate[groupBy];

    const startDate = options.startDate ? aggrFunc(options.startDate) : null;
    const endDate = options.endDate ? aggrFunc(options.endDate) : null;

    type CardWithDate = SKanbanCard<D> & {
      _columnId: string;
      _date: string | Date | null | undefined;
      _dateMoment: moment.Moment | null;
      _dateUTC: string | null;
    };

    type ColumnId =
      | "date-null"
      | "date-before"
      | "date-after"
      | `date--${string}`;

    const getColumnId = (
      date: string | Date | null | undefined
    ): "" | ColumnId => {
      if (!date) {
        if (!options.showWithoutDateColumn) {
          return "";
        }
        return "date-null";
      }
      const aggrDate = aggrFunc(date);

      if (startDate && aggrDate.isBefore(startDate)) {
        if (options.showPreviousCardsColumn) {
          return "date-before";
        } else {
          return "";
        }
      }
      if (endDate && aggrDate.isAfter(endDate)) {
        if (options.showNextCardsColumn) {
          return "date-after";
        } else {
          return "";
        }
      }

      return `date--${aggrDate.format("YYYY-MM-DD")}`;
    };

    const cardsByColumnId: {
      [key in ColumnId]?: CardWithDate[];
    } = {};
    const cards: CardWithDate[] = (data || [])
      .map((d) => {
        const date = getCardDate(d);
        const id: string = getColumnId(date);
        const dateMoment = date ? moment(date) : null;
        return {
          id: uniqueId(),
          _columnId: id,
          data: d,
          _date: date,
          _dateMoment: dateMoment,
          _dateUTC: dateMoment ? dateMoment.toISOString() : null,
        };
      })
      .filter((x) => x._columnId);

    for (let index = 0; index < cards.length; index++) {
      const card = cards[index];
      cardsByColumnId[card._columnId] = cardsByColumnId[card._columnId] || [];
      cardsByColumnId[card._columnId].push(card);
    }

    const columnIds = Object.keys(cardsByColumnId);
    const columnWithDateIds = columnIds
      .filter((x) => x.startsWith("date--"))
      .sort();

    columnIds.forEach((id) => {
      const _cards = cardsByColumnId[id] as CardWithDate[] | undefined;
      if (_cards) {
        _cards.sort((a, b) => {
          const aDate = a._dateUTC;
          const bDate = b._dateUTC;
          if (aDate && bDate) {
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
          } else {
            return 0;
          }
        });
      }
    });

    let columnsBefore: SKanbanColumn<D>[] = [];
    let columnsAfter: SKanbanColumn<D>[] = [];

    if (
      options.showPreviousCardsColumn &&
      cardsByColumnId["date-before"]?.length
    ) {
      columnsBefore.push({
        id: "date-before",
        title: options.previousCardsColumnTitle || "",
        cards: cardsByColumnId["date-before"],
      });
    }
    if (options.showNextCardsColumn && cardsByColumnId["date-after"]?.length) {
      columnsAfter.push({
        id: "date-after",
        title: options.nextCardsColumnTitle || "",
        cards: cardsByColumnId["date-after"],
      });
    }
    if (options.showWithoutDateColumn && cardsByColumnId["date-null"]?.length) {
      const withoutDateColumn: SKanbanColumn<D> = {
        id: "date-null",
        title: options.withoutDateColumnTitle || "",
        cards: cardsByColumnId["date-null"],
      };
      if (preventDropOnWithoutDateColumn) {
        withoutDateColumn.droppable = false;
      }
      if (options.showWithoutDateColumn === "last") {
        columnsAfter = [...columnsAfter, withoutDateColumn];
      } else {
        columnsBefore = [withoutDateColumn, ...columnsBefore];
      }
    }

    const columnsWithDates: SKanbanColumn<D>[] = [];
    let firstColDateString = startDate
      ? startDate.format("YYYY-MM-DD")
      : columnWithDateIds.at(0)?.substring("date--".length);

    let lastColDateString = endDate
      ? endDate.format("YYYY-MM-DD")
      : columnWithDateIds.at(-1)?.substring("date--".length);

    if (firstColDateString || lastColDateString) {
      let dateStringArray: string[] = [];
      if (showEmptyColumns) {
        if (!firstColDateString) {
          firstColDateString = lastColDateString;
        }
        if (!lastColDateString) {
          lastColDateString = firstColDateString;
        } else if (
          firstColDateString &&
          moment(lastColDateString).isBefore(moment(firstColDateString))
        ) {
          lastColDateString = firstColDateString;
        }

        firstColDateString = firstColDateString!;
        lastColDateString = lastColDateString!;

        let dateString = firstColDateString;
        let _addEmptyFutureColumns = addEmptyFutureColumns;
        while (
          dateString <= lastColDateString ||
          // eslint-disable-next-line no-unmodified-loop-condition
          (minimumColumns > 0 && dateStringArray.length < minimumColumns)
        ) {
          dateStringArray.push(dateString);
          if (dateString > lastColDateString) {
            _addEmptyFutureColumns--;
          }
          dateString = moment(dateString).add(1, groupBy).format("YYYY-MM-DD");
        }
        if (_addEmptyFutureColumns > 0) {
          for (let index = 0; index < _addEmptyFutureColumns; index++) {
            dateStringArray.push(dateString);
            dateString = moment(dateString)
              .add(1, groupBy)
              .format("YYYY-MM-DD");
          }
        }
      } else {
        dateStringArray = columnWithDateIds.map((x) =>
          x.substring("date--".length)
        );
      }

      dateStringArray.forEach((dateString) => {
        const id = `date--${dateString}`;
        columnsWithDates.push({
          id: id,
          title: getColumnTitle(dateString),
          cards: cardsByColumnId[id] || [],
        });
      });
    }

    const columns: SKanbanColumn<D>[] = [
      ...columnsBefore,
      ...columnsWithDates,
      ...columnsAfter,
    ];

    const board: SKanbanBoard<D> = {
      columns: columns,
    };

    return board;
  }, [
    data,
    options.startDate,
    options.endDate,
    groupBy,
    showEmptyColumns,
    preventDropOnWithoutDateColumn,
    options.showPreviousCardsColumn,
    options.showNextCardsColumn,
    options.showWithoutDateColumn,
    minimumColumns,
    addEmptyFutureColumns,
  ]);
}
