import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import NumberInput from "app/components/common/NumberInput";
import VariabileAgevolazionePicker from "app/components/common/VariabileAgevolazionePicker";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

// PROPS:
// agevolazioni -> datasource
// fslMilestones -> datasource
// idMilestonePagamento -> number ---> = rowSource.getValue('id')
// readOnly
export default function MilestonePagamentiImporto(props) {
  const { agevolazioni, fslMilestones, idMilestonePagamento } = props;

  const fslMilestoneId = fslMilestones.data.find((includedMilestone) => {
    return idMilestonePagamento === includedMilestone.idMilestonePagamento;
  }).id;

  const tipoImporto = fslMilestones.getValue([
    {
      id: fslMilestoneId,
    },
    "tipoImporto",
  ]);

  let content = null;

  if (tipoImporto === "percentuale") {
    // PERCENTUALE

    content = (
      <div
        style={{
          display: "flex",
          minWidth: 500,
          alignItems: "center",
        }}
      >
        <NumberInput
          percent={true}
          source={fslMilestones}
          sourceKey={[
            {
              id: fslMilestoneId,
            },
            "min",
          ]}
          disabled={props.readOnly}
          style={{
            marginRight: 8,
          }}
          variant="outlined"
          fullWidth={false}
          label="Min"
          min={0}
        />
        <NumberInput
          percent={true}
          source={fslMilestones}
          sourceKey={[
            {
              id: fslMilestoneId,
            },
            "listino",
          ]}
          disabled={props.readOnly}
          style={{
            marginRight: 8,
          }}
          variant="outlined"
          fullWidth={false}
          label="Listino"
          min={0}
          min___NOT_WORKING={fslMilestones.getValue(
            [
              {
                id: fslMilestoneId,
              },
              "min",
            ],
            0
          )}
        />
        di
        <div
          style={{
            flex: 1,
            minWidth: 350,
          }}
        >
          <VariabileAgevolazionePicker
            agevolazioni={agevolazioni?.data}
            readOnly={true}
            variant="outlined"
            source={fslMilestones}
            idVariabileKey={[
              {
                id: fslMilestoneId,
              },
              "idVariabile",
            ]}
          />
        </div>
      </div>
    );
  } else if (tipoImporto === "percentuale-scaglioni") {
    // SCAGLIONI

    let scaglioni =
      fslMilestones.getValue([
        {
          id: fslMilestoneId,
        },
        "scaglioni",
      ]) || [];

    // add temp key
    scaglioni.forEach((s) => {
      if (!s.__key) {
        s.__key = "k" + Math.random();
      }
    });

    if (scaglioni.length === 0) {
      scaglioni = [
        {
          finoA: null,
          min: null,
          listino: null,
        },
      ];
      fslMilestones.changeValue(
        [
          {
            id: fslMilestoneId,
          },
          "scaglioni",
        ],
        scaglioni
      );
    }

    content = (
      <div
        style={{
          // display: 'flex',
          minWidth: 500,
          width: "100%",
        }}
      >
        Percentuali relative a:
        <div
          style={{
            flex: 1,
            minWidth: 350,
          }}
        >
          <VariabileAgevolazionePicker
            agevolazioni={agevolazioni?.data}
            readOnly={true}
            variant="outlined"
            source={fslMilestones}
            idVariabileKey={[
              {
                id: fslMilestoneId,
              },
              "idVariabile",
            ]}
          />
        </div>
        <hr />
        <Table
        // </div>className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell>Fino a</TableCell>
              <TableCell>Percentuale</TableCell>
              <TableCell style={{ width: 100 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scaglioni.map((scaglione, scaglioneIndex) => {
              const isLast = scaglioneIndex === scaglioni.length - 1;

              return (
                <TableRow key={scaglione.__key}>
                  <TableCell>
                    {isLast ? (
                      " ∞ "
                    ) : (
                      <NumberInput
                        currency={true}
                        source={fslMilestones}
                        sourceKey={[
                          {
                            id: fslMilestoneId,
                          },
                          "scaglioni",
                          scaglioneIndex,
                          "finoA",
                        ]}
                        disabled={props.readOnly}
                        style={
                          {
                            // marginRight: 8,
                          }
                        }
                        variant="outlined"
                        fullWidth={false}
                        label="Fino a:"
                        min={0}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <NumberInput
                        percent={true}
                        source={fslMilestones}
                        sourceKey={[
                          {
                            id: fslMilestoneId,
                          },
                          "scaglioni",
                          scaglioneIndex,
                          "min",
                        ]}
                        disabled={props.readOnly}
                        style={{
                          marginRight: 8,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        label="Min"
                        min={0}
                      />

                      <NumberInput
                        percent={true}
                        source={fslMilestones}
                        sourceKey={[
                          {
                            id: fslMilestoneId,
                          },
                          "scaglioni",
                          scaglioneIndex,
                          "listino",
                        ]}
                        disabled={props.readOnly}
                        style={{
                          marginRight: 8,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        label="Listino"
                        min={0}
                        min___NOT_WORKING={fslMilestones.getValue(
                          [
                            {
                              id: fslMilestoneId,
                            },
                            "scaglioni",
                            scaglioneIndex,
                            "min",
                          ],
                          0
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        maxWidth: 200,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {props.readOnly ? null : (
                        // ? (
                        // <>
                        //   <Tooltip title="Salva le modifiche">
                        //     <IconButton
                        //       onClick={(event) => {
                        //         interazione.commit()
                        //       }}
                        //     >
                        //       <SaveIcon />
                        //     </IconButton>
                        //   </Tooltip>

                        //   <Tooltip title="Annulla le modifiche">
                        //     <IconButton
                        //       onClick={(event) => {
                        //         if (interazione.getValue('__isNew')) {
                        //           // source.delete({
                        //           //   tempId: data.id,
                        //           // })
                        //         } else {
                        //           // interazione.cancel()
                        //           // reload()
                        //         }
                        //       }}
                        //     >
                        //       <CancelIcon />
                        //     </IconButton>
                        //   </Tooltip>
                        // </>
                        //   )
                        // : (
                        <>
                          <Tooltip title="Aggiungi">
                            <IconButton
                              onClick={(event) => {
                                const newScaglioni = [...scaglioni];
                                newScaglioni.splice(scaglioneIndex + 1, 0, {
                                  finoA: null,
                                  min: null,
                                  listino: null,
                                });
                                fslMilestones.changeValue(
                                  [
                                    {
                                      id: fslMilestoneId,
                                    },
                                    "scaglioni",
                                  ],
                                  newScaglioni
                                );
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Elimina">
                            <IconButton
                              onClick={(event) => {
                                const newScaglioni = [...scaglioni];
                                newScaglioni.splice(scaglioneIndex, 1);
                                if (isLast) {
                                  newScaglioni[newScaglioni.length - 1] = {
                                    ...newScaglioni[newScaglioni.length - 1],
                                    finoA: null,
                                  };
                                }
                                fslMilestones.changeValue(
                                  [
                                    {
                                      id: fslMilestoneId,
                                    },
                                    "scaglioni",
                                  ],
                                  newScaglioni
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  } else {
    // VALORE

    content = (
      <div
        style={{
          display: "flex",
          minWidth: 500,
          alignItems: "center",
        }}
      >
        <NumberInput
          currency={true}
          source={fslMilestones}
          sourceKey={[
            {
              id: fslMilestoneId,
            },
            "min",
          ]}
          disabled={props.readOnly}
          style={{
            marginRight: 8,
          }}
          variant="outlined"
          fullWidth={false}
          label="Min"
          min={0}
        />

        <NumberInput
          currency={true}
          source={fslMilestones}
          sourceKey={[
            {
              id: fslMilestoneId,
            },
            "listino",
          ]}
          disabled={props.readOnly}
          style={{}}
          variant="outlined"
          fullWidth={false}
          label="Listino"
          min={0}
        />
      </div>
    );
  }

  return content;
}
